<script setup lang="ts">
import BaseInput from '@/components/forms/BaseInput.vue'
import feedbackService, { type IFeedbackRequest } from '@/services/feedback.service'
import { useField, useForm } from 'vee-validate'
import { ref, computed, onMounted, watch } from 'vue'
import { string } from 'yup'

const languageUrl = ref<string>('')

const httpErrorTimeOut = ref<number | null>(null)
const httpError = ref<string>('')
const httpSuccessTimeOut = ref<number | null>(null)
const httpSuccess = ref<string>('')

const { errors, handleSubmit, resetForm } = useForm()

const { value: language, errorMessage: languageError } = useField('language', string().required())

const dynamicId = computed(() => {
  return `${Math.floor(Math.random() * 1000)}`
})
const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0
})

const onSubmit = handleSubmit(async (values) => {
  const requestPayload: IFeedbackRequest = {
    comment: values.language,
    isSurvey: true
  }

  await feedbackService
    .sendFeedback(requestPayload)
    .then((response: any) => {
      languageUrl.value = ''
      if (response?.data?.path && response?.data?.statusCode === 201) {
        languageUrl.value = response?.data?.path
        httpSuccess.value = `${values.language} is already available at `
      } else {
        httpSuccess.value = 'Thank you for your input!'
      }
      httpError.value = ''
      resetForm()
    })
    .catch((error) => {
      languageUrl.value = ''
      httpSuccess.value = ''
      httpError.value = error?.response?.data?.message
    })
})
onMounted(() => {
  watch(httpError, () => {
    if (httpError.value) {
      if (httpErrorTimeOut.value) clearTimeout(httpErrorTimeOut.value)
      httpErrorTimeOut.value = setTimeout(() => {
        httpError.value = ''
      }, 8000)
    }
  })
  watch(httpSuccess, () => {
    if (httpSuccess.value) {
      if (httpSuccessTimeOut.value) clearTimeout(httpSuccessTimeOut.value)
      httpSuccessTimeOut.value = setTimeout(() => {
        httpSuccess.value = ''
      }, 8000)
    }
  })
})
</script>
<template>
  <form @submit.prevent="onSubmit">
    <div class="flex flex-col items-end gap-3 sm:flex-row">
      <div class="w-full md:w-[70%]">
        <BaseInput
          :id="dynamicId"
          label="Which language would you like to see next in JDoodle?"
          :inputType="'input'"
          placeholder="New Language"
          :error="languageError"
          v-model="language"
        />
      </div>
      <button
        :disabled="!canSubmit"
        class="btn btn-primary mb-[4px] h-fit w-full sm:w-fit"
        type="submit"
      >
        Post Language
      </button>
    </div>
    <div>
      <p :class="['p-xsmall ', 'error', { hidden: !httpError }]">
        {{ httpError }}
      </p>
      <p :class="['p-xsmall ', 'success', { hidden: !httpSuccess }]">
        {{ httpSuccess }}
        <span class="link-secondary">
          <a :href="languageUrl" target="_blank">
            {{ languageUrl }}
          </a>
        </span>
      </p>
    </div>
  </form>
</template>
