<script setup lang="ts">
import ModelLayout from '@/layouts/ModelLayout.vue'
import { TEACHMODALHS } from '@/utils/models'
import BaseInput from '@/components/forms/BaseInput.vue'
import BaseTextbox from '@/components/forms/BaseTextbox.vue'
import { useField, useForm } from 'vee-validate'
import { string } from 'yup'
import { type PropType, computed, watch, ref } from 'vue'
import { registerInstitute } from '@/services/teach.service'
import { HttpStatusCode } from 'axios'
import type IInstitute from '@/components/guru/interface/IInstitute'
import { GURU_INSTITUTE_ACTIONS } from '@/components/guru/enums/teach.enum'
import type IInstituteOwn from '@/components/guru/interface/IInstituteOwn'
import { type IRegisterInstituteRequest } from '@/components/guru/interface/requests.interface'
import router from '@/router'
import utilModelsService from '@/services/util.models.service'

const props = defineProps({
  prefilledInstitute: {
    type: Object as PropType<IInstitute>,
    require: false
  },
  instituteOwn: {
    type: Object as PropType<IInstituteOwn>,
    require: false
  },
  mode: {
    type: String as PropType<GURU_INSTITUTE_ACTIONS>,
    require: false
  },
  isAccountOwner: {
    type: Boolean,
    required: false
  }
})

//Prop related
const currentInstitute = ref(props.prefilledInstitute)
const currentMode = ref(
  props.prefilledInstitute ? GURU_INSTITUTE_ACTIONS.VIEW : GURU_INSTITUTE_ACTIONS.ADD
)
const isDisabled = computed(() => currentMode.value === GURU_INSTITUTE_ACTIONS.VIEW)
const errorRes = ref<string>('')
watch(
  () => props.prefilledInstitute,
  (newValue) => {
    if (newValue !== undefined) {
      changeMode(GURU_INSTITUTE_ACTIONS.VIEW)

      currentInstitute.value = newValue
      description.value = currentInstitute.value?.description
      instituteCode.value = currentInstitute.value?.code
      instituteName.value = currentInstitute.value?.name

      firstName.value = props.instituteOwn?.firstName
      lastName.value = props.instituteOwn?.lastName
    }
  }
)

// Form-related
const { errors, handleSubmit } = useForm()

const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0
})

const { value: instituteCode, errorMessage: instituteCodeError } = useField(
  'instituteCode',
  string()
    .required()
    .min(3)
    .max(10)
    .matches(/^[a-zA-Z0-9]*$/)
)

const { value: instituteName, errorMessage: instituteNameError } = useField(
  'instituteName',
  string().required().min(5)
)

const { value: firstName, errorMessage: firstNameError } = useField(
  'firstName',
  string().required().min(3)
)

const { value: lastName, errorMessage: lastNameError } = useField(
  'lastName',
  string().required().min(3)
)
// unused-vars  descriptionError
const { value: description, errorMessage: descriptionError } = useField(
  'description',
  string().required().min(5)
)

const instituteCodeInput = {
  label: 'Virtual Institute Code (3-10 AlphaNumeric Characters)',
  placeholder: 'XYZSCHOOL'
}

const instituteNameInput = {
  label: 'Virtual Institute Name',
  placeholder: 'XYZ School'
}

const firstNameInput = {
  label: 'First Name',
  placeholder: ''
}

const lastNameInput = {
  label: 'Last Name',
  placeholder: ''
}

const descriptionInput = {
  label: 'Description',
  placeholder: 'Short description about your institute.'
}

/**
 * @returns Modal Title
 */
function getModalTitle() {
  if (currentMode.value === GURU_INSTITUTE_ACTIONS.ADD) {
    return 'Create Virtual Institute'
  } else if (currentMode.value === GURU_INSTITUTE_ACTIONS.VIEW) {
    return 'Your Virtual Institute'
  } else if (currentMode.value === GURU_INSTITUTE_ACTIONS.EDIT) {
    return 'Edit Virtual Institute'
  }
  return ''
}

/**
 * @param newMode enum of mode to change
 */
function changeMode(newMode: GURU_INSTITUTE_ACTIONS) {
  if (newMode === GURU_INSTITUTE_ACTIONS.EDIT) {
    currentMode.value = GURU_INSTITUTE_ACTIONS.EDIT
  }

  if (newMode === GURU_INSTITUTE_ACTIONS.ADD) {
    currentMode.value = GURU_INSTITUTE_ACTIONS.ADD
  }

  if (newMode === GURU_INSTITUTE_ACTIONS.VIEW) {
    currentMode.value = GURU_INSTITUTE_ACTIONS.VIEW
  }
}

const onSubmit = handleSubmit(async (values) => {
  try {
    let res = undefined
    if (currentMode.value === GURU_INSTITUTE_ACTIONS.ADD) {
      res = await registerInstitute({ mode: 'add', ...values } as IRegisterInstituteRequest)
    } else if (currentMode.value === GURU_INSTITUTE_ACTIONS.EDIT) {
      res = await registerInstitute({ mode: 'edit', ...values } as IRegisterInstituteRequest)
    }

    if (res?.status == HttpStatusCode.Ok) {
      errorRes.value = ''
      utilModelsService.closeModal(TEACHMODALHS.REGISTER_INSTITUTE)
      router.push(`/dashboard/institution`)
      // .then(() => router.go(0))
    }
  } catch (err: any) {
    errorRes.value = err.response.data.message
  }
})
</script>

<template>
  <ModelLayout :title="getModalTitle()" :hs="TEACHMODALHS.REGISTER_INSTITUTE">
    <!-- form -->
    <form @submit.prevent="onSubmit">
      <BaseInput
        :label="instituteCodeInput.label"
        :placeholder="instituteCodeInput.placeholder"
        v-model="instituteCode"
        :error="instituteCodeError"
        :disabled="currentMode == GURU_INSTITUTE_ACTIONS.ADD ? false : true"
      />

      <BaseInput
        :label="instituteNameInput.label"
        :placeholder="instituteNameInput.placeholder"
        v-model="instituteName"
        :error="instituteNameError"
        :disabled="isDisabled"
      />

      <div v-if="currentMode == GURU_INSTITUTE_ACTIONS.ADD || props.isAccountOwner">
        <BaseInput
          :label="firstNameInput.label"
          :placeholder="firstNameInput.placeholder"
          v-model="firstName"
          :error="firstNameError"
          :disabled="isDisabled"
        />

        <BaseInput
          :label="lastNameInput.label"
          :placeholder="lastNameInput.placeholder"
          v-model="lastName"
          :error="lastNameError"
          :disabled="isDisabled"
        />
      </div>

      <BaseTextbox
        :label="descriptionInput.label"
        :placeholder="descriptionInput.placeholder"
        v-model="description"
        :error="descriptionError"
        :disabled="isDisabled"
      />

      <button
        :disabled="!canSubmit"
        type="submit"
        class="btn-primary btn-rounded-md"
        v-if="currentMode === GURU_INSTITUTE_ACTIONS.ADD"
      >
        Create Institute
      </button>

      <div v-if="props.isAccountOwner">
        <button
          v-if="currentMode === GURU_INSTITUTE_ACTIONS.VIEW"
          class="btn-secondary btn-rounded-md"
          @click="changeMode(GURU_INSTITUTE_ACTIONS.EDIT)"
        >
          Edit Institute
        </button>
        <button
          v-if="currentMode === GURU_INSTITUTE_ACTIONS.EDIT"
          type="submit"
          class="btn-primary btn-rounded-md"
        >
          Save Changes
        </button>
      </div>
    </form>
    <span class="text-sm" v-if="currentMode === GURU_INSTITUTE_ACTIONS.VIEW">
      *Only 'Account Owner' can edit this page.
    </span>
    <p v-if="errorRes" class="error my-3 text-xs">{{ errorRes }}</p>
  </ModelLayout>
</template>
