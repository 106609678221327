import editorService from '@/services/ide/languages/html/editor.service'
import projectsService from '@/services/ide/projects.service'
import historyService from '@/services/ide/settings/history.service'
import { useIdeStore } from '@/stores/ide.store'

/**
 * Execute the code
 * @param autoSave if true, auto save the project
 */
const execute = async (autoSave: boolean = true) => {
  const script = editorService.getOutputScript()

  const textArea = document.getElementById('outputTextArea') as HTMLTextAreaElement
  textArea.value = script
  const form = document.getElementById('outputForm') as HTMLFormElement
  form.setAttribute('target', 'output')
  form.submit()

  useIdeStore().setisCodeExecuting(false)
  if (autoSave) projectsService.autoSave()
}
/**
 * Try to execute the code
 */
const tryExecute = () => {
  if (useIdeStore().isCodeExecuting) return
  useIdeStore().setisCodeExecuting(true)
  execute()
  historyService.addToRecentHtml()
}
/**
 * stop execute the code
 */
const stopExecution = () => {
  useIdeStore().setisCodeExecuting(false)
}

export default { tryExecute, execute, stopExecution }
