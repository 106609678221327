/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import {
  faFacebook,
  faGooglePlus,
  faHackerNews,
  faInstagram,
  faLinkedin,
  faPinterest,
  faReddit,
  faTelegram,
  faTumblr,
  faTwitter,
  faVk,
  faWhatsapp,
  faXing
} from '@fortawesome/free-brands-svg-icons'

import {
  faAngleDown,
  faAngleUp,
  faArrowDownZA,
  faArrowLeft,
  faArrowRight,
  faArrowUpRightFromSquare,
  faArrowsTurnToDots,
  faBars,
  faBook,
  faBookmark,
  faBuilding,
  faBuildingColumns,
  faCaretDown,
  faCheck,
  faCheckDouble,
  faChevronDown,
  faChevronRight,
  faCircleCheck,
  faCircleDollarToSlot,
  faCircleExclamation,
  faCircleInfo,
  faCircleNodes,
  faCircleQuestion,
  faCircleXmark,
  faClipboard,
  faClock,
  faCloud,
  faCode,
  faComment,
  faCommentDots,
  faCompress,
  faCopy,
  faCube,
  faDollar,
  faDownload,
  faEarthAsia,
  faEllipsis,
  faEnvelope,
  faExclamationCircle,
  faExpand,
  faEye,
  faFile,
  faFileAlt,
  faFileInvoiceDollar,
  faFileLines,
  faFloppyDisk,
  faFolder,
  faFolderClosed,
  faFolderOpen,
  faFolderPlus,
  faGear,
  faGears,
  faGlobe,
  faGraduationCap,
  faHand,
  faHeart,
  faHistory,
  faHome,
  faHouse,
  faIdCard,
  faKey,
  faKeyboard,
  faLanguage,
  faLaptopCode,
  faLightbulb,
  faLink,
  faListCheck,
  faLockOpen,
  faMagnifyingGlass,
  faMaximize,
  faMessage,
  faMicrochip,
  faMinimize,
  faPaperPlane,
  faPen,
  faPenToSquare,
  faPersonChalkboard,
  faPlay,
  faPlus,
  faPrint,
  faPuzzlePiece,
  faQuestion,
  faQuestionCircle,
  faReceipt,
  faRightFromBracket,
  faRocket,
  faRotate,
  faRotateRight,
  faSchool,
  faScissors,
  faSearch,
  faShareAltSquare,
  faShareFromSquare,
  faShareNodes,
  faShareSquare,
  faShieldHalved,
  faSpinner,
  faSquare,
  faStarHalfStroke,
  faStop,
  faTrash,
  faTrashCan,
  faTriangleExclamation,
  faUnlock,
  faUpDownLeftRight,
  faUpload,
  faUser,
  faUserGroup,
  faUserTie,
  faUsers,
  faUsersBetweenLines,
  faVideo,
  faWallet,
  faXmark
} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(
  faEye,
  faArrowUpRightFromSquare,
  faChevronRight,
  faChevronDown,
  faSearch,
  faTrash,
  faCheck,
  faArrowRight,
  faArrowLeft,
  faMagnifyingGlass,
  faCircleInfo,
  faCircleXmark,
  faHeart,
  faPlay,
  faSpinner,
  faQuestion,
  faEnvelope,
  faFacebook,
  faTwitter,
  faLinkedin,
  faPen,
  faUsers,
  faUserTie,
  faArrowDownZA,
  faGear,
  faPenToSquare,
  faAngleUp,
  faAngleDown,
  faMinimize,
  faMaximize,
  faUser,
  faCaretDown,
  faBars,
  faXmark,
  faLanguage,
  faKey,
  faIdCard,
  faRightFromBracket,
  faLaptopCode,
  faPuzzlePiece,
  faPersonChalkboard,
  faBuilding,
  faCheckDouble,
  faBuildingColumns,
  faBook,
  faLockOpen,
  faStarHalfStroke,
  faEarthAsia,
  faKeyboard,
  faListCheck,
  faShareNodes,
  faShareFromSquare,
  faUsersBetweenLines,
  faCode,
  faArrowsTurnToDots,
  faGraduationCap,
  faExclamationCircle,
  faGears,
  faCompress,
  faFolder,
  faTrashCan,
  faUpload,
  faStop,
  faCopy,
  faQuestionCircle,
  faHouse,
  faFolderOpen,
  faHome,
  faCube,
  faFileAlt,
  faDownload,
  faPrint,
  faGooglePlus,
  faPinterest,
  faTumblr,
  faReddit,
  faXing,
  faWhatsapp,
  faHackerNews,
  faVk,
  faTelegram,
  faPlus,
  faFile,
  faVideo,
  faFloppyDisk,
  faBookmark,
  faShareAltSquare,
  faShareSquare,
  faClipboard,
  faCircleQuestion,
  faHistory,
  faTriangleExclamation,
  faCircleCheck,
  faUnlock,
  faRotate,
  faRotateRight,
  faCircleExclamation,
  faFolderPlus,
  faFolderClosed,
  faEllipsis,
  faSquare,
  faExpand,
  faPaperPlane,
  faGlobe,
  faDollar,
  faCircleDollarToSlot,
  faRocket,
  faMicrochip,
  faSchool,
  faFileLines,
  faCommentDots,
  faComment,
  faInstagram,
  faUpDownLeftRight,
  faScissors,
  faHand,
  faClock,
  faCloud,
  faMessage,
  faShieldHalved,
  faUserGroup,
  faLink,
  faWallet,
  faFileInvoiceDollar,
  faReceipt,
  faCircleNodes,
  faLightbulb
)
