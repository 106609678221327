<script setup lang="ts">
import { onBeforeMount, onMounted, watch, computed, onBeforeUnmount } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { useRoute } from 'vue-router'
import { type IMeta } from '@/utils/meta'
import ideService from '@/services/ide/ide.service'
import editorService from '@/services/ide/editor.service'
import embedService from '@/services/ide/embed.service'
import { useIdeStore } from '@/stores/ide.store'

import Recaptcha from '@/components/shared/RecaptchaComp.vue'
import PrintBlocker from '@/components/ide/shared/PrintBlocker.vue'
import LogoComp from '@/components/utils/LogoComp.vue'
import ProjectHeader from '@/components/ide/embed/ProjectHeaderComp.vue'
import Ide from '@/components/ide/embed/IdeComp.vue'
import Accordian from '@/components/ide/embed/AccordionComp.vue'
import Settings from '@/components/ide/embed/SettingsComp.vue'
import Output from '@/components/ide/embed/OutputComp.vue'
import FileComp from '@/components/ide/embed/FileComp.vue'
import PostToComp from '@/components/ide/embed/PostToComp.vue'

const props = defineProps({
  shareId: {
    type: String,
    required: true
  },
  set_args: {
    type: String,
    required: false
  }
})
const route = useRoute()
const ideStore = useIdeStore()

const meta = computed<IMeta>(() => {
  return route.meta as IMeta
})

const langDisplayName = computed(() => {
  return ideStore.ideMeta?.langDisplayName
})
const isCompileLang = computed(() => {
  return ideStore.ideMeta?.isCompile || ideStore.ideMeta?.language === 'java'
})
const isShareNotFound = computed(() => {
  return ideStore.shareNotFound
})
const isShareFoundHttpError = computed(() => {
  return ideStore.shareNotFoundHttpError
})
/**
 * Init embed ide
 */
const initEmbedIde = () => {
  ideStore.setIsEmbedded(true)
  if (!isShareNotFound.value) {
    editorService.injectAce()
    embedService.initOnRouterChange()
  }
}

onBeforeMount(() => {
  ideService.cleanIdeStore()
  initEmbedIde()
  watch(route, () => {
    if (route.meta.canonicalPath) initEmbedIde()
  })
})

onBeforeRouteLeave((to, from, next) => {
  if (ideStore.isCodeUpdated) {
    if (
      window.confirm(
        `Are you sure you want to move from this ${langDisplayName.value}${
          isCompileLang.value ? ' Compiler' : ''
        } IDE?`
      )
    ) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
})

onMounted(async () => {
  if (props.shareId) {
    embedService.initEmbedShare(meta.value, props.shareId, props.set_args)
    window.addEventListener('resize', () => {
      editorService.resizeCodeEditor()
    })
  }
  window.onbeforeunload = function () {
    if (ideStore.isCodeUpdated) {
      return `Are you sure you want to move from this ${langDisplayName.value}${
        isCompileLang.value ? ' Compiler' : ''
      } IDE?`
    }
  }
})
onBeforeUnmount(() => {
  ideService.cleanIde()
  ideService.cleanIdeStore()
})
</script>

<template>
  <Recaptcha />
  <div class="relative">
    <PrintBlocker />
    <div class="embed-ide">
      <p
        v-if="isShareNotFound"
        :class="[
          'print:hidden, p-small ',
          'error',
          'text-center',
          'my-5',
          { hidden: !isShareNotFound }
        ]"
      >
        {{ isShareFoundHttpError || 'Share not found' }}
      </p>
      <div
        v-show="!isShareNotFound"
        :class="['section-tertiary', 'flex', 'flex-col', 'gap-5', 'rounded-lg', 'p-5']"
      >
        <LogoComp class="mb-2 hidden w-full justify-center print:flex print:break-inside-avoid" />
        <ProjectHeader />
        <Ide />
        <Accordian />
        <Settings />
        <Output />
        <FileComp />
        <PostToComp />
      </div>
    </div>
  </div>
</template>

<style>
@import url('@/assets/styles/embed-ide.css');

.gutter {
  background-color: gray;
  background-repeat: no-repeat;
  background-position: 50%;
}
</style>
