import axios from 'axios'
import type { IDeleteProjectsRequest } from './ide/projects.service'

// delete project from dashboard

// open project in IDE

export interface IDashboardProject {
  id: string
  type: string
  language: string
  name: string
  lastAccess: string
  versionIndex: number
  dateCreated: string
  lastUpdated: string
  isMultiFile: boolean
}

export interface IDashboardProjectPageRequest {
  page: number
  size: number
}

/**
 * @param page Fetch the projects from the server
 * @returns The projects response object
 */
const loadDashboardProjects = async (page: IDashboardProjectPageRequest) => {
  return await axios
    .post('/api/doodle/myProjects', page)
    .then((response: { data: { projects: IDashboardProject[] } }) => {
      return response.data.projects
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @param id , project id selected
 * @param language gets the icon for the language
 * @param isMultifile flag to determine the advanded IDE projects
 * Delete the project from the server
 * @returns The project response object
 */
const deleteProject = async (id: string, language: string, isMultifile: boolean) => {
  const deleteProjectsRequest: IDeleteProjectsRequest = {
    id: id,
    lang: language,
    isMultiFile: isMultifile
  }

  return await axios
    .post('/api/doodle/deletefile', deleteProjectsRequest)
    .then(() => {})
    .catch((error) => {
      throw error
    })
}

export default {
  deleteProject,
  loadDashboardProjects
}
