import { type IinitAuthResponse } from '@/services/auth.service'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useAuthStore = defineStore('auth', () => {
  const isAppInitiated = ref<boolean>(false)
  const kurukkuKuri = ref<string | null>(null)
  const isUserloggedIn = ref<boolean>(false)
  const robotChecked = ref<boolean>(false)
  const lastRobotCheckedTime = ref<number | null>(null)
  const username = ref<string | null>(null)
  const notificationCount = ref<number | null>(null)
  const userEmail = ref<string | null>(null)
  const googleClientId = ref<string>('')

  const isInitiated = computed(() => {
    return isAppInitiated.value && kurukkuKuri.value !== null
  })

  const isLogedIn = computed(() => {
    return isUserloggedIn.value && robotChecked.value && username.value !== null
  })

  /**
   * Initializes the authentication response.
   * @param  initAuthResponse - The authentication response to initialize.
   */
  const initAuth = (initAuthResponse: IinitAuthResponse) => {
    setKurukkuKuri(initAuthResponse.kurukkuKuri as string)

    isUserloggedIn.value = initAuthResponse.isUserLoggedIn
    robotChecked.value = initAuthResponse.robotChecked

    if (initAuthResponse.isUserLoggedIn) {
      if (initAuthResponse.username && initAuthResponse.email)
        login(initAuthResponse.username, initAuthResponse.email)
      if (initAuthResponse.notificationCount)
        setNotificationCount(initAuthResponse.notificationCount)
    }
    setAppInitiated(true)
  }
  /**
   * Handles the disconnection event.
   */
  const onDisconnected = () => {
    setKurukkuKuri(null)
    setAppInitiated(false)
    logout()
  }
  /**
   * Sets the kurukku kuri.
   * @param value - The value to set.
   */
  const setKurukkuKuri = (value: string | null) => {
    kurukkuKuri.value = value
  }
  /**
   * Sets the app initiated.
   * @param value - The value to set.
   */
  const setAppInitiated = (value: boolean) => {
    isAppInitiated.value = value
  }
  /**
   *
   * @param name - The name of the user to login.
   * @param email email - Email of user to login
   */
  const login = (name: string, email?: string) => {
    isUserloggedIn.value = true
    username.value = name
    robotChecked.value = true
    lastRobotCheckedTime.value = Date.now()

    if (email) userEmail.value = email
  }
  /**
   * Logs out the user.
   */
  const logout = () => {
    isUserloggedIn.value = false
    username.value = null
    userEmail.value = null
    robotChecked.value = false
    lastRobotCheckedTime.value = null
    notificationCount.value = null
  }

  /**
   * Sets the notification count.
   * @param count - The notification count to set.
   */
  const setNotificationCount = (count: number) => {
    notificationCount.value = count
  }

  /**
   * Sets the robot check done.
   */
  const robotCheckDone = () => {
    robotChecked.value = true
    lastRobotCheckedTime.value = Date.now()
  }
  /**
   * Clears the robot check.
   */
  const clearRobotCheck = () => {
    robotChecked.value = false
    lastRobotCheckedTime.value = null
  }

  /**
   * Sets google_client_id for  one tap login
   * @param  id - The notification count to set.
   */
  const setGoogleClientId = (id: string) => {
    googleClientId.value = id
  }

  return {
    isInitiated,
    setAppInitiated,
    kurukkuKuri,
    setKurukkuKuri,
    isLogedIn,
    robotChecked,
    userEmail,
    robotCheckDone,
    clearRobotCheck,
    lastRobotCheckedTime,
    username,
    isUserloggedIn,
    initAuth,
    onDisconnected,
    login,
    notificationCount,
    setNotificationCount,
    logout,
    googleClientId,
    setGoogleClientId
  }
})
