import type { IDashboardProject } from '@/services/dashboard.project.service'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useDashboardProjectStore = defineStore('dashboardproject', () => {
  const projectId = ref<string>()
  const projectLang = ref<string>()
  const isAdvanced = ref<Boolean>()
  const projectName = ref<string>()
  const filteredProjects = ref<IDashboardProject[]>([] as IDashboardProject[])
  const deleteProjectId = ref<string>()
  const showFilter = ref<Boolean>(false)
  const deleteProjectLang = ref<string>()
  const httpErrorTimeOut = ref<number | null>(null)
  const httpError = ref<string>('')
  const httpSuccessTimeOut = ref<number | null>(null)
  const httpSuccess = ref<string>('')
  const showDeleteConfirm = ref(false)
  const showAskForDelete = ref(false)
  /**
   * @param id , project id selected
   * @param language gets the icon for the language
   * @param isMultifile flag to determine the advanded IDE projects
   * @param name name of the selected projects
   */
  const setProjectDetails = (id: string, language: string, isMultifile: boolean, name: string) => {
    projectId.value = id
    projectLang.value = language
    isAdvanced.value = isMultifile
    projectName.value = name
  }
  /**
   * @param value true or false
   */
  const setShowFilter = (value: boolean) => {
    showFilter.value = value
  }

  return {
    projectId,
    projectLang,
    isAdvanced,
    projectName,
    showFilter,
    setShowFilter,
    setProjectDetails,
    filteredProjects,
    httpErrorTimeOut,
    httpError,
    httpSuccessTimeOut,
    httpSuccess,
    deleteProjectId,
    showDeleteConfirm,
    showAskForDelete,
    deleteProjectLang
  }
})
