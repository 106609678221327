<script setup lang="ts">
import CaseStudyDemoCTA, {
  type IDemoCTATextData
} from '@/components/shared/casestudy/CaseStudyDemoCTA.vue'

const sectionData: IDemoCTATextData = {
  textHeading: "Demonstrate your product's capabilities with ease",
  textSubheading: "Showcase your software library's power and enhance user experience",
  getStartedLink: '/pricing/api',
  learnMoreLink: '/solutions/software-library-demostration'
}
</script>

<template>
  <CaseStudyDemoCTA :sectionData="sectionData" />
</template>
