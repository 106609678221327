<script setup lang="ts">
import type { PropType } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { loginThenRedirect } from '@/services/subscription.service'
import {
  SUBSCRIPTION_PLAN_NAME,
  SUBSCRIPTION_PRODUCT
} from '@/components/shared/subscription/enum/subscription.enum'

export interface IActionButton {
  buttonText: string
  link?: string
  href?: string
  modal?: string
  product: SUBSCRIPTION_PRODUCT
  disabled?: boolean
}

export interface IPricingCard {
  tierName: string
  pricingDollar: string
  pricingPeriod?: string
  description: string
  idePluginIncludes?: string[]
  teachAndAssessIncludes?: string[]
  customIncludes?: string[]
  isMostPopular?: boolean
  isCustom?: boolean
  freeButton?: IActionButton
  proButton?: IActionButton
  contactButton?: IActionButton
}

const props = defineProps({
  cardData: {
    type: Object as PropType<IPricingCard>,
    required: true
  },
  isUserSubscribedToPlatform: {
    type: Boolean,
    required: false
  }
})

/**
 * @param button the IActionButton object passed through
 * @returns the text to show
 */
function getButtonText(button: IActionButton) {
  if (props.isUserSubscribedToPlatform) {
    if (button.disabled) {
      return currentPlanText
    } else {
      return changePlanText
    }
  } else {
    return button.buttonText
  }
}

/**
 * If logged-in -> link or modal, otherwise login modal
 * @param button the button object clicked
 * @param planName the name of plan selected
 */
function buttonAction(button: any, planName?: SUBSCRIPTION_PLAN_NAME) {
  loginThenRedirect(button, planName)
}

const currentPlanText = 'Current Plan'
const changePlanText = 'Change Plan'
</script>

<template>
  <div
    class="col-span-6 flex flex-col justify-stretch rounded-lg bg-background-tertiary-dark p-4 sm:col-span-3 lg:col-span-2"
    :class="props.cardData.isMostPopular ? 'border border-2 border-text-brand' : ''"
  >
    <div class="relative">
      <span class="text-brand mb-2 block text-sm">{{ props.cardData.tierName }}</span>
      <span
        v-if="props.cardData.isMostPopular"
        class="text-brand absolute right-0 top-0 rounded-full bg-white px-2 py-1 text-xs"
        >🔥 Most Popular</span
      >
      <div class="mb-2 text-sm">
        <span class="me-2 text-3xl font-bold text-white">{{ props.cardData.pricingDollar }}</span>
        <span v-if="props.cardData.pricingPeriod" class="text-gray-500">
          {{ `/ ${props.cardData.pricingPeriod}` }}
        </span>
      </div>
      <p class="text-sm text-gray-500">
        {{ props.cardData.description }}
      </p>
      <hr class="my-5" />
    </div>
    <div class="grow">
      <ul class="mb-6" v-if="props.cardData.idePluginIncludes">
        <span class="mb-2 block font-bold text-white">IDE and Plugins</span>
        <li
          v-for="(item, idx) in props.cardData.idePluginIncludes"
          :key="idx"
          class="mb-3 flex text-sm text-gray-100"
        >
          <FontAwesomeIcon
            icon="fa-check-circle"
            class="me-2 h-4 w-4 text-green-500"
          ></FontAwesomeIcon>
          {{ item }}
        </li>
      </ul>

      <ul v-if="props.cardData.teachAndAssessIncludes">
        <span class="mb-2 block font-bold text-white">Teach and Assess Platform</span>
        <li
          v-for="(item, idx) in props.cardData.teachAndAssessIncludes"
          :key="idx"
          class="mb-3 flex text-sm text-gray-100"
        >
          <FontAwesomeIcon
            icon="fa-check-circle"
            class="me-2 h-4 w-4 text-green-500"
          ></FontAwesomeIcon>
          {{ item }}
        </li>
      </ul>

      <ul v-if="props.cardData.customIncludes">
        <li
          v-for="(item, idx) in props.cardData.customIncludes"
          :key="idx"
          class="mb-3 flex text-sm text-gray-100"
        >
          <FontAwesomeIcon
            icon="fa-check-circle"
            class="me-2 h-4 w-4 text-green-500"
          ></FontAwesomeIcon>
          {{ item }}
        </li>
      </ul>
    </div>

    <!-- Button -->
    <div class="">
      <!-- Pro Button -->
      <button
        v-if="props.cardData.proButton"
        class="btn btn-primary w-full"
        @click="buttonAction(props.cardData.proButton, SUBSCRIPTION_PLAN_NAME.TEAM)"
        :disabled="props.cardData.proButton.disabled"
      >
        {{ getButtonText(props.cardData.proButton)
        }}<FontAwesomeIcon
          icon="fa-arrow-right"
          class="ms-1"
          v-if="!props.cardData.proButton.disabled"
        />
      </button>

      <!-- Contact Button -->
      <a
        type="button"
        v-if="props.cardData.contactButton"
        class="btn btn-secondary w-full"
        href="/contact-us"
        :disabled="props.cardData.contactButton.disabled"
      >
        Contact Us
      </a>

      <!-- Free Button -->
      <button
        v-if="props.cardData.freeButton"
        class="btn btn-secondary w-full"
        @click="buttonAction(props.cardData.freeButton, SUBSCRIPTION_PLAN_NAME.FREE)"
        :disabled="props.cardData.freeButton.disabled"
      >
        {{ getButtonText(props.cardData.freeButton) }}
      </button>
    </div>
  </div>
</template>
