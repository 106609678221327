<script setup lang="ts">
import { ref, type PropType } from 'vue'
import DoodleMessage from '@/components/utils/DoodleMessage.vue'
import { delay } from 'lodash'
import { cancelApiSubscription, cancelBundleSubscription } from '@/services/subscription.service'
import { SUBSCRIPTION_TYPE_ENUM } from '@/components/shared/subscription/enum/subscription.enum'
import utilModelsService from '@/services/util.models.service'
import { SUBSCRIPTION_MODAL } from '@/utils/models'

const props = defineProps({
  subscriptionType: {
    type: String as PropType<SUBSCRIPTION_TYPE_ENUM>,
    required: true
  },
  tabProperties: {
    required: true,
    type: Object as PropType<any>
  }
})

const emit = defineEmits(['cancelPlan'])
const completed = ref(false)
const showCancel = ref(true)
const clickDisabled = ref(false)

/**
 *
 */
async function cancelSubscription() {
  clickDisabled.value = true

  if (props.subscriptionType == SUBSCRIPTION_TYPE_ENUM.BUNDLE) {
    await cancelBundleSubscription()
  } else {
    await cancelApiSubscription()
  }

  completed.value = true
  showCancel.value = false

  delay(() => {
    completed.value = false
    emit('cancelPlan')
    utilModelsService.closeModal(SUBSCRIPTION_MODAL.CANCEL)
  }, 1000)
  clickDisabled.value = false
}
</script>

<template>
  <div>
    <div class="section-primary">
      <DoodleMessage
        class="text-gray-600 dark:text-gray-200"
        :message="'Subscription Cancelled. Thanks for Using JDoodle.com Compiler APIs.'"
        message-class="text-red-600"
        v-show="completed"
      />
      <div v-show="showCancel">
        <h3 class="heading-medium mb-14 mt-10 font-semibold">
          Are you sure want to cancel your subscription?
        </h3>
        <div class="text-left text-base">
          <div v-if="props.tabProperties.clientPlan == 'Free'">
            <span class="underline">Note:</span> You are on Free Plan, if you proceed, your
            subscription will be cancelled immediately.
          </div>
          <div v-else>
            <p class="leading-20">
              Cancellation is effective only from the end of your current billing period. No refund
              will be provided.
              <br />You can use this subscription till the end of your current billing cycle.
              <br />You can reactivate your subscription until the end of your current billing
              period
            </p>
          </div>

          <p class="my-8">
            Read
            <a
              href="https://docs.jdoodle.com/integrating-compiler-ide-to-your-application/compiler-api"
              target="_blank"
              class="text-blue-500"
              >API FAQ</a
            >
            for more details
          </p>
        </div>

        <button
          class="btn btn-primary mt-12 w-fit text-base"
          @click="cancelSubscription"
          :disabled="clickDisabled"
        >
          Cancel Subscription
        </button>
      </div>
    </div>
  </div>
</template>
