<script setup lang="ts">
import BaseInput from '@/components/forms/BaseInput.vue'
import authService, { type IChangePasswordRequest } from '@/services/auth.service'
import { useField, useForm } from 'vee-validate'
import { computed, onMounted, ref, watch, watchEffect } from 'vue'
import { string } from 'yup'

const httpErrorTimeOut = ref<number | null>(null)
const httpError = ref<string>('')
const httpSuccessTimeOut = ref<number | null>(null)
const httpSuccess = ref<string>('')

const { errors, handleSubmit, resetForm } = useForm()

const { value: oldPassword, errorMessage: oldPasswordError } = useField(
  'oldPassword',
  string().required().min(5)
)
const { value: newPassword, errorMessage: newPasswordError } = useField(
  'newPassword',
  string().required().min(5)
)
const { value: confirmNewPassword, errorMessage: confirmNewPasswordError } = useField(
  'retypeNewPassword',
  string().required().min(5)
)

const passwordNotMatchError = ref<string>('')
const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0 && !passwordNotMatchError.value
})

const onSubmit = handleSubmit(async (values) => {
  const requestPayload: IChangePasswordRequest = {
    oldPassword: values.oldPassword,
    newPassword: values.newPassword,
    retypeNewPassword: values.retypeNewPassword
  }

  await authService
    .changePassword(requestPayload)
    .then((response: any) => {
      httpSuccess.value = response?.data?.message || 'Your password has been changed successfully.'
      httpError.value = ''
      resetForm()
      // close the modal
    })
    .catch((error: any) => {
      httpSuccess.value = ''
      httpError.value = error?.response?.data?.message
    })
})

onMounted(() => {
  watchEffect(() => {
    if (newPassword.value !== confirmNewPassword.value) {
      passwordNotMatchError.value = 'Password does not match'
    } else {
      passwordNotMatchError.value = ''
    }
  })
  watch(httpError, () => {
    if (httpError.value) {
      if (httpErrorTimeOut.value) clearTimeout(httpErrorTimeOut.value)
      httpErrorTimeOut.value = setTimeout(() => {
        httpError.value = ''
      }, 8000)
    }
  })
  watch(httpSuccess, () => {
    if (httpSuccess.value) {
      if (httpSuccessTimeOut.value) clearTimeout(httpSuccessTimeOut.value)
      httpSuccessTimeOut.value = setTimeout(() => {
        httpSuccess.value = ''
      }, 8000)
    }
  })
})
</script>

<template>
  <!-- form -->
  <form @submit.prevent="onSubmit">
    <BaseInput
      label="Old Password"
      :inputType="'password'"
      placeholder="*****"
      autocomplete="current-password"
      :error="oldPasswordError"
      v-model="oldPassword"
      :isLightGray="false"
    />
    <BaseInput
      label="New Password"
      :inputType="'password'"
      placeholder="*****"
      :error="newPasswordError"
      v-model="newPassword"
      :isLightGray="false"
    />
    <BaseInput
      label="Retype New Password"
      :inputType="'password'"
      placeholder="*****"
      :error="confirmNewPasswordError"
      v-model="confirmNewPassword"
      :isLightGray="false"
    />
    <p :class="['p-xsmall ', 'error', { hidden: !passwordNotMatchError }]">
      {{ passwordNotMatchError }}
    </p>
    <p :class="['p-xsmall ', 'error', { hidden: !httpError }]">
      {{ httpError }}
    </p>
    <p :class="['p-xsmall ', 'success', { hidden: !httpSuccess }]">
      {{ httpSuccess }}
    </p>

    <button :disabled="!canSubmit" class="btn-primary btn-rounded-md w-fit" type="submit">
      Update Password
      <FontAwesomeIcon icon="fa-arrow-right" class="ms-1" />
    </button>
  </form>
</template>
