<script setup lang="ts">
import { RouterLink } from 'vue-router'

import codeImg from '@/assets/images/home/woman.png'
import integrateImg from '@/assets/images/home/man.png'
import guruImg from '@/assets/images/home/man_woman.png'

interface ILinkItem {
  image: {
    src: string
    alt: string
  }
  icon: string
  title: string
  description: string
  button?: {
    title: string
    hs: string
  }
  route?: {
    title: string
    path: string
  }
}

const LinkItems: ILinkItem[] = [
  {
    image: {
      src: codeImg,
      alt: 'code_image'
    },
    icon: 'code-online-compiler-ide',
    title: 'Code',
    description: 'Fast, easy-to-use, online compilers and editors, supporting 76+ languages.',
    route: {
      title: 'Start Coding',
      path: 'online-java-compiler'
    }
  },
  {
    image: {
      src: integrateImg,
      alt: 'integrate_image'
    },
    icon: 'arrows-turn-to-dots',
    title: 'Integrate',
    description: 'Execute code directly in your platform with powerful APIs and Plugins.',
    route: {
      title: 'Connect',
      path: 'intergrate-online-ide-compiler-api-plugins'
    }
  },
  {
    image: {
      src: guruImg,
      alt: 'guru_image'
    },
    icon: 'graduation-cap',
    title: 'Teach and Assess',
    description: 'Engage students with our innovative coding and assessment solutions.',
    route: {
      title: 'Explore',
      path: 'online-assessment-teaching'
    }
  }
]

defineExpose({
  LinkItems
})
</script>

<template>
  <div class="section-primary py-8 md:py-16">
    <div class="view-container">
      <div class="mb-8 text-center text-2xl font-bold">
        One platform for all your coding requirements
      </div>
      <div
        class="flex flex-col items-center justify-between gap-10 lg:flex-row lg:items-stretch lg:gap-10"
      >
        <div v-for="(links, index) of LinkItems" :key="index">
          <div
            class="flex flex-col items-start justify-between gap-7 border-t border-t-text-brand px-2 py-5 dark:border-t-white"
          >
            <div class="flex max-w-md flex-col justify-stretch gap-6">
              <div>
                <FontAwesomeIcon :icon="links.icon" class="text-brand mb-2 h-6 w-6" />

                <div class="text-start text-xl font-semibold">
                  {{ links.title }}
                </div>
              </div>

              <p class="text-secondary text-start text-sm">
                {{ links.description }}
              </p>
            </div>

            <RouterLink
              v-if="links.route"
              :to="`/${links.route.path}`"
              class="btn btn-secondary border-0.5 w-fit border px-6 py-2 text-sm"
            >
              {{ links.route.title }}
            </RouterLink>
            <!-- For Modals -->
            <button
              v-if="links.button"
              type="button"
              :data-hs-overlay="`#${links.button.hs}`"
              class="btn btn-secondary w-fit rounded-md border"
            >
              {{ links.button.title }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
