<script setup lang="ts">
import { computed } from 'vue'
import { useIdeStore } from '@/stores/ide.store'

const ideStore = useIdeStore()

const showProjectName = computed(() => {
  return ideStore.project && ideStore.project?.name
})
const projectName = computed(() => {
  return ideStore.project?.name
})
</script>

<template>
  <h1 v-if="showProjectName" class="text-md text-start">Project Name: {{ projectName }}</h1>
</template>
