<script setup lang="ts">
import ModelLayout from '@/layouts/ModelLayout.vue'
import { TEACHMODALHS } from '@/utils/models'
import { HttpStatusCode } from 'axios'
import { reopenAssignment } from '@/services/teach.service'
import { useTeachStore } from '@/stores/teach.store'
import { storeToRefs } from 'pinia'
import { TEACH_STUDENT_ASSIGNMENT_STATUS } from '@/components/guru/enums/teach.enum'
import router from '@/router'

const teachStore = useTeachStore()
const { studentUnderEvaluation } = storeToRefs(teachStore)

const props = defineProps({
  instituteCode: {
    type: String,
    required: true
  },
  assignmentId: {
    type: Number,
    required: true
  },
  reload: {
    type: Function,
    required: true
  }
})

//Static data
const modalTitle = 'Open Assignment for Re-submission'
const message = 'Do you want to open assignment for re-submission?'

/**
 *
 */
const handleSubmit = async () => {
  const res = await reopenAssignment(
    props.instituteCode,
    props.assignmentId,
    studentUnderEvaluation.value?.email,
    TEACH_STUDENT_ASSIGNMENT_STATUS.IN_PROGRESS
  )

  if (res.status == HttpStatusCode.Ok) {
    router.go(0)
  }
}
</script>

<template>
  <ModelLayout
    :title="modalTitle"
    :hs="TEACHMODALHS.REOPEN_SUBMISSION_CONFIRMATION"
    :loadDataWhenClose="false"
  >
    <p class="my-5 break-normal text-center text-sm">{{ message }}</p>

    <div class="mt-8 flex justify-center gap-5">
      <button class="btn-primary rounded-md px-5 py-2" @click="handleSubmit" data-hs-overlay-close>
        Yes
      </button>

      <button class="btn-secondary rounded-md px-5 py-2" data-hs-overlay-close>No</button>
    </div>
  </ModelLayout>
</template>
