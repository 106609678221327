<script setup lang="ts">
import { ref, computed } from 'vue'
import { useIdeStore } from '@/stores/ide.store'
import { FILE_MESSAGE_TYPE } from '@/utils/ide'
import uploadService from '@/services/ide/upload.service'

const ideStore = useIdeStore()
const uploadInput = ref<HTMLElement | null>(null)

const isHtml = computed(() => {
  return ideStore.isHtml
})
const isUploadInProgress = computed(() => {
  return ideStore.isUploadInProgress
})
const isFileMessage = computed(() => {
  return ideStore.isFileUploadMessages !== null
})
const fileMessageType = computed(() => {
  return ideStore.isFileUploadMessages?.type || FILE_MESSAGE_TYPE.INFO
})
const fileMessage = computed(() => {
  return ideStore.isFileUploadMessages?.message || ''
})
const showInputFiles = computed(() => {
  return ideStore.isInputFiles && ideStore.isInputFiles.length > 0
})
const inputFiles = computed(() => {
  return ideStore.isInputFiles
})
const showOutputFiles = computed(() => {
  return ideStore.isOutputFiles && ideStore.isOutputFiles.length > 0
})

const outputFiles = computed(() => {
  return ideStore.isOutputFiles
})
const showFiles = computed(() => {
  return showInputFiles.value || showOutputFiles.value || isFileMessage.value
})
/**
 * Handles the upload click
 */
const handleUploadClick = () => {
  uploadInput?.value?.click()
}
/**
 * Gets the download url for the input file
 * @param file The file name
 * @returns The url
 */
const getUrlInput = (file: string) => {
  return `/api/projectSync/downloadItem?projectKey=1001&name=/${encodeURI(file)}&type=input`
}
/**
 * Gets the download url for the output file
 * @param file The file name
 * @returns The url
 */
const getUrlOutput = (file: string) => {
  return `/api/projectSync/downloadItem?projectKey=1001&name=${encodeURI(file)}&type=output`
}
/**
 * remove the file
 * @param file The file name
 */
const removeFile = (file: string) => {
  uploadService.removeFile(file)
}
</script>

<template>
  <div v-if="!isHtml">
    <div class="flex w-full flex-col items-center gap-2">
      <button
        v-if="!isUploadInProgress"
        class="w-full min-w-fit rounded-md px-2 py-1 text-sm"
        @click="handleUploadClick"
      >
        <span>
          <input
            v-show="false"
            ref="uploadInput"
            class="hidden"
            type="file"
            name="file"
            @change="uploadService.tryUploadFile($event)"
          />
          <FontAwesomeIcon icon="fa-upload" class="h-3 w-3" />
          Upload File
        </span>
      </button>
      <button
        v-if="isUploadInProgress"
        class="w-full min-w-fit rounded-md px-2 py-1 text-sm"
        @click="uploadService.cancelUpload"
      >
        <FontAwesomeIcon bounce icon="fa-stop" class="error h-3 w-3" />
        Cancel Upload
      </button>
      <p
        v-if="isFileMessage"
        :class="[
          'p-xsmall',
          {
            success: fileMessageType === FILE_MESSAGE_TYPE.SUCCESS,
            error: fileMessageType === FILE_MESSAGE_TYPE.ERROR
          }
        ]"
      >
        {{ fileMessage }}
      </p>
    </div>
  </div>
  <div v-if="showFiles">
    <div class="flex w-full flex-col justify-start gap-5 align-middle">
      <div v-if="showInputFiles">
        <p class="heading-xsmall text-sm">Input Files</p>
        <div class="max-h-24 overflow-auto">
          <ul>
            <li v-for="file in inputFiles" :key="file" class="flex items-baseline gap-1">
              <button @click="removeFile(file)">
                <FontAwesomeIcon icon="fa-trash-can" class="h-3 w-3" />
              </button>
              <a :href="getUrlInput(file)" target="_blank" class="link-secondary p-xsmall"
                >/uploads/{{ file }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div v-if="showOutputFiles">
        <p class="heading-xsmall text-sm">Files Generated</p>
        <div class="max-h-24 overflow-auto">
          <ul>
            <li v-for="file in outputFiles" :key="file" class="flex items-baseline gap-1">
              <a :href="getUrlOutput(file)" target="_blank" class="link-secondary p-xsmall">{{
                file
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
