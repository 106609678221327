<script setup lang="ts">
import CaseStudyDemoCTA, {
  type IDemoCTATextData
} from '@/components/shared/casestudy/CaseStudyDemoCTA.vue'

const sectionData: IDemoCTATextData = {
  textHeading: 'Teaching coding and execute code in your LMS or course material',
  textSubheading: 'Create better learning experiences and improve learning outcomes.',
  getStartedLink: '/pricing/platform',
  learnMoreLink: '/solutions/education-institutions'
}
</script>

<template>
  <CaseStudyDemoCTA :sectionData="sectionData" />
</template>
