/* eslint-disable jsdoc/require-jsdoc */
import StartCodingPage from '@/components/code/StartCodingPage.vue'
import GuruInstitute from '@/components/guru/GuruInstitute.vue'
import AppLayout from '@/layouts/AppLayout.vue'
import GuestAppLayout from '@/layouts/GuestAppLayout.vue'
import dashboardRoutes from '@/router/dashboardRoutes'
import ideRoutes from '@/router/IdeRoutes'
import authService from '@/services/auth.service'
import { useAuthStore } from '@/stores/auth.store'
import { META } from '@/utils/meta'
import AboutView from '@/views/AboutView.vue'
import AdminView from '@/views/AdminView.vue'
import Blogs from '@/views/BlogsView.vue'
import CaseStudyiText from '@/views/casestudy/CaseStudyiText.vue'
import CaseStudyKansas from '@/views/casestudy/CaseStudyKansas.vue'
import CaseStudyKodezi from '@/views/casestudy/CaseStudyKodezi.vue'
import CaseStudyLinkGroup from '@/views/casestudy/CaseStudyLinkGroup.vue'
import CaseStudyOutlier from '@/views/casestudy/CaseStudyOutlier.vue'
import CodeView from '@/views/CodeView.vue'
import ContactUsView from '@/views/ContactUsView.vue'
import CustomPluginIDE from '@/views/CustomPluginIDE.vue'
import ConfigurePlugin from '@/views/dashboard/plugin/ConfigurePlugin.vue'
import EducationInstitutions from '@/views/EducationInstitutions.vue'
import EmbedIDE from '@/views/EmbedIDE.vue'
import GuruView from '@/views/GuruView.vue'
import HomeView from '@/views/HomeView.vue'
import HTMLIDE from '@/views/HTMLIDE.vue'
import IDE from '@/views/IDE.vue'
import IntegrateView from '@/views/IntegrateView.vue'
import NotFound from '@/views/NotFound.vue'
import PluginIDE from '@/views/PluginIDE.vue'
import PricingView from '@/views/PricingView.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import SolutionEducationView from '@/views/SolutionEducationView.vue'
import SolutionEduTechView from '@/views/SolutionEduTechView.vue'
import SolutionLibProviderView from '@/views/SolutionLibProviderView.vue'
import Terminal from '@/views/TerminalView.vue'
import Tutorials from '@/views/TutorialsView.vue'
import VerifyRegistration from '@/views/VerifyRegistration.vue'
import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
  type RouteRecordRaw
} from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: META.home
  },

  {
    path: '/code-online-compiler-ide',
    name: 'code-online-compiler-ide',
    component: CodeView,
    meta: META.code
  },
  {
    path: '/start-coding',
    name: 'start-coding',
    component: StartCodingPage,
    meta: META.code
  },
  {
    path: '/intergrate-online-ide-compiler-api-plugins',
    name: 'compilerApi',
    component: IntegrateView,
    meta: META.integrate
  },
  {
    path: '/online-assessment-teaching',
    name: 'online-assessment-teaching',
    component: GuruView,
    meta: META.teachassess,
    children: [
      {
        path: '',
        name: 'guruHome',
        component: () => import('@/components/guru/GuruHome.vue')
      },
      {
        path: 'ins/:instituteCode',
        name: 'guruInstitute',
        component: GuruInstitute,
        children: [
          {
            path: '',
            name: 'guruInstitute-home',
            component: () => import('@/components/guru/institute/InstituteHomePage.vue')
          },
          {
            path: 'assignments',
            name: 'guruInstitute-assignments-home',
            component: () =>
              import('@/components/guru/institute/assignments/AssignmentsHomePage.vue')
          },
          {
            path: 'users',
            name: 'guruInstitute-users-home',
            component: () => import('@/components/guru/institute/users/UsersHomePage.vue')
          },
          {
            path: 'assignment/:assignmentId',
            name: 'guruInstitute-assignment',
            component: () => import('@/components/guru/institute/assignments/AssignmentPage.vue')
          },
          {
            path: 'assignment/:assignmentId/:mode',
            name: 'guruInstitute-assignment-attend-or-preview',
            component: () =>
              import('@/components/guru/institute/assignments/AssignmentAttendPreviewPage.vue')
          },
          {
            path: 'sgs',
            name: 'guruInstitute-studentGroups-home',
            component: () =>
              import('@/components/guru/institute/studentGroups/StudentGroupsHomePage.vue')
          },
          {
            path: 'studentGroup/:insStudentGroupId',
            name: 'guruInstitute-studentGroup',
            component: () =>
              import('@/components/guru/institute/studentGroups/StudentGroupPage.vue')
          }
        ]
      }
    ]
  },
  ...ideRoutes,
  ...dashboardRoutes,
  {
    path: '/a/:shareId',
    name: 'share',
    component: IDE,
    props: (route: RouteLocationNormalized) => ({
      shareId: route.params.shareId,
      byPost: route.query.byPost
    }),
    meta: Object.assign({}, META.default, {
      isShared: true,
      isInstantShare: false
    })
  },
  {
    path: '/ia/:shareId',
    name: 'ishare',
    component: IDE,
    props: (route: RouteLocationNormalized) => ({
      shareId: route.params.shareId,
      byPost: route.query.byPost
    }),
    meta: Object.assign({}, META.default, {
      isShared: true,
      isInstantShare: true
    })
  },
  {
    path: '/configure-plugin/:pluginId',
    name: 'dashboard-configure-plugin',
    component: ConfigurePlugin,
    props: (route: RouteLocationNormalized) => ({
      pluginId: route.params.pluginId
    })
  },
  {
    path: '/html-css-javascript-online-editor/',
    name: 'html',
    component: HTMLIDE,
    meta: META.html
  },
  {
    path: '/h/:shareId',
    name: 'htmlShare',
    component: HTMLIDE,
    props: (route: RouteLocationNormalized) => ({
      shareId: route.params.shareId
    }),
    meta: Object.assign({}, META.html, {
      isShared: true
    })
  },
  {
    path: '/online-mysql-terminal',
    name: 'mysqlTerminal',
    component: Terminal,
    meta: META.mysql
  },
  {
    path: '/online-mongodb-terminal',
    name: 'mongoTerminal',
    component: Terminal,
    meta: META.mongo
  },
  {
    path: '/education-institutions',
    name: 'education-institutions',
    component: EducationInstitutions,
    meta: META.default
  },

  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/TermsView.vue'),
    meta: META.default
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: PricingView,
    meta: META.pricing,
    children: [
      {
        path: ':tab',
        name: 'pricing-tab',
        component: PricingView
      }
    ]
  },
  {
    path: '/blog',
    name: 'blog',
    component: Blogs,
    meta: META.blogs
  },
  {
    path: '/tutorials',
    name: 'tutorials',
    component: Tutorials,
    meta: META.default
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: AboutView,
    meta: META.aboutus
  },
  {
    path: '/compiler-ide-plugin/',
    name: 'compilerIdePlugin',
    component: () => import('@/views/PluginView.vue'),
    meta: META.api
  },

  {
    path: '/register/verifyRegistration',
    name: 'verifyRegistration',
    component: VerifyRegistration,
    // eslint-disable-next-line
    props: (route: RouteLocationNormalized) => ({ token: route.query.t }),
    meta: META.default
  },
  {
    path: '/register/resetPassword',
    name: 'resetPassword',
    component: ResetPassword,
    // eslint-disable-next-line
    props: (route: RouteLocationNormalized) => ({ token: route.query.t }),
    meta: META.default
  },

  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    meta: META.default
  },
  {
    path: '/solutions/education-institutions',
    name: 'educationSolution',
    component: SolutionEducationView,
    meta: META.educators
  },
  {
    path: '/solutions/education-technology',
    name: 'eduTechSolution',
    component: SolutionEduTechView,
    meta: META.edutech
  },
  {
    path: '/solutions/software-library-demostration',
    name: 'libProviderSolution',
    component: SolutionLibProviderView,
    meta: META.libprovider
  },
  {
    path: '/case-study/kansas',
    name: 'casestudyKansas',
    component: CaseStudyKansas,
    meta: META.default
  },
  {
    path: '/case-study/itext',
    name: 'casestudyiText',
    component: CaseStudyiText,
    meta: META.default
  },
  {
    path: '/case-study/kodezi',
    name: 'caseStudyKodezi',
    component: CaseStudyKodezi,
    meta: META.default
  },
  {
    path: '/case-study/linkgroup',
    name: 'caseStudyLinkGroup',
    component: CaseStudyLinkGroup,
    meta: META.default
  },
  {
    path: '/case-study/outlier',
    name: 'caseStudyOutlier',
    component: CaseStudyOutlier,
    meta: META.default
  },

  {
    path: '/contact-us',
    name: 'contactus',
    component: ContactUsView,
    meta: META.contactus
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: AppLayout,
      children: routes
    },
    {
      path: '/langs-and-versions-table/',
      name: 'langsAndVersionsTable',
      component: () => import('@/views/LangAndVersionsTable.vue')
    },
    {
      path: '/embed',
      name: 'embed',
      component: GuestAppLayout,
      children: [
        {
          path: '/embed/v0/:shareId',
          name: 'embedIDE',
          component: EmbedIDE,
          // eslint-disable-next-line
          props: (route: RouteLocationNormalized) => ({
            shareId: route.params.shareId,
            set_args: route.query.set_args
          }),
          meta: { isInstantShare: false }
        }
      ]
    },
    {
      path: '/iembed',
      name: 'iembed',
      component: GuestAppLayout,
      children: [
        {
          path: '/iembed/v0/:shareId',
          name: 'iembedIde',
          component: EmbedIDE,
          // eslint-disable-next-line
          props: (route: RouteLocationNormalized) => ({
            shareId: route.params.shareId,
            set_args: route.query.set_args
          }),
          meta: { isInstantShare: true }
        }
      ]
    },
    {
      path: '/plugin',
      name: 'ideplugin',
      component: GuestAppLayout,
      children: [
        {
          path: '/plugin',
          name: 'plugin',
          component: PluginIDE
        },
        {
          path: '/plugin/v0/:clientkey/:customkey',
          name: 'customPlugin',
          component: CustomPluginIDE,
          props: (route: RouteLocationNormalized) => ({
            clientkey: route.params.clientkey,
            customkey: route.params.customkey
          })
        }
      ]
    },
    {
      path: '/mobile-app/',
      name: 'mobile',
      meta: META.default,
      // eslint-disable-next-line
      component: () => import('@/views/mobile/MobileApp.vue'),
      children: [
        {
          path: '/mobile-app/login/:token',
          name: 'mobileLogin',
          component: () => import('@/views/mobile/MobileLogin.vue')
        },
        {
          path: '/mobile-app/login-success/:token',
          name: 'mobileLoginSuccess',
          component: () => import('@/views/mobile/MobileLoginSuccess.vue')
        },
        {
          path: '/mobile-app/links/post-login/',
          name: 'mobilePostLogin',
          component: () => import('@/views/mobile/MobileApp.vue')
        }
      ]
    },

    {
      path: '/:pathMatch(.*)*',
      component: AppLayout,
      children: [
        {
          path: '/:pathMatch(.*)*',
          name: 'notFound',
          component: NotFound,
          meta: META.default
        }
      ]
    }
  ],
  scrollBehavior() {
    return { top: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.fullPath.match(new RegExp('/dashboard'))) {
    await authService.initAuth().then(() => {
      if (useAuthStore().isLogedIn) return next()
      else next({ name: 'home' })
    })
  } else {
    next()
  }
})

export default router
