<script setup lang="ts">
import AssignmentCreateEditComponent from '@/components/guru/dashboard/assignment/AssignmentCreateEditComponent.vue'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import { useTeachStore } from '@/stores/teach.store'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const teachStore = useTeachStore()
const breadcrumbStore = useBreadcrumbStore()
const route = useRoute()
const insCode = ref(route.params.insCode as string)

onMounted(() => {
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
})

const breadcrumbLinks = computed(() => {
  return [
    {
      href: '/',
      title: 'Home'
    },
    {
      href: '/dashboard',
      title: 'Dashboard'
    },
    {
      href: '/dashboard/institution',
      title: 'Virtual Institutions'
    },
    {
      href: `/dashboard/institution/${insCode.value}`,
      title: `${teachStore.currentAssociation?.name}`
    },
    {
      href: `/dashboard/institution/${insCode.value}/create-assignment`,
      title: 'Create Assignment'
    }
  ]
})

watch(
  () => teachStore.associations,
  () => {
    breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
  }
)
</script>

<template>
  <div class="h-full w-full">
    <DashboardCard title="Create Assignment" class="h-full w-full">
      <AssignmentCreateEditComponent />
    </DashboardCard>
  </div>
</template>
