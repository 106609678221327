<script setup lang="ts">
import AssignmentsComponent from '@/components/guru/dashboard/AssignmentsComponent.vue'
import YourInstituteAssociations from '@/components/guru/dashboard/YourInstituteAssociations.vue'
import InstituteDetailsComponent from '@/components/guru/dashboard/InstituteDetailsComponent.vue'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import { useTeachStore } from '@/stores/teach.store'
import { computed, watch } from 'vue'
import YourInvitations from '@/components/guru/dashboard/YourInvitations.vue'
import type ILink from '@/components/shared/interface/ILink'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'

const teachStore = useTeachStore()
const breadcrumbStore = useBreadcrumbStore()
const myInstitute = computed(() => teachStore.instituteOwn)

const breadcrumbLinks: ILink[] = [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  },
  {
    href: '/dashboard/institution',
    title: 'Virtual Institutions'
  }
]

breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks)

watch(
  () => myInstitute.value,
  () => {
    teachStore.getAndSetCurrentAssociation(myInstitute?.value?.code)
  }
)
</script>

<template>
  <div class="flex h-full flex-col gap-4 lg:grid lg:grid-cols-12 lg:grid-rows-3">
    <DashboardCard
      title="My Assignments as a Student"
      class="col-span-7 row-span-2"
      :link="{ url: '/dashboard/institution/assignments', name: 'See More' }"
    >
      <AssignmentsComponent :display-count="4" :showAllAsStudent="true" />
    </DashboardCard>

    <DashboardCard
      title="My Associations"
      :link="{ url: '/dashboard/institution/associations', name: 'See More' }"
      class="col-span-5 row-span-2"
    >
      <YourInstituteAssociations />
    </DashboardCard>

    <DashboardCard title="My Institute" class="col-span-7 row-span-1"
      ><InstituteDetailsComponent :show-button="true" :insCode="myInstitute?.code" />
    </DashboardCard>

    <DashboardCard
      title="Invitations"
      :link="{ url: '/dashboard/institution/invitations', name: 'See More' }"
      class="col-span-5 row-span-1"
      ><YourInvitations
    /></DashboardCard>
  </div>
</template>
