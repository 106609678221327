<script setup lang="ts">
import { IDECONSTANT } from '@/utils/ide'
import { computed, onMounted, watch } from 'vue'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import editorService from '@/services/ide/editor.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'

const ideStore = useIdeStore()
const pluginStore = usePluginStore()

const isPlugin = computed(() => {
  return pluginStore.isPlugin
})
const isCustomPlugin = computed(() => {
  return pluginStore.isCusomPlugin
})
const isEmbedded = computed(() => {
  return ideStore.isEmbedded
})
const isBlockly = computed(() => {
  return ideStore.isBlockly
})
const isAdvanced = computed(() => {
  return ideStore.isAdvanced
})
const isCodeEditor = computed(() => {
  return ideStore.codeEditor
})
const activeItem = computed(() => {
  return ideStore.activeItem
})
const isFullScreen = computed(() => {
  return ideStore.isFullScreen
})
const isBlocklyExpand = computed(() => {
  return ideStore.isblocklyExpand
})
const toogleBlocklyExpandIcon = computed(() => {
  return ideStore.isblocklyExpand ? 'fa-compress' : 'fa-expand'
})

const activeItemName = computed(() => {
  const activeItem = ideStore.activeItem

  if (activeItem?.parent === '/') {
    return '/' + activeItem?.name
  } else {
    return activeItem?.parent + '/' + activeItem?.name
  }
})
/**
 * Checks if the font size is selected
 * @param fontSize The font size to check
 * @returns True if the font size is selected
 */
const isFontsizeSelected = (fontSize: number) => {
  return ideStore.fontSize === fontSize
}
/**
 * Sets the font size
 * @param e The event
 */
const setFontSize = (e: Event) => {
  editorService.setFontSize(parseInt((e.target as HTMLSelectElement).value))
  if (isEmbedded.value) {
    ideStore.pymChild?.sendHeight()
  }
}
/**
 * Toggles the full screen
 */
const toogleBlocklyExpand = async () => {
  ideStore.setBlocklyExpand(!ideStore.isblocklyExpand)
  await new Promise((resolve) => setTimeout(resolve, 100))
  blocklyService.resizeBlockly()
  editorService.resizeCodeEditor()
}

onMounted(() => {
  if (isPlugin.value || isEmbedded.value) {
    editorService.setFontSize(12)
  } else {
    const tempSize = Number(localStorage.getItem(IDECONSTANT.LOCALSTORAGE_FONT_SIZE_NAME))
    if (tempSize < 11 || tempSize > 25) {
      localStorage.setItem(IDECONSTANT.LOCALSTORAGE_FONT_SIZE_NAME, '12')
      editorService.setFontSize(12)
    }
  }
  watch(isFullScreen, () => {
    editorService.resizeCodeEditor()
    blocklyService.resizeBlockly()
  })
})
</script>

<template>
  <div
    :class="[
      'flex',
      'min-h-fit',
      'flex-1',
      'flex-col',
      'items-stretch',
      {
        'min-h-[384px]': isCustomPlugin
      }
    ]"
  >
    <div class="flex items-center justify-between">
      <p v-if="activeItem && isAdvanced" class="p-small">{{ activeItemName }}</p>
      <div class="flex h-fit w-full items-center justify-end gap-5">
        <div v-if="!isPlugin" class="flex items-center gap-1 print:hidden">
          <label for="fontSizeSelect" class="p-xsmall block">Font Size:</label>
          <select
            id="fontSizeSelect"
            class="section-primary p-xsmall block h-6 w-fit rounded-md"
            @change="setFontSize($event)"
          >
            <option
              v-for="fontSize in Array.from({ length: 15 }, (_, i) => i + 11)"
              :key="fontSize"
              :selected="isFontsizeSelected(fontSize)"
              :value="fontSize"
            >
              {{ fontSize }}px
            </option>
          </select>
        </div>
        <button
          v-if="isBlockly && !isCustomPlugin"
          class="hidden w-fit min-w-fit print:hidden lg:block"
          @click="toogleBlocklyExpand"
        >
          <FontAwesomeIcon :icon="toogleBlocklyExpandIcon" class="h-4 w-4" />
        </button>
      </div>
    </div>
    <div
      :class="[
        'flex',
        'flex-1',
        'gap-5',
        'lg:flex-row',
        {
          'lg:flex-col': isBlockly && isBlocklyExpand && !isCustomPlugin
        },
        {
          'flex-row': isBlockly && isCustomPlugin
        },
        {
          'flex-col': isBlockly && !isCustomPlugin
        }
      ]"
    >
      <div
        v-if="isBlockly"
        :id="IDECONSTANT.BLOCKLY_EDITOR"
        :class="[
          'print:w-flex-1 z-0 h-96 w-full min-w-fit text-black print:border print:p-1',
          {
            'h-full': isCustomPlugin
          }
        ]"
      ></div>
      <div
        v-show="isCodeEditor"
        id="ideCodeEditor"
        :class="[
          'w-full',
          'print:border',
          'print:p-1',
          'lg:min-w-fit',
          {
            'min-w-fit': isCustomPlugin
          },
          {
            'min-w-full': !isCustomPlugin
          },
          {
            'flex-1': !isBlockly
          }
        ]"
      >
        <div
          :id="IDECONSTANT.CODE_EDITOR"
          :class="['editor', 'block', 'min-w-full', 'rounded-lg', 'xl:overflow-scroll']"
        />
      </div>
    </div>
  </div>
</template>
