import { SelectedLanguageObject } from '@/components/guru/class/SelectedLanguageObject'
import type { IQuestionType } from '@/components/guru/interface/IQuestionType'
import type { ITestCase } from '@/components/guru/interface/ITestCase'
/**
 * Creates default form object with default values, and assigned different form types
 */
export class Question {
  answer: any | null = null
  args: string = '' //Default ""
  assQuestionId: number | string = '' //Default ""??
  autoCorrectNotes: string = '' //Default ""
  comment: null | string = null
  completed: boolean = false
  correctAnswerOption: string | number | null = null // "1" vs 1
  cpuTime: null | number = 0
  errorMessage: string = ''
  evaluated: boolean = false
  executionTime: null | number = null
  ideCodeChangeSignal: number | null = null
  inProgress: boolean = false
  isCodeExecuting: boolean = false
  isMarkedForReview: boolean = false
  language: SelectedLanguageObject | null = null //new SelectedLanguageObject(languagesItems[0])
  versionIndex: null | number = null
  mark: number | null = null
  markForCorrectAnswer: number | null = null //default?
  maxLength: number | null = null
  memory: number | null = 0 //default?
  minLength: null | number = null
  negativeMark: null | number = null
  openTrySection: boolean = false
  optionLevelMarking: boolean = false
  options: any[] = []
  output: string = ''
  question: string = ''
  questionType: IQuestionType | null = null
  stdin: string = ''
  testCases: ITestCase[] = []

  /**
   * @param formObject Various types of question form data
   * @param questionType questionType
   */
  constructor(formObject: any, questionType: IQuestionType) {
    Object.assign(this, formObject)
    this.questionType = questionType
  }
}
