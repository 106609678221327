<script setup lang="ts">
declare global {
  interface Window {
    TogetherJS: any
  }
}
import { onBeforeMount, onMounted, onBeforeUnmount, watch, computed } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { useRoute } from 'vue-router'
import { type IMeta } from '@/utils/meta'
import ideService from '@/services/ide/ide.service'
import shareService from '@/services/ide/settings/share.service'
import editorService from '@/services/ide/editor.service'
import htmlEditorService from '@/services/ide/languages/html/editor.service'
import historyService from '@/services/ide/settings/history.service'
import utilModelsService from '@/services/util.models.service'
import loadScriptInBody from '@/services/loadScriptInBody.service'

import { useDark } from '@vueuse/core'
import { useIdeStore } from '@/stores/ide.store'

import SettingsModel from '@/components/ide/ide/settings/SettingsModel.vue'
import ClearProjectModel from '@/components/ide/ide/settings/ClearProjectModel.vue'
import NotLoginModel from '@/components/ide/ide/settings/NotLoginModel.vue'
import MyProjectModel from '@/components/ide/ide/settings/MyProjectModel.vue'
import ExecurteHistoryModel from '@/components/ide/ide/settings/ExecurteHistoryModel.vue'
import SaveModel from '@/components/ide/ide/settings/SaveModel.vue'
import DownloadModel from '@/components/ide/ide/settings/DownloadModel.vue'
import EditableShareModel from '@/components/ide/ide/settings/EditableShareModel.vue'

import Recaptcha from '@/components/shared/RecaptchaComp.vue'
import Header from '@/components/ide/ide/HeaderComp.vue'
import ProjectHeader from '@/components/ide/ide/ProjectHeaderComp.vue'
import Ide from '@/components/ide/ide/languages/html/IdeComp.vue'
import Settings from '@/components/ide/ide/SettingsComp.vue'
import Output from '@/components/ide/ide/languages/html/OutputComp.vue'

// import knowYourComp from '@/components/ide/knowYourComp.vue'
import IdeFeedbackComp from '@/components/ide/IdeFeedbackComp.vue'
import IdeFooterCardsComp from '@/components/ide/IdeFooterCardsComp.vue'

const props = defineProps({
  shareId: {
    type: String,
    required: false
  }
})
const route = useRoute()
const isDark = useDark()
const ideStore = useIdeStore()

const isShareNotFound = computed(() => {
  return ideStore.shareNotFound
})
const isShareFoundHttpError = computed(() => {
  return ideStore.shareNotFoundHttpError
})
/**
 * @description inject together js
 */
const injectTogetherJs = () => {
  if (!window.TogetherJS) {
    loadScriptInBody.loadScriptInBody('/assets/javascript/togetherjs-min.js')
  }
}
/**
 * @description eject together js
 */
const ejectTogetherJs = () => {
  if (window.TogetherJS) {
    loadScriptInBody.unloadScriptInBody('/assets/javascript/togetherjs-min.js')
  }
}
/**
 * @description init ide on mount and router change
 */
const initIde = async () => {
  ideStore.setIdeMeta(route.meta as IMeta)
  editorService.injectAce()
  if (props.shareId) {
    shareService.initHtmlOnRouterChange(props.shareId as string)
  } else {
    ideService.initHtmlOnRouterChange()
  }
}

onBeforeMount(() => {
  ideService.cleanIdeStore()
  ideStore.setRouteMeta(null as any)
  ideStore.setIdeMeta(null as any)
  injectTogetherJs()
  initIde()
  watch(isDark, () => {
    htmlEditorService.codeEditorsSetTheme()
  })
})
onMounted(() => {
  window.addEventListener('resize', () => {
    htmlEditorService.resizeCodeEditor()
  })
  window.onbeforeunload = function () {
    if (ideStore.isCodeUpdated) {
      return `Are you sure you want to move from this IDE?`
    }
  }
  historyService.checkStorage()
})
onBeforeRouteLeave((to, from, next) => {
  if (ideStore.isCodeUpdated) {
    utilModelsService
      .confirmPromise(
        `Are you sure you want to move from this IDE?`,
        'You will lose all your unsaved changes.'
      )
      .then(() => {
        next()
      })
      .catch(() => {
        next(false)
      })
  } else {
    next()
  }
})
onBeforeUnmount(() => {
  ejectTogetherJs()
  ideService.cleanIde()
  ideService.cleanIdeStore()
})
</script>

<template>
  <SettingsModel />
  <ClearProjectModel />
  <NotLoginModel />
  <MyProjectModel />
  <ExecurteHistoryModel />
  <SaveModel />
  <DownloadModel />
  <EditableShareModel />
  <Recaptcha />
  <div class="section-primary p-5">
    <div class="block">
      <Header />
    </div>
    <p
      v-if="isShareNotFound"
      :class="[
        'print:hidden, p-small ',
        'error',
        'text-center',
        'my-5',
        { hidden: !isShareNotFound }
      ]"
    >
      {{ isShareFoundHttpError || 'Share not found' }}
    </p>
    <div v-show="!isShareNotFound" class="section-tertiary flex flex-col gap-5 rounded-lg p-5">
      <ProjectHeader />
      <Ide />
      <Settings />
      <Output />
    </div>
  </div>
  <div class="view-container block">
    <IdeFooterCardsComp />
    <IdeFeedbackComp />
  </div>
</template>
