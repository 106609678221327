<script setup lang="ts">
import CaseStudyLogoAndIntro from '@/components/shared/casestudy/CaseStudyLogoAndIntro.vue'
import linkgroupLogo from '@/assets/images/casestudy/linkgroup/LinkGroup2.png'

const sectionData = {
  image: {
    src: linkgroupLogo,
    alt: '',
    copyright: '© martinedoucet from Getty Images Signature via Canva.com'
  },
  heading: 'LINK Edu Group',
  desc: 'LINK Group is a leading multinational company with more than 20 years of success in professional education and certification in the fields of information technology and contemporary business. The company offers courses that are designed to be engaging and interactive and uses the latest technology to deliver them.'
}
</script>

<template>
  <CaseStudyLogoAndIntro :sectionData="sectionData" />
</template>
