<script setup lang="ts">
import { computed } from 'vue'
import { useApiStore } from '@/stores/api.store'

const msg = computed(() => {
  return useApiStore().globalMsg
})
</script>

<template>
  <div
    v-if="msg"
    class="fixed right-4 top-4 z-[60] max-w-xs rounded-md border bg-white shadow-lg dark:border-gray-700 dark:bg-gray-800"
    role="alert"
  >
    <div class="flex p-4">
      <div class="flex-shrink-0">
        <svg
          class="mt-0.5 h-4 w-4 text-orange-500"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
          />
        </svg>
      </div>
      <div class="ml-3">
        <p class="text-sm text-gray-700 dark:text-gray-400">
          {{ msg }}
        </p>
      </div>
    </div>
  </div>
</template>
