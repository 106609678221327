<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import ModelLayout from '@/layouts/ModelLayout.vue'
import { DASHBOARD } from '@/utils/models'
import dashboardPluginService from '@/services/dashboard.plugin.service'
import utilModelsService from '@/services/util.models.service'

import BaseInput from '@/components/forms/BaseInput.vue'

import { useField, useForm } from 'vee-validate'
import { string } from 'yup'

const route = useRoute()
const router = useRouter()

const httpError = ref<string>('')
const httpSuccess = ref<string>('')
const httpInProgress = ref<boolean>(false)

const { errors, handleSubmit, resetForm } = useForm()
const { value: pluginName, errorMessage: pluginNameError } = useField(
  'pluginName',
  string().required()
)
const { value: pluginDescription, errorMessage: pluginDescriptionError } = useField(
  'pluginDescription',
  string().required()
)

const isHttpInProgress = computed(() => {
  return httpInProgress.value
})

const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0
})

const onSubmit = handleSubmit(async (values) => {
  httpInProgress.value = true
  httpError.value = ''
  httpSuccess.value = ''

  await dashboardPluginService
    .createPlugin(values.pluginName, values.pluginDescription)
    .then(
      async (response: {
        status: number
        data: {
          id: string
        }
      }) => {
        if (response?.status === 200) {
          httpSuccess.value = 'Plugin created successfully'
          resetForm()
          await utilModelsService.closeModal(DASHBOARD.CREATE_PLUGIN)
          const { id } = response.data
          router.push({
            name: 'dashboard-configure-plugin',
            params: {
              pluginId: id
            }
          })
        }
      }
    )
    .catch((err) => {
      httpError.value = err.response.data.message ? err.response.data.message : err.message
    })
    .finally(() => {
      httpInProgress.value = false
    })
})
onMounted(() => {
  watch(route, () => {
    httpError.value = ''
    httpSuccess.value = ''
  })
})
</script>

<template>
  <ModelLayout title="Create New Plugin" :hs="DASHBOARD.CREATE_PLUGIN">
    <p :class="['p-xsmall ', 'error', , { hidden: !httpError }]">
      {{ httpError }}
    </p>
    <p :class="['p-xsmall', 'success', { hidden: !httpSuccess }]">
      {{ httpSuccess }}
    </p>
    <p :class="['p-xsmall', , { hidden: !isHttpInProgress }]">
      JDoodle is creating your plugin. Please wait...
    </p>
    <form @submit.prevent="onSubmit">
      <BaseInput
        label="Plugin Name"
        placeholder="My plugin 1"
        :error="pluginNameError"
        v-model="pluginName"
      />
      <BaseInput
        label="Plugin Description"
        placeholder="Description"
        :error="pluginDescriptionError"
        v-model="pluginDescription"
      />
      <div class="flex w-full gap-2">
        <button
          :disabled="!canSubmit || isHttpInProgress"
          class="btn-secondary btn-rounded-md"
          type="submit"
        >
          Create Plugin
        </button>
      </div>
    </form>
  </ModelLayout>
</template>
