<script lang="ts" setup>
import { type PropType, ref, computed } from 'vue'
import { TEACHMODALHS } from '@/utils/models'
import { GURU_USER_TYPE, TEACH_SECTION } from '@/components/guru/enums/teach.enum'
import SearchAndAddUserModal from '@/components/guru/modals/SearchAndAddUserModal.vue'
import router from '@/router'
import { type IStudentInAssignment } from '@/components/guru/interface/IStudentInAssignment'
import { TEACH_STUDENT_ASSIGNMENT_STATUS } from '@/components/guru/enums/teach.enum'
import { useTeachStore } from '@/stores/teach.store'
import NotReadyToEvaluateModal from '@/components/guru/modals/errorModals/NotReadyToEvaluateModal.vue'
import StudentRemoveModal from '@/components/guru/modals/confirmationModals/StudentRemoveModal.vue'
import SearchAndAddStudentGroupModal from '@/components/guru/modals/SearchAndAddStudentGroupModal.vue'
import type { IUserInStudentGroup } from '@/components/guru/interface/IUserInStudentGroup'

const teachStore = useTeachStore()

const props = defineProps({
  assignmentId: {
    type: Number,
    required: false
  },
  studentGroupId: {
    type: Number,
    required: true
  },
  instituteCode: {
    type: String,
    required: true
  },
  students: {
    type: Array<IStudentInAssignment | IUserInStudentGroup>,
    required: false
  },
  section: {
    type: String as PropType<TEACH_SECTION>,
    required: true
  }
})

const studentNotReadyToBeEvaluatedModal = {
  title: 'Evaluate Student',
  message: 'Assignment not ready for evaluation yet.'
}

/**
 * Is student ready to be evaluated?
 * @param student IStudentInAssignment
 * @returns boolean
 */
function readyToEvaluate(student: IStudentInAssignment) {
  return (
    student.status !== TEACH_STUDENT_ASSIGNMENT_STATUS.YET_TO_START &&
    student.status !== TEACH_STUDENT_ASSIGNMENT_STATUS.IN_PROGRESS
  )
}

const currentStudentEmail = ref<string>('')

/**
 * Set ref to target student's email
 * @param user IStudentInAssignment or IUserInStudentGroup
 */
const handleRemoveStudent = async (user: IStudentInAssignment | IUserInStudentGroup) => {
  currentStudentEmail.value = user.email
}
/**
 * If ready, set student email in teachStore
 * @param student selected student
 */
const handleEvaluateStudent = (student: IStudentInAssignment) => {
  if (!readyToEvaluate(student)) {
    return
  } else {
    teachStore.setStudentUnderEvaluation(student)
    router.push(
      `/dashboard/institution/${props.instituteCode}/assignment/${props.assignmentId}/evaluate`
    )
  }
}

const isAssignmentSection = computed(() => props.section == TEACH_SECTION.ASSIGNMENT)
</script>

<template>
  <SearchAndAddUserModal
    :userType="GURU_USER_TYPE.STUDENT"
    :existingUsers="props.students"
    :instituteCode="props.instituteCode"
    :assignmentId="props.assignmentId"
    :section="props.section"
    :studentGroupId="props.studentGroupId"
  />

  <SearchAndAddStudentGroupModal
    :instituteCode="props.instituteCode"
    :assignmentId="props.assignmentId!"
  />

  <StudentRemoveModal
    :email="currentStudentEmail"
    :instituteCode="props.instituteCode!"
    :insAssignmentId="props.assignmentId!"
    :studentGroupId="props.studentGroupId"
    :section="props.section"
  />

  <NotReadyToEvaluateModal
    :title="studentNotReadyToBeEvaluatedModal.title"
    :message="studentNotReadyToBeEvaluatedModal.message"
  />
  <div>
    <div class="m-auto flex flex-col">
      <div class="flex justify-between">
        <h2 class="mb-4 text-lg">Students</h2>
        <div class="hs-dropdown relative inline-flex [--trigger:hover]">
          <button
            id="hs-dropdown-hover-event"
            type="button"
            class="hs-dropdown-toggle btn-dashboard-small inline-block flex items-center justify-center gap-2 rounded-md px-4 py-2 align-middle text-sm shadow-sm transition-all"
          >
            Actions
            <svg
              class="h-2.5 w-2.5 text-gray-600 text-white hs-dropdown-open:rotate-180"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </button>

          <div
            class="hs-dropdown-menu duration mt-2 hidden w-60 rounded-lg bg-white p-2 opacity-0 shadow-md transition-[opacity,margin] before:absolute before:-top-4 before:left-0 before:h-4 before:w-full after:absolute after:-bottom-4 after:left-0 after:h-4 after:w-full hs-dropdown-open:opacity-100 dark:divide-gray-700 dark:border dark:border-gray-700 dark:bg-gray-800"
            aria-labelledby="hs-dropdown-hover-event"
          >
            <button
              :data-hs-overlay="`#${TEACHMODALHS.ADD_USER_STUDENT}`"
              class="hover:section-secondary w-full rounded-md p-2 text-start text-sm"
            >
              Add Student
            </button>
            <button
              v-if="props.section == TEACH_SECTION.ASSIGNMENT"
              :data-hs-overlay="`#${TEACHMODALHS.IMPORT_STUDENT_GROUP}`"
              class="hover:section-secondary w-full rounded-md p-2 text-start text-sm"
            >
              Import from Student Group
            </button>
          </div>
        </div>
      </div>
      <p v-if="props.students && props.students?.length < 1" class="">No Students Yet</p>
      <div
        class="panel-block has-text-centered is-paddingless"
        v-else-if="props.students && props.students?.length > 0"
      >
        <table class="w-full table-auto border-separate border-spacing-y-3">
          <thead class="text-sm">
            <tr>
              <th class="text-start">Name</th>
              <th class="text-start">Email</th>
              <th class="text-start" v-if="isAssignmentSection">Status</th>
              <th class="text-start"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="student in props.students"
              :key="student.email"
              class="section-primary text-primary"
            >
              <td class="rounded-s-lg p-3 text-sm">
                {{ student.firstName }} {{ student.lastName }}
              </td>
              <td class="break-all p-3 text-sm">{{ student.email }}</td>
              <td v-if="isAssignmentSection" class="p-2 text-sm">
                {{ (student as IStudentInAssignment).status }}
              </td>
              <td class="rounded-e-lg p-3 text-sm">
                <button
                  v-if="isAssignmentSection && !readyToEvaluate(student as IStudentInAssignment)"
                  :data-hs-overlay="`#${TEACHMODALHS.ERROR_NOT_READY_TO_EVALUATE}`"
                >
                  <FontAwesomeIcon icon="fa-pen-to-square" class="me-2"></FontAwesomeIcon>
                </button>

                <button
                  v-else-if="isAssignmentSection && readyToEvaluate(student as IStudentInAssignment)"
                  @click="handleEvaluateStudent(student as IStudentInAssignment)"
                >
                  <FontAwesomeIcon icon="fa-pen-to-square" class="me-2"></FontAwesomeIcon>
                </button>
                <button
                  :data-hs-overlay="`#${TEACHMODALHS.REMOVE_USER_STUDENT_CONFIRMATION}`"
                  @click="handleRemoveStudent(student)"
                >
                  <FontAwesomeIcon icon="fa-trash" class="error"></FontAwesomeIcon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
