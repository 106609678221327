import { META, type IMeta } from '@/utils/meta'
import IDE from '@/views/IDE.vue'
import { cloneDeep } from 'lodash'
import { type RouteRecordRaw } from 'vue-router'

const ideRoutes: RouteRecordRaw[] = []
const paths: string[] = []

for (const key in META) {
  const lang: IMeta = META[key]

  if (typeof lang.isCompile === 'boolean' && lang.langDisplayName) {
    const canonicalPath = `/${lang.canonicalPath}/`
    paths.push(canonicalPath)

    const ideRoute: RouteRecordRaw = {
      path: canonicalPath,
      name: lang.langDisplayName,
      component: IDE,
      meta: lang
    }

    ideRoutes.push(ideRoute)

    if (lang.isPlus) {
      const langPlus = cloneDeep(lang)
      langPlus.isAdvanced = true
      langPlus.basicPath = langPlus.canonicalPath
      langPlus.canonicalPath = `${langPlus.canonicalPath}-ide`
      const advancedCanonicalPath = `/${langPlus.canonicalPath}/`
      paths.push(advancedCanonicalPath)

      const advancedIdeRoute: RouteRecordRaw = {
        path: advancedCanonicalPath,
        name: `${langPlus.langDisplayName} Advanced IDE`,
        component: IDE,
        meta: langPlus
      }

      ideRoutes.push(advancedIdeRoute)
    }
  }
}

export default ideRoutes
