<script setup lang="ts">
import { onMounted, ref } from 'vue'

const showCookieBanner = ref(false)

/**
 * @description Hides the cookie banner and sets a local storage key to prevent it from showing again
 */
const onClickAccept = () => {
  localStorage.setItem('cookie:accepted', 'true')
  showCookieBanner.value = false
}

onMounted(() => {
  if (localStorage.getItem('cookie:accepted') === null) {
    showCookieBanner.value = true
  }
})
</script>

<template>
  <div
    v-if="showCookieBanner"
    class="fixed bottom-0 left-1/2 z-[50] mx-auto w-full -translate-x-1/2 transform print:hidden"
  >
    <div class="section-tertiary p-5 shadow-2xl">
      <div class="flex flex-col items-center justify-center gap-5 md:flex-row">
        <p class="p-normal">
          This website uses cookies to ensure you get the best experience on our website.
        </p>
        <button @click="onClickAccept" class="btn-secondary btn-rounded-full sm:w-auto">
          Got it!
        </button>
      </div>
    </div>
  </div>
</template>
