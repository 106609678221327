<script lang="ts" setup>
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import ProjectSearchBar from '@/components/dashboard/project/ProjectSearchBar.vue'
import PreviewProject from '@/components/dashboard/project/PreviewProject.vue'
import { useDashboardProjectStore } from '@/stores/project.store'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import type ILink from '@/components/shared/interface/ILink'
import { onMounted, watch } from 'vue'
import dashboardProjectService from '@/services/dashboard.project.service'

const dashboardProjectStore = useDashboardProjectStore()
const breadcrumbStore = useBreadcrumbStore()

const breadcrumbLinks: ILink[] = [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  },
  {
    href: '/dashboard/project',
    title: 'My Projects'
  }
]
/**
 * closes the delete confirmation pop
 *
 */
const closePopup = () => {
  dashboardProjectStore.showDeleteConfirm = false
  dashboardProjectStore.showAskForDelete = false
}

/**
 * filters out the deleted Project from the project list
 *
 */
const filterDeletedProject = () => {
  dashboardProjectStore.filteredProjects = dashboardProjectStore.filteredProjects.filter(
    (project) => project.id !== dashboardProjectStore.deleteProjectId
  )
}
/**
 * confirm before delete
 *
 */
const deleteProject = async () => {
  dashboardProjectStore.showAskForDelete = false

  dashboardProjectStore.httpError = ''
  dashboardProjectStore.httpSuccess = ''
  await dashboardProjectService
    .deleteProject(
      dashboardProjectStore.deleteProjectId as string,
      dashboardProjectStore.deleteProjectLang as string,
      false
    )
    .then(() => {
      dashboardProjectStore.httpSuccess = 'Project Deleted Successfully'
      dashboardProjectStore.showDeleteConfirm = true
      filterDeletedProject()
    })
    .catch(() => {
      dashboardProjectStore.httpError = 'Error deleting your project. Please try again later.'
      dashboardProjectStore.showDeleteConfirm = true
    })
    // })
    .catch(() => {})
}
watch(
  () => dashboardProjectStore.httpError,
  () => {
    if (dashboardProjectStore.httpError) {
      if (dashboardProjectStore.httpErrorTimeOut)
        clearTimeout(dashboardProjectStore.httpErrorTimeOut)
      dashboardProjectStore.httpErrorTimeOut = setTimeout(() => {}, 4000)
    }
  }
)
watch(
  () => dashboardProjectStore.httpSuccess,
  () => {
    if (dashboardProjectStore.httpSuccess) {
      if (dashboardProjectStore.httpSuccessTimeOut)
        clearTimeout(dashboardProjectStore.httpSuccessTimeOut)
      dashboardProjectStore.httpSuccessTimeOut = setTimeout(() => {}, 4000)
    }
  }
)

onMounted(() => {
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks)
})
</script>

<template>
  <div class="grid grid-cols-12 grid-rows-2 gap-3 md:grid-cols-12 md:grid-rows-1">
    <DashboardCard title="My Projects" class="relative col-span-12 row-span-1 lg:col-span-5">
      <div>
        <svg
          v-if="!dashboardProjectStore.showFilter"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          class="text-brand absolute right-5 top-5 h-6 w-6 transform cursor-pointer transition-transform"
          @click="dashboardProjectStore.setShowFilter(true)"
        >
          <path
            d="M14 15L17 18M17 18L20 15M17 18V6M5 5.5C5 5.36739 5.05268 5.24021 5.14645 5.14645C5.24021 5.05268 5.36739 5 5.5 5H9.5C9.63261 5 9.75979 5.05268 9.85355 5.14645C9.94732 5.24021 10 5.36739 10 5.5V9.5C10 9.63261 9.94732 9.75979 9.85355 9.85355C9.75979 9.94732 9.63261 10 9.5 10H5.5C5.36739 10 5.24021 9.94732 5.14645 9.85355C5.05268 9.75979 5 9.63261 5 9.5V5.5ZM5 14.5C5 14.3674 5.05268 14.2402 5.14645 14.1464C5.24021 14.0527 5.36739 14 5.5 14H9.5C9.63261 14 9.75979 14.0527 9.85355 14.1464C9.94732 14.2402 10 14.3674 10 14.5V18.5C10 18.6326 9.94732 18.7598 9.85355 18.8536C9.75979 18.9473 9.63261 19 9.5 19H5.5C5.36739 19 5.24021 18.9473 5.14645 18.8536C5.05268 18.7598 5 18.6326 5 18.5V14.5Z"
            stroke="#FD5200"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          v-if="dashboardProjectStore.showFilter"
          class="text-brand absolute right-5 top-5 h-6 w-6 cursor-pointer"
          @click="dashboardProjectStore.setShowFilter(false)"
        >
          <path
            d="M9.5 4L12.5 1M12.5 1L15.5 4M12.5 1V15M1 1.5C1 1.36739 1.05268 1.24021 1.14645 1.14645C1.24021 1.05268 1.36739 1 1.5 1H5.5C5.63261 1 5.75979 1.05268 5.85355 1.14645C5.94732 1.24021 6 1.36739 6 1.5V5.5C6 5.63261 5.94732 5.75979 5.85355 5.85355C5.75979 5.94732 5.63261 6 5.5 6H1.5C1.36739 6 1.24021 5.94732 1.14645 5.85355C1.05268 5.75979 1 5.63261 1 5.5V1.5ZM1 10.5C1 10.3674 1.05268 10.2402 1.14645 10.1464C1.24021 10.0527 1.36739 10 1.5 10H5.5C5.63261 10 5.75979 10.0527 5.85355 10.1464C5.94732 10.2402 6 10.3674 6 10.5V14.5C6 14.6326 5.94732 14.7598 5.85355 14.8536C5.75979 14.9473 5.63261 15 5.5 15H1.5C1.36739 15 1.24021 14.9473 1.14645 14.8536C1.05268 14.7598 1 14.6326 1 14.5V10.5Z"
            stroke="#FD5200"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div>
        <div class="relative">
          <ProjectSearchBar />
        </div>
      </div>
    </DashboardCard>

    <DashboardCard
      v-if="dashboardProjectStore.showDeleteConfirm"
      class="col-span-12 row-span-1 place-items-center lg:col-span-7"
    >
      <div class="section-primary mt-32 rounded-md px-6 py-4">
        <div class="dashboard flex justify-center">
          <p :class="['p-normal ', 'error', { hidden: !dashboardProjectStore.httpError }]">
            {{ dashboardProjectStore.httpError }}
          </p>
          <p :class="['p-normal', 'text-primary', { hidden: !dashboardProjectStore.httpSuccess }]">
            {{ dashboardProjectStore.httpSuccess }}
          </p>
        </div>
        <div class="flex justify-center">
          <button class="btn-primary mt-10 rounded-md px-2" @click="closePopup">Close</button>
        </div>
      </div>
    </DashboardCard>
    <DashboardCard
      v-show="dashboardProjectStore.showAskForDelete"
      class="col-span-12 row-span-1 place-items-center lg:col-span-7"
    >
      <div class="section-primary mt-32 rounded-md px-6 py-4">
        <div class="dashboard flex justify-center">
          <p class="p-normal text-primary">Are you sure, do you want to delete this project?</p>
        </div>
        <div class="flex justify-center gap-4">
          <button class="btn-secondary mt-10 rounded-md px-2" @click="deleteProject">Delete</button>
          <button class="btn-primary mt-10 rounded-md px-2" @click="closePopup">Close</button>
        </div>
      </div>
    </DashboardCard>
    <DashboardCard
      v-if="!dashboardProjectStore.showDeleteConfirm"
      title="Project Preview"
      class="col-span-12 row-span-1 lg:col-span-7"
    >
      <PreviewProject />
    </DashboardCard>
  </div>
</template>
