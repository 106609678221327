import { SUBSCRIPTION_PLAN_NAME } from '@/components/shared/subscription/enum/subscription.enum'
import type { ISubscriptionInitRes } from '@/components/shared/subscription/interface/ISubscriptionInitRes'
import type { IinitAuthResponse } from '@/services/auth.service'
import utilModelsService from '@/services/util.models.service'
import { SUBSCRIPTION_MODAL } from '@/utils/models'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useSubscribeStore = defineStore('subscribe', () => {
  // Plan at login
  const isPremium = ref<boolean>(false)

  /**
   * Sets isPremium at login
   * @param loginRes Response from login api call
   */
  const storePlanAtLogin = (loginRes: IinitAuthResponse) => {
    isPremium.value = loginRes.isPremium || false
  }

  const selectedPlan = ref(undefined as SUBSCRIPTION_PLAN_NAME | undefined)
  const showSubscribeComponents = ref(false)

  const bundleInitData = ref(undefined as undefined | ISubscriptionInitRes)
  const apiInitData = ref(undefined as undefined | ISubscriptionInitRes)
  const showSubscribeOverlay = ref(false)

  /**
   * Selects the plan name
   * @param planName name of plan selected
   */
  function selectPlan(planName: SUBSCRIPTION_PLAN_NAME | undefined) {
    selectedPlan.value = planName
  }

  /**
   * Sets the Bundle subscription data using init response
   * @param response Resposne object from service
   */
  function initBundle(response: ISubscriptionInitRes) {
    bundleInitData.value = response
  }

  /**
   * Sets the API subscription data using init response
   * @param response Resposne object from service
   */
  function initApi(response: ISubscriptionInitRes) {
    apiInitData.value = response
  }

  /**
   *
   */
  function openSubscribeModal() {
    utilModelsService.openModal(SUBSCRIPTION_MODAL.SUBSCRIBE)
    showSubscribeOverlay.value = true
  }

  /**
   *
   */
  function closeSubscribeModal() {
    utilModelsService.closeModal(SUBSCRIPTION_MODAL.SUBSCRIBE)
    showSubscribeOverlay.value = false
  }

  /**
   *
   */
  function SubscribeOverlayFalse() {
    showSubscribeOverlay.value = false
  }

  return {
    isPremium,
    storePlanAtLogin,
    selectedPlan,
    showSubscribeComponents,
    bundleInitData,
    apiInitData,
    selectPlan,
    initBundle,
    initApi,
    openSubscribeModal,
    closeSubscribeModal,
    showSubscribeOverlay,
    SubscribeOverlayFalse
  }
})
