<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import ModelLayout from '@/layouts/ModelLayout.vue'
import { DASHBOARD } from '@/utils/models'
import utilModelsService from '@/services/util.models.service'
import configureService from '@/services/ide/plugin/configure.service'
import { usePluginStore } from '@/stores/plugin.store'

import BaseInput from '@/components/forms/BaseInput.vue'

import { useField, useForm } from 'vee-validate'
import { string } from 'yup'

const route = useRoute()
const pluginStore = usePluginStore()

const httpError = ref<string>('')
const httpSuccess = ref<string>('')
const httpInProgress = ref<boolean>(false)

const { errors, handleSubmit } = useForm()
const { value: pluginName, errorMessage: pluginNameError } = useField(
  'pluginName',
  string().required()
)
const { value: pluginDescription, errorMessage: pluginDescriptionError } = useField(
  'pluginDescription',
  string().required()
)

const isHttpInProgress = computed(() => {
  return httpInProgress.value
})

const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0
})

const onSubmit = handleSubmit(async (values) => {
  httpInProgress.value = true
  httpError.value = ''
  httpSuccess.value = ''

  const id = route.params.pluginId as string

  await configureService
    .renamePlugin(id, values.pluginName, values.pluginDescription)
    .then(
      async (response: {
        status: number
        data: {
          name: string
          description: string
        }
      }) => {
        if (response?.status === 200) {
          const { name, description } = response.data
          httpSuccess.value = 'Plugin renamed successfully'
          pluginName.value = name
          pluginDescription.value = description
          await utilModelsService.closeModal(DASHBOARD.RENAME_PLUGIN)
        }
      }
    )
    .catch((err) => {
      httpError.value = err.response.data.message ? err.response.data.message : err.message
    })
    .finally(() => {
      httpInProgress.value = false
    })
})
onMounted(() => {
  pluginName.value = pluginStore.isPluginResponse.name
  pluginDescription.value = pluginStore.isPluginResponse.description

  watch(pluginStore.isPluginResponse, () => {
    if (pluginStore.isPluginResponse) {
      pluginName.value = pluginStore.isPluginResponse.name
      pluginDescription.value = pluginStore.isPluginResponse.description
    }
  })
  watch(route, () => {
    httpError.value = ''
    httpSuccess.value = ''
  })
})
</script>

<template>
  <ModelLayout title="Rename Plugin" :hs="DASHBOARD.RENAME_PLUGIN">
    <p :class="['p-xsmall ', 'error', , { hidden: !httpError }]">
      {{ httpError }}
    </p>
    <p :class="['p-xsmall', 'success', { hidden: !httpSuccess }]">
      {{ httpSuccess }}
    </p>
    <p :class="['p-xsmall', , { hidden: !isHttpInProgress }]">
      JDoodle is creating your plugin. Please wait...
    </p>
    <form @submit.prevent="onSubmit">
      <BaseInput
        label="Plugin Name"
        placeholder="My plugin 1"
        :error="pluginNameError"
        v-model="pluginName"
      />
      <BaseInput
        label="Plugin Description"
        placeholder="Description"
        :error="pluginDescriptionError"
        v-model="pluginDescription"
      />
      <div class="flex w-full gap-2">
        <button
          :disabled="!canSubmit || isHttpInProgress"
          class="btn-secondary btn-rounded-md"
          type="submit"
        >
          Rename Plugin
        </button>
      </div>
    </form>
  </ModelLayout>
</template>
