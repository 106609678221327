<script setup lang="ts">
import { computed } from 'vue'
import executeService from '@/services/ide/execute.service'
import htmlExecuteService from '@/services/ide/languages/html/execute.service'

import { useIdeStore } from '@/stores/ide.store'

const ideStore = useIdeStore()
const isHtml = computed(() => {
  return ideStore.isHtml
})
const isCodeExecuting = computed(() => {
  return ideStore.isCodeExecuting
})
/**
 * Tries to execute the code
 */
const tryExecute = () => {
  if (isHtml.value) {
    htmlExecuteService.tryExecute()
  } else {
    executeService.tryExecute()
  }
}
/**
 * Stops the execution
 */
const stopExecution = () => {
  if (isHtml.value) {
    htmlExecuteService.stopExecution()
  } else {
    executeService.stopExecution()
  }
}
</script>

<template>
  <button
    v-if="!isCodeExecuting"
    class="btn-primary flex h-fit w-fit items-center gap-2 rounded-md px-2 py-1 text-sm"
    @click="tryExecute"
  >
    <FontAwesomeIcon icon="fa-play" class="h-3 w-3" />
    Execute
  </button>
  <button
    v-if="isCodeExecuting"
    class="btn-primary flex h-fit w-fit items-center gap-2 rounded-md border px-2 py-1 text-sm"
    @click="stopExecution"
  >
    <FontAwesomeIcon spin icon="fa-spinner" class="h-3 w-3" />
    Stop Execution
  </button>
</template>
