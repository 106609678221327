<script setup lang="ts">
import LogoWhite from '@/assets/images/JD_logo_white.png'
import LogoBlack from '@/assets/images/JD_logo_colour.png'
import { useColorMode } from '@vueuse/core'

const colorTheme = useColorMode()

const props = defineProps({
  isLink: {
    type: Boolean,
    required: false,
    default: true
  }
})
</script>

<template>
  <div v-if="props.isLink" class="-ml-5">
    <RouterLink :to="{ name: 'home' }" class="link-primary">
      <img
        v-if="colorTheme == 'dark'"
        class="w-[200px] sm:w-[230px]"
        :src="LogoWhite"
        alt="JDoodle"
      />
      <img v-else class="w-[200px] sm:w-[230px]" :src="LogoBlack" alt="JDoodle" />
    </RouterLink>
  </div>
  <div v-else class="link-primary -ml-4">
    <img
      v-if="colorTheme == 'dark'"
      class="w-[200px] sm:w-[230px]"
      :src="LogoWhite"
      alt="JDoodle"
    />
    <img v-else class="w-[200px] sm:w-[230px]" :src="LogoBlack" alt="JDoodle" />
  </div>
</template>
