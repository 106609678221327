<script setup lang="ts">
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import { useTeachStore } from '@/stores/teach.store'
import { computed, watch, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import AddEditStudentGroupComponent from './AddEditStudentGroupComponent.vue'

const teachStore = useTeachStore()
const breadcrumbStore = useBreadcrumbStore()
const route = useRoute()
const insCode = ref(route.params.insCode as string)

const breadcrumbLinks = computed(() => [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  },
  {
    href: '/dashboard/institution',
    title: 'Virtual Institutions'
  },
  {
    href: `/dashboard/institution/${insCode.value}`,
    title: `${teachStore.currentAssociation?.name}`
  },
  {
    href: `/dashboard/institution/${insCode.value}/student-groups`,
    title: `Student Groups`
  },
  {
    href: `/dashboard/institution/${insCode.value}/add-student-group`,
    title: `Add Student Group`
  }
])

watch(
  () => teachStore.currentAssociation,
  () => {
    breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
  }
)

onMounted(() => {
  insCode.value = route.params.insCode as string
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
})
</script>

<template>
  <AddEditStudentGroupComponent />
</template>
