<script setup lang="ts">
import { RouterLink } from 'vue-router'
import MegaMenu from '@/components/header/navigation/MegaMenu.vue'

interface ILinkItem {
  title: string
  routeName: string

  categories: {
    name: string
    path: string
  }[]
}

const LinkItems: ILinkItem[] = [
  {
    title: 'By Industry',
    routeName: 'intergrate-online-ide-compiler-api-plugins',

    categories: [
      { name: 'Education', path: 'solutions/education-institutions' },
      { name: 'Edu Tech', path: 'solutions/education-technology' },
      { name: 'Library Providers', path: 'solutions/software-library-demostration' }
    ]
  },
  {
    title: 'Use Cases',

    routeName: 'case-study',

    categories: [
      {
        name: 'Kansas University',
        path: 'case-study/kansas'
      },
      {
        name: 'iText',
        path: 'case-study/itext'
      },
      {
        name: 'Kodezi',
        path: 'case-study/kodezi'
      },
      {
        name: 'Link Edu Group',
        path: 'case-study/linkgroup'
      },
      {
        name: 'Outlier',
        path: 'case-study/outlier'
      }
    ]
  }
]
</script>

<template>
  <MegaMenu>
    <div
      class="flex w-full cursor-pointer flex-col justify-start gap-6 pt-6 sm:gap-20 sm:pl-0 sm:pt-0 md:flex-row"
    >
      <div v-for="(links, index) of LinkItems" :key="index" class="pr-8">
        <div class="flex cursor-pointer flex-col gap-4 text-base">
          <h3
            class="text-secondary font-medium uppercase tracking-wider dark:text-text-primary-dark"
          >
            {{ links.title }}
          </h3>
          <RouterLink
            v-for="(category, index) of links.categories"
            :key="index"
            class="link-primary font-light sm:block"
            :to="`/${category.path}`"
          >
            {{ category.name }}
          </RouterLink>
        </div>
      </div>
    </div>
  </MegaMenu>
</template>
