<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import { useSubscribeStore } from '@/stores/subscribe.store'
import SubscriptionComponent from '@/components/shared/subscription/SubscriptionComponent.vue'
import { SUBSCRIPTION_TYPE_ENUM } from '@/components/shared/subscription/enum/subscription.enum'
import type ILink from '@/components/shared/interface/ILink'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import { type IBundledPlan, pluginPlansData } from '@/utils/sharedData/plans'
import APICurrentPlan from '@/components/shared/subscription/APICurrentPlan.vue'
import utilModelsService from '@/services/util.models.service'
import { SUBSCRIPTION_MODAL } from '@/utils/models'

const breadcrumbStore = useBreadcrumbStore()
const subscribeStore = useSubscribeStore()
const subscribeType = ref(SUBSCRIPTION_TYPE_ENUM.COMPILER_API)
const showCancel = ref(true)

const breadcrumbLinks: ILink[] = [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  },
  {
    href: '/dashboard/payment/plans',
    title: 'Active Plans'
  }
]

const platformPlanName = computed<string | undefined>(() => {
  const name = pluginPlansData.find((plan: IBundledPlan) => {
    return plan.name == subscribeStore.bundleInitData?.plan
  })?.type

  return name
})

/**
 *
 * @param type any
 * @param plan any
 */
const subscribePlan = (type: any, plan: any) => {
  subscribeType.value = type
  subscribeStore.selectPlan(plan)
  utilModelsService.openModal(SUBSCRIPTION_MODAL.CANCEL)
}

onMounted(async () => {
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks)
})
</script>

<template>
  <div class="">
    <SubscriptionComponent :subscription-type="subscribeType" />
    <APICurrentPlan :subscription-type="subscribeType" @planCancelled="showCancel = false" />

    <div class="flex flex-col gap-4">
      <DashboardCard title="Platform Plan">
        {{ platformPlanName || 'Free' }} Plan

        <div class="text-right" v-show="!subscribeStore.bundleInitData?.isUnlimited">
          <button
            v-if="platformPlanName && !subscribeStore.bundleInitData?.isCancelled"
            class="ml-4 rounded-md border px-3.5 py-2 text-xs"
            @click="subscribePlan(SUBSCRIPTION_TYPE_ENUM.BUNDLE, subscribeStore.apiInitData?.plan)"
          >
            Cancel
          </button>
          <button
            v-if="platformPlanName && subscribeStore.bundleInitData?.isCancelled"
            class="ml-4 rounded-md border px-3.5 py-2 text-xs"
            @click="subscribePlan(SUBSCRIPTION_TYPE_ENUM.BUNDLE, subscribeStore.apiInitData?.plan)"
          >
            Re-activate
          </button>
          <RouterLink
            :to="{ name: 'pricing-tab', params: { tab: 'platform' } }"
            class="ml-4 rounded-md border px-3.5 py-2 text-xs"
          >
            Upgrade
          </RouterLink>
        </div>
      </DashboardCard>

      <DashboardCard title="API Plan">
        <p>
          <span
            class="inline-block w-36"
            v-if="
              !subscribeStore.apiInitData?.isUnlimited && subscribeStore.apiInitData?.futurePlan
            "
            >Current plan:
          </span>
          <span v-if="subscribeStore.apiInitData?.isUnlimited">Unlimited</span>
          <span v-else>{{ subscribeStore.apiInitData?.plan || 'No' }}</span>
          Plan
        </p>

        <!-- Future plan -->
        <div v-if="subscribeStore.apiInitData?.futurePlan" class="mt-4">
          <p class="text-secondary mb-4">
            <span class="inline-block w-36">Future plan: </span>
            <span>{{ subscribeStore.apiInitData?.futurePlan }} Plan</span>
          </p>
        </div>

        <div class="text-right" v-show="!subscribeStore.apiInitData?.isUnlimited">
          <button
            v-if="
              subscribeStore.apiInitData?.plan &&
              !subscribeStore.apiInitData?.isCancelled &&
              showCancel
            "
            class="ml-4 rounded-md border px-3.5 py-2 text-xs"
            @click="
              subscribePlan(SUBSCRIPTION_TYPE_ENUM.COMPILER_API, subscribeStore.apiInitData?.plan)
            "
          >
            Cancel
          </button>
          <button
            v-if="subscribeStore.apiInitData?.plan && subscribeStore.apiInitData?.isCancelled"
            class="ml-4 rounded-md border px-3.5 py-2 text-xs"
            @click="
              subscribePlan(SUBSCRIPTION_TYPE_ENUM.COMPILER_API, subscribeStore.apiInitData?.plan)
            "
          >
            Re-activate
          </button>
          <RouterLink
            v-if="!subscribeStore.apiInitData?.futurePlan"
            :to="{ name: 'pricing-tab', params: { tab: 'api' } }"
            class="ml-4 rounded-md border px-3.5 py-2 text-xs"
          >
            Upgrade
          </RouterLink>
        </div>
      </DashboardCard>
    </div>
  </div>
</template>
