<script setup lang="ts">
import CaseStudyDemoCTA, {
  type IDemoCTATextData
} from '@/components/shared/casestudy/CaseStudyDemoCTA.vue'

const sectionData: IDemoCTATextData = {
  textHeading: 'Empower your students to code within your platform Or course material',
  textSubheading: 'Deliver a more robust and intuitive learning experience for your students.',
  getStartedLink: '/pricing/platform',
  learnMoreLink: '/solutions/education-technology'
}
</script>

<template>
  <CaseStudyDemoCTA :sectionData="sectionData" />
</template>
