import { cloneDeep } from 'lodash'

//eslint-disable-next-line
const resetStore = ({ store }: any) => {
  const initialState = cloneDeep(store.$state)
  /**
   * reset the store to its initial state
   * @returns void
   */
  store.$reset = () => store.$patch(cloneDeep(initialState))
}

export default resetStore
