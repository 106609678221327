<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { languagesItems } from '@/utils/sharedData/languages'
import configureService from '@/services/ide/plugin/configure.service'
import { usePluginStore } from '@/stores/plugin.store'

const pluginStore = usePluginStore()

const mouseXY = ref<any>({ x: null, y: null })
const selectedItemID = ref<any>(null)

const searchTerm = ref<string>('')
const filteredProjects = ref<any[]>([])

const availbleProjects = computed(() => {
  return pluginStore.availbleProjects
})
const isAvailbleProjects = computed(() => {
  return pluginStore.availbleProjects.length > 0
})
const mouseInGrid = computed(() => {
  return pluginStore.isMouseInGrid
})
/**
 * When the user drags a component, check if the mouse is in the grid
 * @param e The drag event
 */
const drag = (e: any) => {
  pluginStore.setDragging(true)
  if (e.target?.id) {
    selectedItemID.value = e.target.id
  } else {
    selectedItemID.value = null
  }
  let parentRect = document.getElementById('gridContainer')?.getBoundingClientRect()
  pluginStore.setMouseInGrid(false)
  if (
    parentRect &&
    mouseXY.value.x > parentRect.left &&
    mouseXY.value.x < parentRect.right &&
    mouseXY.value.y > parentRect.top &&
    mouseXY.value.y < parentRect.bottom
  ) {
    pluginStore.setMouseInGrid(true)
    pluginStore.setDragging(false)
  } else {
    pluginStore.setDragging(true)
  }
}
/**
 * once the drag ends, check if the mouse is in the grid and if so, add the component to the grid
 * @param e The drag event
 */
const dragend = (e: any) => {
  pluginStore.setDragging(false)
  if (mouseInGrid.value) {
    let project = pluginStore.availbleProjects.find(
      (item) => item.id.toString() === e.target?.id.toString()
    )
    if (project) {
      const index = pluginStore.languages.findIndex((item) => item.language === project.language)
      if (index != -1) {
        configureService.openProject(project, index)
      }
    }
  }
  selectedItemID.value = null
  pluginStore.setMouseInGrid(false)
}
/**
 * Checks if the project is selected
 * @param project The project to check
 * @returns True if the project is selected
 */
const isItemSelected = (project: any) => {
  if (!selectedItemID.value) return false
  return selectedItemID.value === project.id
}
/**
 * @param language gets the icon for the language
 * @returns icon
 */
const getIcon = (language: string) => {
  const langaugeForIcon = language
  for (let i = 0; i <= languagesItems.length; i++) {
    if (languagesItems[i]?.language === langaugeForIcon) return languagesItems[i]?.icon
  }
}
/**
 * @param language gets the icon for the language
 * @returns displayName
 */
const getDisplayName = (language: string) => {
  const langaugeForIcon = language
  for (let i = 0; i <= languagesItems.length; i++) {
    if (languagesItems[i]?.language === langaugeForIcon) return languagesItems[i]?.displayName
  }
}

onMounted(async () => {
  await configureService.getProjects()
  filteredProjects.value = [...availbleProjects.value]
  watch(
    () => searchTerm.value,
    (string) => {
      if (string) {
        filteredProjects.value = [...availbleProjects.value].filter((p: any) => {
          return p.name.toLowerCase().includes(string.toLowerCase())
        })
      } else {
        filteredProjects.value = [...availbleProjects.value]
      }
    }
  )
  watch(availbleProjects.value, () => {
    searchTerm.value = ''
    filteredProjects.value = [...availbleProjects.value]
  })
  document.addEventListener(
    'dragover',
    function (e) {
      mouseXY.value.x = e.clientX
      mouseXY.value.y = e.clientY
    },
    false
  )
})
</script>

<template>
  <div class="h-full w-full">
    <p v-if="!isAvailbleProjects" class="p-small text-secondary">No Projects available</p>
    <div v-else class="flex flex-col gap-5">
      <p class="p-small text-secondary">Drag and drop your projects to the plugin view</p>
      <div class="relative flex flex-col gap-4">
        <div class="relative">
          <input
            placeholder="Search Projects"
            name="projectSearch"
            id="searchBar"
            class="section-primary p-small block w-full rounded-lg px-4 py-3"
            v-model="searchTerm"
          />
          <div class="pointer-events-none absolute inset-y-0 right-2 flex items-center pl-3">
            <FontAwesomeIcon icon="fa-magnifying-glass" class="text-brand h-5 w-5" />
          </div>
        </div>
        <ul class="flex max-h-64 w-full flex-wrap justify-stretch gap-2 overflow-scroll">
          <li
            v-for="project in filteredProjects"
            :key="project.id"
            :id="project.id"
            @drag="drag"
            @dragend="dragend"
            draggable="true"
            unselectable="on"
            :class="[
              'section-secondary w-fit rounded-lg border p-2 hover:border-btn-primary',
              {
                'opacity-50': isItemSelected(project)
              }
            ]"
          >
            <div class="text-primary flex items-center text-sm">
              <img :src="getIcon(project.language)" class="light:inline-block me-2 h-3 w-3" />
              <span class="p-xsmall">{{ getDisplayName(project.language) }}</span> -
              <span class="p-xsmall">{{ project.name }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
