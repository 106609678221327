<script setup lang="ts">
import MobileAppSection from '@/components/shared/MobileAppSection.vue'
import SlidingImages from '@/components/shared/SlidingImages.vue'
import CaseStudyBannerEduTech from '@/components/solution/edutech/CaseStudyBannerEduTech.vue'
import EduTechCompilerPluginFeatures from '@/components/solution/edutech/EduTechCompilerPluginFeatures.vue'
import SolutionDocBanner from '@/components/solution/SolutionDocBanner.vue'
import SolutionEduTechHero from '@/components/solution/edutech/SolutionEduTechHero.vue'
import ThreeSteps from '@/components/shared/ThreeSteps.vue'
import SolutionPricingMain from '@/components/solution/SolutionPricingMain.vue'
import SolutionEduTechNav from '@/components/solution/edutech/SolutionEduTechNav.vue'
import UserReviews from '@/components/integrate/landing/UserReviews.vue'
import FrequentQuestions from '@/components/shared/FrequentQuestions.vue'
import { type ISixItems } from '@/components/shared/SixItemsPanel.vue'

const faqData: ISixItems[] = [
  {
    icon: 'fa-wallet',
    heading: 'What payment methods do you accept?',
    body: 'We accept the following payment methods for subscription: Credit card: Visa, Mastercard and American Express. We also accept PayPal and Bank transfer.'
  },
  {
    icon: 'fa-receipt',
    heading: 'What subscription options are available?',
    body: 'For APIs, we offer free, credits based and custom plans. For Plugins, we offer a Platform plan that includes free, team and custom plans. Please <RouterLink to="/contact-us" class="underline underline-offset-2">contact us</RouterLink> if you need a custom version.'
  },
  {
    icon: 'fa-circle-dollar-to-slot',
    heading: 'Is plan costs in USD?',
    body: 'All the costs are in USD.'
  },
  {
    icon: 'fa-shield-halved',
    heading: 'Are my credit card details safe?',
    body: 'Yes, absolutely. We use Stripe for all credit card processing and we do not store or handle your credit card details in our systems.'
  },
  {
    icon: 'fa-file-invoice-dollar',
    heading: 'What is the billing period?',
    body: 'When you subscribe to a paid plan for the first time, you are paying for the plan and your billing period starts from that date. Your credit card will be charged every month on that day.'
  },
  {
    icon: 'fa-file-lines',
    heading: 'How do I request a purchase order?',
    body: 'You can request a purchase order by contacting us. We will need your name, contact information, and the details of your purchase order. Once we have received your request, we will send you a PDF copy of the purchase order within 24 hours.'
  }
]
</script>

<template>
  <SolutionEduTechNav />
  <SolutionEduTechHero id="overview" />
  <SlidingImages />
  <EduTechCompilerPluginFeatures id="features" />
  <CaseStudyBannerEduTech id="useCases" />
  <ThreeSteps />
  <SolutionDocBanner />
  <SolutionPricingMain id="pricing" />
  <UserReviews id="reviews" />
  <FrequentQuestions :faqData="faqData" :document="true" id="faqs" />
  <MobileAppSection />
</template>
