<script setup lang="ts">
import { computed } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import { useIdeStore } from '@/stores/ide.store'
import LogoComp from '@/components/utils/LogoComp.vue'

const ideStore = useIdeStore()
const route = useRoute()

const routeUrl = computed(() => {
  return route.path
})
const type = computed(() => {
  return ideStore.isTerminal ? 'Terminal' : 'IDE'
})
const langDisplayName = computed(() => {
  return ideStore.ideMeta?.langDisplayName
})
const isCompileLang = computed(() => {
  return ideStore.ideMeta?.isCompile || ideStore.ideMeta?.language === 'java'
})
const isMultiFile = computed(() => {
  return ideStore.ideMeta?.isAdvanced
})
const showLinkToMultiFileLang = computed(() => {
  return ideStore.ideMeta?.isPlus && !isMultiFile.value
})
const showLinkToSingleFileLang = computed(() => {
  return ideStore.ideMeta?.isPlus && isMultiFile.value
})
const ideLinkUrl = computed<string>(() => {
  if (ideStore.ideMeta?.isPlus && isMultiFile.value) {
    return '/' + ideStore.ideMeta?.basicPath + '/'
  } else if (ideStore.ideMeta?.isPlus && !isMultiFile.value) {
    return '/' + ideStore.ideMeta?.canonicalPath + '-ide' + '/'
  } else {
    return ''
  }
})
const isShareNotFound = computed(() => {
  return ideStore.shareNotFound
})
</script>

<template>
  <div>
    <div v-if="!isShareNotFound" class="my-5 text-center">
      <LogoComp class="mb-2 hidden w-full justify-center print:flex" />
      <h1 class="heading-medium md:leading-tight">
        <span>Online</span> {{ langDisplayName }} <span v-if="isCompileLang">Compiler </span
        ><span> {{ type }}</span>
      </h1>
      <p class="p-normal link-secondary hidden text-center print:block">
        https://www.jdoodle.com{{ routeUrl }}
      </p>
      <span class="p-normal text-center" v-if="isMultiFile">(Advanced IDE)</span>
      <div class="p-small px-2 text-center print:hidden">
        <div v-if="showLinkToMultiFileLang">
          <p>
            For Multiple Files, Custom Library and File Read/Write, use our new -
            <Router-link :to="ideLinkUrl" class="link-secondary"
              >Advanced {{ langDisplayName }} IDE</Router-link
            >
          </p>
        </div>
        <div v-if="showLinkToSingleFileLang">
          <p>
            For simple single file programs and faster execution, use -
            <Router-link :to="ideLinkUrl" class="link-secondary"
              >Basic {{ langDisplayName }} IDE</Router-link
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
