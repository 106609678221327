<script lang="ts" setup>
import CaseStudyBanner, { type ICaseStudyBanner } from '@/components/shared/CaseStudyBanner.vue'
import Dark_Linkgroup from '@/assets/images/shared/reviews/businesses/Dark_Linkgroup.png'
import Light_Linkgroup from '@/assets/images/shared/reviews/businesses/Light_LinkGroup.png'
import { useColorMode } from '@vueuse/core'
import { ref, watch } from 'vue'

const colorTheme = useColorMode()

watch(colorTheme, () => {
  if (colorTheme.value == 'dark') {
    caseStudyBannerData.value.image.src = Dark_Linkgroup
  } else {
    caseStudyBannerData.value.image.src = Light_Linkgroup
  }
})

const caseStudyBannerData = ref<ICaseStudyBanner>({
  orangeTextAboveTitle: 'Case Study',
  title:
    'LinkGroup used compiler plugins to improve the functionality and interactivity of their teaching materials',
  primaryButton: {
    link: '/case-study/linkgroup',
    text: 'Read More'
  },
  image: {
    src: colorTheme.value == 'dark' ? Dark_Linkgroup : Light_Linkgroup,
    alt: 'LinkGroup'
  }
})
</script>
<template>
  <div>
    <CaseStudyBanner :sectionData="caseStudyBannerData" />
  </div>
</template>
