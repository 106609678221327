<script setup lang="ts">
import BaseInput from '@/components/forms/BaseInput.vue'
import BaseTextbox from '@/components/forms/BaseTextbox.vue'
import BaseCheck from '@/components/forms/BaseCheck.vue'
import BaseSelect from '@/components/forms/BaseSelect.vue'
import { array, boolean, number, object, string } from 'yup'
import { useField, useFieldArray, useForm } from 'vee-validate'
import { type PropType, computed, ref, watch, type ComputedRef, onMounted } from 'vue'
import { FORM_INPUT_TYPE } from '@/components/forms/form.enum'
import { FORM_SELECT_VALUE_TYPE } from '@/components/forms/form.enum'
import { GURU_ASSIGNMENT_ACTIONS, GURU_QUESTION_TYPE } from '@/components/guru/enums/teach.enum'
import { type ILanguage, noneLanguage } from '@/utils/sharedData/languages'
import TestCaseSection from '@/components/guru/modals/components/TestCaseSection.vue'
import type { ITestCase } from '@/components/guru/interface/ITestCase'
import { type IQuestionType } from '@/components/guru/interface/IQuestionType'
import { postQuestion } from '@/services/teach.service'
import { type IProgramQuestion_Request } from '@/components/guru/interface/requests.interface'
import { SelectedLanguageObject } from '@/components/guru/class/SelectedLanguageObject'
import { HttpStatusCode } from 'axios'
import router from '@/router'
import { type IQuestion } from '@/components/guru/interface/IQuestion'
import { useTeachStore } from '@/stores/teach.store'

//Assets and static data
const teachStore = useTeachStore()
const submitClicked = ref(false)
const mounted = ref(false)
const langData: ILanguage[] = [noneLanguage, ...teachStore.languagesSupportedByAssignment]
const versionData = ref(undefined as undefined | any[])
const testCasesHasErrors = ref<boolean[]>([] as boolean[])
const questionInput = {
  label: 'Question'
}

const markInput = {
  label: 'Mark/Score'
}

const negativeMarkInput = {
  label: 'Negative Mark/Score'
}

const choiceLevelMarkInput = {
  label: 'Score/Mark at Test Cases level'
}

const defaultLanguageInput = {
  label: 'Language'
}

const versionIndexInput = {
  label: 'Version'
}

const fieldsCopy = computed(() => {
  return fields.value
})

/**
 * @returns string
 */
function getButtonText(): string {
  if (props.mode === GURU_ASSIGNMENT_ACTIONS.ADD) {
    return 'Create Question'
  }

  if (props.mode === GURU_ASSIGNMENT_ACTIONS.EDIT) {
    return 'Save Changes'
  }

  return ''
}

const { errors, handleSubmit } = useForm({
  validationSchema: object({
    question: string().required().min(5).label('Question'),
    optionLevelMarking: boolean().default(false),
    testCases: array()
      .of(
        object({
          question: string().required().min(5).label('Test Case Title'),
          expectedAnswer: string().min(1).label('Expected Answer'),
          mark: number().nullable(),
          negativeMark: number().nullable()
        })
      )
      .label('Test Cases')
  })
})

const { value: markForCorrectAnswer, errorMessage: markForCorrectAnswerError } =
  useField('markForCorrectAnswer')

const { value: negativeMark, errorMessage: negativeMarkError } = useField('negativeMark')

const { value: question } = useField('question')

const { value: optionLevelMarking, errorMessage: optionLevelMarkingError } =
  useField<boolean>('optionLevelMarking')

optionLevelMarking.value = optionLevelMarking.value == undefined ? false : optionLevelMarking.value

const { value: language, errorMessage: languageError } = useField('language', string())

const { value: versionIndex, errorMessage: versionIndexError } = useField('versionIndex')

let { remove, push, fields } = useFieldArray('testCases')

const props = defineProps({
  formType: {
    type: String as PropType<GURU_QUESTION_TYPE>,
    require: true
  },
  instituteCode: {
    type: String,
    required: true
  },
  assignmentId: {
    type: Number,
    required: true
  },
  questionTypeObj: {
    type: Object as PropType<IQuestionType>,
    require: true
  },
  mode: {
    type: String as PropType<GURU_ASSIGNMENT_ACTIONS>,
    require: false
  },
  questionData: {
    type: Object as PropType<IQuestion | undefined>,
    require: false
  },
  enableNegativeMark: {
    type: Boolean,
    required: false
  },
  autoScore: {
    type: Boolean,
    required: false
  },
  autoCorrect: {
    type: Boolean,
    required: false
  }
})

// Watch edit vs add
watch(
  () => props.questionData,
  (editingQuestion) => {
    if (editingQuestion) {
      question.value = editingQuestion?.question
      language.value = editingQuestion?.language
      versionIndex.value = editingQuestion.versionIndex
      optionLevelMarking.value = editingQuestion.optionLevelMarking
      markForCorrectAnswer.value = editingQuestion.markForCorrectAnswer
      negativeMark.value = editingQuestion?.negativeMark

      //reset fields
      resetTestCaseArray()

      if (props.autoCorrect) {
        editingQuestion?.testCases?.forEach((testCase: ITestCase) => {
          push(testCase)
        })
      }
    } else {
      // console.log('Adding question')
      resetTestCaseArray()
      question.value = null
      language.value = teachStore.assignmentDefaultLanguage
      versionIndex.value = teachStore.assignmentDefaultLanguageVersionIndex
      optionLevelMarking.value = false
      markForCorrectAnswer.value = 0
      negativeMark.value = 0
    }
  }
)

const isOptionLevelMarking: ComputedRef<boolean> = computed(() => optionLevelMarking.value)

const canAddTestCase: ComputedRef<boolean> = computed(() => props.autoCorrect)

onMounted(() => {
  if (props.questionData) {
    // Default Values

    markForCorrectAnswer.value = 0
    language.value = props.questionData.language
      ? props.questionData.language
      : teachStore.assignmentDefaultLanguage

    versionIndex.value = props.questionData.versionIndex
      ? props.questionData.versionIndex
      : teachStore.assignmentDefaultLanguageVersionIndex

    versionData.value = langData.find((lang: ILanguage) => {
      return lang.language === language.value
    })?.versions

    mounted.value = true
  } else {
    markForCorrectAnswer.value = 0
    language.value = teachStore.assignmentDefaultLanguage
      ? teachStore.assignmentDefaultLanguage
      : 'none'

    versionIndex.value = teachStore.assignmentDefaultLanguageVersionIndex
      ? teachStore.assignmentDefaultLanguageVersionIndex
      : 0

    versionData.value = langData.find((lang: ILanguage) => {
      return lang.language === language.value
    })?.versions

    mounted.value = true
  }
})

watch(
  () => language.value,
  (selectedLang) => {
    versionData.value = langData.find((lang: ILanguage) => {
      return lang.language === selectedLang
    })?.versions

    if (props.questionData) {
      if (
        selectedLang == props.questionData.language ||
        selectedLang == teachStore.assignmentDefaultLanguage
      ) {
        // console.log('Dont change vindex')
      } else {
        versionIndex.value = 0
      }
    } else {
      if (selectedLang == teachStore.assignmentDefaultLanguage) {
        // console.log('Dont change vindex')
      } else {
        versionIndex.value = 0
      }
    }
  }
)

const errorsProp = ref({})
watch(
  () => errors.value,
  (err) => {
    errorsProp.value = err
  }
)

// watch(
//   () => fieldsCopy.value,
//   (change) => {
//     console.log('Watch fields', change)
//   }
// )

const satisfiesMinimumTestCaseRequirement = computed(() => {
  if (canAddTestCase.value) {
    return fields.value.length > 0 ? true : false
  }

  return true
})

const satisfiesLanguageRequirement = computed(() => {
  // if (canAddTestCase.value) {
  //   return language.value != 'none'
  // }

  // return true
  return language.value != 'none'
})

const defaultLangObj = computed(() => {
  return langData.find((lang: ILanguage) => lang.language == teachStore.assignmentDefaultLanguage)
})

const markForCorrectAnswerIsValid = computed(() => {
  if (props.autoScore) {
    if (isOptionLevelMarking.value) {
      return true
    } else {
      return markForCorrectAnswer.value
    }
  } else {
    return true
  }
})

const negativemarkIsValid = computed(() => {
  if (props.enableNegativeMark) {
    if (isOptionLevelMarking.value) {
      return true
    } else {
      return (negativeMark.value as number) < 0
    }
  } else {
    return true
  }
})

const canSubmit = computed(() => {
  if (Object.keys(errors.value).length !== 0) return false

  if (testCasesHasErrors.value.length > 0) return false

  if (!satisfiesMinimumTestCaseRequirement.value) return false

  if (!satisfiesLanguageRequirement.value) return false
  if (submitClicked.value) return false

  if (!markForCorrectAnswerIsValid.value) return false

  if (!negativemarkIsValid.value) return false

  return true
})

/**
 * @param hasErrors if a testcase has errors, the state will be true
 */
function hasErrorsState(hasErrors: boolean) {
  if (hasErrors == true) {
    testCasesHasErrors.value.push(true)
  } else {
    testCasesHasErrors.value.splice(0, 1)
  }
}

/**
 * Remove's previous testcases data
 */
function resetTestCaseArray() {
  const previousTestCasesLength = fields.value.length
  for (let i = 0; i < previousTestCasesLength; i++) {
    remove(0)
  }
}

const onSubmit = handleSubmit(async (values) => {
  const selectedLang = langData.find((lang: ILanguage) => lang.language == values.language)
  if (!selectedLang) return

  submitClicked.value = true
  const selectedLangObj = new SelectedLanguageObject(selectedLang)

  const formObject: IProgramQuestion_Request = {
    question: values.question,
    language: selectedLangObj,
    versionIndex: Number.parseInt(values.versionIndex as string),
    optionLevelMarking: values.optionLevelMarking,
    markForCorrectAnswer: values.markForCorrectAnswer,
    testCases: values.testCases,
    assQuestionId: props.questionData?.assQuestionId,
    negativeMark: props.enableNegativeMark ? values.negativeMark : undefined
  }

  const res = await postQuestion(
    formObject,
    props.questionTypeObj!,
    props.assignmentId,
    props.instituteCode
  )

  if (res == HttpStatusCode.Ok) {
    router.go(0)
  }
  submitClicked.value = false
})
</script>

<template>
  <form @submit.prevent="onSubmit">
    <div>
      <BaseTextbox
        :label="questionInput.label"
        :name="'question'"
        v-model="question"
        :error="errors['question']"
        :isLightGray="false"
      />

      <div>
        <BaseCheck
          v-if="props.autoScore"
          :label="choiceLevelMarkInput.label"
          v-model="optionLevelMarking"
          :error="optionLevelMarkingError"
        />
      </div>
      <div v-if="!isOptionLevelMarking && props.autoScore">
        <BaseInput
          :label="markInput.label"
          v-model="markForCorrectAnswer"
          :error="markForCorrectAnswerError"
          :inputType="FORM_INPUT_TYPE.NUMBER"
          :isLightGray="false"
          :is-small-label="true"
        />
        <p :class="['p-xsmall ', 'error']" v-if="!markForCorrectAnswerIsValid">
          Mark/Score is required
        </p>

        <!-- Negative Score -->
        <div v-if="props.enableNegativeMark">
          <BaseInput
            :label="negativeMarkInput.label"
            v-model="negativeMark"
            :error="negativeMarkError"
            :inputType="FORM_INPUT_TYPE.NUMBER"
            :isLightGray="false"
            :is-small-label="true"
          />
          <p :class="['p-xsmall ', 'error']" v-if="!negativeMark && negativeMark != 0">
            Negative Mark/Score is required
          </p>
        </div>
      </div>

      <!-- Language -->
      <BaseSelect
        :label="defaultLanguageInput.label"
        :list="langData"
        :default="defaultLangObj"
        v-model="language"
        :error="languageError"
        :valueType="FORM_SELECT_VALUE_TYPE.LANGUAGE"
        :isLightGray="false"
      />
      <p class="error text-xs" v-if="!satisfiesLanguageRequirement">Language cannot be 'none'</p>

      <BaseSelect
        v-if="versionData !== undefined"
        :label="versionIndexInput.label"
        :list="versionData"
        v-model="versionIndex"
        :error="versionIndexError"
        :valueType="FORM_SELECT_VALUE_TYPE.INDEX"
        :isLightGray="false"
      />

      <!-- Test Cases -->
    </div>
    <div class="my-5 flex items-center justify-between" v-if="canAddTestCase">
      <h2 class="text-xl">Test Cases</h2>
      <div>
        <button
          type="button"
          @click="
            push({
              question: null,
              expectedAnswer: null,
              input: '',
              arguments: '',
              mark: isOptionLevelMarking == true ? 0 : undefined,
              negativeMark: undefined,
              maximumCpuTimeAllowed: '',
              maximumMemoryAllowed: ''
            } as unknown as ITestCase)
          "
          class="btn-dashboard-small rounded-md p-2 text-xs"
        >
          Add Test Case
        </button>
      </div>
    </div>
    <p :class="['p-xsmall ', 'error']" v-if="!satisfiesMinimumTestCaseRequirement">
      Minimum One Test Case Required
    </p>
    <div class="flex flex-col gap-3">
      <div
        v-for="(field, index) in fieldsCopy"
        :key="index"
        class="rounded-md border border-slate-800 px-4 py-2"
      >
        <TestCaseSection
          :index="index"
          :testCaseObject="field.value"
          :errors="errorsProp"
          :remove="remove"
          :isOptionLevelMarking="isOptionLevelMarking"
          :enableNegativeMark="props.enableNegativeMark"
          :hasErrorsState="hasErrorsState"
        />
      </div>
    </div>
    <button :disabled="!canSubmit" type="submit" class="btn-primary btn-rounded-md">
      {{ getButtonText() }}
    </button>
  </form>
</template>
