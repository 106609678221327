<script setup lang="ts">
import { computed, ref, watch, onMounted, onBeforeUnmount } from 'vue'
import { getOneStudentGroup } from '@/services/teach.service'
import { useRoute } from 'vue-router'
import { STUDENT_GROUP_ACTIONS, TEACH_SECTION } from '@/components/guru/enums/teach.enum'
import { useTeachStore } from '@/stores/teach.store'
import DashboardCard, {
  type IDashboardCardLink
} from '@/components/shared/dashboard/DashboardCard.vue'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import AddEditStudentGroupComponent from './AddEditStudentGroupComponent.vue'
import StudentsTable from '@/components/guru/dashboard/users/StudentsTable.vue'
import StaffTable from '@/components/guru/dashboard/users/StaffTable.vue'
import { TEACHMODALHS } from '@/utils/models'

const teachStore = useTeachStore()
const breadcrumbStore = useBreadcrumbStore()
const route = useRoute()
const insCode = ref(route.params.insCode as string)
const studentGroupId = ref(Number.parseInt(route.params.studentGroupId as string))
const currentStudentGroup = computed(() => {
  return teachStore.editPrefilledStudentGroup
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const currentMode = computed(() =>
  teachStore.editPrefilledStudentGroup == undefined
    ? STUDENT_GROUP_ACTIONS.ADD
    : STUDENT_GROUP_ACTIONS.EDIT
)

onMounted(async () => {
  insCode.value = route.params.insCode as string
  studentGroupId.value = Number.parseInt(route.params.studentGroupId as string)
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)

  const res = await getOneStudentGroup(insCode.value, studentGroupId.value)
  teachStore.editPrefilledStudentGroup = res
})

onBeforeUnmount(() => {
  teachStore.editPrefilledStudentGroup = undefined
})

const breadcrumbLinks = computed(() => [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  },
  {
    href: '/dashboard/institution',
    title: 'Virtual Institutions'
  },
  {
    href: `/dashboard/institution/${insCode.value}`,
    title: `${teachStore.currentAssociation?.name}`
  },
  {
    href: `/dashboard/institution/${insCode.value}/student-groups`,
    title: `Student Groups`
  },
  {
    href: `/dashboard/institution/${insCode.value}/student-group/${studentGroupId.value}`,
    title: `${teachStore.editPrefilledStudentGroup?.name}`
  }
])

watch(
  () => [teachStore.currentAssociation, breadcrumbLinks.value],
  () => {
    breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
  }
)

const teacherBtn: IDashboardCardLink = {
  modal: TEACHMODALHS.ADD_USER_STAFF_OR_TEACHER,
  name: 'Add Teacher'
}
</script>

<template>
  <div class="grid grid-cols-4 grid-rows-3 gap-4">
    <AddEditStudentGroupComponent class="col-span-4 row-span-1" />

    <DashboardCard class="col-span-2 row-span-2">
      <StudentsTable
        :institute-code="insCode"
        :student-group-id="studentGroupId"
        :students="currentStudentGroup?.students"
        :section="TEACH_SECTION.STUDENT_GROUP"
      />
    </DashboardCard>

    <DashboardCard class="col-span-2 row-span-2" title="Teacher/Co-Owner" :link="teacherBtn">
      <StaffTable
        :institute-code="insCode"
        :student-group-id="studentGroupId"
        :staff="currentStudentGroup?.groupAdmins"
        :section="TEACH_SECTION.STUDENT_GROUP"
      />
    </DashboardCard>
  </div>
</template>
