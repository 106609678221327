<script setup lang="ts">
import PricingCard from '@/components/shared/PricingCard.vue'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import { platformPricingCardsData } from '@/utils/sharedData/platformPricingCards'

const pricingCards = platformPricingCardsData
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="grid gap-4 lg:grid-cols-3">
      <DashboardCard class="lg:col-span-2">
        <div class="text-primary flex flex-col gap-4 px-2">
          <h1 class="heading-medium">Why JDoodle Plugins?</h1>
          <p class="text-secondary leading-7">
            Limitless code execution. Upgrade to JDoodle's platform plan for:
          </p>
          <h3 class="heading-small">Key Benefits</h3>
          <ul class="text-secondary flex flex-col gap-4 leading-7">
            <li>
              <FontAwesomeIcon icon="fa-check" class="mr-2 h-2 w-2 rounded-full p-1 text-red-500" />
              White labeled plugins and free commercial use
            </li>
            <li>
              <FontAwesomeIcon icon="fa-check" class="mr-2 h-2 w-2 rounded-full p-1 text-red-500" />
              Multiple custom plugins
            </li>
            <li>
              <FontAwesomeIcon icon="fa-check" class="mr-2 h-2 w-2 rounded-full p-1 text-red-500" />
              A fully customizable dashboard
            </li>
            <li>
              <FontAwesomeIcon icon="fa-check" class="mr-2 h-2 w-2 rounded-full p-1 text-red-500" />
              Premium access to teach and assess platform
            </li>
          </ul>
        </div>
      </DashboardCard>

      <DashboardCard>
        <div class="text-primary flex flex-col gap-2 px-2">
          <div class="flex w-full flex-col items-center justify-center gap-4">
            <h1 class="heading-small text-center">
              Book a One on One call to understand the features
            </h1>
            <a type="button" class="btn btn-secondary w-fit" href="/contact-us"> Talk to Sales </a>
          </div>
          <h3 class="text-primary">Video Tutorial</h3>
          <p class="text-secondary leading-7">
            Check out our video tutorials on how to create a plugin
          </p>
          <div class="flex w-full gap-2">
            <iframe
              class="w-1/2"
              src="https://www.youtube.com/embed/lAJAPEzGRzc?si=mPA3nTJmOLSOejhk"
              title="YouTube video player"
              frameborder="0"
              allowfullscreen
            ></iframe>
            <iframe
              class="w-1/2"
              src="https://www.youtube.com/embed/ab7S9knqfww?si=IOJTAPX_65K-vPrk"
              title="YouTube video player"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </DashboardCard>
    </div>
    <!-- Cards -->
    <DashboardCard>
      <div class="grid auto-rows-fr grid-cols-6 gap-8">
        <PricingCard
          v-for="(card, index) in pricingCards"
          :key="index"
          :cardData="card"
          :isUserSubscribedToPlatform="false"
        />
      </div>
    </DashboardCard>
  </div>
</template>
