<script setup lang="ts">
import CaseStudyLogoAndIntro from '@/components/shared/casestudy/CaseStudyLogoAndIntro.vue'
import kansasLogo from '@/assets/images/casestudy/kansas/KansasUni2.png'

const sectionData = {
  image: {
    src: kansasLogo,
    alt: '',
    copyright: '© Sean Pavone from Getty Images Pro via Canva.com'
  },
  heading: 'University of Kansas',
  desc: 'Assessment and Technology Solutions (ATS) is a leading institution committed to delivering advanced technology solutions for academic assessments. With a focus on enhancing the learning experience, ATS aims to provide students and faculty with efficient, user-friendly and reliable assessment tools.'
}
</script>

<template>
  <CaseStudyLogoAndIntro :sectionData="sectionData" />
</template>
