<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useIdeStore } from '@/stores/ide.store'
import ModelLayout from '@/layouts/ModelLayout.vue'
import { IDEVIEWMODELS } from '@/utils/models'
import shareService from '@/services/ide/settings/share.service'

import CopyShare from '@/components/ide/shared/CopyShareComp.vue'

const ideStore = useIdeStore()
const route = useRoute()

const httpErrorTimeOut = ref<number | null>(null)
const httpError = ref<string>('')
const httpSuccessTimeOut = ref<number | null>(null)
const httpSuccess = ref<string>('')
const loadUrlsInProgress = ref<boolean>(false)

const projectUrl = ref<string | null>(null)
const embedUrl = ref<string | null>(null)
const pluginId = ref<string | null>(null)

const isAdvanced = computed(() => {
  return ideStore.isAdvanced
})
const isShowShared = computed(() => {
  return projectUrl.value !== null || embedUrl.value !== null
})
const isProjectUrl = computed(() => {
  return projectUrl.value || ''
})
const isEmbedUrl = computed(() => {
  return embedUrl.value || ''
})
const isPluginId = computed(() => {
  return pluginId.value || null
})
/**
 * Get the share url
 */
const share = async () => {
  projectUrl.value = null
  embedUrl.value = null
  loadUrlsInProgress.value = true

  await shareService
    .instantShare()
    .then((response) => {
      projectUrl.value = response?.data?.url || null
      embedUrl.value = response?.data?.embedUrl || null
      pluginId.value = response?.data?.pluginId || null
      httpSuccess.value = `Snapshot of your code is created. Copy the URL${
        !isAdvanced.value ? 's' : ''
      } immediately as every instant share creates a new copy of the program with a new URL.`
    })
    .catch((error) => {
      httpError.value =
        error?.response?.data?.message || 'Something went wrong. Please try again later.'
    })

  loadUrlsInProgress.value = false
}
/**
 * Initialize the copy editor
 */
const init = () => {
  projectUrl.value = null
  embedUrl.value = null
}

onMounted(() => {
  init()
  watch(route, () => {
    httpError.value = ''
    httpSuccess.value = ''
    init()
  })
  watch(httpError, () => {
    if (httpError.value) {
      if (httpErrorTimeOut.value) clearTimeout(httpErrorTimeOut.value)
      httpErrorTimeOut.value = setTimeout(() => {
        httpError.value = ''
      }, 8000)
    }
  })
  watch(httpSuccess, () => {
    if (httpSuccess.value) {
      if (httpSuccessTimeOut.value) clearTimeout(httpSuccessTimeOut.value)
      httpSuccessTimeOut.value = setTimeout(() => {
        httpSuccess.value = ''
      }, 5000)
    }
  })
})
</script>

<template>
  <ModelLayout title="Instant Share (No Login/Save required)" :hs="IDEVIEWMODELS.INSTANTSHARE">
    <p :class="['p-xsmall ', 'error', , { hidden: !httpError }]">
      {{ httpError }}
    </p>
    <p :class="['p-xsmall', 'success', { hidden: !httpSuccess }]">
      {{ httpSuccess }}
    </p>
    <p class="p-xsmall">
      Instant Share creates share<span v-if="!isAdvanced">/embed</span> urls for the current
      program, which can be used to share <span v-if="!isAdvanced"> or embed</span>. This share is a
      one time snapshot of the current program. Further changes in the program will not reflect in
      the share. Copy the URLs immediately as every instant share creates a new copy of the program
      with a new URL.
    </p>
    <CopyShare
      :isShowShared="isShowShared"
      :projectUrl="isProjectUrl"
      :showEmbed="!isAdvanced"
      :embedUrl="isEmbedUrl"
      :pluginId="(isPluginId as string)"
      :closeHsModal="IDEVIEWMODELS.INSTANTSHARE"
    />
    <div class="flex w-full gap-2">
      <button :disabled="loadUrlsInProgress" class="btn-primary btn-rounded-md" @click="share">
        Get Share Url<span v-if="!isAdvanced">s</span><span v-if="isShowShared"> Again</span>
      </button>
      <button
        class="btn-secondary btn-rounded-md"
        :data-hs-overlay="`#${IDEVIEWMODELS.INSTANTSHARE}`"
        data-hs-overlay-close
      >
        Close
      </button>
    </div>
  </ModelLayout>
</template>
