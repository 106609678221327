<script setup lang="ts">
import BaseSelect from '@/components/forms/BaseSelect.vue'
import { computed, watch, ref, onMounted } from 'vue'
import { TEACH_CONSTANTS } from '@/components/guru/constants/teach.constants'
import { GURU_ASSIGNMENT_ACTIONS, GURU_QUESTION_TYPE } from '@/components/guru/enums/teach.enum'
import { FORM_SELECT_VALUE_TYPE } from '@/components/forms/form.enum'
import { useRoute } from 'vue-router'
import QuestionMultipleChoice from '@/components/guru/modals/components/QuestionMultipleChoice.vue'
import QuestionOpenEnded from '@/components/guru/modals/components/QuestionOpenEnded.vue'
import QuestionTrueOrFalse from '@/components/guru/modals/components/QuestionTrueOrFalse.vue'
import QuestionProgram from '@/components/guru/modals/components/QuestionProgram.vue'
import { type IQuestionType } from '@/components/guru/interface/IQuestionType'
import type { IQuestion } from '@/components/guru/interface/IQuestion'
import { getQuestion } from '@/services/teach.service'
import { useTeachStore } from '@/stores/teach.store'

const props = defineProps({
  insCode: {
    type: String,
    required: true
  },
  enableNegativeMark: {
    type: Boolean,
    required: false
  },
  autoScore: {
    type: Boolean,
    required: false
  },
  autoCorrect: {
    type: Boolean,
    required: false
  },
  disclose: {
    type: Number,
    required: false
  }
})

const noOpenEndedForDiscloseMessage =
  'Open ended question can not be included in an Assignment with disclose type - "Auto Disclose at the time of submission". To include open ended question, please change the disclose type.'

const questionTypes = TEACH_CONSTANTS.QUESTION_TYPES
const teachStore = useTeachStore()
const route = useRoute()
const assignmentId = Number.parseInt(route.params.assignmentId as string)
// Form-related
const currentQuestionTypeValue = ref(
  getQuestionTypeValueWithName(teachStore.questionToEdit?.questionType) as number
)

const questionTypeObj = ref(getQuestionTypeObjWithValue(currentQuestionTypeValue.value))

const prefillQuestionData = ref(undefined as undefined | IQuestion)

const currentMode = ref(GURU_ASSIGNMENT_ACTIONS.ADD)

const questionTypeInput = {
  label: 'Question Type'
}
onMounted(async () => {
  if (teachStore.questionToEdit) {
    //Mode
    currentMode.value = GURU_ASSIGNMENT_ACTIONS.EDIT

    //Question Data
    const res = await getQuestion(teachStore.questionToEdit.id!, props.insCode, assignmentId)

    //Change QType
    currentQuestionTypeValue.value = getQuestionTypeValueWithName(
      teachStore.questionToEdit.questionType
    )

    prefillQuestionData.value = res
  } else {
    currentMode.value = GURU_ASSIGNMENT_ACTIONS.ADD
    prefillQuestionData.value = undefined
  }
})

//Get version values and default version
watch(
  () => currentQuestionTypeValue.value,
  (selectedQTypeValue) => {
    // Get the selected questionType
    questionTypeObj.value = getQuestionTypeObjWithValue(selectedQTypeValue)
  }
)

/**
 * @param typeName question type
 * @returns question type value
 */
function getQuestionTypeValueWithName(typeName: GURU_QUESTION_TYPE | undefined): number {
  if (typeName != undefined) {
    return questionTypes.find((questionTypeObj: IQuestionType) => typeName == questionTypeObj.type)
      ?.value!
  }

  //Default type
  return 0
}

const showOpenEndedForm = computed(() => {
  return props.disclose == 0
})

/**
 * @param value questionType value
 * @returns questionType object
 */
function getQuestionTypeObjWithValue(value: number): IQuestionType {
  if (value != undefined) {
    return questionTypes.find((questionTypeObj: IQuestionType) => value == questionTypeObj.value)!
  }
  //Default type
  return questionTypes[0]
}
</script>

<template>
  <!-- form -->
  <div>
    <BaseSelect
      :label="questionTypeInput.label"
      :list="questionTypes"
      v-model="currentQuestionTypeValue"
      :valueType="FORM_SELECT_VALUE_TYPE.VALUE"
      :isLightGray="false"
    />

    <!-- MC One Answer -->
    <div v-if="questionTypeObj?.type === GURU_QUESTION_TYPE.MC_ONE_ANSWER">
      <QuestionMultipleChoice
        :formType="GURU_QUESTION_TYPE.MC_ONE_ANSWER"
        :instituteCode="insCode"
        :assignmentId="assignmentId"
        :questionTypeObj="questionTypeObj"
        :mode="currentMode"
        :questionData="prefillQuestionData"
        :enableNegativeMark="props.enableNegativeMark"
        :autoScore="props.autoScore"
        :autoCorrect="props.autoCorrect"
      />
    </div>

    <!-- MC Multiple Answer -->
    <div v-if="questionTypeObj?.type === GURU_QUESTION_TYPE.MC_MULTIPLE_ANSWER">
      <QuestionMultipleChoice
        :formType="GURU_QUESTION_TYPE.MC_MULTIPLE_ANSWER"
        :instituteCode="insCode"
        :assignmentId="assignmentId"
        :questionTypeObj="questionTypeObj"
        :mode="currentMode"
        :questionData="prefillQuestionData"
        :enableNegativeMark="props.enableNegativeMark"
        :autoScore="props.autoScore"
        :autoCorrect="props.autoCorrect"
      />
    </div>

    <!-- Program -->
    <div v-if="questionTypeObj?.type === GURU_QUESTION_TYPE.PROGRAM">
      <QuestionProgram
        :instituteCode="insCode"
        :assignmentId="assignmentId"
        :questionTypeObj="questionTypeObj"
        :questionData="prefillQuestionData"
        :mode="currentMode"
        :enableNegativeMark="props.enableNegativeMark"
        :autoScore="props.autoScore"
        :autoCorrect="props.autoCorrect"
      />
    </div>

    <!-- Open Ended -->
    <div v-if="questionTypeObj?.type === GURU_QUESTION_TYPE.OPEN_ENDED">
      <QuestionOpenEnded
        v-if="showOpenEndedForm"
        :instituteCode="insCode"
        :assignmentId="assignmentId"
        :questionTypeObj="questionTypeObj"
        :questionData="prefillQuestionData"
        :mode="currentMode"
      />

      <div v-if="!showOpenEndedForm" class="section-secondary mt-5 rounded-md p-3 text-sm">
        {{ noOpenEndedForDiscloseMessage }}
      </div>
    </div>

    <!-- T/F -->
    <div v-if="questionTypeObj?.type === GURU_QUESTION_TYPE.TRUE_OR_FALSE">
      <QuestionTrueOrFalse
        :instituteCode="insCode"
        :assignmentId="assignmentId"
        :questionTypeObj="questionTypeObj"
        :questionData="prefillQuestionData"
        :mode="currentMode"
        :enableNegativeMark="props.enableNegativeMark"
        :autoScore="props.autoScore"
        :autoCorrect="props.autoCorrect"
      />
    </div>
  </div>
</template>
