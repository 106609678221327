<script setup lang="ts">
import BaseInput from '@/components/forms/BaseInput.vue'
import BaseTextbox from '@/components/forms/BaseTextbox.vue'
import BaseCheck from '@/components/forms/BaseCheck.vue'
import { array, boolean, object, string } from 'yup'
import { useField, useFieldArray, useForm } from 'vee-validate'
import type { IMultipleChoiceOption } from '@/components/guru/interface/IMultipleChoiceOption'
import type { IQuestion } from '@/components/guru/interface/IQuestion'
import { type PropType, computed, ref, watch } from 'vue'
import { FORM_INPUT_TYPE } from '@/components/forms/form.enum'
import { GURU_ASSIGNMENT_ACTIONS, GURU_QUESTION_TYPE } from '@/components/guru/enums/teach.enum'
import { type IQuestionType } from '@/components/guru/interface/IQuestionType'
import { type IMultipleChoiceQuestion_Request } from '@/components/guru/interface/requests.interface'
import { postQuestion } from '@/services/teach.service'
import { HttpStatusCode } from 'axios'
import router from '@/router'

const questionInput = {
  label: 'Question'
}

const markInput = {
  label: 'Mark/Score'
}

const negativeMarkInput = {
  label: 'Negative Mark/Score'
}

const optionLevelMarkingInput = {
  label: 'Score/Mark at Choice level'
}

/**
 * @returns button text
 */
function getButtonText(): string {
  if (props.mode === GURU_ASSIGNMENT_ACTIONS.ADD) {
    return 'Create Question'
  }

  if (props.mode === GURU_ASSIGNMENT_ACTIONS.EDIT) {
    return 'Save Changes'
  }

  return ''
}

// Manually filter errors for form instances?

const correctOptionValidator = ref([] as number[])

const { errors, handleSubmit } = useForm({
  validationSchema: object({
    question: string().required().min(5).label('Question'),
    optionLevelMarking: boolean().default(false),
    options: array()
      .of(
        object({
          question: string().required().min(5).label('Option')
        })
      )
      .required()
      .min(2)
      .label('Options')
  })
})

const { value: markForCorrectAnswer, errorMessage: markForCorrectAnswerError } =
  useField('markForCorrectAnswer')

const { value: negativeMark, errorMessage: negativeMarkError } = useField('negativeMark')

const { value: question, errorMessage: questionError } = useField('question')

const { value: optionLevelMarking, errorMessage: optionLevelMarkingError } =
  useField('optionLevelMarking')

optionLevelMarking.value = ref(
  optionLevelMarking.value == undefined || optionLevelMarking.value == null
    ? false
    : optionLevelMarking.value
).value

const { value: correctAnswerOptionInput } = useField('correctAnswerOption')

const props = defineProps({
  formType: {
    type: String as PropType<GURU_QUESTION_TYPE>,
    require: true
  },
  instituteCode: {
    type: String,
    required: true
  },
  assignmentId: {
    type: Number,
    required: true
  },
  questionTypeObj: {
    type: Object as PropType<IQuestionType>,
    require: true
  },
  mode: {
    type: String as PropType<GURU_ASSIGNMENT_ACTIONS>,
    require: false
  },
  questionData: {
    type: Object as PropType<IQuestion | undefined>,
    require: false
  },
  enableNegativeMark: {
    type: Boolean,
    required: false
  },
  autoScore: {
    type: Boolean,
    required: false
  },
  autoCorrect: {
    type: Boolean,
    required: false
  }
})

const isOptionLevelMarking = ref(optionLevelMarking.value)
const formType = ref(props.formType)
const submitClicked = ref(false)
// Default Values
markForCorrectAnswer.value = 0
negativeMark.value = 0

watch(
  () => props.formType,
  (changedType) => {
    formType.value = changedType
  }
)

watch(
  () => optionLevelMarking.value,
  (changedMarking) => {
    isOptionLevelMarking.value = changedMarking
  }
)

watch(
  () => props.questionData,
  (editingQuestion) => {
    if (editingQuestion) {
      markForCorrectAnswer.value = editingQuestion?.markForCorrectAnswer
      negativeMark.value = editingQuestion?.negativeMark
      question.value = editingQuestion?.question
      optionLevelMarking.value = editingQuestion?.optionLevelMarking

      editingQuestion?.options?.forEach((option: IMultipleChoiceOption, index: number) => {
        push(option)
        if (option.isCorrectAnswer == true) {
          correctOptionValidator.value.push(index)
          correctAnswerOptionInput.value = index
        }
      })
    } else {
      //default
      markForCorrectAnswer.value = 0
      negativeMark.value = 0
      question.value = null
      optionLevelMarking.value = false
      correctAnswerOptionInput.value = null
      fields.value = []
    }
  }
)

const { remove, push, fields } = useFieldArray('options')
const optionsHaveErrors = ref(false)

watch(
  () => [isOptionLevelMarking.value, fields],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ([optionLevelMarkingChange, fieldsChange]) => {
    let choicesHaveErrors = true
    const fieldsWithErrors = fields.value.filter((fieldItem) => {
      if (optionLevelMarkingChange) {
        let errorAtMark =
          (fieldItem.value as IMultipleChoiceOption).isCorrectAnswer &&
          ((fieldItem.value as IMultipleChoiceOption).mark == undefined ||
            (fieldItem.value as IMultipleChoiceOption).mark == null ||
            Number.isNaN((fieldItem.value as IMultipleChoiceOption).mark))

        if (props.enableNegativeMark) {
          return (
            //Negative mark is not null AND is less than 0
            (fieldItem.value as IMultipleChoiceOption).negativeMark == null ||
            (fieldItem.value as IMultipleChoiceOption).negativeMark == undefined ||
            Number.isNaN((fieldItem.value as IMultipleChoiceOption).negativeMark) ||
            (fieldItem.value as IMultipleChoiceOption).negativeMark! >= 0 ||
            //Mark is not null
            errorAtMark
          )
        }

        return errorAtMark
      }
    })

    if (fieldsWithErrors.length > 0) {
      choicesHaveErrors = true
    } else {
      choicesHaveErrors = false
    }

    optionsHaveErrors.value = choicesHaveErrors
  },
  { deep: true }
)

const canSubmit = computed(() => {
  if (Object.keys(errors.value).length !== 0) return false
  if (correctOptionValidator.value.length <= 0) return false
  if (optionsHaveErrors.value) return false
  if (submitClicked.value) return false
  if (!markForCorrectAnswerIsValid.value) return false
  if (!negativemarkIsValid.value) return false

  return true
})

const markForCorrectAnswerIsValid = computed(() => {
  if (props.autoScore) {
    if (isOptionLevelMarking.value) {
      return true
    } else {
      return markForCorrectAnswer.value
    }
  } else {
    return true
  }
})

const negativemarkIsValid = computed(() => {
  if (props.enableNegativeMark) {
    if (isOptionLevelMarking.value) {
      return true
    } else {
      return (negativeMark.value as number) < 0
    }
  } else {
    return true
  }
})

const onSubmit = handleSubmit(async (values) => {
  submitClicked.value = true
  const formObject: IMultipleChoiceQuestion_Request = {
    question: values.question,
    optionLevelMarking: values.optionLevelMarking,
    markForCorrectAnswer: values.markForCorrectAnswer,
    options: values.options,
    correctAnswerOption: values.correctAnswerOption,
    assQuestionId: props.questionData?.assQuestionId,
    negativeMark: props.enableNegativeMark ? values.negativeMark : undefined
  }

  const res = await postQuestion(
    formObject,
    props.questionTypeObj!,
    props.assignmentId,
    props.instituteCode
  )

  if (res == HttpStatusCode.Ok) router.go(0)

  submitClicked.value = false
})

/**
 * @param index Index of the selected Radio
 */
const selectRadioChangeList = (index: number) => {
  for (let i = 0; i < fields.value.length; i++) {
    if (i == index) {
      ;(fields.value[i].value as IMultipleChoiceOption).isCorrectAnswer = true
      correctAnswerOptionInput.value = index
      correctOptionValidator.value = [index]
    } else {
      ;(fields.value[i].value as IMultipleChoiceOption).isCorrectAnswer = false
    }
  }
}

/**
 * @param index Index of the selected Radio
 * @param event event
 */
const selectCheckbox = (index: number, event: Event) => {
  ;(fields.value[index].value as IMultipleChoiceOption).isCorrectAnswer = (
    event.target as HTMLInputElement
  ).checked

  //Add or remove from validator arr
  if ((event.target as HTMLInputElement).checked) {
    correctOptionValidator.value.push(index)
  } else {
    correctOptionValidator.value = ref(
      correctOptionValidator.value.filter((item: number) => item !== index)
    ).value
  }
}

/**
 * Removes a choice from form field and updates the choice validator
 * @param field the choice form field
 * @param index index of question
 */
const removeChoice = (field: any, index: number) => {
  //Remove form field
  remove(index)

  if ((field.value as IMultipleChoiceOption).isCorrectAnswer == true) {
    //Remove one item (any item) from array of correct options
    correctOptionValidator.value.splice(0, 1)
  }
}
</script>

<template>
  <form @submit.prevent="onSubmit">
    <div>
      <BaseTextbox
        :label="questionInput.label"
        :name="'question'"
        v-model="question"
        :error="questionError"
        :isLightGray="false"
      />

      <!-- Choice level checkbox -->
      <div v-if="formType == GURU_QUESTION_TYPE.MC_MULTIPLE_ANSWER">
        <BaseCheck
          :label="optionLevelMarkingInput.label"
          v-model="optionLevelMarking"
          :error="optionLevelMarkingError"
        />
      </div>
      <!-- Mark/Score -->
      <div v-if="!isOptionLevelMarking && props.autoScore">
        <BaseInput
          :label="markInput.label"
          v-model="markForCorrectAnswer"
          :error="markForCorrectAnswerError"
          :inputType="FORM_INPUT_TYPE.NUMBER"
          :is-light-gray="false"
        />
        <p :class="['p-xsmall ', 'error']" v-if="!markForCorrectAnswerIsValid">
          Mark/Score is required
        </p>

        <!-- Negative Score -->
        <div v-if="props.enableNegativeMark">
          <BaseInput
            :label="negativeMarkInput.label"
            v-model="negativeMark"
            :error="negativeMarkError"
            :maxInt="0"
            :inputType="FORM_INPUT_TYPE.NUMBER"
            :isLightGray="false"
            :is-small-label="true"
          />
          <p :class="['p-xsmall ', 'error']" v-if="!negativeMark && negativeMark != 0">
            Negative Mark/Score is required
          </p>
        </div>
      </div>
    </div>

    <!-- === Choices Section === -->
    <div class="my-5 flex justify-between">
      <h2 class="heading-small">Choices</h2>
      <div>
        <button
          :disabled="fields.length >= 10"
          type="button"
          @click="
            fields.length < 10
              ? push({
                  question: '',
                  isCorrectAnswer: false,
                  mark: undefined,
                  negativeMark: undefined
                } as IMultipleChoiceOption)
              : () => {}
          "
          class="btn-primary rounded-md p-1 text-sm"
        >
          Add Choice
        </button>
      </div>
    </div>
    <!-- Errors -->
    <p :class="['p-xsmall ', 'error']" v-if="errors['options']">
      {{ errors['options'] }}
    </p>
    <p :class="['p-xsmall ', 'error']" v-if="correctOptionValidator.length == 0">
      Must have a correct answer
    </p>

    <!-- Recursive options -->
    <div class="flex flex-col gap-3">
      <div
        v-for="(field, idx) in fields"
        :key="idx"
        class="rounded-md border border-slate-700 px-2 pb-2"
      >
        <div class="flex justify-start">
          <BaseTextbox
            :label="`Choice ${idx + 1}`"
            v-model="(field.value as IMultipleChoiceOption).question"
            class="w-[95%]"
            :error="errors[`options[${idx}].question`] ? errors[`options[${idx}].question`] : ''"
            :isLightGray="false"
          />
          <button class="" @click="removeChoice(field, idx)" type="button">
            <FontAwesomeIcon icon="fa-trash" class="error h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <!-- ==Choose correct answer section== -->
        <!-- Radio button  -->
        <div class="flex gap-3" v-if="formType == GURU_QUESTION_TYPE.MC_ONE_ANSWER">
          <input
            type="radio"
            name="correctOption"
            :id="`isCorrectChoice${idx}`"
            :checked="(field.value as IMultipleChoiceOption).isCorrectAnswer"
            @change="selectRadioChangeList(idx)"
          />
          <label class="text-sm" :id="`isCorrectChoice${idx}`" :for="`isCorrectChoice${idx}`"
            >Is Correct Answer?</label
          >
        </div>

        <!-- Checkbox, if isOptionLevelMarking -->
        <div class="flex flex-col gap-1" v-if="formType == GURU_QUESTION_TYPE.MC_MULTIPLE_ANSWER">
          <div class="flex gap-3">
            <input
              type="checkbox"
              :name="`correctOption${idx}`"
              :id="`isCorrectChoice${idx}`"
              :checked="(field.value as IMultipleChoiceOption).isCorrectAnswer"
              @change="selectCheckbox(idx, $event)"
            />
            <label class="text-sm" :id="`isCorrectChoice${idx}`" :for="`isCorrectChoice${idx}`"
              >Is Correct Answer?</label
            >
          </div>

          <!-- Mark -->
          <BaseInput
            v-if="(field.value as IMultipleChoiceOption).isCorrectAnswer && isOptionLevelMarking"
            :label="markInput.label"
            v-model="(field.value as IMultipleChoiceOption).mark"
            :inputType="FORM_INPUT_TYPE.NUMBER"
            :is-light-gray="false"
          />
          <p
            class="error text-xs"
            v-if="(field.value as IMultipleChoiceOption).isCorrectAnswer && isOptionLevelMarking && (!(field.value as IMultipleChoiceOption).mark && (field.value as IMultipleChoiceOption).mark != 0)"
          >
            Mark/Score is required
          </p>

          <!-- Negative Mark -->
          <BaseInput
            v-if="isOptionLevelMarking && enableNegativeMark"
            v-model="(field.value as IMultipleChoiceOption).negativeMark"
            :label="negativeMarkInput.label"
            :maxInt="0"
            :inputType="FORM_INPUT_TYPE.NUMBER"
            :is-light-gray="false"
          />
          <p
            class="error text-xs"
            v-if="isOptionLevelMarking && enableNegativeMark && (!(field.value as IMultipleChoiceOption).negativeMark && (field.value as IMultipleChoiceOption).negativeMark != 0)"
          >
            Negative Mark/Score is required
          </p>
        </div>
      </div>
    </div>
    <button :disabled="!canSubmit" type="submit" class="btn-primary btn-rounded-md">
      {{ getButtonText() }}
    </button>
  </form>
</template>
