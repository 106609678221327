<script setup lang="ts">
import { getAssignments, getOneAssignment } from '@/services/teach.service'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import type IAssignmentDetailed from '@/components/guru/interface/IAssignmentDetailed'
import AssignmentQuestionList from '@/components/guru/dashboard/assignment/AssignmentQuestionList.vue'
import StaffTable from '@/components/guru/dashboard/users/StaffTable.vue'

import { GURU_ASSIGNMENT_ACTIONS, TEACH_SECTION } from '@/components/guru/enums/teach.enum'

// import TeachBreadcrumb from '@/components/guru/shared/TeachBreadcrumb.vue'
import { useTeachStore } from '@/stores/teach.store'
import DashboardCard, {
  type IDashboardCardLink
} from '@/components/shared/dashboard/DashboardCard.vue'
import StudentsSummary from '@/components/guru/dashboard/users/StudentsSummary.vue'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import AssignmentDetails from './AssignmentDetails.vue'
import type { IQuestion_ForOneAssignment } from '@/components/guru/interface/IQuestion_ForOneAssignment'
import type { IQuestion } from '@/components/guru/interface/IQuestion'
import QuestionCreateEditComponent from '@/components/guru/dashboard/assignment/QuestionCreateEditComponent.vue'
import { TEACHMODALHS } from '@/utils/models'

const route = useRoute()
const teachStore = useTeachStore()
const breadcrumbStore = useBreadcrumbStore()
const insCode = route.params.insCode as string
const assignmentId = Number.parseInt(route.params.assignmentId as string)
const assignmentData = ref(undefined as undefined | IAssignmentDetailed)
const currentMode = ref(undefined as undefined | GURU_ASSIGNMENT_ACTIONS)

const questionObjectToEdit = ref(undefined as IQuestion_ForOneAssignment | undefined)
const questionObjectToEditData = ref(undefined as IQuestion | undefined)
onMounted(async () => {
  const res = await getOneAssignment(insCode, assignmentId)
  assignmentData.value = res
  teachStore.setCurrentAssignment(res)
  teachStore.setAssignmentLanguage(assignmentData.value)

  if (!teachStore.instituteAssignments) {
    const res = await getAssignments(insCode)
    teachStore.setAssignments(res)
  }
})

/**
 * Opens add user modal
 */
function handleAddUser() {
  teachStore.showQuestionForm = !teachStore.showQuestionForm
  teachStore.questionToEdit = undefined
}

const breadcrumbLinks = computed(() => [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  },
  {
    href: '/dashboard/institution',
    title: 'Virtual Institutions'
  },
  {
    href: `/dashboard/institution/${insCode}`,
    title: `${teachStore.currentAssociation?.name}`
  },
  {
    href: `/dashboard/institution/${insCode}/assignments`,
    title: `Assignments`
  },
  {
    href: `/dashboard/institution/${insCode}/assignment/${assignmentId}`,
    title: `${assignmentData.value?.title}`
  }
])

watch(
  () => breadcrumbLinks.value,
  () => {
    breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
  }
)

const questionCardTitle = computed(() => {
  if (questionObjectToEdit.value) {
    return 'Edit Question'
  }
  return 'Add Question'
})

const addOrBackBtnText = computed(() => {
  if (teachStore.showQuestionForm) {
    return 'Go Back'
  }
  return 'Add Question'
})

const teacherBtn: IDashboardCardLink = {
  modal: TEACHMODALHS.ADD_USER_STAFF_OR_TEACHER,
  name: 'Add Teacher'
}
</script>

<template>
  <!-- <TeachBreadcrumb :linkList="linkList" /> -->
  <div class="grid grid-cols-8 grid-rows-3 gap-4">
    <!-- Assignment details -->
    <DashboardCard class="col-span-3 row-span-1" :title="'Assignment'">
      <AssignmentDetails :assignment="assignmentData" :currentMode="currentMode" />
    </DashboardCard>
    <DashboardCard class="col-span-5 row-span-4">
      <div class="flex h-fit items-center justify-between border-b border-b-slate-700 pb-5">
        <h3 class="inline-block text-lg font-medium">{{ questionCardTitle }}</h3>
        <button
          class="btn-dashboard-small h-fit w-fit rounded-md px-4 py-2 text-sm"
          :disabled="teachStore.questionsLimitReached && !teachStore.showQuestionForm"
          @click="handleAddUser"
        >
          <FontAwesomeIcon :icon="teachStore.showQuestionForm ? 'fa-arrow-left' : 'fa-plus'" />
          {{ addOrBackBtnText }}
        </button>
      </div>
      <!-- Question -->
      <AssignmentQuestionList
        v-if="!teachStore.showQuestionForm"
        :questions="assignmentData?.questions"
        :assignmentId="assignmentId"
        :instituteCode="insCode"
        :enableNegativeMark="assignmentData?.enableNegativeMark"
        :autoScore="assignmentData?.autoScore"
        :autoCorrect="assignmentData?.autoCorrect"
        :disclose="assignmentData?.disclose"
      />

      <!-- Question Form -->
      <QuestionCreateEditComponent
        v-if="teachStore.showQuestionForm"
        :ins-code="insCode"
        :question="questionObjectToEditData"
        :assignmentId="assignmentId"
        :instituteCode="insCode"
        :enableNegativeMark="assignmentData?.enableNegativeMark"
        :autoScore="assignmentData?.autoScore"
        :autoCorrect="assignmentData?.autoCorrect"
        :disclose="assignmentData?.disclose"
      />
    </DashboardCard>

    <DashboardCard class="col-span-3 row-span-1" title="Teacher/Co-Owner" :link="teacherBtn">
      <StaffTable
        :staff="assignmentData?.coOwners"
        :institute-code="insCode"
        :section="TEACH_SECTION.ASSIGNMENT"
        :assignment-id="assignmentData?.insAssignmentId"
      />
    </DashboardCard>

    <DashboardCard class="col-span-3 row-span-1">
      <div class="flex h-fit items-center justify-between">
        <h3 class="inline-block text-lg font-medium">Students</h3>
        <RouterLink
          :to="`/dashboard/institution/${insCode}/assignment/${assignmentId}/manage-students`"
          class="btn-dashboard-small h-fit w-fit rounded-md px-4 py-2 text-sm"
        >
          Manage
        </RouterLink>
      </div>
      <StudentsSummary
        :students="assignmentData?.students"
        :institute-code="insCode"
        :assignment-id="assignmentId"
      />
    </DashboardCard>
  </div>
</template>
