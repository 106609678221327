<script setup lang="ts">
import UseCaseBanner, { type IUseCaseBannerData } from '@/components/shared/UseCaseBanner.vue'
import Homepage_4 from '@/assets/images/svg/Homepage_4.svg'

const teachAndAssessSection: IUseCaseBannerData = {
  orangeTextAboveTitle: 'Software Library Providers',
  title: "Showcase your software library's prowess and",
  highlightedTitle: ' shorten your sales cycle by 50%',

  description:
    'Easily integrate our secure compiler APIs and Plugins into your website to let customers try your product and make your documentation more interactive.',
  secondaryButton: {
    text: 'Learn More',
    link: '/solutions/software-library-demostration'
  },
  textLeftImageRight: true,
  widerTitle: true,
  image: {
    src: Homepage_4,
    alt: 'animation svg'
  }
}
</script>

<template>
  <div class="section-primary">
    <UseCaseBanner :sectionData="teachAndAssessSection"> </UseCaseBanner>
  </div>
</template>
