<script setup lang="ts">
import { loadSnitcher, unloadSnitcher } from '@/services/snitcher.service'
import { initBundle, initCompilerApi } from '@/services/subscription.service'
import { useAuthStore } from '@/stores/auth.store'
import { useSubscribeStore } from '@/stores/subscribe.store'
import { apiPlansData, pluginPlansData } from '@/utils/sharedData/plans'
import { find } from 'lodash'
import { SUBSCRIPTION_TYPE_ENUM } from '@/components/shared/subscription/enum/subscription.enum'
import { computed, onMounted, onUnmounted, reactive, ref, type PropType, watch } from 'vue'
import { SUBSCRIPTION_MODAL } from '@/utils/models'
import PlanConfirmationAndSubscribe from '@/components/shared/subscription/PlanConfirmationAndSubscribe.vue'
import ModelLayout from '@/layouts/ModelLayout.vue'

const authStore = useAuthStore()
const subscribeStore = useSubscribeStore()

const isSnitcherLoaded = ref<boolean>(false)

const isUserloggedIn = computed(() => {
  return authStore.isLogedIn
})

const props = defineProps({
  subscriptionType: {
    type: String as PropType<SUBSCRIPTION_TYPE_ENUM>,
    required: true
  }
})

const currentPlanType = computed(() => {
  return props.subscriptionType
})

// For pricing page tab changes
watch(
  () => props.subscriptionType,
  async () => {
    await initPage()
  }
)

watch(
  () => subscribeStore.showSubscribeOverlay,
  async (val) => {
    if (val) {
      await initPage()
    }
  }
)

const tabProperties = reactive({
  isUserLoggedIn: isUserloggedIn,
  stripe: {},
  stripCard: {},
  clientPlan: ('' as string) || null,
  isUnlimited: false as any,
  clientFuturePlan: null,
  clientFuturePlanDetail: {},
  clientPlanDetail: {},
  isSubscriptionCancelled: false,
  hasPayAccount: false,
  stripeKey: ''
})

const plans = computed(() => {
  if (currentPlanType.value == SUBSCRIPTION_TYPE_ENUM.BUNDLE) {
    return pluginPlansData
  } else {
    return apiPlansData
  }
})

/**
 *
 */
async function initPage() {
  try {
    let data = undefined

    // Init Bundle or Compiler
    if (props.subscriptionType == SUBSCRIPTION_TYPE_ENUM.BUNDLE) {
      data = await initBundle()
    } else {
      data = await initCompilerApi()
    }

    if (data.plan) {
      tabProperties.clientPlan = data.plan
      tabProperties.isUnlimited = data.isUnlimited
      tabProperties.clientPlanDetail = find(plans.value, { name: data.plan }) || {}
      tabProperties.isSubscriptionCancelled = data.isCancelled
      tabProperties.hasPayAccount = data.hasStripeAccount
    } else {
      tabProperties.clientPlanDetail = {}
      tabProperties.clientPlan = null
      tabProperties.isUnlimited = false
      tabProperties.isSubscriptionCancelled = false
      tabProperties.hasPayAccount = false

      loadSnitcher()
      isSnitcherLoaded.value = true
    }

    if (data.futurePlan) {
      tabProperties.clientFuturePlan = data.futurePlan
      tabProperties.clientFuturePlanDetail =
        find(plans.value, {
          name: data.futurePlan
        }) || {}
    } else {
      tabProperties.clientFuturePlan = null
      tabProperties.clientFuturePlanDetail = {}
    }
    tabProperties.stripeKey = data.stripeKey
  } catch (e) {
    console.error(e)
  }
}

/**
 *
 */
function subscribeClosed() {
  subscribeStore.SubscribeOverlayFalse()
}

onMounted(async () => {
  await initPage()
})
onUnmounted(() => {
  if (isSnitcherLoaded.value) {
    unloadSnitcher()
    isSnitcherLoaded.value = false
  }
})
</script>

<template>
  <ModelLayout title="" :hs="SUBSCRIPTION_MODAL.SUBSCRIBE" @closeSubscribe="subscribeClosed">
    <PlanConfirmationAndSubscribe
      v-if="subscribeStore.showSubscribeOverlay && tabProperties.stripeKey"
      :tabProperties="tabProperties"
      :plans="plans"
      :subscriptionType="props.subscriptionType"
    />
  </ModelLayout>
</template>
