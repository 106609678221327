<script setup lang="ts">
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'

import ChangeUserName from '@/components/dashboard/settings/ChangeUserName.vue'
import ChangePassword from '@/components/dashboard/settings/ChangePassword.vue'
import type ILink from '@/components/shared/interface/ILink'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import { onMounted } from 'vue'
const breadcrumbStore = useBreadcrumbStore()
const breadcrumbLinks: ILink[] = [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  },
  {
    href: '/dashboard/settings',
    title: 'Settings'
  }
]

onMounted(() => {
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks)
})
</script>

<template>
  <div class="flex flex-col gap-4 lg:w-1/2">
    <DashboardCard class="" title="Change Display Name">
      <ChangeUserName />
    </DashboardCard>
    <DashboardCard class="" title="Change Password">
      <ChangePassword />
    </DashboardCard>
  </div>
</template>
