<script lang="ts" setup>
import CaseStudyChallengesAndSolutions from '@/components/shared/casestudy/CaseStudyChallengesAndSolutions.vue'
import linkgroup3 from '@/assets/images/casestudy/linkgroup/LinkGroup3.png'

const sectionData = {
  paragraph1:
    'LINK Group faced a critical challenge in ensuring that their teaching materials were not only informative but also highly interactive. The existing setup posed a significant obstacle: students were unable to execute code directly within their Learning Management System (LMS). This disjointed learning experience required students to navigate between the LMS and external coding environments, leading to frustration and inefficiency in the learning process.',
  qouteText:
    "JDoodle created an LMS extension that enhanced our teaching materials' functionality and interactivity. Students can now engage seamlessly with course content, eliminating previous learning obstacles. The JDoodle team's professionalism and responsiveness ensured a successful collaboration. We highly recommend their high-quality software solutions and business support.",
  qoutedBy: 'Dragoljub Ćatović, E-Learning Coordinator, LINK Edu Alliance',
  paragraph2:
    'To address this challenge and enhance the interactivity of their teaching materials, LINK Group partnered with JDoodle to develop an extension for their LMS system. The primary goals were to improve functionality and create a more engaging and streamlined learning experience.',
  benefitheading: "JDoodle's solution brought several substantial advantages:",
  benefitlist: [
    {
      title: 'Interactive Learning within LMS:',
      desc: "The JDoodle compiler plugins allowed students to execute code directly within the LMS, eliminating the need to switch to external coding platforms. This seamless integration enhanced the students' learning experience by providing a cohesive and interactive environment."
    },
    {
      title: 'Improved Functionality:',
      desc: 'The extension extended the capabilities of the LMS system, enabling LINK Group to create richer, more interactive educational content. Students could now practice coding exercises, receive immediate feedback, and engage with course materials without leaving the LMS.'
    },
    {
      title: 'Seamless Integration:',
      desc: "JDoodle ensured that the plugins seamlessly integrated with LINK Group's existing LMS infrastructure, minimizing disruption and complexity. This integration eliminated the disjoined learning experiences that students previously encountered."
    },
    {
      title: 'Customization:',
      desc: 'The JDoodle team worked closely with Outlier.org to implement custom features tailored to their curriculum, ensuring a perfect fit for their courses.'
    },
    {
      title: 'Responsive Support:',
      desc: "Throughout the project, JDoodle provided professional and responsive support, addressing LINK Group's concerns and requirements promptly. The collaboration between the teams ensured a smooth transition to the enhanced learning environment."
    }
  ],
  image3: {
    src: linkgroup3,
    alt: '',
    copyright: '© mediaphotos from Getty Images Signature via Canva.com'
  },
  result: {
    paragraph:
      'The collaboration between LINK Group and JDoodle resulted in a remarkable transformation of their teaching materials and the learning experience.',
    list: [
      {
        title: 'Enhanced Engagement:',
        desc: 'The interactive teaching materials made learning more engaging and enjoyable, leading to increased student participation and retention. Students could now practice coding exercises directly within the LMS, making learning a more cohesive experience.'
      },
      {
        title: 'Efficient Learning:',
        desc: 'The improved functionality allowed educators to create more efficient and effective course content, ultimately saving time and resources. Students could seamlessly transition between learning and coding, eliminating the need for external tools.'
      },
      {
        title: 'Positive Feedback:',
        desc: 'Students and educators provided positive feedback on the enhanced interactivity, praising the newfound dynamism in the learning process. The frustrations associated with disjointed learning experiences were eliminated.'
      },

      {
        title: 'Streamlined Delivery: ',
        desc: 'LINK Group successfully addressed the challenges they faced in delivering effective and engaging content, making their courses more competitive and valuable. The LMS became a central hub for both learning and coding, simplifying the educational journey.'
      }
    ]
  }
}
</script>

<template>
  <CaseStudyChallengesAndSolutions :sectionData="sectionData" />
</template>
