<script setup lang="ts">
import AssignmentAsStudentBlock from '@/components/guru/dashboard/AssignmentAsStudentBlock.vue'
import { TEACH_STUDENT_ASSIGNMENT_STATUS } from '@/components/guru/enums/teach.enum'
import type IAssignmentDetailed from '@/components/guru/interface/IAssignmentDetailed'
import type IAssignmentMini from '@/components/guru/interface/IAssignmentMini'
import type { IAssignmentOfStudent } from '@/components/guru/interface/IAssignmentOfStudent'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import DashboardCardEmptyMessageButton, {
  type IDashboardEmptyData
} from '@/components/shared/dashboard/DashboardCardEmptyMessageButton.vue'
import { fetchAssignmentsOfStudent } from '@/services/assignments.service'
import { getAssignmentToAttend } from '@/services/teach.service'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import { useTeachStore } from '@/stores/teach.store'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { TEACHMODALHS } from '@/utils/models'
import { useAuthStore } from '@/stores/auth.store'
const teachStore = useTeachStore()
const breadcrumbStore = useBreadcrumbStore()
const route = useRoute()
const insCode = ref(route.params.insCode as string)
const searchString = ref('')
const assignmentsData = ref<IAssignmentMini[] | IAssignmentOfStudent[]>([])

const viewingAssignmentOfStudentObj = ref<IAssignmentOfStudent>()
const viewingAssignmentData = ref<IAssignmentDetailed>()
onMounted(async () => {
  if (!useAuthStore().isUserloggedIn) return
  insCode.value = route.params.insCode as string
  if (insCode.value) {
    teachStore.getAndSetCurrentAssociation(insCode.value)
  }
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
  let data = [] as IAssignmentOfStudent[] | IAssignmentMini[]
  data = await fetchAssignmentsOfStudent(insCode.value)
  assignmentsData.value = data
})

watch(
  () => teachStore.associations,
  () => {
    teachStore.getAndSetCurrentAssociation(insCode.value)
    breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
  }
)

watch(
  () => viewingAssignmentOfStudentObj.value,
  async () => {
    if (viewingAssignmentOfStudentObj.value) {
      const res = await getAssignmentToAttend(
        viewingAssignmentOfStudentObj.value?.insCode,
        viewingAssignmentOfStudentObj.value?.insAssignmentId,
        undefined
      )

      viewingAssignmentData.value = res
    }
  }
)

/**
 * Direct to either assignment page OR assignment attend page
 * @param assignment Assignment row clicked
 */
async function handleViewAssignment(assignment: IAssignmentOfStudent) {
  viewingAssignmentOfStudentObj.value = assignment
}

const filteredAssignments = computed(() => {
  let assignments = []
  if (searchString.value) {
    const lowerCaseString = searchString.value.toLowerCase()
    assignments = (assignmentsData.value as any[])
      .filter((assignment: IAssignmentMini | IAssignmentOfStudent) => {
        return assignment.title.toLowerCase().includes(lowerCaseString)
      })
      .slice(0, 6)
  } else {
    assignments = assignmentsData.value.slice(0, 6)
  }

  return assignments.sort((a: IAssignmentMini, b: IAssignmentMini) => {
    return a.endTime! - b.endTime!
  })
})

/**
 * Handles emission of search string and filters invitations
 * @param emit The emitted search string
 */
// function handleSearchInput(emit: string) {
//   searchString.value = emit
// }

const breadcrumbLinks = computed(() => {
  if (!insCode.value) {
    return [
      {
        href: '/',
        title: 'Home'
      },
      {
        href: '/dashboard',
        title: 'Dashboard'
      },
      {
        href: '/dashboard/institution',
        title: 'Virtual Institutions'
      },
      {
        href: `/dashboard/institution/assignments`,
        title: `All Assignments`
      }
    ]
  } else {
    return [
      {
        href: '/',
        title: 'Home'
      },
      {
        href: '/dashboard',
        title: 'Dashboard'
      },
      {
        href: '/dashboard/institution',
        title: 'Virtual Institutions'
      },
      {
        href: `/dashboard/institution/${insCode.value}`,
        title: `${teachStore.currentAssociation?.name}`
      }
    ]
  }
})

const emptyData: IDashboardEmptyData = {
  message: 'You do not have any assignments'
}

const pageTitle = computed(() => {
  if (insCode.value) {
    // return `${teachStore.currentAssociation?.name} Assignments`
    return 'Assignments'
  } else {
    return `All My Assignments`
  }
})

//Assignment Computed Values
const isResultAnnounced = computed(() => {
  return (
    viewingAssignmentData.value?.submissionStatus ===
    TEACH_STUDENT_ASSIGNMENT_STATUS.RESULT_ANNOUNCED
  )
})

/**
 * Determine whether question is marked
 */
const hasMarks = computed(() => {
  return viewingAssignmentData.value?.resultType !== 2
})

const assignmentActionText = computed(() => {
  return viewingAssignmentData.value?.submissionStatus ===
    TEACH_STUDENT_ASSIGNMENT_STATUS.YET_TO_START ||
    viewingAssignmentData.value?.submissionStatus === TEACH_STUDENT_ASSIGNMENT_STATUS.IN_PROGRESS
    ? 'Attend Assignment'
    : 'View Assignment'
})

/**
 * Get mark of question
 * @param index index of question
 * @returns number | empty string
 */
function getMarkForQuestion(index: number) {
  const marks = viewingAssignmentData.value?.marks

  if (marks) {
    return marks[index]
  }

  return ''
}
</script>

<template>
  <div class="h-full">
    <DashboardCard :title="pageTitle" class="h-full">
      <div class="h-full w-full">
        <div class="grid h-full grid-cols-8 gap-6">
          <div class="col-span-3">
            <!-- Student view (By Institute OR All My Assignments (no inscode)) -->
            <div v-if="assignmentsData.length > 0" class="flex w-full flex-col gap-3">
              <div class="relative flex">
                <input
                  v-model="searchString"
                  class="section-primary p-small block w-full rounded-md px-4 py-2"
                  placeholder="Search"
                />
                <FontAwesomeIcon icon="fa-search" class="text-brand absolute right-4 top-3" />
              </div>
              <div
                @click="handleViewAssignment(assignment)"
                role="button"
                v-for="(assignment, index) in (filteredAssignments as IAssignmentOfStudent[])"
                :key="index"
                class=""
              >
                <AssignmentAsStudentBlock :assignment="assignment" />
              </div>
            </div>
            <div v-else>
              <DashboardCardEmptyMessageButton :empty-data="emptyData" />
            </div>
          </div>
          <div class="col-span-5 border-s border-slate-700 px-6 text-sm">
            <div v-if="viewingAssignmentData">
              <h2 class="text-primary mb-5 text-lg font-bold">
                {{ viewingAssignmentData?.title }}
              </h2>
              <span class="text-secondary mb-2 block">Assignment Description</span>
              <div class="section-primary text-secondary mb-5 min-h-[100px] rounded-lg p-2 text-sm">
                {{ viewingAssignmentData?.description }}
              </div>

              <!-- Status -->
              <div class="mb-5 flex justify-between">
                <p class="inline-block">
                  <span class="font-bold">Assignment Status:</span>
                  {{ viewingAssignmentData?.status }}
                </p>
                <p class="inline-block">
                  <span class="font-bold">Submission Status:</span>
                  {{ viewingAssignmentData?.submissionStatus }}
                </p>
              </div>

              <!-- Buttons -->
              <div class="flex gap-5">
                <RouterLink
                  :to="{
                    path: `/dashboard/institution/${viewingAssignmentOfStudentObj?.insCode}/assignment/${viewingAssignmentData.insAssignmentId}/attend`
                  }"
                  class="btn-primary rounded-md px-6 py-2"
                  >{{ assignmentActionText }}</RouterLink
                >

                <button
                  :data-hs-overlay="`#${TEACHMODALHS.REQUEST_REEVALUATION}`"
                  class="btn-dashboard-small rounded-md px-6 py-2 text-sm"
                  v-if="isResultAnnounced"
                >
                  Request For Re-evaluation
                </button>
              </div>

              <!-- Summary -->

              <!-- Results Section (if disclosed) -->
              <div v-if="isResultAnnounced" class="mt-10 w-full">
                <div class="flex justify-between">
                  <span class="text-secondary mb-2 inline-block">Summary</span>
                  <div class="text-secondary ms-1 text-start text-sm" v-if="hasMarks">
                    Total Mark: {{ viewingAssignmentData?.totalMark }}
                  </div>
                </div>

                <div class="section-primary mb-5 min-h-[100px] rounded-lg p-2 text-sm">
                  <div class="text-secondary w-full">
                    <table class="w-full table-auto border-separate border-spacing-y-3">
                      <thead class="">
                        <tr>
                          <th class="p-1 text-start">Question</th>
                          <th class="p-1 text-start" v-if="hasMarks">Mark</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(question, index) in viewingAssignmentData?.questions"
                          v-bind:key="index"
                        >
                          <td class="p-2">
                            <!-- <a @click="props.loadQuestion(index)">{{ question.question }}</a> -->
                            {{ index + 1 }}. {{ question.question }}
                          </td>
                          <td class="p-2" v-if="hasMarks">
                            {{ getMarkForQuestion(index) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardCard>
  </div>
</template>
