<script setup lang="ts">
declare global {
  interface Window {
    jQuery: any
  }
}

import { onBeforeMount, onMounted, onBeforeUnmount, watch } from 'vue'
import {
  type NavigationGuardNext,
  type RouteLocationNormalized,
  useRoute,
  onBeforeRouteLeave
} from 'vue-router'
import { type IMeta } from '@/utils/meta'
import editorService from '@/services/ide/languages/terminal/editor.service'
import ideService from '@/services/ide/ide.service'
import utilModelsService from '@/services/util.models.service'
import { useIdeStore } from '@/stores/ide.store'

import Recaptcha from '@/components/shared/RecaptchaComp.vue'
import Header from '@/components/ide/ide/HeaderComp.vue'
import Ide from '@/components/ide/ide/languages/terminal/IdeComp.vue'
// import knowYourComp from '@/components/ide/knowYourComp.vue'
import IdeFeedbackComp from '@/components/ide/IdeFeedbackComp.vue'
import IdeFooterCardsComp from '@/components/ide/IdeFooterCardsComp.vue'
import JQuery from 'jquery'

window.jQuery = JQuery
const route = useRoute()
const ideStore = useIdeStore()

/**
 * @description init ide on mount and router change
 */
const initIde = async () => {
  editorService.initTernimal()
  ideStore.setIdeMeta(route.meta as IMeta)
}
onBeforeMount(() => {
  ideService.cleanIdeStore()
  initIde()
  watch(route, () => {
    initIde()
  })
})

onMounted(() => {
  window.onbeforeunload = function () {
    if (ideStore.isCodeUpdated) {
      return `Are you sure you want to move from this IDE?`
    }
  }
})

onBeforeRouteLeave(
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (ideStore.isCodeUpdated) {
      utilModelsService
        .confirmPromise(
          `Are you sure you want to move from this IDE?`,
          'You will lose all your unsaved changes.'
        )
        .then(() => {
          next()
        })
        .catch(() => {
          next(false)
        })
    } else {
      next()
    }
  }
)
onBeforeUnmount(() => {
  ideService.cleanIde()
  ideService.cleanIdeStore()
})
</script>

<template>
  <Recaptcha />
  <div class="section-primary p-5">
    <div class="block">
      <Header />
    </div>
    <div class="section-tertiary flex flex-col gap-5 rounded-lg p-5">
      <Ide />
    </div>
  </div>
  <div class="view-container block">
    <IdeFooterCardsComp />
    <IdeFeedbackComp />
  </div>
</template>
