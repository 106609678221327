<script setup lang="ts">
import type { IUser } from '@/components/guru/interface/IUser'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import { getUsers, getInstituteSubscriptionInfo } from '@/services/teach.service'
import { useAuthStore } from '@/stores/auth.store'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import { useTeachStore } from '@/stores/teach.store'
import { TEACHMODALHS } from '@/utils/models'
import { sortBy } from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const teachStore = useTeachStore()
const breadcrumbStore = useBreadcrumbStore()
const insCode = ref<string>()

const searchString = ref('')

const route = useRoute()
const router = useRouter()
const users = ref([] as IUser[])
const filteredUsers = computed(() => {
  if (searchString.value) {
    return users.value.filter((user: IUser) => {
      return (
        user.email.includes(searchString.value) ||
        user.firstName.includes(searchString.value) ||
        user.lastName.includes(searchString.value)
      )
    })
  } else {
    return users.value
  }
})

let showOnboard = ref(false)
/**
 * @returns boolean
 */
const showOnboardBox = () => {
  return (showOnboard.value = !showOnboard.value)
}

/**
 *
 */
function checkAuthentication() {
  if (!teachStore.guruInitialised) {
    return
  }

  let location = '/dashboard/institute/' + route.params.insCode

  if (!teachStore.isAccountOwner && !teachStore.isInstituteAdmin && !teachStore.isTeacher) {
    router.push(location)
  }
}

/**
 *
 */
function sortUsers() {
  users.value = sortBy(users.value, (o) => {
    return o.firstName + ' ' + o.lastName
  })
}

/**
 *
 * @param user user
 */
async function handleEditUser(user: IUser | undefined) {
  teachStore.editPrefilledUser = user
  router.push(`/dashboard/institution/${insCode.value}/edit-user`)
}

onMounted(async () => {
  if (!useAuthStore().isUserloggedIn) return
  insCode.value = route.params.insCode as string
  teachStore.getAndSetCurrentAssociation(insCode.value)
  const res = await getUsers(insCode.value)
  teachStore.setUsers(res)
  if (!teachStore.instituteSubscriptionInfo) {
    const insSubRes = await getInstituteSubscriptionInfo(insCode.value)
    teachStore.setSubscriptionInfo(insSubRes)
  }

  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)

  //@ts-ignore-next-line
  users.value = res
  sortUsers()
})

watch(
  () => teachStore.currentAssociation,
  () => {
    checkAuthentication()
    breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
  }
)

/**
 * @param emit The emitted search string
 */
function handleSearchInput(emit: string) {
  searchString.value = emit
}

const addUserBtn = computed(() => {
  return {
    url: `/dashboard/institution/${insCode.value}/add-user`,
    name: 'Add User'
  }
})

const breadcrumbLinks = computed(() => [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  },
  {
    href: '/dashboard/institution',
    title: 'Virtual Institutions'
  },
  {
    href: `/dashboard/institution/${insCode.value}`,
    title: `${teachStore.currentAssociation?.name}`
  },
  {
    href: `/dashboard/institution/${insCode.value}/users`,
    title: `User Management`
  }
])
</script>

<template>
  <div class="h-full w-full">
    <DashboardCard
      title="User Management"
      class="h-full w-full"
      :has-search-bar="true"
      :link="addUserBtn"
      @searchInput="handleSearchInput"
    >
      <div
        v-if="teachStore.isAccountOwner || teachStore.isInstituteAdmin || teachStore.isTeacher"
        class="m-auto max-w-4xl"
      >
        <div v-if="users.length > 0" class="mt-5 w-full">
          <table class="w-full table-auto border-separate border-spacing-y-4">
            <thead class="text-start text-sm">
              <tr>
                <th class="px-3 py-1 text-start">Name</th>
                <th class="px-3 py-1 text-start">Email</th>
                <th class="px-3 py-1 text-start">Role(s)</th>
                <th class="px-3 py-1 text-start">Status</th>
              </tr>
            </thead>
            <tbody class="">
              <tr
                @click="handleEditUser(user)"
                role="button"
                v-for="user in filteredUsers"
                :key="user.email"
                class="section-primary overflow-hidden rounded-lg p-3"
              >
                <td class="rounded-s-lg p-3 text-sm">{{ user.firstName }} {{ user.lastName }}</td>
                <td :data-hs-overlay="`#${TEACHMODALHS.EDIT_USER}`" class="p-3 text-sm">
                  {{ user.email }}
                </td>
                <td class="p-3 text-sm">
                  <span v-for="(role, index) in user.roles" :key="index"> {{ role }}<br /></span>
                </td>
                <td class="rounded-e-lg p-3 text-sm">{{ user.status }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </DashboardCard>
    <DashboardCard class="mt-4">
      <div class="mb-4 flex justify-center">
        <span class="text-sm font-bold">*User Bulk Creation functionality coming soon*</span>
      </div>
      <div class="justify-center">
        <span
          class="my-3 flex cursor-pointer justify-center text-sm text-text-tertiary underline dark:text-text-tertiary-dark"
          @click="showOnboardBox"
          >How to Onboard User?</span
        >
        <div
          v-if="showOnboard == true"
          class="section-primary my-2 flex justify-center rounded-md p-5"
        >
          <ol class="list-decimal ps-5 text-sm">
            <li class="mb-2">Create a new user using the button above</li>
            <li class="mb-2">
              If the user has not signed up with JDoodle already, ask the user to sign up with
              JDoodle.com using the same Email ID you used when creating the user
            </li>
            <li class="mb-2">
              After signing up with JDoodle, ask the user to visit - https://www.jdoodle.com/guru
            </li>
            <li>Under "Your Virtual Institutes" section, they can accept the admission</li>
          </ol>
        </div>
      </div>

      <div class="mt-3 text-center">
        <p class="error mb-3 text-xs" v-if="teachStore.adminUsersLimitReached">
          {{ teachStore.adminLimitMessage }}
        </p>
        <p class="error mb-3 text-xs" v-if="teachStore.teacherUsersLimitReached">
          {{ teachStore.teacherLimitMessage }}
        </p>
        <p class="error mb-3 text-xs" v-if="teachStore.studentUsersLimitReached">
          {{ teachStore.studentLimitMessage }}
        </p>
      </div>
    </DashboardCard>
  </div>
</template>
