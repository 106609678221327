<script setup lang="ts">
import axios from 'axios'
import { delay } from 'lodash'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import UnsubscribedPlan from '@/views/dashboard/api/UnsubscribedPlan.vue'
import SubscribedPlan from '@/views/dashboard/api/SubscribedPlan.vue'
import { initCompilerApi } from '@/services/subscription.service'
import { useAuthStore } from '@/stores/auth.store'
import { useSubscribeStore } from '@/stores/subscribe.store'
import { ref, reactive, onMounted, computed } from 'vue'
import { apiPlansData, type IApiPlan } from '@/utils/sharedData/plans'
import { find } from 'lodash'
import DoodleMessage from '@/components/utils/DoodleMessage.vue'
import { getApiCredentials } from '@/services/subscription.service'
import SubscriptionComponent from '@/components/shared/subscription/SubscriptionComponent.vue'
import { SUBSCRIPTION_TYPE_ENUM } from '@/components/shared/subscription/enum/subscription.enum'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import type ILink from '@/components/shared/interface/ILink'

const authStore = useAuthStore()
const breadcrumbStore = useBreadcrumbStore()

const breadcrumbLinks: ILink[] = [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  },
  {
    href: '/dashboard/api',
    title: 'APIs'
  }
]

const userEmail = computed(() => authStore.userEmail)
const subscribeStore = useSubscribeStore()
const subscribed = computed(() => {
  return subscribeStore.apiInitData?.plan
})

const clientId = ref('')
const clientSecret = ref('')
const showCopiedMessage = ref(false)
const showCopiedMessageText = ref('')

const apiInfo = reactive({
  isUserLoggedIn: false,
  stripe: {},
  stripCard: {},
  clientPlan: ('' as string) || null,
  isUnlimited: false as any,
  clientFuturePlan: ('' as string) || null,
  clientFuturePlanDetail: {},
  clientPlanDetail: {},
  isSubscriptionCancelled: false,
  hasPayAccount: false,
  stripeKey: ''
})

onMounted(async () => {
  if (!useAuthStore().isUserloggedIn) return
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks)
  await initCompilerApi().then((data) => {
    if (data.plan) {
      apiInfo.clientPlan = data.plan
      apiInfo.isUnlimited = data.isUnlimited
      apiInfo.isSubscriptionCancelled = data.isCancelled
      apiInfo.hasPayAccount = data.hasStripeAccount
      if (data.isUnlimited) {
        apiInfo.clientPlanDetail = find(apiPlansData, { name: 'Unlimited' }) || {}
      } else {
        apiInfo.clientPlanDetail = find(apiPlansData, { name: data.plan }) || {}
      }
    } else {
      apiInfo.clientPlanDetail = {}
      apiInfo.clientPlan = null
      apiInfo.isUnlimited = false
      apiInfo.isSubscriptionCancelled = false
      apiInfo.hasPayAccount = false
    }

    if (data.futurePlan) {
      apiInfo.clientFuturePlan = data.futurePlan
      apiInfo.clientFuturePlanDetail = find(apiPlansData, { name: data.futurePlan }) || {}
    } else {
      apiInfo.clientFuturePlan = null
      apiInfo.clientFuturePlanDetail = {}
    }
  })

  await getApiCredentials().then((res) => {
    clientId.value = res.clientId
    clientSecret.value = res.clientSecret
  })
})

/**
 *
 */
function refreshCredentials() {
  axios.post('/api/compiler-api/refreshSecret').then(({ data }) => {
    clientSecret.value = data.clientSecret
  })
}

/**
 *
 * @param text string
 */
function copyToClipboard(text: string) {
  window.navigator.clipboard.writeText(text).then(
    () => {
      showCopiedMessage.value = true
      showCopiedMessageText.value = 'Copied to clipboard'
      delay(() => {
        showCopiedMessage.value = false
      }, 1000)
    },
    () => {
      showCopiedMessage.value = true
      delay(() => {
        showCopiedMessage.value = false
      }, 1000)
      showCopiedMessageText.value = 'Copy to clipboard failed'
    }
  )
}
</script>

<template>
  <div>
    <div class="grid gap-4 lg:grid-cols-3">
      <SubscriptionComponent :subscription-type="SUBSCRIPTION_TYPE_ENUM.COMPILER_API" />

      <DashboardCard title="Account Details" class="lg:col-span-2">
        <p class="mb-3.5 mt-1">
          <span class="text-secondary mr-6">Email: </span>
          {{ userEmail }}
        </p>

        <h3 class="my-2 font-semibold">
          Unique Credentials
          <button
            class="ml-4 inline-block rounded-md border border-text-brand px-4 py-0.5 text-xs font-light text-text-brand hover:opacity-90"
            @click="refreshCredentials"
          >
            Refresh
          </button>

          <DoodleMessage
            class="ml-6 inline-block text-gray-600 dark:text-gray-200"
            :message="showCopiedMessageText"
            message-class="text-text-brand"
            v-show="showCopiedMessage"
          />
        </h3>

        <div class="text-sm">
          <div class="mb-1">
            <span class="text-secondary mr-4">Client ID:</span>
            <span class="break-all">{{ clientId }}</span>

            <span v-show="clientId" @click="copyToClipboard(clientId)">
              <FontAwesomeIcon icon="fa-copy" class="ml-2 h-4 w-4 cursor-pointer text-red-500" />
            </span>
          </div>
          <div class="mb-1">
            <span class="text-secondary mr-2">Client Secret:</span>
            <span class="break-all">{{ clientSecret }}</span>

            <span v-show="clientSecret" @click="copyToClipboard(clientSecret)">
              <FontAwesomeIcon icon="fa-copy" class="ml-2 h-4 w-4 cursor-pointer text-red-500" />
            </span>
          </div>
        </div>
      </DashboardCard>

      <DashboardCard>
        <p class="text-secondary mt-1">Active API Plan</p>
        <div v-if="subscribed">
          <div class="py-1 text-xl">
            <span class="mr-1 text-2xl font-bold text-text-brand" v-if="apiInfo.isUnlimited"
              >Unlimited</span
            >
            <span class="mr-1 font-bold text-text-brand" v-else>
              {{ (apiInfo.clientPlanDetail as IApiPlan).credits }} Credits*
            </span>
            Per Day
          </div>
          <p v-if="!apiInfo.isUnlimited">
            <span>{{ (apiInfo.clientPlanDetail as IApiPlan).value }} </span>
            per month
          </p>
          <RouterLink
            v-if="
              !apiInfo.isUnlimited && !apiInfo.isSubscriptionCancelled && !apiInfo.clientFuturePlan
            "
            :to="{ name: 'dashboard-plans' }"
            class="btn-dashboard mt-7"
            >Manage Plan</RouterLink
          >

          <!-- Future plan -->
          <div
            v-if="apiInfo.clientFuturePlan"
            class="mt-6 border-t border-neutral-200 pt-4 dark:border-neutral-800"
            v-cloak
          >
            <p class="text-secondary mt-1">Your Plan From Next Billing Period</p>
            <div class="py-1 text-xl">
              <span class="mr-1 font-bold text-text-brand">
                {{ (apiInfo.clientFuturePlanDetail as IApiPlan).credits }} Credits*
              </span>
              Per Day
            </div>
            <p>{{ (apiInfo.clientFuturePlanDetail as IApiPlan).value }} per month</p>
          </div>
        </div>
        <div v-else>
          <p class="mt-4 text-xl font-bold text-text-brand">Not Subscribed</p>
          <RouterLink to="/pricing/api" class="btn-dashboard mt-9 text-center">
            Subscribe now
          </RouterLink>
        </div>
      </DashboardCard>
    </div>

    <SubscribedPlan v-if="subscribed" :data="apiInfo" />

    <UnsubscribedPlan v-else />
  </div>
</template>
