<script setup lang="ts">
import { computed } from 'vue'
import editorService from '@/services/ide/editor.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import LogoComp from '@/components/utils/LogoComp.vue'

const ideStore = useIdeStore()
const pluginStore = usePluginStore()

const isPrintBlocker = computed(() => {
  return ideStore.isPrintBlocker
})
const isEmbedded = computed(() => {
  return ideStore.isEmbedded || pluginStore.isPlugin
})
/**
 * Closes the print blocker
 */
const closePrintBlocker = () => {
  ideStore.setPrintBlocker(false)
  editorService.resizeCodeEditor()
  editorService.resizeOutputEditor()
  blocklyService.resizeBlockly()
}
</script>

<template>
  <div
    v-if="isPrintBlocker"
    :class="[
      'print:hidden',
      'top-0',
      'left-0',
      'w-full',
      'h-full',
      'section-primary',
      'z-50',
      'flex',
      'justify-center',
      'items-start',
      {
        absolute: isEmbedded
      },
      {
        fixed: !isEmbedded
      }
    ]"
  >
    <div class="flex w-fit flex-col items-center justify-center gap-5">
      <LogoComp />
      <p class="p-small">JDoodle pretty printing is printing your code.</p>
      <button class="btn btn-primary" @click="closePrintBlocker">Close</button>
    </div>
  </div>
</template>
