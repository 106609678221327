<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  treeData: {
    type: Object
  },
  treeHome: {
    type: [Object, String]
  },
  siblings: {
    type: Array
  },
  libraries: {
    type: [String, Array]
  }
})

const itemName = computed(() => {
  return props.treeData?.name || ''
})
const isFolder = computed(() => {
  if (props.treeData?.children) {
    return true
  }
  return false
})
const isHome = computed(() => {
  let parent = '/'
  if (props.treeData?.parent && props.treeData?.parent === '/') {
    parent = props.treeData?.parent + props.treeData?.name
  } else if (props.treeData?.parent) {
    parent = props.treeData?.parent + '/' + props.treeData?.name
  }
  return parent === props.treeHome
})

const isPublicLib = computed(() => {
  return props.treeData?.isPublicLib || false
})
const libraries = computed(() => {
  return props.libraries || []
})
const home = computed(() => {
  return props.treeHome || {}
})
</script>

<template>
  <li class="list-none pl-3">
    <div class="flex items-center gap-2">
      <span v-if="isFolder">
        <FontAwesomeIcon icon="fa-folder-open" class="h-4 w-4" />
      </span>
      <span v-else>
        <FontAwesomeIcon v-show="isHome" icon="home" class="h-4 w-4 text-text-brand" />
        <FontAwesomeIcon v-show="!isHome" icon="file-alt" class="h-4 w-4" />
      </span>
      <span class="p-small">{{ itemName }}</span>
    </div>
    <div v-if="isFolder">
      <ul v-if="!isPublicLib">
        <TreeItemRoComp
          v-for="(child, index) in props.treeData?.children"
          :key="index"
          :treeData="child"
          :treeHome="home"
          :siblings="props.treeData?.children"
          :libraries="props.libraries"
        />
      </ul>
      <ul v-if="isPublicLib">
        <li v-for="(lib, index) in libraries" :key="index" class="list-none pl-3">
          <div class="flex gap-2">
            <FontAwesomeIcon icon="fa-cube" class="h-4 w-4" />
            <p class="p-small">{{ lib }}</p>
          </div>
        </li>
      </ul>
    </div>
  </li>
</template>
