<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { type IAssignmentOfStudent } from '@/components/guru/interface/IAssignmentOfStudent'
// import { getFormattedDate } from '@/utils/format'
import type IAssignmentMini from '../interface/IAssignmentMini'
import DashboardCardEmptyMessageButton, {
  type IDashboardEmptyData
} from '@/components/shared/dashboard/DashboardCardEmptyMessageButton.vue'
import { useTeachStore } from '@/stores/teach.store'
import AssignmentAsStaffBlock from './AssignmentAsStaffBlock.vue'
import AssignmentAsStudentBlock from './AssignmentAsStudentBlock.vue'
import { fetchAssignmentsOfStudentOrStaff } from '@/services/assignments.service'

const teachStore = useTeachStore()
const assignmentData = ref<IAssignmentOfStudent[] | IAssignmentMini[]>([])
const filteredAssignmentData = ref<IAssignmentOfStudent[] | IAssignmentMini[]>([])
const props = defineProps({
  displayCount: {
    type: Number,
    required: false
  },
  insCode: {
    type: String,
    required: false
  },
  showAllAsStudent: {
    type: Boolean,
    required: false,
    default: false
  },
  searchString: {
    type: String,
    required: false
  }
})

onMounted(async () => {
  await fetchAssignments()
})

watch(
  () => props.insCode,
  async () => {
    await fetchAssignments()
  }
)

watch(
  () => props.searchString,
  (string) => {
    if (string) {
      filteredAssignmentData.value = [...assignmentData.value].filter(
        (assignment: IAssignmentOfStudent | IAssignmentMini) => {
          return assignment.title.toLowerCase().includes(string.toLowerCase())
        }
      ) as IAssignmentMini[] | IAssignmentOfStudent[]
    } else {
      filteredAssignmentData.value = [...assignmentData.value] as
        | IAssignmentMini[]
        | IAssignmentOfStudent[]
    }
  }
)

/**
 *
 */
async function fetchAssignments() {
  let data: any[] = await fetchAssignmentsOfStudentOrStaff(props.insCode, props.showAllAsStudent)

  assignmentData.value = data

  if (props.displayCount) {
    filteredAssignmentData.value = data.slice(0, props.displayCount)
  } else {
    filteredAssignmentData.value = data
  }
}

const emptyData: IDashboardEmptyData = {
  message: 'You do not have any assignments.'
}
</script>

<template>
  <!-- Wrap Dashboard card around this -->
  <div class="w-full">
    <div v-if="assignmentData.length < 1">
      <DashboardCardEmptyMessageButton :empty-data="emptyData" />
    </div>
    <div v-else class="flex flex-col gap-3">
      <div v-for="assignment in filteredAssignmentData" :key="assignment.insAssignmentId">
        <AssignmentAsStaffBlock
          v-if="!teachStore.isStudent && !props.showAllAsStudent"
          :assignment="(assignment as IAssignmentMini)"
          :insCode="props.insCode"
        />
        <RouterLink
          v-if="teachStore.isStudent || props.showAllAsStudent"
          :to="{path: `/dashboard/institution/${(assignment as IAssignmentOfStudent).insCode}/assignment/${
        (assignment as IAssignmentOfStudent).insAssignmentId
      }/attend`}"
        >
          <AssignmentAsStudentBlock :assignment="(assignment as IAssignmentOfStudent)" />
        </RouterLink>
      </div>
    </div>
  </div>
</template>
