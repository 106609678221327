<script setup lang="ts">
import { TEACHMODALHS } from '@/utils/models'
import BaseInput from '@/components/forms/BaseInput.vue'
import { useField, useForm } from 'vee-validate'
import { string } from 'yup'
import { computed, ref, watch, onMounted } from 'vue'
import { TEACH_CONSTANTS } from '@/components/guru/constants/teach.constants'
import { addUser, deleteUser } from '@/services/teach.service'
import { useRoute, useRouter } from 'vue-router'
import type { IAddUserRequest } from '@/components/guru/interface/requests.interface'
import {
  GURU_ROLES,
  GURU_USER_ACTION_MODES,
  GURU_USER_STATUS
} from '@/components/guru/enums/teach.enum'
import type { IUser } from '@/components/guru/interface/IUser'
import type { IUserRole } from '@/components/guru/interface/IUserRole'
import HintsUserPermission from '@/components/guru/modals/components/HintsUserPermission.vue'
import { useTeachStore } from '@/stores/teach.store'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import { HttpStatusCode } from 'axios'

const teachStore = useTeachStore()
const breadcrumbStore = useBreadcrumbStore()
const route = useRoute()
const router = useRouter()
const insCode = ref(route.params.insCode as string)
const isLoading = ref<boolean>(false)
const deleteSelected = ref(false)
const currentMode = ref(GURU_USER_ACTION_MODES.ADD as GURU_USER_ACTION_MODES)
const isDisabled = computed(() => {
  return currentMode.value === GURU_USER_ACTION_MODES.VIEW
})
let selectedRoles = ref<IUserRole[]>([])
const currentUser = ref<IUser>()
let roleTypes: IUserRole[] = TEACH_CONSTANTS.GURU_ROLE_TYPES

onMounted(() => {
  insCode.value = route.params.insCode as string
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)

  //Default values, new user VS prefill
  if (teachStore.editPrefilledUser == undefined) {
    currentMode.value = GURU_USER_ACTION_MODES.ADD
    currentUser.value = undefined
    emailId.value = undefined
    firstName.value = undefined
    lastName.value = undefined
  } else {
    currentMode.value = GURU_USER_ACTION_MODES.VIEW
    currentUser.value = teachStore.editPrefilledUser
    emailId.value = currentUser.value.email
    firstName.value = currentUser.value.firstName
    lastName.value = currentUser.value.lastName
  }

  selectedRoles.value = roleTypes.filter((role: any) => {
    return currentUser.value?.roles.includes(role.name)
  })
})

watch(
  () => teachStore.currentAssociation,
  () => {
    breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
  }
)

const breadcrumbLinks = computed(() => [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  },
  {
    href: '/dashboard/institution',
    title: 'Virtual Institutions'
  },
  {
    href: `/dashboard/institution/${insCode.value}`,
    title: `${teachStore.currentAssociation?.name}`
  },
  {
    href: `/dashboard/institution/${insCode.value}/users`,
    title: `User Management`
  },
  {
    href: `/dashboard/institution/${insCode.value}/users/add-user`,
    title: `Add/Edit User`
  }
])

const showDeactivate = computed(() => {
  return (
    (isUserActive.value || isUserInActive.value) &&
    (teachStore.isAccountOwner ||
      teachStore.isInstituteAdmin ||
      (!hasInstituteAdminRole.value && !hasTeacherRole.value && teachStore.isTeacher))
  )
})

// const isUserAlreadyAcceptedInvite = computed(() => {
//   return (
//     currentUser.value?.status !== GURU_USER_STATUS.PENDING_USER_ACCEPTANCE &&
//     currentUser.value?.status !== GURU_USER_STATUS.REJECT
//   )
// })

const availableRolesForLoggedInUser = computed(() => {
  let availableRoles = roleTypes.filter((role: IUserRole) => {
    return !selectedRoles.value?.includes(role)
  })

  if (teachStore.currentUserRole == GURU_ROLES.TEACHER) {
    availableRoles = availableRoles.filter((role: IUserRole) => role.name == GURU_ROLES.STUDENT)
  }

  if (teachStore.adminUsersLimitReached) {
    availableRoles = availableRoles.filter(
      (role: IUserRole) => role.name !== GURU_ROLES.INSTITUTE_ADMIN
    )
  }

  if (teachStore.teacherUsersLimitReached) {
    availableRoles = availableRoles.filter((role: IUserRole) => role.name !== GURU_ROLES.TEACHER)
  }

  if (teachStore.studentUsersLimitReached) {
    availableRoles = availableRoles.filter((role: IUserRole) => role.name !== GURU_ROLES.STUDENT)
  }

  return availableRoles
})

const isUserActive = computed(() => {
  return currentUser.value?.status === GURU_USER_STATUS.ACTIVE
})
const isUserInActive = computed(() => {
  return currentUser.value?.status === GURU_USER_STATUS.INACTIVE
})

const hasTeacherRole = computed(() => {
  for (const key in selectedRoles.value) {
    if (selectedRoles.value[key].name === GURU_ROLES.TEACHER) {
      return true
    }
  }
  return false
})

const hasInstituteAdminRole = computed(() => {
  for (const key in selectedRoles.value) {
    if (selectedRoles.value[key].name === GURU_ROLES.INSTITUTE_ADMIN) {
      return true
    }
  }
  return false
})

const { errors, handleSubmit } = useForm()

const emailInput = {
  label: 'Email',
  placeholder: 'Email'
}

const firstNameInput = {
  label: 'First Name',
  placeholder: 'First Name'
}
const lastNameInput = {
  label: 'Last Name',
  placeholder: 'Last Name'
}

const currentSelectedRole = ref(availableRolesForLoggedInUser.value[0]) //Default
// const availableRoles = ref(roleTypes)

// Form validation
const { value: emailId, errorMessage: emailError } = useField(
  'emailId',
  string().required().email()
)
const { value: firstName, errorMessage: firstNameError } = useField(
  'firstName',
  string().required().min(2)
)
const { value: lastName, errorMessage: lastNameError } = useField(
  'lastName',
  string().required().min(2)
)

/**
 *
 */
const addUserRole = () => {
  if (!selectedRoles.value.includes(currentSelectedRole.value)) {
    ;(selectedRoles.value as IUserRole[]).push(currentSelectedRole.value)
    // availableRoles.value.splice(availableRoles.value.indexOf(currentSelectedRole.value), 1)
  }
}

/**
 *
 * @param role role of user
 */
const removeUserRole = (role: IUserRole) => {
  // availableRoles.value.push(role)
  ;(selectedRoles.value as IUserRole[]).splice(
    (selectedRoles.value as IUserRole[]).indexOf(role),
    1
  )
}

const selectedUsersHighestPermission = computed(() => {
  if (currentUser.value?.roles.includes(GURU_ROLES.ACCOUNT_OWNER)) {
    return GURU_ROLES.ACCOUNT_OWNER
  } else if (currentUser.value?.roles.includes(GURU_ROLES.INSTITUTE_ADMIN)) {
    return GURU_ROLES.INSTITUTE_ADMIN
  } else if (currentUser.value?.roles.includes(GURU_ROLES.TEACHER)) {
    return GURU_ROLES.TEACHER
  }

  return GURU_ROLES.STUDENT
})

const userHasPermissionToEdit = computed(() => {
  if (
    teachStore.currentUserRole == GURU_ROLES.ACCOUNT_OWNER ||
    teachStore.currentUserRole == GURU_ROLES.INSTITUTE_ADMIN
  ) {
    return true
  } else if (
    teachStore.currentUserRole == GURU_ROLES.TEACHER &&
    selectedUsersHighestPermission.value == GURU_ROLES.STUDENT
  ) {
    return true
  }

  return false
})

const canSubmit = computed(() => {
  return (
    Object.keys(errors.value).length === 0 && !roleIsEmpty.value && userHasPermissionToEdit.value
  )
})

const allLimitsReached = computed(() => {
  return (
    teachStore.adminUsersLimitReached &&
    teachStore.teacherUsersLimitReached &&
    teachStore.studentUsersLimitReached
  )
})

const canDelete = computed(
  () => currentUser.value?.status == GURU_USER_STATUS.PENDING_USER_ACCEPTANCE
)

const errorMessage = ref('')
const onSubmit = handleSubmit(async (values) => {
  try {
    let res = undefined

    if (currentMode.value === GURU_USER_ACTION_MODES.EDIT) {
      const reqData: IAddUserRequest = {
        instituteCode: insCode.value,
        emailId: values.emailId,
        firstName: values.firstName,
        lastName: values.lastName,
        roles: selectedRoles.value,
        mode: GURU_USER_ACTION_MODES.EDIT
      }
      isLoading.value = true
      res = await addUser(reqData)
    }

    if (currentMode.value === GURU_USER_ACTION_MODES.ADD) {
      isLoading.value = true
      res = await addUser({
        instituteCode: insCode.value,
        ...values,
        roles: selectedRoles.value,
        mode: GURU_USER_ACTION_MODES.ADD
      } as unknown as IAddUserRequest)
    }

    if (res.message) {
      isLoading.value = false
      errorMessage.value = res.message
    } else {
      isLoading.value = false
      errorMessage.value = ''
      window.location.reload()
    }
  } catch (err: any) {
    isLoading.value = false
  }
})

/**
 *
 */
const handleDelete = async () => {
  try {
    isLoading.value = true
    const res = await deleteUser(insCode.value, currentUser.value?.email)
    teachStore.editPrefilledUser = undefined

    if (res.status == HttpStatusCode.Ok) {
      isLoading.value = false
      router.push(`/dashboard/institution/${insCode.value}/users`)
    }
  } catch (err: any) {
    isLoading.value = false
  }
}

/**
 *
 * @param newMode mode
 */
function changeMode(newMode: GURU_USER_ACTION_MODES) {
  if (newMode === GURU_USER_ACTION_MODES.EDIT) {
    currentMode.value = GURU_USER_ACTION_MODES.EDIT
  }
  if (newMode === GURU_USER_ACTION_MODES.VIEW) {
    currentMode.value = GURU_USER_ACTION_MODES.VIEW
  }
}

/**
 * @returns boolean
 */
const roleIsEmpty = computed(() => {
  return selectedRoles.value.length < 1
})
</script>

<template>
  <DashboardCard :title="currentUser ? 'Edit User' : 'Add User'">
    <!-- form -->
    <form @submit.prevent="onSubmit" class="m-auto max-w-xl">
      <BaseInput
        :label="emailInput.label"
        :placeholder="emailInput.placeholder"
        v-model="emailId"
        :error="emailError"
        :disabled="isDisabled"
        class="mb-2"
        :isLightGray="false"
      />

      <BaseInput
        :label="firstNameInput.label"
        :placeholder="firstNameInput.placeholder"
        v-model="firstName"
        :error="firstNameError"
        :disabled="isDisabled"
        class="mb-2"
        :isLightGray="false"
      />
      <BaseInput
        :label="lastNameInput.label"
        :placeholder="lastNameInput.placeholder"
        v-model="lastName"
        :error="lastNameError"
        :disabled="isDisabled"
        class="mb-2"
        :isLightGray="false"
      />

      <!-- Shows dropdown and holds the value in currentSelectedRole -->
      <div class="mb-2 mt-3 flex flex-col">
        <label class="text-sm">Roles</label>
        <div class="flex gap-2">
          <select
            v-if="availableRolesForLoggedInUser.length > 0"
            default="Select"
            v-model="currentSelectedRole"
            class="section-primary btn-rounded-md h-full border"
            :disabled="isDisabled"
          >
            <option v-for="role in availableRolesForLoggedInUser" :key="role.name" :value="role">
              {{ role.name }}
            </option>
          </select>

          <button
            v-if="availableRolesForLoggedInUser.length > 0"
            :disabled="isDisabled"
            @click="addUserRole"
            type="button"
            class="btn-primary btn-rounded-md"
          >
            Add Role
          </button>
        </div>
      </div>
      <div class="mb-5">
        <p v-if="teachStore.adminUsersLimitReached" class="error mb-2 text-xs">
          {{ teachStore.adminLimitMessage }}
        </p>
        <p v-if="teachStore.teacherUsersLimitReached" class="error mb-2 text-xs">
          {{ teachStore.teacherLimitMessage }}
        </p>
        <p v-if="teachStore.studentUsersLimitReached" class="error mb-2 text-xs">
          {{ teachStore.studentLimitMessage }}
        </p>
      </div>
      <div v-if="roleIsEmpty && !allLimitsReached" className="my-2">
        <p class="error text-xs">At least one role should be selected</p>
      </div>

      <div class="mb-3 rounded-md border border-slate-700 p-2">
        <p class="mb-2 text-sm">Selected Roles:</p>
        <div v-for="role in selectedRoles" :key="role.name">
          <button :disabled="isDisabled" @click="removeUserRole(role)" type="button">
            <FontAwesomeIcon icon="fa-times-circle" /></button
          >&nbsp;<span class="text-sm">
            {{ role.name }}
          </span>
        </div>
        <p v-if="selectedRoles.length < 1 || !selectedRoles" class="text-sm">None</p>
      </div>

      <p class="error text-xs" v-if="errorMessage">{{ errorMessage }}</p>

      <div class="mb-5" v-if="currentMode !== GURU_USER_ACTION_MODES.ADD">
        <label class="text-sm">Status</label>
        <div class="section-primary p-small block w-full rounded-md border px-4 py-2">
          {{ currentUser?.status }}
        </div>
      </div>
      <button
        type="button"
        v-if="showDeactivate"
        class="btn-primary btn-rounded-md underline"
        :data-hs-overlay="`#${TEACHMODALHS.CHANGE_ACTIVATION_CONFIRMATION}`"
      >
        <span v-if="isUserActive">Deactivate</span><span v-if="isUserInActive">Activate</span>
      </button>

      <button
        :disabled="!canSubmit || isLoading"
        type="submit"
        v-if="currentMode === GURU_USER_ACTION_MODES.ADD"
        class="btn-primary btn-rounded-md"
      >
        Add User
      </button>
      <p v-if="isLoading" class="my-4 text-sm">Loading...</p>

      <div v-if="!userHasPermissionToEdit" className="my-2">
        <p class="error text-xs">You do not have permission to make changes to this user.</p>
      </div>

      <button
        type="button"
        v-if="canDelete && userHasPermissionToEdit"
        class="btn bg-red-500"
        @click="deleteSelected = true"
        :disabled="isLoading"
      >
        Delete User
      </button>
      <div v-if="deleteSelected" class="m-auto my-8">
        <p class="mb-8 text-center">Are you sure you would like to delete user?</p>
        <div class="flex justify-center gap-5">
          <button @click="handleDelete" class="btn btn-primary w-fit">Yes</button>
          <button @click="deleteSelected = false" class="btn btn-secondary w-fit">No</button>
        </div>
      </div>

      <button
        type="button"
        :disabled="!userHasPermissionToEdit || isLoading"
        v-if="currentMode === GURU_USER_ACTION_MODES.VIEW"
        class="btn-secondary btn-rounded-md"
        @click="changeMode(GURU_USER_ACTION_MODES.EDIT)"
      >
        Edit User
      </button>
      <button
        :disabled="!userHasPermissionToEdit || isLoading"
        v-if="currentMode === GURU_USER_ACTION_MODES.EDIT"
        type="submit"
        class="btn-secondary btn-rounded-md"
      >
        Update User
      </button>
      <br />
      <HintsUserPermission />
    </form>
  </DashboardCard>
</template>
