enum WHATLIKETOTALK {
  COMPILER_EDITORS = 'Compilers and online editors',
  COMPILER_API_PLUGIN = 'Compiler API and Plugins',
  TEACH = 'Teach and Assess Platform',
  PARTNERSHIP = 'Partnership',
  EVENTS_SPONSORSHIP = 'Events and Sponsorship',
  GENERAL_ENQUIRY = 'General Enquiry'
}
export const whatLikeToTalkData = [
  WHATLIKETOTALK.COMPILER_EDITORS,
  WHATLIKETOTALK.COMPILER_API_PLUGIN,
  WHATLIKETOTALK.TEACH,
  WHATLIKETOTALK.PARTNERSHIP,
  WHATLIKETOTALK.EVENTS_SPONSORSHIP,
  WHATLIKETOTALK.GENERAL_ENQUIRY
]
