<script setup lang="ts">
import DashboardProjectsCard from '@/components/dashboard/project/DashboardProjectsCard.vue'
import AssignmentsComponent from '@/components/guru/dashboard/AssignmentsComponent.vue'
import AssociationBlock from '@/components/guru/dashboard/AssociationBlock.vue'
import InstituteDetailsComponent from '@/components/guru/dashboard/InstituteDetailsComponent.vue'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import type ILink from '@/components/shared/interface/ILink'
import { getAssociationsAndInstituteOwn } from '@/services/teach.service'
import { useSubscribeStore } from '@/stores/subscribe.store'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import { useTeachStore } from '@/stores/teach.store'
import { computed, onMounted, ref } from 'vue'
import { find } from 'lodash'

import type IAssociation from '@/components/guru/interface/IAssociation'
import { GURU_USER_STATUS } from '@/components/guru/enums/teach.enum'
import { pluginPlansData, type IBundledPlan } from '@/utils/sharedData/plans'
import { initBundle, initCompilerApi } from '@/services/subscription.service'

onMounted(async () => {
  await initCompilerApi().then((data) => {
    subscribeStore.initApi(data)
    if (data.plan) {
      currentApiPlan.value = data.plan
    }
  })
  await initBundle().then((data) => {
    subscribeStore.initBundle(data)

    if (data.plan) {
      currentBundlePlan.value = data.plan

      currentBundlePlanDetails.value =
        find(pluginPlansData, { name: currentBundlePlan.value }) || ({} as IBundledPlan)
    }
  })
})

const subscribeStore = useSubscribeStore()

const currentApiPlan = ref(('' as string) || null)
const currentBundlePlan = ref(('' as string) || null)
const currentBundlePlanDetails = ref({} as IBundledPlan)
const teachStore = useTeachStore()
const breadcrumbStore = useBreadcrumbStore()
const instituteOwn = computed(() => teachStore.instituteOwn)

const breadcrumbLinks: ILink[] = [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  }
]

onMounted(async () => {
  if (!instituteOwn.value) {
    const res = await getAssociationsAndInstituteOwn()
    teachStore.setAssociationAndInstituteOwn(res)
  }

  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks)
})
</script>

<template>
  <div class="md:grid-rows-8 mb-3 flex flex-col gap-5 md:grid md:grid-cols-2">
    <DashboardProjectsCard class="col-span-1 row-span-2" />
    <DashboardCard
      class="row-span-4"
      title="My Assignments as a Student"
      :link="{ url: '/dashboard/institution/assignments', name: 'See More' }"
    >
      <AssignmentsComponent :displayCount="3" :showAllAsStudent="true" />
    </DashboardCard>
    <DashboardCard
      class="col-span-1 row-span-2"
      title="Platform Plan"
      :link="{ url: '/dashboard/payment/plans', name: 'Manage Plan' }"
    >
      <div v-if="currentBundlePlan != null" class="text-secondary mb-2 text-base">
        You are currently on a {{ (currentBundlePlanDetails as IBundledPlan).type }} Plan
      </div>
      <div v-else>
        <div class="mb-3 text-base">You are currently on a free Platform plan.</div>
        <div class="flex">
          <FontAwesomeIcon icon="fa-check" class="text-brand ml-1 mt-1 h-3 w-3" />
          <p class="text-secondary p-small relative mb-1.5 pl-4">
            Integrate IDEs effortlessly into your LMS, websites and platforms with three lines of
            HTML code.
          </p>
        </div>
        <div class="flex">
          <FontAwesomeIcon icon="fa-check" class="text-brand ml-1 mt-1 h-3 w-3" />
          <p class="text-secondary p-small relative mb-1.5 pl-4">
            Teach & Assess with limited features.
          </p>
        </div>
      </div>
    </DashboardCard>

    <DashboardCard
      title="API Plan"
      emptyMsg="You do not have any active plugin Plan"
      class="row-span-2"
      :link="{ url: '/dashboard/payment/plans', name: 'See Plan' }"
    >
      <div v-if="currentApiPlan != null" class="text-secondary mb-2 text-base">
        You are currently on a {{ currentApiPlan }} plan.
      </div>
      <div v-else class="text-center text-sm text-gray-600">
        You do not have a API Subscription yet.
        <RouterLink to="/dashboard/api" class="btn-dashboard m-auto mt-4">
          <FontAwesomeIcon icon="fa-add" class="mr-1" />
          Subscribe
        </RouterLink>
      </div>
    </DashboardCard>

    <DashboardCard title="My Institute" class="row-span-2">
      <InstituteDetailsComponent :show-button="true" :insCode="instituteOwn?.code" />
    </DashboardCard>

    <DashboardCard
      class="col-span-2 row-span-2"
      title="My Associations"
      :link="{ name: 'SEE MORE', url: '/dashboard/institution/associations' }"
    >
      <div class="grid grid-cols-3 grid-rows-1 gap-x-5">
        <div
          v-for="(association, index) in teachStore.associations?.filter((asso: IAssociation) => asso.status == GURU_USER_STATUS.ACTIVE).slice(0, 3)"
          :key="index"
          class="col-span-1"
        >
          <AssociationBlock :association="association" class="h-full" />
        </div>
      </div>
    </DashboardCard>
  </div>
</template>
<style>
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>
