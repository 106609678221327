<script setup lang="ts">
import BannerCenter, { type IBannerCenterData } from '@/components/shared/BannerCenter.vue'
const codeBannerData: IBannerCenterData = {
  title: 'Take a look our documentation to understand how our IDEs work'
}
</script>

<template>
  <BannerCenter :bannerData="codeBannerData">
    <a
      href="https://docs.jdoodle.com/compilers-and-ides"
      target="_blank"
      class="btn btn-primary block w-fit"
      >Find Out</a
    >
  </BannerCenter>
</template>
