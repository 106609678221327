declare global {
  interface Window {
    snid: any
  }
}
import loadScriptInBody from '@/services/loadScriptInBody.service'

/**
 * Load Snitcher
 */
export const loadSnitcher = () => {
  //@ts-ignore
  if (process.env.NODE_ENV === 'development') {
    return
  }
  loadScriptInBody
    .loadScriptInBody('https://snid.snitcher.com/8422298.js', null)
    .then(() => {
      window.snid('verify', '8422298')
    })
    .catch(() => {})

  loadScriptInBody
    .loadScriptInBody('https://a.omappapi.com/app/js/api.min.js', [
      { name: 'user', value: '269893' },
      { name: 'account', value: '287518' }
    ])
    .then(() => {})
    .catch(() => {})
}

/**
 * Unload Snitcher
 */
export const unloadSnitcher = () => {
  //@ts-ignore
  if (process.env.NODE_ENV === 'development') {
    return
  }
  loadScriptInBody.unloadScriptInBody('https://snid.snitcher.com/8422298.js')
  loadScriptInBody.unloadScriptInBody('https://a.omappapi.com/app/js/api.min.js')
}
