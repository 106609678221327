<script setup lang="ts">
import Gokul from '@/assets/images/about/team/Gokul.png'
import Anuj from '@/assets/images/about/team/Anuj.png'
import Antti from '@/assets/images/about/team/Antti.png'
import Chris from '@/assets/images/about/team/Chris.png'
import Dion from '@/assets/images/about/team/Dion.png'
import Dodson from '@/assets/images/about/team/Dodson.png'
import Jiayi from '@/assets/images/about/team/Jiayi.png'
import Johnny from '@/assets/images/about/team/Johnny.png'
import Mara from '@/assets/images/about/team/Mara.png'
import Nick from '@/assets/images/about/team/Nick.png'
import Anjali from '@/assets/images/about/team/Anjali.png'
import Chandru from '@/assets/images/about/team/Chandru.png'
import Iqbal from '@/assets/images/about/team/Iqbal.png'
import Sharada from '@/assets/images/about/team/Sharada.png'
import Umar from '@/assets/images/about/team/Umar.png'

interface ITeamMember {
  name: string
  position: string
  image: {
    src: string
    alt: string
  }
  linkedinUrl?: string
}

const teamData: ITeamMember[] = [
  {
    name: 'Gokul Chandrasekaran',
    position: 'Chief Executive Officer',
    image: {
      src: Gokul,
      alt: 'Gokul'
    },
    linkedinUrl: 'https://www.linkedin.com/in/gokulchandrasekaran-jdoodle/'
  },
  {
    name: 'Chandru Balasubramanium',
    position: 'Graphic Designer',
    image: {
      src: Chandru,
      alt: 'Chandru'
    },
    linkedinUrl: ''
  },
  {
    name: 'Dodson Joly Mathew',
    position: 'DevOps Engineer',
    image: {
      src: Dodson,
      alt: 'Dodson'
    },
    linkedinUrl: 'https://www.linkedin.com/in/dodsonjolymathew/'
  },
  {
    name: 'Anuj Vaidya',
    position: 'Marketing Lead',
    image: {
      src: Anuj,
      alt: 'Anuj'
    },
    linkedinUrl: 'https://www.linkedin.com/in/anuj-vaidya/'
  },
  {
    name: 'Sharada Gunathilake',
    position: 'Software Engineer',
    image: {
      src: Sharada,
      alt: 'Sharada'
    },
    linkedinUrl: 'https://www.linkedin.com/in/sharadagunathilake/'
  },
  {
    name: 'Chris Yuen',
    position: 'Software Engineer',
    image: {
      src: Chris,
      alt: 'Chris'
    },
    linkedinUrl: 'https://www.linkedin.com/in/chris-y-dev/'
  },
  {
    name: 'Anjali Ghanshani',
    position: 'Software Engineer',
    image: {
      src: Anjali,
      alt: 'Anjali'
    },
    linkedinUrl: 'https://www.linkedin.com/in/anjali-ghanshani/'
  },
  {
    name: 'Nicholas (Nick) John Randall',
    position: 'Software Engineer',
    image: {
      src: Nick,
      alt: 'Nick'
    },
    linkedinUrl: 'https://www.linkedin.com/in/nicholas-randall-982a9022b/'
  },
  {
    name: 'Dion Escarez',
    position: 'DevOps Engineer',
    image: {
      src: Dion,
      alt: 'Dion'
    },
    linkedinUrl: 'https://www.linkedin.com/in/dion-escarez/'
  },
  {
    name: 'Antti Puurula',
    position: 'Lead ML Engineer',
    image: {
      src: Antti,
      alt: 'Antti'
    },
    linkedinUrl: 'https://www.linkedin.com/in/antti-puurula/'
  },
  {
    name: 'Li (Johnny) He',
    position: 'Software Engineer',
    image: {
      src: Johnny,
      alt: 'Johnny'
    },
    linkedinUrl: 'https://www.linkedin.com/in/li-he-94a514194/'
  },
  {
    name: 'Jiayi Shen',
    position: 'Software Engineer',
    image: {
      src: Jiayi,
      alt: 'Jiayi'
    },
    linkedinUrl: 'https://www.linkedin.com/in/jiayi-shen-826577229/'
  },
  {
    name: 'Mara Cabriga',
    position: 'HR/Admin',
    image: {
      src: Mara,
      alt: 'Mara'
    },
    linkedinUrl: 'https://www.linkedin.com/in/mara-de-villa-cabriga-151023/'
  },
  {
    name: 'Iqbal Hanif',
    position: 'Software Engineer',
    image: {
      src: Iqbal,
      alt: 'Iqbal'
    },
    linkedinUrl: 'https://www.linkedin.com/in/iqbalhanif/'
  },
  {
    name: 'Umar Naseer',
    position: 'Technical Product Owner',
    image: {
      src: Umar,
      alt: 'Umar'
    },
    linkedinUrl: 'https://www.linkedin.com/in/umar-naseer-a826b1172/'
  }
]
</script>

<template>
  <div class="section-primary py-16">
    <div class="view-container">
      <h2 class="heading-large mb-10 text-center">Meet our team</h2>
      <div
        class="grid-rows-auto grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 md:gap-x-8 md:gap-y-5 lg:grid-cols-4 lg:gap-y-8 xl:gap-x-12"
      >
        <div v-for="(staff, idx) in teamData" :key="idx" class="col-span-1 p-2 text-center sm:p-5">
          <div
            class="m-auto mb-4 flex h-[160px] w-[160px] flex-col justify-center overflow-hidden rounded-lg"
          >
            <img :src="staff.image.src" :alt="staff.image.alt" class="w-full" />
          </div>
          <h3 class="text-lg text-text-primary dark:text-text-primary-dark">
            {{ staff.name }}
          </h3>
          <p class="mb-2 text-sm text-[#737373]">{{ staff.position }}</p>
          <a rel="nofollow" :href="staff.linkedinUrl" target="_blank" v-if="staff.linkedinUrl">
            <FontAwesomeIcon icon="fa-brands fa-linkedin" class="text-tertiary h-6 w-6" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
