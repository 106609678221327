<script setup lang="ts">
import SolutionNav, { type ISolutionLink } from '@/components/solution/SolutionNav.vue'

const linkList: ISolutionLink[] = [
  {
    text: 'Overview',
    idHash: '#overview'
  },
  {
    text: 'Features',
    idHash: '#features'
  },
  {
    text: 'Use Cases',
    idHash: '#useCases'
  },
  {
    text: 'Reviews',
    idHash: '#reviews'
  },
  {
    text: 'Pricing',
    idHash: '#pricing'
  },
  {
    text: 'FAQs',
    idHash: '#faqs'
  }
]
</script>

<template>
  <SolutionNav title="Library Provider" :list="linkList" />
</template>
