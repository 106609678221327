<script setup lang="ts">
import UseCaseBanner, { type IUseCaseBannerData } from '@/components/shared/UseCaseBanner.vue'
import Homepage_2 from '@/assets/images/svg/Homepage_2.svg'

const teachAndAssessSection: IUseCaseBannerData = {
  orangeTextAboveTitle: 'Education Institutions',
  title: "Increase your students' learning productivity by 25%",
  highlightedTitle: 'within your LMS and course material itself',
  description:
    "Stay ahead of the curve! Integrate an IDE directly into your course material and don't waste your time in managing dev environments.",
  secondaryButton: {
    text: 'Learn More',
    link: '/solutions/education-institutions'
  },
  textLeftImageRight: true,
  image: {
    src: Homepage_2,
    alt: 'animation svg'
  }
}
</script>

<template>
  <div class="section-primary">
    <UseCaseBanner :sectionData="teachAndAssessSection"> </UseCaseBanner>
  </div>
</template>
