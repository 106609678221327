<script setup lang="ts">
import { languagesItems, type ILanguage } from '@/utils/sharedData/languages'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { RouterLink } from 'vue-router'

const props = defineProps({
  isFullSize: {
    type: Boolean,
    required: false,
    default: true
  },
  isMini: {
    type: Boolean,
    required: false,
    default: false
  }
})

const searchTerm = ref('')
const languages: ILanguage[] = [...languagesItems].sort((a: ILanguage, b: ILanguage) =>
  a.displayName > b.displayName ? 1 : -1
)

const showDropdown = ref(false)

const links = computed<ILanguage[]>(() => {
  if (searchTerm.value === '' || !searchTerm.value) {
    //Filter popular and order by number (if present)
    return [...languages]
      .filter((lang: ILanguage) => lang.isPopular === true)
      .sort((a: ILanguage, b: ILanguage) => {
        if (a.isPopularNumber && b.isPopularNumber) {
          return a.isPopularNumber - b.isPopularNumber
        } else {
          if (a.isPopularNumber && !b.isPopularNumber) {
            return -1
          }

          if (b.isPopularNumber && !a.isPopularNumber) {
            return 1
          }

          return 0
        }
      })
  } else {
    let links = languages.filter((o: ILanguage) => {
      return o.searchTerm.includes(searchTerm.value.toLowerCase())
    })
    return links
  }
})

const featuredLanguages = computed<ILanguage[]>(() => {
  return [...languagesItems]
    .filter((lang: ILanguage) => lang.isFeatured === true)
    .sort((a: ILanguage, b: ILanguage) => {
      if (a.isPopularNumber && b.isPopularNumber) {
        return a.isPopularNumber - b.isPopularNumber
      } else {
        if (a.isPopularNumber && !b.isPopularNumber) {
          return -1
        }

        if (b.isPopularNumber && !a.isPopularNumber) {
          return 1
        }

        return 0
      }
    })
})

const placeholder = computed(() => {
  return props.isMini ? 'Search Language' : 'Search IDEs Compiler or Terminal'
})

/**
 * @param event Click event
 * If clicking outside of dropdown div, dropdown will disappear
 */
const clickEventHandler = (event: any) => {
  if (event.target.id != 'searchBar') {
    if (showDropdown.value) {
      if (event.target.id != 'codeSearchDropdown') {
        showDropdown.value = false
      } else {
        showDropdown.value = true
      }
    }
  }
}

onMounted(() => {
  document.addEventListener('click', clickEventHandler)
}),
  onUnmounted(() => {
    document.removeEventListener('click', clickEventHandler)
  })
</script>

<template>
  <div class="relative print:hidden" :class="isMini ? 'w-fit' : 'mb-4 w-full'">
    <input
      :placeholder="placeholder"
      name="languageSearch"
      id="searchBar"
      class="section-primary p-small block w-full rounded-lg border border-gray-900 dark:border-gray-200"
      :class="[
        {
          'px-12 py-4 sm:py-5': props.isFullSize
        },
        {
          'px-12 py-4': !props.isFullSize && !props.isMini
        },
        {
          'section-tertiary max-w-[200px] border-none py-2 pe-6 ps-12': props.isMini
        }
      ]"
      v-model="searchTerm"
      @click="showDropdown = !showDropdown"
    />
    <!-- Dropdown -->
    <div
      v-if="showDropdown"
      id="codeSearchDropdown"
      class="section-tertiary absolute z-50 w-full rounded-lg p-3"
      :class="[
        {
          'w-full': links.length > 10
        },
        {
          'sm:w-full md:max-w-md': links.length < 10
        },
        {
          'w-auto': props.isMini
        }
      ]"
    >
      <span
        v-if="searchTerm === '' || !searchTerm"
        class="mb-3 block text-xs"
        id="codeSearchDropdown"
        >Top Searches:</span
      >
      <div
        class="max-h-60 overflow-y-auto md:grid"
        :class="
          props.isFullSize && links.length > 10
            ? 'gap-3 md:grid-cols-2 lg:grid-cols-3'
            : 'md:grid-cols-1'
        "
      >
        <RouterLink
          v-for="item in links"
          :key="item.displayName"
          class="w-full"
          :to="item.link"
          id="codeSearchDropdown"
        >
          <div
            class="hover:section-secondary flex items-center justify-between rounded-md px-2 py-3"
          >
            <div>
              <!-- Dark -->
              <img
                v-if="!props.isMini"
                :src="item.darkIcon ? item.darkIcon : item.icon"
                class="light:hidden me-2 w-8 dark:inline-block"
              />
              <!-- Light -->
              <img
                v-if="item.darkIcon && !props.isMini"
                :src="item.icon"
                class="light:inline-block me-2 w-8 dark:hidden"
              />
              <span class="inline-block text-sm text-text-primary dark:text-text-primary-dark">{{
                item.displayName
              }}</span>
            </div>
            <span v-if="!props.isMini" class="text-brand inline-block text-[10px]"
              >Code Now
              <FontAwesomeIcon
                icon="fa-arrow-right"
                class="w-3 text-text-primary dark:text-text-primary-dark"
              />
            </span>
            <!-- Empty message -->
          </div>
        </RouterLink>
        <p v-if="links.length == 0" class="text-secondary text-sm">No language found.</p>
      </div>
    </div>
    <div
      class="pointer-events-none absolute inset-y-0 flex items-center pl-3"
      :class="isMini ? 'left-1 top-[-0.5rem]' : 'left-2 '"
    >
      <FontAwesomeIcon icon="fa-magnifying-glass" class="text-tertiary h-5 w-5" />
    </div>
    <button
      v-if="searchTerm"
      @click=";(searchTerm = ''), (showDropdown = false)"
      class="absolute inset-y-0 flex items-center pr-3"
      :class="isMini ? 'right-1 top-[-0.5rem]' : 'right-0 '"
    >
      <FontAwesomeIcon icon="fa-circle-xmark" class="h-5 w-5" />
    </button>
  </div>
  <!-- Featured language icons -->
  <div class="flex w-full flex-col justify-between gap-3 sm:flex-row" v-if="!props.isMini">
    <div class="flex flex-wrap items-center gap-2 text-xs">
      <span class="inline-block w-fit break-normal">Popular Searches: </span>
      <RouterLink
        v-for="(lang, idx) in featuredLanguages"
        :key="idx"
        :to="{ path: lang.link }"
        class="me-2 flex items-center text-xs"
      >
        <!-- Dark -->
        <img
          :src="lang.darkIcon ? lang.darkIcon : lang.icon"
          class="light:hidden me-2 w-6 dark:block"
        />
        <!-- Light -->
        <img v-if="lang.darkIcon" :src="lang.icon" class="light:block me-2 w-6 dark:hidden" />
        {{ lang.displayName }}
      </RouterLink>
    </div>

    <a v-if="props.isFullSize" class="text-xs underline" href="#suggestALangauge"
      >Suggest a Language</a
    >
  </div>
</template>

<style scoped>
/* For webkit-based browsers (Chrome, Safari) */
#codeSearchDropdown ::-webkit-scrollbar {
  width: 8px;
  /* Adjust width as needed */
}

#codeSearchDropdown ::-webkit-scrollbar-thumb {
  background-color: #747474;
  /* Set the background color of the thumb */
}

/* For Firefox and some newer browsers */
/* Note: This property might not be supported in all browsers */
/* .scrollbar-color: #555 #fff; */
/* Set thumb color and track color */
</style>
