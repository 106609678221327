<script setup lang="ts">
import Aboutus from '@/assets/images/svg/aboutus.svg'

interface ISectionData {
  title: string
  highlightedTitle: string
  body: string
}

const sectionData: ISectionData[] = [
  {
    title: 'Our vision is a',
    highlightedTitle: 'world driven by technological innovation',
    body: 'We know that technology has the power to change the world for the better. We believe that everyone should have the opportunity to learn to code, regardless of their age, background or experience.'
  },
  {
    title: 'Our mission is to',
    highlightedTitle: 'turbocharge the software development industry',
    body: "We believe that an efficient software development industry is essential to the world's future. We are committed to providing the tools and resources that people need to learn to code."
  }
]
</script>

<template>
  <div class="section-primary py-16">
    <div class="view-container">
      <div class="flex flex-col gap-14 md:flex-row">
        <div class="md:w-[50%]">
          <img :src="Aboutus" alt="about us" class="m-auto w-full lg:w-[80%]" />
        </div>
        <div class="flex max-w-lg flex-col gap-10 md:w-[50%] md:gap-20">
          <div v-for="(item, idx) in sectionData" :key="idx">
            <h2 class="heading-medium mb-5 leading-[2.2rem]">
              {{ item.title }}
              <span class="text-text-brand">{{ item.highlightedTitle }}</span
              >.
            </h2>
            <p class="text-secondary text-md">{{ item.body }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
