<script lang="ts" setup>
import CaseStudyChallengesAndSolutions from '@/components/shared/casestudy/CaseStudyChallengesAndSolutions.vue'
import Kodezi3 from '@/assets/images/casestudy/kodezi/Kodezi3.png'

const sectionData = {
  paragraph1:
    'Kodezi faced a significant challenge in providing a seamless and user-friendly coding environment for their learners. Coding platforms often come with steep learning curves, hindering novice programmers from effectively practicing and mastering coding skills. Additionally, managing and executing code online within their platform was a complex task. Some of the challenges Kedezi faced included:',
  challengeList: [
    'Providing a user-friendly coding environment',
    'Simplifying the execution of code online',
    'Eliminating the learning curve for novice programmers',
    'Managing and executing code within their platform'
  ],
  qouteText:
    "JDoodle makes coding easy with their straightforward API for running code online. It's a solid, user-friendly tool we'd suggest to anyone in a heartbeat.",
  qoutedBy: 'Ishraq Khan, Kodezi',
  paragraph2:
    "To overcome these challenges and streamline the coding experience for their users, Kodezi turned to JDoodle's Compiler API. The primary objectives were to provide an intuitive coding environment and simplify the execution of code online.",
  benefitheading: "JDoodle's Compiler API brought several crucial advantages:",
  benefitlist: [
    {
      title: 'User-Friendly Coding:',
      desc: "JDoodle's API offered an intuitive coding environment that made it easier for learners to practice coding without the complexities of traditional coding platforms."
    },
    {
      title: 'Seamless Integration:',
      desc: "The Compiler API seamlessly integrated into Kodezi's platform, allowing learners to write and execute code within the same interface, eliminating the need to navigate external tools."
    },
    {
      title: 'Immediate Feedback:',
      desc: "JDoodle's real-time feedback feature enabled learners to receive instant insights into their code's execution, facilitating quicker learning and problem-solving."
    },
    {
      title: 'Customization:',
      desc: 'JDoodle collaborated closely with Kodezi to tailor the API to their specific educational needs, ensuring a perfect fit for their courses.'
    },
    {
      title: 'Reliability:',
      desc: "JDoodle's API provided a stable and reliable coding environment, reducing frustration and increasing learners' confidence."
    }
  ],
  image3: {
    src: Kodezi3,
    alt: '',
    copyright: '© mediaphotos from Getty Images Signature via Canva.com '
  },
  result: {
    paragraph:
      "The integration of JDoodle's Compiler API into Kodezi's platform had a transformative impact on the learning experience and outcomes of students.",
    list: [
      {
        title: 'Enhanced Learning:',
        desc: 'Learners found it significantly easier to practice coding, resulting in improved comprehension and retention of coding concepts.'
      },
      {
        title: 'Streamlined Experience:',
        desc: "The seamless integration of the Compiler API within Kodezi's platform eliminated the need for learners to switch between different tools, creating a more efficient and user-friendly coding experience."
      },
      {
        title: 'Increased Engagement:',
        desc: 'The user-friendly environment and immediate feedback encouraged learners to engage more actively with coding exercises and assignments.'
      },

      {
        title: 'Positive Feedback:  ',
        desc: 'Kodezi received positive feedback from their users, praising the simplicity and effectiveness of the coding environment, which translated into higher user satisfaction.'
      }
    ]
  }
}
</script>

<template>
  <CaseStudyChallengesAndSolutions :sectionData="sectionData" />
</template>
