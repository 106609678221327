<script setup lang="ts">
import { SUBSCRIPTION_TYPE_ENUM } from '@/components/shared/subscription/enum/subscription.enum'
import CancelSubscription from '@/components/shared/subscription/CancelSubscription.vue'
import ReactivateSubscription from '@/components/shared/subscription/APIReactivateSubscription.vue'
import { initBundle, initCompilerApi } from '@/services/subscription.service'
import { apiPlansData, ide_teach_plansData } from '@/utils/sharedData/plans'
import { find } from 'lodash'
import { computed, onMounted, reactive, type PropType, watch } from 'vue'
import ModelLayout from '@/layouts/ModelLayout.vue'
import { SUBSCRIPTION_MODAL } from '@/utils/models'

const props = defineProps({
  subscriptionType: {
    type: String as PropType<SUBSCRIPTION_TYPE_ENUM>,
    required: true
  }
})

const currentPlanType = computed(() => {
  return props.subscriptionType
})

watch(
  () => props.subscriptionType,
  async () => {
    await initPage()
  }
)

const tabProperties = reactive({
  isUserLoggedIn: false,
  stripe: {},
  stripCard: {},
  clientPlan: ('' as string) || null,
  isUnlimited: false as any,
  clientFuturePlan: null,
  clientFuturePlanDetail: {},
  clientPlanDetail: {},
  isSubscriptionCancelled: false,
  hasPayAccount: false,
  stripeKey: ''
})

const plans = computed(() => {
  if (currentPlanType.value == SUBSCRIPTION_TYPE_ENUM.BUNDLE) {
    return ide_teach_plansData
  } else {
    return apiPlansData
  }
})

/**
 *
 */
async function initPage() {
  try {
    let data = undefined

    // Init Bundle or Compiler
    if (props.subscriptionType == SUBSCRIPTION_TYPE_ENUM.BUNDLE) {
      data = await initBundle()
    } else {
      data = await initCompilerApi()
    }

    if (data.plan) {
      tabProperties.clientPlan = data.plan
      tabProperties.isUnlimited = data.isUnlimited
      tabProperties.clientPlanDetail = find(plans.value, { name: data.plan }) || {}
      tabProperties.isSubscriptionCancelled = data.isCancelled
      tabProperties.hasPayAccount = data.hasStripeAccount
    } else {
      tabProperties.clientPlanDetail = {}
      tabProperties.clientPlan = null
      tabProperties.isUnlimited = false
      tabProperties.isSubscriptionCancelled = false
      tabProperties.hasPayAccount = false
    }

    if (data.futurePlan) {
      tabProperties.clientFuturePlan = data.futurePlan
      tabProperties.clientFuturePlanDetail =
        find(plans.value, {
          name: data.futurePlan
        }) || {}
    } else {
      tabProperties.clientFuturePlan = null
      tabProperties.clientFuturePlanDetail = {}
    }
    tabProperties.stripeKey = data.stripeKey
  } catch (e) {
    console.error(e)
  }
}

const emit = defineEmits(['planCancelled'])

/**
 *
 */
function cancelPlan() {
  emit('planCancelled')
}

onMounted(async () => {
  await initPage()
})
</script>

<template>
  <ModelLayout title="" :hs="SUBSCRIPTION_MODAL.CANCEL">
    <div class="section-primary text-center">
      <ReactivateSubscription
        v-show="tabProperties.isSubscriptionCancelled"
        :subscriptionType="subscriptionType"
      />

      <div
        v-if="!tabProperties.isSubscriptionCancelled && !tabProperties.isUnlimited"
        class="my-10"
      >
        <CancelSubscription
          :tab-properties="tabProperties"
          :subscriptionType="subscriptionType"
          @cancelPlan="cancelPlan"
        />
      </div>
    </div>
  </ModelLayout>
</template>
