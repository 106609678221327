import { TEACH_CONSTANTS } from '@/components/guru/constants/teach.constants'
import { GURU_ROLES, TEACH_ASSIGNMENT_STATUS } from '@/components/guru/enums/teach.enum'
import type IAssignmentDetailed from '@/components/guru/interface/IAssignmentDetailed'
import type IAssignmentMini from '@/components/guru/interface/IAssignmentMini'
import type IAssociation from '@/components/guru/interface/IAssociation'
import type { IAssociationAndInstituteOwn } from '@/components/guru/interface/IAssociationsAndInstituteOwn'
import type { IEvaluationData } from '@/components/guru/interface/IEvaluationData'
import type IInstitute from '@/components/guru/interface/IInstitute'
import type IInstituteOwn from '@/components/guru/interface/IInstituteOwn'
import type { IInstituteSubscriptionInfo } from '@/components/guru/interface/IInstituteSubscriptionInfo'
import type { IMultipleChoiceOption_ForAssignment } from '@/components/guru/interface/IMultipleChoiceOption_ForAssignment'
import { type IQuestionMini } from '@/components/guru/interface/IQuestionMini'
import type { IQuestion_ForAssignment } from '@/components/guru/interface/IQuestion_ForAssignment'
import { type IStudentGroup } from '@/components/guru/interface/IStudentGroup'
import type { IStudentInAssignment } from '@/components/guru/interface/IStudentInAssignment'
import type { ISubmission_Question } from '@/components/guru/interface/ISubmission_Question'
import { type IUser } from '@/components/guru/interface/IUser'
import { languagesItems, type ILanguage } from '@/utils/sharedData/languages'
import { defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue'

export const useTeachStore = defineStore('teach', () => {
  // ===== Teach instute/asso related =====
  const associations = ref<IAssociation[] | null>(null)
  const instituteOwn = ref<IInstituteOwn | null>(null)
  const guruInitialised = ref<boolean>(false)
  const currentAssociation = ref<IInstitute | undefined>(undefined)
  const currentAssociationFull = ref<IAssociation | undefined>(undefined)

  const studentGroupsInCurrentAsso = ref<IStudentGroup[] | null>(null)

  /**
   * Search associations and find current one
   * @param insCode Institute Code of association
   */
  const getAndSetCurrentAssociation = (insCode: string | undefined) => {
    if (guruInitialised.value && insCode) {
      const foundAsso = associations.value?.find(
        (association: IAssociation) => association.institute.code == insCode
      )
      currentAssociation.value = foundAsso?.institute
      currentAssociationFull.value = foundAsso
    } else {
      currentAssociation.value = undefined
    }
  }

  /**
   * Set Associations and Ins Own
   * @param res IAssociationAndInstituteOwn response object
   */
  const setAssociationAndInstituteOwn = (res: IAssociationAndInstituteOwn) => {
    if (res.associations) {
      associations.value = res.associations
    }

    if (res.instituteOwn) {
      instituteOwn.value = res.instituteOwn
    }

    guruInitialised.value = true
  }

  //Roles

  const isAccountOwner = computed(() => {
    if (currentAssociationFull.value?.associationTypes.includes(GURU_ROLES.ACCOUNT_OWNER))
      return true

    return false
  })

  const isInstituteAdmin = computed(() => {
    if (currentAssociationFull.value?.associationTypes.includes(GURU_ROLES.INSTITUTE_ADMIN))
      return true

    return false
  })

  const isTeacher = computed(() => {
    if (currentAssociationFull.value?.associationTypes.includes(GURU_ROLES.TEACHER)) return true

    return false
  })

  const isStudent = computed(() => {
    if (currentAssociationFull.value?.associationTypes.includes(GURU_ROLES.STUDENT)) return true

    return false
  })

  // ===== Institute Users related =====
  const editPrefilledUser = ref<IUser | undefined>(undefined)
  const editPrefilledStudentGroup = ref<IStudentGroup | undefined>(undefined)
  const currentUserRole = computed(() => {
    if (currentAssociationFull.value?.associationTypes.includes(GURU_ROLES.ACCOUNT_OWNER)) {
      return GURU_ROLES.ACCOUNT_OWNER
    } else if (
      currentAssociationFull.value?.associationTypes.includes(GURU_ROLES.INSTITUTE_ADMIN)
    ) {
      return GURU_ROLES.INSTITUTE_ADMIN
    } else if (currentAssociationFull.value?.associationTypes.includes(GURU_ROLES.TEACHER)) {
      return GURU_ROLES.TEACHER
    }

    return GURU_ROLES.STUDENT
  })

  const studentCount = computed(() => {
    return instituteUsers.value?.filter((user: IUser) => {
      return user.roles.includes(GURU_ROLES.STUDENT)
    }).length
  })

  // ===== Assignment related =====
  const questionToEdit = ref<IQuestionMini | undefined>(undefined)
  const showQuestionForm = ref<boolean>()
  // ===== Subscription related =====
  //Stores
  const instituteSubscriptionInfo = ref<IInstituteSubscriptionInfo | null>(null)
  const instituteAssignments = ref<IAssignmentMini[] | null>(null)
  const currentAssignment = ref<IAssignmentDetailed | null>(null)
  const instituteUsers = ref<IUser[] | null>(null)

  //Computed
  const draftLimitReached = computed(() => {
    //Once subscription info is fetched
    if (instituteSubscriptionInfo.value != null) {
      //If FREE
      if (!instituteSubscriptionInfo.value.isPremium) {
        const draftAssignments = instituteAssignments.value?.filter(
          (ass: IAssignmentMini) => ass.status == TEACH_ASSIGNMENT_STATUS.DRAFT
        )

        // Count draft assignments
        if (draftAssignments) {
          return (
            draftAssignments.length >= instituteSubscriptionInfo.value?.limit.draftAssignmentLimit
          )
        } else {
          return false
        }
      } else {
        // IF PREMIUM
        return false
      }
    }
    return false
  })

  const activeAssignmentLimitReached = computed(() => {
    if (instituteSubscriptionInfo.value) {
      //If FREE
      if (!instituteSubscriptionInfo.value.isPremium) {
        const activeAssignments = instituteAssignments.value?.filter((ass: IAssignmentMini) => {
          return (
            ass.status == TEACH_ASSIGNMENT_STATUS.IN_PROGRESS ||
            ass.status == TEACH_ASSIGNMENT_STATUS.READY
          )
        })
        //Count assignments
        if (activeAssignments) {
          return (
            activeAssignments.length >= instituteSubscriptionInfo.value?.limit.activeAssignmentLimit
          )
        } else {
          return true
        }
      } else {
        //IF PREMIUM
        return false
      }
    }
    return false
  })

  const questionsLimitReached = computed(() => {
    if (currentAssignment.value && instituteSubscriptionInfo.value) {
      //If FREE
      if (!instituteSubscriptionInfo.value.isPremium) {
        //Count questions
        return (
          currentAssignment.value.questions.length >=
          instituteSubscriptionInfo.value?.limit.questionLimit
        )
      } else {
        //If PREMIUM
        return false
      }
    }

    return false
  })

  const studentUsersLimitReached = computed(() => {
    if (instituteUsers.value && instituteSubscriptionInfo.value) {
      //If FREE
      if (!instituteSubscriptionInfo.value.isPremium) {
        const students = instituteUsers.value.filter((user: IUser) =>
          user.roles.includes(GURU_ROLES.STUDENT)
        )
        //Count students
        if (students) {
          return students.length >= instituteSubscriptionInfo.value?.limit.studentLimit
        } else {
          return true
        }
      } else {
        //If PREMIUM
        return false
      }
    }
    return false
  })

  const teacherUsersLimitReached = computed(() => {
    if (instituteUsers.value && instituteSubscriptionInfo.value) {
      //If FREE
      if (!instituteSubscriptionInfo.value.isPremium) {
        const teachers = instituteUsers.value.filter((user: IUser) =>
          user.roles.includes(GURU_ROLES.TEACHER)
        )
        //Count teachers
        if (teachers) {
          return teachers.length >= instituteSubscriptionInfo.value?.limit.teacherLimit
        } else {
          return true
        }
      } else {
        //If Premium
        return false
      }
    }
    return false
  })

  const adminUsersLimitReached = computed(() => {
    if (instituteUsers.value && instituteSubscriptionInfo.value) {
      //If FREE
      if (!instituteSubscriptionInfo.value.isPremium) {
        const admins = instituteUsers.value.filter((user: IUser) =>
          user.roles.includes(GURU_ROLES.INSTITUTE_ADMIN)
        )
        //Count admins
        if (admins) {
          return admins.length >= instituteSubscriptionInfo.value?.limit.adminLimit
        } else {
          return true
        }
      } else {
        //If Premium
        return false
      }
    }
    return false
  })

  //Messages
  const studentLimitMessage = computed(() => {
    return `You have reached the institute's plan limit of ${instituteSubscriptionInfo.value?.limit.studentLimit} student users`
  })

  const teacherLimitMessage = computed(() => {
    return `You have reached the institute's plan limit of ${instituteSubscriptionInfo.value?.limit.teacherLimit} teacher users`
  })

  const adminLimitMessage = computed(() => {
    return `You have reached the institute's plan limit of ${instituteSubscriptionInfo.value?.limit.adminLimit} admin user(s)`
  })

  const draftLimitMessage = computed(() => {
    return `You have reached the institute's plan limit of ${instituteSubscriptionInfo.value?.limit.draftAssignmentLimit} draft assignments`
  })

  // ====== Evaluation-related ======
  const studentUnderEvaluation = ref<IStudentInAssignment | undefined>(undefined)

  // ====== Language-related ======
  const assignmentDefaultLanguage = ref<string>()
  const assignmentTryLanguage = ref<string | null>('')
  const assignmentDefaultLanguageVersionIndex = ref<number | undefined>()
  const allLanguages = languagesItems
  const codeAnswer = ref<string | undefined>(undefined)
  const languagesSupportedByAssignment = languagesItems.filter(
    (lang: ILanguage) => lang.isSupportedInAssignments == true
  )

  // ====== For Attend/Preview/Evaluate Questions ======
  const initialQuestionForm = reactive({
    assQuestionId: '',
    question: '',
    questionType: '',
    language: {},
    versionIndex: null,
    markForCorrectAnswer: null,
    optionLevelMarking: false,
    negativeMark: null,
    correctAnswerOption: null,
    options: [] as IMultipleChoiceOption_ForAssignment[],
    testCases: [],
    args: '',
    stdin: '',
    output: '',
    openTrySection: false,
    isCodeExecuting: false,
    executionTime: null,
    memory: 0,
    cpuTime: 0,
    isMarkedForReview: false,
    answer: undefined,
    evaluated: false,
    autoCorrectNotes: '',
    minLength: null,
    maxLength: null,
    mark: null,
    comment: null,

    questionIdToSave: undefined as undefined | number | string,
    reachedReview: undefined
  })

  const currentSavedAnswers = ref<ISubmission_Question[]>()

  // ======= Functions =======
  // ********** Subscription related **********
  /**
   * Set stored subscription info from service
   * @param subscriptionInfo IInstituteSubscriptionInfo Object
   */
  function setSubscriptionInfo(subscriptionInfo: IInstituteSubscriptionInfo) {
    instituteSubscriptionInfo.value = subscriptionInfo
  }

  /**
   * Set stored assignments to compute limitations
   * @param assignments array of IAssignmentMini
   */
  function setAssignments(assignments: IAssignmentMini[]) {
    instituteAssignments.value = assignments
  }

  /**
   * Set stored current assignment to compute limitations
   * @param assignment array of IAssignmentMini
   */
  function setCurrentAssignment(assignment: IAssignmentDetailed) {
    currentAssignment.value = assignment
    setAssignmentLanguage(assignment)
  }

  /**
   * Set stored users to compute limitations
   * @param users array of IUsers
   */
  function setUsers(users: IUser[]) {
    instituteUsers.value = users
  }

  // ********** Assignment related **********
  /**
   * Identifies the language of an assigment using the saved strng
   * @param question IQuestion_ForAssignment object
   */
  function setAssignmentLanguageFromRef(question: IQuestion_ForAssignment) {
    if (question) {
      assignmentDefaultLanguage.value = question.language || 'none'
      assignmentTryLanguage.value = null
      assignmentDefaultLanguageVersionIndex.value = (question.versionIndex as number) || 0
    }
  }
  /**
   * Identifies the language of an assigment using the saved strng
   * @param language string
   */
  function setAssignmentTryLanguage(language: string) {
    if (language) {
      assignmentTryLanguage.value = language
      assignmentDefaultLanguageVersionIndex.value = 0
    }
  }

  /**
   * Identifies the language of an assigment using the saved strng
   * @param assignment IAssignment object
   */
  function setAssignmentLanguage(assignment: IAssignmentDetailed | IEvaluationData) {
    //If evaluation data
    if ((assignment as IEvaluationData).assignment) {
      assignmentDefaultLanguage.value = (assignment as IEvaluationData).assignment.defaultLanguage
      assignmentDefaultLanguageVersionIndex.value = (
        assignment as IEvaluationData
      ).assignment.versionIndex
    } else {
      //If IAssignmentDetailed
      if (assignment) {
        assignmentDefaultLanguage.value = (assignment as IAssignmentDetailed).defaultLanguage
        assignmentDefaultLanguageVersionIndex.value = (
          assignment as IAssignmentDetailed
        ).versionIndex
      }
    }
  }

  /**
   * @param code - sets code answer
   */
  function setCodeAnswer(code: string) {
    codeAnswer.value = code
  }

  /**
   * Get language displayname of default language
   * @returns language displayname
   */
  function getCurrentAssignmentLanguageDisplayName() {
    return allLanguages.find((lang: ILanguage) => lang.language == assignmentDefaultLanguage.value)
      ?.displayName
  }

  /**
   * Get assignment's language version
   * @returns version displayname
   */
  function getCurrentAssignmentLanguageVersion() {
    const versions = allLanguages.find(
      (lang: ILanguage) => lang.language == assignmentDefaultLanguage.value
    )?.versions

    if (versions && assignmentDefaultLanguageVersionIndex.value != undefined) {
      return versions[assignmentDefaultLanguageVersionIndex.value]
    }
  }

  /**
   * @param questionType - questiontype
   * @returns - returns boolean
   */
  function isSingleChoiceQuestion(questionType: number | undefined) {
    return questionType === TEACH_CONSTANTS.QUESTION_TYPES[0].value
  }

  /**
   * @param questionType - questiontype
   * @returns - returns boolean
   */
  function isMultipleChoiceQuestion(questionType: number | undefined) {
    return questionType === TEACH_CONSTANTS.QUESTION_TYPES[1].value
  }

  /**
   * @param questionType - questionstype
   * @returns - returns boolean
   */
  function isChoiceQuestion(questionType: number | undefined) {
    return isSingleChoiceQuestion(questionType) || isMultipleChoiceQuestion(questionType)
  }

  /**
   * @param questionType - questiontype
   * @returns - returns boolean
   */
  function isOpenEndedQuestion(questionType: number | undefined) {
    return questionType === TEACH_CONSTANTS.QUESTION_TYPES[2].value
  }

  /**
   * @param questionType - questiontype
   * @returns - returns boolean
   */
  function isProgramQuestion(questionType: number | undefined) {
    return questionType === TEACH_CONSTANTS.QUESTION_TYPES[3].value
  }

  /**
   * @param questionType - questiontype
   * @returns - returns boolean
   */
  function isTrueOrFalseQuestion(questionType: number | undefined) {
    return questionType == TEACH_CONSTANTS.QUESTION_TYPES[4].value
  }

  // ********** Evaluation related **********
  /**
   * @param student - sets student under evalation using object as a parameter
   */
  function setStudentUnderEvaluation(student: IStudentInAssignment) {
    studentUnderEvaluation.value = student
  }

  return {
    // === Subscription ===
    instituteSubscriptionInfo,
    setSubscriptionInfo,
    instituteAssignments,
    setAssignments,
    currentAssignment,
    setCurrentAssignment,
    draftLimitReached,
    activeAssignmentLimitReached,
    questionsLimitReached,
    studentLimitMessage,
    teacherLimitMessage,
    adminLimitMessage,
    setUsers,
    studentUsersLimitReached,
    teacherUsersLimitReached,
    adminUsersLimitReached,
    draftLimitMessage,

    // === Teach ===
    associations,
    instituteOwn,
    guruInitialised,
    currentAssociation,
    currentAssociationFull,
    getAndSetCurrentAssociation,
    setAssociationAndInstituteOwn,
    isAccountOwner,
    isInstituteAdmin,
    isTeacher,
    isStudent,

    // == Users ==
    editPrefilledUser,
    currentUserRole,
    instituteUsers,
    studentCount,
    //Student Groups
    editPrefilledStudentGroup,
    studentGroupsInCurrentAsso,

    // == Assignments ==
    questionToEdit,
    showQuestionForm,
    currentSavedAnswers,

    //Evaluation
    studentUnderEvaluation,
    setStudentUnderEvaluation,
    initialQuestionForm,

    //languages
    assignmentDefaultLanguage,
    assignmentTryLanguage,
    assignmentDefaultLanguageVersionIndex,
    allLanguages,
    codeAnswer,
    languagesSupportedByAssignment,
    getCurrentAssignmentLanguageDisplayName,
    getCurrentAssignmentLanguageVersion,

    //functions
    setCodeAnswer,
    isSingleChoiceQuestion,
    isMultipleChoiceQuestion,
    isChoiceQuestion,
    isOpenEndedQuestion,
    isProgramQuestion,
    isTrueOrFalseQuestion,
    setAssignmentLanguage,
    setAssignmentLanguageFromRef,
    setAssignmentTryLanguage
  }
})
