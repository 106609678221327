<script lang="ts" setup>
import CaseStudyChallengesAndSolutions from '@/components/shared/casestudy/CaseStudyChallengesAndSolutions.vue'
import kansasPeople from '@/assets/images/casestudy/kansas/KansasUni3.png'

const sectionData = {
  paragraph1:
    'As ATS expanded its digital assessment offerings, they encountered a crucial challenge incorporating a robust compiler API into their applications. They needed a solution that seamlessly integrated with their existing infrastructure, enabling students and educators to compile and run code with ease.',
  qouteText:
    'JDoodle has a history of providing University of Kansas Assessment and Technology Solutions (ATS) with prompt support and willingness to help troubleshoot issues. Their team has provided a consistent service with great documentation that allowed ATS to easily integrate their API into our applications..',
  qoutedBy: 'By Susan Dressler, Director, University of Kansas ATS',
  paragraph2:
    "ATS chose JDoodle for its consistent service and impeccable documentation. JDoodle provided a well-documented Compiler API, ensuring seamless integration into ATS's applications. Renowned for its exceptional Compiler API and user-friendly design, JDoodle emerged as the ideal partner for ATS. Recognising the potential of JDoodle's Compiler API, ATS joined forces with the platform to elevate their assessment applications and streamline code compilation processes.",

  image3: {
    src: kansasPeople,
    alt: '',
    copyright: '© Pressmaster Signature via Canva.com'
  },
  result: {
    paragraph:
      "The collaboration between ATS and JDoodle yielded impressive results. ATS successfully integrated JDoodle's Compiler API into their applications, enriching their assessment ecosystem with powerful code compilation capabilities. This enhancement empowered students and educators with a more immersive and interactive learning environment. The partnership between ATS and JDoodle exemplifies the significance of seamless API integration and dedicated support. JDoodle's commitment to empowering education institutions with cutting-edge technology has enhanced the University of Kansas ATS platform, contributing to an enriched learning experience for students and educators alike. With a foundation of trust and collaboration, ATS and JDoodle continue to drive innovation in the realm of academic assessments and technology solutions."
  }
}
</script>

<template>
  <CaseStudyChallengesAndSolutions :sectionData="sectionData" />
</template>
