<script setup lang="ts">
import { ref, computed, onBeforeMount, onBeforeUnmount, onMounted } from 'vue'
import { IDECONSTANT } from '@/utils/ide'
import dashboardIdeService from '@/services/dashboard.ide.service'
import dashboardPluginService from '@/services/dashboard.plugin.service'
import ideService from '@/services/ide/ide.service'
import { useDashboardIdeStore } from '@/stores/dashboard.ide.store'
import { useDashboardPluginStore } from '@/stores/dashboard.plugin.store'

import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'

const dashboardIdeStore = useDashboardIdeStore()
const dashboardPluginStore = useDashboardPluginStore()

const timeOut = ref<any | null>(null)

const copiedToClip = ref<boolean>(false)
const showCopiedMessageText = ref<string>('')
const copySuccess = ref<boolean>(false)

const title = computed(() => {
  return dashboardPluginStore.title
})
const isCodeEditor = computed(() => {
  return dashboardIdeStore.codeEditor !== null
})
const isScript = computed(() => {
  return dashboardIdeStore.script
})
/**
 * copy the current code text to clipboard
 * @returns true if success else false
 */
const tryCopyToClipboard = async () => {
  const text = dashboardIdeStore.script
  return window.navigator.clipboard.writeText(text).then(
    () => {
      return true
    },
    () => {
      return false
    }
  )
}
/**
 * Copy the project script to the  to clipboard
 */
const copyToClipboard = async () => {
  const isCopied = await tryCopyToClipboard()
  if (isCopied) {
    showCopiedMessageText.value = 'Copied to clipboard!'
    copySuccess.value = true
    copiedToClip.value = true
  } else {
    showCopiedMessageText.value = 'Copy to clipboard failed!'
    copySuccess.value = false
    copiedToClip.value = true
  }
  if (timeOut.value) clearTimeout(timeOut.value)
  timeOut.value = setTimeout(() => {
    copiedToClip.value = false
    copySuccess.value = false
    showCopiedMessageText.value = ''
  }, 5000)
}
onBeforeMount(() => {
  ideService.cleanIdeStore()
  dashboardIdeService.cleanDashboardIdeStore()
  const script = dashboardPluginService.getDefaultEmbededCode()
  dashboardIdeStore.setScript(script)
  dashboardIdeService.initOnRouterChange('text', true)
})
onMounted(() => {
  const title = 'Integrate Jdoodle Plugin with Simple 3 lines of Code'
  dashboardPluginStore.setTitle(title)
  const script = dashboardPluginService.getDefaultEmbededCode()
  dashboardIdeStore.setScript(script)
})
onBeforeUnmount(() => {
  dashboardIdeService.cleanDashboardIdeStore()
  dashboardIdeService.cleanIde()
  ideService.cleanIdeStore()
})
</script>

<template>
  <DashboardCard id="integrateCode" :title="title">
    <div class="flex flex-col gap-4">
      <div v-show="isCodeEditor && isScript">
        <div
          :id="IDECONSTANT.DASHBOARDCODEEDITOR"
          class="section-dashboard bg block h-36 w-full min-w-full rounded-lg"
        ></div>
      </div>
      <button class="btn btn-secondary flex w-fit gap-2" @click="copyToClipboard">
        <FontAwesomeIcon icon="fa-copy" class="h-4 w-4" />
        Copy to Clipboard
      </button>
      <p
        :class="['p-xsmall ', { success: copySuccess, error: !copySuccess, hidden: !copiedToClip }]"
      >
        {{ showCopiedMessageText }}
      </p>
    </div>
  </DashboardCard>
</template>
