<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

interface IShareItems {
  icon: string
  link: string
  title: string
  color: string
}
const shareItems: IShareItems[] = [
  {
    icon: 'fa-brands fa-facebook',
    link: 'https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%3Dhttps%253A%252F%252Fwww.jdoodle.com&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB',
    title: 'Facebook',
    color: 'text-blue-600'
  },
  {
    icon: 'fa-brands fa-twitter',
    link: 'https://twitter.com/intent/tweet/?text=JDoodle%20-%20free%20Online%20Compiler,%20Editor%20for%20Java,%20C/C%20,%20etc&url=https%3A%2F%2Fwww.jdoodle.com',
    title: 'Twitter',
    color: 'text-blue-400'
  },
  {
    icon: 'fa-envelope',
    link: 'mailto:?subject=JDoodle%20-%20free%20Online%20Compiler,%20Editor%20for%20Java,%20C/C%20,%20etc.&body=https%3A%2F%2Fwww.jdoodle.com',
    title: 'Email',
    color: 'text-gray-600'
  },
  {
    icon: 'fa-brands fa-linkedin',
    link: 'https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fwww.jdoodle.com&title=JDoodle%20-%20free%20Online%20Compiler,%20Editor%20for%20Java,%20C/C%20,%20etc.&summary=JDoodle%20-%20free%20Online%20Compiler,%20Editor%20for%20Java,%20C/C%20,%20etc.&source=https%3A%2F%2Fwww.jdoodle.com',
    title: 'LinkedIn',
    color: 'text-blue-600'
  }
]
const copiedToClip = ref<boolean>(false)
const showCopiedMessageText = ref<string>('')
const copySuccess = ref<boolean>(false)
const currentUrl = ref<string>(window.location.origin + route.fullPath)
const timeOut = ref<any | null>(null)

watch(
  () => route.fullPath,
  () => {
    currentUrl.value = window.location.origin + route.fullPath
  }
)

/**
 * Copy to clipboard
 */
const copyToClipboard = async () => {
  window.navigator.clipboard.writeText(currentUrl.value).then(
    () => {
      showCopiedMessageText.value = 'Copied to clipboard!'
      copySuccess.value = true
      copiedToClip.value = true
    },
    () => {
      showCopiedMessageText.value = 'Copy to clipboard failed!'
      copySuccess.value = false
      copiedToClip.value = true
    }
  )
  if (timeOut.value) clearTimeout(timeOut.value)
  timeOut.value = setTimeout(() => {
    copiedToClip.value = false
    copySuccess.value = false
    showCopiedMessageText.value = ''
  }, 5000)
}
</script>

<template>
  <div class="section-primary mt-12 grid grid-cols-2 grid-rows-1 gap-4 print:hidden lg:mt-4">
    <!-- Card -->
    <div class="section-tertiary col-span-2 rounded-lg p-5 print:break-inside-avoid lg:col-span-1">
      <h2 class="text-md mb-5 text-center">Share this awesome tool with your peers</h2>
      <div class="flex flex-1 flex-col gap-5">
        <div class="flex w-full flex-wrap justify-start gap-y-4 lg:justify-between">
          <div
            v-for="(item, index) in shareItems"
            :key="index"
            class="flex w-[50%] justify-center md:w-[25%]"
          >
            <a
              :href="item.link"
              target="_blank"
              rel="nofollow"
              class="flex w-fit flex-col items-center justify-start gap-2 rounded-full p-1 text-sm hover:text-btn-primary-hover"
            >
              <FontAwesomeIcon
                :icon="item.icon"
                :class="[
                  'h-6',
                  'w-6',
                  'rounded-full',
                  'bg-white',
                  'p-1',
                  {
                    [item.color]: true
                  }
                ]"
              />
              {{ item.title }}
            </a>
          </div>
        </div>

        <div>
          <div class="relative flex">
            <!-- Widescreen version -->
            <input
              :value="currentUrl"
              class="section-primary p-small block hidden w-full rounded-md px-4 py-3 sm:block"
              :disabled="true"
            />
            <!-- Mobile version -->
            <input
              :value="`${currentUrl.slice(0, 25)}...`"
              class="section-primary p-small block w-full rounded-md px-4 py-3 sm:hidden"
              :disabled="true"
            />
            <FontAwesomeIcon
              icon="fa-copy"
              class="text-brand absolute right-5 top-3"
              role="button"
              @click="copyToClipboard"
            />
          </div>
          <p
            :class="[
              'p-xsmall mt-2',
              { success: copySuccess, error: !copySuccess, hidden: !copiedToClip }
            ]"
          >
            {{ showCopiedMessageText }}
          </p>
        </div>
      </div>
    </div>
    <div class="section-tertiary col-span-2 rounded-lg p-5 print:break-inside-avoid lg:col-span-1">
      <h2 class="text-md mb-5 text-center">Like coding with JDoodle? Share a review!</h2>
      <div class="flex justify-center">
        <a
          href="https://www.producthunt.com/products/jdoodle/reviews?utm_source=badge-product_review&utm_medium=badge&utm_souce=badge-jdoodle"
          rel="nofollow"
          target="_blank"
          ><img
            src="https://api.producthunt.com/widgets/embed-image/v1/product_review.svg?product_id=540497&theme=light"
            alt="JDoodle - JDoodle&#0032;provides&#0032;zero&#0045;setup&#0032;cloud&#0032;IDEs&#0032;for&#0032;76&#0032;languages | Product Hunt"
            style="width: 250px; height: 54px"
            width="250"
            height="54"
            class="inline-block"
        /></a>
      </div>
    </div>
  </div>
</template>
