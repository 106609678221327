// === General ===

export enum TEACH_SECTION {
  ASSIGNMENT = 'assignment',
  STUDENT_GROUP = 'student group'
}

// === User-related ===
export enum GURU_USER_STATUS {
  PENDING_USER_ACCEPTANCE = 'Pending User Acceptance',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  REJECT = 'Rejected',
  EXITED = 'Exited'
}

export enum GURU_USER_TYPE {
  TEACHER = 'teacher-active',
  STUDENT_ACTIVE = 'student-active',
  STUDENT = 'student',
  STAFF = 'staff',
  STAFF_ACTIVE = 'staff-active',
  STUDENT_GROUP = 'PvtStudentFromGroup'
}

export enum GURU_USER_ACTIVATION_ACTION {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate'
}

export enum GURU_USER_REQUEST {
  ADD = 'add',
  REMOVE = 'remove'
}

export enum GURU_ROLES {
  ACCOUNT_OWNER = 'Account Owner',
  INSTITUTE_ADMIN = 'Institute Admin',
  TEACHER = 'Teacher',
  STUDENT = 'Student'
}

// === Institute ===

export enum GURU_INSTITUTE_ACTIONS {
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view'
}

export enum INSTITUTE_ROLE_RESPONSE {
  ACCEPT = 'accept',
  REJECT = 'reject'
}

// === Assignments ===

export enum GURU_ASSIGNMENT_ACTIONS {
  ADD = 'add',
  EDIT = 'edit',
  CLONE = 'clone'
}

export enum GURU_ASSIGNMENT_RESULT_TYPE {
  MARK_AND_COMMENT = 'Mark and Comment',
  MARK_ONLY = 'Mark Only',
  COMMENT_ONLY = 'Comment Only'
}

export enum GURU_ASSIGNMENT_DISCLOSE_TYPE {
  MANUAL = 'Manual',
  ON_SUBMISSION = 'On Assignment Submission'
}

export enum TEACH_ASSIGNMENT_STATUS {
  //Assignment status
  DRAFT = 'Draft',
  READY = 'Ready',
  ON_HOLD = 'On Hold',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed'
}

export enum TEACH_DURATION_MODAL_MODE {
  ACTIVATE = 'activate',
  CHANGE = 'change'
}

export enum TEACH_STUDENT_ASSIGNMENT_STATUS {
  YET_TO_START = 'Yet to Start',
  IN_PROGRESS = 'In Progress',
  SUBMITTED = 'Submitted',
  ERROR_WHEN_AUTO_CORRECT = 'Unable to Auto Correct',
  EVALUATED = 'Evaluated',
  PARTIALLY_EVALUATED = 'Partially Evaluated',
  RESULT_ANNOUNCED = 'Result Announced',
  REEVALUATION_REQUESTED = 'Reevaluation Requested'
}

// === Questions ===

export enum GURU_QUESTION_TYPE {
  MC_ONE_ANSWER = 'Multiple Choice - One Answer',
  MC_MULTIPLE_ANSWER = 'Multiple Choice - Multiple Answer',
  OPEN_ENDED = 'Open Ended',
  PROGRAM = 'Program',
  TRUE_OR_FALSE = 'True or False'
}

// === Student Group ===
export enum STUDENT_GROUP_ACTIONS {
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view',
  REMOVE = 'remove'
}

export enum GURU_USER_ACTION_MODES {
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
  VIEW = 'view'
}
