interface IData {
  name: string
  value: string
}
/**
 * Load script in body
 * @param src Script source
 * @param data Data to pass to the script
 * @returns Promise
 */
const loadScriptInBody = (src: string, data?: IData[] | null): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) {
      resolve()
      return
    }

    const el = document.createElement('script')
    el.type = 'text/javascript'
    el.async = true
    el.src = src

    if (data) {
      for (const key in data) {
        el.dataset[data[key].name] = data[key].value
      }
    }

    el.addEventListener('load', () => resolve())
    el.addEventListener('error', () => reject())
    el.addEventListener('abort', () => reject())

    document.body.appendChild(el)
  })
}
/**
 * Unload script in body
 * @param src Script source
 * @returns Promise
 */
const unloadScriptInBody = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const el = document.querySelector(`script[src="${src}"]`)
    if (el) {
      document.body.removeChild(el)
      resolve()
    } else {
      reject()
    }
  })
}
export default {
  loadScriptInBody,
  unloadScriptInBody
}
