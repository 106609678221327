<script setup lang="ts">
import { RouterLink } from 'vue-router'
import Integrate from '@/assets/images/header/product_integrate.png'
import Coding from '@/assets/images/header/product_coding.png'
import Teach from '@/assets/images/header/product_teach.png'
import MegaMenu from '@/components/header/navigation/MegaMenu.vue'

interface ILinkItem {
  title: string
  routeName: string
  path: string
  img: string
}

const LinkItems: ILinkItem[] = [
  {
    title: 'Online Coding',
    routeName: 'code-online-compiler-ide',
    path: 'code-online-compiler-ide',
    img: Coding
  },
  {
    title: 'Integrate IDEs',
    routeName: 'compilerApi',
    path: 'intergrate-online-ide-compiler-api-plugins',
    img: Integrate
  },
  {
    title: 'Teach & Assess Platform',
    routeName: 'online-assessment-teaching',
    path: 'online-assessment-teaching',
    img: Teach
  }
]
</script>

<template>
  <MegaMenu>
    <div class="flex w-full flex-col justify-start gap-6 pt-6 sm:gap-8 sm:pt-0 lg:flex-row">
      <RouterLink v-for="(item, index) of LinkItems" :key="index" :to="`/${item.path}`">
        <div class="flex gap-8">
          <div class="flex cursor-pointer flex-col gap-6">
            <div class="m-auto h-40 w-72 rounded-md bg-neutral-200 dark:bg-neutral-50">
              <img class="h-full rounded-xl" :src="item.img" :alt="item.img" />
            </div>
            <h3 class="link-primary p-normal flex justify-center text-justify">
              {{ item.title }}
            </h3>
          </div>
        </div>
      </RouterLink>
    </div>
  </MegaMenu>
</template>
