<script setup lang="ts">
import { computed, onMounted, watch } from 'vue'
import { IDECONSTANT } from '@/utils/ide'
import { useIdeStore } from '@/stores/ide.store'
import editorService from '@/services/ide/editor.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'

const ideStore = useIdeStore()

const isBlockly = computed(() => {
  return ideStore.isBlockly
})
const isAdvanced = computed(() => {
  return ideStore.isAdvanced
})
const isCodeEditor = computed(() => {
  return ideStore.codeEditor
})
const activeItem = computed(() => {
  return ideStore.activeItem
})
const isFullScreen = computed(() => {
  return ideStore.isFullScreen
})
const isBlocklyExpand = computed(() => {
  return ideStore.isblocklyExpand
})
const toogleBlocklyExpandIcon = computed(() => {
  return ideStore.isblocklyExpand ? 'fa-compress' : 'fa-expand'
})
const activeItemName = computed(() => {
  const activeItem = ideStore.activeItem
  if (activeItem?.parent === '/') {
    return '/' + activeItem?.name
  } else {
    return activeItem?.parent + '/' + activeItem?.name
  }
})
/**
 * Toggles the full screen
 */
const toogleBlocklyExpand = async () => {
  ideStore.setBlocklyExpand(!ideStore.isblocklyExpand)
  await new Promise((resolve) => setTimeout(resolve, 100))
  editorService.resizeCodeEditor()
  editorService.resizeOutputEditor()
  blocklyService.resizeBlockly()
}

onMounted(() => {
  const tempSize = Number(localStorage.getItem(IDECONSTANT.LOCALSTORAGE_FONT_SIZE_NAME))
  if (tempSize < 11 || tempSize > 25) {
    localStorage.setItem(IDECONSTANT.LOCALSTORAGE_FONT_SIZE_NAME, '12')
    editorService.setFontSize(12)
  }
  watch(isFullScreen, () => {
    editorService.resizeCodeEditor()
    editorService.resizeOutputEditor()
    blocklyService.resizeBlockly()
  })
})
</script>

<template>
  <!-- 36px = height of settings bar -->
  <div :class="['flex', 'h-full', 'flex-1', 'flex-col', 'items-stretch']">
    <div class="flex items-center justify-between">
      <p v-if="activeItem && isAdvanced" class="p-small">{{ activeItemName }}</p>
      <div class="flex h-fit w-full items-center justify-start gap-5">
        <!-- font select -->
        <button
          v-if="isBlockly"
          class="w-fit min-w-fit print:hidden lg:block"
          @click="toogleBlocklyExpand"
        >
          <FontAwesomeIcon :icon="toogleBlocklyExpandIcon" class="h-4 w-4" />
        </button>
      </div>
    </div>
    <div
      :class="[
        'flex',
        'flex-1',
        'flex-col',
        'gap-5',
        'lg:flex-row',
        'h-full',
        {
          'lg:flex-col': isBlockly && isBlocklyExpand
        }
      ]"
    >
      <!-- <div
        v-if="isAdvanced"
        id="ideProjectTree"
        class="hidden h-fit w-full min-w-full lg:h-full lg:min-w-fit"
      >
        <ProjectTree />
      </div> -->
      <div
        v-if="isBlockly"
        :id="IDECONSTANT.BLOCKLY_EDITOR"
        :class="[
          'z-0 h-[calc(100%-36px)] w-full text-black print:flex-1 print:border print:p-1 lg:min-w-fit',
          {
            'max-h-[290px] min-h-[290px]': !isFullScreen
          }
        ]"
      ></div>
      <div
        v-show="isCodeEditor"
        id="ideCodeEditor"
        :class="[
          'min-w-full',
          'w-full',
          'print:border',
          'print:p-1',
          'lg:min-w-fit',
          'lg:overflow-auto',
          {
            'flex-1': !isBlockly
          },
          {
            'h-full': isFullScreen
          }
        ]"
      >
        <div
          :id="IDECONSTANT.CODE_EDITOR"
          :class="[
            'print:h-full',
            'editor',
            'block',
            'min-w-full',
            'rounded-lg'
            // 'xl:overflow-scroll',
          ]"
        />
      </div>
    </div>
  </div>
</template>
