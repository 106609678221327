<script setup lang="ts">
import SixItemsPanel, { type ISixItems } from '@/components/shared/SixItemsPanel.vue'
import UseCaseBanner, { type IUseCaseBannerData } from '@/components/shared/UseCaseBanner.vue'
import type { IActionButton } from '@/components/shared/PricingCard.vue'
import { SUBSCRIPTION_PRODUCT } from '@/components/shared/subscription/enum/subscription.enum'
import Button_login_then_redirect from '@/components/shared/subscription/Button_login_then_redirect.vue'
const sectionProp: IUseCaseBannerData = {
  orangeTextAboveTitle: 'Compiler plugin Features',
  title: 'Embed code execution into your platform',
  description:
    'Stand out from the crowd with our innovative, gamified and imaginative compiler APIs and plugins.',
  textLeftImageRight: true,
  image: {
    src: '',
    alt: ''
  }
}

const featuresGridData: ISixItems[] = [
  {
    icon: 'fa-folder-closed',
    heading: 'Easy Integration',
    body: 'Seamlessly integrate the compiler APIs and plugins with any platform within minutes.'
  },
  {
    icon: 'fa-share-nodes',
    heading: 'Gamified and interactive experience',
    body: 'Help your students and developers learn faster and more effectively with gamified and interactive experiences.'
  },
  {
    icon: 'fa-comment',
    heading: 'Instant feedback',
    body: 'Users can experiment with code in real-time and see the results, fostering a deeper understanding of programming concepts.'
  },
  {
    icon: 'fa-message',
    heading: 'Multi-language support',
    body: 'JDoodle supports 76 programming languages, catering to diverse curricula and project needs.'
  },
  {
    icon: 'fa-arrow-up-right-from-square',
    heading: 'Scalable',
    body: 'Our Compiler APIs and Plugins offer unparalleled scalability, allowing you the flexibility you need to grow.'
  },
  {
    icon: 'fa-shield-halved',
    heading: 'Cost-effective and secure',
    body: 'Create memorable user experiences without breaking the budget with a secure code execution environment.'
  }
]

const button: IActionButton = {
  buttonText: 'Compiler Plugin',
  link: `/dashboard/plugin`,
  product: SUBSCRIPTION_PRODUCT.COMPILER_API
}
</script>

<template>
  <div class="py-8 md:py-14">
    <UseCaseBanner :sectionData="sectionProp" :removePadding="true" class="py-10">
      <Button_login_then_redirect :buttonData="button" class="" />
    </UseCaseBanner>
    <SixItemsPanel :sixItems="featuresGridData" />
  </div>
</template>
