import type ILink from '@/components/shared/interface/ILink'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useBreadcrumbStore = defineStore('breadcrumb', () => {
  const linkList = ref<ILink[]>([])
  /**
   * Set's the current page's breadcrumb links
   * @param newList list of the page
   */
  function setBreadcrumbLinks(newList: ILink[]) {
    linkList.value = newList
  }

  return {
    linkList,
    setBreadcrumbLinks
  }
})
