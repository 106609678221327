<script setup lang="ts">
import { ref, computed } from 'vue'
import PricingMain from '@/components/pricing/PricingMain.vue'
import PricingSlider from '@/components/shared/PricingSlider.vue'
import SubscriptionComponent from '@/components/shared/subscription/SubscriptionComponent.vue'
import { SUBSCRIPTION_TYPE_ENUM } from '@/components/shared/subscription/enum/subscription.enum'

enum PRICING_TAB_ENUM {
  PLUGIN = 'Plugin',
  API = 'API'
}

const currentTab = ref(PRICING_TAB_ENUM.PLUGIN)

const subscriptionType = computed(() => {
  if (currentTab.value == PRICING_TAB_ENUM.API) return SUBSCRIPTION_TYPE_ENUM.COMPILER_API
  else return SUBSCRIPTION_TYPE_ENUM.BUNDLE
})
</script>

<template>
  <SubscriptionComponent :subscriptionType="subscriptionType" />

  <div class="section-primary py-16">
    <div class="view-container mb-10">
      <div class="section-tertiary m-auto flex max-w-xl gap-2 rounded-lg px-2">
        <button
          class="btn text-primary"
          :class="currentTab == PRICING_TAB_ENUM.PLUGIN ? 'section-secondary dark:bg-gray-700' : ''"
          @click="currentTab = PRICING_TAB_ENUM.PLUGIN"
        >
          {{ PRICING_TAB_ENUM.PLUGIN }}
        </button>
        <button
          class="btn text-primary"
          :class="currentTab == PRICING_TAB_ENUM.API ? 'section-secondary dark:bg-gray-700' : ''"
          @click="currentTab = PRICING_TAB_ENUM.API"
        >
          {{ PRICING_TAB_ENUM.API }}
        </button>
      </div>
    </div>

    <PricingMain v-if="currentTab == PRICING_TAB_ENUM.PLUGIN" />
    <PricingSlider v-if="currentTab == PRICING_TAB_ENUM.API" />
  </div>
</template>
