<script setup lang="ts">
import CookieBanner from '@/components/footer/CookieBanner.vue'
import FooterMenu from '@/components/footer/FooterMenu.vue'
import FeedbackModel from '@/components/footer/FeedbackModel.vue'
import ForgotPasswordModel from '@/components/header/auth/ForgotPasswordModel.vue'
import LoginModel from '@/components/header/auth/LoginModel.vue'
import RegisterModel from '@/components/header/auth/RegisterModel.vue'
import ChangePasswordModel from '@/components/header/auth/profile/ChangePasswordModel.vue'
import ChangeUserNameModel from '@/components/header/auth/profile/ChangeUserNameModel.vue'
import ConfirmModel from '@/components/utils/ConfirmModel.vue'
import AlertModel from '@/components/utils/AlertModel.vue'
import NavigationMenu from '@/components/header/navigation/NavigationMenu.vue'
import CompilerModel from '@/components/home/__CompilerModel.vue'
import GlobalToast from '@/components/shared/GlobalToast.vue'
import { initAxios } from '@/services/api.service'
import authService from '@/services/auth.service'
import LoadingView from '@/views/LoadingView.vue'
import { useAuthStore } from '@/stores/auth.store'
import { ref, computed, onBeforeMount, onMounted } from 'vue'
import { RouterView } from 'vue-router'

const authStore = useAuthStore()

const initAndLogedIn = computed(() => authStore.isInitiated && authStore.isLogedIn)
const sticky = ref(true)
onBeforeMount(() => {
  initAxios()
  authService.initAuth()
})

onMounted(() => {
  // Show/hide sticky navbar on top when scrolling
  var oldScrollY = window.scrollY
  window.onscroll = function () {
    if (oldScrollY < window.scrollY) {
      sticky.value = false
    } else {
      sticky.value = true
    }
    oldScrollY = window.scrollY
  }
})
</script>

<template>
  <!-- Global notifications -->
  <GlobalToast />
  <!-- Loading view -->
  <LoadingView />
  <!-- Models when not logged in -->
  <div v-if="!initAndLogedIn">
    <LoginModel />
    <RegisterModel />
    <ForgotPasswordModel />
  </div>
  <!-- Models when logged in -->
  <div v-else>
    <ChangePasswordModel />
    <ChangeUserNameModel />
  </div>

  <!-- Other models -->
  <FeedbackModel />
  <CompilerModel />
  <ConfirmModel />
  <AlertModel />

  <div class="flex min-h-screen flex-col justify-between">
    <!-- <header
      class="sticky left-0 right-0 top-0 z-50 transform drop-shadow-md transition-all duration-300"
      :class="sticky ? 'sm:translate-y-0' : 'sm:-translate-y-full'"
    > -->
    <header class="sticky top-0 z-50">
      <NavigationMenu />
    </header>

    <body class="section-primary flex-1">
      <RouterView />
    </body>

    <CookieBanner />
    <FooterMenu />
  </div>
</template>
