<script setup lang="ts">
import { computed, defineAsyncComponent, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useTeachStore } from '@/stores/teach.store'
import type IAssignmentDetailed from '@/components/guru/interface/IAssignmentDetailed'
import {
  getAssignmentResponses,
  getAssignmentSubmissionsForEvaluation,
  getAssignmentToAttend,
  getAssignmentToPreview
} from '@/services/teach.service'
import type { ISubmission_Question } from '@/components/guru/interface/ISubmission_Question'
import router from '@/router'
import type {
  IAssignment_ForEvaluation,
  IEvaluationData,
  ISubmission_ForEvaluation
} from '@/components/guru/interface/IEvaluationData'
import { type IUserMini } from '@/components/guru/interface/IUserMini'
import {
  TEACH_ASSIGNMENT_STATUS,
  TEACH_STUDENT_ASSIGNMENT_STATUS
} from '@/components/guru/enums/teach.enum'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import DashboardCardEmptyMessageButton from '@/components/shared/dashboard/DashboardCardEmptyMessageButton.vue'
// import TeachBreadcrumb from '@/components/guru/shared/TeachBreadcrumb.vue'
import { TEACHMODALHS } from '@/utils/models'
import AssignmentSubmissionModal from '@/components/guru/modals/confirmationModals/AssignmentSubmissionModal.vue'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import AssignmentResubmissionModal from '@/components/guru/modals/confirmationModals/AssignmentResubmissionModal.vue'
import { getFormattedDate } from '@/utils/format'
const teachStore = useTeachStore()
const breadcrumbStore = useBreadcrumbStore()

const SUMMARY = defineAsyncComponent(
  () => import('@/components/guru/institute/assignments/components/AssignmentSummary.vue')
)

const QUESTION = defineAsyncComponent(
  () => import('@/components/guru/institute/assignments/components/AssignmentQuestionTab.vue')
)

const SUBMISSION = defineAsyncComponent(
  () => import('@/components/guru/institute/assignments/components/AssignmentSubmission.vue')
)
// Reactive objects
const currentTab = ref('Summary')

const questionIndex = ref(null as null | number)
const submissions = ref([] as ISubmission_Question[] | ISubmission_ForEvaluation[])
const student = ref(undefined as undefined | IUserMini)
const reachedSubmissionPage = ref(false)
const assignment = ref(undefined as undefined | IAssignmentDetailed | IAssignment_ForEvaluation)

const route = useRoute()
const mode = route.params.mode as string
const instituteCode = route.params.insCode as string
const assignmentId = Number.parseInt(route.params.assignmentId as string)

const currentTabComponent = computed(() => {
  if (currentTab.value == 'Summary') return SUMMARY

  if (currentTab.value == 'Question') return QUESTION

  if (currentTab.value == 'Submission') return SUBMISSION

  return SUMMARY
})

const breadcrumbLinks = computed(() => {
  if (isPreview.value) {
    return [
      {
        href: '/',
        title: 'Home'
      },
      {
        href: '/dashboard',
        title: 'Dashboard'
      },
      {
        href: '/dashboard/institution',
        title: 'Virtual Institutions'
      },
      {
        href: `/dashboard/institution/${instituteCode}`,
        title: `${teachStore.currentAssociation?.name}`
      },
      {
        href: `/dashboard/institution/${instituteCode}/assignments`,
        title: `Assignments`
      },
      {
        href: `/dashboard/institution/${instituteCode}/assignment/${assignmentId}`,
        title: `${assignment.value?.title}`
      },
      {
        href: `/dashboard/institution/${instituteCode}/assignment/${assignmentId}/preview`,
        title: `Preview Assignment`
      }
    ]
  } else if (isEvaluate.value) {
    return [
      {
        href: '/',
        title: 'Home'
      },
      {
        href: '/dashboard',
        title: 'Dashboard'
      },
      {
        href: '/dashboard/institution',
        title: 'Virtual Institutions'
      },
      {
        href: `/dashboard/institution/${instituteCode}`,
        title: `${teachStore.currentAssociation?.name}`
      },
      {
        href: `/dashboard/institution/${instituteCode}/assignments`,
        title: `Assignments`
      },
      {
        href: `/dashboard/institution/${instituteCode}/assignment/${assignmentId}`,
        title: `${assignment.value?.title}`
      },
      {
        href: `/dashboard/institution/${instituteCode}/assignment/${assignmentId}/evaluate`,
        title: `Evaluate Assignment`
      }
    ]
  } else {
    return [
      {
        href: '/',
        title: 'Home'
      },
      {
        href: '/dashboard',
        title: 'Dashboard'
      },
      {
        href: '/dashboard/institution',
        title: 'Virtual Institutions'
      },
      {
        href: `/dashboard/institution/${instituteCode}`,
        title: `${teachStore.currentAssociation?.name}`
      },
      {
        href: `/dashboard/institution/${instituteCode}/assignment/${assignmentId}/attend`,
        title: `Attend Assignment`
      }
    ]
  }
})

onMounted(async () => {
  currentTab.value = 'Summary'
  await getAssignment()

  if (mode === 'attend') {
    await getSavedAnswers()
  }
  teachStore.getAndSetCurrentAssociation(instituteCode)
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
})

//Refresh submitted answer tracking when page/question changes
watch(
  () => [questionIndex.value, currentTab.value],
  async () => {
    if (shouldRetrieveSavedAnswersOnRefresh.value) {
      // Small delay needed because answer needs to be saved to database before this tracking/validation can work.
      setTimeout(async () => {
        await getSavedAnswers()
      }, 750)
    }
  }
)

const shouldRetrieveSavedAnswersOnRefresh = computed(() => {
  return (
    mode === 'attend' &&
    (assignment.value?.submissionStatus == TEACH_STUDENT_ASSIGNMENT_STATUS.YET_TO_START ||
      assignment.value?.submissionStatus == TEACH_STUDENT_ASSIGNMENT_STATUS.IN_PROGRESS)
  )
})

const isPreview = computed(() => {
  return (
    mode === 'preview' &&
    ((teachStore.isAccountOwner || teachStore.isInstituteAdmin || teachStore.isTeacher) as boolean)
  )
})

const isAttend = computed(() => {
  return mode === 'attend' && (teachStore.isStudent as boolean)
})

const isEvaluate = computed(() => {
  return (
    mode === 'evaluate' &&
    ((teachStore.isAccountOwner || teachStore.isInstituteAdmin || teachStore.isTeacher) as boolean)
  )
})

const canOpen = computed(() => {
  return (
    teachStore.guruInitialised &&
    ((isPreview.value || isAttend.value || isEvaluate.value) as boolean)
  )
})

watch(
  () => [isAttend.value, isEvaluate.value, isPreview.value],
  async () => {
    await getAssignment()
  }
)

watch(
  () => [teachStore.currentAssociation, breadcrumbLinks.value],
  () => {
    teachStore.getAndSetCurrentAssociation(instituteCode)
    breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
  }
)

/**
 * Retrieve current assignment
 */
const getAssignment = async () => {
  let res: IAssignmentDetailed | IEvaluationData | undefined = undefined

  if (isPreview.value) {
    res = await getAssignmentToPreview(instituteCode, assignmentId, undefined)
  } else if (isAttend.value) {
    res = await getAssignmentToAttend(instituteCode, assignmentId, undefined)
  } else if (isEvaluate.value) {
    //Get evaluate value

    if (useTeachStore().studentUnderEvaluation) {
      res = await getAssignmentSubmissionsForEvaluation(
        instituteCode,
        assignmentId,
        teachStore.studentUnderEvaluation?.email
      )
    } else {
      router.push(`/dashboard/institution/${instituteCode}/assignment/${assignmentId}`)
      return
    }
  } else {
    // router.push('/online-assessment-teaching');
    return
  }

  //Store default language
  teachStore.setAssignmentLanguage(res)

  if (isEvaluate.value) {
    assignment.value = (res as IEvaluationData).assignment
    ;(assignment.value as IAssignment_ForEvaluation).submissionStatus =
      res.status as TEACH_STUDENT_ASSIGNMENT_STATUS
    submissions.value = (res as IEvaluationData).submission
    student.value = (res as IEvaluationData).student
  } else {
    assignment.value = res as IAssignmentDetailed

    if (!isEvaluate.value) {
      reachedSubmissionPage.value = true
    }
  }
}

// Function for navigation
/**
 *
 * @param index question index
 */
async function loadQuestion(index: number) {
  questionIndex.value = index
  // currentTabComponent.value = QUESTION'
  currentTab.value = 'Question'
}

/**
 *
 */
async function loadQuestionSummaryPage() {
  if (isEvaluate.value) {
    await getAssignment()
  }
  // currentTabComponent.value = SUMMARY
  currentTab.value = 'Summary'
}
/**
 *
 * @param submissionsData submission page
 */
function loadQuestionSubmissionPage(submissionsData?: ISubmission_Question[]) {
  if (isAttend.value) {
    if (submissionsData) {
      submissions.value = submissionsData
    }
  }
  reachedSubmissionPage.value = true
  currentTab.value = 'Submission'
  // currentTabComponent.value = SUBMISSION
}

/**
 *
 * @param questionIndex any
 * @param responseObject any
 */
function updatePreviewSubmission(questionIndex: number, responseObject: ISubmission_Question) {
  ;(submissions.value as ISubmission_Question[]).splice(questionIndex, 1, responseObject)

  if (assignment.value) {
    if (submissions.value.length != assignment.value?.questions.length) {
      for (let i = 1; i < assignment.value?.questions.length; i++) {
        //Find if question exists
        const sub = submissions.value.find(
          (submission: ISubmission_Question) => submission.questionId == i
        )

        if (!sub) {
          let unansweredQuestion: ISubmission_Question = {
            questionId: i,
            answer: '',
            isMarkedForReview: false
          }

          ;(submissions.value as ISubmission_Question[]).splice(i, 1, unansweredQuestion)
        }
      }
    }
  }
}

/**
 * Gets the user's saved answers
 */
async function getSavedAnswers() {
  const submissionRes = await getAssignmentResponses(instituteCode, assignmentId)
  submissions.value = submissionRes
}

const questionsAnswered = computed(() => {
  let questionsAnswered = 0
  submissions.value.forEach((submission: ISubmission_Question) => {
    if (submission.answer) questionsAnswered++
  })

  return questionsAnswered
})

const isResultAnnounced = computed(() => {
  return assignment.value?.submissionStatus === TEACH_STUDENT_ASSIGNMENT_STATUS.RESULT_ANNOUNCED
})

const submitBtnActionText = computed(() => {
  if (isPreview.value) {
    return 'Cannot Submit in Preview'
  }

  if (
    !(
      assignment.value?.submissionStatus == TEACH_STUDENT_ASSIGNMENT_STATUS.YET_TO_START ||
      assignment.value?.submissionStatus == TEACH_STUDENT_ASSIGNMENT_STATUS.IN_PROGRESS
    )
  ) {
    return 'Assignment Submitted'
  } else {
    return 'Submit Assignment'
  }
})

const isNotSubmittedBeforeCompletion = computed(() => {
  return (
    (assignment.value?.submissionStatus === TEACH_STUDENT_ASSIGNMENT_STATUS.YET_TO_START ||
      assignment.value?.submissionStatus === TEACH_STUDENT_ASSIGNMENT_STATUS.IN_PROGRESS) &&
    assignment.value?.status === TEACH_ASSIGNMENT_STATUS.COMPLETED
  )
})

const isSubmitBtnDisabled = computed(() => {
  //Not attend OR assignment not in progress
  if (
    !(
      isAttend.value &&
      (assignment.value?.submissionStatus == TEACH_STUDENT_ASSIGNMENT_STATUS.YET_TO_START ||
        assignment.value?.submissionStatus == TEACH_STUDENT_ASSIGNMENT_STATUS.IN_PROGRESS)
    )
  ) {
    return true
  } else if (isNotSubmittedBeforeCompletion.value) {
    //Not submitted before assignment deadline
    return true
  } else if (!submissionsAllAnswered.value) {
    return true
  }

  return false
})

const isNotSubmittedAndOnHold = computed(() => {
  return (
    (assignment.value?.submissionStatus === TEACH_STUDENT_ASSIGNMENT_STATUS.YET_TO_START ||
      assignment.value?.submissionStatus === TEACH_STUDENT_ASSIGNMENT_STATUS.IN_PROGRESS) &&
    assignment.value?.status === TEACH_ASSIGNMENT_STATUS.ON_HOLD
  )
})

const submissionsAllAnswered = computed(() => {
  if (assignment.value) {
    if (submissions.value.length < assignment.value.questions.length) {
      return false
    }
  }

  let allAnswered = true
  submissions.value.forEach((sub: ISubmission_Question) => {
    if (!sub.answer) {
      allAnswered = false
    }
  })

  return allAnswered
})
</script>

<template>
  <AssignmentResubmissionModal
    :instituteCode="instituteCode"
    :assignmentId="assignmentId"
    :reload="getAssignment"
  />
  <AssignmentSubmissionModal :instituteCode="instituteCode" :assignmentId="assignmentId" />
  <div class="grid-rows-8 grid h-full grid-cols-10 gap-4" v-if="canOpen">
    <DashboardCard class="col-span-6 row-span-1">
      <h1 class="text-primary mb-1 flex items-center gap-3 text-xl">
        Assignment: <span class="font-bold">{{ assignment?.title }}</span>
        <span
          v-if="isPreview"
          class="inline-block rounded-full bg-blue-500 px-2 py-1 text-center text-sm"
          >Preview</span
        >
      </h1>

      <p class="text-md mb-5">Virtual Institution: {{ teachStore.currentAssociation?.name }}</p>
      <div class="flex justify-between text-xs">
        <span class="inline-block">Assignment Status: {{ assignment?.status }}</span>
        <span
          class="inline-block"
          v-if="!isPreview && assignment?.status !== TEACH_ASSIGNMENT_STATUS.DRAFT"
          >Submission Status: {{ assignment?.submissionStatus }}</span
        >
      </div>
    </DashboardCard>
    <DashboardCard class="col-span-4 row-span-1">
      <!-- Attend/Preview view -->
      <div class="flex h-full flex-col items-center justify-center" v-if="!isEvaluate">
        <!-- Show how many questions answered (Attending In-progress assignment only)-->
        <p
          class="mb-2"
          v-if="assignment?.submissionStatus !== TEACH_STUDENT_ASSIGNMENT_STATUS.RESULT_ANNOUNCED"
        >
          Questions answered: {{ questionsAnswered }}/{{ assignment?.questions.length }}
        </p>

        <!-- Show total marks (ResultType 0 or 1) -->
        <p
          class="mb-2"
          v-else-if="
            assignment?.submissionStatus === TEACH_STUDENT_ASSIGNMENT_STATUS.RESULT_ANNOUNCED &&
            assignment?.resultType !== 2
          "
        >
          Total Mark: {{ (assignment as IAssignmentDetailed).totalMark }}
        </p>

        <!-- Show Comments only (ResultType 2) -->
        <p
          class="mb-2"
          v-else-if="
            assignment?.submissionStatus === TEACH_STUDENT_ASSIGNMENT_STATUS.RESULT_ANNOUNCED &&
            assignment?.resultType === 2
          "
        >
          Comments Released
        </p>

        <button
          class="btn-primary w-fit rounded-md p-2 text-sm"
          :data-hs-overlay="`#${TEACHMODALHS.SUBMIT_ASSIGNMENT_CONFIRMATION}`"
          v-if="!isResultAnnounced"
          :disabled="isSubmitBtnDisabled"
        >
          {{ submitBtnActionText }}
        </button>
        <button
          :data-hs-overlay="`#${TEACHMODALHS.REQUEST_REEVALUATION}`"
          class="btn-dashboard-small rounded-md px-5 py-2 text-sm"
          v-if="isResultAnnounced"
        >
          Request For Re-evaluation
        </button>
        <p class="text-tertiary mt-2 text-xs" v-if="!submissionsAllAnswered">
          All questions need to be answered prior to submission.
        </p>
      </div>

      <!-- Evaluate view (Student details) -->
      <div v-if="isEvaluate">
        <h2 class="text-primary mb-1 text-lg font-medium">Student Details</h2>
        <div class="text-secondary text-sm">
          <p>
            Name:
            {{
              `${teachStore.studentUnderEvaluation?.firstName} ${teachStore.studentUnderEvaluation?.lastName}`
            }}
          </p>
          <p>Email: {{ teachStore.studentUnderEvaluation?.email }}</p>
          <p>
            Submitted On: {{ getFormattedDate(teachStore.studentUnderEvaluation?.submittedOn) }}
          </p>
        </div>
      </div>
    </DashboardCard>
    <DashboardCard class="row-span-7 col-span-3" title="Questions">
      <div class="flex flex-col gap-5" v-if="assignment && assignment?.questions.length > 0">
        <!-- Jumping across questions introduces an inconsistent bug on question save feature. Thus, unclickable for now. -->
        <!-- @click="loadQuestion(index)" -->
        <!-- role="button" -->
        <div
          v-for="(question, index) in assignment?.questions"
          :key="index"
          class="text-sm"
          :class="questionIndex == index ? 'text-brand' : 'text-primary'"
        >
          {{ `${index + 1}. ${question.question}` }}
        </div>
      </div>
      <div v-else>
        <DashboardCardEmptyMessageButton :emptyData="{ message: 'No questions added yet' }" />
      </div>
    </DashboardCard>

    <!-- Each component will have it's down dashboarcard wrapper due to layout -->
    <!--     <DashboardCard class="row-span-7 col-span-7"> -->
    <component
      v-bind:is="currentTabComponent"
      :mode="mode"
      :instituteCode="instituteCode"
      :assignmentId="assignmentId"
      :assignment="assignment"
      :questionIndex="questionIndex"
      :isPreview="isPreview"
      :isAttend="isAttend"
      :isEvaluate="isEvaluate"
      :reachedSubmissionPage="reachedSubmissionPage"
      :loadQuestion="loadQuestion"
      :loadQuestionSummaryPage="loadQuestionSummaryPage"
      :loadQuestionSubmissionPage="loadQuestionSubmissionPage"
      :submissions="submissions"
      :updatePreviewSubmission="updatePreviewSubmission"
      :reloadGetAssignment="getAssignment"
      :isNotSubmittedBeforeCompletion="isNotSubmittedBeforeCompletion"
      :isNotSubmittedAndOnHold="isNotSubmittedAndOnHold"
      :isResultAnnounced="isResultAnnounced"
    />
  </div>
</template>
