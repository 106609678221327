<script lang="ts" setup>
import { computed } from 'vue'

import { AUTHMODELHS } from '@/utils/models'
import { useAuthStore } from '@/stores/auth.store'

import circleTwoImg from '@/assets/images/educationInstitution/banner/circle2-yellow.svg'
import dotsThreeImg from '@/assets/images/educationInstitution/banner/dots3-blue.svg'
import pluginsDemoImg from '@/assets/images/educationInstitution/banner/Plugins-demo-1.png'

const authStore = useAuthStore()

const isLogedIn = computed(() => authStore.isLogedIn)
</script>

<template>
  <section class="relative mx-10 overflow-hidden bg-center">
    <div class="py-20 md:py-28">
      <div class="w-full px-4">
        <div
          class="mx-2 flex w-full flex-col items-center justify-center gap-16 text-center lg:flex-row lg:items-center lg:gap-0"
        >
          <div class="mb-16 w-full px-4 lg:mb-0 lg:w-6/12">
            <h1 class="heading-large text-left">Plugins to make coding education efficient</h1>
            <p class="mb-8 text-left text-lg font-medium text-gray-500 md:text-xl">
              JDoodle is a great choice for Universities, Colleges &amp; Schools looking to
              seamlessly integrate a powerful, cost-effective &amp; easy-to-use online compiler into
              their LMS.
            </p>
            <div class="flex flex-wrap">
              <div class="w-full py-1 md:mr-4 md:w-auto md:py-0">
                <a
                  class="p-normal inline-block w-full rounded-md border border-background-jdoodle bg-background-jdoodle px-5 py-3 text-center font-medium leading-4 text-green-50 shadow-sm hover:bg-background-jdoodle focus:ring-2 focus:ring-text-tertiary focus:ring-opacity-50 md:text-lg"
                  href="https://app.monstercampaigns.com/c/eelbkirqhtobo1lclzwx/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Request a Demo
                </a>
              </div>
              <div class="w-full py-1 md:mr-4 md:w-auto md:py-0">
                <button
                  v-if="!isLogedIn"
                  class="link-primary p-normal inline-block w-full rounded-md border border-gray-300 bg-background-primary px-5 py-3 text-center font-medium leading-4 text-text-tertiary shadow-sm focus:ring-2 focus:ring-gray-200 focus:ring-opacity-50 md:text-lg"
                  :data-hs-overlay="`#${AUTHMODELHS.REGISTER}`"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
          <div class="ml-10 mr-10 w-full px-4 lg:w-6/12">
            <div class="relative mx-auto max-w-max md:mr-0">
              <img
                class="absolute left-[-3.5rem] top-[-3rem] z-10 w-28"
                :src="circleTwoImg"
                alt="circleTwo"
              />
              <img
                class="absolute bottom-[-2rem] right-[-1.75rem] z-10 w-28 md:w-auto"
                :src="dotsThreeImg"
                alt="dotsThree"
              />
              <img
                class="img-shadow relative rounded-3xl"
                :src="pluginsDemoImg"
                alt="pluginsDemo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
