<script setup lang="ts">
import CaseStudyLogoAndIntro from '@/components/shared/casestudy/CaseStudyLogoAndIntro.vue'
import itextLogo from '@/assets/images/casestudy/itext/iText2.png'

const sectionData = {
  image: {
    src: itextLogo,
    alt: '',
    copyright: '© Prathan Chorruangsak from prathan chorruangsak via Canva.com'
  },
  heading: 'iText',
  desc: "iText is a leading provider of innovative PDF libraries and a comprehensive software development platform in Java and .NET. Their mission is to empower businesses and developers to seamlessly integrate PDF functionalities within their applications, processes, and products. iText's cutting-edge technology serves as a robust foundation for businesses seeking to harness the full spectrum of PDF capabilities."
}
</script>

<template>
  <CaseStudyLogoAndIntro :sectionData="sectionData" />
</template>
