declare global {
  interface ace {
    Editor: any
  }
}

import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useDashboardIdeStore = defineStore('dashboardide', () => {
  const codeEditor = ref<ace['Editor'] | null>(null)
  const script = ref<string>('')
  const isBasicEditor = ref<boolean>(false)
  const aceCode = ref<string>('text')

  const isWindowAce = computed(() => {
    return window['ace'] || null
  })
  /**
   * set the script
   * @param value - The script
   */
  const setScript = (value: string) => {
    script.value = value
  }
  /**
   * set the isBasicEditor
   * @param value - The isBasicEditor
   */
  const setIsBasicEditor = (value: boolean) => {
    isBasicEditor.value = value
  }
  /**
   * set the aceCode
   * @param value - The aceCode
   */
  const setAceCode = (value: string) => {
    aceCode.value = value
  }
  return {
    codeEditor,
    isWindowAce,
    script,
    setScript,
    isBasicEditor,
    setIsBasicEditor,
    aceCode,
    setAceCode
  }
})
