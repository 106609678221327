<script setup lang="ts">
import BaseInput from '@/components/forms/BaseInput.vue'
import BaseTextbox from '@/components/forms/BaseTextbox.vue'
import { ref, watch, type PropType, computed, onMounted } from 'vue'
import { FORM_INPUT_TYPE } from '@/components/forms/form.enum'
import type { ITestCase } from '@/components/guru/interface/ITestCase'

const props = defineProps({
  index: {
    type: Number,
    required: true
  },
  testCaseObject: {
    type: Object as PropType<ITestCase | any>,
    require: true
  },
  errors: {
    type: Object as PropType<any>,
    require: true
  },
  remove: {
    type: Function,
    required: true
  },
  isOptionLevelMarking: {
    type: Boolean,
    require: true
  },
  enableNegativeMark: {
    type: Boolean,
    requried: false
  },
  hasErrorsState: {
    type: Function,
    required: true
  }
})

const tcErrors = ref({} as any)

watch(
  () => props.errors,
  (newErrs) => {
    tcErrors.value = newErrs
  }
)

//Assets and static data
const testCaseNameInput = {
  label: 'Test Case Name'
}

const argumentsInput = {
  label: 'Arguments'
}

const standardInputInput = {
  label: 'Standard Input'
}

const expectedOutputInput = {
  label: 'Expected Output'
}

const maximumCpuTimeAllowedInput = {
  label: 'Maximum CPU Time Permitted (in MilliSeconds)'
}

const maximumMemoryAllowedInput = {
  label: 'Maximum Memory Permitted (In kilobytes)'
}

const markForCorrectAnswerInput = {
  label: 'Mark/Score'
}

const negativeMarkInput = {
  label: 'Negative Mark/Score'
}

const isMinimized = ref(false)
/**
 *
 */
const minimizeSection = () => {
  isMinimized.value = true
}

/**
 *
 */
const maximizeSecrtion = () => {
  isMinimized.value = false
}

//Errors
onMounted(() => {
  props.hasErrorsState(testCaseHasError.value)
})

const errors = ref(props.errors)
watch(
  () => props.errors,
  (newErrors) => {
    errors.value = newErrors
  }
)
//Object
const testCaseObject = ref(props.testCaseObject as unknown as ITestCase)
watch(
  () => props.testCaseObject,
  (newObject) => {
    testCaseObject.value = newObject as ITestCase
  }
)

//Error checking
const testCaseHasError = computed(() => {
  if (props.isOptionLevelMarking) {
    if (props.enableNegativeMark) {
      return (
        !testCaseObject.value.negativeMark ||
        testCaseObject.value.negativeMark >= 0 ||
        !testCaseObject.value.mark
      )
    }

    return !testCaseObject.value.mark
  }

  return false
})
watch(
  () => testCaseHasError.value,
  (hasErrors) => {
    props.hasErrorsState(hasErrors)
  }
)
</script>

<template>
  <div class="flex flex-col">
    <BaseInput
      name="question"
      v-model="(testCaseObject as ITestCase).question"
      :label="testCaseNameInput.label"
      :error="
        tcErrors[`testCases[${props.index}].question`]
          ? tcErrors[`testCases[${props.index}].question`]
          : ''
      "
      :inputType="FORM_INPUT_TYPE.STRING"
      :isLightGray="false"
    />

    <div v-if="!isMinimized">
      <BaseInput
        v-model="(testCaseObject as ITestCase).arguments"
        :label="argumentsInput.label"
        :inputType="FORM_INPUT_TYPE.STRING"
        :isLightGray="false"
      />

      <BaseTextbox
        :label="standardInputInput.label"
        v-model="(testCaseObject as ITestCase).input"
        :isLightGray="false"
      />

      <BaseTextbox
        :label="expectedOutputInput.label"
        v-model="(testCaseObject as ITestCase).expectedAnswer"
        :error="
          tcErrors[`testCases[${props.index}].expectedAnswer`]
            ? tcErrors[`testCases[${props.index}].expectedAnswer`]
            : ''
        "
        :isLightGray="false"
      />

      <BaseInput
        v-model="(testCaseObject as ITestCase).maximumCpuTimeAllowed"
        :label="maximumCpuTimeAllowedInput.label"
        :inputType="FORM_INPUT_TYPE.NUMBER"
        :isLightGray="false"
      />

      <BaseInput
        v-model="(testCaseObject as ITestCase).maximumMemoryAllowed"
        :label="maximumMemoryAllowedInput.label"
        :inputType="FORM_INPUT_TYPE.NUMBER"
        :isLightGray="false"
      />

      <BaseInput
        v-if="props.isOptionLevelMarking"
        v-model="(testCaseObject as ITestCase).mark"
        :label="markForCorrectAnswerInput.label"
        :inputType="FORM_INPUT_TYPE.NUMBER"
        :isLightGray="false"
      />
      <p class="error text-xs" v-if="props.isOptionLevelMarking && !testCaseObject.mark">
        Mark/Score is required
      </p>

      <BaseInput
        v-if="props.enableNegativeMark && props.isOptionLevelMarking"
        v-model="(testCaseObject as ITestCase).negativeMark"
        :label="negativeMarkInput.label"
        :inputType="FORM_INPUT_TYPE.NUMBER"
        :maxInt="0"
        :isLightGray="false"
      />
      <p
        class="error text-xs"
        v-if="
          props.enableNegativeMark && props.isOptionLevelMarking && !testCaseObject.negativeMark
        "
      >
        Negative Mark/Score is required
      </p>
    </div>
    <div v-if="isMinimized" class="mt-3 text-xs">Expand to reveal...</div>
    <div class="mt-3 flex justify-end gap-3">
      <button class="" @click="minimizeSection" v-if="!isMinimized">
        <FontAwesomeIcon icon="fa-minimize" class="h-5 w-5" aria-hidden="true" />
      </button>

      <button class="" @click="maximizeSecrtion" v-if="isMinimized">
        <FontAwesomeIcon icon="fa-maximize" class="h-5 w-5" aria-hidden="true" />
      </button>

      <button class="" @click="props.remove(props.index)">
        <FontAwesomeIcon icon="fa-trash" class="error h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  </div>
</template>
