<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import configureService from '@/services/ide/plugin/configure.service'

import { usePluginStore } from '@/stores/plugin.store'

const pluginStore = usePluginStore()

const mouseXY = ref<any>({ x: null, y: null })
const selectedItemID = ref<any>(null)

const availbleFunctionals = computed(() => {
  return pluginStore.availableFunctionals
})
const isAvailbleFunctionals = computed(() => {
  return pluginStore.availableFunctionals.length > 0
})
const mouseInGrid = computed(() => {
  return pluginStore.isMouseInGrid
})
/**
 * When the user drags a component, check if the mouse is in the grid
 * @param e The drag event
 */
const drag = (e: any) => {
  pluginStore.setDragging(true)
  if (e.target?.id) {
    selectedItemID.value = e.target.id
  } else {
    selectedItemID.value = null
  }
  let parentRect = document.getElementById('gridContainer')?.getBoundingClientRect()
  pluginStore.setMouseInGrid(false)
  if (
    parentRect &&
    mouseXY.value.x > parentRect.left &&
    mouseXY.value.x < parentRect.right &&
    mouseXY.value.y > parentRect.top &&
    mouseXY.value.y < parentRect.bottom
  ) {
    pluginStore.setMouseInGrid(true)
    pluginStore.setDragging(false)
  } else {
    pluginStore.setDragging(true)
  }
}
/**
 * once the drag ends, check if the mouse is in the grid and if so, add the functional to the grid
 * @param e The drag event
 */
const dragend = (e: any) => {
  pluginStore.setDragging(false)
  if (mouseInGrid.value) {
    let functional = pluginStore.availableFunctionals.find((item) => item.name === e.target?.id)
    if (functional) {
      configureService.addFunctionalToSelected(functional)
    }
  }
  selectedItemID.value = null
  pluginStore.setMouseInGrid(false)
}
/**
 * Checks if the functional is selected
 * @param functional The functional to check
 * @returns True if the functional is selected
 */
const isItemSelected = (functional: any) => {
  if (!selectedItemID.value) return false
  return selectedItemID.value === functional.name
}
onMounted(() => {
  configureService.updateAvailableFunctionals()
  document.addEventListener(
    'dragover',
    function (e) {
      mouseXY.value.x = e.clientX
      mouseXY.value.y = e.clientY
    },
    false
  )
})
</script>

<template>
  <p v-if="!isAvailbleFunctionals" class="p-small text-secondary">
    No functional features available
  </p>
  <div v-else class="flex flex-col gap-5">
    <p class="p-small text-secondary">
      Drag and drop host to plugin functional features to the plugin view
    </p>
    <ul class="flex w-full flex-wrap justify-stretch gap-2">
      <li
        v-for="functional in availbleFunctionals"
        :key="functional.name"
        :id="functional.name"
        @drag="drag"
        @dragend="dragend"
        draggable="true"
        unselectable="on"
        :class="[
          'section-secondary flex w-fit flex-col items-center justify-center gap-2 rounded-lg border p-2 hover:border-btn-primary',
          {
            'opacity-50': isItemSelected(functional)
          }
        ]"
      >
        <h4 class="p-xsmall text-primary">{{ functional.name }}</h4>
      </li>
    </ul>
  </div>
</template>
