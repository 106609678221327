<template>
  <div class="mx-5 py-10 lg:mx-32" id="scrollspy-1">
    <div class="grid grid-cols-5 gap-4">
      <div
        class="col-span-5 col-start-1 border-b border-gray-200 dark:border-gray-700 md:col-span-1 md:border-b-0 md:border-r"
      >
        <nav
          class="sticky top-0 mb-4 space-y-2 md:flex md:flex-col"
          aria-label="Tabs"
          role="tablist"
          data-hs-scrollspy="#scrollspy-1"
        >
          <button
            v-for="(tab, index) in tabs"
            :key="index"
            type="button"
            class="text-md inline-flex items-center gap-2 rounded-l border-r-[3px] border-transparent px-4 py-2 text-left text-gray-500"
            :class="
              tab.name === currentTab.name
                ? 'bg-blue-600 text-white hover:bg-blue-700 hover:text-white'
                : 'text-gray-500 hover:text-blue-700 dark:hover:text-gray-300'
            "
            role="tab"
            @click="tabChange(tab)"
          >
            {{ tab.name }}
          </button>
        </nav>
      </div>

      <div class="col-start-1 col-end-6 w-full md:col-start-2 lg:col-end-6">
        <keep-alive>
          <div>
            <component role="tabpanel" :is="currentTab.component" />
          </div>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, markRaw, defineAsyncComponent } from 'vue'

const tabs = [
  {
    name: 'Comments',
    component: markRaw(defineAsyncComponent(() => import('@/components/admin/CommentComp.vue')))
  },
  {
    name: 'Counts',
    component: markRaw(defineAsyncComponent(() => import('@/components/admin/CountComp.vue')))
  },
  {
    name: 'Download Stats',
    component: markRaw(defineAsyncComponent(() => import('@/components/admin/DownloadComp.vue')))
  },
  {
    name: 'Subscriptions',
    component: markRaw(
      defineAsyncComponent(() => import('@/components/admin/SubscriptionComp.vue'))
    )
  },
  {
    name: 'Delete Account',
    component: markRaw(defineAsyncComponent(() => import('@/components/admin/DeleteAccount.vue')))
  },
  {
    name: 'Delete Institute',
    component: markRaw(defineAsyncComponent(() => import('@/components/admin/DeleteInstitute.vue')))
  },
  {
    name: 'Disable API Plan',
    component: markRaw(defineAsyncComponent(() => import('@/components/admin/DisablePlan.vue')))
  },
  {
    name: 'Insert Notifications',
    component: markRaw(
      defineAsyncComponent(() => import('@/components/admin/InsertNotification.vue'))
    )
  },
  {
    name: 'Impersonate',
    component: markRaw(defineAsyncComponent(() => import('@/components/admin/ImpersonateComp.vue')))
  },
  {
    name: 'Toggle Unlimited Plan',
    component: markRaw(defineAsyncComponent(() => import('@/components/admin/ToggleUnlimited.vue')))
  },
  {
    name: 'Active Paying Customers',
    component: markRaw(defineAsyncComponent(() => import('@/components/admin/ActiveCustomer.vue')))
  },
  {
    name: 'Create Stripe Account',
    component: markRaw(defineAsyncComponent(() => import('@/components/admin/CreateStripe.vue')))
  },
  {
    name: 'Update API Plan', // (only if the customer paid inital pay via Stripe)
    component: markRaw(defineAsyncComponent(() => import('@/components/admin/UpdatePlan.vue')))
  }
]

const currentTab = ref(tabs[0])

/**
 *
 * @param tab any
 */
function tabChange(tab: any) {
  currentTab.value = tab
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
</script>
