<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  id: {
    type: String,
    default: null
  },
  label: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  rows: {
    type: Number,
    required: false
  },
  error: {
    type: String,
    default: ''
  },
  modelValue: {
    required: true
  },
  disabled: {
    type: Boolean,
    require: false
  },
  isContactus: {
    type: Boolean,
    require: false,
    default: false
  },
  isLightGray: {
    type: Boolean,
    required: false,
    default: true
  }
})

const inputClasses = computed(() => {
  if (props.isContactus) {
    return 'section-combined'
  } else if (props.isLightGray) {
    return `bg-neutral-100 dark:bg-[#17191F]`
  } else {
    return `section-primary `
  }
})
</script>

<template>
  <div class="my-2">
    <div class="flex items-center justify-between">
      <label :for="props.name || `${props.label + props.placeholder}`" class="p-small my-2 block">{{
        props.label
      }}</label>
      <slot />
    </div>
    <div class="relative">
      <textarea
        v-bind="$attrs"
        :id="props.id || props.name || `${props.label + props.placeholder}`"
        class="p-small block w-full rounded-md px-4 py-2"
        :class="[{ 'border-red-500': props.error }, inputClasses]"
        :placeholder="props.placeholder"
        :value="modelValue as string"
        :disabled="props.disabled"
        :rows="props.rows"
        @change="(event: Event) => $emit('update:modelValue', (event.target as HTMLInputElement).value)"
      />
      <div
        :class="[
          'pointer-events-none',
          'absolute',
          'inset-y-0',
          'right-0',
          'top-0',
          'flex',
          'p-3',
          { hidden: !props.error }
        ]"
      >
        <FontAwesomeIcon icon="fa-circle-xmark" class="error h-5 w-5" aria-hidden="true" />
      </div>
    </div>
    <p :class="['p-xsmall ', 'error', { hidden: !props.error }]">
      {{ props.error }}
    </p>
  </div>
</template>
