import { getAssignments, getAssignmentsOfStudent } from '@/services/teach.service'
import { useTeachStore } from '@/stores/teach.store'

/**
 * @param insCode the instituteCode of assignments
 * @returns found assignments
 */
const fetchAssignmentsOfStudent = async (insCode?: string) => {
  let data: any[] = []
  if (!insCode) {
    data = await getAssignmentsOfStudent('')
  } else {
    data = await getAssignmentsOfStudent(insCode)
  }

  return data
}

/**
 * @param insCode the instituteCode of assignments
 * @param showAllAsStudent show all as student?
 * @returns found assignments
 */
const fetchAssignmentsOfStudentOrStaff = async (insCode?: string, showAllAsStudent?: boolean) => {
  const teachStore = useTeachStore()
  let data: any[] = []

  if (teachStore.isStudent || showAllAsStudent) {
    if (showAllAsStudent) {
      data = await getAssignmentsOfStudent('')
    } else {
      if (insCode) {
        data = await getAssignmentsOfStudent(insCode!)
      }
    }
  } else {
    //For staff
    if (insCode) {
      data = await getAssignments(insCode!)
    }
  }

  return data
}

export { fetchAssignmentsOfStudent, fetchAssignmentsOfStudentOrStaff }
