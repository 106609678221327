<template>
  <nav class="flex justify-between py-4">
    <div v-if="breadcrumbStore.linkList.length > 0">
      <span v-for="(link, index) in linkList" v-bind:key="link.title">
        <router-link
          :to="link.href"
          class="text-xs"
          :class="index == linkList.length - 1 ? 'text-primary' : 'text-secondary'"
          >{{ link.title }}</router-link
        >
        <span class="text-sm" v-if="index != linkList.length - 1"> &nbsp;/&nbsp;</span>
      </span>
    </div>
  </nav>
</template>
<script setup lang="ts">
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import { computed } from 'vue'

const breadcrumbStore = useBreadcrumbStore()

const linkList = computed(() => {
  return breadcrumbStore.linkList
})
</script>
