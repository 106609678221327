<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useAuthStore } from '@/stores/auth.store'

import PlanAndBenifits from '@/components/dashboard/plugin/PlansAndBenifits.vue'
import MyPlugins from '@/components/dashboard/plugin/MyPlugins.vue'
import type ILink from '@/components/shared/interface/ILink'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'

const authStore = useAuthStore()
const breadcrumbStore = useBreadcrumbStore()
const isLogedIn = computed(() => authStore.isLogedIn)

const breadcrumbLinks: ILink[] = [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  },
  {
    href: '/dashboard/plugin',
    title: 'Plugins'
  }
]

onMounted(() => {
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks)
})
</script>

<template>
  <div>
    <template v-if="!isLogedIn">
      <PlanAndBenifits />
    </template>
    <template v-else>
      <MyPlugins />
    </template>
  </div>
</template>
