<script setup lang="ts">
import BaseInput from '@/components/forms/BaseInput.vue'
import BaseTextbox from '@/components/forms/BaseTextbox.vue'
import { number, object, string } from 'yup'
import { useField, useForm } from 'vee-validate'
import { computed, type PropType, watch, ref } from 'vue'
import { FORM_INPUT_TYPE } from '@/components/forms/form.enum'
import type { IOpenEndedQuestion_Request } from '@/components/guru/interface/requests.interface'
import { postQuestion } from '@/services/teach.service'
import { type IQuestionType } from '@/components/guru/interface/IQuestionType'
import { HttpStatusCode } from 'axios'
import router from '@/router'
import type { IQuestion } from '@/components/guru/interface/IQuestion'
import { GURU_ASSIGNMENT_ACTIONS } from '@/components/guru/enums/teach.enum'

const props = defineProps({
  instituteCode: {
    type: String,
    required: true
  },
  assignmentId: {
    type: Number,
    required: true
  },
  questionTypeObj: {
    type: Object as PropType<IQuestionType>,
    require: true
  },
  mode: {
    type: String as PropType<GURU_ASSIGNMENT_ACTIONS>,
    require: false
  },
  questionData: {
    type: Object as PropType<IQuestion | undefined>,
    require: false
  }
})
const submitClicked = ref(false)
watch(
  () => props.questionData,
  (editingQuestion) => {
    if (editingQuestion) {
      question.value = editingQuestion?.question
      maxLength.value = editingQuestion?.maxLength
      minLength.value = editingQuestion?.minLength
    } else {
      //default
      question.value = null
      maxLength.value = null
      minLength.value = null
    }
  }
)

//Assets and static data
const questionInput = {
  label: 'Question'
}

const minLengthInput = {
  label: 'Minimum Length'
}

const maxLengthInput = {
  label: 'Maximum Length'
}

/**
 * @returns string
 */
function getButtonText(): string {
  if (props.mode === GURU_ASSIGNMENT_ACTIONS.ADD) {
    return 'Create Question'
  }

  if (props.mode === GURU_ASSIGNMENT_ACTIONS.EDIT) {
    return 'Save Changes'
  }

  return ''
}

const { errors, handleSubmit } = useForm({
  validationSchema: object({
    question: string().required().min(5).label('Question'),
    minLength: number().required().label('Minimum Length'),
    maxLength: number().required().label('Maximum Length')
  })
})

const { value: question, errorMessage: questionError } = useField('question')

const { value: minLength, errorMessage: minLengthError } = useField('minLength')

const { value: maxLength, errorMessage: maxLengthError } = useField('maxLength')

const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0 && !submitClicked.value
})

const onSubmit = handleSubmit(async (values) => {
  submitClicked.value = true

  const formObject: IOpenEndedQuestion_Request = {
    question: values.question,
    maxLength: values.maxLength,
    minLength: values.minLength,
    assQuestionId: props.questionData?.assQuestionId
  }

  const res = await postQuestion(
    formObject,
    props.questionTypeObj!,
    props.assignmentId,
    props.instituteCode
  )

  if (res == HttpStatusCode.Ok) {
    router.go(0)
  }
  submitClicked.value = false
})
</script>

<template>
  <form @submit.prevent="onSubmit">
    <div class="my-5 flex flex-col">
      <BaseTextbox
        :label="questionInput.label"
        v-model="question"
        :error="questionError"
        :isLightGray="false"
      />
      <div class="flex justify-between">
        <BaseInput
          v-model="minLength"
          :label="minLengthInput.label"
          :error="minLengthError"
          :inputType="FORM_INPUT_TYPE.NUMBER"
          :isLightGray="false"
        />
        <BaseInput
          v-model="maxLength"
          :label="maxLengthInput.label"
          :error="maxLengthError"
          :inputType="FORM_INPUT_TYPE.NUMBER"
          :isLightGray="false"
        />
      </div>
    </div>
    <button :disabled="!canSubmit" type="submit" class="btn-primary btn-rounded-md">
      {{ getButtonText() }}
    </button>
  </form>
</template>
