<script setup lang="ts">
import SixItemsPanel, { type ISixItems } from '@/components/shared/SixItemsPanel.vue'
import UseCaseBanner, { type IUseCaseBannerData } from '@/components/shared/UseCaseBanner.vue'
import type { IActionButton } from '@/components/shared/PricingCard.vue'
import { SUBSCRIPTION_PRODUCT } from '@/components/shared/subscription/enum/subscription.enum'
import Button_login_then_redirect from '@/components/shared/subscription/Button_login_then_redirect.vue'
const sectionProp: IUseCaseBannerData = {
  orangeTextAboveTitle: 'Teach & Assess Features',
  title: 'Create assignments and automate grading and corrections',
  description:
    "Track students' progress, save valuable time and create memorable learning experiences.",
  textLeftImageRight: true,
  image: {
    src: '',
    alt: ''
  }
}

const featuresGridData: ISixItems[] = [
  {
    icon: 'fa-check',
    heading: 'Create interactive learning experiences',
    body: 'Promote active learning and hands-on practice for students, fostering a deeper understanding of programming concepts.'
  },
  {
    icon: 'fa-users',
    heading: 'Flexible User Management',
    body: 'Easily assign roles like Account Owner, Institute Admin, Teachers and Students, ensuring smooth administration and organized assignment distribution.'
  },
  {
    icon: 'fa-comment',
    heading: 'Automated Grading and Scoring',
    body: 'Save time with auto-correct and auto-score features, providing students with instant feedback and allowing you to focus on guiding their progress.'
  },
  {
    icon: 'fa-question',
    heading: 'Varied Question Types',
    body: 'Engage students with a range of question formats, including multiple choice (single and multiple answers), true or false, open-ended and challenging programming questions.'
  },
  {
    icon: 'fa-arrow-up-right-from-square',
    heading: 'Transparent Result Disclosures',
    body: 'Choose between automatic or manual result disclosures for auto-corrected assignments, ensuring fairness and transparency in evaluation.'
  },
  {
    icon: 'fa-message',
    heading: 'Reevaluation Support',
    body: 'Foster a growth mindset with reevaluation options, allowing students to request a second review of their assignments for improved learning outcomes.'
  }
]

const button: IActionButton = {
  buttonText: 'Create an Assignment',
  link: `/dashboard/institution`,
  product: SUBSCRIPTION_PRODUCT.TEACH
}
</script>

<template>
  <div class="py-8 md:py-14">
    <UseCaseBanner :sectionData="sectionProp" :removePadding="true" class="py-10">
      <Button_login_then_redirect :button-data="button" />
    </UseCaseBanner>
    <SixItemsPanel :sixItems="featuresGridData" />
  </div>
</template>
