<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import projectsService, { type IProject } from '@/services/ide/projects.service'
import utilModelsService from '@/services/util.models.service'
import { useIdeStore } from '@/stores/ide.store'
import ModelLayout from '@/layouts/ModelLayout.vue'
import { IDEVIEWMODELS } from '@/utils/models'
import TreeItemRoComp from '@/components/ide/ide/settings/TreeItemRoComp.vue'
import CopyShare from '@/components/ide/shared/CopyShareComp.vue'
import { useAuthStore } from '@/stores/auth.store'
import { IDECONSTANT } from '@/utils/ide'

const authStore = useAuthStore()
const ideStore = useIdeStore()
const route = useRoute()

const isMounted = ref<boolean>(false)
const httpErrorTimeOut = ref<number | null>(null)
const httpError = ref<string>('')
const httpSuccessTimeOut = ref<number | null>(null)
const httpSuccess = ref<string>('')
const loadProjectInProgress = ref<boolean>(false)

const isBlockly = computed(() => {
  return ideStore.isBlockly
})
const isUserloggedIn = computed(() => {
  return authStore.isLogedIn
})
const title = computed(() => {
  return 'My ' + ideStore.ideMeta?.langDisplayName + ' Projects'
})
const isProjectsMounted = computed(() => {
  return isMounted.value
})
const projects = computed(() => {
  return ideStore.isProjects || []
})
const isProjects = computed(() => {
  return projects.value.length > 0
})
const isAdvanced = computed(() => {
  return ideStore.isAdvanced
})
const projectUrl = computed(() => {
  return ideStore.isSelectedProject?.url || ''
})
const embedUrl = computed(() => {
  return ideStore.isSelectedProject?.embedUrl || ''
})
const pluginId = computed(() => {
  return ideStore.isSelectedProject?.pluginId || ''
})
const isProject = computed(() => {
  return ideStore.isSelectedProject !== null
})
const treeData = computed(() => {
  return ideStore.isSelectedProject?.script?.treeData || {}
})
const treeHome = computed(() => {
  return ideStore.isSelectedProject?.script?.home || {}
})
const libraries = computed(() => {
  return ideStore.isSelectedProject?.libraries || ''
})
const isShowShared = computed(() => {
  return isProject.value && ideStore.isSelectedProject?.shared === true
})
const isHtml = computed(() => {
  return ideStore.isHtml
})
/**
 * if the project is selected
 * @param project IProject
 * @returns boolean
 */
const isSelected = (project: IProject) => {
  return ideStore.isSelectedProject?.id === project.id || false
}
/**
 * if the project is loaded
 * @param project IProject
 * @returns boolean
 */
const isMyProject = (project: IProject) => {
  return ideStore.isProjectId === project.id || false
}
/**
 * Load projects
 */
const loadProjects = async () => {
  httpError.value = ''
  httpSuccess.value = ''
  isMounted.value = false
  await new Promise((resolve) => setTimeout(resolve, 100))
  await projectsService
    .loadProjects()
    .then(() => {
      isMounted.value = true
    })
    .catch(() => {
      httpError.value = 'Error loading your projects. Please try again later.'
    })

  isMounted.value = true
}
/**
 * Load project
 * @param selecedProject IProject
 */
const loadProject = async (selecedProject: IProject) => {
  httpError.value = ''
  httpSuccess.value = ''
  loadProjectInProgress.value = true
  await projectsService
    .loadProject(selecedProject)
    .then(() => {
      loadProjectInProgress.value = false
    })
    .catch(() => {
      loadProjectInProgress.value = false
      httpError.value = 'Error loading your project. Please try again later.'
    })
}
/**
 * Open project
 */
const openProject = () => {
  httpError.value = ''
  httpSuccess.value = ''
  projectsService.openProject(ideStore.isSelectedProject)
}
/**
 * Delete project
 */
const deleteProject = async () => {
  await utilModelsService
    .confirmPromise(
      'Are you sure, do you want to delete this project?',
      null,
      IDEVIEWMODELS.MYPROJECTS
    )
    .then(async () => {
      httpError.value = ''
      httpSuccess.value = ''
      await projectsService
        .deleteProject()
        .then(() => {
          httpSuccess.value = 'Project Deleted Successfully'
        })
        .catch(() => {
          httpError.value = 'Error deleting your project. Please try again later.'
        })
    })
    .catch(() => {})
}
onMounted(() => {
  loadProjects()
  watch(route, () => {
    httpError.value = ''
    httpSuccess.value = ''
    loadProjects()
  })
  watch(isUserloggedIn, () => {
    loadProjects()
  })
  watch(httpError, () => {
    if (httpError.value) {
      if (httpErrorTimeOut.value) clearTimeout(httpErrorTimeOut.value)
      httpErrorTimeOut.value = setTimeout(() => {
        httpError.value = ''
      }, 8000)
    }
  })
  watch(httpSuccess, () => {
    if (httpSuccess.value) {
      if (httpSuccessTimeOut.value) clearTimeout(httpSuccessTimeOut.value)
      httpSuccessTimeOut.value = setTimeout(() => {
        httpSuccess.value = ''
      }, 8000)
    }
  })
})
</script>

<template>
  <ModelLayout :title="title" :hs="IDEVIEWMODELS.MYPROJECTS" :wide-screen="true">
    <p :class="['p-xsmall ', 'error', , { hidden: !httpError && !isProjectsMounted }]">
      {{ httpError }}
    </p>
    <p :class="['p-xsmall', 'success', { hidden: !httpSuccess }]">
      {{ httpSuccess }}
    </p>
    <div v-show="isProjectsMounted" class="flex flex-col gap-2">
      <div v-show="isProjects" class="flex h-full w-full flex-col gap-5 lg:flex-row">
        <div
          class="flex h-full max-h-32 w-full max-w-full flex-col gap-2 lg:max-h-72 lg:max-w-[50%]"
        >
          <p class="heading-xsmall text-sm">Your Projects</p>
          <div class="flex h-full max-h-full flex-col gap-1 overflow-y-scroll">
            <button
              :disabled="loadProjectInProgress || isMyProject(project)"
              v-for="project in projects"
              :key="project.id"
              :class="[
                'link-primary',
                'flex',
                'items-center',
                'w-full',
                'gap-x-2',
                'rounded-md',
                'py-1',
                {
                  'link-secondary': isSelected(project)
                },
                {
                  'link-secondary line-through': isMyProject(project)
                }
              ]"
              @click="loadProject(project)"
            >
              <FontAwesomeIcon icon="fa-folder" class="h-4 w-4" />
              <span class="p-small"
                >{{ project.name }} {{ isMyProject(project) ? '(Current)' : '' }}
              </span>
            </button>
          </div>
        </div>
        <div
          v-show="!isBlockly && !isAdvanced && !httpError && !loadProjectInProgress && isProject"
          class="h-72 w-full"
        >
          <div :id="IDECONSTANT.PROJECT_EDITOR" class="editor block h-72 w-full rounded-lg"></div>
        </div>
        <div
          v-show="isAdvanced && !httpError && !loadProjectInProgress && isProject"
          class="h-full w-full"
        >
          <div class="section-tertiary h-72 w-full min-w-full overflow-scroll rounded-lg">
            <TreeItemRoComp :treeData="treeData" :treeHome="treeHome" :libraries="libraries" />
          </div>
        </div>
      </div>
      <CopyShare
        :isShowShared="isShowShared"
        :projectUrl="projectUrl"
        :showEmbed="!isHtml && !isAdvanced"
        :embedUrl="embedUrl"
        :pluginId="pluginId"
        :closeHsModal="IDEVIEWMODELS.MYPROJECTS"
      />
      <div v-if="isProject" class="flex w-full gap-2">
        <button
          class="btn-primary btn-rounded-md"
          :data-hs-overlay="`#${IDEVIEWMODELS.MYPROJECTS}`"
          data-hs-overlay-close
          @click="openProject"
        >
          Open
        </button>
        <button class="btn-secondary btn-rounded-md" @click="deleteProject">Remove</button>
      </div>
      <div v-show="!isProjects">
        <p class="heading-xsmall text-sm">No Projects</p>
        <p class="p-xsmall">You have no projects yet. Create a new project to get started.</p>
      </div>
    </div>
    <button
      class="btn-secondary btn-rounded-md"
      :data-hs-overlay="`#${IDEVIEWMODELS.MYPROJECTS}`"
      data-hs-overlay-close
    >
      Close
    </button>
  </ModelLayout>
</template>
