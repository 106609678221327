<template>
  <div
    class="hs-dropdown-menu left-0 top-full z-10 hidden w-full min-w-[15rem] border-t border-t-neutral-200 bg-neutral-50 opacity-0 transition-[opacity,margin] duration-[0.1ms] hs-dropdown-open:opacity-100 dark:border-t-text-secondary dark:bg-[#121418] sm:shadow-lg sm:duration-[150ms]"
  >
    <div class="view-container w-full justify-around sm:pt-14 lg:flex lg:flex-col lg:pt-10">
      <slot></slot>

      <div
        class="p-small lg:p-normal leading-12 mt-8 border-t border-t-neutral-200 py-8 font-light dark:border-gray-700"
      >
        <span class="italic"
          >Are you an education institution or business looking for a custom solution? Easy!
        </span>
        <RouterLink to="/contact-us" class="text-brand mx-1 cursor-pointer text-left underline">
          Contact Us</RouterLink
        >
        <span class="italic"> and we will build one for you.</span>
      </div>
    </div>
  </div>
</template>
