<script setup lang="ts">
declare global {
  interface Window {
    TogetherJS: any
  }
}
import projectsService from '@/services/ide/projects.service'
import historyService from '@/services/ide/settings/history.service'
import settingService from '@/services/ide/settings/setting.service'
import ModelLayout from '@/layouts/ModelLayout.vue'
import { computed } from 'vue'
import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'
import { IDEVIEWMODELS } from '@/utils/models'

const authStore = useAuthStore()
const ideStore = useIdeStore()

const isShowLibs = computed(() => {
  return ideStore.ideMeta?.language === 'java'
})
const isUserloggedIn = computed(() => {
  return authStore.isLogedIn
})
const isAdvanced = computed(() => {
  return ideStore.isAdvanced || false
})
const isHtml = computed(() => {
  return ideStore.isHtml
})
/**
 * Clear selected project before opening my projects model
 */
const initProjects = () => {
  if (authStore.isUserloggedIn) {
    projectsService.refresh()
  }
}
/**
 * Get history before opening history model
 */
const initHistory = () => {
  if (!isAdvanced.value) {
    historyService.refresh()
  }
}
/**
 * set copy editer script to code editor script
 */
const initCopy = () => {
  if (!isHtml.value) {
    settingService.refreshCopyEditor()
  }
}
/**
 * Collaborate and initiate togetherjs
 */
const collaborate = () => {
  window.TogetherJS(this)
}
/**
 * Download the code
 */
const InitDownload = async () => {
  settingService.refreshDownloadEditor()
}
/**
 * Open the code from local file
 */
const initOpen = () => {
  settingService.refreshOpenEditor()
}

/**
 * Pretty print the page
 */
const prettyPrint = () => {
  settingService.prettyPrint()
}
</script>

<template>
  <ModelLayout title="IDE Settings" :hs="IDEVIEWMODELS.SETTINGS">
    <div class="flex flex-col gap-1">
      <button
        v-show="isShowLibs"
        class="btn link-primary flex w-fit justify-start gap-4 p-1 align-middle"
        :data-hs-overlay="`#${IDEVIEWMODELS.LIBRARIES}`"
      >
        <FontAwesomeIcon icon="fa-plus" class="h-4 w-4" />
        External Libraries (from Maven repo)
      </button>
      <button
        class="btn link-primary flex w-fit justify-start gap-4 p-1 align-middle"
        :data-hs-overlay="`#${IDEVIEWMODELS.SETTINGS}`"
        data-hs-overlay-close
        @click="collaborate"
      >
        <FontAwesomeIcon icon="fa-video" class="h-4 w-4" />
        Collaborate/Peer Programming
      </button>
      <button
        v-if="!isAdvanced"
        class="btn link-primary flex w-fit justify-start gap-4 p-1 align-middle"
        :data-hs-overlay="`#${IDEVIEWMODELS.EXECUTEHISTORY}`"
        @click="initHistory"
      >
        <FontAwesomeIcon icon="fa-history" class="h-4 w-4" />
        Execute History
      </button>
      <button
        class="btn link-primary flex w-fit justify-start gap-4 p-1 align-middle"
        :data-hs-overlay="`#${IDEVIEWMODELS.NEWPROJECT}`"
      >
        <FontAwesomeIcon icon="fa-file" class="h-4 w-4" />
        New Project/ Clear All
      </button>
      <button
        class="btn link-primary flex w-fit justify-start gap-4 p-1 align-middle"
        :data-hs-overlay="
          isUserloggedIn ? `#${IDEVIEWMODELS.MYPROJECTS}` : `#${IDEVIEWMODELS.NOTLOGGEDIN}`
        "
        @click="initProjects"
      >
        <FontAwesomeIcon icon="fa-folder" class="h-4 w-4" />
        My Projects
      </button>
      <button
        class="btn link-primary flex w-fit justify-start gap-4 p-1 align-middle"
        :data-hs-overlay="
          isUserloggedIn ? `#${IDEVIEWMODELS.SAVE}` : `#${IDEVIEWMODELS.NOTLOGGEDIN}`
        "
      >
        <FontAwesomeIcon icon="fa-floppy-disk" class="h-4 w-4" />
        Save
      </button>
      <button
        v-if="!isAdvanced && !isHtml"
        class="btn link-primary flex w-fit justify-start gap-4 p-1 align-middle"
        :data-hs-overlay="`#${IDEVIEWMODELS.OPENFROMFILE}`"
        @click="initOpen"
      >
        <FontAwesomeIcon icon="fa-upload" class="h-4 w-4" />
        Open (from local file)
      </button>
      <button
        v-if="!isAdvanced"
        class="btn link-primary flex w-fit justify-start gap-4 p-1 align-middle"
        :data-hs-overlay="`#${IDEVIEWMODELS.DOWNLOAD}`"
        @click="InitDownload"
      >
        <FontAwesomeIcon icon="fa-download" class="h-4 w-4" />
        Save (to local file)
      </button>
      <button
        class="btn link-primary flex w-fit justify-start gap-4 p-1 align-middle"
        :data-hs-overlay="
          isUserloggedIn ? `#${IDEVIEWMODELS.EDITABLESHARE}` : `#${IDEVIEWMODELS.NOTLOGGEDIN}`
        "
      >
        <FontAwesomeIcon icon="fa-share-alt-square" class="h-4 w-4" />
        Editable Share
      </button>
      <button
        v-if="!isHtml"
        class="btn link-primary flex w-fit justify-start gap-4 p-1 align-middle"
        :data-hs-overlay="`#${IDEVIEWMODELS.INSTANTSHARE}`"
      >
        <FontAwesomeIcon icon="fa-share-square" class="h-4 w-4" />
        Instant Share (No Login/Save required)
      </button>
      <button
        v-if="!isHtml"
        class="btn link-primary flex w-fit justify-start gap-4 p-1 align-middle"
        :data-hs-overlay="`#${IDEVIEWMODELS.COPY}`"
        @click="initCopy"
      >
        <FontAwesomeIcon icon="fa-clipboard" class="h-4 w-4" />
        Copy to Clipboard
      </button>
      <button
        v-if="!isAdvanced"
        class="btn link-primary flex w-fit justify-start gap-4 p-1 align-middle"
        :data-hs-overlay="`#${IDEVIEWMODELS.SETTINGS}`"
        data-hs-overlay-close
        @click="prettyPrint"
      >
        <FontAwesomeIcon icon="fa-print" class="h-4 w-4" />
        Pretty Print
      </button>
      <a
        class="btn link-primary flex w-fit justify-start gap-4 p-1 align-middle"
        href="https://docs.jdoodle.com"
        target="_blank"
      >
        <FontAwesomeIcon icon="fa-circle-question" class="h-4 w-4" />
        How To/FAQ
      </a>
    </div>
  </ModelLayout>
</template>
