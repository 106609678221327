<script setup lang="ts">
import Goback from '@/components/utils/GobackComp.vue'
import authService from '@/services/auth.service'
import { useAuthStore } from '@/stores/auth.store'
import { computed, onMounted, ref, watch } from 'vue'

const props = defineProps({
  token: {
    type: String,
    required: true
  }
})

const authStore = useAuthStore()

const varifySuccess = ref('')
const varifyError = ref('')

enum VERIFYREGISTRATIONSTATE {
  WAITINGFORINIT = 'waitingForInit',
  LOGEDIN = 'logedin',
  VERIFIED = 'verified',
  ERROR = 'error'
}

const verifyRegistrationState = ref<VERIFYREGISTRATIONSTATE>(VERIFYREGISTRATIONSTATE.WAITINGFORINIT)

interface ISuggestion {
  title: string
  description: string
}

const waitingForInitSuggestion: ISuggestion[] = [
  {
    title: 'Please wait...',
    description: 'We are verifying your email address. Please wait for a moment.'
  }
]
const logedInSuggestion: ISuggestion[] = [
  {
    title: 'Please logout to verify your registration',
    description:
      'please click on the logout button to logout from your account. We will verify your registration after you logout.'
  }
]

const variedSuggestion: ISuggestion[] = [
  {
    title: 'You are already logged in',
    description: 'Your email address has been verified successfully and you are now logged in.'
  }
]

const errorSuggestion: ISuggestion[] = [
  {
    title: 'Please check the link is valid',
    description:
      'If you have copied the link from an email, please make sure you have copied the entire link.'
  },
  {
    title: 'This is one time use link',
    description:
      'If you have already used the link, your email should have been verified already. You can login with your username and password.'
  },
  {
    title: 'If your account is locked',
    description: 'Please contact JDoodle Support for assistance.'
  },
  {
    title: 'If you still have issues varifying your email',
    description: 'Please contact JDoodle Support for any assistance.'
  }
]

interface IViewData {
  title: string
  suggestions: ISuggestion[]
  icon: string
}

const viewData = computed<IViewData>(() => {
  switch (verifyRegistrationState.value) {
    case VERIFYREGISTRATIONSTATE.LOGEDIN:
      return {
        title: 'You are already logged in',
        suggestions: logedInSuggestion,
        icon: 'right-from-bracket'
      }
    case VERIFYREGISTRATIONSTATE.VERIFIED:
      return {
        title: 'Your email has been verified successfully',
        suggestions: variedSuggestion,
        icon: 'circle-check'
      }
    case VERIFYREGISTRATIONSTATE.ERROR:
      return {
        title: 'We are unable to verify your email address',
        suggestions: errorSuggestion,
        icon: 'circle-question'
      }
    default:
      return {
        title: 'Loading...',
        suggestions: waitingForInitSuggestion,
        icon: 'spinner'
      }
  }
})

/**
 * @description Verify registration
 *  1. If user is loged in, log out and verify registration
 *  2. If user is not loged in, verify registration
 */
const verifyRegistration = async () => {
  await authService
    .verifyRegistration(props.token)
    .then((response) => {
      varifySuccess.value = response?.data?.username
        ? `Thanks for using JDoodle ${response?.data?.username}!`
        : `Thanks for using JDoodle!`
      varifyError.value = ''
      verifyRegistrationState.value = VERIFYREGISTRATIONSTATE.VERIFIED
    })
    .catch((error: any) => {
      varifySuccess.value = ''
      varifyError.value =
        error?.response?.data?.message ||
        'Sorry, we have no record with this registration code, or it has expired.'
      verifyRegistrationState.value = VERIFYREGISTRATIONSTATE.ERROR
    })
}

onMounted(() => {
  watch(
    () => authStore.isInitiated,
    (isAppInitiated) => {
      if (isAppInitiated) {
        if (authStore.isLogedIn) {
          verifyRegistrationState.value = VERIFYREGISTRATIONSTATE.LOGEDIN
        } else {
          verifyRegistration()
        }
      }
    }
  )
})
</script>
<template>
  <div class="section-primary">
    <div class="view-container w-full py-4">
      <div>
        <h1 class="heading-large my-10 md:leading-tight lg:my-14">
          {{ viewData.title }}
        </h1>
        <h2 v-if="varifyError" class="error heading-medium mb-4">Reason: {{ varifyError }}</h2>
        <h2 v-if="varifySuccess" class="success heading-medium mb-4">
          {{ varifySuccess }}
        </h2>

        <div v-for="(sugession, index) of viewData.suggestions" :key="index" class="py-4">
          <div class="flex gap-x-5">
            <FontAwesomeIcon :icon="viewData.icon" class="mt-1 h-6 w-6 flex-shrink-0" />

            <div>
              <h3 class="heading-small">
                {{ sugession.title }}
              </h3>
              <p class="p-normal mt-1 text-justify text-link-primary-hover">
                {{ sugession.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Goback v-if="verifyRegistrationState !== VERIFYREGISTRATIONSTATE.WAITINGFORINIT">
        <button
          v-if="
            verifyRegistrationState === VERIFYREGISTRATIONSTATE.LOGEDIN ||
            verifyRegistrationState === VERIFYREGISTRATIONSTATE.ERROR
          "
          @click="verifyRegistration"
          class="btn-secondary btn-rounded-md sm:w-auto"
        >
          <FontAwesomeIcon
            :icon="
              verifyRegistrationState === VERIFYREGISTRATIONSTATE.LOGEDIN
                ? 'right-from-bracket'
                : 'rotate-right'
            "
            class="h-5 w-5"
          />
          {{
            verifyRegistrationState === VERIFYREGISTRATIONSTATE.LOGEDIN
              ? 'Logout and verify'
              : 'Try again'
          }}
        </button>
      </Goback>
    </div>
  </div>
</template>
