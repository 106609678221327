<script setup lang="ts">
import type { ICarouselItem } from '@/components/shared/CardCarousel.vue'
import CardCarousel from '@/components/shared/CardCarousel.vue'
import { customersReviewData } from '@/utils/sharedData/userReviews'

const userReviews: ICarouselItem[] = customersReviewData
const title = 'What JDoodle coders say about us'
</script>

<template>
  <CardCarousel class="bg-gray-50" :reviewData="userReviews" :title="title" />
</template>
