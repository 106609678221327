import editorService from '@/services/ide/editor.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import customPluginService from '@/services/ide/plugin/custom.plugin.service'
import pluginService from '@/services/ide/plugin/plugin.service'
import { type ILoadProjectsRequest } from '@/services/ide/projects.service'
import loadScriptInBody from '@/services/loadScriptInBody.service'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import { BREAKPOINTS, SCREENSIZES, TYPES } from '@/utils/customPlugin'
import { IDECONSTANT } from '@/utils/ide'
import { META } from '@/utils/meta'
import axios from 'axios'
import { cloneDeep, compact, isNumber } from 'lodash'
/**
 * utility function to get the new component based on the screen size and layout
 * @param layout layout
 * @param component component
 * @param screenSize screenSize
 * @returns new component
 */
const getNewComponent = (
  layout: any,
  component: any,
  screenSize: (typeof SCREENSIZES)[keyof typeof SCREENSIZES]
) => {
  const maxI = Math.max(...layout.map((item: any) => item.i))
  // find the largest y value
  const maxY = Math.max(...layout.map((item: any) => item.y))
  let w = 1
  switch (screenSize) {
    case SCREENSIZES.SM:
      w = 6
      break
    case SCREENSIZES.MD:
      w = 10
      break
    case SCREENSIZES.LG:
      w = 12
      break
    default:
      w = 12
      break
  }
  const newComponent = {
    ...component,
    i: `${maxI + 1}`,
    x: 0,
    y: maxY,
    w: w,
    h: 1
  }
  return newComponent
}
/**
 * Set the version index
 */
const setVersionIndex = () => {
  if (useIdeStore().project.versionIndex || isNumber(useIdeStore().project.versionIndex)) {
    useIdeStore().setVersionIndex(parseInt(useIdeStore().project.versionIndex))
  } else {
    // @ts-ignore-next-line
    const versionIndex = useIdeStore().ideMeta?.versions?.length - 1 || 0
    useIdeStore().setVersionIndex(versionIndex)
  }
}
/**
 * Post action for the embed share blockly
 * @param data - The shared api response data
 * @param count - The count
 */
const openPostActionBlockly = async (data: any, count: number = 0) => {
  if (!window.Blockly || !useIdeStore().isBlocklyWorkspace) {
    if (count > 10) {
      useIdeStore().setShareNotFound(true)
      useIdeStore().setShareNotFoundHttpError('Unable to load the IDE. Please try again later.')
    } else {
      await new Promise((resolve) => setTimeout(resolve, 600))
      openPostActionBlockly(data, count + 1)
    }
  } else {
    const project = data.project
    useIdeStore().setProject(project)
    blocklyService.openBlockly(project.script)
  }
}
/**
 * Post action for the embed share
 * @param data response data
 * @param count count
 */
const openPostAction = async (data: any, count: number = 0) => {
  if (!useIdeStore().isWindowAce) {
    if (count > 10) {
      useIdeStore().setShareNotFound(true)
      useIdeStore().setShareNotFoundHttpError('Unable to load the IDE. Please try again later.')
    } else {
      await new Promise((resolve) => setTimeout(resolve, 600))
      openPostAction(data, count + 1)
    }
  } else {
    const project = data.project
    useIdeStore().setProject(project)
    if (useIdeStore().isBlockly) {
      blocklyService.loadBlockly()
      openPostActionBlockly(data)
    } else {
      editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, project.script)
    }
    setVersionIndex()
    if (useIdeStore().project?.libraries)
      useIdeStore().javaLibraries = compact(useIdeStore().project.libraries.split(' '))
  }
}
/**
 * Remove the selected project
 */
const removeSelectedProject = () => {
  const selectedProject = usePluginStore().selectedProject
  // add it back to the available projects
  if (selectedProject) {
    const availableProjects = [...usePluginStore().availbleProjects]
    availableProjects.push(selectedProject)
    usePluginStore().setAvailbleProjects(availableProjects)
    usePluginStore().setSelectedProject(null)
    useIdeStore().setProject(null)
    setDefautlLanguage(0)
    usePluginStore().updatePluginResponse('type', TYPES.BASIC)
    usePluginStore().updatePluginResponse('pluginProjectData', null)
  }
}
/**
 * Update the available projects
 */
const updateAvailableProjects = () => {
  const projects = usePluginStore().availbleProjects
  const availableProjects = projects.filter(
    (project: any) =>
      !usePluginStore().selectedProject || project.id !== usePluginStore().selectedProject.id
  )
  usePluginStore().setAvailbleProjects(availableProjects)
}
/**
 * Open project
 * @param project - The project to set.
 * @param index - The lang index to set.
 * @returns axios response
 */
const openProject = async (project: any, index: number) => {
  removeSelectedProject()
  usePluginStore().updatePluginResponse('type', TYPES.PROJECT)
  usePluginStore().setSelectedProject(project)
  updateAvailableProjects()
  useIdeStore().setProject(null)

  const languages = [...usePluginStore().languages]
  const language = languages[index].language
  editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, '')
  const loadProjectRequest: ILoadProjectsRequest = {
    id: project.id,
    lang: language as string,
    isMultiFile: false
  }
  return axios
    .post('/api/doodle/file', loadProjectRequest)
    .then(async (response: { data: any }) => {
      usePluginStore().updatePluginResponse('pluginProjectData', response.data.project)
      useIdeStore().setIdeMeta(META[response.data.project.language])
      await new Promise((resolve) => setTimeout(resolve, 500))
      editorService.splitAndInit()
      await new Promise((resolve) => setTimeout(resolve, 600))
      openPostAction(response.data)
      return response
    })
    .catch((error) => {
      throw error
    })
}
/**
 * Get projects
 * @returns axios response
 */
const getProjects = async () => {
  const requestData = {
    page: 0,
    size: 50
  }
  return await axios
    .post('/api/doodle/myProjects', requestData)
    .then((response: { data: { projects: any[] } }) => {
      // exculde any project has isMultiFile as true or type as '"htmlCode"'
      const availbleProjects = response.data.projects.filter(
        (project) => !project.isMultiFile && project.type !== 'htmlCode'
      )
      usePluginStore().setAvailbleProjects(availbleProjects)
      return response
    })
    .catch((error) => {
      throw error
    })
}
/**
 * add the selected component to all layout
 * @param component - The component to set
 */
const addComponentToLayout = (component: any) => {
  const layoutByScreenSize = cloneDeep(usePluginStore().layoutByScreenSize)
  // find the largest i value

  layoutByScreenSize.push(
    getNewComponent(layoutByScreenSize, component, usePluginStore().screenSize)
  )
  usePluginStore().layoutByScreenSize = cloneDeep(layoutByScreenSize)
  const sizes = Object.keys(BREAKPOINTS)
  sizes.forEach((size: any) => {
    const layout = usePluginStore().layouts[size]
    if (!layout) return
    // if the component is already added to the layout, then return
    if (layout.find((item: any) => item.name === component.name)) return
    layout.push(getNewComponent(layout, component, size))
  })
  usePluginStore().setSelectedComponent(component)
  updateAvailableComponents()
}
/**
 * add the selected functional to all layout
 * @param functional - The functional to set
 */
const addFunctionalToSelected = (functional: any) => {
  usePluginStore().addToSelectedFunctional(functional)
  updateAvailableFunctionals()
}
/**
 * remove the selected functional from all layout
 * @param functional - The functional to set
 */
const removeSelectedFunctional = (functional: any) => {
  const selectedFunctionals = cloneDeep(usePluginStore().selectedFunctionals)
  const index = selectedFunctionals.findIndex((i: any) => i.id === functional.id)
  if (index > -1) {
    selectedFunctionals.splice(index, 1)
  }
  usePluginStore().setSelectedFunctionals(selectedFunctionals)
  updateAvailableFunctionals()
}
/**
 * delete the selected component from all layout
 * @param item - The item to set
 */
const deleteComponentFromLayout = (item: any) => {
  // remove the selected component from all layout

  const layoutByScreenSize = cloneDeep(usePluginStore().layoutByScreenSize)
  const index = layoutByScreenSize.findIndex((i: any) => i.i === item.i)
  if (index > -1) {
    layoutByScreenSize.splice(index, 1)
  }
  usePluginStore().layoutByScreenSize = cloneDeep(layoutByScreenSize)

  const sizes = Object.keys(BREAKPOINTS)
  sizes.forEach((size: any) => {
    const layout = usePluginStore().layouts[size]
    if (!layout) return
    const index = layout.findIndex((i: any) => i.i === item.i)
    if (index > -1) {
      layout.splice(index, 1)
    }
  })
  updateAvailableComponents()
}
/**
 * update the available components
 */
const updateAvailableComponents = () => {
  const components = usePluginStore().components
  const availableComponents = components.filter(
    (component: any) =>
      !usePluginStore().layoutByScreenSize.find((item: any) => item.name === component.name)
  )
  usePluginStore().setAvailbleComponents(availableComponents)
  customPluginService.reseizeAllComponents()
}
/**
 * update the available functionals
 */
const updateAvailableFunctionals = () => {
  const functionals = usePluginStore().functionals
  const availableFunctionals = functionals.filter(
    (functional: any) =>
      !usePluginStore().selectedFunctionals.find((item: any) => item.name === functional.name)
  )
  usePluginStore().setAvailableFunctionals(availableFunctionals)
}
/**
 * Set the default language
 * @param index - The index to set.
 */
const setDefautlLanguage = (index: number) => {
  usePluginStore().setDefautlLanguageIndex(index)
  usePluginStore().setLanguage(index)
  useIdeStore().setProject({
    ...useIdeStore().project,
    language: usePluginStore().isDefaultLanguageAceCode,
    script: ''
  })
  pluginService.onLanguageChange()
}

/**
 * Inject pym script
 */
const injectPym = () => {
  loadScriptInBody.loadScriptInBody('/assets/jdoodle-pym.min.js')
}
/**
 * Eject pym script
 */
const ejectPym = () => {
  loadScriptInBody.unloadScriptInBody('/assets/jdoodle-pym.min.js')
}
/**
 * Save plugin
 * @param pluginId plugin id
 * @returns axios response
 */
const savePlugin = async (pluginId: string) => {
  const name = usePluginStore().isPluginResponse.name
  const type = usePluginStore().isPluginResponse.type
  const activeStatus = usePluginStore().isPluginResponse.activeStatus
  const uiData = JSON.stringify(usePluginStore().layouts)
  let features = null
  const requestData: any = {
    id: pluginId,
    name: name,
    type: type,
    activeStatus: activeStatus
  }
  if (type === TYPES.BASIC) {
    const defautlLanguage = usePluginStore().defautlLanguageIndex || 0
    const defaultVersion = useIdeStore().versionIndex || 0
    let program = ''
    if (useIdeStore().isBlockly) {
      program = JSON.stringify(blocklyService.getBlocklyScript())
    } else {
      program = JSON.stringify(editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue())
    }
    const interactiveMode = useIdeStore().interactiveMode
    const args = useIdeStore().args
    const stdin = useIdeStore().stdin
    features = {
      defautlLanguage,
      defaultVersion,
      program: program,
      interactiveMode,
      args,
      stdin
    }
  }
  if (type === TYPES.PROJECT) {
    requestData['projectId'] = usePluginStore().selectedProject.id as string
  }

  if (usePluginStore().selectedFunctionals.length > 0) {
    if (!features) {
      features = {
        functionals: JSON.stringify(usePluginStore().selectedFunctionals)
      }
    } else {
      features = {
        ...features,
        functionals: JSON.stringify(usePluginStore().selectedFunctionals)
      }
    }
  }

  requestData['pluginConfig'] = {
    uiData,
    features: JSON.stringify(features)
  }
  return await axios
    .post('/api/plugin/updatePlugin', requestData)
    .then(
      (response: {
        data: {
          updatedAt: string
        }
      }) => {
        const { updatedAt } = response.data
        usePluginStore().updateInitialPluginResponse('updatedAt', updatedAt)
        return response
      }
    )
    .catch((error: { response: { message: string } }) => {
      throw error
    })
}
/**
 * Reset plugin
 */
const resetPlugin = () => {
  const originalPluginResponse = cloneDeep(usePluginStore().isInitialPluginResponse)
  usePluginStore().setPluginResponse(originalPluginResponse)
  const { uiData } = originalPluginResponse.pluginConfig
  customPluginService.initLayouts(uiData)
}
/**
 * Toggle active status
 * @param pluginId plugin id
 * @param status status
 * @returns axios response
 */
const toggleActiveStatus = async (pluginId: string, status: boolean) => {
  const requestData = {
    pluginId: pluginId,
    activeStatus: status
  }
  return await axios
    .post('/api/plugin/updatePluginStatus', requestData)
    .then(
      (response: {
        data: {
          activeStatus: string
        }
      }) => {
        const { activeStatus } = response.data
        usePluginStore().updateInitialPluginResponse('activeStatus', activeStatus)
        return response
      }
    )
    .catch((error: { response: { message: string } }) => {
      throw error
    })
}
/**
 * Delete plugin
 * @param pluginId plugin id
 * @returns axios response
 */
const deletePlugin = async (pluginId: string) => {
  const requestData = {
    pluginId: pluginId
  }
  return await axios
    .post('/api/plugin/deletePlugin', requestData)
    .then((response) => {
      return response
    })
    .catch((error: { response: { message: string } }) => {
      throw error
    })
}
/**
 * Rename plugin
 * @param id plugin id
 * @param pluginName plugin name
 * @param pluginDescription plugin description
 * @returns axios response
 */
const renamePlugin = async (id: string, pluginName: string, pluginDescription: string) => {
  const activeStatus = usePluginStore().isPluginResponse.activeStatus
  const requestData = {
    id: id,
    name: pluginName,
    description: pluginDescription,
    activeStatus: activeStatus
  }
  return await axios
    .post('/api/plugin/updatePlugin', requestData)
    .then(
      (response: {
        status: number
        data: {
          name: string
          description: string
          updatedAt: string
        }
      }) => {
        const { name, description, updatedAt } = response.data
        usePluginStore().updateInitialPluginResponse('name', name)
        usePluginStore().updateInitialPluginResponse('description', description)
        usePluginStore().updateInitialPluginResponse('updatedAt', updatedAt)
        return response
      }
    )
    .catch((error: { response: { message: string } }) => {
      throw error
    })
}
/**
 * Initialize the configure when dashboard view is mounted
 * @param pluginId - The pluginId to set.
 * @returns axios response
 */
const initConfigure = async (pluginId: string) => {
  usePluginStore().setIsEditable(true)
  injectPym()
  const requestData = {
    pluginId: pluginId
  }
  return await axios
    .post('/api/plugin/plugin', requestData)
    .then(
      async (response: {
        data: {
          type: string
          pluginProjectData: string
          pluginConfig: {
            uiData: string
            features: string
          }
        }
      }) => {
        const data = response.data
        usePluginStore().setInitialPluginResponse(data)
        usePluginStore().setPluginResponse(data)
        if (data?.pluginProjectData) {
          usePluginStore().setSelectedProject(data.pluginProjectData)
        }
        const { uiData } = data.pluginConfig
        customPluginService.initLayouts(uiData)
        const { type } = data
        const { features } = data.pluginConfig
        customPluginService.initFeatures(features, type)
        await new Promise((resolve) => setTimeout(resolve, 1000))
        updateAvailableComponents()
        return response
      }
    )
    .catch((error: { response: { message: string } }) => {
      throw error
    })
}
export default {
  savePlugin,
  resetPlugin,
  toggleActiveStatus,
  deletePlugin,
  renamePlugin,
  initConfigure,
  setDefautlLanguage,
  addComponentToLayout,
  deleteComponentFromLayout,
  updateAvailableComponents,
  removeSelectedProject,
  addFunctionalToSelected,
  removeSelectedFunctional,
  updateAvailableFunctionals,
  getProjects,
  openProject,
  ejectPym
}
