import type { ISelectListItem } from '@/components/forms/ISelectListItem'
import {
  GURU_ASSIGNMENT_DISCLOSE_TYPE,
  GURU_ASSIGNMENT_RESULT_TYPE,
  GURU_QUESTION_TYPE,
  GURU_ROLES
} from '@/components/guru/enums/teach.enum'
import type { IQuestionType } from '@/components/guru/interface/IQuestionType'
import type { IUserRole } from '@/components/guru/interface/IUserRole'

export const TEACH_CONSTANTS = {
  ASSIGNMENT_RESULT_TYPES: [
    { type: GURU_ASSIGNMENT_RESULT_TYPE.MARK_AND_COMMENT, value: 0 },
    { type: GURU_ASSIGNMENT_RESULT_TYPE.MARK_ONLY, value: 1 },
    { type: GURU_ASSIGNMENT_RESULT_TYPE.COMMENT_ONLY, value: 2 }
  ] as ISelectListItem[],

  DISCLOSE_TYPES: [
    { type: GURU_ASSIGNMENT_DISCLOSE_TYPE.MANUAL, value: 0 },
    { type: GURU_ASSIGNMENT_DISCLOSE_TYPE.ON_SUBMISSION, value: 1 }
  ],

  QUESTION_TYPES: [
    { type: GURU_QUESTION_TYPE.MC_ONE_ANSWER, value: 0 },
    { type: GURU_QUESTION_TYPE.MC_MULTIPLE_ANSWER, value: 1 },
    { type: GURU_QUESTION_TYPE.OPEN_ENDED, value: 2 },
    { type: GURU_QUESTION_TYPE.PROGRAM, value: 3 },
    { type: GURU_QUESTION_TYPE.TRUE_OR_FALSE, value: 4 }
  ] as IQuestionType[],

  GURU_ROLE_TYPES: [
    { name: GURU_ROLES.INSTITUTE_ADMIN, index: 0 },
    { name: GURU_ROLES.TEACHER, index: 1 },
    { name: GURU_ROLES.STUDENT, index: 2 }
  ] as IUserRole[]
}
