<script lang="ts" setup>
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'

import { onMounted, ref } from 'vue'
import ProjectList from '@/components/dashboard/project/ProjectList.vue'
import projectService, {
  type IDashboardProject,
  type IDashboardProjectPageRequest
} from '@/services/dashboard.project.service'
import DashboardCardEmptyMessageButton, {
  type IDashboardEmptyData
} from '@/components/shared/dashboard/DashboardCardEmptyMessageButton.vue'

onMounted(() => {
  loadDashboardProjects()
})
const projectData = ref<IDashboardProject[]>([])
const page: IDashboardProjectPageRequest = { page: 0, size: 3 }

/**
 * @returns list of projects
 */
const loadDashboardProjects = async () => {
  return await projectService
    .loadDashboardProjects(page)
    .then((response) => {
      projectData.value = response
      return projectData.value
    })
    .catch((error) => {
      throw error
    })
}

const emptyData: IDashboardEmptyData = {
  message: 'You do not have any projects yet.'
}
</script>

<template>
  <DashboardCard title="My Projects" :link="{ name: 'SEE MORE', url: '/dashboard/project' }">
    <div v-if="projectData.length < 1">
      <DashboardCardEmptyMessageButton :empty-data="emptyData" />
    </div>
    <ProjectList v-else :projectData="projectData" />
  </DashboardCard>
</template>
