<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import editorService from '@/services/ide/editor.service'
import projectTreeService from '@/services/ide/projectTree.service'
import { useIdeStore } from '@/stores/ide.store'
import LogoComp from '@/components/utils/LogoComp.vue'

// TODO: Combine this and HeadComp when time allows
const route = useRoute()
const ideStore = useIdeStore()

const routeUrl = computed(() => {
  return route.path
})
const type = computed(() => {
  return ideStore.isTerminal ? 'Terminal' : 'IDE'
})
const langDisplayName = computed(() => {
  return ideStore.ideMeta?.langDisplayName
})
const isCompileLang = computed(() => {
  return ideStore.ideMeta?.isCompile || ideStore.ideMeta?.language === 'java'
})
const isMultiFile = computed(() => {
  return ideStore.ideMeta?.isAdvanced
})
const isShareNotFound = computed(() => {
  return ideStore.shareNotFound
})
const isHtml = computed(() => {
  return ideStore.isHtml
})
const toogleFullScreenIcon = computed(() => {
  return ideStore.isFullScreen ? 'fa-compress' : 'fa-expand'
})

/**
 * Toggles the full screen
 */
const toggleFullScreen = async () => {
  ideStore.setFullScreen(!ideStore.isFullScreen)
  if (ideStore.isAdvanced) {
    projectTreeService.expandProjectTree()
    if (ideStore.isFullScreen) {
      // ideStore.ideSplit.setSizes([25, 75])
    } else {
      // ideStore.ideSplit.setSizes([0, 100])
    }
  }
  await new Promise((resolve) => setTimeout(resolve, 100))
  editorService.resizeCodeEditor()
  editorService.resizeOutputEditor()
}
</script>

<template>
  <div v-if="!isShareNotFound" class="flex h-full w-full items-center justify-between px-5">
    <LogoComp class="w-[120px] justify-center justify-self-start print:flex" />
    <h1 class="text-large w-[80%] text-center md:leading-tight">
      <span>Online</span> {{ langDisplayName }} <span v-if="isCompileLang">Compiler </span
      ><span> {{ type }}</span>
      <span class="p-normal text-center" v-if="isMultiFile">(Advanced IDE)</span>
    </h1>
    <p class="p-normal link-secondary hidden text-center print:block">
      https://www.jdoodle.com{{ routeUrl }}
    </p>
    <!-- Hide messages for fullscreen header? -->
    <div class="p-small px-2 text-center print:hidden">
      <!-- <div v-if="showLinkToMultiFileLang">
          <p>
            For Multiple Files, Custom Library and File Read/Write, use our new -
            <Router-link :to="ideLinkUrl" class="link-secondary"
              >Advanced {{ langDisplayName }} IDE</Router-link
            >
          </p>
        </div>
        <div v-if="showLinkToSingleFileLang">
          <p>
            For simple single file programs and faster execution, use -
            <Router-link :to="ideLinkUrl" class="link-secondary"
              >Basic {{ langDisplayName }} IDE</Router-link
            >
          </p>
        </div> -->
    </div>
    <button
      v-if="!isHtml"
      class="btn-secondary w-fit min-w-fit rounded-md px-2 py-1 text-sm"
      @click="toggleFullScreen"
    >
      <FontAwesomeIcon :icon="toogleFullScreenIcon" class="h-3 w-3" />
    </button>
  </div>
</template>
