<script setup lang="ts">
import school1Img from '@/assets/images/educationInstitution/schools/school1.png'
import school2Img from '@/assets/images/educationInstitution/schools/school2.png'
import school3Img from '@/assets/images/educationInstitution/schools/school3.png'
import school4Img from '@/assets/images/educationInstitution/schools/school4.png'
import school5Img from '@/assets/images/educationInstitution/schools/school5.png'
import school6Img from '@/assets/images/educationInstitution/schools/school6.png'
import school7Img from '@/assets/images/educationInstitution/schools/school7.png'
import school8Img from '@/assets/images/educationInstitution/schools/school8.png'
import school9Img from '@/assets/images/educationInstitution/schools/school9.png'
import { computed, onMounted, ref, onUnmounted } from 'vue'

const images = [
  school1Img,
  school2Img,
  school3Img,
  school4Img,
  school5Img,
  school6Img,
  school7Img,
  school8Img,
  school9Img
]
const currentIndex = ref(0)
const itemsPerRow = ref(5)
const rotationInterval = ref(0)

const visibleImages: any = computed(() => {
  const start = currentIndex.value
  const end = start + itemsPerRow.value
  return images.concat(images).slice(start, end)
})

/**
 * start image rotation on mount
 */
const startRotation = () => {
  rotationInterval.value = window.setInterval(() => {
    nextImage()
  }, 3000)
}
/**
 * reset image rotation when user clicks nest or prev button
 */
const resetRotation = () => {
  clearInterval(rotationInterval.value)
  startRotation()
}
/**
 * show next image
 */
const nextImage = () => {
  currentIndex.value = (currentIndex.value + 1) % images.length
  resetRotation()
}

onMounted(() => {
  startRotation()
})

onUnmounted(() => {
  clearInterval(rotationInterval.value)
})
</script>

<template>
  <section class="mt-20 flex flex-col items-center justify-center px-4">
    <div class="container">
      <div
        class="mx-2 w-full flex-col items-center justify-center gap-16 text-center lg:flex-row lg:items-center lg:gap-0"
      >
        <h2
          class="mb-8 text-center font-medium leading-6 text-background-secondary-dark dark:text-background-secondary"
        >
          Trusted by the top education providers in this industry
        </h2>
        <div class="flex flex-col items-center justify-center">
          <div class="relative">
            <div class="flex overflow-x-hidden">
              <div
                class="mr-2.5 max-h-[150px] flex-[0_0_calc(100%_/_5.25)] ease-in-out motion-reduce:transition-none"
                v-for="(image, index) in visibleImages"
                :key="index"
              >
                <img
                  class="h-full w-full bg-background-secondary object-contain"
                  :src="image"
                  alt="Carousel Image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
