<script lang="ts" setup>
import { type PropType, ref } from 'vue'
import { TEACHMODALHS } from '@/utils/models'
import { type IUser } from '@/components/guru/interface/IUser'
import { GURU_USER_TYPE, TEACH_SECTION } from '@/components/guru/enums/teach.enum'
import SearchAndAddUserModal from '@/components/guru/modals/SearchAndAddUserModal.vue'
import type { IUserInStudentGroup } from '@/components/guru/interface/IUserInStudentGroup'
import TeacherRemoveModal from '@/components/guru/modals/confirmationModals/TeacherRemoveModal.vue'
import DashboardCardEmptyMessageButton from '@/components/shared/dashboard/DashboardCardEmptyMessageButton.vue'

const props = defineProps({
  assignmentId: {
    type: Number,
    require: true
  },
  studentGroupId: {
    type: Number,
    require: false
  },
  instituteCode: {
    type: String,
    require: true
  },
  staff: {
    type: Array<IUser | IUserInStudentGroup>,
    require: true
  },
  section: {
    type: String as PropType<TEACH_SECTION>,
    require: true
  }
})

/**
 * @returns boolean
 */
const showRemoveButton = () => {
  if (props.section == TEACH_SECTION.ASSIGNMENT) {
    return true
  }

  if (props.section == TEACH_SECTION.STUDENT_GROUP && props.staff && props.staff?.length > 1) {
    return true
  }

  return false
}

const currentUserEmail = ref<string>('')

/**
 *
 * @param user to remove
 */
const handleRemoveStaff = async (user: IUser | IUserInStudentGroup) => {
  currentUserEmail.value = user.email
}
</script>

<template>
  <div>
    <TeacherRemoveModal
      :instituteCode="props.instituteCode!"
      :insAssignmentId="props.assignmentId!"
      :email="currentUserEmail"
      :studentGroupId="props.studentGroupId"
      :section="props.section"
    />
    <SearchAndAddUserModal
      :userType="
        props.section == TEACH_SECTION.ASSIGNMENT ? GURU_USER_TYPE.TEACHER : GURU_USER_TYPE.STAFF
      "
      :section="props.section"
      :existingUsers="props.staff"
      :instituteCode="props.instituteCode"
      :assignmentId="props.assignmentId"
      :studentGroupId="props.studentGroupId"
    />
    <div class="m-auto flex flex-col">
      <DashboardCardEmptyMessageButton
        v-if="!props.staff || props.staff?.length < 1"
        :empty-data="{ message: 'No teachers added yet' }"
      />
      <div
        class="panel-block has-text-centered is-paddingless"
        v-else-if="props.staff && props.staff?.length > 0"
      >
        <table class="w-full table-auto border-separate border-spacing-y-3">
          <thead class="">
            <tr class="text-sm">
              <th class="text-start">Name</th>
              <th class="text-start">Email</th>
              <th class="text-start"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="teacher in props.staff" :key="teacher.email" class="section-primary">
              <td class="rounded-s-lg p-3 text-sm">
                {{ teacher.firstName }} {{ teacher.lastName }}
              </td>
              <td class="break-all p-3 text-sm">{{ teacher.email }}</td>
              <td class="rounded-e-lg p-3 text-sm">
                <button
                  :data-hs-overlay="`#${TEACHMODALHS.REMOVE_USER_STAFF_OR_TEACHER_CONFIRMATION}`"
                  @click="handleRemoveStaff(teacher)"
                  v-if="showRemoveButton()"
                >
                  <FontAwesomeIcon icon="fa-trash" class="error"></FontAwesomeIcon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
