<script setup lang="ts">
import type { ISixItems } from '@/components/shared/SixItemsPanel.vue'
import FrequentQuestions from '@/components/shared/FrequentQuestions.vue'

const faqData: ISixItems[] = [
  {
    icon: 'fa-scissors',
    heading: 'Any Shortcuts?',
    body: 'Ctrl+Space or Alt+Space for Auto Complete and Ctrl+Enter to Execute.'
  },
  {
    icon: 'fa-folder-closed',
    heading: 'What libraries are supported?',
    body: 'JDoodle fully supports all Maven repository jars for Java and standard libraries for other languages. If you have specific library requests, please let us know at hello@jdoodle.com.'
  },
  {
    icon: 'fa-hand',
    heading: 'Any restrictions?',
    body: 'Network operations are not supported at this stage.'
  },
  {
    icon: 'fa-clock',
    heading: 'Any Execution time restriction?',
    body: "Yes, there is a time restriction, but it's not fixed; it may vary based on factors like system load. If you ever find that the allotted time is insufficient, please don't hesitate to reach out to us."
  },
  {
    icon: 'fa-share-nodes',
    heading: 'How to share my code?',
    body: 'You can share your code in two ways:<ul><ol class="flex mb-2">1. <span class="ml-2">Editable Share: With this managed option, you have full control over what you share. You can edit the shared file later and even stop sharing when needed.</span></ol><ol class="flex">2. <span class="ml-2">Instant Share: This creates a public copy of your current code in the IDE. Keep in mind that this share can\'t be edited or unshared once it\'s created.</span></ol>'
  },
  {
    icon: 'fa-users',
    heading: 'How to do Peer Programming or Online interview?',
    body: 'Click the "Collaborate" button and share the provided URL with others (supports multiple users simultaneously). Enjoy real-time collaboration and observe each other\'s actions.'
  }
]
</script>

<template>
  <FrequentQuestions :faqData="faqData" />
</template>
