<script setup lang="ts">
import BannerCenter, { type IBannerCenterData } from '@/components/shared/BannerCenter.vue'
import Button_login_then_redirect from '@/components/shared/subscription/Button_login_then_redirect.vue'
import type { IActionButton } from '@/components/shared/PricingCard.vue'
import { SUBSCRIPTION_PRODUCT } from '@/components/shared/subscription/enum/subscription.enum'

const bannerData: IBannerCenterData = {
  title: 'Take a look our documentation for step by step integration guide.'
}

const button: IActionButton = {
  buttonText: 'Find Out',
  href: `https://docs.jdoodle.com/assignments`,
  product: SUBSCRIPTION_PRODUCT.TEACH
}
</script>

<template>
  <BannerCenter :bannerData="bannerData">
    <Button_login_then_redirect :buttonData="button" />
  </BannerCenter>
</template>
