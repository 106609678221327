import router from '@/router'
import { useAuthStore } from '@/stores/auth.store'
import { useSubscribeStore } from '@/stores/subscribe.store'
import axios, { HttpStatusCode } from 'axios'

declare global {
  interface Window {
    google: any
  }
}
export interface IinitAuthResponse {
  google_client_id: string
  isUserLoggedIn: boolean
  kurukkuKuri: string
  robotChecked: boolean
  username?: string
  email?: string
  notificationCount?: number
  isPremium: boolean
}
/**
 * this function runs every time the app is loaded
 */
const initGuestAuth = async () => {
  await axios
    .post('/api/doodle/init')
    .then((response: { data: IinitAuthResponse }) => {
      useAuthStore().setKurukkuKuri(response.data.kurukkuKuri)
      if (response.data.isUserLoggedIn || response.data.robotChecked) {
        useAuthStore().robotCheckDone()
      }
      useAuthStore().setAppInitiated(true)
    })
    .catch(() => {
      useAuthStore().onDisconnected()
    })
}
/**
 * this function runs every time the app is loaded
 */
const initAuth = async () => {
  await axios
    .post('/api/doodle/init')
    .then((response: { data: IinitAuthResponse }) => {
      useSubscribeStore().storePlanAtLogin(response.data)
      useAuthStore().setGoogleClientId(response.data.google_client_id)
      if (!response.data.isUserLoggedIn) {
        googleOneTapSignin()
      }
      useAuthStore().initAuth(response.data)
    })
    .catch(() => {
      useAuthStore().onDisconnected()
    })
}

export enum SOCIAL_LOGIN_SERVICE {
  GOOGLE = 'Google',
  MICROSOFT = 'Microsoft'
}
/**
 * @description This function is used to authenticate the user using social login
 * @param service The social login service to use
 */
const socialAuthentication = async (service: SOCIAL_LOGIN_SERVICE) => {
  let url = ''
  switch (service) {
    case SOCIAL_LOGIN_SERVICE.GOOGLE:
      // url = '/api/oauth/google?path='
      url = '/api/oauth/authorization/google?path='
      break
    case SOCIAL_LOGIN_SERVICE.MICROSOFT:
      // url = '/api/oauth/ms?path='
      url = '/api/oauth/authorization/azure?path='
      break
    default:
      break
  }
  if (url) {
    const encodeURIComponent = window.encodeURIComponent(window.location.href)
    window.location.href = url + encodeURIComponent
  }
}

export interface IgoogleOneTapOptions {
  client_id: string
  auto_select: boolean
  cancel_on_tap_outside: boolean
  context: string
}

export interface IgoogleOneTapResponse {
  clientId: string
  client_id: string
  credential: string
  selected_by: string
}
/**
 * this function runs to suggest google one tap
 * @returns the googleOneTap function call
 */
const googleOneTapSignin = () => {
  const googleOptions: IgoogleOneTapOptions = {
    client_id: useAuthStore().googleClientId,
    // client_id: '815410704344-5akkg2k9imfp0pa8lrsmqjgkggjf8pj5.apps.googleusercontent.com', // required
    auto_select: false, // optional
    cancel_on_tap_outside: false, // optional
    context: 'signin' // optional
  }

  /**
   * this function handles the response from the google one tap api
   * @param response from google
   */
  const handleOneTapResponse = (response: IgoogleOneTapResponse) => {
    //send token to backend
    const OneTapCredential: string = response.credential

    axios
      .post('/api/login/googleonetap', {
        credential: OneTapCredential
      })
      .then((response) => {
        if (response.status === HttpStatusCode.Ok) window.location.reload()
      })
      .catch((error) => {
        throw error
      })
  }

  /**
   * this function runs to suggest google one tap
   * @param googleOptions options for googleoneTap
   * @param handleOneTapResponse callback to handle response from google one tap service
   */
  const googleOneTap = (googleOptions: IgoogleOneTapOptions, handleOneTapResponse: Function) => {
    const contextValue = ['signin', 'signup', 'use'].includes(googleOptions.context)
      ? googleOptions.context
      : 'signin'
    const googleScript = document.createElement('script')

    googleScript.setAttribute('src', 'https://accounts.google.com/gsi/client')
    document.head.appendChild(googleScript)
    /**
     * googleScript.onload instead of window.onload because window.onload can be triggered by other libraries and or just missed while googleScript.onload is more consistent
     */
    googleScript.onload = () => {
      if (googleOptions.client_id) {
        window.google.accounts.id.initialize({
          client_id: googleOptions.client_id,
          auto_select: googleOptions.auto_select,
          cancel_on_tap_outside: googleOptions.cancel_on_tap_outside,
          context: contextValue,
          callback: handleOneTapResponse
        })
        window.google.accounts.id.prompt()
      } else {
        console.error('client_id is missing')
      }
    }
  }
  return googleOneTap(googleOptions, handleOneTapResponse)
}

export interface IloginRequest {
  username: string
  password: string
  recaptcha: string
}
/**
 * @param loginRequest The login request object containing user credentials
 * @returns The response object containing the user details
 */
const login = async (loginRequest: IloginRequest) => {
  return await axios
    .post('/api/login/authenticate', loginRequest)
    .then((response) => {
      if (response.data.error) throw response
      return response
    })
    .catch((error) => {
      throw error
    })
}

export interface IRegisterRequest {
  username: string
  displayName: string
  newPassword: string
  retypePassword: string
  recaptcha: string
}
/**
 * @param registerRequest The register request object containing user credentials
 * @returns The response object containing the user details
 */
const register = async (registerRequest: IRegisterRequest) => {
  return await axios
    .post('/api/register/register', registerRequest)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @param verificationCode The verification code sent to the user's email
 * @description This function is used to verify the user's registration
 * @returns The response object containing the user details
 */
const verifyRegistration = async (verificationCode: string) => {
  if (useAuthStore().isLogedIn) {
    await logout()
    await initAuth()
  }
  return await axios
    .post('/api/register/verifyRegistration', {
      t: verificationCode
    })
    .then((response) => {
      useAuthStore().login(response.data.username)
      return response
    })
    .catch((error) => {
      throw error
    })
}

export interface IForgotPasswordRequest {
  email: string
  gc: string
}
/**
 * @param forgotPasswordRequest The forgot password request object containing user credentials
 * @description This function is used to send the user a password reset link
 * @returns The response object containing the user details
 */
const forgotPassword = async (forgotPasswordRequest: IForgotPasswordRequest) => {
  return await axios
    .post('/api/register/forgotPassword', forgotPasswordRequest)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @param verificationCode The verification code sent to the user's email
 * @description This function is used to verify the user's password reset token
 * @returns The response object containing the user details
 */
const verifyForgotPasswordToken = async (verificationCode: string) => {
  if (useAuthStore().isLogedIn) {
    await logout()
    await initAuth()
  }
  return await axios
    .post('/api/register/resetPasswordCodeCheck', {
      t: verificationCode
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export interface IRestPasswordRequest {
  newPassword: string
  retypeNewPassword: string
  t: string
}
/**
 * @param restPasswordRequest The reset password request object containing user credentials
 * @description This function is used to reset the user's password
 * @returns The response object containing the user details
 */
const resetForgotPassword = async (restPasswordRequest: IRestPasswordRequest) => {
  return await axios
    .post('/api/register/resetPassword', restPasswordRequest)
    .then((response) => {
      useAuthStore().login(response.data.username)
      return response
    })
    .catch((error) => {
      throw error
    })
}

export interface IChangePasswordRequest {
  oldPassword: string
  newPassword: string
  retypeNewPassword: string
}

/**
 * @description This function is used to change the user's password
 * @param changePasswordRequest  The change password request object containing user credentials
 * @returns The response object containing the user details
 */
const changePassword = async (changePasswordRequest: IChangePasswordRequest) => {
  return await axios
    .post('/api/register/changePassword', changePasswordRequest)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export interface IChangeUsernameRequest {
  displayName: string
}
/**
 * @description This function is used to change the user's username
 * @param changeUsernameRequest  The change username request object containing user credentials
 * @returns The response object containing the user details
 */
const changeUsername = async (changeUsernameRequest: IChangeUsernameRequest) => {
  return await axios
    .post('/api/register/changeDisplayName', changeUsernameRequest)
    .then((response) => {
      useAuthStore().login(changeUsernameRequest.displayName)
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @description This function is used to log the user out
 * @returns The response object containing the user details
 */
const logout = async () => {
  return await axios
    .post('/api/logout/logoff')
    .then(async (response) => {
      useAuthStore().logout()
      router.push('/').then(() => {
        router.go(0)
      })
      return response
    })
    .catch((error) => {
      throw error
    })
}

export default {
  initGuestAuth,
  initAuth,
  socialAuthentication,
  login,
  register,
  verifyForgotPasswordToken,
  verifyRegistration,
  forgotPassword,
  resetForgotPassword,
  changePassword,
  changeUsername,
  logout,
  googleOneTapSignin
}
