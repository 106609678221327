<script setup lang="ts">
import type { ICarouselItem } from '@/components/shared/CardCarousel.vue'
import CardCarousel from '@/components/shared/CardCarousel.vue'
import { businessReviews, customersReviewData } from '@/utils/sharedData/userReviews'

const userReviews: ICarouselItem[] = [...customersReviewData, ...businessReviews]
const title = 'What our users say about us'
</script>

<template>
  <CardCarousel :reviewData="userReviews" :title="title" />
</template>
