import { useDashboardPluginStore } from '@/stores/dashboard.plugin.store'
import { FUNCTIONALSDATA } from '@/utils/customPlugin'
import axios from 'axios'
import dashboardIdeService from './dashboard.ide.service'

/**
 * Clone plugin
 * @param pluginId plugin id
 * @returns axios response
 */
const clonePlugin = async (pluginId: string) => {
  const requestData = {
    pluginId: pluginId
  }
  return await axios
    .post('/api/plugin/clonePlugin', requestData)
    .then((response) => {
      return response
    })
    .catch((error: { response: { message: string } }) => {
      throw error
    })
}
/**
 * Create plugin
 * @param pluginName plugin name
 * @param pluginDescription plugin description
 * @returns axios response
 */
const createPlugin = async (pluginName: string, pluginDescription: string) => {
  const requestData = {
    name: pluginName,
    description: pluginDescription,
    pluginType: 'BASIC'
  }
  return await axios
    .post('/api/plugin/createPlugin', requestData)
    .then((response) => {
      return response
    })
    .catch((error: { response: { message: string } }) => {
      throw error
    })
}
/**
 * Get plugin list
 * @returns axios response
 */
const getPluginList = async () => {
  const requestData = {
    page: 0,
    size: 50
  }
  return await axios
    .post('/api/plugin/plugins', requestData)
    .then((response) => {
      useDashboardPluginStore().setPluginList(response.data)
      return response
    })
    .catch((error: { response: { message: string } }) => {
      throw error
    })
}
/**
 * Get default embeded code
 * @returns default embeded code
 */
const getDefaultEmbededCode = () => {
  return `
    <body>
        <div data-pym-src="https://www.jdoodle.com/plugin" data-language="java"  data-version-index="4" data-libs="mavenlib1, mavenlib2">
            Optional default code goes here
        </div>
        <script src="https://www.jdoodle.com/assets/jdoodle-pym.min.js" type="text/javascript"> </script>
    </body>
    `
}
/**
 * Set embeded code
 * @param plugin plugin
 */
const setEmbededCode = (plugin: any) => {
  const { name, clientId, pluginKey, pluginConfig } = plugin

  const { features } = pluginConfig
  const featuresData = JSON.parse(features)

  let getCode: boolean = false
  let setCode: boolean = false
  let execute: boolean = false
  let getFile: boolean = false

  if (featuresData && featuresData.functionals && featuresData.functional !== null) {
    const functionalsData = JSON.parse(featuresData.functionals)
    if (functionalsData && functionalsData.length > 0) {
      functionalsData.forEach((element: any) => {
        if (element.name === FUNCTIONALSDATA[0].name) {
          getCode = true
        }
        if (element.name === FUNCTIONALSDATA[1].name) {
          setCode = true
        }
        if (element.name === FUNCTIONALSDATA[2].name) {
          execute = true
        }
        if (element.name === FUNCTIONALSDATA[3].name) {
          getFile = true
        }
      })
    }
  }
  const getCodeScript1 = `<button onclick="getCode()">Get Code</button>`
  const getCodeScript2 = `<script>
        function getCode() {
          pym.autoInitInstances[0].sendMessage('getCode')
        }
        window.addEventListener("message", (event) => {
          if (event.origin === "https://www.jdoodle.com" && event.data.script) {
            console.log(event.data.script)
          }
        });
      </script>`
  const setCodeScript1 = `<input id="code" type="text" value="">`
  const setCodeScript2 = `<button onclick="setCode()">Set Code</button>`
  const setCodeScript3 = `<script>
        function setCode() {
          const code = document.getElementById('code').value
          pym.autoInitInstances[0].sendMessage('setCode', code)
        }
      </script>`

  const executeScript1 = `<button onclick="execute()">Execute Code</button>`
  const executeScript2 = `<script>
        function execute() {
          pym.autoInitInstances[0].sendMessage('execute')
        }
      </script>`

  const getFileScript1 = `data-has-files="true"`
  const getFileScript2 = `<div data-type="file" data-file-name="testdata.csv">1,Jhon,Doe,1,street,Canberra,Australia,2601 2,Jane,Doe,2,street,Canberra,Australia,2601</div>`
  const getFileScript3 = `<div data-type="script">
          <xmp>
            <!-- your code goes here -->
          </xmp>
        </div>`
  // dynamic script
  const script = `
    <body>
      <div data-pym-src="https://www.jdoodle.com/plugin/v0/${clientId}/${pluginKey}" ${
    getFile ? getFileScript1 : ''
  }>
        ${getFile ? getFileScript2 : ''}
        ${getFile ? getFileScript3 : ''}
      </div>
      ${getCode ? getCodeScript1 : ''}
      ${setCode ? setCodeScript1 : ''}
      ${setCode ? setCodeScript2 : ''}
      ${execute ? executeScript1 : ''}
      <script src="https://www.jdoodle.com/assets/jdoodle-pym.min.js" type="text/javascript"></script>
      ${getCode ? getCodeScript2 : ''}
      ${setCode ? setCodeScript3 : ''}
      ${execute ? executeScript2 : ''}
    </body>
    `
  //  find empty line and remove it
  const cleanScript = script.replace(/^\s*[\r\n]/gm, '')
  dashboardIdeService.setScript(cleanScript)
  const title = `Embeded Code for ${name}`
  useDashboardPluginStore().setTitle(title)
}
export default {
  createPlugin,
  getPluginList,
  clonePlugin,
  getDefaultEmbededCode,
  setEmbededCode
}
