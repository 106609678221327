<script setup lang="ts">
import ModelLayout from '@/layouts/ModelLayout.vue'
import { TEACHMODALHS } from '@/utils/models'
import { type PropType, onMounted, watch, ref } from 'vue'
import { addOrRemoveUserFromStudentGroup } from '@/services/teach.service'
import { HttpStatusCode } from 'axios'
import {
  GURU_USER_REQUEST,
  GURU_USER_TYPE,
  STUDENT_GROUP_ACTIONS,
  TEACH_SECTION
} from '@/components/guru/enums/teach.enum'
import router from '@/router'
import { addOrRemoveUserFromAssignment, getUsersInInstitute } from '@/services/teach.service'
import type { IUser } from '@/components/guru/interface/IUser'
import { type IStudentInAssignment } from '@/components/guru/interface/IStudentInAssignment'
import { type IUserInStudentGroup } from '@/components/guru/interface/IUserInStudentGroup'
import {
  type IAssignment_Users_AddRemoveRequest,
  type IStudentGroup_AddRemoveRequest
} from '@/components/guru/interface/requests.interface'
import { useAuthStore } from '@/stores/auth.store'

const authStore = useAuthStore()

const props = defineProps({
  userType: {
    type: String as PropType<GURU_USER_TYPE>,
    require: true
  },
  existingUsers: {
    type: Array<IUser | IStudentInAssignment | IUserInStudentGroup>,
    require: true
  },
  assignmentId: {
    type: Number,
    require: true
  },
  instituteCode: {
    type: String,
    require: true
  },
  section: {
    type: String as PropType<TEACH_SECTION>,
    require: true
  },
  studentGroupId: {
    type: Number,
    require: true
  }
})

/**
 * @returns modal title
 * @param currentType for the type user
 */
const getModalTitle = (currentType: GURU_USER_TYPE | undefined) => {
  if (props.section == TEACH_SECTION.ASSIGNMENT && currentType === GURU_USER_TYPE.STUDENT) {
    return 'Add Student to Assignment'
  }

  if (props.section == TEACH_SECTION.ASSIGNMENT && currentType === GURU_USER_TYPE.TEACHER) {
    return 'Add Teacher to Assignment'
  }

  if (props.section == TEACH_SECTION.STUDENT_GROUP && currentType === GURU_USER_TYPE.STAFF) {
    return 'Add Admin to Student Group'
  }

  if (props.section == TEACH_SECTION.STUDENT_GROUP && currentType === GURU_USER_TYPE.STUDENT) {
    return 'Add Student to Student Group'
  }

  return ''
}

/**
 * @returns modal type
 * @param currentType type of current user, student is teacher or student
 */
const getHSModalString = (currentType: GURU_USER_TYPE | undefined) => {
  if (currentType === GURU_USER_TYPE.STUDENT) {
    return TEACHMODALHS.ADD_USER_STUDENT
  }

  if (currentType === GURU_USER_TYPE.STAFF || currentType === GURU_USER_TYPE.TEACHER) {
    return TEACHMODALHS.ADD_USER_STAFF_OR_TEACHER
  }

  return ''
}

//Static data
const modalTitle = ref(getModalTitle(props.userType))
const noMatchFound = 'No Match Found.'

//Refs
const usersData = ref([] as IUser[])
const filteredUsers = ref([] as IUser[])
const usersJustAdded = ref([] as IUser[])
const searchInput = ref('')

onMounted(async () => {
  if (props.userType === GURU_USER_TYPE.TEACHER) {
    const res = await getUsersInInstitute(props.instituteCode!, GURU_USER_TYPE.TEACHER)

    usersData.value = res.filter((user: IUser) => user.email != authStore.userEmail)
  }

  if (props.userType === GURU_USER_TYPE.STUDENT) {
    const res = await getUsersInInstitute(props.instituteCode!, GURU_USER_TYPE.STUDENT_ACTIVE)

    usersData.value = res
  }

  if (props.userType === GURU_USER_TYPE.STAFF) {
    const res = await getUsersInInstitute(props.instituteCode!, GURU_USER_TYPE.STAFF_ACTIVE)

    usersData.value = res
  }

  filteredUsers.value = filterUsers('')
})

watch(
  () => props.userType,
  async (currentType) => {
    if (currentType === GURU_USER_TYPE.TEACHER) {
      const res = await getUsersInInstitute(props.instituteCode!, GURU_USER_TYPE.TEACHER)

      usersData.value = res
    }

    if (currentType === GURU_USER_TYPE.STUDENT) {
      const res = await getUsersInInstitute(props.instituteCode!, GURU_USER_TYPE.STUDENT)

      usersData.value = res
    }
    modalTitle.value = getModalTitle(currentType)
  }
)

watch(
  () => props.existingUsers,
  () => {
    filteredUsers.value = filterUsers(searchInput.value)
  }
)

watch(
  () => searchInput.value,
  (searchTerm) => {
    filteredUsers.value = filterUsers(searchTerm)
  }
)

/**
 * @param searchTerm String searchTerm
 * @returns Array of users, filtered from existing and just added
 */
const filterUsers = (searchTerm: string) => {
  return [
    ...usersData.value.filter((user: IUser) => {
      // Don't show users already added
      const isAlreadyInProp = props.existingUsers?.find(
        (existingUser: IUser | IStudentInAssignment | IUserInStudentGroup) =>
          existingUser.email == user.email
      )
      const isInJustAdded = usersJustAdded.value.find(
        (existingUser: IUser) => existingUser.email == user.email
      )

      if (!isAlreadyInProp && !isInJustAdded) {
        if (searchTerm !== '') {
          if (
            user.email.toLowerCase().includes(searchTerm) ||
            user.firstName.toLowerCase().includes(searchTerm) ||
            user.lastName.toLowerCase().includes(searchTerm)
          ) {
            return user
          }
        } else {
          return user
        }
      }
    })
  ]
}

/**
 * @returns userType matched value from enums
 */
const getAssignmentUserType = () => {
  if (props.userType == GURU_USER_TYPE.TEACHER) {
    return GURU_USER_TYPE.STAFF_ACTIVE
  }

  if (props.userType == GURU_USER_TYPE.STUDENT) {
    return GURU_USER_TYPE.STUDENT
  }

  return undefined
}

/**
 * @returns user_type
 */
const getStudentGroupUserType = () => {
  if (props.userType == GURU_USER_TYPE.STUDENT) {
    return GURU_USER_TYPE.STUDENT
  }

  if (props.userType == GURU_USER_TYPE.STAFF) {
    return GURU_USER_TYPE.STAFF
  }

  return undefined
}

/**
 *
 * @param user user to be added
 */
const handleAddUser = async (user: IUser) => {
  let res = 0
  if (props.section == TEACH_SECTION.ASSIGNMENT) {
    const reqObject: IAssignment_Users_AddRemoveRequest = {
      email: user.email,
      instituteCode: props.instituteCode!,
      insAssignmentId: props.assignmentId!,
      updateType: GURU_USER_REQUEST.ADD,
      participantType: getAssignmentUserType()
    }

    res = await addOrRemoveUserFromAssignment(reqObject)
  }

  //For Student Group Students
  if (props.section == TEACH_SECTION.STUDENT_GROUP) {
    const reqObject: IStudentGroup_AddRemoveRequest = {
      email: user.email,
      instituteCode: props.instituteCode!,
      insStudentGroupId: props.studentGroupId!,
      mode: STUDENT_GROUP_ACTIONS.VIEW,
      type: getStudentGroupUserType(),
      subType: STUDENT_GROUP_ACTIONS.ADD
    }

    res = await addOrRemoveUserFromStudentGroup(reqObject)
  }

  if (res == HttpStatusCode.Ok) {
    usersJustAdded.value.push(user)

    filteredUsers.value = filterUsers('')
  }
}

/**
 *
 */
const refreshData = () => {
  router.go(0)
}
</script>

<template>
  <ModelLayout :title="modalTitle" :hs="getHSModalString(props.userType)" :loadDataWhenClose="true">
    <!-- form -->
    <div class="relative flex">
      <input
        v-model="searchInput"
        class="section-primary p-small block w-full rounded-md border px-4 py-2"
        :placeholder="'Search User'"
      />
      <FontAwesomeIcon icon="fa-search" class="absolute right-4 top-3" />
    </div>
    <p class="my-5 text-center text-sm" v-if="filteredUsers.length < 1">
      {{ noMatchFound }}
    </p>

    <table class="my-5 w-full table-auto border-separate border-spacing-y-3">
      <tr v-for="user in filteredUsers" :key="user.email" class="w-full">
        <td class="section-tertiary rounded-s-md px-4 py-2">
          {{ `${user.firstName} ${user.lastName} (${user.email})` }}
        </td>
        <td class="section-tertiary flex justify-center rounded-e-md px-4 py-2">
          <button @click="handleAddUser(user)" class="btn-primary rounded-md px-2 py-1">Add</button>
        </td>
      </tr>
    </table>

    <button class="btn-secondary btn-rounded-md" @click="refreshData" data-hs-overlay-close>
      Close
    </button>
  </ModelLayout>
</template>
