import { useApiStore } from '@/stores/api.store'
import { useAuthStore } from '@/stores/auth.store'
import axios, { type InternalAxiosRequestConfig } from 'axios'
import { delay } from 'lodash'
import querystring from 'querystring'
import { ref } from 'vue'

// Axios configuration
axios.defaults.timeout = 40000

const flagToClearModel = ref<boolean>(false)
const SERVER_ERROR =
  'Unable to process your request. Please try again, or contact JDoodle Support at hello@jdoodle.com.'
const SESSION_EXPIRED_SERVER_ERROR = 'Your session has expired. Please login again.'
const DEFAULT_HTTP_ERROR = 'Something went wrong. Please try again later.'

const EMAIL_ALREADY_REGISTERED = 'This email is already registered'

/**
 * @description This function is used to initialize the axios interceptors to have kurukku kuri in the header
 * @param config - The config
 * @param count - The count
 * @returns The config
 */
const initPostRequestInterceptor = async (
  config: InternalAxiosRequestConfig<any>,
  count: number = 0
): Promise<InternalAxiosRequestConfig<any>> => {
  if (config.url === '/api/doodle/init') {
    return config
  }
  if (!useAuthStore().isInitiated) {
    if (count > 20) {
      return config
    } else {
      await new Promise((resolve) => setTimeout(resolve, 600))
      return initPostRequestInterceptor(config, count + 1)
    }
  } else {
    config.headers['Kurukku-Kuri'] = useAuthStore().kurukkuKuri
    return config
  }
}
/**
 * @description This function is used to initialize the axios interceptors to have kurukku kuri in the header
 */
export const initGuestAxios = () => {
  axios.interceptors.request.use(async (config) => {
    if (config.url === '/api/login/authenticate') {
      config.data = querystring.stringify(config.data)
    }
    return await initPostRequestInterceptor(config)
  })
}
/**
 * @description This function is used to initialize the axios interceptors
 */
export const initAxios = () => {
  axios.interceptors.request.use(
    async function (config) {
      if (import.meta.env.DEV) {
        config.headers['x-forwarded-for'] = '127.0.0.1'
      }

      if (config.url === '/api/login/authenticate' || config.url === '/logout/impersonate') {
        config.data = querystring.stringify(config.data)
      }
      return await initPostRequestInterceptor(config)
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    function (response) {
      if (flagToClearModel.value) {
        useApiStore().flagtoHideLoadingIndicatior()
        flagToClearModel.value = false
      }
      return response
    },
    function (error) {
      if (flagToClearModel.value) {
        flagToClearModel.value = false
      }
      if (error.response.status >= 500) {
        useApiStore().setGlobalMsg(SERVER_ERROR)
      } else if (error.response.status === 404) {
        if (
          error.config.url == '/api/compiler-api/paymentDetail' ||
          error.config.url == '/api/bundle/paymentDetail'
        ) {
          // ignore payment 402 is no stripe detail accounts
          // useApiStore().setGlobalMsg(
          //   'Unable to load your account details. Please contact JDoodle Support for any assistance'
          // )
        } else {
          useApiStore().setGlobalMsg(SERVER_ERROR)
        }
      } else if (
        error.response.status === 401 &&
        error.response.data.message != EMAIL_ALREADY_REGISTERED
      ) {
        useApiStore().setGlobalMsg(SESSION_EXPIRED_SERVER_ERROR)
      } else if (
        error.response.status === 402 &&
        (error.config.url === '/api/compiler-api/paymentMethod' ||
          error.config.url === '/api/bundle/paymentMethod')
      ) {
        // ignore payment 402 is the default response statue code for no stripe detail accounts
        // useApiStore().setGlobalMsg(
        //   'Unable to load your account details. Please contact JDoodle Support for any assistance'
        // )
      } else if (error.response.status === 403 && error.config.url === '/api/admin/comments') {
        // admin 403 to verify whether the user is admin account or not
      } else {
        useApiStore().setGlobalMsg(
          error?.response?.data?.message || error?.message || DEFAULT_HTTP_ERROR
        )
      }

      delay(() => {
        useApiStore().setGlobalMsg('')
      }, 2000)

      return Promise.reject(error)
    }
  )
}
