<script setup lang="ts">
import Logo from '@/components/utils/LogoComp.vue'
import { computed, onMounted, watch } from 'vue'
import { useUtilModelsStore } from '@/stores/util.models.store'
import utilModelsService from '@/services/util.models.service'

const hs = 'hs-confirm'
const utilModelsStore = useUtilModelsStore()

const promise = computed(() => {
  return utilModelsStore.isConfirmPromise
})
const isConfirmPromiseFinished = computed(() => {
  return utilModelsStore.isConfirmPromiseFinish
})
const title = computed(() => {
  return utilModelsStore.isConfirmTitle
})
const subTitle = computed(() => {
  return utilModelsStore.isConfirmSubtitle
})
const returnHS = computed(() => {
  return utilModelsStore.isConfirmReturnHs
})
const yesBtnText = computed(() => {
  return utilModelsStore.isConfirmYesBtnText
})
const noBtnText = computed(() => {
  return utilModelsStore.isConfirmNoBtnText
})
/**
 * Closes the modal and resolves the promise
 */
const yes = () => {
  utilModelsService.confirmeResolve()
  if (returnHS.value) utilModelsService.openModal(returnHS.value)
}
/**
 * Closes the modal and rejects the promise
 */
const no = () => {
  utilModelsService.confirmeReject()
  if (returnHS.value) utilModelsService.openModal(returnHS.value)
}

onMounted(() => {
  watch(promise, (val) => {
    if (!isConfirmPromiseFinished.value && val) {
      utilModelsService.openModal(hs)
    }
  })
})
</script>

<template>
  <div
    :id="hs"
    class="hs-overlay fixed left-0 top-0 z-[60] hidden h-full w-full overflow-y-auto overflow-x-hidden backdrop-blur-lg backdrop-brightness-50 [--overlay-backdrop:static]"
  >
    <div
      class="m-3 mt-0 opacity-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-lg"
    >
      <div class="section-primary flex flex-col rounded-xl shadow-lg">
        <div class="flex items-center justify-start px-4 py-3">
          <Logo :isLink="false" />
        </div>
        <FontAwesomeIcon icon="fa-question-circle" class="rounded-full text-right text-6xl" />
        <div class="p-4 sm:p-7">
          <h1 class="heading-xsmall mb-2 block text-center">
            {{ title }}
          </h1>
          <p v-if="subTitle" class="p-small text-center">
            {{ subTitle }}
          </p>
          <div class="flex w-full gap-2">
            <button
              v-if="yesBtnText"
              class="btn-primary btn-rounded-md"
              :data-hs-overlay="`#${hs}`"
              data-hs-overlay-close
              @click="yes"
            >
              {{ yesBtnText }}
            </button>
            <button
              v-if="noBtnText"
              class="btn-secondary btn-rounded-md"
              :data-hs-overlay="`#${hs}`"
              data-hs-overlay-close
              @click="no"
            >
              {{ noBtnText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
