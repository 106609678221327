<template>
  <div class="view-container">
    <RegisterInstituteModal
      :prefilledInstitute="currentAssociation?.institute"
      :instituteOwn="props.instituteOwn"
      :isAccountOwner="isAccountOwner"
    />
    <router-view
      :guruInitialised="guruInitialised"
      :currentAssociation="currentAssociation"
      :isAccountOwner="isAccountOwner"
      :isTeacher="isTeacher"
      :isInstituteAdmin="isInstituteAdmin"
      :isStudent="isStudent"
    ></router-view>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, type PropType, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import type IAssociation from '@/components/guru/interface/IAssociation'
import { GURU_ROLES } from '@/components/guru/enums/teach.enum'
import RegisterInstituteModal from '@/components/guru/modals/RegisterInstituteModal.vue'
import type IInstituteOwn from '@/components/guru/interface/IInstituteOwn'

const props = defineProps({
  associations: {
    type: Array<IAssociation>,
    require: true
  },
  guruInitialised: {
    type: Boolean,
    require: true
  },
  instituteOwn: {
    type: Object as PropType<IInstituteOwn>,
    required: false
  }
})

const route = useRoute()

/**
 * @returns association
 */
const getCurrentAssociation = () => {
  if (props.guruInitialised) {
    return props.associations?.find(
      (association: IAssociation) =>
        association.institute.code == (route.params.instituteCode as string)
    )
  }

  return undefined
}

const currentAssociation = ref(undefined as undefined | IAssociation)

onMounted(() => {
  currentAssociation.value = getCurrentAssociation()
})

watch(
  () => props.associations,
  () => {
    currentAssociation.value = getCurrentAssociation()
  }
)

/**
 *
 */
const isAccountOwner = computed(() => {
  if (currentAssociation.value?.associationTypes.includes(GURU_ROLES.ACCOUNT_OWNER)) return true

  return false
})

/**
 *
 */
const isInstituteAdmin = computed(() => {
  if (currentAssociation.value?.associationTypes.includes(GURU_ROLES.INSTITUTE_ADMIN)) return true

  return false
})

/**
 *
 */
const isTeacher = computed(() => {
  if (currentAssociation.value?.associationTypes.includes(GURU_ROLES.TEACHER)) return true

  return false
})

/**
 *
 */
const isStudent = computed(() => {
  if (currentAssociation.value?.associationTypes.includes(GURU_ROLES.STUDENT)) return true

  return false
})
</script>
