<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useAuthStore } from '@/stores/auth.store'
import { useSubscribeStore } from '@/stores/subscribe.store'

import { DASHBOARD } from '@/utils/models'
import { PLATFORM_PLAN_VALUE } from '@/components/shared/subscription/enum/subscription.enum'

import CreatePluginModel from '@/components/dashboard/plugin/CreatePluginModel.vue'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import AccountDetailsAndPlans from '@/components/dashboard/plugin/AccountDetailsAndPlans.vue'
import NonSubscriber from '@/components/dashboard/plugin/NonSubscriber.vue'
import PluginList from '@/components/dashboard/plugin/PluginList.vue'
import IntegrateCode from '@/components/dashboard/plugin/IntegrateCode.vue'
import { initBundle } from '@/services/subscription.service'

const authStore = useAuthStore()
const subscribeStore = useSubscribeStore()
const isLogedIn = computed(() => authStore.isLogedIn)
const isSubscribed = computed(
  () =>
    (isLogedIn.value && subscribeStore.bundleInitData?.plan === PLATFORM_PLAN_VALUE.PRO) || false
)

onMounted(async () => {
  const bundleRes = await initBundle()
  subscribeStore.initBundle(bundleRes)
})
</script>

<template>
  <CreatePluginModel />
  <div class="flex flex-col gap-4">
    <AccountDetailsAndPlans />
    <DashboardCard>
      <div class="mb-10 flex w-full items-center justify-between">
        <h1 class="heading-small items-center text-left">My Plugins</h1>
        <button
          v-if="isSubscribed"
          class="btn btn-primary w-fit"
          :data-hs-overlay="`#${DASHBOARD.CREATE_PLUGIN}`"
        >
          Create New Plugin
          <FontAwesomeIcon icon="fa-arrow-right" class="ms-1" />
        </button>
      </div>
      <template v-if="isSubscribed">
        <PluginList />
      </template>
      <template v-else>
        <NonSubscriber />
      </template>
    </DashboardCard>
    <IntegrateCode />
  </div>
</template>
