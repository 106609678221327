<script setup lang="ts">
import CaseStudyLogoAndIntro from '@/components/shared/casestudy/CaseStudyLogoAndIntro.vue'
import kodeziLogo from '@/assets/images/casestudy/kodezi/Kodezi2.png'

const sectionData = {
  image: {
    src: kodeziLogo,
    alt: '',
    copyright: '© izusek from Getty Images Signature via Canva.com'
  },
  heading: 'Kodezi',
  desc: 'Kodezi is a dynamic educational platform committed to providing coding education and resources to learners of all levels. Their mission is to make coding accessible, engaging and rewarding for everyone.'
}
</script>

<template>
  <CaseStudyLogoAndIntro :sectionData="sectionData" />
</template>
