<script setup lang="ts">
import BaseInput from '@/components/forms/BaseInput.vue'
import { useField, useForm } from 'vee-validate'
import BaseTextbox from '@/components/forms/BaseTextbox.vue'
import { string } from 'yup'
import { computed, ref, watch, onMounted } from 'vue'
import { addStudentGroup } from '@/services/teach.service'
import { useRoute, useRouter } from 'vue-router'
import type { IStudentGroup_Request } from '@/components/guru/interface/requests.interface'
import { STUDENT_GROUP_ACTIONS } from '@/components/guru/enums/teach.enum'
import { useTeachStore } from '@/stores/teach.store'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import { HttpStatusCode } from 'axios'
import StudentGroupDeleteModal from '@/components/guru/modals/confirmationModals/StudentGroupDeleteModal.vue'
import { TEACHMODALHS } from '@/utils/models'

// Form-related
const { errors, handleSubmit } = useForm()
const { value: name, errorMessage: nameError } = useField('name', string().required().min(2))

const { value: description } = useField('description', string().required().min(2))

const nameInput = {
  label: 'Student Group Name',
  placeholder: 'E.g. Class 1A'
}

const descriptionInput = {
  label: 'Description',
  placeholder: 'Short description about student group.'
}

const teachStore = useTeachStore()
const route = useRoute()
const router = useRouter()
const insCode = ref(route.params.insCode as string)
const studentGroupId = ref(route.params.studentGroupId as string)
const showSave = ref(false)
const currentStudentGroup = computed(() => {
  return teachStore.editPrefilledStudentGroup
})
const currentMode = computed(() =>
  studentGroupId.value == undefined ? STUDENT_GROUP_ACTIONS.ADD : STUDENT_GROUP_ACTIONS.EDIT
)

onMounted(() => {
  insCode.value = route.params.insCode as string
  studentGroupId.value = route.params.studentGroupId as string
})

watch(
  () => currentStudentGroup.value,
  () => {
    if (teachStore.editPrefilledStudentGroup == undefined) {
      name.value = undefined
      description.value = undefined
    } else {
      name.value = currentStudentGroup.value?.name
      description.value = currentStudentGroup.value?.description
    }
  }
)

const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0
})

const onSubmit = handleSubmit(async () => {
  const reqObject: IStudentGroup_Request = {
    instituteCode: insCode.value,
    name: name.value as string,
    description: description.value as string,
    insStudentGroupId: currentStudentGroup.value?.insStudentGroupId,
    mode: currentMode.value
  }

  const res = await addStudentGroup(reqObject)

  if (res.status == HttpStatusCode.Ok) {
    router
      .push(
        `/dashboard/institution/${insCode.value}/student-group/${res.data.sg.insStudentGroupId}`
      )
      .then(() => {
        router.go(0)
      })
  }
})
</script>

<template>
  <DashboardCard
    :title="currentStudentGroup == undefined ? 'Add Student Group' : 'Edit Student Group'"
  >
    <StudentGroupDeleteModal
      :institute-code="insCode"
      :ins-student-group-id="currentStudentGroup?.insStudentGroupId"
    />
    <!-- form -->
    <form @submit.prevent="onSubmit" class="m-auto max-w-2xl">
      <BaseInput
        :label="nameInput.label"
        :placeholder="nameInput.placeholder"
        v-model="name"
        :error="nameError"
        :disabled="currentMode == STUDENT_GROUP_ACTIONS.EDIT && !showSave"
        :isLightGray="false"
      />

      <BaseTextbox
        :label="descriptionInput.label"
        :placeholder="descriptionInput.placeholder"
        v-model="description"
        :disabled="currentMode == STUDENT_GROUP_ACTIONS.EDIT && !showSave"
        :isLightGray="false"
      />

      <button
        :disabled="!canSubmit"
        type="submit"
        v-if="currentMode === STUDENT_GROUP_ACTIONS.ADD"
        class="btn-primary btn-rounded-md"
      >
        Create Student Group
      </button>
      <div v-if="currentMode === STUDENT_GROUP_ACTIONS.EDIT" class="mt-6 flex justify-center gap-3">
        <button
          type="button"
          v-if="!showSave"
          @click="showSave = true"
          class="btn-dashboard-small w-fit rounded-md p-2 text-sm"
        >
          Edit Details
        </button>
        <button v-if="showSave" type="submit" class="btn-primary w-fit rounded-md p-2 text-sm">
          Save Changes
        </button>
        <button
          class="btn-dashboard-small w-fit rounded-md p-2 text-sm text-red-500"
          type="button"
          :data-hs-overlay="`#${TEACHMODALHS.DELETE_STUDENT_GROUP_CONFIRMATION}`"
        >
          Delete Student Group
        </button>
      </div>
    </form>
  </DashboardCard>
</template>
