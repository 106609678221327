<script setup lang="ts">
import { computed } from 'vue'
import configureService from '@/services/ide/plugin/configure.service'
import { usePluginStore } from '@/stores/plugin.store'

const pluginStore = usePluginStore()

const selectedFunctionals = computed(() => {
  return pluginStore.selectedFunctionals
})
const isSelectedFunctionals = computed(() => {
  return pluginStore.selectedFunctionals.length > 0
})

/**
 * Deletes the selected project
 * @param functional The functional to delete
 */
const onDelete = (functional: any) => {
  configureService.removeSelectedFunctional(functional)
}
</script>

<template>
  <div v-if="isSelectedFunctionals">
    <ul class="flex w-full flex-wrap justify-stretch gap-2">
      <li
        v-for="functional in selectedFunctionals"
        :key="functional.name"
        :id="functional.name"
        class="section-secondary flex w-fit items-center rounded-lg border p-2 text-sm hover:border-btn-primary"
      >
        <h4 class="p-xsmall">{{ functional.name }}</h4>
        <button
          class="error ms-2 h-4 w-4 disabled:text-gray-500"
          @click="() => onDelete(functional)"
        >
          <FontAwesomeIcon icon="fa-close" />
        </button>
      </li>
    </ul>
  </div>
</template>
