import { ADVANCEDIDETYPE, IDECONSTANT } from '@/utils/ide'
import { type IMeta } from '@/utils/meta'

import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'

import editorService from '@/services/ide/editor.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import htmlEditorService from '@/services/ide/languages/html/editor.service'
import terminalEditorService from '@/services/ide/languages/terminal/editor.service'
import projectsService from '@/services/ide/projects.service'

import axios from 'axios'

export interface IInitIdeResponse {
  inputFiles: Array<string>
  robotChecked: boolean
}
export interface IInitAdvancedIdeRequest {
  lang: string | null
  type: (typeof ADVANCEDIDETYPE)[keyof typeof ADVANCEDIDETYPE]
  projectKey: string | boolean
  projectId: string
  shareId: string
  isInstant: object
}
export interface IInitAdvancedIdeResponse {
  project: any
  projectKey: string | boolean
  homeFile?: any
}
export interface IInitTerminalRequest {
  lang: string | null
}
/**
 * Initialize the advanced ide
 * @param type - The type of advanced ide
 */
const initAdvancedIde = async (type: (typeof ADVANCEDIDETYPE)[keyof typeof ADVANCEDIDETYPE]) => {
  const initAdvancedIdeRequest: IInitAdvancedIdeRequest = {
    lang: useIdeStore().isLanguage,
    type: type,
    projectKey: useIdeStore().projectKey,
    projectId: useIdeStore().isProjectId,
    shareId: useIdeStore().isShareId as string,
    isInstant: useIdeStore().isInstantShare
  }
  await axios
    .post('/api/projectSync/initIde', initAdvancedIdeRequest)
    .then((response: { data: IInitAdvancedIdeResponse }) => {
      useIdeStore().setProjectKey(response.data.projectKey)
      if (type !== ADVANCEDIDETYPE.OPEN && type !== ADVANCEDIDETYPE.OPENSHARE) {
        useIdeStore().setProject(response.data.project)
        useIdeStore().setActiveItem()
      }
      if (type === ADVANCEDIDETYPE.OPEN || type === ADVANCEDIDETYPE.OPENSHARE) {
        editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, response.data.homeFile)
      }
    })
}

/**
 * Initialize the ide when ide view is mounted
 */
const initIde = async () => {
  await axios.post('/api/doodle/initIde').then((response: { data: IInitIdeResponse }) => {
    useIdeStore().setInputFiles(response.data.inputFiles)
    if (response.data.robotChecked) {
      useAuthStore().robotCheckDone()
    }
  })
}

/**
 * Initialize the ide everytime the route changes
 * @param meta - The meta to set
 */
const initOnRouterChange = async (meta: IMeta) => {
  await cleanIde()
  useIdeStore().setProject(null)
  useIdeStore().setRouteMeta(meta)
  editorService.injectAceAndSplit()
  if (meta.language === 'blockly') blocklyService.loadBlockly()
  editorService.resetCodeEditor()

  useIdeStore().setIdeMeta(meta)

  if (useIdeStore().isAdvanced) {
    initAdvancedIde(ADVANCEDIDETYPE.INIT)
  }
}
/**
 * Clean the ide when ide view is unmounted
 */
const cleanIde = () => {
  if (useIdeStore().codeEditor) {
    useIdeStore().codeEditor.destroy()
    useIdeStore().codeEditor = null
  }
  if (useIdeStore().outputEditor) {
    useIdeStore().outputEditor.destroy()
    useIdeStore().outputEditor = null
  }
  if (useIdeStore().projectEditor) {
    useIdeStore().projectEditor.destroy()
    useIdeStore().projectEditor = null
  }
  if (useIdeStore().executeCodeEditor) {
    useIdeStore().executeCodeEditor.destroy()
    useIdeStore().executeCodeEditor = null
  }
  if (useIdeStore().executeOutputEditor) {
    useIdeStore().executeOutputEditor.destroy()
    useIdeStore().executeOutputEditor = null
  }
  if (useIdeStore().copyEditor) {
    useIdeStore().copyEditor.destroy()
    useIdeStore().copyEditor = null
  }
  if (useIdeStore().downloadEditor) {
    useIdeStore().downloadEditor.destroy()
    useIdeStore().downloadEditor = null
  }
  if (useIdeStore().openEditor) {
    useIdeStore().openEditor.destroy()
    useIdeStore().openEditor = null
  }
  if (useIdeStore().htmlDoctypeEditor) {
    useIdeStore().htmlDoctypeEditor.destroy()
    useIdeStore().htmlDoctypeEditor = null
  }
  if (useIdeStore().htmlHeadEditor) {
    useIdeStore().htmlHeadEditor.destroy()
    useIdeStore().htmlHeadEditor = null
  }
  if (useIdeStore().htmlBodyEditor) {
    useIdeStore().htmlBodyEditor.destroy()
    useIdeStore().htmlBodyEditor = null
  }
  if (useIdeStore().htmlJsEditor) {
    useIdeStore().htmlJsEditor.destroy()
    useIdeStore().htmlJsEditor = null
  }
  if (useIdeStore().htmlCssEditor) {
    useIdeStore().htmlCssEditor.destroy()
    useIdeStore().htmlCssEditor = null
  }
  if (useIdeStore().terminal) {
    useIdeStore().terminal = null
    terminalEditorService.ejectTerminal()
  }
  if (useIdeStore().isBlockly) blocklyService.unloadBlockly()

  editorService.ejectAce()
  useIdeStore().setCodeUpdated(false)
}

/**
 * Initialize the  html ide everytime the route changes
 */
const initHtmlOnRouterChange = async () => {
  if (!useIdeStore().isShared) {
    await new Promise((resolve) => setTimeout(resolve, 500))
    await htmlEditorService.initEditors()
    useIdeStore().setCodeUpdated(false)
    projectsService.initOnRouterChange()
  }
}

/**
 * init the post ide
 * @param byPost - router query
 */
const initPostIde = async (byPost: boolean) => {
  if (!byPost) return
  await axios.post('/api/doodle/initPostIde').then((response: { data: { script: string } }) => {
    editorService.postSetScript(response.data.script)
    useIdeStore().setCodeUpdated(false)
  })
}
/**
 * Initialize the terminal
 * @param terminal - The terminal to set
 */
const initTernimal = async (terminal: any) => {
  useIdeStore().terminal = terminal
  const initTerminalRequest: IInitTerminalRequest = {
    lang: useIdeStore().isTerminal
  }
  await axios
    .post('/api/doodle/initTerminal', initTerminalRequest)
    .then((response: { data: { message: string } }) => {
      useIdeStore().terminal.echo(response.data.message)
    })
    .catch((error: any) => {
      useIdeStore().terminal.error(error)
    })
  useIdeStore().setCodeUpdated(false)
}
/**
 * Clean the ide store
 */
const cleanIdeStore = () => {
  if (useIdeStore().openProjectID) {
    const openProjectID = useIdeStore().openProjectID
    useIdeStore().$reset()
    useIdeStore().setOpenProjectID(openProjectID)
  } else {
    useIdeStore().$reset()
  }
}
export default {
  cleanIde,
  initOnRouterChange,
  initHtmlOnRouterChange,
  initPostIde,
  initIde,
  initAdvancedIde,
  initTernimal,
  cleanIdeStore
}
