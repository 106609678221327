<script setup lang="ts">
import ModelLayout from '@/layouts/ModelLayout.vue'
import { TEACHMODALHS } from '@/utils/models'
import { type PropType, computed, watch } from 'vue'

import { HttpStatusCode } from 'axios'
import router from '@/router'
import { activateAssignment } from '@/services/teach.service'
import type IAssignmentDetailed from '@/components/guru/interface/IAssignmentDetailed'
import { useField, useForm } from 'vee-validate'
import moment from 'moment'
import { type IActivateAssignment_Request } from '@/components/guru/interface/requests.interface'
import {
  TEACH_ASSIGNMENT_STATUS,
  TEACH_DURATION_MODAL_MODE
} from '@/components/guru/enums/teach.enum'

const props = defineProps({
  assignmentId: {
    type: Number,
    require: true
  },
  instituteCode: {
    type: String,
    require: true
  },
  assignment: {
    type: Object as PropType<IAssignmentDetailed>,
    require: true
  },
  mode: {
    type: String as PropType<TEACH_DURATION_MODAL_MODE>,
    required: true
  }
})

// Form-related
const { handleSubmit } = useForm()

const { value: assignmentStartDate } = useField('assignmentStartDate')

const { value: assignmentEndDate } = useField('assignmentEndDate')

watch(
  () => props.assignment,
  (prefillData) => {
    assignmentStartDate.value = getDateAsString(prefillData?.startTime)

    assignmentEndDate.value = getDateAsString(prefillData?.endTime)
  }
)

const startDateError = computed<string>(() => {
  if (!assignmentStartDate.value) {
    return 'Start Date is required'
  }

  const startDate: Date = moment(assignmentStartDate.value).toDate()

  //Valid Date Input
  if (isNaN(startDate.valueOf() as number)) {
    return 'Invalid Date'
  }

  //Valid Date Input
  const currentDateTime = new Date()

  const differenceInMinutes = Math.floor(
    (startDate.valueOf() - currentDateTime.valueOf()) / 1000 / 60
  )
  if (differenceInMinutes < 3) {
    if (props.assignment?.status == TEACH_ASSIGNMENT_STATUS.DRAFT) {
      return 'Start date should be at least 3 minutes from now.'
    }
  }
  return ''
})

const endDateError = computed<string>(() => {
  // Required
  if (!assignmentEndDate.value) {
    return 'End Date is required'
  } else {
    const endDateTime: Date = moment(assignmentEndDate.value).toDate()

    //Valid Date Input
    if (isNaN(endDateTime.valueOf() as number)) {
      return 'Invalid Date'
    }

    //Valid Date Input
    const startDateTime = moment(assignmentStartDate.value!).toDate()
    const difference = endDateTime.valueOf() - startDateTime.valueOf()

    if (difference < 1 || !assignmentStartDate.value) {
      return 'End date should be greater than start date.'
    }

    return ''
  }
})

//Static data
const modalTitle = computed(() => {
  if (props.mode == TEACH_DURATION_MODAL_MODE.ACTIVATE) {
    return 'Activate Assignment'
  }

  if (props.mode == TEACH_DURATION_MODAL_MODE.CHANGE) {
    return 'Change Duration'
  }

  return ''
})

const buttonText = computed(() => {
  if (props.mode == TEACH_DURATION_MODAL_MODE.ACTIVATE) {
    return 'Activate'
  }

  if (props.mode == TEACH_DURATION_MODAL_MODE.CHANGE) {
    return 'Save Changes'
  }

  return ''
})

const assignmentStartDateInput = {
  label: 'Assignment Start Date (dd/MM/yyyy hh:mm a)',
  placeholder: 'dd/MM/yyyy hh:mm a'
}

const assignmentEndDateInput = {
  label: 'Assignment End Date (dd/MM/yyyy hh:mm a)',
  placeholder: 'dd/MM/yyyy hh:mm a'
}

/**
 * @returns formatted date
 * @param dte date
 */
function getDateAsLong(dte: any): number {
  return moment(dte, 'DD/MM/YYYY hh:mm A').unix()
}
/**
 * @returns string
 * @param dte date
 */
function getDateAsString(dte: any): string {
  const string = moment(dte).format('YYYY-MM-DDTkk:mm')
  return string
}

//Computed values
const canSubmit = computed(() => {
  // return Object.keys(errors.value).length === 0

  return !startDateError.value && !endDateError.value
})

const onSubmit = handleSubmit(async () => {
  //Refresh the startDate value for validation
  const tempStorage = assignmentStartDate.value
  assignmentStartDate.value = ''
  assignmentStartDate.value = tempStorage
  if (startDateError.value) return

  //If valid, proceed to make API call
  const reqObj: IActivateAssignment_Request = {
    instituteCode: props.instituteCode!,
    assignmentId: props.assignmentId!,
    assignmentStartDate: getDateAsLong(moment(assignmentStartDate.value!).toDate()) * 1000,
    assignmentEndDate: getDateAsLong(moment(assignmentEndDate.value!).toDate()) * 1000
  }

  const res = await activateAssignment(reqObj)

  if (res == HttpStatusCode.Ok) {
    router.go(0)
  }
})
</script>

<template>
  <ModelLayout
    :title="modalTitle"
    :hs="TEACHMODALHS.ACTIVATE_ASSIGNMENT"
    :loadDataWhenClose="false"
  >
    <!-- form -->
    <form @submit.prevent="onSubmit">
      <div class="mb-5">
        <label class="p-small my-2 block">{{ assignmentStartDateInput.label }}</label>
        <input
          type="datetime-local"
          v-model="assignmentStartDate"
          :placeholder="assignmentStartDateInput.placeholder"
          :disabled="assignment?.status !== TEACH_ASSIGNMENT_STATUS.DRAFT"
          class="section-tertiary p-small block w-full rounded-md px-4 py-2"
        />
        <p :class="['p-xsmall ', 'error', 'mt-2', 'mb-5', { hidden: !startDateError }]">
          {{ startDateError }}
        </p>
      </div>

      <div class="mb-5">
        <label class="p-small my-2 block">{{ assignmentEndDateInput.label }}</label>
        <input
          type="datetime-local"
          v-model="assignmentEndDate"
          :placeholder="assignmentEndDateInput.placeholder"
          class="section-tertiary p-small block w-full rounded-md px-4 py-2"
        />

        <p :class="['p-xsmall ', 'error', 'mt-2', 'mb-5', { hidden: !endDateError }]">
          {{ endDateError }}
        </p>
      </div>

      <button class="btn-primary btn-rounded-md" type="submit" :disabled="!canSubmit">
        {{ buttonText }}
      </button>
    </form>
  </ModelLayout>
</template>
