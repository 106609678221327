<script setup lang="ts">
import type { IActionButton } from '@/components/shared/PricingCard.vue'
import UseCaseBanner, { type IUseCaseBannerData } from '@/components/shared/UseCaseBanner.vue'
import Button_login_then_redirect from '@/components/shared/subscription/Button_login_then_redirect.vue'
import { SUBSCRIPTION_PRODUCT } from '@/components/shared/subscription/enum/subscription.enum'
import Integrate_3 from '@/assets/images/svg/Integrate_3.svg'
import Integrate_4 from '@/assets/images/svg/Integrate_4.svg'
import Integrate_5 from '@/assets/images/svg/Integrate_5.svg'
import Integrate_6 from '@/assets/images/svg/Integrate_6.svg'

const useCases: IUseCaseBannerData[] = [
  {
    orangeTextAboveTitle: 'Education Providers',
    title: 'Elevate Your Programming Courses',
    description:
      "Say goodbye to the hassle of students switching between your course and external IDEs! With our IDE embedded directly into your course modules, you can provide a seamless and memorable learning environment. JDoodle's Cloud IDE is the perfect addition to create interactive and engaging learning experiences.",
    textLeftImageRight: true,
    image: {
      src: Integrate_3,
      alt: 'Integrate animation'
    },
    listHeading: 'Key Benefits',
    list: [
      'Keep students focused and immersed in your course content.',
      'Encourage active participation and practice through in-course coding exercises.',
      'Boost student retention and satisfaction by combining theory with practical coding challenges.'
    ],
    useCaseLink: {
      href: '/case-study/kansas',
      text: 'Explore how University of Kansas transformed its coding curriculum using JDoodle’s API.'
    },
    isUseCase: true
  },
  {
    orangeTextAboveTitle: 'Bloggers | Course Creators | Technical Writers',
    title: 'Seamless Integration for Technical Blogs and Web Apps',
    description:
      "Do you run a technical blog or manage a web application? With JDoodle's Cloud IDE, you can significantly improve user engagement and interactivity on your platform. By integrating the IDE directly into your blogs or web apps, you empower your readers and users to code, experiment and learn in real-time.",
    textLeftImageRight: false,
    image: {
      src: Integrate_4,
      alt: 'Integrate animation'
    },
    listHeading: 'Key Benefits',
    list: [
      'Enhance the user experience by providing a code playground directly within your blog posts or web app.',
      'Allow your readers or users to tinker with code examples and see instant results.',
      'Encourage hands-on learning and knowledge retention.'
    ],
    useCaseLink: {
      href: '/case-study/outlier',
      text: 'Explore how Outlier.org transformed its coding curriculum using JDoodle’s API.'
    },
    isUseCase: true
  },
  {
    orangeTextAboveTitle: 'Faculty | Educators',
    title: 'Execute code within your LMS',
    description:
      "JDoodle's Compiler Plugins are revolutionizing education for faculty and educators. These plugins seamlessly integrate into Learning Management Systems (LMS), making teaching more interactive and efficient. With coding challenges and interactive content at their fingertips, educators can create engaging lessons and simplify the learning experience.",
    textLeftImageRight: true,
    image: {
      src: Integrate_5,
      alt: 'Integrate animation'
    },
    listHeading: 'Key Benefits',
    list: [
      'Integrating coding challenges and exercises directly into lessons.',
      'Effortlessly integrate coding practice into your LMS for focused, distraction-free learning.',
      'Increase students’ learning productivity by 25%.'
    ],
    useCaseLink: {
      href: '/case-study/linkgroup',
      text: "Explore how JDoodle's Compiler Plugins transformed education for LINKGroup"
    },
    isUseCase: true
  },
  {
    orangeTextAboveTitle: 'Software Library Providers',
    title: "Showcase Your Software Library's Power",
    description:
      "Are you eager to demonstrate the capabilities of your software library? JDoodle's Compiler API and Plugins offer an excellent platform to showcase the strength and versatility of your product. With our API, you can easily integrate your library into JDoodle's powerful coding environment and allow users to experience its potential firsthand.",
    textLeftImageRight: false,
    image: {
      src: Integrate_6,
      alt: 'Integrate animation'
    },
    listHeading: 'Key Benefits',
    list: [
      'Showcase your software library interactively.',
      'Keep users engaged in a seamless coding environment.',
      'Shorten sales cycle by 50%.'
    ],
    useCaseLink: {
      href: '/case-study/itext',
      text: 'Explore how iText showcased its library’s power in a convenient and friendly way to its prospects, with no setup time, trial forms or project setup using JDoodle.'
    },
    isUseCase: true
  }
]

const integrateBtn: IActionButton = {
  buttonText: 'Integrate Now',
  link: '/pricing',
  product: SUBSCRIPTION_PRODUCT.COMPILER_API
}
</script>

<template>
  <div>
    <div class="mb-12 text-center">
      <h2 class="heading-large mb-4">Use Cases</h2>
      <p class="text-secondary text-md m-auto max-w-lg">
        Take your platform's functionality and user experience to the next level by seamlessly
        integrating our Compiler API and Plugins
      </p>
    </div>

    <UseCaseBanner
      v-for="(cas, index) in useCases"
      :key="index"
      :sectionData="cas"
      :removePadding="false"
      :caseIndex="index + 1"
    >
      <Button_login_then_redirect :button-data="integrateBtn" :hasArrow="true" />
    </UseCaseBanner>
  </div>
</template>
