<script lang="ts" setup>
import CaseStudyBanner, { type ICaseStudyBanner } from '@/components/shared/CaseStudyBanner.vue'
import Dark_Outlier from '@/assets/images/shared/reviews/businesses/Dark_Outlier.png'
import Light_Outlier from '@/assets/images/shared/reviews/businesses/Light_Outlier.svg'

import { useColorMode } from '@vueuse/core'
import { ref, watch } from 'vue'

const colorTheme = useColorMode()

watch(colorTheme, () => {
  if (colorTheme.value == 'dark') {
    caseStudyBannerData.value.image.src = Dark_Outlier
  } else {
    caseStudyBannerData.value.image.src = Light_Outlier
  }
})

const caseStudyBannerData = ref<ICaseStudyBanner>({
  orangeTextAboveTitle: 'Case Study',
  title:
    "Outlier.org created robust andintuitive learning experiencefor coders on its platform with JDoodle's compiler plugins.",
  primaryButton: {
    link: '/case-study/outlier',
    text: 'Read More'
  },
  image: {
    src: colorTheme.value == 'dark' ? Dark_Outlier : Light_Outlier,
    alt: 'LinkGroup'
  }
})
</script>
<template>
  <div>
    <CaseStudyBanner :sectionData="caseStudyBannerData" />
  </div>
</template>
