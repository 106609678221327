<script setup lang="ts">
// import TeachBreadcrumb from '@/components/guru/shared/TeachBreadcrumb.vue'
import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { getInstituteSubscriptionInfo, getUsers } from '@/services/teach.service'
import { sortBy } from 'lodash'
import { GURU_ROLES } from '@/components/guru/enums/teach.enum'
import { useTeachStore } from '@/stores/teach.store'
import type { IUser } from '../../interface/IUser'
import { useAuthStore } from '@/stores/auth.store'

const route = useRoute()
const teachStore = useTeachStore()
const insCode = ref(route.params.insCode as string)
const users = ref([] as IUser[])

const teacherCount = computed(() => {
  return users.value.filter((user: IUser) => {
    return user.roles.includes(GURU_ROLES.TEACHER)
  }).length
})

const adminCount = computed(() => {
  return users.value.filter((user: IUser) => {
    return user.roles.includes(GURU_ROLES.INSTITUTE_ADMIN)
  }).length
})

/**
 *
 */
function sortUsers() {
  users.value = sortBy(users.value, (o) => {
    return o.firstName + ' ' + o.lastName
  })
}

onMounted(async () => {
  if (!useAuthStore().isUserloggedIn) return
  insCode.value = route.params.insCode as string
  const res = await getUsers(insCode.value)
  teachStore.setUsers(res)
  if (!teachStore.instituteSubscriptionInfo) {
    const insSubRes = await getInstituteSubscriptionInfo(insCode.value)
    teachStore.setSubscriptionInfo(insSubRes)
  }

  //@ts-ignore-next-line
  users.value = res
  sortUsers()
})
</script>

<template>
  <div class="flex h-full flex-col justify-between">
    <div class="text-sm">
      <p class="mb-2">Admins: {{ adminCount }}</p>
      <p class="mb-2">Teachers: {{ teacherCount }}</p>
      <p class="mb-2">Students: {{ teachStore.studentCount }}</p>
    </div>
    <div class="mt-3">
      <p class="error mb-3 text-xs" v-if="teachStore.adminUsersLimitReached">
        {{ teachStore.adminLimitMessage }}
      </p>
      <p class="error mb-3 text-xs" v-if="teachStore.teacherUsersLimitReached">
        {{ teachStore.teacherLimitMessage }}
      </p>
      <p class="error mb-3 text-xs" v-if="teachStore.studentUsersLimitReached">
        {{ teachStore.studentLimitMessage }}
      </p>
    </div>
  </div>
</template>
