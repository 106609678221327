import loadScriptInBody from '@/services/loadScriptInBody.service'
import { useDashboardIdeStore } from '@/stores/dashboard.ide.store'
import { IDECONSTANT } from '@/utils/ide'
import { useDark } from '@vueuse/core'
/**
 * set the script
 * @param script - The script to set
 */
const setScript = (script: string) => {
  if (useDashboardIdeStore().codeEditor) {
    useDashboardIdeStore().setScript(script)
    useDashboardIdeStore().codeEditor.getSession().setValue(script)
  }
}
/**
 * Set code editors theme
 */
const codeEditorSetTheme = () => {
  const isDark = useDark().value
  if (isDark) {
    if (useDashboardIdeStore().codeEditor)
      useDashboardIdeStore().codeEditor.setTheme('ace/theme/gruvbox')
  } else {
    if (useDashboardIdeStore().codeEditor)
      useDashboardIdeStore().codeEditor.setTheme('ace/theme/xcode')
  }
}
/**
 * Initialize the ace editor
 * @param id - The id of the ace editor to initialize
 * @returns The ace editor
 */
const initAceEditor = (id: (typeof IDECONSTANT)[keyof typeof IDECONSTANT]) => {
  window.ace.require('ace/ext/language_tools')
  window.ace.config.set('basePath', '/assets/javascript/ace')

  if (window?.ace?.edit(id)) {
    window?.ace?.edit(id).destroy()
  }

  const editor: ace['Editor'] = window.ace.edit(id)
  editor.getSession().setMode(`ace/mode/${useDashboardIdeStore().aceCode}`)

  editor.renderer.setAnimatedScroll(true)
  editor.renderer.setShowPrintMargin(false)
  editor.setReadOnly(true)

  if (useDashboardIdeStore().isBasicEditor) {
    editor.renderer.setShowGutter(false)
    editor.setHighlightActiveLine(false)
  } else {
    editor.renderer.setShowGutter(true)
  }

  editor.$blockScrolling = Infinity

  return editor
}

/**
 * set code editor sample script
 */
const codeEditorSetSampleScript = () => {
  if (useDashboardIdeStore().script) {
    useDashboardIdeStore().codeEditor.getSession().setValue(useDashboardIdeStore().script)
  }
}
/**
 * Fix ace editor bug on ios
 */
const fixAceeIOSBug = () => {
  if (
    typeof navigator === 'object' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
    !window.MSStream
  ) {
    window.MSStream = {}
  }
}

/**
 * Initialize the editor when router changes
 * @param count - The count of the init
 * @returns null
 */
const initEditor = async (count: number = 0) => {
  if (!useDashboardIdeStore().isWindowAce) {
    if (count > 10) {
      return null
    } else {
      await new Promise((resolve) => setTimeout(resolve, 100))
      initEditor(count + 1)
    }
  } else {
    if (useDashboardIdeStore().codeEditor) {
      codeEditorSetSampleScript()
      useDashboardIdeStore()
        .codeEditor.getSession()
        .setMode(`ace/mode/${useDashboardIdeStore().aceCode}`)
    } else {
      useDashboardIdeStore().codeEditor = initAceEditor(IDECONSTANT.DASHBOARDCODEEDITOR)
      codeEditorSetSampleScript()
    }
    await new Promise((resolve) => setTimeout(resolve, 100))
    useDashboardIdeStore().codeEditor.resize()
  }

  codeEditorSetTheme()
}

/**
 * Initialize the code editor
 */
const injectAce = () => {
  fixAceeIOSBug()
  loadScriptInBody.loadScriptInBody('/assets/javascript/ace.min.js').then(async () => {
    loadScriptInBody.loadScriptInBody('/assets/javascript/ext-language_tools.js')
    loadScriptInBody.loadScriptInBody('/assets/javascript/ext-static_highlight.js')
    initEditor()
  })
}

/**
 * Initialize the code editor
 * @param aceCode - The ace code to set
 * @param isBasicEditor - The isBasicEditor to set
 */
const initOnRouterChange = (aceCode: string, isBasicEditor: boolean = false) => {
  useDashboardIdeStore().setAceCode(aceCode)
  useDashboardIdeStore().setIsBasicEditor(isBasicEditor)
  cleanIde()
  injectAce()
}
/**
 * Eject the code editor
 */
const ejectAce = () => {
  if (window.ace) {
    loadScriptInBody.unloadScriptInBody('/assets/javascript/ace.min.js')
    loadScriptInBody.unloadScriptInBody('/assets/javascript/ext-language_tools.js')
    loadScriptInBody.unloadScriptInBody('/assets/javascript/ext-static_highlight.js')
  }
}
/**
 * Clean the ide when ide view is unmounted
 */
const cleanIde = () => {
  if (useDashboardIdeStore().codeEditor) {
    useDashboardIdeStore().codeEditor.destroy()
    useDashboardIdeStore().codeEditor = null
    useDashboardIdeStore().setScript('')
    useDashboardIdeStore().setIsBasicEditor(false)
    useDashboardIdeStore().setAceCode('text')
    ejectAce()
  }
}
/**
 * Clean the ide store
 */
const cleanDashboardIdeStore = () => {
  useDashboardIdeStore().$reset()
}

export default {
  initOnRouterChange,
  cleanIde,
  cleanDashboardIdeStore,
  setScript
}
