<script setup lang="ts">
import ModelLayout from '@/layouts/ModelLayout.vue'
import { TEACHMODALHS } from '@/utils/models'
import { HttpStatusCode } from 'axios'
import router from '@/router'
import { onHoldAssignment } from '@/services/teach.service'

const props = defineProps({
  assignmentId: {
    type: Number,
    require: true
  },
  instituteCode: {
    type: String,
    require: true
  }
})

//Static data
const modalTitle = 'Put On Hold'
const message = 'Are you sure you want to put this assignment on hold?'

/**
 *
 */
const handlePutOnHold = async () => {
  const res = await onHoldAssignment(props.instituteCode!, props.assignmentId!)

  if (res == HttpStatusCode.Ok) {
    router.go(0)
  }
}
</script>

<template>
  <ModelLayout
    :title="modalTitle"
    :hs="TEACHMODALHS.ON_HOLD_CONFIRMATION"
    :loadDataWhenClose="false"
  >
    <p class="my-5 break-normal text-center text-sm">{{ message }}</p>

    <div class="mt-8 flex justify-center gap-5">
      <button class="btn-secondary rounded-md px-5 py-2" @click="handlePutOnHold">Yes</button>

      <button class="btn-primary rounded-md px-5 py-2" data-hs-overlay-close>No</button>
    </div>
  </ModelLayout>
</template>
