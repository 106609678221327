<script lang="ts" setup>
import CaseStudyBannerImageAndHeading, {
  type ICaseStudyBInHData
} from '@/components/shared/casestudy/CaseStudyBannerImageAndHeading.vue'

import outlierBanner from '@/assets/images/casestudy/outlier/Outlier.org1.png'

const sectionData: ICaseStudyBInHData = {
  image: {
    src: outlierBanner,
    alt: '',
    copyright: '© Peopleimages.com - YuriArcurs via Canva.com'
  },
  heading: "JDoodle's Java Compiler Plugin Boosted Outlier.org's Student Outcomes",
  sHeading: 'Empowering Novice Programmers for Success',
  clientName: 'Outlier.org',
  date: 'January 2023',
  institution: 'Assessment and Technology Solutions'
}
</script>

<template>
  <CaseStudyBannerImageAndHeading :sectionData="sectionData" />
</template>
