<script setup lang="ts">
import CaseStudyLogoAndIntro from '@/components/shared/casestudy/CaseStudyLogoAndIntro.vue'
import outlierLogo from '@/assets/images/casestudy/outlier/Outlier.org2.png'

const sectionData = {
  image: {
    src: outlierLogo,
    alt: '',
    copyright: '© Rockaa from Getty Images Signature via Canva.com'
  },
  heading: 'Outlier.org',
  desc: 'Outlier.org is a pioneering online education platform committed to making high-quality, college-level courses accessible to all. Offering a range of engaging and rigorous courses, Outlier.org aims to revolutionize education by delivering immersive and affordable learning experiences.'
}
</script>

<template>
  <CaseStudyLogoAndIntro :sectionData="sectionData" />
</template>
