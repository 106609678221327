<script lang="ts" setup>
import CaseStudyBannerImageAndHeading, {
  type ICaseStudyBInHData
} from '@/components/shared/casestudy/CaseStudyBannerImageAndHeading.vue'

import KodeziBanner from '@/assets/images/casestudy/kodezi/Kodezi1.png'

const sectionData: ICaseStudyBInHData = {
  image: {
    src: KodeziBanner,
    alt: '',
    copyright: '© mediaphotos from Getty Images Signature via Canva.com'
  },
  heading: 'Simplifying Coding Challenges',
  sHeading: 'Enhancing the Learning Experience with Seamless Online IDE Integration',
  clientName: 'Kodezi',
  date: 'January 2023'
}
</script>

<template>
  <CaseStudyBannerImageAndHeading :sectionData="sectionData" />
</template>
