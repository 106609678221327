<script setup lang="ts">
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import ProgressRing from '@/components/shared/dashboard/ProgressRing.vue'
import { ref, onMounted } from 'vue'
import { getApiCreditsUsedToday } from '@/services/subscription.service'
import { useSubscribeStore } from '@/stores/subscribe.store'

defineProps({
  data: Object
})

const creditUsed = ref({ used: 0, quota: 0 })
const subscribeStore = useSubscribeStore()

onMounted(async () => {
  getCreditsUsed()
})

/**
 *
 */
async function getCreditsUsed() {
  await getApiCreditsUsedToday().then((data) => {
    creditUsed.value = data
  })
}
</script>

<template>
  <div class="my-4 grid grid-cols-1 gap-4 lg:grid-cols-3">
    <DashboardCard title="Daily Credits Usage">
      <p v-if="subscribeStore.apiInitData?.isUnlimited" class="text-secondary mb-8">
        You are unlimited plan, your usage is not metered.
      </p>
      <div v-else>
        <ProgressRing :credits="creditUsed" />
        <p class="text-secondary mb-6 text-center text-sm">*1 Credit = 1 'Execute' call</p>
        <button class="btn-dashboard mx-auto mb-6 mt-10" @click="getCreditsUsed">
          Refresh Count
        </button>
      </div>
    </DashboardCard>

    <DashboardCard title="Monthly Credit Usage" class="lg:col-span-2">
      <div class="text-secondary flex h-48 flex-col items-center justify-center font-semibold">
        Feature Coming Soon
      </div>
    </DashboardCard>
  </div>

  <DashboardCard>
    <div class="py-6">
      <p class="text-secondary mx-auto mb-10 max-w-md text-center">
        Are you looking for a custom solution for your business or institution? Easy! We we will be
        happy to create it for you.
      </p>

      <RouterLink class="btn-dashboard m-auto text-center" :to="{ name: 'contactus' }">
        Contact Us
      </RouterLink>
    </div>
  </DashboardCard>
</template>
