declare global {
  interface Window {
    Blockly: any
  }
}
import editorService from '@/services/ide/editor.service'
import ideService from '@/services/ide/ide.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import htmlEditorService from '@/services/ide/languages/html/editor.service'
import htmlExecutionService from '@/services/ide/languages/html/execute.service'
import projectTreeService from '@/services/ide/projectTree.service'
import projectsService from '@/services/ide/projects.service'
import recaptchaService from '@/services/recaptcha.service'
import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'
import { ADVANCEDIDETYPE, IDECONSTANT, SYNC_ERROR } from '@/utils/ide'
import { META, type IMeta } from '@/utils/meta'
import { cloneDeep, compact, isNumber } from 'lodash'

import axios from 'axios'

export interface IShareIndexRequest {
  shareId: string
  isInstant?: boolean
  lang?: string
}
export interface IEditableShareRequest {
  id: string
  lang: string
}
export interface IInstantShareRequest {
  script: string | null
  libs?: string | null
  versionIndex?: number
  projectKey?: string | boolean | number
  isMultiFile?: boolean
  lang?: string
}
/**
 * Set the blockly project and script
 * @param data - The project data
 * @param count - The count
 */
const postActionBlockly = async (data: any, count: number = 0) => {
  if (!window.Blockly || !useIdeStore().isBlocklyWorkspace) {
    if (count > 10) {
      useIdeStore().setShareNotFound(true)
      useIdeStore().setShareNotFoundHttpError('Unable to load the IDE. Please try again later.')
    } else {
      await new Promise((resolve) => setTimeout(resolve, 600))
      postActionBlockly(data, count + 1)
    }
  } else {
    const project = data.project
    useIdeStore().setProject(project)
    blocklyService.openBlockly(project.script)
    if (isNumber(project.versionIndex)) {
      useIdeStore().setVersionIndex(project.versionIndex)
    }
  }
}
/**
 * Set the project and script
 * @param data - The project data
 * @param count - The count
 */
const postAction = async (data: any, count: number = 0) => {
  if (!useIdeStore().isWindowAce) {
    if (count > 10) {
      useIdeStore().setShareNotFound(true)
      useIdeStore().setShareNotFoundHttpError('Unable to load the IDE. Please try again later.')
    } else {
      await new Promise((resolve) => setTimeout(resolve, 600))
      postAction(data, count + 1)
    }
  } else {
    if (useIdeStore().isAdvanced) {
      const project = data.project
      const script = JSON.parse(project.script)
      project.treeData = script.treeData
      project.home = script.home
      data.script = null
      useIdeStore().setProject(project)
      if (isNumber(project.versionIndex)) {
        useIdeStore().setVersionIndex(project.versionIndex)
      }
      useIdeStore().javaLibraries = compact(project.libraries.split(' '))
      useIdeStore().setActiveItem()
      ideService.initAdvancedIde(ADVANCEDIDETYPE.OPENSHARE)
      for (const node of useIdeStore().isProject.treeData.children) {
        if (!node.children) {
          if ('/' + node.name !== useIdeStore().isProject.home) {
            node.yetToSync = true
          }
        } else {
          projectsService.markChildrenYetToSync(node)
        }
      }
    } else {
      const project = data.project
      useIdeStore().setProject(project)
      editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, project.script)
      if (isNumber(project.versionIndex)) {
        useIdeStore().setVersionIndex(project.versionIndex)
      }
      useIdeStore().javaLibraries = compact(project.libraries.split(' '))
    }
  }
}
/**
 * Set the html project and script
 * @param project - The project data
 * @param count - The count
 */
const postHtmlAction = async (project: any, count: number = 0) => {
  if (!useIdeStore().isWindowAce) {
    if (count > 10) {
      useIdeStore().setShareNotFound(true)
      useIdeStore().setShareNotFoundHttpError('Unable to load the IDE. Please try again later.')
    } else {
      await new Promise((resolve) => setTimeout(resolve, 600))
      postHtmlAction(project, count + 1)
    }
  } else {
    htmlEditorService.resetCodeEditor()
    useIdeStore().setProject(cloneDeep(project))
    htmlEditorService.setProject(project)
    htmlExecutionService.execute(false)
  }
}
/**
 * Check is the html project is shared
 * @param shareId - The share id
 */
const initHtmlOnRouterChange = async (shareId: string) => {
  if (!useIdeStore().ideMeta?.isShared) return
  useIdeStore().setSharedId(shareId)
  const requestData: IShareIndexRequest = {
    shareId: shareId,
    lang: 'html'
  }
  await axios
    .post('/api/doodle/shareIndex', requestData)
    .then(
      async (response: {
        data: {
          project: any
        }
      }) => {
        await ideService.initHtmlOnRouterChange()
        postHtmlAction(response.data.project)
      }
    )
    .catch((error: { response: { data: { message: string } } }) => {
      useIdeStore().setShareNotFound(true)
      useIdeStore().setShareNotFoundHttpError(
        error.response.data.message ||
          'Something went wrong. Unable to load the shared project. Please try again later.'
      )
    })
}
/**
 * Check is the project is shared
 * @param shareId - The share id
 */
const initOnRouterChange = async (shareId: string) => {
  if (!useIdeStore().ideMeta?.isShared) return
  useIdeStore().setSharedId(shareId)
  const requestData: IShareIndexRequest = {
    shareId: shareId,
    isInstant: useIdeStore().ideMeta?.isInstantShare as boolean
  }
  await axios
    .post('/api/doodle/shareIndex', requestData)
    .then(
      async (response: {
        data: {
          project: {
            language: string
            isMultiFile: boolean
          }
        }
      }) => {
        if (response.data.project.isMultiFile) {
          const ideMeta: IMeta = cloneDeep(META[response.data.project.language])
          ideMeta.isAdvanced = true
          ideMeta.canonicalPath += '-ide'
          ideMeta.isInstantShare = useIdeStore().ideMeta?.isInstantShare as boolean
          useIdeStore().setIdeMeta(ideMeta)
        } else {
          useIdeStore().setIdeMeta(META[response.data.project.language])
        }
        await new Promise((resolve) => setTimeout(resolve, 500))
        editorService.splitIde()
        editorService.initEditors()
        projectsService.loadProjects()
        if (useIdeStore().isBlockly) {
          blocklyService.loadBlockly()
          postActionBlockly(response.data)
        } else {
          postAction(response.data)
        }
      }
    )
    .catch((error: { response: { data: { message: string } } }) => {
      useIdeStore().setShareNotFound(true)
      useIdeStore().setShareNotFoundHttpError(
        error.response.data.message ||
          'Something went wrong. Unable to load the shared project. Please try again later.'
      )
    })
}
/**
 * Editable unshare
 * @returns The project response object
 */
const editableUnshare = async () => {
  const requestData: IEditableShareRequest = {
    id: useIdeStore().isProjectId,
    lang: useIdeStore().isLanguage
  }
  return await axios
    .post('/api/doodle/unshare', requestData)
    .then(async (response: { status: number }) => {
      if (response.status === 200) {
        useIdeStore().setEditableShareUrl(null)
        useIdeStore().setEditableShareEmbedUrl(null)
        return response
      }
      throw new Error('Something went wrong. Please try again later.')
    })
    .catch((error: { response: { message: string } }) => {
      throw error
    })
}
/**
 * Editable share
 * @returns The project response object
 */
const editableShare = async () => {
  const requestData: IEditableShareRequest = {
    id: useIdeStore().isProjectId,
    lang: useIdeStore().isLanguage
  }
  return await recaptchaService
    .callViaCaptcha()
    .then(async () => {
      return await axios
        .post('/api/doodle/share', requestData)
        .then(
          async (response: {
            data: { url: string; embedUrl: string; pluginId?: number; isProBundlePlan?: boolean }
          }) => {
            if (response.data.url) {
              useIdeStore().setEditableShareUrl(response.data.url)
            }
            if (response.data.embedUrl) {
              useIdeStore().setEditableShareEmbedUrl(response.data.embedUrl)
            }
            if (response.data.pluginId) {
              useIdeStore().setEditableSharePluginId(response.data.pluginId.toString())
            }
            if (response.data.isProBundlePlan) {
              useIdeStore().setIsProBundlePlan(response.data.isProBundlePlan)
            }
            return response
          }
        )
        .catch((error: { response: { message: string } }) => {
          throw error
        })
    })
    .catch((error: any) => {
      throw error
    })
}
/**
 * sahre logic
 * @param requestData - The request data
 * @returns The project response object
 */
const shareInstantLogic = async (requestData: IInstantShareRequest) => {
  return await axios
    .post('/api/doodle/instantShare', requestData)
    .then(
      async (response: {
        data: { url: string; embedUrl: string; pluginId: string }
        status: number
      }) => {
        return response
      }
    )
    .catch((error: { response: { status: number } }) => {
      if (error?.response?.status === 403) {
        useAuthStore().clearRobotCheck()
      }
      throw error
    })
}
/**
 * Wait for the sync to finish
 * @param requestData - the request data
 * @param count - the count
 * @returns The project response object
 */
const multifileWaitForSync = async (requestData: IInstantShareRequest, count: number = 0) => {
  if (!projectTreeService.isSyncSuccess) {
    if (count > 9) {
      throw new Error(SYNC_ERROR)
    } else {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      await multifileWaitForSync(requestData, count + 1)
    }
  } else {
    return await shareInstantLogic(requestData)
  }
}
/**
 * Instant share
 * @returns The project response object
 */
const instantShare = async () => {
  return await recaptchaService
    .callViaCaptcha()
    .then(async () => {
      let requestData: IInstantShareRequest = {} as IInstantShareRequest
      const libs = useIdeStore().javaLibraries ? useIdeStore().javaLibraries.join(' ') : ''
      if (useIdeStore().isBlockly) {
        requestData = {
          script: JSON.stringify(blocklyService.getBlocklyScript()),
          isMultiFile: false
        }
      } else if (useIdeStore().isAdvanced) {
        const root = cloneDeep(useIdeStore().project)
        const script = JSON.stringify({ home: root.home, treeData: root.treeData })
        requestData = {
          script: script,
          projectKey: useIdeStore().projectKey,
          isMultiFile: true
        }
      } else {
        requestData = {
          script: editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue(),
          isMultiFile: false
        }
      }
      requestData.lang = useIdeStore().isLanguage
      requestData.versionIndex = useIdeStore().versionIndex
      requestData.libs = libs

      if (useIdeStore().isAdvanced) {
        projectTreeService.syncBeforeExecute()
        return multifileWaitForSync(requestData)
      } else {
        return await shareInstantLogic(requestData)
      }
    })
    .catch((error: any) => {
      throw error
    })
}

export default {
  initOnRouterChange,
  initHtmlOnRouterChange,
  editableUnshare,
  editableShare,
  instantShare
}
