<script setup lang="ts">
import AssignmentsComponent from '@/components/guru/dashboard/AssignmentsComponent.vue'
import InstituteDetailsComponent from '@/components/guru/dashboard/InstituteDetailsComponent.vue'
import StudentGroupsList from '@/components/guru/dashboard/users/StudentGroupsList.vue'
import UsersSummary from '@/components/guru/dashboard/users/UsersSummary.vue'
import { GURU_ASSIGNMENT_ACTIONS, GURU_ROLES } from '@/components/guru/enums/teach.enum'
import CreateAssignmentModal from '@/components/guru/modals/CreateAssignmentModal.vue'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import { useTeachStore } from '@/stores/teach.store'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import AssignmentsAsStudentPage from './assignments/AssignmentsAsStudentPage.vue'
import documentIcon from '@/assets/images/teach-and-assess/file-plus.svg'
import { useAuthStore } from '@/stores/auth.store'
const teachStore = useTeachStore()
const breadcrumbStore = useBreadcrumbStore()
const currentInstitute = computed(() => teachStore.currentAssociation)
const route = useRoute()
const insCode = ref<string>(route.params.insCode as string)

onMounted(async () => {
  if (!useAuthStore().isUserloggedIn) return
  insCode.value = route.params.insCode as string
  teachStore.getAndSetCurrentAssociation(insCode.value)
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)

  // Currently, this isn't used elsewhere. Future use case?
  // if (!teachStore.instituteAssignments) {
  //   const res = await getAssignments(insCode.value)
  //   teachStore.setAssignments(res)
  // }
})

watch(
  () => teachStore.associations,
  () => {
    teachStore.getAndSetCurrentAssociation(insCode.value)
    breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
  }
)

watch(
  () => route.params,
  () => {
    insCode.value = route.params.insCode as string
    teachStore.getAndSetCurrentAssociation(insCode.value)
  }
)

const userRole = computed<GURU_ROLES | null>(() => {
  if (teachStore.currentAssociationFull?.associationTypes.includes(GURU_ROLES.ACCOUNT_OWNER)) {
    return GURU_ROLES.ACCOUNT_OWNER
  } else if (
    teachStore.currentAssociationFull?.associationTypes.includes(GURU_ROLES.INSTITUTE_ADMIN)
  ) {
    return GURU_ROLES.INSTITUTE_ADMIN
  } else if (teachStore.currentAssociationFull?.associationTypes.includes(GURU_ROLES.TEACHER)) {
    return GURU_ROLES.TEACHER
  } else if (teachStore.currentAssociationFull?.associationTypes.includes(GURU_ROLES.STUDENT)) {
    return GURU_ROLES.STUDENT
  } else {
    return null
  }
})

const breadcrumbLinks = computed(() => [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  },
  {
    href: '/dashboard/institution',
    title: 'Virtual Institutions'
  },
  {
    href: `/dashboard/institution/${insCode.value}`,
    title: `${currentInstitute.value?.name}`
  }
])

const usersLink = computed(() => {
  return {
    url: `/dashboard/institution/${insCode.value}/users`,
    name: 'Manage'
  }
})

const searchString = ref('')

const associationTypeText = computed(() => {
  return currentInstitute.value?.name
})

const draftLimitsReached = computed(() => {
  return teachStore.draftLimitReached
})

const assignmentsLink = computed(() => {
  return {
    url: `/dashboard/institution/${currentInstitute.value?.code}/assignments`,
    name: 'See More'
  }
})

const studentGroupsLink = computed(() => {
  return {
    url: `/dashboard/institution/${currentInstitute.value?.code}/student-groups`,
    name: 'Manage'
  }
})
</script>

<template>
  <CreateAssignmentModal :mode="GURU_ASSIGNMENT_ACTIONS.ADD" :insCode="currentInstitute?.code" />
  <div class="lg:grid-rows-7 flex h-full flex-col gap-4 lg:grid lg:grid-cols-6">
    <DashboardCard
      :title="associationTypeText"
      class="row-span-1"
      :class="userRole !== GURU_ROLES.STUDENT ? 'col-span-4' : 'col-span-6'"
    >
      <InstituteDetailsComponent :ins-code="insCode" />
    </DashboardCard>

    <DashboardCard class="col-span-2 row-span-1" v-if="userRole !== GURU_ROLES.STUDENT">
      <div class="flex h-full flex-col justify-between">
        <div>
          <h2 class="mb-4 text-lg">Create an Assignment to start assessing your students!</h2>
          <button
            append
            :disabled="!currentInstitute || draftLimitsReached"
            @click="$router.push(`${insCode}/create-assignment`)"
            class="btn-primary mb-4 flex w-fit items-center gap-2 rounded-md px-3 py-2 text-sm"
          >
            Create Assigment
            <img :src="documentIcon" class="w-4" />
          </button>
          <p class="error text-xs" v-if="draftLimitsReached">
            {{ teachStore.draftLimitMessage }}
          </p>
        </div>
        <!-- Uncomment to show video tutorial content when ready -->
        <!-- <div>
          <span class="text-sm font-bold">Video Tutorial</span>
          <p class="text-secondary text-xs">View video tutorials on how to create an assignment</p>
        </div> -->
      </div>
    </DashboardCard>

    <DashboardCard
      v-if="userRole !== GURU_ROLES.STUDENT"
      title="Assignments"
      :link="assignmentsLink"
      class="col-span-4 row-span-6"
    >
      <div class="relative mb-4 flex">
        <input
          v-model="searchString"
          placeholder="Search Assignments"
          class="section-primary p-small block w-full rounded-lg px-5 py-3"
        />
        <FontAwesomeIcon icon="fa-search" class="text-brand absolute right-4 top-4" />
      </div>
      <AssignmentsComponent
        :display-count="4"
        :insCode="currentInstitute?.code"
        :searchString="searchString"
      />
    </DashboardCard>

    <DashboardCard
      v-if="userRole !== GURU_ROLES.STUDENT"
      title="User Management"
      class="col-span-2 row-span-3"
      :link="usersLink"
    >
      <UsersSummary />
    </DashboardCard>
    <DashboardCard
      v-if="userRole !== GURU_ROLES.STUDENT"
      title="Student Groups"
      class="col-span-2 row-span-3"
      :link="studentGroupsLink"
    >
      <StudentGroupsList :displayCount="3" />
    </DashboardCard>

    <!-- Students Only -->
    <AssignmentsAsStudentPage v-if="userRole == GURU_ROLES.STUDENT" class="col-span-6 row-span-5" />
  </div>
</template>
