<script setup lang="ts">
import { computed } from 'vue'
import { IDECONSTANT } from '@/utils/ide'
import { useRoute } from 'vue-router'
import editorService from '@/services/ide/editor.service'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import LogoBlack from '@/assets/images/JD_logo_colour.png'

const route = useRoute()
const ideStore = useIdeStore()
const pluginStore = usePluginStore()

const shareId = computed(() => {
  return ideStore.isShareId
})
const isPlugin = computed(() => {
  return pluginStore.isPlugin
})
const shareUrl = computed(() => {
  let url: string = ''
  //@ts-ignore
  if (process.env.NODE_ENV === 'development') {
    url = `${import.meta.env.VITE_APP_IP}:${import.meta.env.VITE_APP_PORT}`
  } else {
    url = `https://www.jdoodle.com`
  }
  return `${url}/${route.meta.isInstantShare ? 'ia' : 'a'}/${shareId.value}`
})
/**
 * post to jdoodle
 */
const postToJDoodle = async () => {
  let postUrl: string = ''
  //@ts-ignore
  if (process.env.NODE_ENV === 'development') {
    postUrl = `${import.meta.env.VITE_APP_IP}:${
      import.meta.env.VITE_APP_PORT
    }/api/redirect-to-post/${ideStore.ideMeta?.canonicalPath}`
  } else {
    postUrl = `https://www.jdoodle.com/api/redirect-to-post/${ideStore.ideMeta?.canonicalPath}`
  }
  const form = document.createElement('form')
  form.method = 'POST'
  form.action = postUrl
  form.target = '_blank'
  const input = document.createElement('input')
  input.type = 'hidden'
  input.id = 'initScriptForPost'
  input.name = 'initScript'
  input.value = editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue()
  form.appendChild(input)
  document.body.appendChild(form)
  form.submit()
  await new Promise((resolve) => setTimeout(resolve, 1000))
  document.body.removeChild(form)
}
</script>

<template>
  <div class="flex max-h-12 items-center justify-between print:hidden">
    <p class="p-small print:hidden">
      <a v-if="isPlugin" @click="postToJDoodle" class="link-secondary"
        >Edit this program in JDoodle.com</a
      >
      <a v-else :href="shareUrl" target="_blank" class="link-secondary"
        >Edit this program in JDoodle.com</a
      >
    </p>
    <a
      href="https://www.jdoodle.com"
      target="_blank"
      class="flex items-center gap-2 rounded-lg p-2"
    >
      <span class="p-small">Online compiler powered by</span>
      <img class="h-12 w-fit" :src="LogoBlack" alt="JDoodle" />
    </a>
  </div>
</template>
