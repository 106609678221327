import editorService from '@/services/ide/editor.service'
import ideService from '@/services/ide/ide.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import htmlEditorService from '@/services/ide/languages/html/editor.service'
import htmlExecutionService from '@/services/ide/languages/html/execute.service'
import projectTreeService from '@/services/ide/projectTree.service'
import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore, type THtmlExecutionHistory } from '@/stores/ide.store'
import { ADVANCEDIDETYPE, IDECONSTANT, SYNC_ERROR } from '@/utils/ide'
import { cloneDeep, compact, merge, remove } from 'lodash'

import axios from 'axios'
export interface IDubplicateProjectNameRequest {
  filename: string
  isMultiFile?: boolean
  lang: string
}
export interface IAutoSaveRequest {
  script: string | null
  libs: string | null
  versionIndex: number
  projectKey?: string | boolean | number
  isMultiFile?: boolean
  filename: string
  id: number
  lang: string
  docType?: string
  htmlHead?: string
  htmlBody?: string
  jsCode?: string
  cssCode?: string
}
export interface IsaveProjectActualRequest extends Partial<IAutoSaveRequest> {
  filename?: string
  id?: number
  lang?: string
}
export interface IProject {
  id: string
}
export interface ILoadProjectsRequest {
  id?: string
  lang: string
  isMultiFile: boolean
}
export interface IDeleteProjectsRequest {
  id?: string
  lang: string
  isMultiFile: boolean
}

/**
 * Check for duplicate project name
 * @param projectName - The project name
 * @returns The project response object
 */
const checkForDubplicateProjectName = async (projectName: string) => {
  const requestData: IDubplicateProjectNameRequest = {
    filename: projectName,
    isMultiFile: useIdeStore().isAdvanced,
    lang: useIdeStore().isLanguage
  }
  return await axios
    .post('/api/doodle/checkFileName', requestData)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}
/**
 * Get the sanitized script
 * @param root - the root
 */
const getSanitizedScriptIt = (root: any) => {
  if (root.content) {
    root.content = null
  }

  if (root.codeChanged) {
    root.codeChanged = false
  }

  if (root.children) {
    for (const child of root.children) {
      getSanitizedScriptIt(child)
    }
  }
}
/**
 * Save the project to the server
 * @param requestData - The request data
 * @param isSaveAs - Is save as
 * @returns The project response object
 */
const saveLogic = async (requestData: IsaveProjectActualRequest, isSaveAs: boolean = false) => {
  return await axios
    .post('/api/doodle/save', requestData)
    .then(async (response: { data: { project: any }; status: number }) => {
      if (!isSaveAs) {
        if (useIdeStore().isAdvanced) {
          const newProject = merge(useIdeStore().isProject, response?.data?.project)
          useIdeStore().setProject(null)
          useIdeStore().setProject(newProject)
        } else {
          useIdeStore().setProject(response?.data?.project)
        }
      }
      await loadProjects()
      useIdeStore().setCodeUpdated(false)
      return response
    })
    .catch((error) => {
      throw error
    })
}
/**
 * Wait for the sync to finish
 * @param requestData - the request data
 * @param isSaveAs - Is save as
 * @param count - the count
 * @returns The project response object
 */
const multifileWaitForSync = async (
  requestData: IsaveProjectActualRequest,
  isSaveAs: boolean = false,
  count: number = 0
) => {
  if (projectTreeService.isSyncSuccess() === false) {
    if (count > 9) {
      throw new Error(SYNC_ERROR)
    } else {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      await multifileWaitForSync(requestData, isSaveAs, count + 1)
    }
  } else {
    return await saveLogic(requestData, isSaveAs)
  }
}
/**
 * Save the project to the server
 * @param isSaveAs - Is save as
 * @param projectName - The project name
 * @returns The project response object
 */
const saveProjectActual = async (isSaveAs: boolean = false, projectName: string = '') => {
  let requestData: IsaveProjectActualRequest = {} as IsaveProjectActualRequest
  const libs = useIdeStore().javaLibraries ? useIdeStore().javaLibraries.join(' ') : ''
  if (useIdeStore().isHtml) {
    requestData = htmlEditorService.getProject()
  } else if (useIdeStore().isLanguage === 'blockly') {
    requestData = {
      script: JSON.stringify(blocklyService.getBlocklyScript()),
      versionIndex: useIdeStore().versionIndex,
      isMultiFile: false
    }
  } else if (useIdeStore().isAdvanced) {
    const root = cloneDeep(useIdeStore().project)
    getSanitizedScriptIt(root.treeData)
    const script = JSON.stringify({ home: root.home, treeData: root.treeData })
    requestData = {
      script: script,
      libs: libs,
      versionIndex: useIdeStore().versionIndex,
      projectKey: useIdeStore().projectKey,
      isMultiFile: true
    }
  } else {
    requestData = {
      script: editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue(),
      libs: libs,
      versionIndex: useIdeStore().versionIndex,
      isMultiFile: false
    }
  }
  if (isSaveAs || !useIdeStore().isProject || !useIdeStore().isProjectId) {
    requestData.filename = projectName
    requestData.id = -1
  } else {
    requestData.filename = useIdeStore().isProject.name
    requestData.id = useIdeStore().isProjectId
  }
  requestData.lang = useIdeStore().isLanguage

  if (useIdeStore().isAdvanced) {
    await projectTreeService.syncBeforeExecute()
    return multifileWaitForSync(requestData, isSaveAs)
  } else {
    return await saveLogic(requestData, isSaveAs)
  }
}
/**
 * Auto save the code
 */
const autoSave = async () => {
  if (useIdeStore().isAutoSaveOn && useIdeStore().isProject && useIdeStore().isProjectId) {
    let requestData: IAutoSaveRequest | IsaveProjectActualRequest | null = null
    if (useIdeStore().isHtml) {
      const htmlData = htmlEditorService.getProject()
      requestData = {
        ...htmlData,
        filename: useIdeStore().isProject.name,
        id: useIdeStore().isProjectId,
        lang: useIdeStore().isLanguage
      } as IsaveProjectActualRequest
    } else {
      const libs = useIdeStore().javaLibraries ? useIdeStore().javaLibraries.join(' ') : ''

      if (useIdeStore().isAdvanced) {
        const root = cloneDeep(useIdeStore().project)
        getSanitizedScriptIt(root.treeData)
        const script = JSON.stringify({ home: root.home, treeData: root.treeData })
        const libs = useIdeStore().javaLibraries ? useIdeStore().javaLibraries.join(' ') : ''
        requestData = {
          script: script,
          libs: libs,
          versionIndex: useIdeStore().versionIndex,
          projectKey: useIdeStore().projectKey,
          isMultiFile: true,
          filename: useIdeStore().project.name,
          id: useIdeStore().isProjectId,
          lang: useIdeStore().isLanguage
        }
      } else {
        requestData = {
          script: editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue(),
          libs: libs,
          versionIndex: useIdeStore().versionIndex,
          isMultiFile: false,
          filename: useIdeStore().project.name,
          id: useIdeStore().isProjectId,
          lang: useIdeStore().isLanguage
        }
      }
    }
    await axios.post('/api/doodle/save', requestData)
    useIdeStore().setCodeUpdated(false)
  }
}
/**
 * Clear the current project
 */
const clearProject = () => {
  useIdeStore().setProject(null)
  if (useIdeStore().isHtml) {
    htmlEditorService.resetCodeEditor()
  } else {
    editorService.resetCodeEditor()
    if (useIdeStore().isAdvanced) {
      ideService.initAdvancedIde(ADVANCEDIDETYPE.CLEAR)
    }
  }
}
/**
 * Delete the project from the server
 * @returns The project response object
 */
const deleteProject = async () => {
  if (!useIdeStore().isSelectedProject) return

  const deleteProjectsRequest: IDeleteProjectsRequest = {
    id: useIdeStore().isSelectedProject.id,
    lang: useIdeStore().isLanguage,
    isMultiFile: useIdeStore().isAdvanced
  }

  return await axios
    .post('/api/doodle/deletefile', deleteProjectsRequest)
    .then(() => {
      if (useIdeStore().isProjectId === useIdeStore().isSelectedProject.id) {
        clearProject()
      }
      remove(useIdeStore().isProjects, { id: useIdeStore().isSelectedProject.id })
      if (!useIdeStore().isAdvanced) {
        editorService.setEditorSession(IDECONSTANT.PROJECT_EDITOR, '')
      }
      useIdeStore().setSelectedProject(null)
    })
    .catch((error) => {
      throw error
    })
}
/**
 * Mark the children of a node as yet to sync
 * So that the children can be synced with the server
 * @param root - The root node
 */
const markChildrenYetToSync = (root: any) => {
  for (const node of root.children) {
    if (!node.children) {
      node.yetToSync = true
    } else {
      markChildrenYetToSync(node)
    }
  }
}
/**
 * Open the project in the IDE
 * @param data - The project data
 */
const openProject = (data: any) => {
  useIdeStore().setProject(null)
  if (useIdeStore().isHtml) {
    htmlEditorService.resetCodeEditor()
    useIdeStore().setProject(cloneDeep(data))
    htmlEditorService.setProject(data)
    htmlExecutionService.execute(false)
  } else {
    editorService.resetCodeEditor()

    if (useIdeStore().isAdvanced) {
      data.treeData = data.script.treeData
      data.home = data.script.home
      data.script = null
    }
    useIdeStore().setProject(cloneDeep(data))

    if (useIdeStore().isProject.libraries) {
      useIdeStore().javaLibraries = useIdeStore().isProject.libraries
    }
    if (useIdeStore().isAdvanced) {
      ideService.initAdvancedIde(ADVANCEDIDETYPE.OPEN)
      useIdeStore().setActiveItem()
      for (const node of useIdeStore().isProject.treeData.children) {
        if (!node.children) {
          if ('/' + node.name !== useIdeStore().isProject.home) {
            node.yetToSync = true
          }
        } else {
          markChildrenYetToSync(node)
        }
      }
    } else {
      if (useIdeStore().isBlockly) {
        blocklyService.openBlockly(data.script)
      } else {
        editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, data.script)
      }
    }
    useIdeStore().setVersionIndex(data.versionIndex)
    if (useIdeStore().isBlockly) blocklyService.changeBlocklyLanguage()
  }
  useIdeStore().setCodeUpdated(false)
}
/**
 * Fetch the project from the server
 * @param project The project to fetch
 * @returns The project response object
 */
const loadProject = async (project: IProject) => {
  if (useIdeStore().isSelectedProject && project.id === useIdeStore().isSelectedProject.id) {
    return
  }

  useIdeStore().setSelectedProject(null)

  if (!useIdeStore().isAdvanced) {
    editorService.setEditorSession(IDECONSTANT.PROJECT_EDITOR, '')
  }
  const loadProjectRequest: ILoadProjectsRequest = {
    id: project.id,
    lang: useIdeStore().isHtml ? 'html' : useIdeStore().isLanguage,
    isMultiFile: useIdeStore().isAdvanced
  }
  return await axios
    .post('/api/doodle/file', loadProjectRequest)
    .then((response: { data: any }) => {
      const data = response.data
      if (response.data.project.libraries) {
        data.project.libraries = compact(data.project.libraries.split(' '))
      }

      if (useIdeStore().isAdvanced) {
        data.project.script = JSON.parse(data.project.script)
      }

      useIdeStore().setSelectedProject(data.project)

      let script = ''
      if (useIdeStore().isHtml) {
        const htmlProject = useIdeStore().isSelectedProject as THtmlExecutionHistory
        script = htmlEditorService.convertToHtmlString(
          htmlProject.docType,
          htmlProject.htmlHead,
          htmlProject.htmlBody,
          htmlProject.jsCode,
          htmlProject.cssCode
        )
      } else if (!useIdeStore().isAdvanced) {
        script = useIdeStore().isSelectedProject.script
      }

      if (!useIdeStore().isAdvanced) {
        editorService.setEditorSession(IDECONSTANT.PROJECT_EDITOR, script)
      }
    })
    .catch((error) => {
      throw error
    })
}
/**
 * Fetch the projects from the server
 * @returns The projects response object
 */
const loadProjects = async () => {
  useIdeStore().setSelectedProject(null)
  if (!useAuthStore().isLogedIn) return

  const loadProjectsRequest: ILoadProjectsRequest = {
    lang: useIdeStore().isLanguage,
    isMultiFile: useIdeStore().isAdvanced
  }
  return await axios
    .post('/api/doodle/myfiles', loadProjectsRequest)
    .then((response: { data: { files: any[] } }) => {
      useIdeStore().setProjects(response.data.files)
      return response
    })
    .catch((error) => {
      throw error
    })
}
/**
 * Initialize the file editor
 */
const initProjectEditor = async () => {
  if (!useIdeStore().isAdvanced && !useIdeStore().projectEditor) {
    useIdeStore().projectEditor = editorService.initAceEditor(IDECONSTANT.PROJECT_EDITOR)
    useIdeStore().projectEditor.renderer.setShowGutter(true)
    useIdeStore().projectEditor.setReadOnly(true)
    editorService.codeEditorsSetTheme()
  }
}
/**
 * Refresh when model opens
 */
const refresh = () => {
  useIdeStore().setSelectedProject(null)
  initProjectEditor()
}
/**
 * open a project when router changes
 * @param count - the count
 * @returns The project response object
 */
const initOnRouterChange = async (count: number = 0) => {
  if (!useIdeStore().openProjectID) return
  if (!window['ace'] && useIdeStore().isBlockly && !window['Blockly']) {
    if (count > 30) {
      useIdeStore().setOpenProjectID(null)
      return null
    } else {
      await new Promise((resolve) => setTimeout(resolve, 100))
      initOnRouterChange(count + 1)
    }
  } else {
    const project: IProject = {
      id: useIdeStore().openProjectID as string
    }
    await loadProject(project).then(async () => {
      await openProject(useIdeStore().isSelectedProject)
      useIdeStore().setOpenProjectID(null)
    })
  }
}

export default {
  checkForDubplicateProjectName,
  saveProjectActual,
  autoSave,
  clearProject,
  refresh,
  deleteProject,
  openProject,
  loadProject,
  loadProjects,
  initProjectEditor,
  initOnRouterChange,
  markChildrenYetToSync
}
