import axios from 'axios'

export interface IFeedbackRequest {
  comment: string
  isSurvey: boolean
}
/**
 * @param feedbackRequest The feedback request object
 * @returns The response object containing the user details
 */
const sendFeedback = async (feedbackRequest: IFeedbackRequest) => {
  return await axios
    .post('/api/comment', feedbackRequest)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export default { sendFeedback }
