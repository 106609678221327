<script setup lang="ts">
import { computed } from 'vue'
import { CMD_LANGUAGES } from '@/utils/ide'
import editorService from '@/services/ide/editor.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import { useIdeStore } from '@/stores/ide.store'
import ExecuteComp from './ExecuteComp.vue'

const ideStore = useIdeStore()

const shareNotFound = computed(() => {
  return ideStore.shareNotFound
})
const interactiveMode = computed(() => {
  return ideStore.interactiveMode
})
const versions = computed(() => {
  return ideStore.isVersions
})
const showCommandLine = computed(() => {
  if (!ideStore.isLanguage) {
    return false
  }
  return !CMD_LANGUAGES.includes(ideStore.isLanguage)
})
const isFullScreen = computed(() => {
  return ideStore.isFullScreen
})
/**
 * Checks if the version is selected
 * @param index The version index to check
 * @returns True if the version is selected
 */
const isVersionSelected = (index: number) => {
  return ideStore.versionIndex === index
}
/**
 * Sets the version
 * @param e The event
 */
const setVersion = (e: Event) => {
  ideStore.setVersionIndex(parseInt((e.target as HTMLSelectElement).value))
  ideStore.setCodeUpdated(true)
  blocklyService.changeBlocklyLanguage()
}
/**
 * Resizes the editors
 */
const resizeEditors = async () => {
  await new Promise((resolve) => setTimeout(resolve, 250))
  editorService.resizeCodeEditor()
  editorService.resizeOutputEditor()
  blocklyService.resizeBlockly()
}
</script>

<template>
  <div class="flex flex-col gap-1">
    <div
      class="section-primary hs-accordion-group data-hs-accordion-always-open rounded-lg print:break-inside-avoid print:border print:p-1"
    >
      <div class="hs-accordion active" id="hs-settings">
        <div class="flex w-full flex-col justify-between gap-2 lg:flex-row lg:gap-0">
          <button
            class="hs-accordion-toggle heading-xsmall inline-flex w-fit items-center gap-x-5 break-normal rounded-lg px-2 text-left text-sm no-underline transition hs-accordion-active:rounded-b-none"
            aria-controls="hs-basic-always-open-setting"
            @click="resizeEditors"
          >
            <FontAwesomeIcon
              icon="angle-down"
              class="block h-full w-4 flex-shrink-0 hs-accordion-active:hidden"
            />
            <FontAwesomeIcon
              icon="angle-up"
              class="hidden h-full w-4 flex-shrink-0 hs-accordion-active:block"
            />
            Execute Mode, Version, Inputs & Arguments
          </button>
          <select
            v-if="!shareNotFound"
            id="versionSelect"
            class="section-secondary p-small block w-full rounded-md px-4 py-2 print:border lg:w-fit"
            @change="setVersion($event)"
          >
            <option
              v-for="(version, index) in versions"
              :key="index"
              :selected="isVersionSelected(index)"
              :value="index"
            >
              {{ version }}
            </option>
          </select>
        </div>
        <div
          id="hs-basic-always-open-setting"
          class="hs-accordion-content h-full w-full overflow-hidden rounded-b-lg transition-[height] duration-150"
          aria-labelledby="hs-settings"
        >
          <div
            :class="[
              'flex h-full w-full  flex-col gap-4 p-4 lg:h-60 lg:flex-row',
              {
                'justify-between lg:flex-col': interactiveMode
              }
            ]"
          >
            <div
              v-if="!interactiveMode"
              class="flex h-full w-full flex-col gap-2 print:block print:border"
            >
              <p class="text-sm">Stdin Inputs</p>
              <textarea
                class="section-tertiary p-small block h-full min-h-[40px] w-full resize-none rounded-md p-2 print:border"
                :value="ideStore.stdin || ''"
                @input="(e: Event) => { ideStore.stdin = (e.target as HTMLInputElement).value }"
              />
            </div>
            <div class="flex h-full w-full flex-col gap-4 rounded-lg print:border">
              <div class="flex w-full items-center gap-2 print:hidden">
                <label for="interactiveToggle" class="p-small">Interactive</label>
                <input
                  type="checkbox"
                  id="interactiveToggle"
                  class="checkbox-primary"
                  checked
                  v-model="ideStore.interactiveMode"
                  @change="resizeEditors"
                />
              </div>
              <div v-show="showCommandLine" class="flex h-full w-full flex-col gap-2">
                <p class="text-sm">CommandLine Arguments</p>
                <textarea
                  class="section-tertiary p-small block h-full min-h-[40px] w-full resize-none rounded-md p-2 print:border"
                  :value="ideStore.args || ''"
                  @input="(e: Event) => { ideStore.args = (e.target as HTMLInputElement).value }"
                />
              </div>
            </div>
            <div
              v-if="interactiveMode"
              class="hidden min-h-fit rounded-lg px-1 print:block print:border"
            >
              <label class="p-normal my-2 block px-3"
                >Interactive Mode: {{ interactiveMode ? 'On' : 'Off' }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isFullScreen" class="flex h-full w-full items-center justify-center">
      <ExecuteComp />
    </div>
  </div>
</template>
