<script setup lang="ts">
import { computed } from 'vue'
import projectTreeService from '@/services/ide/projectTree.service'
import editorService from '@/services/ide/editor.service'
import { useIdeStore } from '@/stores/ide.store'
import TreeItem from '@/components/ide/ide/TreeItemComp.vue'

const props = defineProps({
  hideCollapse: {
    type: Boolean,
    default: false,
    required: false
  }
})

const ideStore = useIdeStore()

const httpError = computed(() => {
  return ideStore.syncErrorMessages
})
const isFullScreen = computed(() => {
  return ideStore.isFullScreen
})
const isMinimizeTree = computed(() => {
  return ideStore.minimizeTree
})
const treeData = computed(() => {
  return ideStore.project?.treeData || {}
})
const treeHome = computed(() => {
  return ideStore.project?.home || {}
})

/**
 * Compresses the project tree
 */
const compress = async () => {
  projectTreeService.compressProjectTree()
  await new Promise((resolve) => setTimeout(resolve, 10))
  editorService.resizeCodeEditor()
  editorService.resizeOutputEditor()
}
/**
 * Expands the project tree
 */
const expand = async () => {
  projectTreeService.expandProjectTree()
  await new Promise((resolve) => setTimeout(resolve, 10))
  editorService.resizeCodeEditor()
  editorService.resizeOutputEditor()
}
</script>

<template>
  <div
    :class="[
      'h-32',
      'section-tertiary',
      'rounded-lg',
      'w-full',
      'min-w-fit',
      'relative',
      {
        'h-fit': isMinimizeTree
      },
      {
        'lg:h-full': isFullScreen
      },
      {
        'h-full': !isFullScreen
      }
    ]"
  >
    <div v-show="isMinimizeTree" class="w-full lg:w-12">
      <div class="lg:rotate-90">
        <button class="btn m-0 flex w-44 min-w-full" @click="expand">
          <FontAwesomeIcon icon="fa-folder" class="h-4 w-4" />
          Project Files
        </button>
      </div>
    </div>
    <div
      v-show="!isMinimizeTree"
      :class="[
        'h-full',
        'w-full',
        'flex',
        'flex-col',
        'mx-0',
        {
          absolute: isFullScreen
        }
      ]"
    >
      <div class="mx-5 flex items-center justify-end">
        <button @click="compress" v-if="!props.hideCollapse">
          <FontAwesomeIcon icon="fa-compress" class="h-4 w-4" />
        </button>
      </div>
      <p :class="['p-xsmall ', 'error', 'break-normal', 'max-w-xs', { hidden: !httpError }]">
        {{ httpError }}
      </p>
      <!-- overflow-scroll -->
      <div class="absolute h-full w-full overflow-auto" :class="props.hideCollapse ? 'pt-4' : ''">
        <TreeItem :treeData="treeData" :treeHome="treeHome" />
      </div>
    </div>
  </div>
</template>
