<script lang="ts" setup>
import CaseStudyBanner, { type ICaseStudyBanner } from '@/components/shared/CaseStudyBanner.vue'
import kansas from '@/assets/images/casestudy/kansas/KansasUni2.png'
const caseStudyBannerData: ICaseStudyBanner = {
  orangeTextAboveTitle: 'Case Study',
  title:
    'University of Kansas Assessment and Technology Solutions (ATS) used JDoodle’s API to streamline assessment and automate the compilation process',
  primaryButton: {
    link: '/case-study/kansas',
    text: 'Read More'
  },
  image: {
    src: kansas,
    alt: 'kansas'
  }
}
</script>
<template>
  <div>
    <CaseStudyBanner :sectionData="caseStudyBannerData" />
  </div>
</template>
