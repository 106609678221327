<script lang="ts" setup>
import CaseStudyChallengesAndSolutions from '@/components/shared/casestudy/CaseStudyChallengesAndSolutions.vue'
import outlier3 from '@/assets/images/casestudy/outlier/Outlier.org3.png'

const sectionData = {
  paragraph1:
    'Outlier.org faced a unique challenge in its quest to provide top-notch coding education to first-time learners. While their curriculum was robust and their instructors were top-tier, they needed a way to ensure that students grasped the intricacies of programming languages, particularly Java, effectively. Traditional coding environments often intimidated novice programmers, hindering their learning journeys.',
  qouteText:
    "JDoodle's online Java compiler plugin has been instrumental in helping us deliver a more robust and intuitive learning experience for first-time coders on our platform. The JDoodle team accommodated our custom requests on a tight timeline, demonstrating a dedication to client success and an impressive ability to navigate complex technical challenges.",
  qoutedBy: 'Michael T. Astolfi, VP of Product',
  paragraph2:
    "To address this challenge, Outlier.org turned to JDoodle's online Java compiler plugin. JDoodle's solution offered a seamless and user-friendly coding environment that eliminated the technical barriers faced by many first-time coders. Outlier.org's main objectives were to enhance the learning experience, boost students' confidence, and improve learning outcomes.",
  benefitheading: "JDoodle's plugin provided several key advantages:",
  benefitlist: [
    {
      title: 'Accessibility:',
      desc: 'The plugin allowed students to practice coding from anywhere, eliminating the need for complex software installations.'
    },
    {
      title: 'User-Friendly Interface:',
      desc: 'The intuitive interface reduced the learning curve, enabling students to focus on coding concepts rather than struggling with tool complexities.'
    },
    {
      title: 'Real-Time Feedback:',
      desc: 'JDoodle provided immediate feedback on code execution, helping students correct errors and understand concepts faster.'
    },
    {
      title: 'Customisation:',
      desc: 'The JDoodle team worked closely with Outlier.org to implement custom features tailored to their curriculum, ensuring a perfect fit for their courses.'
    },
    {
      title: 'Robust and Reliable:',
      desc: "JDoodle's platform ensured that students could rely on a stable and consistent coding environment, reducing frustration and boosting confidence."
    }
  ],
  image3: {
    src: outlier3,
    alt: '',
    copyright: '© Pressmaster Signature via Canva.com'
  },
  result: {
    paragraph:
      "The integration of JDoodle's online Java compiler plugin into Outlier.org's curriculum had a profound impact on student learning experiences and outcomes.",
    list: [
      {
        title: 'Improved Engagement:',
        desc: 'With a user-friendly and accessible coding environment, student engagement increased significantly. They were more likely to actively participate in coding exercises and assignments.'
      },
      {
        title: 'Higher Completion Rates:',
        desc: 'The simplified coding platform reduced dropout rates, as students were less likely to become discouraged by technical challenges.'
      },
      {
        title: 'Enhanced Learning:',
        desc: 'Real-time feedback and custom features enabled students to grasp Java concepts faster and more effectively, resulting in improved comprehension and retention.'
      },
      {
        title: 'Greater Confidence:',
        desc: 'Students reported increased confidence in their coding abilities, which positively influenced their overall performance.'
      },
      {
        title: 'Success Stories:',
        desc: "Outlier.org witnessed numerous success stories of students who, thanks to JDoodle's plugin, overcame their initial apprehension of coding and went on to excel in their coursework."
      }
    ]
  }
}
</script>

<template>
  <CaseStudyChallengesAndSolutions :sectionData="sectionData" />
</template>
