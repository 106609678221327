<script setup lang="ts">
import { ref, computed, onMounted, onBeforeMount } from 'vue'

defineProps({
  credits: {
    default: {
      used: 0,
      quota: 0
    }
  }
})

const ring = ref(0)
const height = computed(() => {
  return width.value + 'px'
})
const width = ref(40)

const cx = computed(() => r.value + strokeWidth + 10)
const cy = computed(() => r.value + strokeWidth + 10)
const r = computed(() => Math.floor(width.value / 2 - 20))
const strokeWidth = 11
const circumference = computed(() => r.value * 2 * Math.PI)

onMounted(() => {
  handleResize()
  window.addEventListener('resize', handleResize)
})
onBeforeMount(() => {
  window.removeEventListener('resize', handleResize)
})

/**
 *
 */
function handleResize() {
  // @ts-ignore
  width.value = ring.value.clientWidth
}
</script>

<template>
  <div class="mb-10 mt-2 w-full text-gray-100" ref="ring" :style="`height:${height}`">
    <div class="relative flex h-full w-full items-center justify-center">
      <svg class="h-full w-full -rotate-90 transform">
        <circle
          :cx="cx"
          :cy="cy"
          :r="r"
          stroke="currentColor"
          :stroke-width="strokeWidth"
          fill="transparent"
          class="text-white opacity-50 dark:text-black"
        />

        <circle
          :cx="cx"
          :cy="cy"
          :r="r"
          stroke="currentColor"
          :stroke-width="strokeWidth + 0.5"
          fill="transparent"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="circumference - (credits.used / credits.quota) * circumference"
          class="relative text-text-brand"
        />
      </svg>

      <div
        class="heading-large absolute bottom-0 top-0 flex flex-col justify-center text-center font-semibold text-gray-900 dark:text-white"
      >
        {{ credits.used || 0 }}
        of
        {{ credits.quota }}
        <p class="text-secondary mt-1 text-sm">Total Available Credit</p>
      </div>

      <span class="text-secondary heading-medium absolute bottom-0 right-0">
        {{ Math.floor(credits.used / credits.quota) }}%
      </span>
    </div>
  </div>
</template>
