<script lang="ts" setup>
import ModelLayout from '@/layouts/ModelLayout.vue'

import { TEACHMODALHS } from '@/utils/models'
import { deleteQuestion } from '@/services/teach.service'
import { HttpStatusCode } from 'axios'
import router from '@/router'

const props = defineProps({
  questionId: {
    type: Number,
    required: false
  },
  assignmentId: {
    type: Number,
    require: true
  },
  instituteCode: {
    type: String,
    require: true
  }
})

/**
 *
 */
const removeQuestion = async () => {
  const res = await deleteQuestion(props.questionId!, props.assignmentId!, props.instituteCode!)

  if (res === HttpStatusCode.Ok) {
    router.go(0)
  }
}

//static Data
const modalTitle = 'Remove Question'
const message = 'Are you sure, do you want to remove this question?'

/**
 *
 */
</script>

<template>
  <ModelLayout
    :title="modalTitle"
    :hs="TEACHMODALHS.REMOVE_QUESTION_CONFIRMATION"
    :loadDataWhenClose="false"
  >
    <p class="my-5 break-normal text-center text-sm">{{ message }}</p>

    <div class="mt-8 flex justify-center gap-5">
      <button class="btn-secondary rounded-md px-5 py-2" @click="removeQuestion">Yes</button>

      <button class="btn-primary rounded-md px-5 py-2" data-hs-overlay-close>No</button>
    </div>
  </ModelLayout>
</template>
