<script setup lang="ts">
import SixItemsPanel, { type ISixItems } from '@/components/shared/SixItemsPanel.vue'
const featuresGridData: ISixItems[] = [
  {
    icon: 'fa-user-group',
    heading: 'Real Time Collaboration',
    body: 'Edit the same code in real-time, fostering seamless collaboration and brainstorming. Built-in chat functionality to communicate instantly.'
  },
  {
    icon: 'fa-gear',
    heading: 'Multi-Language Support',
    body: "JDoodle's Cloud IDE supports 76 programming languages.Work with multiple languages within the same project."
  },
  {
    icon: 'fa-cube',
    heading: 'Integrated Package Management',
    body: 'Easily add and manage libraries and dependencies for various languages. Users can specify package versions or use the latest ones.'
  },
  {
    icon: 'fa-link',
    heading: 'Interactive Input/Output Streams',
    body: 'Developers can interact with their code by providing custom inputs. See the code output instantly, making it easier to debug and analyse.'
  },
  {
    icon: 'fa-cloud',
    heading: 'Cloud-Based Storage and Sharing',
    body: 'Save your projects and code snippets in the cloud. Share your code securely with collaborators, enabling code reviews and joint debugging.'
  },
  {
    icon: 'fa-heart',
    heading: 'Personalised IDE Experience',
    body: "JDoodle's IDEs empower coders to tailor their coding environment with customisable themes, extensions and plugins."
  }
]
</script>

<template>
  <div class="section-primary bg-gray-50 py-16">
    <div class="view-container">
      <div class="mb-8">
        <span class="title-feature">Why Jdoodle</span>
        <h2 class="title-heading max-w-2xl">Experience instant coding right from your browser.</h2>
        <p class="title-description max-w-lg">
          Our platform offers robust tools, innovative AI capabilities and effortless multiplayer
          support.
        </p>
      </div>
      <SixItemsPanel :sixItems="featuresGridData" />
    </div>
  </div>
</template>
