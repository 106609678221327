<script setup lang="ts">
// @ts-ignore
import { VueMarqueeSlider } from 'vue3-marquee-slider'
import { computed } from 'vue'
import { institutesForDark, institutesForLight } from '@/utils/sharedData/institutes'
import { useColorMode } from '@vueuse/core'
const colorTheme = useColorMode()

const institutes = computed(() => {
  return colorTheme.value == 'dark' ? institutesForDark : institutesForLight
})
</script>

<template>
  <div class="section-secondary overflow-hidden py-6 sm:pb-16 sm:pt-12">
    <div class="text-secondary view-container text-md mb-6 text-center md:text-lg">
      10,000+ education providers trust us for their coding needs
    </div>
    <VueMarqueeSlider id="marquee-slider" :speed="60000" :space="20" :width="190">
      <div
        v-for="(img, index) in institutes"
        :key="index"
        class="flex w-[100px] items-center justify-center gap-10"
      >
        <img :src="img.src" :alt="img.alt" class="max-h-[70px] w-fit" />
      </div>
    </VueMarqueeSlider>
  </div>
</template>

<style lang="css">
._marqueeSliderContainer_1htck_11 {
  width: 100%;
  animation-name: _horizontalAnimation_1htck_1;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: flex;
}

@keyframes _horizontalAnimation_1htck_1 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
