<script setup lang="ts"></script>

<template>
  <div class="flex flex-col justify-between gap-1 print:break-inside-avoid print:border print:p-1">
    <p class="heading-xsmall text-left text-sm">Result</p>
    <div class="h-96 w-full rounded-lg bg-white">
      <iframe
        name="output"
        frameborder="0"
        height="100%"
        width="100%"
        sandbox=" allow-forms allow-popups allow-scripts allow-same-origin allow-modals"
      ></iframe>
    </div>
  </div>
  <div class="hidden">
    <form
      id="outputForm"
      name="html-download-form"
      method="post"
      action="/api/doodle/download/web.html"
    >
      <textarea id="outputTextArea" name="code"></textarea>
    </form>
  </div>
</template>
