<script setup lang="ts">
import CaseStudyDemoCTA, {
  type IDemoCTATextData
} from '@/components/shared/casestudy/CaseStudyDemoCTA.vue'

const sectionData: IDemoCTATextData = {
  textHeading: 'Execute code within your platform with JDoodle’s Compiler API',
  textSubheading: 'Keep students focused and immersed in your course content.',
  getStartedLink: '/pricing/api',
  learnMoreLink: '/intergrate-online-ide-compiler-api-plugins'
}
</script>

<template>
  <CaseStudyDemoCTA :sectionData="sectionData" />
</template>
