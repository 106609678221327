<script lang="ts" setup>
import CaseStudyBannerImageAndHeading, {
  type ICaseStudyBInHData
} from '@/components/shared/casestudy/CaseStudyBannerImageAndHeading.vue'

import linkgroupBanner from '@/assets/images/casestudy/linkgroup/LinkGroup1.png'

const sectionData: ICaseStudyBInHData = {
  image: {
    src: linkgroupBanner,
    alt: '',
    copyright: '© mediaphotos from Getty Images Signature via Canva.com'
  },
  heading: 'Compiler Plugins Improve Functionality and Interactivity of Teaching Materials',
  sHeading: 'Solve the Challenges of Delivering Effective and Engaging Content',
  clientName: 'LINK Group',
  date: 'January 2023'
}
</script>

<template>
  <CaseStudyBannerImageAndHeading :sectionData="sectionData" />
</template>
