//Model
// Auth
export const AUTHMODELHS = {
  LOGIN: 'hs-login-modal',
  REGISTER: 'hs-register-modal',
  FORGOTPASSWORD: 'hs-forgot-password-modal'
}
export const PROFILEMODELHS = {
  CHANGEPASSWORD: 'hs-change-password-modal',
  CHANGEUSERNAME: 'hs-change-username-modal'
}

export const HOMEVIEWMODELS = {
  ONLINECOMPILER: 'hs-online-compiler-modal'
}
// ide
export const IDEVIEWMODELS = {
  SETTINGS: 'hs-settings-modal',
  SHARE: 'hs-share-modal',
  LIBRARIES: 'hs-libraries-modal',
  NEWPROJECT: 'hs-new-project-modal',
  NOTLOGGEDIN: 'hs-not-logged-in-modal',
  MYPROJECTS: 'hs-my-projects-modal',
  EXECUTEHISTORY: 'hs-execute-history-modal',
  SAVE: 'hs-save-modal',
  EDITABLESHARE: 'hs-editable-share-modal',
  INSTANTSHARE: 'hs-instant-share-modal',
  COPY: 'hs-copy-modal',
  DOWNLOAD: 'hs-download-modal',
  OPENFROMFILE: 'hs-open-from-file-modal'
}

export const DASHBOARD = {
  CREATE_PLUGIN: 'hs-create-plugin-modal',
  RENAME_PLUGIN: 'hs-rename-plugin-modal'
}

// feedback
export const FEEDBACKMODELHS = {
  FEEDBACK: 'hs-feedback-modal'
}

//guru
export const TEACHMODALHS = {
  REGISTER_INSTITUTE: 'hs-register-institute-modal',
  //Assignments
  CREATE_ASSIGNMENT: 'hs-create-assignment-modal',
  ACTIVATE_ASSIGNMENT: 'hs-activate-assignment',
  CREATE_QUESTION: 'hs-create-question-modal',
  CREATE_TESTCASE: 'hs-create-testcase',
  ADD_USER_STUDENT: 'hs-search-add-user-student',
  ADD_USER_STAFF_OR_TEACHER: 'hs-search-add-user-staff-or-teacher',
  IMPORT_STUDENT_GROUP: 'hs-import-student-group',

  //Student Group
  ADD_STUDENT_GROUP: 'hs-add-student-group',
  DELETE_STUDENT_GROUP_CONFIRMATION: 'hs-delete-student-group-confirmation',

  //Confirmation
  INVITE_CONFIRMATION: 'hs-invite-confirmation',
  ON_HOLD_CONFIRMATION: 'hs-on-hold-confirmation',
  DELETE_ASSIGNMENT_CONFIRMATION: 'hs-delete-assignment-confirmation',
  SUBMIT_ASSIGNMENT_CONFIRMATION: 'hs-submit-assignment-confirmation',
  EVALUATED_CONFIRMATION: 'hs-evaluated-confirmation',
  AUTO_EVALUATION_CONFIRMATION: 'hs-auto-evaluation-confirmation',
  REOPEN_EVALUATION_CONFIRMATION: 'hs-reopen-evaluation-confirmation',
  REOPEN_SUBMISSION_CONFIRMATION: 'hs-reopen-submission-confirmation',
  DISCLOSE_CONFIRMATION: 'hs-disclose-result-confirmation',
  CHANGE_ACTIVATION_CONFIRMATION: 'hs-change-activation-modal',
  REQUEST_REEVALUATION: 'hs-request-reevaluation-modal',

  //Errors
  ERROR_INSTRUCTION: 'hs-error-instruction',
  ERROR_NOT_READY_TO_EVALUATE: 'hs-not-ready-to-evaluate',
  //Delete/Remove Modals
  REMOVE_QUESTION_CONFIRMATION: 'hs-delete-question-modal',
  REMOVE_USER_STUDENT_CONFIRMATION: 'hs-remove-student-modal',
  REMOVE_USER_STAFF_OR_TEACHER_CONFIRMATION: 'hs-remove-teacher-modal',
  REMOVE_USER_CONFIRMATION: 'hs-remove-user-modal',
  EDIT_USER: 'hs-edit-user-modal',
  //Shared
  ADD_USER: 'hs-add-user-modal'
}

//Subscriptions
export const SUBSCRIPTION_MODAL = {
  SUBSCRIBE: 'hs-subscribe',
  CANCEL: 'hs-cancel-subscribe'
}
