<script setup lang="ts">
import { initGuestAxios } from '@/services/api.service'
import authService from '@/services/auth.service'
import { onBeforeMount } from 'vue'
import { RouterView } from 'vue-router'

onBeforeMount(() => {
  initGuestAxios()
  authService.initGuestAuth()
})
</script>

<template>
  <RouterView />
</template>
