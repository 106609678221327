import editorService from '@/services/ide/editor.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import { COMPONENTS, DEFAULTLAYPUTS, TYPES } from '@/utils/customPlugin'
import axios from 'axios'
import { cloneDeep } from 'lodash'
/**
 * update the layouts
 */
const updateLayouts = () => {
  const layouts = cloneDeep(usePluginStore().layouts)
  layouts[usePluginStore().screenSize] = cloneDeep(usePluginStore().layoutByScreenSize)
  usePluginStore().setLayouts(layouts)
}
/**
 * get each individiual component size data and set width and heights
 * @param component  - The component to set
 * @param node  - The node to set
 */
const onReseizeHeightComponent = async (component: any, node: HTMLElement) => {
  if (node && usePluginStore().isCusomPlugin) {
    const componentLayout = usePluginStore().layoutByScreenSize.find(
      (item: any) => item.name === component.name
    )
    if (componentLayout) {
      if (componentLayout.h < component.settings.minH) {
        componentLayout.h = component.settings.minH
      }
      if (node.id === COMPONENTS.OUTPUT || node.id === COMPONENTS.IDE) {
        const height = componentLayout.h * 100
        node.style.height = `${height}px`
        editorService.resizeCodeEditor()
        editorService.resizeOutputEditor()
        blocklyService.resizeBlockly()
      } else {
        node.style.height = 'max-content'
        await new Promise((resolve) => setTimeout(resolve, 100))
        const height = node?.clientHeight
        const newHeight = height / 100
        if (componentLayout.h !== newHeight) componentLayout.h = newHeight
        node.style.height = `${Math.round(newHeight * 100)}px`
      }
    }
  }
}
/**
 * reseize all components manually
 */
const reseizeAllComponents = () => {
  usePluginStore().components.forEach((component: any) => {
    const node = document.getElementById(component.name)
    onReseizeHeightComponent(component, node as HTMLElement)
  })

  if (usePluginStore().isShowCustomPlugin) updateLayouts()
}

/**
 * reseize all components manually
 * @param newBreakpoint - The newBreakpoint to set
 */
const onContainerResize = async (newBreakpoint: any) => {
  usePluginStore().setScreenSize(newBreakpoint)
  reseizeAllComponents()
  editorService.resizeCodeEditor()
  editorService.resizeOutputEditor()
  blocklyService.resizeBlockly()
}
/**
 * Initialize custom plugin by getting the plugin data
 * @param uiData - The uiData to set
 */
const initLayouts = async (uiData: any) => {
  if (uiData) {
    const layouts = JSON.parse(cloneDeep(uiData))
    usePluginStore().setLayouts(layouts)
    usePluginStore().setLayoutByScreenSize(layouts[usePluginStore().screenSize])
  } else {
    const layouts = cloneDeep(DEFAULTLAYPUTS)
    usePluginStore().setLayouts(cloneDeep(layouts))
    usePluginStore().setLayoutByScreenSize(layouts[usePluginStore().screenSize])
  }
  await new Promise((resolve) => setTimeout(resolve, 100))
  usePluginStore().setShowCustomPlugin(true)
}
/**
 * Initialize custom plugin by getting the plugin data
 * @param features - The features to set
 * @param type - The type to set
 */
const initFeatures = async (features: any, type: string) => {
  if (!features) return
  const featuresData = JSON.parse(features)

  if (featuresData && type === TYPES.BASIC) {
    if (featuresData.defautlLanguage)
      usePluginStore().setDefautlLanguageIndex(featuresData.defautlLanguage)
    if (featuresData.defaultVersion)
      usePluginStore().setDefautlVersionIndex(featuresData.defaultVersion)
    if (featuresData.program) usePluginStore().setDefaultScript(JSON.parse(featuresData.program))
    if (featuresData.interactiveMode) useIdeStore().interactiveMode = featuresData.interactiveMode
    if (featuresData.args) useIdeStore().args = featuresData.args
    if (featuresData.stdin) useIdeStore().stdin = featuresData.stdin
  }
  if (featuresData && featuresData.functionals && featuresData.functional !== null) {
    const functionalsData = JSON.parse(featuresData.functionals)
    if (functionalsData && functionalsData.length > 0) {
      usePluginStore().setSelectedFunctionals(functionalsData)
    }
  }
}
/**
 * Initialize custom plugin by getting the plugin data
 * @returns axios response
 */
const initCustomPlugin = async () => {
  const requestData = {
    clientId: usePluginStore().isClientkey,
    pluginKey: usePluginStore().isCustomkey
  }
  return await axios
    .post('/api/plugin/getPluginByKey', requestData)
    .then(
      (response: {
        data: {
          type: string
          pluginConfig: {
            uiData: string
            features: string
          }
        }
      }) => {
        usePluginStore().setPluginResponse(response.data)
        const { uiData } = response.data.pluginConfig
        initLayouts(uiData)
        const { type } = response.data
        const { features } = response.data.pluginConfig
        initFeatures(features, type)
        return response
      }
    )
    .catch((error: { response: { message: string } }) => {
      throw error
    })
}
export default {
  initFeatures,
  initLayouts,
  initCustomPlugin,
  reseizeAllComponents,
  onContainerResize
}
