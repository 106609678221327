import Dark_GeekHunter from '@/assets/images/shared/reviews/businesses/Dark_GeekHunter.svg'
import Dark_Linkgroup from '@/assets/images/shared/reviews/businesses/Dark_Linkgroup.png'
import Dark_Outlier from '@/assets/images/shared/reviews/businesses/Dark_Outlier.png'
import Dark_UniversityKansas from '@/assets/images/shared/reviews/businesses/Dark_UniversityKansas.svg'
import Light_GeekHunter from '@/assets/images/shared/reviews/businesses/Light_GeekHunter.svg'
import Light_Linkgroup from '@/assets/images/shared/reviews/businesses/Light_LinkGroup.png'
import Light_Outlier from '@/assets/images/shared/reviews/businesses/Light_Outlier.svg'
import Light_UniversityKansas from '@/assets/images/shared/reviews/businesses/Light_UniversityKansas.svg'
import itext from '@/assets/images/shared/reviews/businesses/itext.svg'
import kodezi from '@/assets/images/shared/reviews/businesses/kodezi.png'
import user_fd from '@/assets/images/shared/reviews/customers/user_fd.png'
import user_jm from '@/assets/images/shared/reviews/customers/user_jm.png'
import user_nhr from '@/assets/images/shared/reviews/customers/user_nhr.png'
import user_vf from '@/assets/images/shared/reviews/customers/user_vf.png'
import type { ICarouselItem } from '@/components/shared/CardCarousel.vue'

export const customersReviewData = [
  {
    name: 'Nitin Hema Raj',
    positionOrCompany: 'Student',
    review:
      'JDoodle offers a straightforward and effective online Python compiler. It supports multiple Python versions and provides a clean interface for writing and testing code.',
    profilePic: {
      src: user_nhr,
      alt: 'JDoodle Student'
    }
  },
  {
    name: 'Vladimir Filippov',
    positionOrCompany: 'Teacher, Academic Degree ',
    review:
      'An excellent and ideal tool for training school children in sports programming. An indispensable and quite convenient set of online compilers.',
    profilePic: {
      src: user_vf,
      alt: 'JDoodle Teacher'
    }
  },
  {
    name: 'Joel Macwan',
    positionOrCompany: 'Co-founder, Pixeltec',
    review:
      'If you are looking for a great online programming platform, I highly recommend JDoodle. The platform is very well designed and easy to navigate.',
    profilePic: {
      src: user_jm,
      alt: 'Co-founder of a company'
    }
  },
  {
    name: 'Frank Delporte',
    positionOrCompany: 'Java Champion, Foojay.io',
    review:
      "I created a Java tutorial on Foojay.io, designed to help anyone interested to get started with the language. With the seamless integration of JDoodle, readers can experiment right on the tutorial's web pages, eliminating the need for PC installations.",
    profilePic: {
      src: user_fd,
      alt: 'Java Champion'
    }
  }
]

export const businessReviews: ICarouselItem[] = [
  {
    name: 'Michael T. Astolfi',
    positionOrCompany: 'VP of Product, Outlier',
    review:
      "Doodle's online Java compiler plugin has been instrumental in helping us deliver a more robust and intuitive learning experience for first-time coders on our platform",
    profilePic: {
      srcDark: Dark_Outlier,
      srcLight: Light_Outlier,
      alt: 'JDoodle Student'
    }
  },
  {
    name: 'André Lemos',
    positionOrCompany: 'iText, VP of Product',
    review:
      "JDoodle's API enabled us to showcase the power of the iText library in a super convenient and friendly way to our prospects, with no setup time, trial forms or project setup.",
    profilePic: {
      srcDark: itext,
      srcLight: itext,
      alt: 'VP of Product from iText'
    }
  },
  {
    name: 'Ishraq Khan',
    positionOrCompany: 'Kodezi, Founder',
    review:
      "JDoodle makes coding easy with their straightforward API for running code online. It's a solid, user-friendly tool we'd suggest to anyone in a heartbeat.",
    profilePic: {
      srcDark: kodezi,
      srcLight: kodezi,
      alt: 'Founder of a Kodezi'
    }
  },
  {
    name: 'Dragoljub Ćatović',
    positionOrCompany: 'E-Learning Coordinator, LINK Edu Alliance',
    review:
      "JDoodle's plugin created an extension for our LMS system that has improved the functionality and interactivity of our teaching materials.",
    profilePic: {
      srcDark: Dark_Linkgroup,
      srcLight: Light_Linkgroup,
      alt: 'E-Learning Coordinator of LINK Group'
    }
  },
  {
    name: 'João Mello',
    positionOrCompany: 'Head of Engineering, GeekHunter',
    review:
      "JDoodle has been indispensable as a tool for our core business for the past years. It's simple to integrate, reliable and cost efficient. ",
    profilePic: {
      srcDark: Dark_GeekHunter,
      srcLight: Light_GeekHunter,
      alt: 'Head of Engineering of GeekHunter'
    }
  },
  {
    name: 'Susan Dressler',
    positionOrCompany: 'ATS Director, University of Kansas',
    review:
      'JDoodle has a history of providing us prompt support and willingness to help troubleshoot issues. Their team has provided a consistent service with great documentation.',
    profilePic: {
      srcDark: Dark_UniversityKansas,
      srcLight: Light_UniversityKansas,
      alt: 'ATS Director of University of Kansas'
    }
  }
]
