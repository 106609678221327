<script setup lang="ts">
import { loginThenRedirect } from '@/services/subscription.service'
import {
  SUBSCRIPTION_PRODUCT,
  SUBSCRIPTION_PLAN_NAME
} from '@/components/shared/subscription/enum/subscription.enum'
/**
 * If logged-in -> link or modal, otherwise login modal
 */
const buttonAction = () => {
  const planName = SUBSCRIPTION_PLAN_NAME.TEAM
  const button = {
    buttonText: 'Start Now',
    product: SUBSCRIPTION_PRODUCT.TEACH
  }
  loginThenRedirect(button, planName)
}
</script>

<template>
  <div class="flex flex-col items-center gap-8">
    <div class="section-primary text-secondary flex h-full w-full justify-center rounded-lg p-10">
      <div class="flex w-fit flex-col items-center gap-4 text-center">
        <h3 class="heading-small">You do not have any active Plugin Plan</h3>
        <p>
          Integrate JDoodle’s Free IDE plugins with 3 simple lines of code and start executing code
          on your platform or subscribe to our Platform Team Plan to avail premium plugin benefits
          like:
        </p>
        <ul class="flex flex-col gap-4 text-start leading-7">
          <li>
            <FontAwesomeIcon icon="fa-check" class="mr-2 h-2 w-2 rounded-full p-1 text-red-500" />
            White labeled plugins and free commercial use
          </li>
          <li>
            <FontAwesomeIcon icon="fa-check" class="mr-2 h-2 w-2 rounded-full p-1 text-red-500" />
            Multiple custom plugins
          </li>
          <li>
            <FontAwesomeIcon icon="fa-check" class="mr-2 h-2 w-2 rounded-full p-1 text-red-500" />
            A fully customizable dashboard
          </li>
          <li>
            <FontAwesomeIcon icon="fa-check" class="mr-2 h-2 w-2 rounded-full p-1 text-red-500" />
            Premium access to teach and assess platform
          </li>
        </ul>
      </div>
    </div>
    <button class="btn btn-primary w-fit" @click="buttonAction">
      Subscribe Now
      <FontAwesomeIcon icon="fa-arrow-right" class="ms-1" />
    </button>
  </div>
</template>
