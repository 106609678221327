export enum SUBSCRIPTION_TYPE_ENUM {
  BUNDLE = 'Bundle',
  COMPILER_API = 'Compiler API'
}

export enum SUBSCRIPTION_PLAN_NAME {
  FREE = 'Free',
  TEAM = 'Team'
}

export enum PLATFORM_PLAN_VALUE {
  FREE = 'pFree',
  PRO = 'p100'
}

export enum SUBSCRIPTION_PRODUCT {
  TEACH = 'Teach and Assess',
  COMPILER_API = 'Compiler API',
  CODE = 'Code'
}
