<script lang="ts" setup></script>

<template>
  <section>
    <div class="container mx-auto px-4">
      <div class="-mx-4 flex flex-wrap justify-center text-center">
        <div class="mb-8 w-full px-4 md:w-1/3 lg:mb-0 lg:w-1/4">
          <h2
            class="mb-2 text-4xl font-bold tracking-tighter text-text-tertiary dark:text-text-tertiary-dark md:text-5xl"
          >
            1 Million
          </h2>
          <p class="text-coolGray-500 text-lg font-medium md:text-xl">Monthly Users</p>
        </div>
        <div class="mb-8 w-full px-4 md:w-1/3 lg:mb-0 lg:w-1/4">
          <h2
            class="mb-2 text-4xl font-bold tracking-tighter text-text-tertiary dark:text-text-tertiary-dark md:text-5xl"
          >
            10K+
          </h2>
          <p class="text-coolGray-500 text-lg font-medium md:text-xl">Institutional Signups</p>
        </div>
        <div class="mb-8 w-full px-4 md:w-1/3 lg:mb-0 lg:w-1/4">
          <h2
            class="mb-2 text-4xl font-bold tracking-tighter text-text-tertiary dark:text-text-tertiary-dark md:text-5xl"
          >
            76
          </h2>
          <p class="text-coolGray-500 text-lg font-medium md:text-xl">Programming Languages</p>
        </div>
        <div class="mb-8 w-full px-4 md:w-1/3 lg:mb-0 lg:w-1/4">
          <h2
            class="mb-2 text-4xl font-bold tracking-tighter text-text-tertiary dark:text-text-tertiary-dark md:text-5xl"
          >
            2
          </h2>
          <p class="text-coolGray-500 text-lg font-medium md:text-xl">Databases</p>
        </div>
      </div>
    </div>
  </section>
</template>
