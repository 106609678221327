<script setup lang="ts">
import moment from 'moment'
import DoodleMessage from '@/components/utils/DoodleMessage.vue'
import { ref, watch, type PropType, computed } from 'vue'
import { showUpdateCreditCard } from '@/services/payment.service'
import { findIndex, delay } from 'lodash'
import router from '@/router'
import {
  cancelBundleSubscription,
  getApiPaymentMethod,
  getBundlePaymentMethod,
  subscribeToApi,
  subscribeToBundle
} from '@/services/subscription.service'
import { useSubscribeStore } from '@/stores/subscribe.store'
import type { IApiPlan, IBundledPlan } from '@/utils/sharedData/plans'
import {
  PLATFORM_PLAN_VALUE,
  SUBSCRIPTION_PLAN_NAME,
  SUBSCRIPTION_TYPE_ENUM
} from '@/components/shared/subscription/enum/subscription.enum'
import type { ISubscribeRequest } from './interface/ISubscribeRequest'
// import { useAuthStore } from '@/stores/auth.store'

const subscribeStore = useSubscribeStore()
const props = defineProps({
  subscriptionType: {
    type: String as PropType<SUBSCRIPTION_TYPE_ENUM>,
    required: true
  },
  tabProperties: {
    required: true,
    type: Object as PropType<any>
  },
  plans: {
    required: true,
    type: Array<IApiPlan | IBundledPlan>
  }
})

const completed = ref(false)
const errorMessage = ref<string>('')
const subscribeSuccess = ref(false)
const clickDisabled = ref(false)

const selectedPlan = ref<IApiPlan | IBundledPlan | undefined>(undefined)

// const showUpgradeNotes = ref(false)
const showCCInSubscription = ref(false)

const stripCard = ref<any>(null)
const stripe = ref<any>(null)

// For when plan is selected
watch(
  () => subscribeStore.selectedPlan,
  (plan) => {
    choose(plan)
  },
  { immediate: true }
)

const emit = defineEmits(['planChange'])

// ===== Don't delete yet - need to test with subscribed plans =====

// function checkShowUpgradeNotes() {
//   if (props.tabProperties.clientPlan) {
//     let currentPlanIndex = findIndex(props.plans, {
//       name: props.tabProperties.clientPlan
//     })
//     let newPlanIndex = findIndex(props.plans, { name: selectedPlan.value?.name })
//     if (newPlanIndex > currentPlanIndex) {
//       showUpgradeNotes.value = true
//     } else {
//       showUpgradeNotes.value = false
//     }
//   }
// }

const showUpgradeNotes = computed(() => {
  if (props.tabProperties.clientPlan) {
    let currentPlanIndex = findIndex(props.plans, {
      name: props.tabProperties.clientPlan
    })
    let newPlanIndex = findIndex(props.plans, { name: selectedPlan.value?.name })

    if (newPlanIndex > currentPlanIndex) {
      return true
    } else {
      return false
    }
  }
  return false
})

/**
 * Called when clicking onto a plan button
 * @param planName plan name enum or undefined
 */
async function choose(planName: SUBSCRIPTION_PLAN_NAME | undefined) {
  // if (!useAuthStore().isLogedIn) {
  //   return
  // }

  if (planName === props.tabProperties.clientPlan) {
    return
  }

  if (
    props.tabProperties.isUnlimited ||
    props.tabProperties.isSubscriptionCancelled ||
    props.tabProperties.clientFuturePlan
  ) {
    return
  }

  if (props.subscriptionType == SUBSCRIPTION_TYPE_ENUM.COMPILER_API) {
    selectedPlan.value = props.plans.find((plan: IBundledPlan) => plan.name == planName)
  } else {
    selectedPlan.value = props.plans.find((plan: IBundledPlan) => plan.type == planName)
  }

  if (planName == 'Free') {
    return
  }

  try {
    let data = undefined

    if (props.subscriptionType == SUBSCRIPTION_TYPE_ENUM.BUNDLE) {
      data = await getBundlePaymentMethod()
    } else {
      data = await getApiPaymentMethod()
    }

    let expires = moment(data.expires, 'MM/YYYY')
    if (expires.isBefore(new Date())) {
      showCCInSubscription.value = true
      showUpdateCreditCard(
        props.tabProperties.stripeKey,
        stripCard,
        stripe,
        completed.value,
        errorMessage
      )
    } else {
      showCCInSubscription.value = false
    }
  } catch (err: any) {
    if (err.response?.status === 402) {
      showCCInSubscription.value = true
      showUpdateCreditCard(
        props.tabProperties.stripeKey,
        stripCard,
        stripe,
        completed.value,
        errorMessage
      )
    }
  }
}

/**
 *
 */
// function onApiPlanChange() {
//   clickDisabled.value = false
//   // checkShowUpgradeNotes()
// }

/**
 *
 */
function closeSubscriptionForm() {
  subscribeStore.selectPlan(undefined)
  subscribeStore.closeSubscribeModal()
}

/**
 *
 * @param token any
 */
async function proceedWithSubscription(token: any) {
  subscribeSuccess.value = false

  const reqObj: ISubscribeRequest = {
    plan:
      props.subscriptionType == SUBSCRIPTION_TYPE_ENUM.COMPILER_API
        ? (selectedPlan.value! as IApiPlan).value
        : (selectedPlan.value as IBundledPlan).name!,
    token: token
  }

  try {
    if (props.subscriptionType == SUBSCRIPTION_TYPE_ENUM.BUNDLE) {
      //Cancel paid -> Free
      if (
        selectedPlan.value?.value == PLATFORM_PLAN_VALUE.FREE ||
        selectedPlan.value?.name == PLATFORM_PLAN_VALUE.FREE
      ) {
        await cancelBundleSubscription()
      } else {
        //subscribe to paid
        await subscribeToBundle(reqObj)
      }
    } else {
      //Subscribe to Api
      await subscribeToApi(reqObj)
    }
    subscribeSuccess.value = true
    emit('planChange')
    errorMessage.value = 'Congrats! You have subscribed the plan successfully.'

    delay(() => {
      subscribeStore.selectPlan(undefined)
      subscribeStore.closeSubscribeModal()

      if (props.subscriptionType == SUBSCRIPTION_TYPE_ENUM.BUNDLE) {
        router.push('/dashboard')
      } else if (props.subscriptionType == SUBSCRIPTION_TYPE_ENUM.COMPILER_API) {
        router.push('/dashboard/api')
      }
    }, 1000)
  } catch (err: any) {
    errorMessage.value = err.response.data.message
  }

  clickDisabled.value = false
}
/**
 *
 */
function submitPaymentInfo() {
  stripe.value.createToken(stripCard.value).then((res: any) => {
    if (res.error) {
      errorMessage.value = res.error.message
      clickDisabled.value = false
    } else {
      proceedWithSubscription(res.token.id)
    }
  })
}

/**
 *
 */
function subscribePlan() {
  clickDisabled.value = true
  if (selectedPlan.value?.name !== SUBSCRIPTION_PLAN_NAME.FREE && showCCInSubscription.value) {
    submitPaymentInfo()
  } else {
    proceedWithSubscription(null)
  }
}
</script>

<template>
  <div>
    <div
      v-show="!subscribeSuccess && !tabProperties.isUnlimited && !tabProperties.clientFuturePlan"
      class="m-auto max-w-[800px] pb-20 text-center"
    >
      <div
        v-if="props.subscriptionType == SUBSCRIPTION_TYPE_ENUM.COMPILER_API"
        class="mb-6 mt-8 text-lg font-semibold text-gray-600 underline dark:text-gray-200"
      >
        Please Confirm the Plan
      </div>
      <div class="mb-8" v-if="props.subscriptionType == SUBSCRIPTION_TYPE_ENUM.COMPILER_API">
        <div
          class="py-1 text-2xl"
          v-if="props.subscriptionType == SUBSCRIPTION_TYPE_ENUM.COMPILER_API"
        >
          <span
            class="mr-1 text-2xl font-bold text-text-brand"
            v-if="selectedPlan?.name == 'Unlimited'"
            >Unlimited</span
          >
          <span class="mr-1 font-bold text-text-brand" v-else>
            {{ (selectedPlan as IApiPlan)?.credits }} Credits*
          </span>
          Per Day
        </div>

        <p
          class="mb-2 text-xl"
          v-if="props.subscriptionType == SUBSCRIPTION_TYPE_ENUM.COMPILER_API"
        >
          {{ selectedPlan?.name }} per month
        </p>

        <p
          class="text-secondary"
          v-if="props.subscriptionType == SUBSCRIPTION_TYPE_ENUM.COMPILER_API"
        >
          (*1 Credit = 1 Execute API Call)
        </p>
      </div>

      <!-- Stripe element -->
      <div
        v-show="showCCInSubscription && selectedPlan?.name !== SUBSCRIPTION_PLAN_NAME.FREE"
        class="mb-6 mt-10 text-lg font-semibold text-gray-600 underline dark:text-gray-200"
      >
        Payment Information
      </div>
      <div v-show="showCCInSubscription && selectedPlan?.name !== SUBSCRIPTION_PLAN_NAME.FREE">
        <div class="text-center">
          <div
            id="apiUpdatePaymentMethod-card-element"
            class="mb-4 h-[42px] rounded-lg p-3 dark:bg-gray-300"
          ></div>
          <img
            src="@/assets/images/integrate/powered_by_stripe.png"
            alt="Powered By Stripe"
            class="w-38 m-auto mb-10"
          />
        </div>
      </div>

      <!-- If user has a plan -->
      <div
        v-if="tabProperties.clientPlan"
        class="my-5 text-left font-normal text-gray-600 dark:text-gray-200"
      >
        <!-- Downgrade notes -->
        <div v-show="!showUpgradeNotes">
          <span class="underline">Note:</span> You are moving from higher volume plan to lower
          volume plan (downgrade).
          <ul>
            <li>
              As you may have already paid for your current plan for this billing cycle, Your
              current Plan will be active until end of your current billing Period.
            </li>
            <li>Your new plan will be active from start of Next Billing cycle.</li>
            <li>No further changes allowed in plans until next billing period</li>
            <li>
              Read
              <a
                href="https://docs.jdoodle.com/integrating-compiler-ide-to-your-application/compiler-api"
                target="_blank"
                class="text-blue-500"
                >API FAQ</a
              >
              for more details
            </li>
          </ul>
        </div>
        <!-- Upgrade Notes -->
        <div v-show="showUpgradeNotes">
          <span class="underline"> Note:</span> You are moving from lower volume plan to upper
          volume plan (upgrade).
          <ul>
            <li>Your new Plan will be active immediately</li>
            <li v-show="tabProperties.clientPlan != SUBSCRIPTION_PLAN_NAME.FREE">
              Cost for current billing cycle will be calculated on prorate basis for your old and
              new plans
            </li>
            <li v-show="tabProperties.clientPlan != SUBSCRIPTION_PLAN_NAME.FREE">
              As you may have already paid for the current billing cycle for your current plan,
              difference in prorate calculation will be added in your next payment
            </li>
            <li>
              Read
              <a
                href="https://docs.jdoodle.com/integrating-compiler-ide-to-your-application/compiler-api"
                target="_blank"
                class="text-blue-500"
                >API FAQ</a
              >
              for more details
            </li>
          </ul>
        </div>
      </div>
      <!-- Terms and conditions -->
      <div class="my-5 font-normal text-gray-600 dark:text-gray-200">
        By subscribing, you agree to the
        <RouterLink to="/terms" class="text-blue-500">Terms of Service</RouterLink>
      </div>

      <DoodleMessage
        class="mt-8 text-gray-600 dark:text-gray-200"
        :message="errorMessage"
        message-class="text-red-600"
        v-show="errorMessage"
      />

      <div class="mt-8">
        <button class="btn btn-primary w-fit" :disabled="clickDisabled" @click="subscribePlan">
          Subscribe
        </button>
        <button class="btn btn-secondary ml-2 w-fit" @click="closeSubscriptionForm">Close</button>
      </div>
    </div>

    <div
      v-show="
        !tabProperties.isUnlimited &&
        (tabProperties.isSubscriptionCancelled || tabProperties.clientFuturePlan)
      "
    >
      Your plan downgrade/cancel request already in-progress. No further changes allowed until next
      billing period.
    </div>
    <div v-show="tabProperties.isUnlimited">
      You are on a custom plan, please contact our support to change.
    </div>
  </div>
</template>
