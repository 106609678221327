<script setup lang="ts">
import UseCaseBanner, { type IUseCaseBannerData } from '@/components/shared/UseCaseBanner.vue'
import type { ICountAndFeature } from '@/components/shared/CountAndFeature.vue'
import CountAndFeature from '@/components/shared/CountAndFeature.vue'
import type { IActionButton } from '@/components/shared/PricingCard.vue'
import { SUBSCRIPTION_PRODUCT } from '@/components/shared/subscription/enum/subscription.enum'
import Button_login_then_redirect from '@/components/shared/subscription/Button_login_then_redirect.vue'
import Solution_edu from '@/assets/images/svg/Homepage_5.svg'

const sectionData: IUseCaseBannerData = {
  largeTitle:
    'Improve user engagement by <span class="text-text-brand">200%</span> with compiler APIs and Plugins',
  textLeftImageRight: true,
  list: [
    'Make your product more interactive and engaging',
    'Easily integrate code execution into your platform',
    'Creating interactive learning experiences'
  ],
  image: {
    src: Solution_edu,
    alt: 'Solution edu animation'
  }
}

const countAndFeatures: ICountAndFeature[] = [
  {
    count: '1 Million',
    description: 'Monthly Users'
  },
  {
    count: '76+',
    description: 'Languages Supported'
  }
]

const button: IActionButton = {
  buttonText: 'Subscribe Now',
  link: `/dashboard/api`,
  product: SUBSCRIPTION_PRODUCT.COMPILER_API
}
</script>

<template>
  <UseCaseBanner :sectionData="sectionData">
    <Button_login_then_redirect :buttonData="button" class="mt-6" />
    <div class="mt-6 flex gap-5">
      <div v-for="(item, idx) in countAndFeatures" :key="idx">
        <CountAndFeature :countAndFeature="item" />
      </div>
    </div>
  </UseCaseBanner>
</template>
