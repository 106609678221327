<script setup lang="ts">
import BaseInput from '@/components/forms/BaseInput.vue'
import BaseTextbox from '@/components/forms/BaseTextbox.vue'
import { number, object, string } from 'yup'
import { useField, useForm } from 'vee-validate'
import { computed, type PropType, watch, ref } from 'vue'
import { FORM_INPUT_TYPE } from '@/components/forms/form.enum'
import { type IQuestionType } from '@/components/guru/interface/IQuestionType'
import { type ITrueOrFalseQuestion_Request } from '@/components/guru/interface/requests.interface'
import { postQuestion } from '@/services/teach.service'
import { HttpStatusCode } from 'axios'
import router from '@/router'
import { type IQuestion } from '@/components/guru/interface/IQuestion'
import { GURU_ASSIGNMENT_ACTIONS } from '@/components/guru/enums/teach.enum'

const props = defineProps({
  instituteCode: {
    type: String,
    required: true
  },
  assignmentId: {
    type: Number,
    required: true
  },
  questionTypeObj: {
    type: Object as PropType<IQuestionType>,
    require: true
  },
  mode: {
    type: String as PropType<GURU_ASSIGNMENT_ACTIONS>,
    require: false
  },
  questionData: {
    type: Object as PropType<IQuestion | undefined>,
    require: false
  },
  enableNegativeMark: {
    type: Boolean,
    required: false
  },
  autoScore: {
    type: Boolean,
    required: false
  },
  autoCorrect: {
    type: Boolean,
    required: false
  }
})
const submitClicked = ref(false)
watch(
  () => props.questionData,
  (editingQuestion) => {
    if (editingQuestion) {
      question.value = editingQuestion?.question
      markForCorrectAnswer.value = editingQuestion?.markForCorrectAnswer
      correctAnswerOption.value =
        (editingQuestion?.correctAnswerOption as boolean) == true ? '1' : '0'
      negativeMark.value = editingQuestion?.negativeMark
    } else {
      //default
      question.value = null
      markForCorrectAnswer.value = null
      correctAnswerOption.value = null
      negativeMark.value = null
    }
  }
)

//Assets and static data
const questionInput = {
  label: 'Question'
}

const markForCorrectAnswerInput = {
  label: 'Mark/Score'
}

const negativeMarkInput = {
  label: 'Negative Mark/Score'
}

/**
 * @returns button text to be displayed
 */
function getButtonText(): string {
  if (props.mode === GURU_ASSIGNMENT_ACTIONS.ADD) {
    return 'Create Question'
  }

  if (props.mode === GURU_ASSIGNMENT_ACTIONS.EDIT) {
    return 'Save Changes'
  }

  return ''
}

const { errors, handleSubmit } = useForm({
  validationSchema: object({
    question: string().required().min(5).label('Question'),
    markForCorrectAnswer: number().nullable().label('Mark/Score'),
    correctAnswerOption: string().required().label('Correct Answer')
  })
})

const { value: question, errorMessage: questionError } = useField('question')

const { value: markForCorrectAnswer, errorMessage: markForCorrectAnswerError } =
  useField('markForCorrectAnswer')

const { value: negativeMark, errorMessage: negativeMarkError } = useField('negativeMark')

// unused-vars correctAnswerOptionError
const { value: correctAnswerOption } = useField<any>('correctAnswerOption')

const markForCorrectAnswerIsValid = computed(() => {
  if (props.autoScore) {
    return markForCorrectAnswer.value
  } else {
    return true
  }
})

const negativemarkIsValid = computed(() => {
  if (props.enableNegativeMark) {
    return (negativeMark.value as number) < 0
  } else {
    return true
  }
})

const canSubmit = computed(() => {
  if (Object.keys(errors.value).length !== 0) return false

  if (!markForCorrectAnswerIsValid.value) return false

  if (!negativemarkIsValid.value) return false

  if (submitClicked.value) return false

  return true
})

const onSubmit = handleSubmit(async (values: any) => {
  submitClicked.value = true

  const formObject: ITrueOrFalseQuestion_Request = {
    question: values.question,
    markForCorrectAnswer: values.markForCorrectAnswer,
    correctAnswerOption: values.correctAnswerOption,
    assQuestionId: props.questionData?.assQuestionId,
    negativeMark: props.enableNegativeMark ? values.negativeMark : undefined
  }

  const res = await postQuestion(
    formObject,
    props.questionTypeObj!,
    props.assignmentId,
    props.instituteCode
  )

  if (res == HttpStatusCode.Ok) router.go(0)

  submitClicked.value = false
})
</script>

<template>
  <form @submit.prevent="onSubmit">
    <div class="my-5 flex flex-col">
      <BaseTextbox
        :label="questionInput.label"
        v-model="question"
        :error="questionError"
        :isLightGray="false"
      />

      <div v-if="props.autoScore">
        <BaseInput
          v-model="markForCorrectAnswer"
          :label="markForCorrectAnswerInput.label"
          :error="markForCorrectAnswerError"
          :inputType="FORM_INPUT_TYPE.NUMBER"
          :isLightGray="false"
          :is-small-label="true"
        />
        <p :class="['p-xsmall ', 'error']" v-if="!markForCorrectAnswerIsValid">
          Mark/Score is required
        </p>

        <BaseInput
          v-if="props.enableNegativeMark"
          v-model="negativeMark"
          :label="negativeMarkInput.label"
          :error="negativeMarkError"
          :maxInt="0"
          :inputType="FORM_INPUT_TYPE.NUMBER"
          :isLightGray="false"
          :is-small-label="true"
        />
        <p :class="['p-xsmall ', 'error']" v-if="!negativeMark && negativeMark != 0">
          Negative Mark/Score is required
        </p>
      </div>

      <div class="mb-2 mt-5">
        <label class="text-md mb-3 font-bold">Correct Answer</label>
        <div class="flex gap-4">
          <label class="text-md"></label>
          <div class="flex gap-2">
            <input
              type="radio"
              name="trueOrFalse"
              :value="'1'"
              id="true"
              v-model="correctAnswerOption"
              :checked="correctAnswerOption == true"
            />
            <label for="true">True</label>
          </div>
          <div class="flex gap-2">
            <input
              type="radio"
              name="trueOrFalse"
              :value="'0'"
              id="false"
              v-model="correctAnswerOption"
              :checked="correctAnswerOption == false"
            />
            <label for="false">False</label>
          </div>
        </div>
        <p :class="['p-xsmall ', 'error']" class="mt-3" v-if="errors['correctAnswerOption']">
          {{ errors['correctAnswerOption'] }}
        </p>
      </div>
    </div>
    <button :disabled="!canSubmit" type="submit" class="btn-primary btn-rounded-md">
      {{ getButtonText() }}
    </button>
  </form>
</template>
