<script setup lang="ts">
import SlidingImages from '@/components/shared/SlidingImages.vue'
// import heroImage from '@/assets/images/home/Hero.png'
import heroLaptop from '@/assets/images/home/HeroLaptop.png'
import type { ICountAndFeature } from '@/components/shared/CountAndFeature.vue'
import CountAndFeature from '@/components/shared/CountAndFeature.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const heroCounts: ICountAndFeature[] = [
  {
    count: '1 Million',
    description: 'Monthly Users'
  },
  {
    count: '76+',
    description: 'Languages Supported'
  }
]
</script>

<template>
  <div class="section-primary">
    <div class="view-container overflow-y-clip pt-12 lg:pt-20">
      <div class="m-auto max-w-xl text-center lg:max-w-3xl">
        <span class="title-feature">Powered by AI</span>
        <h1 class="title-heading">Free yourself from managing coding environments</h1>
        <p class="title-description">
          A cloud platform to learn, teach, assess and practice programming.
        </p>
      </div>

      <div class="mb-14 flex flex-col items-center justify-center gap-3 sm:gap-5 md:flex-row">
        <RouterLink class="btn btn-primary w-fit" to="/online-java-compiler"
          >Start Coding
          <FontAwesomeIcon icon="fa-arrow-right" class="ms-1" />
        </RouterLink>
        <div class="text-sm text-text-tertiary dark:text-text-tertiary-dark">
          Free Forever. No Credit Card Required.
        </div>
      </div>
      <!-- TODO: Replace bg image -->
      <div
        :class="`w-full bg-[url('@/assets/images/home/hero_grid_light.svg')] bg-center bg-repeat-space dark:bg-[url('@/assets/images/home/hero_grid_dark.svg')]`"
      >
        <div class="m-auto w-full md:w-[80%]">
          <img
            :src="heroLaptop"
            alt="JDoodle on your laptop"
            class="hero-shadow dark:hero-shadow-dark z-0 rounded-md"
          />
        </div>
      </div>
    </div>

    <SlidingImages class="z-10" />

    <div class="view-container relative overflow-hidden">
      <div
        class="dark:orange-shadow-dark absolute left-0 right-0 top-0 m-auto max-w-xl overflow-hidden"
      ></div>

      <div class="my-6 flex justify-center gap-10 sm:my-12">
        <div v-for="item in heroCounts" :key="item.count">
          <CountAndFeature :countAndFeature="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.orange-shadow {
  box-shadow: 0 15px 80px 0px rgba(0, 0, 0, 0.2);
}

.orange-shadow-dark {
  box-shadow: 0 0 120px 10px rgb(254, 89, 0);
}
</style>
