import { languagesItems, type ILanguage } from '@/utils/sharedData/languages'

/**
 * @param storedString The language string stored
 * @returns string displayname
 */
const getLanguageNameFromStoredString = (storedString: string) => {
  const language = languagesItems.find((lang: ILanguage) => lang.language == storedString)

  if (language) {
    return language.displayName
  } else {
    return ''
  }
}

export { getLanguageNameFromStoredString }
