export enum FORM_SELECT_VALUE_TYPE {
  NAME = 'name',
  LANGUAGE = 'language',
  INDEX = 'index',
  VALUE = 'value',
  TYPE = 'type',
  ROLE_NAME = 'name'
}

export enum FORM_INPUT_TYPE {
  NUMBER = 'number',
  STRING = 'string'
}
