import type { IActionButton } from '@/components/shared/PricingCard.vue'
import {
  SUBSCRIPTION_PRODUCT,
  type SUBSCRIPTION_PLAN_NAME
} from '@/components/shared/subscription/enum/subscription.enum'
import type { IApiCredentials } from '@/components/shared/subscription/interface/IApiCredentials'
import type { IApiCreditsUsedToday } from '@/components/shared/subscription/interface/IApiCreditsUsedToday'
import type { IPaymentDetails } from '@/components/shared/subscription/interface/IPaymentDetails'
import type { IPaymentMethod } from '@/components/shared/subscription/interface/IPaymentMethod'
import type { ISubscribeRequest } from '@/components/shared/subscription/interface/ISubscribeRequest'
import type { ISubscriptionInitRes } from '@/components/shared/subscription/interface/ISubscriptionInitRes'
import router from '@/router'
import { useAuthStore } from '@/stores/auth.store'
import { useSubscribeStore } from '@/stores/subscribe.store'
import { AUTHMODELHS } from '@/utils/models'
import axios from 'axios'
import utilModelsService from './util.models.service'

/**
 * This button is shared and takes action when logged-in
 * If logged-in -> redirect to link or modal from props
 * If not logged-in -> Login modal
 * @param button Button object clicked
 * @param planName Plan name enum
 */
function loginThenRedirect(button: IActionButton, planName?: SUBSCRIPTION_PLAN_NAME) {
  // If logged in -> Redirect
  if (useAuthStore().isUserloggedIn) {
    let plan = undefined

    //Determine user's plan
    if (button.product == SUBSCRIPTION_PRODUCT.COMPILER_API) {
      //Compiler plan
      plan = useSubscribeStore().apiInitData?.plan
    } else {
      //Bundle plan data
      plan = useSubscribeStore().isPremium
    }

    //Determine button action to take

    //Internal link
    if (button.link) {
      router.push(button.link).then(() => router.go(0))
    } else if (button.href) {
      //External HREF
      window.location.href = button.href
    } else if (button.modal) {
      //Open Modal
      useSubscribeStore().selectPlan(planName)
      utilModelsService.openModal(button.modal)
    } else {
      // Only for pricing page - cards open modals
      useSubscribeStore().selectPlan(planName)
      useSubscribeStore().openSubscribeModal()
    }
  } else {
    //Not logged in - Open Login Modal
    useSubscribeStore().selectPlan(planName)
    utilModelsService.openModal(AUTHMODELHS.LOGIN)
  }
}

// ===== Compiler API =====

/**
 * Get compiler api subscription data
 * @returns response data
 */
const initCompilerApi = async (): Promise<ISubscriptionInitRes> => {
  return await axios
    .post('/api/compiler-api/init')
    .then((res: any) => {
      useSubscribeStore().initApi(res.data)
      return res.data
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Get api payment details
 * @returns response data
 */
const getApiPaymentDetail = async (): Promise<IPaymentDetails> => {
  return await axios
    .post('/api/compiler-api/paymentDetail', null)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Get API payment method
 * @returns response data
 */
const getApiPaymentMethod = async (): Promise<IPaymentMethod> => {
  return await axios
    .post('/api/compiler-api/paymentMethod', null)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}

/**
 * get Api Credentials
 * @returns response data
 */
const getApiCredentials = async (): Promise<IApiCredentials> => {
  return await axios
    .post('/api/compiler-api/credentials', null)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Get API credits used today
 * @returns response data
 */
const getApiCreditsUsedToday = async (): Promise<IApiCreditsUsedToday> => {
  return await axios
    .post('/api/compiler-api/creditUsedToday', null)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Get API payment method
 * @param resultTokenId tokenID generated
 * @returns response data
 */
const updateApiPaymentMethod = async (resultTokenId: string): Promise<IPaymentMethod> => {
  return await axios
    .post('/api/compiler-api/updatePaymentMethod', { token: resultTokenId })
    .then((response: any) => {
      return response.data
    })
    .catch(({ response }) => {
      throw response
    })
}

/**
 * Subscribe to API
 * @param requestObject ISubscribeRequest object
 * @returns http response object (unused)
 */
const subscribeToApi = async (requestObject: ISubscribeRequest): Promise<any> => {
  return await axios
    .post('/api/compiler-api/subscribe', requestObject)
    .then((response: any) => {
      return response
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Reactivate API subscription
 * @returns http response (unused)
 */
const reactivateApiSubscription = async (): Promise<any> => {
  return await axios
    .post('/api/compiler-api/reactivateSubscription')
    .then((response: any) => {
      return response
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Cancel API subscription
 * @returns http response object (unused)
 */
const cancelApiSubscription = async (): Promise<any> => {
  return await axios
    .post('/api/compiler-api/cancelSubscription')
    .then((response: any) => {
      return response
    })
    .catch((err) => {
      throw err
    })
}

// ====== BUNDLE ======

/**
 * Get bundle subscription data
 * @returns ISubscriptionInitRes object
 */
const initBundle = async (): Promise<ISubscriptionInitRes> => {
  return await axios
    .post('/api/bundle/init')
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Subscribe to bundle
 * @param requestObject ISubscribeRequest object
 * @returns http response object (unused)
 */
const subscribeToBundle = async (requestObject: ISubscribeRequest): Promise<any> => {
  return await axios
    .post('/api/bundle/subscribe', requestObject)
    .then((response: any) => {
      return response
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Get bundle payment method
 * @returns IPaymentMethod data object
 */
const getBundlePaymentMethod = async (): Promise<IPaymentMethod> => {
  return await axios
    .post('/api/bundle/paymentMethod', null)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Reactivate Bundle subscription
 * @returns http response object (unused)
 */
const reactivateBundleSubscription = async (): Promise<any> => {
  return await axios
    .post('/api/bundle/reactivateSubscription')
    .then((response: any) => {
      return response
    })
    .catch((err) => {
      throw err
    })
}

/**
 * Cancel subscription
 * @returns http response object (unused)
 */
const cancelBundleSubscription = async (): Promise<any> => {
  return await axios
    .post('/api/bundle/cancelSubscription')
    .then((response: any) => {
      return response
    })
    .catch((err) => {
      throw err
    })
}

export {
  loginThenRedirect,
  // COMPILERAPI
  initCompilerApi,
  getApiPaymentDetail,
  getApiPaymentMethod,
  getApiCredentials,
  updateApiPaymentMethod,
  getApiCreditsUsedToday,
  subscribeToApi,
  cancelApiSubscription,
  reactivateApiSubscription,
  // BUNDLE STARTS HERE
  initBundle,
  subscribeToBundle,
  getBundlePaymentMethod,
  reactivateBundleSubscription,
  cancelBundleSubscription
}
