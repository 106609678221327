<script setup lang="ts">
import ModelLayout from '@/layouts/ModelLayout.vue'
import { TEACHMODALHS } from '@/utils/models'
import { HttpStatusCode } from 'axios'
import router from '@/router'
import { submitAssignment } from '@/services/teach.service'

const props = defineProps({
  instituteCode: {
    type: String,
    required: true
  },
  assignmentId: {
    type: Number,
    required: true
  }
})

//Static data
const modalTitle = 'Submit Assignment'
const message = 'Do you want to submit the Assignment?'

/**
 *
 */
const handleSubmit = async () => {
  const res = await submitAssignment(props.instituteCode, props.assignmentId)

  //Todo: use disclose somewhere?

  if (res.status == HttpStatusCode.Ok) {
    router.go(0)
  }
}
</script>

<template>
  <ModelLayout
    :title="modalTitle"
    :hs="TEACHMODALHS.SUBMIT_ASSIGNMENT_CONFIRMATION"
    :loadDataWhenClose="false"
  >
    <p class="my-5 break-normal text-center text-sm">{{ message }}</p>

    <div class="mt-8 flex justify-center gap-5">
      <button class="btn-primary rounded-md px-5 py-2" @click="handleSubmit">Yes</button>

      <button class="btn-secondary rounded-md px-5 py-2" data-hs-overlay-close>No</button>
    </div>
  </ModelLayout>
</template>
