<script setup lang="ts">
import { computed } from 'vue'
import { IDECONSTANT } from '@/utils/ide'
import { useIdeStore } from '@/stores/ide.store'
import File from '@/components/ide/ide/FileComp.vue'

const ideStore = useIdeStore()

const isOutputEditor = computed(() => {
  return ideStore.outputEditor
})
const showCPUAndMemory = computed(() => {
  return !ideStore.isCodeExecuting && ideStore.cpuTime && ideStore.memory
})
const showExecuteDetails = computed(() => {
  return !ideStore.isCodeExecuting && ideStore.executionTime
})
const isInteractiveMode = computed(() => {
  return ideStore.interactiveMode
})
const isCompileLanguage = computed(() => {
  return ideStore.ideMeta?.isCompile || ideStore.ideMeta?.language === 'java'
})
const isGuru = computed(() => {
  return ideStore.isGuru
})
const isEmbedded = computed(() => {
  return ideStore.isEmbedded
})
</script>

<template>
  <div
    class="flex h-full flex-col gap-4 print:break-inside-avoid print:border print:p-1"
    :class="[
      {
        'justify-between ': !isGuru || !isEmbedded
      },
      {
        'justify-start': !isGuru && !isEmbedded
      }
    ]"
  >
    <p class="heading-xsmall text-left text-sm">Result</p>
    <!-- <div v-if="showExecuteDetails" class="p-xsmall">
      <p v-show="!isInteractiveMode">
        CPU Time: {{ ideStore.cpuTime }} sec(s), Memory: {{ ideStore.memory }} kilobyte(s)
      </p>
      <p v-show="isCompileLanguage">
        compiled and & executed in {{ ideStore.executionTime }} sec(s)
      </p>
    </div> -->
    <div class="h-full" id="ideOutputEditor">
      <div
        :id="IDECONSTANT.OUTPUT_EDITOR"
        v-show="isOutputEditor"
        class="h-full w-full rounded-lg bg-black text-white"
      />
    </div>
    <div v-if="showCPUAndMemory || showExecuteDetails" class="p-xsmall mt-2">
      <p v-show="!isInteractiveMode && showCPUAndMemory">
        CPU Time: {{ ideStore.cpuTime }} sec(s), Memory: {{ ideStore.memory }} kilobyte(s)
      </p>
      <p v-show="isCompileLanguage && showExecuteDetails">
        compiled and & executed in {{ ideStore.executionTime }} sec(s)
      </p>
    </div>

    <File />
  </div>
</template>
