<script lang="ts" setup>
import circleTwoImg from '@/assets/images/educationInstitution/howItWork/circle2-yellow.svg'
import dotsThreeImg from '@/assets/images/educationInstitution/howItWork/dots3-blue.svg'

interface ITextObject {
  text: string
  tab: number
}

const htmlText: ITextObject[] = [
  { text: `<body> `, tab: 0 },
  {
    text: `<div data-pym-src='https://www.jdoodle.com/plugin' data-language="java"
    data-version-index="4" data-libs="mavenlib1, mavenlib2"> `,
    tab: 1
  },
  { text: `Optional default code goes here`, tab: 2 },
  { text: `</div>`, tab: 1 },

  {
    text: `<script src="https://www.jdoodle.com/assets/jdoodle-pym.min.js" type="text/javascript"/>`,
    tab: 1
  },
  { text: `</body>`, tab: 0 }
]
</script>

<template>
  <section class="mx-15 overflow-hidden py-24">
    <div class="container mx-auto px-8">
      <div class="-mx-4 flex flex-wrap">
        <div class="mb-16 w-full px-4 md:mb-0 md:w-1/2">
          <div class="relative mx-auto max-w-max md:ml-0">
            <img
              class="absolute -right-8 -top-8 z-10 w-14 md:w-24"
              :src="circleTwoImg"
              alt="circleTwo"
            />
            <img
              class="absolute -bottom-8 -left-10 z-10 w-28 md:w-auto"
              :src="dotsThreeImg"
              alt="dotsThree"
            />
            <div
              class="m-0 min-h-fit w-full max-w-[80vw] rounded-[50px] bg-[black] p-4 font-semibold text-[white] shadow-[0_0_50px_#ccc] lg:p-12"
            >
              <div
                class="mb-4 flex justify-start gap-4 border-b-[#e5ffea] border-b-[solid] pb-4 pt-4"
              >
                <span
                  class="flex h-[30px] w-[30px] flex-col justify-items-center rounded-[50%]"
                  style="background-color: red"
                ></span>
                <span
                  class="flex h-[30px] w-[30px] flex-col justify-items-center rounded-[50%]"
                  style="background-color: yellow"
                ></span>
                <span
                  class="flex h-[30px] w-[30px] flex-col justify-items-center rounded-[50%]"
                  style="background-color: greenyellow"
                ></span>
              </div>
              <hr class="bg-background-jdoodle" />
              <div
                v-for="(text, i) in htmlText"
                :key="i"
                class="mb-4 flex flex-wrap pt-4 text-left"
              >
                <span v-text="text.text"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full px-8 md:w-1/2">
          <span
            class="text-tertiary mb-4 inline-block rounded-full bg-green-100 px-2 py-px text-xs font-medium uppercase leading-5 shadow-sm"
            >How it works</span
          >
          <h2 class="mb-12 text-left text-4xl font-bold leading-tight tracking-tighter md:text-5xl">
            3 simple steps to integrate plugins into your teaching platform
          </h2>
          <div class="-mx-4 flex flex-wrap text-center md:text-left">
            <div class="mb-8 w-full px-4 md:w-1/2">
              <div
                class="mb-4 inline-flex h-12 w-12 items-center justify-center rounded-full bg-background-jdoodle text-xl font-semibold text-white"
              >
                1
              </div>
              <h3 class="mb-2 text-xl font-bold">Copy the code</h3>
            </div>
            <div class="mb-8 w-full px-4 md:w-1/2">
              <div
                class="mb-4 inline-flex h-12 w-12 items-center justify-center rounded-full bg-background-jdoodle text-xl font-semibold text-white"
              >
                2
              </div>
              <h3 class="mb-2 text-xl font-bold">Paste it into your platform</h3>
            </div>
            <div class="mb-8 w-full px-4 md:w-1/2">
              <div
                class="mb-4 inline-flex h-12 w-12 items-center justify-center rounded-full bg-background-jdoodle text-xl font-semibold text-white"
              >
                3
              </div>
              <h3 class="mb-2 text-xl font-bold">Start using</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
