<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { TEACHMODALHS } from '@/utils/models'
import draggable from 'vuedraggable'
import type { IQuestion } from '@/components/guru/interface/IQuestion'
import type { IQuestionMini } from '@/components/guru/interface/IQuestionMini'
import QuestionRemoveModal from '@/components/guru/modals/confirmationModals/QuestionRemoveModal.vue'
import { reorderQuestions } from '@/services/teach.service'
import { useTeachStore } from '@/stores/teach.store'
import type { IQuestion_ForOneAssignment } from '../../interface/IQuestion_ForOneAssignment'
import { getLanguageNameFromStoredString } from '@/services/util.language.service'
import DashboardCardEmptyMessageButton from '@/components/shared/dashboard/DashboardCardEmptyMessageButton.vue'
const props = defineProps({
  assignmentId: {
    type: Number,
    required: true
  },
  instituteCode: {
    type: String,
    required: true
  },
  questions: {
    type: Array<IQuestion_ForOneAssignment>
  },
  enableNegativeMark: {
    type: Boolean,
    required: false
  },
  autoScore: {
    type: Boolean,
    required: false
  },
  autoCorrect: {
    type: Boolean,
    required: false
  },
  disclose: {
    type: Number,
    required: false
  }
})

const teachStore = useTeachStore()
const questionData = ref(props.questions)
const questionToEdit = ref(undefined as undefined | IQuestionMini)

watch(
  () => props.questions,
  () => {
    questionData.value = props.questions
  }
)

/**
 *
 */
const handleReorder = async () => {
  let positions = questionData.value?.map((question: IQuestion_ForOneAssignment) => {
    return question.id
  })

  if (!positions) positions = [] as number[]

  await reorderQuestions(positions, props.assignmentId, props.instituteCode)
}

/**
 * Sets which question to edit
 * @param question question
 */
const handleEditQuestion = (question: IQuestionMini | undefined) => {
  questionToEdit.value = question
  teachStore.questionToEdit = question
  teachStore.showQuestionForm = true
}

const questionId = ref<undefined | number>()
/**
 *
 * @param question question
 */
const handleClick = (question: IQuestion) => {
  questionId.value = question.id
}

const limitMessage = computed(() => {
  if (teachStore.questionsLimitReached) {
    return `You have reached the institute's plan limit of ${teachStore.instituteSubscriptionInfo?.limit.questionLimit} questions`
  } else return ''
})
</script>

<template>
  <div class="h-full">
    <!-- <CreateQuestionModal
      :question="questionToEdit"
      :enableNegativeMark="props.enableNegativeMark"
      :autoScore="props.autoScore"
      :autoCorrect="props.autoCorrect"
      :disclose="props.disclose"
    /> -->

    <QuestionRemoveModal
      :questionId="questionId"
      :instituteCode="instituteCode"
      :assignmentId="assignmentId"
    />

    <div class="flex h-auto flex-col justify-between shadow-sm">
      <div>
        <p class="text-tertiary wrap-normal my-2 text-xs" v-if="limitMessage">{{ limitMessage }}</p>
        <p v-if="!questionData || questionData?.length < 1" class="">
          <DashboardCardEmptyMessageButton :empty-data="{ message: 'No Questions yet' }" />
        </p>
        <div class="" v-else-if="questionData && questionData?.length > 0">
          <draggable
            v-model="questionData"
            @change="handleReorder"
            group="questions"
            item-key="id"
            class="w-full"
          >
            <template #item="{ element, index }">
              <div class="w-100 flex justify-between border-b border-slate-700 py-3">
                <div class="flex-col" role="button" @click="handleEditQuestion(element)">
                  <h3 class="mb-1">
                    {{ `${index + 1}. ${element.questionType}` }}
                  </h3>

                  <div class="text-secondary text-md ps-5">
                    <div class="">
                      {{ element.question }}
                    </div>

                    <div v-if="element.language" class="mt-3">
                      Language: {{ getLanguageNameFromStoredString(element.language) }}
                    </div>

                    <div v-if="element.options" class="mt-3">
                      <ul class="">
                        <li
                          class="list-style-alpha ms-5"
                          v-for="(option, index) in element.options"
                          :key="index"
                        >
                          {{ option.option }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div>
                  <!-- <button @click="removeQuestion(element)"> -->
                  <button
                    :data-hs-overlay="`#${TEACHMODALHS.REMOVE_QUESTION_CONFIRMATION}`"
                    @click="handleClick(element)"
                  >
                    <FontAwesomeIcon icon="fa-trash" class="error"></FontAwesomeIcon>
                  </button>
                </div>
              </div>
            </template>
          </draggable>
        </div>
      </div>
      <div class="text-md text-secondary mt-10 text-center">
        <span class="underline">Tip:</span> Drag and drop the questions to reorder
      </div>
    </div>
  </div>
</template>
