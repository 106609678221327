<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'

import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'

const ideStore = useIdeStore()
const pluginStore = usePluginStore()

const featurePermissionErrorTimeOut = ref<number | null>(null)

const featurePermissionError = computed(() => {
  return pluginStore.featurePermissionError
})
onMounted(() => {
  watch(featurePermissionError, () => {
    if (ideStore.pymChild) ideStore.pymChild.sendHeight()
    if (featurePermissionError.value) {
      if (featurePermissionErrorTimeOut.value) clearTimeout(featurePermissionErrorTimeOut.value)
      featurePermissionErrorTimeOut.value = setTimeout(() => {
        pluginStore.setFeaturePermissionError(null)
      }, 8000)
    }
  })
})
</script>

<template>
  <p v-show="featurePermissionError" class="p-xsmall error">
    {{ featurePermissionError }}
  </p>
</template>
