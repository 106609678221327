import '@/assets/main.css'
import 'preline'

import resetStore from '@/stores/plugin/reset.plugin'
import { createPinia } from 'pinia'
import { createApp } from 'vue'

import App from '@/App.vue'
import router from '@/router'
import '@/utils/icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import type { RouteRecordNormalized } from 'vue-router'

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta: RouteRecordNormalized | undefined = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title as string

  // Remove any stale meta tags from the document using the key attribute we set below.
  const elements: NodeListOf<HTMLElement> = document.querySelectorAll(
    '[data-vue-router-controlled]'
  )
  Array.from(elements).map((el) => {
    const node: any = el.parentNode
    node.removeChild(el)
  })

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  const metaTags: any = nearestWithMeta.meta.metaTags
  metaTags
    .map((tagDef: any[]) => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach((key: any) => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    // Add the meta tags to the document head.
    .forEach((tag: any) => document.head.appendChild(tag))

  next()
})
const app = createApp(App)

app.component('FontAwesomeIcon', FontAwesomeIcon)
const pinia = createPinia()
pinia.use(resetStore)
app.use(pinia)
app.use(router)

app.mount('#app')
