<template>
  <router-view
    :associations="guruDataAndState.associations"
    :instituteOwn="guruDataAndState.instituteOwn"
    :guruInitialised="guruDataAndState.guruInitialised"
  ></router-view>
</template>

<script lang="ts" setup>
import { getAssociationsAndInstituteOwn } from '@/services/teach.service'
import { onMounted, reactive } from 'vue'
import type IAssociation from '@/components/guru/interface/IAssociation'
import type IInstituteOwn from '@/components/guru/interface/IInstituteOwn'
import { initBundle } from '@/services/subscription.service'
import { useSubscribeStore } from '@/stores/subscribe.store'
const guruDataAndState = reactive({
  associations: [] as IAssociation[],
  instituteOwn: null as IInstituteOwn | null,
  guruInitialised: false
})

const subscribeStore = useSubscribeStore()

onMounted(async () => {
  const bundleRes = await initBundle()
  subscribeStore.initBundle(bundleRes)

  const res = await getAssociationsAndInstituteOwn()

  if (res.associations) {
    guruDataAndState.associations = res.associations
  }

  if (res.instituteOwn) {
    guruDataAndState.instituteOwn = res.instituteOwn
  }

  guruDataAndState.guruInitialised = true
})
</script>

<style>
@import url('@/assets/styles/guru.css');
</style>
