<script setup lang="ts">
import UseCaseBanner, { type IUseCaseBannerData } from '@/components/shared/UseCaseBanner.vue'
import Homepage_5 from '@/assets/images/svg/Homepage_5.svg'
import CodeSearchBar from '@/components/code/CodeSearchBar.vue'

const teachAndAssessSection: IUseCaseBannerData = {
  title: 'Start coding with powerful online compilers and editors',
  list: [
    'Zero set-up, fast anda easy to use',
    'Instant deployment, real-time collaboration',
    'Powered by AI'
  ],
  // primaryButton: {
  //   text: 'Start Coding',
  //   link: '/code-online-compiler-ide'
  // },
  textLeftImageRight: true,
  image: {
    src: Homepage_5,
    alt: 'animation svg'
  }
}
</script>

<template>
  <div class="section-primary">
    <UseCaseBanner :sectionData="teachAndAssessSection">
      <div class="w-full pt-8 sm:max-w-md">
        <CodeSearchBar :isFullSize="false" />
      </div>

      <RouterLink
        class="btn btn-primary my-4 block w-fit py-2.5 md:my-8"
        :to="{ path: '/online-java-compiler' }"
      >
        Start Coding
        <FontAwesomeIcon
          icon="fa-arrow-right"
          class="text-primary ml-1 w-4 dark:text-text-primary-dark"
        />
      </RouterLink>
    </UseCaseBanner>
  </div>
</template>
