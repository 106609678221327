<script setup lang="ts">
import ModelLayout from '@/layouts/ModelLayout.vue'
import { TEACHMODALHS } from '@/utils/models'
import { onMounted, watch, ref } from 'vue'
import { getStudentGroups } from '@/services/teach.service'
import { HttpStatusCode } from 'axios'
import { GURU_USER_REQUEST, GURU_USER_TYPE } from '@/components/guru/enums/teach.enum'
import router from '@/router'
import { addOrRemoveUserFromAssignment } from '@/services/teach.service'
import { type IAssignment_Users_AddRemoveRequest } from '@/components/guru/interface/requests.interface'
import type { IStudentGroup } from '@/components/guru/interface/IStudentGroup'

const props = defineProps({
  assignmentId: {
    type: Number,
    required: true
  },
  instituteCode: {
    type: String,
    required: true
  }
})

//Static data
const modalTitle = 'Import Student Group'
const noMatchFound = 'No Match Found.'

//Refs
const studentGroupsData = ref([] as IStudentGroup[])
const filteredGroups = ref([] as IStudentGroup[])
const groupsJustAdded = ref([] as IStudentGroup[])
const searchInput = ref('')

onMounted(async () => {
  const res = await getStudentGroups(props.instituteCode)
  studentGroupsData.value = res

  filteredGroups.value = filterSG('')
})

watch(
  () => searchInput.value,
  (searchTerm) => {
    filteredGroups.value = filterSG(searchTerm)
  }
)

/**
 * @param searchTerm String searchTerm
 * @returns Array of users, filtered from existing and just added
 */
const filterSG = (searchTerm: string) => {
  return [
    ...studentGroupsData.value.filter((sg: IStudentGroup) => {
      //Has it been added?
      const isInJustAdded = groupsJustAdded.value.find(
        (existingGroup: IStudentGroup) => existingGroup.insStudentGroupId == sg.insStudentGroupId
      )

      if (!isInJustAdded) {
        if (searchTerm !== '') {
          if (
            sg.description.toLowerCase().includes(searchTerm) ||
            sg.name.toLowerCase().includes(searchTerm)
          ) {
            return sg
          }
        } else {
          return sg
        }
      }
    })
  ]
}

/**
 *
 * @param sg studentGroup object
 */
const handleAddSG = async (sg: IStudentGroup) => {
  const reqObject: IAssignment_Users_AddRemoveRequest = {
    email: sg.insStudentGroupId,
    instituteCode: props.instituteCode!,
    insAssignmentId: props.assignmentId,
    updateType: GURU_USER_REQUEST.ADD,
    participantType: GURU_USER_TYPE.STUDENT_GROUP
  }

  const res = await addOrRemoveUserFromAssignment(reqObject)

  if (res == HttpStatusCode.Ok) {
    groupsJustAdded.value.push(sg)
    filteredGroups.value = filterSG('')
  }
}

/**
 *
 */
const refreshData = () => {
  router.go(0)
}
</script>

<template>
  <ModelLayout
    :title="modalTitle"
    :hs="TEACHMODALHS.IMPORT_STUDENT_GROUP"
    :loadDataWhenClose="true"
  >
    <!-- form -->
    <div class="relative flex">
      <input
        v-model="searchInput"
        :placeholder="'Search Student Groupo'"
        class="section-primary p-small block w-full rounded-md border px-4 py-2"
      />
      <FontAwesomeIcon icon="fa-search" class="absolute right-4 top-3" />
    </div>
    <p class="my-5 text-center text-sm" v-if="searchInput != '' && filteredGroups.length < 1">
      {{ noMatchFound }}
    </p>

    <table class="my-5 w-full table-auto border-separate border-spacing-y-3">
      <tr v-for="sg in filteredGroups" :key="sg.insStudentGroupId" class="w-full">
        <td class="section-tertiary rounded-s-md px-4 py-2">
          {{ `${sg.name}` }}
        </td>
        <td class="section-tertiary section-tertiary flex justify-center rounded-e-md px-4 py-2">
          <button @click="handleAddSG(sg)" class="btn-primary rounded-md px-2 py-1">Add</button>
        </td>
      </tr>
    </table>

    <button class="btn-secondary btn-rounded-md" @click="refreshData" data-hs-overlay-close>
      Close
    </button>
  </ModelLayout>
</template>
