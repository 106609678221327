<script setup lang="ts">
import { computed } from 'vue'
import settingService from '@/services/ide/settings/setting.service'
import { useIdeStore } from '@/stores/ide.store'
import ModelLayout from '@/layouts/ModelLayout.vue'
import { IDEVIEWMODELS } from '@/utils/models'
import BaseInput from '@/components/forms/BaseInput.vue'

import { useField, useForm } from 'vee-validate'
import { string } from 'yup'

const ideStore = useIdeStore()

const { errors, handleSubmit, resetForm } = useForm()
const { value: lib, errorMessage: libraryError } = useField(
  'library',
  string()
    .required()
    .matches(/^([0-9a-zA-Z-.]+:[0-9a-zA-Z-.]+:[0-9a-zA-Z-.]+[:]?[0-9a-zA-Z-.]+]?)$/, {
      message: 'The Add Jar from Maven Library field format is invalid'
    })
)

const isShowJavaLibs = computed(() => {
  return ideStore.javaLibraries.length > 0
})

const isJavaLibs = computed(() => {
  return ideStore.javaLibraries || []
})

const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0
})
/**
 * remove the library
 * @param lib The library name
 */
const removeLib = (lib: string) => {
  settingService.removeJavaLibraries(lib)
}
const onSubmit = handleSubmit(async (values) => {
  settingService.addJavaLibraries(values.library)
  resetForm()
})
</script>

<template>
  <ModelLayout title="External Libraries" :hs="IDEVIEWMODELS.LIBRARIES">
    <div v-if="isShowJavaLibs">
      <p class="heading-xsmall text-sm">Added Libraries</p>
      <ul class="flex max-h-48 flex-col flex-wrap gap-1 overflow-auto">
        <li v-for="lib in isJavaLibs" :key="lib" class="flex items-center gap-1">
          <button @click="removeLib(lib)">
            <FontAwesomeIcon icon="fa-trash-can" class="error h-4 w-4" />
          </button>
          <span class="p-small">{{ lib }}</span>
        </li>
      </ul>
    </div>
    <form @submit.prevent="onSubmit">
      <BaseInput
        label="Add Jar from Maven Library"
        placeholder="org.apache.commons:commons-lang3:jar:3.9"
        :error="libraryError"
        v-model="lib"
      />
      <button :disabled="!canSubmit" class="btn-primary btn-rounded-md" type="submit">
        Add Library
      </button>
    </form>
    <div class="flex flex-col gap-2">
      <div class="flex justify-start gap-4">
        <FontAwesomeIcon icon="fa-exclamation-circle" class="h-6 w-4" />
        <span class="p-xsmall"> Library should be available in a public Maven repository. </span>
      </div>
      <div class="flex justify-start gap-4">
        <FontAwesomeIcon icon="fa-exclamation-circle" class="h-6 w-4" />
        <span class="p-xsmall">
          You can search maven artifacts in
          <a class="link-secondary" href="http://search.maven.org/" rel="nofollow" target="_blank"
            >http://search.maven.org/</a
          >
          or
          <a class="link-secondary" href="http://mvnrepository.com/" rel="nofollow" target="_blank"
            >http://mvnrepository.com/</a
          >.
        </span>
      </div>
      <div class="flex justify-start gap-4">
        <FontAwesomeIcon icon="fa-exclamation-circle" class="h-6 w-4" />
        <span class="p-xsmall">
          It should be in a format like -
          <span class="font-bold">org.apache.commons:commons-lang3:3.4</span></span
        >
      </div>
    </div>
    <button
      class="btn-secondary btn-rounded-md"
      :data-hs-overlay="`#${IDEVIEWMODELS.LIBRARIES}`"
      data-hs-overlay-close
    >
      Close
    </button>
  </ModelLayout>
</template>
