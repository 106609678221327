<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { RouterView } from 'vue-router'
import { useAuthStore } from '@/stores/auth.store'
import DashboardBreadcrumb from '@/components/shared/dashboard/DashboardBreadcrumb.vue'
import { useTeachStore } from '@/stores/teach.store'
import authService from '@/services/auth.service'
import { useSubscribeStore } from '@/stores/subscribe.store'
import { initBundle } from '@/services/subscription.service'

const authStore = useAuthStore()
const teachStore = useTeachStore()
const userName = computed(() => authStore.username)

const subscribeStore = useSubscribeStore()
const menuCollapsed = ref(false)
const rightPanel = ref(0)
// const height = ref(0)
// const absolutePosition = computed(() => {
//   return height.value > 790
// })
const absolutePosition = ref(false)
const menuShow = ref(true)
const route = useRoute()

const routeUrl = computed(() => {
  return route.path
})

const router = useRouter()

onMounted(async () => {
  // handleResize()
  if (!subscribeStore.bundleInitData) {
    const res = await initBundle()
    subscribeStore.initBundle(res)
  }
})
watch(routeUrl, () => {
  menuShow.value = false
})

/**
 *
 */
const openMenuOrStartCoding = () => {
  if (menuCollapsed.value) {
    menuCollapsed.value = false
  } else {
    router.push(`/online-java-compiler`)
  }
}

/**
 *
 */
// function handleResize() {
//   // @ts-ignore
//   height.value = rightPanel.value.clientHeight
// }

/**
 * @description Logout the user
 */
const onClickLogout = async (): Promise<void> => {
  await authService.logout()
}
</script>

<template>
  <div class="p-5">
    <DashboardBreadcrumb />
  </div>
  <div class="section-primary w-full pb-10 pt-2 sm:p-5">
    <FontAwesomeIcon
      icon="fa-bars"
      v-show="!menuShow"
      class="mb-4 block h-5 w-5 px-1 pl-4 lg:hidden"
      @click="menuShow = !menuShow"
    ></FontAwesomeIcon>

    <div class="flex flex-col gap-6 overflow-hidden lg:flex-row">
      <div
        class="section-dashboard dashboard-animation relative"
        :class="[
          menuCollapsed
            ? 'w-full p-1 lg:w-16 lg:rounded-xl'
            : 'w-full flex-none px-5 py-4 sm:rounded-3xl lg:w-60',
          menuShow ? 'block' : 'hidden lg:block'
        ]"
      >
        <FontAwesomeIcon
          icon="fa-close"
          class="mb-6 block h-6 w-6 cursor-pointer hover:text-slate-200 lg:hidden"
          @click="menuShow = false"
        />

        <div @click="menuCollapsed = false">
          <div v-if="!menuCollapsed">
            <h3
              class="dashboard-sub-animation w-full overflow-hidden break-all pr-5 text-xl font-medium"
            >
              Hello {{ userName }},
            </h3>
            <p>Welcome</p>
          </div>
          <h3
            v-else
            class="section-secondary rounded-lg px-2 py-3 text-center text-xl dark:text-white"
          >
            {{ userName?.substring(0, 1) }}
          </h3>

          <button
            @click="openMenuOrStartCoding"
            class="btn btn-primary dashboard-sub-animation mb-5 mt-10 block w-3/4 py-2 text-sm font-normal lg:w-full"
          >
            <span v-if="!menuCollapsed">Start Coding</span>
            <FontAwesomeIcon
              icon="fa-arrow-right"
              :class="menuCollapsed ? 'h-5 w-5' : 'ml-1 h-4 w-4'"
            />
          </button>

          <div id="docs-sidebar" class="hs-overlay scrollbar-y dark:scrollbar-y overflow-y-auto">
            <nav
              class="hs-accordion-group flex w-3/4 flex-col flex-wrap lg:w-full"
              :class="menuCollapsed ? 'pl-4' : ''"
            >
              <ul class="space-y-7 py-4 text-base font-normal text-slate-700 dark:text-neutral-50">
                <li>
                  <RouterLink
                    :to="{ name: 'dashboard-project' }"
                    class="flex items-center gap-x-3.5 dark:hover:text-slate-300"
                  >
                    <FontAwesomeIcon
                      icon="fa-folder"
                      class="dashboard-sub-animation"
                      :class="menuCollapsed ? 'mr-5 h-5 w-5' : 'h-3 w-3'"
                    ></FontAwesomeIcon>
                    <span v-if="!menuCollapsed" class="w-4/5">My Projects</span>
                    <FontAwesomeIcon
                      icon="fa-chevron-right"
                      v-if="!menuCollapsed"
                      class="h-3 w-3"
                    />
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    :to="{ name: 'dashboard-api' }"
                    class="flex items-center gap-x-3.5 dark:hover:text-slate-300"
                  >
                    <FontAwesomeIcon
                      icon="fa-rocket"
                      :class="menuCollapsed ? 'h-5 w-5' : 'h-3 w-3'"
                    ></FontAwesomeIcon>
                    <span v-if="!menuCollapsed">APIs</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    :to="{ name: 'dashboard-plugin' }"
                    class="flex items-center gap-x-3.5 dark:hover:text-slate-300"
                  >
                    <FontAwesomeIcon
                      icon="fa-gears"
                      class="dashboard-sub-animation"
                      :class="menuCollapsed ? 'mr-5 h-5 w-5' : 'h-3 w-3'"
                    ></FontAwesomeIcon>
                    <span v-if="!menuCollapsed">Plugins</span>
                  </RouterLink>
                </li>

                <li class="hs-accordion">
                  <a
                    class="hs-accordion-toggle flex items-center gap-x-3.5 dark:hover:text-slate-300"
                    href="javascript:;"
                  >
                    <FontAwesomeIcon
                      icon="fa-school"
                      class="dashboard-sub-animation"
                      :class="menuCollapsed ? 'mr-5 h-5 w-5' : 'h-3 w-3'"
                    />
                    <span v-if="!menuCollapsed" class="w-4/5">Virtual Institutions</span>
                    <FontAwesomeIcon
                      icon="fa-angle-up"
                      class="hidden h-3 w-3 hs-accordion-active:block"
                      v-if="!menuCollapsed"
                    />
                    <FontAwesomeIcon
                      icon="fa-angle-down"
                      class="block h-3 w-3 hs-accordion-active:hidden"
                      v-if="!menuCollapsed"
                    />
                  </a>

                  <div
                    class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                    :class="menuCollapsed ? 'hidden' : 'block'"
                  >
                    <ul class="space-y-3.5 pt-4 font-normal">
                      <li>
                        <RouterLink
                          :to="{ path: '/dashboard/institution' }"
                          class="flex items-center gap-x-3.5 pl-12 dark:hover:text-slate-300"
                        >
                          Home
                        </RouterLink>
                      </li>
                      <li v-if="teachStore.instituteOwn">
                        <RouterLink
                          :to="{ path: `/dashboard/institution/${teachStore.instituteOwn?.code}` }"
                          class="flex items-center gap-x-3.5 pl-12 dark:hover:text-slate-300"
                        >
                          My Institute
                        </RouterLink>
                      </li>
                      <li>
                        <RouterLink
                          class="flex items-center gap-x-3.5 pl-12 dark:hover:text-slate-300"
                          to="/dashboard/institution/associations"
                        >
                          My Associations
                        </RouterLink>
                      </li>
                      <li>
                        <RouterLink
                          :to="{ path: `/dashboard/institution/assignments` }"
                          class="flex items-center gap-x-3.5 pl-12 dark:hover:text-slate-300"
                        >
                          My Assignments
                        </RouterLink>
                      </li>
                      <li>
                        <RouterLink
                          class="flex items-center gap-x-3.5 pl-12 dark:hover:text-slate-300"
                          to="/dashboard/institution/invitations"
                        >
                          Invitations
                        </RouterLink>
                      </li>
                    </ul>
                  </div>
                </li>

                <li class="hs-accordion">
                  <a
                    class="hs-accordion-toggle flex items-center gap-x-3.5 dark:hover:text-slate-300"
                    href="javascript:;"
                  >
                    <FontAwesomeIcon
                      icon="fa-dollar"
                      class="dashboard-sub-animation"
                      :class="menuCollapsed ? 'h-5 w-5' : 'h-3 w-3'"
                    />
                    <span v-if="!menuCollapsed" class="w-4/5">Payment Details</span>
                    <FontAwesomeIcon
                      icon="fa-angle-up"
                      class="hidden h-3 w-3 hs-accordion-active:block"
                      v-if="!menuCollapsed"
                    />
                    <FontAwesomeIcon
                      icon="fa-angle-down"
                      class="block h-3 w-3 hs-accordion-active:hidden"
                      v-if="!menuCollapsed"
                    />
                  </a>

                  <div
                    class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  >
                    <ul class="space-y-4 pt-4 font-normal">
                      <li>
                        <RouterLink
                          :to="{ name: 'dashboard-plans' }"
                          class="flex items-center gap-x-3.5 pl-12 dark:hover:text-slate-300"
                        >
                          Active Plans
                        </RouterLink>
                      </li>
                      <li>
                        <RouterLink
                          :to="{ name: 'dashboard-cards' }"
                          class="flex items-center gap-x-3.5 pl-12 dark:hover:text-slate-300"
                          href="javascript:;"
                        >
                          Cards
                        </RouterLink>
                      </li>
                      <li>
                        <RouterLink
                          :to="{ name: 'dashboard-history' }"
                          class="flex items-center gap-x-3.5 pl-12 dark:hover:text-slate-300"
                          href="javascript:;"
                        >
                          Payment history
                        </RouterLink>
                      </li>
                    </ul>
                  </div>
                </li>

                <li
                  :class="absolutePosition ? 'lg:absolute lg:bottom-44 lg:left-5 lg:right-5' : ''"
                >
                  <a
                    href="https://docs.jdoodle.com/"
                    target="_blank"
                    class="flex items-center gap-x-3.5 dark:hover:text-slate-300"
                  >
                    <FontAwesomeIcon
                      icon="fa-file-lines"
                      class="dashboard-sub-animation"
                      :class="menuCollapsed ? 'mr-5 h-5 w-5' : 'h-3 w-3'"
                    ></FontAwesomeIcon>
                    <span v-if="!menuCollapsed" class="w-4/5">Documents</span>
                    <FontAwesomeIcon
                      icon="fa-chevron-right"
                      v-if="!menuCollapsed"
                      class="h-3 w-3"
                    />
                  </a>
                </li>

                <li
                  :class="absolutePosition ? 'lg:absolute lg:bottom-32 lg:left-5 lg:right-5' : ''"
                >
                  <RouterLink
                    :to="{ name: 'contactus' }"
                    class="flex items-center gap-x-3.5 dark:hover:text-slate-300"
                  >
                    <FontAwesomeIcon
                      icon="fa-comment-dots"
                      class="dashboard-sub-animation"
                      :class="menuCollapsed ? 'mr-5 h-5 w-5' : 'h-3 w-3'"
                    ></FontAwesomeIcon>
                    <span v-if="!menuCollapsed" class="w-4/5">Contact Support</span>
                    <FontAwesomeIcon
                      icon="fa-chevron-right"
                      v-if="!menuCollapsed"
                      class="h-3 w-3"
                    />
                  </RouterLink>
                </li>
                <li
                  :class="absolutePosition ? 'lg:absolute lg:bottom-20 lg:left-5 lg:right-5' : ''"
                >
                  <RouterLink
                    :to="{ name: 'settings' }"
                    class="flex items-center gap-x-3.5 dark:hover:text-slate-300"
                  >
                    <FontAwesomeIcon
                      icon="fa-gears"
                      class="dashboard-sub-animation"
                      :class="menuCollapsed ? 'mr-5 h-5 w-5' : 'h-3 w-3'"
                    ></FontAwesomeIcon>
                    <span v-if="!menuCollapsed" class="w-4/5">Settings</span>
                    <FontAwesomeIcon
                      icon="fa-chevron-right"
                      v-if="!menuCollapsed"
                      class="h-3 w-3"
                    />
                  </RouterLink>
                </li>
                <li :class="absolutePosition ? 'lg:absolute lg:bottom-8 lg:left-5 lg:right-5' : ''">
                  <div class="flex cursor-pointer items-center gap-x-3.5 dark:hover:text-slate-300">
                    <FontAwesomeIcon
                      icon="right-from-bracket"
                      class="dashboard-sub-animation"
                      :class="menuCollapsed ? 'mr-5 h-5 w-5' : 'h-3 w-3'"
                    ></FontAwesomeIcon>
                    <span v-if="!menuCollapsed" class="w-4/5" @click="onClickLogout">Logout</span>
                    <FontAwesomeIcon
                      icon="fa-chevron-right"
                      v-if="!menuCollapsed"
                      class="h-3 w-3"
                    />
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <FontAwesomeIcon
          icon="fa-close"
          v-if="!menuCollapsed"
          class="absolute right-4 top-4 hidden h-5 w-5 cursor-pointer p-1 hover:text-slate-200 lg:block"
          @click="menuCollapsed = true"
        />
      </div>

      <div class="h-full w-full px-2 lg:px-0" ref="rightPanel">
        <RouterView></RouterView>
      </div>
    </div>
  </div>
</template>

<style>
.dashboard-animation {
  @apply transform transition-all duration-300 ease-in-out;
}

.dashboard-sub-animation {
  @apply transform transition-all duration-100 ease-in;
}
</style>
