<script setup lang="ts">
import { ref, type PropType, reactive } from 'vue'
import { useRouter } from 'vue-router'
import sortDescending from '@/assets/images/teach-and-assess/sort-descending.svg'
export interface IDashboardCardLink {
  url?: string
  modal?: string
  name: string
  disabled?: boolean
}
import { TEACHMODALHS } from '@/utils/models'
const emit = defineEmits(['searchInput', 'filterInput'])
const router = useRouter()

const searchString = ref('')

/**
 *
 */
function handleInput() {
  emit('searchInput', searchString.value)
}

/**
 *
 */
function handleFilter() {
  emit('filterInput', filters)
}

const props = defineProps({
  title: {
    type: String,
    required: false
  },
  emptyMsg: {
    type: String,
    required: false
  },
  link: {
    type: Object as PropType<IDashboardCardLink>,
    required: false
  },
  hasSearchBar: {
    type: Boolean,
    required: false
  },
  hasStatusFilter: {
    type: Boolean,
    required: false,
    default: false
  },
  hasStudentUserActions: {
    type: Boolean,
    required: false,
    default: false
  }
})

const showSortPanel = ref(false)
const filters = reactive({
  yetToStart: true,
  inProgress: true,
  submitted: true,
  evaluated: true,
  resultAnnounced: true,
  reevaluationRequested: true
})
</script>

<template>
  <div class="section-dashboard flex w-full flex-col rounded-xl px-6 py-5">
    <div
      class="flex flex-col"
      v-if="title || hasSearchBar || link"
      :class="hasSearchBar ? 'border-b border-b-slate-800' : ''"
    >
      <div class="flex justify-between">
        <div class="mb-4 flex items-center gap-3">
          <h3 v-if="title" class="inline-block text-lg font-medium">{{ title }}</h3>
          <div class="relative w-60" v-if="hasSearchBar">
            <input
              v-model="searchString"
              @input="handleInput"
              placeholder="Search"
              class="section-primary p-small block w-full rounded-lg px-5 py-2"
            />
            <FontAwesomeIcon icon="fa-search" class="text-brand absolute right-4 top-[10px]" />
          </div>

          <button @click="showSortPanel = !showSortPanel" v-if="props.hasStatusFilter">
            <img :src="sortDescending" class="w-5" :class="showSortPanel ? 'rotate-180' : ''" />
          </button>
        </div>
        <!-- Link Text -->
        <div v-if="!props.hasStudentUserActions">
          <RouterLink
            v-if="props.link && !hasSearchBar && props.link.url"
            :to="props.link.url"
            class="title-feature pt-1 dark:text-xs"
          >
            {{ props.link.name }}
          </RouterLink>

          <!-- Link as Button -->
          <button
            v-if="props.link && hasSearchBar && props.link.url"
            @click="router.push(props.link.url)"
            :disabled="props.link.disabled"
            class="btn-primary h-fit w-fit rounded-md px-4 py-2 text-sm"
          >
            {{ props.link.name }}
          </button>

          <button
            v-if="props.link && props.link.modal"
            :data-hs-overlay="`#${props.link.modal}`"
            :disabled="props.link.disabled"
            class="btn-dashboard-small h-fit w-fit rounded-md px-4 py-2 text-sm"
          >
            {{ props.link.name }}
          </button>
        </div>

        <div
          class="hs-dropdown relative inline-flex h-fit [--trigger:hover]"
          v-if="props.hasStudentUserActions"
        >
          <button
            id="hs-dropdown-hover-event"
            type="button"
            class="hs-dropdown-toggle btn-dashboard-small inline-block flex items-center justify-center gap-2 rounded-md px-4 py-2 align-middle text-sm shadow-sm transition-all"
          >
            Actions
            <svg
              class="h-2.5 w-2.5 text-gray-600 text-white hs-dropdown-open:rotate-180"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </button>

          <div
            class="hs-dropdown-menu duration mt-2 hidden w-60 rounded-lg bg-white p-2 opacity-0 shadow-md transition-[opacity,margin] before:absolute before:-top-4 before:left-0 before:h-4 before:w-full after:absolute after:-bottom-4 after:left-0 after:h-4 after:w-full hs-dropdown-open:opacity-100 dark:divide-gray-700 dark:border dark:border-gray-700 dark:bg-gray-800"
            aria-labelledby="hs-dropdown-hover-event"
          >
            <button
              :data-hs-overlay="`#${TEACHMODALHS.ADD_USER_STUDENT}`"
              class="hover:section-secondary w-full rounded-md p-2 text-start text-sm"
            >
              Add Student
            </button>
            <button
              :data-hs-overlay="`#${TEACHMODALHS.IMPORT_STUDENT_GROUP}`"
              class="hover:section-secondary w-full rounded-md p-2 text-start text-sm"
            >
              Import from Student Group
            </button>
          </div>
        </div>
      </div>

      <!-- Sorting checkboxes -->
      <div class="flex w-full gap-5 py-3 text-sm" v-show="showSortPanel">
        <div class="flex gap-2">
          <input
            type="checkbox"
            id="yetToStart"
            :checked="filters.yetToStart"
            @click="(event: Event) => {filters.yetToStart = (event.target as HTMLInputElement).checked;
            handleFilter()
            }"
          />
          <label for="yetToStart">Yet To Start</label>
        </div>
        <div class="flex gap-1">
          <input
            type="checkbox"
            id="inProgress"
            :checked="filters.inProgress"
            @click="(event: Event) => {filters.inProgress = (event.target as HTMLInputElement).checked;
            handleFilter()
            }"
          />
          <label for="inProgress">In Progress</label>
        </div>

        <div class="flex gap-1">
          <input
            type="checkbox"
            id="submitted"
            :checked="filters.submitted"
            @click="(event: Event) => {filters.submitted = (event.target as HTMLInputElement).checked;
            handleFilter()
            }"
          />
          <label for="submitted">Submitted</label>
        </div>

        <div class="flex gap-1">
          <input
            type="checkbox"
            id="evaluated"
            :checked="filters.evaluated"
            @click="(event: Event) => {filters.evaluated = (event.target as HTMLInputElement).checked;
            handleFilter()
            }"
          />
          <label for="evaluated">Evaluated</label>
        </div>

        <div class="flex gap-1">
          <input
            type="checkbox"
            id="resultAnnounced"
            :checked="filters.resultAnnounced"
            @click="(event: Event) => {filters.resultAnnounced = (event.target as HTMLInputElement).checked;
            handleFilter()
            }"
          />
          <label for="resultAnnounced">Result Announced</label>
        </div>

        <div class="flex gap-1">
          <input
            type="checkbox"
            id="reevaluationRequested"
            :checked="filters.reevaluationRequested"
            @click="(event: Event) => {filters.reevaluationRequested = (event.target as HTMLInputElement).checked;
            handleFilter()
            }"
          />
          <label for="reevaluationRequested">Re-evaluation Requested</label>
        </div>
      </div>
    </div>

    <div class="grow">
      <slot></slot>
    </div>
  </div>
</template>
