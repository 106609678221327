<script setup lang="ts">
import { ref, computed } from 'vue'
import Integrate_2 from '@/assets/images/svg/Homepage_5.svg'
import Integrate_7 from '@/assets/images/svg/Integrate_7.svg'

const tabs = ['Plugin', 'API']
const currentTab = ref('Plugin')

const tabPanels = {
  Plugin: {
    title: 'Compiler Plugins',
    textLeftImageRight: false,
    image: {
      src: Integrate_7,
      alt: 'Integrate animation'
    },
    list: [
      'Embed IDEs effortlessly into your LMS, websites and platforms with three lines of HTML code.',
      'Use Plugins for free with JDoodle branding or opt for custom-made plugins tailored to your specific  needs.',
      'Enhance teaching and learning experiences with a powerful and interactive coding environment within your platform.'
    ],
    button: 'Contact us',
    buttonLink: '/contact-us'
  },
  API: {
    title: 'Compiler APIs',
    textLeftImageRight: true,
    image: {
      src: Integrate_2,
      alt: 'Integrate animation'
    },
    list: [
      'Security and performance guaranteed with standards-based REST and WebSocket APIs.',
      'Easily send requests (programs) to the Compiler API and receive well-structured responses (output).',
      'Full control over your user interface to create a personalized coding environment.'
    ],
    button: 'Start Now',
    buttonLink: '/pricing'
  }
}

const data = computed(() => {
  return tabPanels[currentTab.value as keyof typeof tabPanels]
})
</script>

<template>
  <div class="view-container mt-16">
    <div class="m-auto w-fit">
      <div class="flex rounded-xl bg-[#17191F] p-2 transition">
        <nav class="flex space-x-2" aria-label="Tabs" role="tablist">
          <button
            v-for="(tab, index) in tabs"
            :key="index"
            type="button"
            class="text-md h-14 w-40 rounded-lg text-center font-medium font-normal text-gray-500 text-white hover:text-gray-300 hs-tab-active:bg-[#363944] hs-tab-active:hover:text-white"
            :class="{ active: currentTab == tab }"
            :id="`#tab-item-${tab}`"
            :data-hs-tab="`#tab-${tab}`"
            :aria-controls="`tab-${tab}`"
            role="tab"
            @click="currentTab = tab"
          >
            {{ tab }}
          </button>
        </nav>
      </div>
    </div>

    <div class="mt-3">
      <div :id="`tab-${currentTab}`" role="tabpanel" :aria-labelledby="`tab-item-${currentTab}`">
        <div class="section-primary py-8 md:py-24">
          <div class="flex flex-col gap-2 md:gap-24 lg:flex-row">
            <div class="py-20" :class="data.textLeftImageRight ? 'lg:order-none' : 'lg:order-2'">
              <h2 class="mb-8 max-w-xl text-start text-4xl font-bold leading-[2.5rem]">
                {{ data.title }}
              </h2>
              <p
                v-for="(item, idx) in data.list"
                :key="idx"
                class="text-secondary relative mb-4 pl-8 text-lg"
              >
                <span class="absolute left-0 w-6">
                  <FontAwesomeIcon icon="fa-check" class="text-brand h-5 w-5"></FontAwesomeIcon>
                </span>
                {{ item }}
              </p>

              <div class="my-8">
                <RouterLink :to="data.buttonLink" class="btn btn-primary w-fit text-base">{{
                  data.button
                }}</RouterLink>
                <!-- <Button_login_then_redirect v-if="data.button" :button-data="data.button" /> -->
              </div>
            </div>

            <div
              class="w-full"
              :class="[
                data.textLeftImageRight ? 'lg:order-none' : 'lg:order-1',
                data.textLeftImageRight ? 'lg:w-[60%]' : 'lg:w-[75%]'
              ]"
            >
              <img
                class="h-full rounded-lg"
                :class="data.image.src ? '' : 'opacity-20'"
                :src="data.image.src"
                :alt="data.image.alt"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
