<script setup lang="ts">
import Logo from '@/components/utils/LogoComp.vue'
import { LOADINGINDICATORTEXTTYPE } from '@/stores/api.store'
import { useApiStore } from '@/stores/api.store'
import { computed, type Ref } from 'vue'

const apiStore = useApiStore()

interface IIcon {
  icon: string
  animation: string | null
}

interface IModelData {
  textColor: string
  spanText: string
  icon: IIcon
}

const modelData: Ref<IModelData> = computed(() => {
  switch (apiStore.showLoadingIndicatorText?.type) {
    case LOADINGINDICATORTEXTTYPE.CRITICAL:
      return {
        textColor: 'error',
        spanText: 'Critical Error:',
        icon: {
          icon: 'triangle-exclamation',
          animation: null
        }
      }
    case LOADINGINDICATORTEXTTYPE.ERROR:
      return {
        textColor: 'error',
        spanText: 'Error:',
        icon: {
          icon: 'triangle-exclamation',
          animation: null
        }
      }
    case LOADINGINDICATORTEXTTYPE.SUCCESS:
      return {
        textColor: 'success',
        spanText: 'Success:',
        icon: {
          icon: 'circle-check',
          animation: null
        }
      }
    default:
      return {
        textColor: '',
        spanText: '',
        icon: {
          icon: 'spinner',
          animation: 'spin'
        }
      }
  }
})

const showLoadingIndicator = computed(() => apiStore.showLoadingIndicator)
const showLoadingIndicatorText = computed(() => apiStore.showLoadingIndicatorText)
</script>
<template>
  <div
    v-if="showLoadingIndicator"
    class="fixed left-0 top-0 z-[120] h-screen w-screen overflow-x-hidden overflow-y-hidden backdrop-blur-lg"
    @click="showLoadingIndicator = !showLoadingIndicator"
  >
    <div class="flex h-full w-full items-center justify-center">
      <div
        :class="[
          'section-primary',
          'flex',
          'items-center',
          'justify-between',
          'gap-8',
          'w-sm',
          'max-w-sm',
          'md:w-md',
          'md:max-w-md',
          'p-8',
          'rounded-lg',
          'shadow-2xl'
        ]"
      >
        <FontAwesomeIcon
          :icon="modelData.icon.icon"
          :class="['text-right text-6xl', 'shadow-lg', 'rounded-full', modelData.textColor]"
          :spin="modelData.icon.animation === 'spin'"
          :shake="modelData.icon.animation === 'shake'"
          :bounce="modelData.icon.animation === 'bounce'"
        />

        <div class="flex flex-col items-start justify-center gap-4">
          <Logo :isLink="false" />
          <div class="flex flex-col items-start justify-center gap-2">
            <p class="p-normal break-words">
              <span
                v-if="showLoadingIndicatorText.type !== LOADINGINDICATORTEXTTYPE.LOADING"
                :class="modelData.textColor"
              >
                {{ modelData.spanText }}
              </span>
              {{ showLoadingIndicatorText.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
