import moment from 'moment'

/**
 * @param dte Date time object
 * @returns formatted date
 */
export const getFormattedDate = (dte: string | number | null | undefined) => {
  if (!dte) {
    return ''
  }

  return moment(dte).format('MMMM Do YYYY, h:mm:ss a')
}

/**
 * @param dte Date time object
 * @returns formatted date
 */
export const getAssignmentDeadlineDate = (dte: string | number | null) => {
  if (!dte) {
    return ''
  }

  return moment(dte).format('D MMM YYYY')
}

/**
 * @param dte Date time object
 * @returns formatted time
 */
export const getAssignmentDeadlineTime = (dte: string | number | null) => {
  if (!dte) {
    return ''
  }

  return moment(dte).format('h:mm a')
}
