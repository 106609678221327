<script setup lang="ts">
import settingService from '@/services/ide/settings/setting.service'
import ModelLayout from '@/layouts/ModelLayout.vue'
import { IDEVIEWMODELS } from '@/utils/models'

/**
 * Clear current project
 */
const clearProject = () => {
  settingService.clearProject()
}
</script>

<template>
  <ModelLayout title="New Project/ Clear All" :hs="IDEVIEWMODELS.NEWPROJECT">
    <div class="flex flex-col gap-1">
      <p class="p-normal">Do you want to clear current project?</p>
      <div class="flex w-full gap-2">
        <button
          class="btn-primary btn-rounded-md"
          :data-hs-overlay="`#${IDEVIEWMODELS.NEWPROJECT}`"
          data-hs-overlay-close
          @click="clearProject"
        >
          Yes
        </button>
        <button
          class="btn-secondary btn-rounded-md"
          :data-hs-overlay="`#${IDEVIEWMODELS.NEWPROJECT}`"
          data-hs-overlay-close
        >
          No
        </button>
      </div>
    </div>
  </ModelLayout>
</template>
