<script setup lang="ts">
import type IAssignmentDetailed from '@/components/guru/interface/IAssignmentDetailed'
import { computed, onMounted, ref, type PropType } from 'vue'
import { TEACH_CONSTANTS } from '@/components/guru/constants/teach.constants'
import { languagesItems, type ILanguage } from '@/utils/sharedData/languages'
import {
  GURU_ASSIGNMENT_ACTIONS,
  TEACH_ASSIGNMENT_STATUS,
  TEACH_DURATION_MODAL_MODE
} from '@/components/guru/enums/teach.enum'
import { TEACHMODALHS } from '@/utils/models'
import { useTeachStore } from '@/stores/teach.store'
import { useRoute, useRouter } from 'vue-router'
import CreateAssignmentModal from '@/components/guru/modals/CreateAssignmentModal.vue'
import ActivateAssignmentModal from '@/components/guru/modals/ActivateAssignmentModal.vue'
import ErrorInstructionModal from '@/components/guru/modals/ErrorInstructionModal.vue'
import AssignmentOnHoldModal from '@/components/guru/modals/confirmationModals/AssignmentOnHoldModal.vue'
import AssignmentDeleteModal from '@/components/guru/modals/confirmationModals/AssignmentDeleteModal.vue'
import type { IActivateAssignment_Request } from '@/components/guru/interface/requests.interface'
import { activateAssignment } from '@/services/teach.service'
import { HttpStatusCode } from 'axios'

const route = useRoute()
const router = useRouter()
const teachStore = useTeachStore()
const insCode = route.params.insCode as string
const assignmentId = Number.parseInt(route.params.assignmentId as string)

const props = defineProps({
  assignment: {
    type: Object as PropType<IAssignmentDetailed>,
    required: false
  },
  mode: {
    type: Object as PropType<GURU_ASSIGNMENT_ACTIONS>,
    required: false
  }
})

const currentMode = ref(props.mode as undefined | GURU_ASSIGNMENT_ACTIONS)
const durationModalMode = ref(TEACH_DURATION_MODAL_MODE.ACTIVATE)
onMounted(async () => {
  getVersionString()
})

const assignmentData = computed(() => {
  return props.assignment
})

/**
 * Activates an on-hold assignment using it's saved start/end time.
 */
const activateOnHoldAssignment = async () => {
  const reqObj: IActivateAssignment_Request = {
    instituteCode: insCode,
    assignmentId: assignmentId,
    assignmentStartDate: assignmentData.value?.startTime!,
    assignmentEndDate: assignmentData.value?.endTime!
  }

  const res = await activateAssignment(reqObj)

  if (res == HttpStatusCode.Ok) {
    router.go(0)
  }
}

/**
 *
 * @param mode mode
 */
const setModalMode = (mode: GURU_ASSIGNMENT_ACTIONS) => {
  currentMode.value = mode
}

/**
 * @returns modal
 */
const getActivateModal = () => {
  if (
    assignmentData.value &&
    assignmentData.value?.questions.length > 0 &&
    assignmentData.value?.students.length > 0
  ) {
    return `#${TEACHMODALHS.ACTIVATE_ASSIGNMENT}`
  }

  return `#${TEACHMODALHS.ERROR_INSTRUCTION}`
}

/**
 * @param value Saved value
 * @returns Result type string
 */
function getResultTypeString(value: number) {
  return TEACH_CONSTANTS.ASSIGNMENT_RESULT_TYPES[value].type
}

/**
 * @param value Saved value
 * @returns Result type string
 */
function getDiscloseTypeString(value: number) {
  return TEACH_CONSTANTS.DISCLOSE_TYPES[value].type
}

/**
 * Returns displayname of selected language
 * @returns string
 */
function getDefaultLanguageDisplay() {
  if (assignmentData.value?.defaultLanguage == 'none') return 'None'

  return languagesItems.find(
    (lang: ILanguage) => lang.language == assignmentData.value?.defaultLanguage
  )?.displayName
}

/**
 * @returns Result version string
 */
function getVersionString() {
  const versions = languagesItems.find(
    (lang: ILanguage) => lang.language == assignmentData.value?.defaultLanguage
  )?.versions

  if (versions != undefined && assignmentData.value?.versionIndex != undefined)
    return versions[assignmentData.value?.versionIndex]

  return undefined
}

// Status
const isDraft = computed(() => assignmentData.value?.status == TEACH_ASSIGNMENT_STATUS.DRAFT)
const isOnHold = computed(() => assignmentData.value?.status == TEACH_ASSIGNMENT_STATUS.ON_HOLD)
const isReady = computed(() => assignmentData.value?.status == TEACH_ASSIGNMENT_STATUS.READY)
const isInProgress = computed(
  () => assignmentData.value?.status == TEACH_ASSIGNMENT_STATUS.IN_PROGRESS
)
const isCompleted = computed(
  () => assignmentData.value?.status == TEACH_ASSIGNMENT_STATUS.COMPLETED
)

// Subscription message / error message
const activeLimitMessage = computed(() => {
  if (
    teachStore.activeAssignmentLimitReached &&
    assignmentData.value?.status !== TEACH_ASSIGNMENT_STATUS.IN_PROGRESS
  ) {
    return `You have reached the institute's plan limit of ${teachStore.instituteSubscriptionInfo?.limit.activeAssignmentLimit} active assignment(s)`
  } else return ''
})

const activateErrorModal = {
  title: 'Activate Assignment',
  message: 'Please add at least one student and one question to activate.'
}
</script>

<template>
  <CreateAssignmentModal
    :ins-code="insCode"
    :assignment="assignmentData"
    :assignmentId="assignmentId"
    :mode="currentMode"
  />

  <ErrorInstructionModal :title="activateErrorModal.title" :message="activateErrorModal.message" />

  <ActivateAssignmentModal
    :instituteCode="insCode"
    :assignmentId="assignmentId"
    :assignment="assignmentData"
    :mode="durationModalMode"
  />

  <AssignmentDeleteModal
    :instituteCode="insCode"
    :assignmentId="assignmentId"
    :assignment="assignmentData"
  />

  <AssignmentOnHoldModal :instituteCode="insCode" :assignmentId="assignmentId" />

  <div class="flex flex-col">
    <h2 class="mb-2">{{ assignmentData?.title }}</h2>
    <div class="text-secondary mb-5 break-words text-sm">
      <p>{{ assignmentData?.description }}</p>
    </div>

    <table class="table-auto">
      <tr>
        <td class="py-1 font-bold">Status:</td>
        <td class="py-1">{{ assignmentData?.status }}</td>
      </tr>
      <tr>
        <td class="py-1 font-bold">Default Language:</td>
        <td class="py-1">{{ getDefaultLanguageDisplay() }}</td>
      </tr>
      <tr v-if="getVersionString() != undefined">
        <td class="py-1 font-bold">Version</td>
        <td class="py-1">
          {{ getVersionString() }}
        </td>
      </tr>
      <tr>
        <td class="py-1 font-bold">Auto Correct:</td>
        <td class="py-1">{{ assignmentData?.autoCorrect }}</td>
      </tr>
      <tr v-if="assignmentData?.autoCorrect">
        <td class="py-1 font-bold">Auto Score:</td>
        <td class="py-1">{{ assignmentData?.autoScore }}</td>
      </tr>
      <tr v-if="assignmentData?.autoScore">
        <td class="py-1 font-bold">Negative Marking:</td>
        <td class="py-1">{{ assignmentData?.enableNegativeMark }}</td>
      </tr>
      <tr v-if="assignmentData?.resultType">
        <td class="py-1 font-bold">Result Type:</td>
        <td class="py-1">{{ getResultTypeString(assignmentData?.resultType) }}</td>
      </tr>
      <tr v-if="assignmentData?.autoCorrect && assignmentData?.disclose">
        <td class="py-1 font-bold">Disclose Result:</td>
        <td class="py-1">{{ getDiscloseTypeString(assignmentData?.disclose) }}</td>
      </tr>
      <tr v-if="assignmentData?.autoCorrect && assignmentData?.disclose">
        <td class="py-1 font-bold">Lead:</td>
        <td class="py-1">
          {{ assignmentData.ownedBy.firstName }} {{ assignmentData.ownedBy.lastName }}
        </td>
      </tr>

      <p class=""></p>
    </table>
    <div class="mt-5 flex flex-wrap justify-start gap-2">
      <!-- Initial Activation (Date selection modal) -->
      <button
        v-if="isDraft"
        :data-hs-overlay="getActivateModal()"
        class="btn-primary rounded-md p-2 text-xs"
        @click="durationModalMode = TEACH_DURATION_MODAL_MODE.ACTIVATE"
        :disabled="teachStore.activeAssignmentLimitReached"
      >
        Activate
      </button>
      <!-- Activate when on-hold (No Modal) -->
      <button
        v-if="isOnHold"
        class="btn-primary rounded-md p-2 text-xs"
        @click="activateOnHoldAssignment"
        :disabled="teachStore.activeAssignmentLimitReached"
      >
        Activate
      </button>
      <button
        v-if="isReady || isInProgress"
        :data-hs-overlay="`#${TEACHMODALHS.ON_HOLD_CONFIRMATION}`"
        class="btn-dashboard-small p-2 text-xs"
      >
        Put On Hold
      </button>

      <button
        v-if="isReady || isInProgress || isCompleted"
        :data-hs-overlay="`#${TEACHMODALHS.ACTIVATE_ASSIGNMENT}`"
        class="btn-dashboard-small p-2 text-xs"
        @click="durationModalMode = TEACH_DURATION_MODAL_MODE.CHANGE"
      >
        Change Duration
      </button>

      <button
        class="btn-dashboard-small p-2 text-xs"
        @click="
          $router.push(`/dashboard/institution/${insCode}/assignment/${assignmentId}/preview`)
        "
      >
        Preview
      </button>

      <button
        class="btn-dashboard-small p-2 text-xs"
        @click="setModalMode(GURU_ASSIGNMENT_ACTIONS.EDIT)"
        :data-hs-overlay="`#${TEACHMODALHS.CREATE_ASSIGNMENT}`"
      >
        Edit
      </button>

      <button
        class="btn-dashboard-small p-2 text-xs"
        @click="setModalMode(GURU_ASSIGNMENT_ACTIONS.CLONE)"
        :data-hs-overlay="`#${TEACHMODALHS.CREATE_ASSIGNMENT}`"
        :disabled="teachStore.draftLimitReached"
      >
        Clone
      </button>
      <button
        :data-hs-overlay="`#${TEACHMODALHS.DELETE_ASSIGNMENT_CONFIRMATION}`"
        class="btn-dashboard-small p-2 text-xs"
      >
        Delete
      </button>
    </div>
    <div class="mt-4 flex flex-col gap-2 text-start">
      <p class="error text-xs" v-if="teachStore.activeAssignmentLimitReached">
        {{ activeLimitMessage }}
      </p>
      <p class="error text-xs" v-if="teachStore.draftLimitReached">
        {{ teachStore.draftLimitMessage }}
      </p>
    </div>
  </div>
</template>
