<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import utilModelsService from '@/services/util.models.service'

const props = defineProps({
  isShowShared: {
    type: Boolean,
    required: true,
    default: false
  },
  projectUrl: {
    type: String,
    required: true,
    default: ''
  },
  showEmbed: {
    type: Boolean,
    required: true,
    default: false
  },
  embedUrl: {
    type: String,
    required: true,
    default: ''
  },
  pluginId: {
    type: String,
    required: false,
    default: null
  },
  isProBundlePlan: {
    type: Boolean,
    required: false,
    default: false
  },
  closeHsModal: {
    type: String,
    required: false,
    default: null
  }
})

const copiedToClip = ref<boolean>(false)
const showCopiedMessageText = ref<string>('')
const copySuccess = ref<boolean>(false)

const router = useRouter()
const timeOut = ref<any | null>(null)

/**
 * Copy to clipboard
 * @param url string
 */
const copyToClipboard = async (url: string) => {
  window.navigator.clipboard.writeText(url).then(
    () => {
      showCopiedMessageText.value = 'Copied to clipboard!'
      copySuccess.value = true
      copiedToClip.value = true
    },
    () => {
      showCopiedMessageText.value = 'Copy to clipboard failed!'
      copySuccess.value = false
      copiedToClip.value = true
    }
  )
  if (timeOut.value) clearTimeout(timeOut.value)
  timeOut.value = setTimeout(() => {
    copiedToClip.value = false
    copySuccess.value = false
    showCopiedMessageText.value = ''
  }, 5000)
}
/**
 * Open the custom plugin
 */
const openCustomPlugin = () => {
  if (props.closeHsModal) {
    utilModelsService.closeModal(props.closeHsModal)
  }
  router.push({
    name: 'dashboard-configure-plugin',
    params: {
      pluginId: props.pluginId
    }
  })
}
</script>

<template>
  <div v-if="props.isShowShared">
    <p class="heading-xsmall text-sm">Share Url<span v-if="props.showEmbed">s</span></p>
    <div class="flex flex-col gap-1">
      <button
        v-if="props.projectUrl"
        class="link-secondary flex items-center gap-1"
        @click="copyToClipboard(props.projectUrl)"
      >
        <FontAwesomeIcon icon="fa-copy" class="h-4 w-4" />
        <span class="p-small w-full break-all text-left">Share URL - {{ props.projectUrl }}</span>
      </button>
      <div v-if="props.showEmbed && props.embedUrl" class="flex items-start gap-4">
        <button
          class="link-secondary flex items-start gap-1"
          @click="copyToClipboard(props.embedUrl)"
        >
          <FontAwesomeIcon icon="fa-copy" class="h-4 w-4" />
          <span class="p-small w-full break-all text-left">Embed URL - {{ props.embedUrl }}</span>
        </button>
        <button
          v-if="props.showEmbed && props.pluginId && props.isProBundlePlan"
          class="link-primary p-small"
          @click="openCustomPlugin"
        >
          Customise
        </button>
      </div>
      <p
        :class="['p-xsmall ', { success: copySuccess, error: !copySuccess, hidden: !copiedToClip }]"
      >
        {{ showCopiedMessageText }}
      </p>
    </div>
  </div>
</template>
