<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type IAssociation from '@/components/guru/interface/IAssociation'
import { useTeachStore } from '@/stores/teach.store'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { GURU_ASSIGNMENT_ACTIONS, GURU_USER_STATUS } from '../enums/teach.enum'
import DashboardCardEmptyMessageButton, {
  type IDashboardEmptyData
} from '@/components/shared/dashboard/DashboardCardEmptyMessageButton.vue'
import AssociationBlock from './AssociationBlock.vue'

const teachStore = useTeachStore()
const searchInput = ref('')

const associations = computed(() => {
  return teachStore.associations?.filter(
    (asso: IAssociation) =>
      asso.status !== GURU_USER_STATUS.INACTIVE &&
      asso.status !== GURU_USER_STATUS.PENDING_USER_ACCEPTANCE &&
      asso.status !== GURU_USER_STATUS.REJECT
  )
})

const filteredAssociations = computed(() => {
  if (searchInput.value == '') {
    return associations.value?.slice(0, 4)
  } else {
    const searchLowerCase = searchInput.value.toLowerCase()
    return associations.value
      ?.filter((association: IAssociation) => {
        return (
          association.institute.name.toLowerCase().includes(searchLowerCase) ||
          association.institute.description.toLowerCase().includes(searchLowerCase) ||
          association.institute.code.toLowerCase().includes(searchLowerCase)
        )
      })
      ?.slice(0, 5)
  }
})

const emptyData: IDashboardEmptyData = {
  message: 'You do not have any associated institutions'
}
</script>

<template>
  <div class="h-full">
    <!-- No Associations -->
    <div class="" v-if="!associations || associations.length < 1">
      <DashboardCardEmptyMessageButton :empty-data="emptyData" />
    </div>

    <!-- Yes Associations -->
    <div class="flex flex-col gap-4" v-else>
      <div class="relative flex">
        <input
          v-model="searchInput"
          class="section-primary p-small block w-full rounded-md px-4 py-2"
          placeholder="Search"
        />
        <FontAwesomeIcon icon="fa-search" class="text-brand absolute right-4 top-3" />
      </div>
      <div v-for="association in filteredAssociations" :key="association.institute.code">
        <AssociationBlock :association="association" />
      </div>
    </div>
  </div>
</template>
