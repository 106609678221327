<script setup lang="ts">
import type { ISelectListItem } from '@/components/forms/ISelectListItem'
import { computed, type PropType } from 'vue'
import { FORM_SELECT_VALUE_TYPE } from '@/components/forms/form.enum'
import type { ILanguage } from '@/utils/sharedData/languages'

const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  list: {
    type: Array<ISelectListItem | string>,
    required: true
  },
  default: {
    type: Object as PropType<ISelectListItem>,
    require: false
  },
  placeholder: {
    type: String,
    default: ''
  },
  error: {
    type: String,
    default: ''
  },
  modelValue: {
    required: true
  },
  valueType: {
    type: String as PropType<FORM_SELECT_VALUE_TYPE>,
    require: true
  },
  disabled: {
    type: Boolean,
    require: false
  },
  isContactus: {
    type: Boolean,
    require: false,
    default: false
  },
  isLightGray: {
    type: Boolean,
    required: false,
    default: true
  }
})

/**
 *
 * @param item String | ISelectListItem
 * @returns item
 */
function getItemDisplay(item: String | ISelectListItem) {
  if (typeof item == 'string') {
    return item
  }

  if ((item as ISelectListItem).displayName) return (item as ISelectListItem).displayName

  if ((item as ISelectListItem).type) return (item as ISelectListItem).type
  if ((item as ISelectListItem).name) return (item as ISelectListItem).name

  return item
}

/**
 *
 * @param item String | ISelectListItem
 * @param index number
 * @returns item
 */
function getItemValue(item: String | ISelectListItem, index: number) {
  if (props.valueType === FORM_SELECT_VALUE_TYPE.INDEX) {
    return index
  }

  if (props.valueType === FORM_SELECT_VALUE_TYPE.NAME) {
    return (item as ISelectListItem).displayName
  }

  if (props.valueType === FORM_SELECT_VALUE_TYPE.VALUE) {
    return (item as ISelectListItem).value
  }

  if (props.valueType === FORM_SELECT_VALUE_TYPE.TYPE) {
    return (item as ISelectListItem).type
  }

  if (props.valueType === FORM_SELECT_VALUE_TYPE.LANGUAGE) {
    return (item as ILanguage).language
  }

  if (props.valueType === FORM_SELECT_VALUE_TYPE.ROLE_NAME) {
    return item as ISelectListItem
  }

  return item
}

/**
 *
 * @param item ISelectListItem | string
 * @param index number
 * @returns boolean
 */
function getSelectedItem(item: ISelectListItem | string | any, index: number) {
  if (props.valueType === FORM_SELECT_VALUE_TYPE.INDEX) {
    return index === props.modelValue
  }

  if (props.valueType === FORM_SELECT_VALUE_TYPE.NAME) {
    return (item as ISelectListItem).displayName === props.modelValue
  }

  if (props.valueType === FORM_SELECT_VALUE_TYPE.VALUE) {
    return (item as ISelectListItem).value === props.modelValue
  }

  if (props.valueType === FORM_SELECT_VALUE_TYPE.TYPE) {
    return (item as ISelectListItem).type === props.modelValue
  }

  if (props.valueType === FORM_SELECT_VALUE_TYPE.LANGUAGE) {
    return (item as ILanguage).language === props.modelValue
  }

  if (props.default) {
    return item == (props.default as ISelectListItem)
  }
  return item == props.modelValue
}

const inputClasses = computed(() => {
  if (props.isContactus) {
    return 'rounded-none border-b px-1'
  } else if (props.isLightGray) {
    return `bg-neutral-50 dark:bg-[#17191F] rounded-md px-4`
  } else {
    return `rounded-md px-4`
  }
})
</script>

<template>
  <div class="my-2">
    <div class="flex items-center justify-between">
      <label :for="props.name || `${props.label + props.placeholder}`" class="p-small my-2 block">{{
        props.label
      }}</label>
      <slot />
    </div>
    <div class="relative">
      <select
        class="section-primary p-small block w-full py-2"
        :class="inputClasses"
        v-bind="$attrs"
        :id="props.name || `${props.label + props.placeholder}`"
        :placeholder="props.placeholder"
        @change="(event: Event) => $emit('update:modelValue', (event.target as HTMLSelectElement).value)"
      >
        <!-- Null as default -->
        <option v-if="props.default === null" :selected="true" :disabled="true">
          Please Select
        </option>
        <!-- Specified Default, or first item -->
        <option
          v-for="(item, index) in list"
          :selected="getSelectedItem(item, index)"
          :value="getItemValue(item, index)"
          :key="index"
        >
          {{ getItemDisplay(item) }}
        </option>
      </select>

      <div
        :class="[
          'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3',
          { hidden: !props.error }
        ]"
      >
        <FontAwesomeIcon icon="fa-circle-xmark" class="error h-5 w-5" aria-hidden="true" />
      </div>
    </div>
    <p :class="['p-xsmall error', { hidden: !props.error }]">
      {{ props.error }}
    </p>
  </div>
</template>
