<script setup lang="ts">
import { GURU_USER_STATUS } from '@/components/guru/enums/teach.enum'
import type IAssociation from '@/components/guru/interface/IAssociation'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import DashboardCardEmptyMessageButton, {
  type IDashboardEmptyData
} from '@/components/shared/dashboard/DashboardCardEmptyMessageButton.vue'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import { useTeachStore } from '@/stores/teach.store'
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

const teachStore = useTeachStore()
const breadcrumbStore = useBreadcrumbStore()

const searchString = ref('')

const router = useRouter()
onMounted(async () => {
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
})

/**
 *
 */
// function checkAuthentication() {
//   if (!teachStore.guruInitialised) {
//     return
//   }

//   let location = '/dashboard/institute/' + route.params.insCode

//   if (!teachStore.isAccountOwner && !teachStore.isInstituteAdmin && !teachStore.isTeacher) {
//     router.push(location)
//   }
// }

/**
 *
 */
// function sortUsers() {
//   users.value = sortBy(users.value, (o) => {
//     return o.firstName + ' ' + o.lastName
//   })
// }

/**
 *
 * @param association Association row clicked
 */
async function handleViewInvitation(association: IAssociation) {
  router.push(
    `/dashboard/institution/${association.institute.code}/invitation/${association.invitationCode}`
  )
}

const invitations = computed(() => {
  if (searchString.value) {
    return teachStore.associations?.filter((asso: IAssociation) => {
      return (
        (asso.status == GURU_USER_STATUS.PENDING_USER_ACCEPTANCE ||
          asso.status == GURU_USER_STATUS.REJECT) &&
        (asso.institute.name.includes(searchString.value) ||
          asso.institute.description.includes(searchString.value))
      )
    })
  } else {
    return teachStore.associations?.filter((asso: IAssociation) => {
      return (
        asso.status == GURU_USER_STATUS.PENDING_USER_ACCEPTANCE ||
        asso.status == GURU_USER_STATUS.REJECT
      )
    })
  }
})

/**
 * Handles emission of search string and filters invitations
 * @param emit The emitted search string
 */
function handleSearchInput(emit: string) {
  searchString.value = emit
}

const breadcrumbLinks = computed(() => [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  },
  {
    href: '/dashboard/institution',
    title: 'Virtual Institutions'
  },
  {
    href: `/dashboard/institution/invitations`,
    title: `My Invitations`
  }
])

const emptyData: IDashboardEmptyData = {
  message: 'You do not have any invitations'
}
</script>

<template>
  <div class="h-full w-full">
    <DashboardCard
      title="My Invitations"
      class="h-full w-full"
      :has-search-bar="true"
      @searchInput="handleSearchInput"
    >
      <div class="m-auto max-w-4xl">
        <div v-if="invitations && invitations.length > 0" class="mt-5 w-full">
          <table class="w-full table-auto border-separate border-spacing-y-4">
            <thead class="text-start text-sm">
              <tr>
                <th class="px-3 py-1 text-start">Name</th>
                <th class="px-3 py-1 text-start">Description</th>
                <th class="px-3 py-1 text-start">Role(s)</th>
                <th class="px-3 py-1 text-start">Status</th>
              </tr>
            </thead>
            <tbody class="">
              <tr
                @click="handleViewInvitation(association)"
                role="button"
                v-for="(association, index) in invitations"
                :key="index"
                class="section-secondary"
              >
                <td class="rounded-s-lg p-3 text-sm">{{ association.institute.name }}</td>
                <td class="p-3 text-sm">
                  {{ association.institute.description.slice(0, 30)
                  }}<span v-if="association.institute.description.length > 30">...</span>
                </td>
                <td class="p-3 text-sm">
                  <span v-for="(role, index) in association.associationTypes" :key="index">
                    {{ role }}<br
                  /></span>
                </td>
                <td class="rounded-e-lg p-3 text-sm">{{ association.status }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <DashboardCardEmptyMessageButton :empty-data="emptyData" />
        </div>
      </div>
    </DashboardCard>
  </div>
</template>
