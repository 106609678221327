import Dark_Academy from '@/assets/images/shared/reviews/businesses/AOT_Wh.png'
import Dark_Automatapi from '@/assets/images/shared/reviews/businesses/Dark_Automatapi.png'
import Dark_Chisum from '@/assets/images/shared/reviews/businesses/Dark_Chisum.png'
import Dark_CloudAngels from '@/assets/images/shared/reviews/businesses/Dark_CloudAngels.png'
import Dark_Empower from '@/assets/images/shared/reviews/businesses/Dark_Empower.png'
import Dark_Fort from '@/assets/images/shared/reviews/businesses/Dark_Fort Bend ISD.png'
import Dark_GVPCE from '@/assets/images/shared/reviews/businesses/Dark_GVPCE.png'
import Dark_GeekHunter from '@/assets/images/shared/reviews/businesses/Dark_GeekHunter.svg'
import Dark_IISER from '@/assets/images/shared/reviews/businesses/Dark_IISER.png'
import Dark_Irving from '@/assets/images/shared/reviews/businesses/Dark_Irving.png'
import Dark_Linkgroup from '@/assets/images/shared/reviews/businesses/Dark_Linkgroup.png'
import Dark_Lockport from '@/assets/images/shared/reviews/businesses/Dark_Lockport.svg'
import Dark_MES from '@/assets/images/shared/reviews/businesses/Dark_MES.png'
import Dark_MetropolitianU_HongKong from '@/assets/images/shared/reviews/businesses/Dark_MetropolitianU_HongKong.png'
import Dark_Milwaukee from '@/assets/images/shared/reviews/businesses/Dark_Milwaukee Public Schools.png'
import Dark_Orangecountypublicschool from '@/assets/images/shared/reviews/businesses/Dark_Orangecountypublicschool.png'
import Dark_Outlier from '@/assets/images/shared/reviews/businesses/Dark_Outlier.png'
import Dark_Parul from '@/assets/images/shared/reviews/businesses/Dark_Parul University.png'
import Dark_Thomas from '@/assets/images/shared/reviews/businesses/Dark_Thomas Institute for Science and Technology.png'
import Dark_UNSW from '@/assets/images/shared/reviews/businesses/Dark_UNSW.svg'
import Dark_UniversityKansas from '@/assets/images/shared/reviews/businesses/Dark_UniversityKansas.svg'

import Light_Academy from '@/assets/images/shared/reviews/businesses/AOT_Clr.png'
import Light_Automatapi from '@/assets/images/shared/reviews/businesses/Light_Automatapi.png'
import Light_Chisum from '@/assets/images/shared/reviews/businesses/Light_Chisum.png'
import Light_CloudAngels from '@/assets/images/shared/reviews/businesses/Light_CloudAngels.png'
import Light_Empower from '@/assets/images/shared/reviews/businesses/Light_Empower.png'
import Light_Fort from '@/assets/images/shared/reviews/businesses/Light_Fort Bend ISD.png'
import Light_GVPCE from '@/assets/images/shared/reviews/businesses/Light_GVPCE.png'
import Light_GeekHunter from '@/assets/images/shared/reviews/businesses/Light_GeekHunter.svg'
import Light_IISER from '@/assets/images/shared/reviews/businesses/Light_IISER.png'
import Light_Irving from '@/assets/images/shared/reviews/businesses/Light_Irving.png'
import Light_Linkgroup from '@/assets/images/shared/reviews/businesses/Light_LinkGroup.png'
import Light_Lockport from '@/assets/images/shared/reviews/businesses/Light_Lockport.png'
import Light_MES from '@/assets/images/shared/reviews/businesses/Light_MES.png'
import Light_MetropolitianU_HongKong from '@/assets/images/shared/reviews/businesses/Light_MetropolitianU_HongKong.png'
import Light_Milwaukee from '@/assets/images/shared/reviews/businesses/Light_Milwaukee Public Schools.png'
import Light_Orangecountypublicschool from '@/assets/images/shared/reviews/businesses/Light_Orangecountypublicschool.png'
import Light_Outlier from '@/assets/images/shared/reviews/businesses/Light_Outlier.svg'
import Light_Parul from '@/assets/images/shared/reviews/businesses/Light_Parul University.png'
import Light_Thomas from '@/assets/images/shared/reviews/businesses/Light_Thomas Institute for Science and Technology.png'
import Light_UNSW from '@/assets/images/shared/reviews/businesses/Light_UNSW.svg'
import Light_UniversityKansas from '@/assets/images/shared/reviews/businesses/Light_UniversityKansas.svg'

import ASK from '@/assets/images/shared/reviews/businesses/American School of Kuwait.png'
import BITS from '@/assets/images/shared/reviews/businesses/BITS Pilani.png'
import Bay from '@/assets/images/shared/reviews/businesses/Bay District Schools.png'
import CityMontessoriSchool from '@/assets/images/shared/reviews/businesses/CityMontessoriSchool.png'
import Colegiul from '@/assets/images/shared/reviews/businesses/Colegiul National Octavian Goga Miercurea-Ciuc Harghita.png'
import JerseyCityPublicschool from '@/assets/images/shared/reviews/businesses/JerseyCityPublicschool.png'
import LiceoSSFP from '@/assets/images/shared/reviews/businesses/LiceoSSFP.png'
import Sarasota from '@/assets/images/shared/reviews/businesses/Sarasota County Schools.png'
import Shri from '@/assets/images/shared/reviews/businesses/Shri Ramdeobaba College of Engineering and Managemen.png'
import itext from '@/assets/images/shared/reviews/businesses/itext.svg'
import kodezi from '@/assets/images/shared/reviews/businesses/kodezi.png'
import lexington from '@/assets/images/shared/reviews/businesses/lexington county school district 3.png'
import sanAgustin from '@/assets/images/shared/reviews/businesses/sanAgustin.png'

export const institutesForDark = [
  {
    src: Dark_Academy,
    alt: 'Dark_Academy'
  },
  {
    src: Dark_Automatapi,
    alt: 'Dark_Automatapi'
  },
  {
    src: Dark_Chisum,
    alt: 'Dark_Chisum'
  },
  {
    src: Dark_CloudAngels,
    alt: 'Dark_CloudAngels'
  },
  {
    src: Dark_Empower,
    alt: 'Dark_Empower'
  },
  {
    src: Dark_Fort,
    alt: 'Dark_Fort'
  },
  {
    src: Dark_GVPCE,
    alt: 'Dark_GVPCE'
  },
  {
    src: Dark_GeekHunter,
    alt: 'Dark_GeekHunter'
  },
  {
    src: Dark_IISER,
    alt: 'Dark_IISER'
  },
  {
    src: Dark_Irving,
    alt: 'Dark_Irving'
  },
  {
    src: Dark_Linkgroup,
    alt: 'Dark_Linkgroup'
  },
  {
    src: Dark_Lockport,
    alt: 'Dark_Lockport'
  },
  {
    src: Dark_MES,
    alt: 'Dark_MES'
  },
  {
    src: Dark_MetropolitianU_HongKong,
    alt: 'Dark_MetropolitianU_HongKong'
  },
  {
    src: Dark_Milwaukee,
    alt: 'Dark_Milwaukee'
  },
  {
    src: Dark_Orangecountypublicschool,
    alt: 'Dark_Orangecountypublicschool'
  },
  {
    src: Dark_Outlier,
    alt: 'Dark_Outlier'
  },
  {
    src: Dark_Parul,
    alt: 'Dark_Parul'
  },
  {
    src: Dark_Thomas,
    alt: 'Dark_Thomas'
  },
  {
    src: Dark_UNSW,
    alt: 'Dark_UNSW'
  },
  {
    src: Dark_UniversityKansas,
    alt: 'fulcieri.png'
  },
  {
    src: ASK,
    alt: 'ASK'
  },
  {
    src: Bay,
    alt: 'Bay'
  },
  {
    src: BITS,
    alt: 'BITS'
  },
  {
    src: CityMontessoriSchool,
    alt: 'CityMontessoriSchool'
  },
  {
    src: Colegiul,
    alt: 'Colegiul'
  },
  {
    src: itext,
    alt: 'itext'
  },
  {
    src: JerseyCityPublicschool,
    alt: 'JerseyCityPublicschool'
  },
  {
    src: kodezi,
    alt: 'kodezi'
  },
  {
    src: lexington,
    alt: 'lexington'
  },
  {
    src: LiceoSSFP,
    alt: 'LiceoSSFP'
  },
  {
    src: sanAgustin,
    alt: 'sanAgustin'
  },
  {
    src: Sarasota,
    alt: 'Sarasota'
  },
  {
    src: Shri,
    alt: 'Shri'
  }
]

export const institutesForLight = [
  {
    src: Light_Academy,
    alt: 'Light_Academy'
  },
  {
    src: Light_Automatapi,
    alt: 'Light_Automatapi'
  },
  {
    src: Light_Chisum,
    alt: 'Light_Chisum'
  },
  {
    src: Light_CloudAngels,
    alt: 'Light_CloudAngels'
  },
  {
    src: Light_Empower,
    alt: 'Light_Empower'
  },
  {
    src: Light_Fort,
    alt: 'Light_Fort'
  },
  {
    src: Light_GVPCE,
    alt: 'Light_GVPCE'
  },
  {
    src: Light_GeekHunter,
    alt: 'hkschool.png'
  },
  {
    src: Light_IISER,
    alt: 'Light_IISER'
  },
  {
    src: Light_Irving,
    alt: 'Light_Irving'
  },
  {
    src: Light_Linkgroup,
    alt: 'Light_Linkgroup'
  },
  {
    src: Light_Lockport,
    alt: 'Light_Lockport'
  },
  {
    src: Light_MES,
    alt: 'Light_MES'
  },
  {
    src: Light_MetropolitianU_HongKong,
    alt: 'Light_MetropolitianU_HongKong'
  },
  {
    src: Light_Milwaukee,
    alt: 'Light_Milwaukee'
  },
  {
    src: Light_Orangecountypublicschool,
    alt: 'Light_Orangecountypublicschool'
  },
  {
    src: Light_Outlier,
    alt: 'lexington.png'
  },
  {
    src: Light_Parul,
    alt: 'Light_Parul'
  },
  {
    src: Light_Thomas,
    alt: 'Light_Thomas'
  },
  {
    src: Light_UNSW,
    alt: 'Light_UNSW'
  },
  {
    src: Light_UniversityKansas,
    alt: 'fulcieri.png'
  },
  {
    src: ASK,
    alt: 'ASK'
  },
  {
    src: Bay,
    alt: 'Bay'
  },
  {
    src: BITS,
    alt: 'BITS'
  },
  {
    src: CityMontessoriSchool,
    alt: 'CityMontessoriSchool'
  },
  {
    src: Colegiul,
    alt: 'Colegiul'
  },
  {
    src: itext,
    alt: 'itext'
  },
  {
    src: JerseyCityPublicschool,
    alt: 'JerseyCityPublicschool'
  },
  {
    src: kodezi,
    alt: 'kodezi'
  },
  {
    src: lexington,
    alt: 'lexington'
  },
  {
    src: LiceoSSFP,
    alt: 'LiceoSSFP'
  },
  {
    src: sanAgustin,
    alt: 'sanAgustin'
  },
  {
    src: Sarasota,
    alt: 'Sarasota'
  },
  {
    src: Shri,
    alt: 'Shri'
  }
]
