<script setup lang="ts">
import UseCaseBanner, { type IUseCaseBannerData } from '@/components/shared/UseCaseBanner.vue'
import Homepage_6 from '@/assets/images/svg/Homepage_6.svg'

const teachAndAssessSection: IUseCaseBannerData = {
  title: 'Execute code in your platform with our secure compiler APIs and Plugins',
  list: [
    'Powerful and secure REST/WebSocket APIs',
    'Integrate plugins with 3 lines of HTML code',
    'Integrate easily with LMSs, apps, blogs, websites etc'
  ],
  primaryButton: {
    text: 'Integrate',
    link: '/intergrate-online-ide-compiler-api-plugins'
  },
  textLeftImageRight: false,
  image: {
    src: Homepage_6,
    alt: 'animation svg'
  }
}
</script>

<template>
  <div class="section-primary">
    <UseCaseBanner :sectionData="teachAndAssessSection"> </UseCaseBanner>
  </div>
</template>
