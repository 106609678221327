<script setup lang="ts">
import { GURU_USER_STATUS } from '@/components/guru/enums/teach.enum'
import type IAssociation from '@/components/guru/interface/IAssociation'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import { useTeachStore } from '@/stores/teach.store'
// import { sortBy } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

const teachStore = useTeachStore()
const breadcrumbStore = useBreadcrumbStore()
const searchString = ref('')

const router = useRouter()
const associations = computed(() => {
  return teachStore.associations?.filter((association: IAssociation) => {
    return association.status == GURU_USER_STATUS.ACTIVE
  })
})
const filteredAssociations = computed(() => {
  if (searchString.value) {
    const lowerCaseSearch = searchString.value.toLowerCase()
    const filtered = associations.value?.filter((association: IAssociation) => {
      return (
        association.institute.name.toLowerCase().includes(lowerCaseSearch) ||
        association.institute.code.toLowerCase().includes(lowerCaseSearch) ||
        association.institute.description.toLowerCase().includes(lowerCaseSearch)
      )
    })

    if (filtered) {
      return filtered
    } else {
      return []
    }
  } else {
    return associations.value ? associations.value : []
  }
})

/**
 *
 */
// function checkAuthentication() {
//   if (!teachStore.guruInitialised) {
//     return
//   }

//   let location = '/dashboard/institute/' + route.params.insCode

//   if (!teachStore.isAccountOwner && !teachStore.isInstituteAdmin && !teachStore.isTeacher) {
//     router.push(location)
//   }
// }

/**
 *
 */
// function sortUsers() {
//   users.value = sortBy(users.value, (o) => {
//     return o.firstName + ' ' + o.lastName
//   })
// }

onMounted(async () => {
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
})

/**
 * @param emit the Searchstring emitted
 */
function handleSearchInput(emit: string) {
  searchString.value = emit
}

const breadcrumbLinks = computed(() => [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  },
  {
    href: '/dashboard/institution',
    title: 'Virtual Institutions'
  },
  {
    href: `/dashboard/institution/associations`,
    title: `My Associations`
  }
])

/**
 *
 * @param association the association clicked on
 */
async function handleViewAssociation(association: IAssociation) {
  router.push(`/dashboard/institution/${association.institute.code}`)
}
</script>

<template>
  <div class="h-full w-full">
    <DashboardCard
      title="My Associations"
      class="h-full w-full"
      :has-search-bar="true"
      @searchInput="handleSearchInput"
    >
      <div v-if="filteredAssociations.length > 0" class="mt-5 w-full">
        <table class="w-full table-auto border-separate border-spacing-y-4">
          <thead class="text-start text-sm">
            <tr>
              <th class="px-3 py-1 text-start">Name</th>
              <th class="px-3 py-1 text-start">Code</th>
              <th class="px-3 py-1 text-start">Description</th>
              <th class="px-3 py-1 text-start">Role(s)</th>
              <th class="px-3 py-1 text-start">Status</th>
            </tr>
          </thead>
          <tbody class="">
            <tr
              @click="handleViewAssociation(association)"
              role="button"
              v-for="association in filteredAssociations"
              :key="association.institute.code"
              class="section-secondary"
            >
              <td class="rounded-s-lg px-3 py-4 text-sm">{{ association.institute.name }}</td>
              <td class="px-3 py-4 text-sm">
                {{ association.institute.code }}
              </td>
              <td class="px-3 py-4 text-sm">
                {{ association.institute.description.slice(0, 40) }}
                <span v-if="association.institute.description.length > 40">...</span>
              </td>
              <td class="px-3 py-4 text-sm">
                <span v-for="(role, index) in association.associationTypes" :key="index">
                  {{ role }}<br
                /></span>
              </td>
              <td class="rounded-e-lg px-3 py-4 text-sm">{{ association.status }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </DashboardCard>
  </div>
</template>
