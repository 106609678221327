import { SUBSCRIPTION_PRODUCT } from '@/components/shared/subscription/enum/subscription.enum'

export const platformPricingCardsData = [
  {
    tierName: 'Free',
    pricingDollar: '$0',
    pricingPeriod: 'Month',
    description: 'Unlock the basics',
    idePluginIncludes: ['Standard IDE', 'Unlimited standard Plugins'],
    teachAndAssessIncludes: [
      'Create a Virtual Institute with up to 25 students',
      'Manage users, run 1 assignment at a time and automate grading',
      'Questions limited to 10',
      'Automated email communications'
    ],
    freeButton: {
      buttonText: 'Start for Free',
      product: SUBSCRIPTION_PRODUCT.TEACH
    }
  },
  {
    tierName: 'Team',
    pricingDollar: '$100',
    pricingPeriod: 'Month',
    description: "Power your team's success",
    idePluginIncludes: [
      'Standard IDE',
      'Unlimited customizable WYSIWYG Plugins',
      'File upload feature',
      'Plugin to host page communication'
    ],
    teachAndAssessIncludes: [
      'All free plan features',
      'Unlimited students',
      'Create and run unlimited assignments',
      'No limits on questions',
      'Bulk import students'
    ],
    isMostPopular: true,
    proButton: {
      buttonText: 'Start Now',
      product: SUBSCRIPTION_PRODUCT.TEACH
    }
  },
  {
    tierName: 'Business',
    pricingDollar: 'Custom',
    description: 'Bespoke solutions tailored to your needs',
    customIncludes: [
      'All team plan features',
      'Tailor-made solutions developed by our expert developers',
      'White-labeled Plugins with the freedom of commercial use',
      'Seamless integration with your existing systems, databases, or tools',
      'Dedicated support',
      'Tailored security measures and protocols'
    ],

    isCustom: true,
    contactButton: {
      buttonText: 'Contact',
      link: `contact-us`,
      product: SUBSCRIPTION_PRODUCT.TEACH
    }
  }
]
