<script setup lang="ts">
import { computed } from 'vue'
import { IDECONSTANT } from '@/utils/ide'
import { useIdeStore } from '@/stores/ide.store'

const ideStore = useIdeStore()

const versions = computed(() => {
  return ideStore.isVersions
})
/**
 * Checks if the version is selected
 * @param index The version index to check
 * @returns True if the version is selected
 */
const isVersionSelected = (index: number) => {
  return ideStore.versionIndex === index
}
/**
 * Sets the version
 * @param e The event
 */
const setVersion = (e: Event) => {
  ideStore.setVersionIndex(parseInt((e.target as HTMLSelectElement).value))
  ideStore.setCodeUpdated(true)
}
</script>

<template>
  <div class="flex w-full flex-col gap-2">
    <select
      id="versionSelect"
      class="section-primary p-small block w-fit rounded-md px-4 py-2 print:border"
      @change="setVersion($event)"
    >
      <option
        v-for="(version, index) in versions"
        :key="index"
        :selected="isVersionSelected(index)"
        :value="index"
      >
        {{ version }}
      </option>
    </select>
    <div
      :id="IDECONSTANT.TERMIANL_EDITOR"
      class="p-xsmall h-96 w-full overflow-auto rounded-lg p-5"
    />
  </div>
</template>

<style>
@import '@/assets/styles/jquery-terminal.css';
</style>
