<script setup lang="ts">
import UseCaseBanner, { type IUseCaseBannerData } from '@/components/shared/UseCaseBanner.vue'
import type { ICountAndFeature } from '@/components/shared/CountAndFeature.vue'
import CountAndFeature from '@/components/shared/CountAndFeature.vue'
import type { IActionButton } from '@/components/shared/PricingCard.vue'
import { SUBSCRIPTION_PRODUCT } from '@/components/shared/subscription/enum/subscription.enum'
import Button_login_then_redirect from '@/components/shared/subscription/Button_login_then_redirect.vue'
import Solution_library from '@/assets/images/svg/Solution_library.svg'

const sectionData: IUseCaseBannerData = {
  largeTitle:
    'Demonstrate your software library’s power and reduce your sales cycle by <span class="text-text-brand">50%</span>.',
  textLeftImageRight: true,
  list: [
    'Effortless integration with compiler APIs and plugins',
    'Engaging learning experience for your users',
    'Secure sandbox environment'
  ],
  image: {
    src: Solution_library,
    alt: 'Solution library animation'
  }
}

const countAndFeatures: ICountAndFeature[] = [
  {
    count: '1 Million',
    description: 'Monthly Users'
  },
  {
    count: '76+',
    description: 'Languages Supported'
  }
]

const button: IActionButton = {
  buttonText: 'Subscribe Now',
  link: `/dashboard/api`,
  product: SUBSCRIPTION_PRODUCT.COMPILER_API
}
</script>

<template>
  <UseCaseBanner :sectionData="sectionData">
    <div class="">
      <Button_login_then_redirect class="mt-6" :button-data="button" />
      <div class="mt-6 flex gap-5">
        <div v-for="(item, idx) in countAndFeatures" :key="idx">
          <CountAndFeature :countAndFeature="item" />
        </div>
      </div>
    </div>
  </UseCaseBanner>
</template>
