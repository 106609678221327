<script setup lang="ts">
import { computed, ref } from 'vue'
import { useIdeStore } from '@/stores/ide.store'
import editorService from '@/services/ide/editor.service'
import projectTreeService from '@/services/ide/projectTree.service'
import utilModelsService from '@/services/util.models.service'

import { SYNC_ACTIONS } from '@/utils/ide'
import { IDEVIEWMODELS } from '@/utils/models'
import { IDECONSTANT } from '@/utils/ide'

import BaseInput from '@/components/forms/BaseInput.vue'
import { useField, useForm } from 'vee-validate'
import { string } from 'yup'

const props = defineProps({
  treeData: {
    type: Object
  },
  treeHome: {
    type: [Object, String]
  },
  siblings: {
    type: Array
  }
})
const { errors, handleSubmit, resetForm } = useForm()

const { value: rename, errorMessage: renameError } = useField(
  'rename',
  string()
    .required()
    .max(25)
    .matches(/^[a-zA-Z0-9]+[a-zA-Z0-9._-]+$/, {
      message: 'Invalid name'
    })
    .test('uniqueName', 'Duplicate Filename', (value: string) => {
      if (props.siblings) {
        const siblings = props.siblings.map((sibling: any) => sibling.name)
        return !siblings.includes(value)
      }
      return true
    })
)

const ideStore = useIdeStore()

const isDragging = ref<boolean>(false)
const isOpen = ref<boolean>(true)
const newFile = ref<boolean>(true)
const tempName = ref<string | null>(null)
const editModeStarted = ref<boolean>(false)
const name = ref<string | null>(null)

const uploadInput = ref<HTMLElement | null>(null)

const itemName = computed(() => {
  return props.treeData?.name || ''
})
const isActive = computed(() => {
  return props.treeData === ideStore.activeItem || false
})
const itemMarkedForDeletion = computed(() => {
  return props.treeData?.markedForDeletion || false
})
const isFolder = computed(() => {
  if (props.treeData?.children) {
    return true
  }
  return false
})
const isHome = computed(() => {
  let parent = '/'
  if (props.treeData?.parent && props.treeData?.parent === '/') {
    parent = props.treeData?.parent + props.treeData?.name
  } else if (props.treeData?.parent) {
    parent = props.treeData?.parent + '/' + props.treeData?.name
  }
  return parent === props.treeHome
})
const path = computed(() => {
  if (props.treeData?.parent && props.treeData?.parent === '/') {
    return props.treeData?.parent + props.treeData?.name
  } else if (props.treeData?.parent) {
    return props.treeData?.parent + '/' + props.treeData?.name
  } else {
    return '/'
  }
})
const isPublicLib = computed(() => {
  return props.treeData?.isPublicLib || false
})
const libraries = computed(() => {
  return ideStore.javaLibraries || []
})
const home = computed(() => {
  return props.treeHome || {}
})
const isEditingInProcess = computed(() => {
  return ideStore.editingInProcess === true
})
const isEditMode = computed(() => {
  return props.treeData?.editMode || false
})
const isHomeCandidate = computed(() => {
  return (!isFolder.value && props.treeData?.parent === '/' && !isHome.value) || false
})
const isStatic = computed(() => {
  return props.treeData?.isStatic || false
})
const isDownloadable = computed(() => {
  if (props.treeData?.children?.length > 0 || !isFolder.value) {
    return true
  } else {
    return false
  }
})
const getUrl = computed(() => {
  return `/api/projectSync/downloadItem?projectKey=${encodeURI(
    ideStore.projectKey as string
  )}&path=${encodeURI(props.treeData?.parent)}&name=${encodeURI(props.treeData?.name)}&type=src`
})
const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0
})
// utility functions
/**
 * check if the item is home
 * @param item tree item
 * @returns true if home
 */
const checkIsHome = (item: any) => {
  let parent = '/'
  if (item.parent && item.parent === '/') {
    parent = item.parent + item.name
  } else if (item.parent) {
    parent = item.parent + '/' + item.name
  }
  return parent === props.treeHome
}
/**
 * get the parent name
 * @param item tree item
 * @returns parent name
 */
const getParentName = (item: any) => {
  let parent = '/'
  if (item.parent && item.parent === '/') {
    parent = item.parent + item.name
  } else if (item.parent) {
    parent = item.parent + '/' + item.name
  }
  return parent
}

/**
 * sort the children
 */
const sortChildren = () => {
  const item = props.treeData || {}
  item.children?.sort(projectTreeService.sortComparator())
}
// button actions
/**
 * when item is dropped on another item
 * @param e event
 */
const dropAction = async (e: any) => {
  isDragging.value = false

  e.preventDefault()
  e.stopPropagation()

  let closestLi = e.target.closest('li')
  let toPath = closestLi.dataset['path']
  let fromPath = e.dataTransfer.getData('dragItemPath')
  let itemName = e.dataTransfer.getData('dragItemName')
  let isItemFolder = e.dataTransfer.getData('dragItemFolder')
  if (toPath === fromPath) {
    return
  }

  let fromPathWithItemName = null

  if (isItemFolder === 'true') {
    if (fromPath === '/') {
      fromPathWithItemName = '/' + itemName
    } else {
      fromPathWithItemName = fromPath + '/' + itemName
    }
  }

  if (
    closestLi.dataset['folder'] === 'true' &&
    !(isItemFolder === 'true' && toPath.startsWith(fromPathWithItemName))
  ) {
    const fromParent = projectTreeService.findEdge(fromPath)
    const toParent = projectTreeService.findEdge(toPath)

    if (toParent.isPublicLib) {
      return
    }

    let duplicateFound = false
    let duplicateItem: any | null = null
    let item: any | null = null

    for (const fromChild of fromParent.children) {
      if (fromChild.name === itemName) {
        item = fromChild
        break
      }
    }
    if (checkIsHome(item)) {
      utilModelsService.alertTimeOut(
        "Start/Main file can't be moved",
        'Compiler looks for main method in this folder to execute.'
      )
      return
    }

    if (item.isStatic === true) {
      utilModelsService.alertTimeOut("File can't be moved", item.staticMessage)
      return
    }

    for (const toChild of toParent.children) {
      if (toChild.name === itemName) {
        duplicateItem = toChild
        duplicateFound = true
        break
      }
    }

    if (duplicateFound) {
      await utilModelsService
        .confirmPromise(
          'File with same name already exists in this folder',
          'Do you want to replace it?'
        )
        .then(() => {
          item.isDirty = true
          if (!item.dirtyAction) {
            item.dirtyActions = []
          }
          toParent.children.splice(toParent.children.indexOf(duplicateItem), 1)
          item.dirtyActions.push({
            action: SYNC_ACTIONS.ITEM_MOVED,
            oldPath: fromPath,
            replacedItem: duplicateItem
          })
        })
        .catch(() => {})
    } else {
      item.isDirty = true
      if (!item.dirtyAction) {
        item.dirtyActions = []
      }
      item.dirtyActions.push({ action: SYNC_ACTIONS.ITEM_MOVED, oldPath: fromPath })
    }

    fromParent.children.splice(fromParent.children.indexOf(item), 1)
    item.parent = toPath
    toParent.children.push(item)

    if (item.children) {
      projectTreeService.updateChildrensParent(item)
    }

    projectTreeService.sortPath(toPath)
    projectTreeService.sync()
  }
}
/**
 * when item is dragged over another item
 * @param e event
 * @returns true
 */
const dragOverAction = (e: any) => {
  e.preventDefault()
  return true
}
/**
 * when item is dragged
 * @param e event
 */
const dragStartAction = (e: any) => {
  const item = props.treeData || {}
  isDragging.value = true

  e.dataTransfer.setData('dragItemName', item.name)
  e.dataTransfer.setData('dragItemPath', item.parent)
  e.dataTransfer.setData('dragItemFolder', isFolder)

  e.stopPropagation()
}
/**
 * forcus the input
 * @param count count
 */
const postGetForcusInput = async (count: number = 0) => {
  const input = document.getElementById('editInput')
  if (!input) {
    if (count < 20) {
      await new Promise((resolve) => setTimeout(resolve, 100))
      postGetForcusInput(count + 1)
    }
  } else {
    input.focus()
  }
}
/**
 * forcus the input
 */
const forcusInput = async () => {
  await postGetForcusInput()
}
/**
 * when item is clicked
 */
const toggle = () => {
  if (ideStore.editingInProcess) return
  const item = props.treeData || {}
  if (isFolder.value) {
    isOpen.value = !isOpen.value
    return
  }

  const editableTypes = ideStore.ideMeta?.editableTypes || []
  let editable: boolean = false

  for (const type of editableTypes) {
    if (item.name.endsWith(type)) {
      editable = true
      break
    }
  }

  if (!editable) {
    utilModelsService.alertTimeOut(
      `Only ${editableTypes} file types can be edited`,
      'For other file types, download, edit locally and upload.'
    )
    return
  }

  projectTreeService.setActiveItem(item)
  editorService.heightChangeFunction(IDECONSTANT.CODE_EDITOR)
}
/**
 * make the file as home
 */
const makeHome = () => {
  const item = props.treeData || {}
  projectTreeService.makeHome(item?.name)
}
/**
 * add a new file
 */
const addFile = () => {
  ideStore.setEditingInProgress(true)
  const item = props.treeData || {}
  let parentName = getParentName(item)
  item?.children.push({
    name: '',
    editMode: true,
    parent: parentName,
    markedForDeletion: false
  })
  isOpen.value = true
  forcusInput()
}
/**
 * add a new folder
 */
const addFolder = () => {
  ideStore.setEditingInProgress(true)
  const item = props.treeData || {}
  let parentName = getParentName(item)
  item?.children.push({
    name: '',
    editMode: true,
    parent: parentName,
    markedForDeletion: false,
    children: []
  })
  forcusInput()
}
/**
 * delete the item
 */
const deleteItem = async () => {
  const item = props.treeData || {}
  if (ideStore.project.home === '/' + item.name) return

  if (item.newFile) {
    const parentEdge = projectTreeService.findEdge(item.parent)
    parentEdge.children.splice(parentEdge.children.indexOf(item), 1)
    return
  }

  await utilModelsService
    .confirmPromise(
      `
  Are you sure want to delete this ${item.children ? 'folder' : 'file'}?
  `
    )
    .then(() => {
      item.isDirty = true
      item.markedForDeletion = true
      if (!item.dirtyActions) {
        item.dirtyActions = []
      }

      item.dirtyActions.push({ action: SYNC_ACTIONS.DELETE })

      projectTreeService.sync()
    })
    .catch(() => {})
}
/**
 * rename the item
 */
const renameItem = () => {
  ideStore.setEditingInProgress(true)
  const item = props.treeData || {}

  newFile.value = false
  item.editMode = true
  editModeStarted.value = true
  name.value = item.name
  tempName.value = item.name

  forcusInput()
}
/**
 * change the file name
 */
const changeFileName = handleSubmit(async (values) => {
  const item = props.treeData || {}

  if (isHome.value) {
    item.name = values.rename
    makeHome()
  } else {
    item.name = values.rename
  }
  item.editMode = false
  sortChildren()

  // Sync Actions
  item.isDirty = true
  if (!item.dirtyAction) {
    item.dirtyActions = []
  }
  if (isFolder.value) projectTreeService.updateChildrensParent(item)

  if (newFile.value) {
    item.dirtyActions.push({
      action: SYNC_ACTIONS.NEW_ITEM
    })
    newFile.value = false
  } else {
    item.dirtyActions.push({
      action: SYNC_ACTIONS.RENAME,
      oldName: tempName.value
    })
  }
  projectTreeService.sync()
  resetForm()
  ideStore.setEditingInProgress(false)
})
/**
 * close the edit mode
 */
const closeEditMode = () => {
  const item = props.treeData || {}
  editModeStarted.value = false
  item.editMode = false
  if (newFile.value) {
    item.newFile = true
    deleteItem()
  } else {
    item.name = tempName.value
  }
  resetForm()
  ideStore.setEditingInProgress(false)
}
/**
 * Handles the upload click
 */
const handleUploadClick = async () => {
  await new Promise((resolve) => setTimeout(resolve, 10))
  uploadInput?.value?.click()
}
/**
 * upload a file
 * @param event event
 */
const tryUploadFile = (event: Event) => {
  const item = props.treeData || {}
  //todo robot check
  if (event.target) {
    const target = event.target as HTMLInputElement
    projectTreeService.uploadFile(target, item)
  }
}
</script>

<template>
  <li
    :data-item="itemName"
    v-if="!itemMarkedForDeletion"
    draggable="true"
    @drop="dropAction"
    @dragover="dragOverAction"
    droppable="true"
    @dragstart="dragStartAction"
    :data-folder="isFolder"
    :data-path="path"
    class="list-none pl-3"
  >
    <input
      v-show="false"
      ref="uploadInput"
      class="hidden"
      type="file"
      name="file"
      @change="tryUploadFile($event)"
    />
    <div :class="['flex', 'gap-2', { 'link-secondary': isActive }]">
      <FontAwesomeIcon v-if="isEditingInProcess" icon="ellipsis" class="h-4 w-4 text-gray-500" />
      <div v-else class="hs-dropdown relative">
        <button id="hs-dropdown-default" type="button" class="hs-dropdown-toggle">
          <FontAwesomeIcon icon="fa-ellipsis" class="h-4 w-4" />
        </button>
        <div
          class="hs-dropdown-menu section-primary z-10 hidden w-max min-w-max rounded-lg p-2 opacity-0 shadow-md transition-[opacity,margin] duration-[0.1ms] hs-dropdown-open:opacity-100"
          aria-labelledby="hs-dropdown-default"
        >
          <button
            v-if="isHomeCandidate"
            @click="makeHome"
            class="link-primary flex w-full gap-x-2 rounded-md px-3 py-2"
          >
            <FontAwesomeIcon icon="fa-home" class="h-4 w-4" />
            <span class="p-xsmall">Make it as Start File</span>
          </button>
          <button
            v-if="isFolder && !isPublicLib"
            @click="addFile"
            class="link-primary flex w-full gap-x-2 rounded-md px-3 py-2"
          >
            <FontAwesomeIcon icon="fa-file-alt" class="h-4 w-4" />
            <span class="p-xsmall">New File</span>
          </button>
          <button
            v-if="isPublicLib"
            :data-hs-overlay="`#${IDEVIEWMODELS.LIBRARIES}`"
            class="link-primary flex w-full gap-x-2 rounded-md px-3 py-2"
          >
            <FontAwesomeIcon icon="fa-cube" class="h-4 w-4" />
            <span class="p-xsmall">Manage Libs</span>
          </button>
          <button
            v-if="isFolder && !isPublicLib"
            @click="addFolder"
            class="link-primary flex w-full gap-x-2 rounded-md px-3 py-2"
          >
            <FontAwesomeIcon icon="fa-folder-plus" class="h-4 w-4" />
            <span class="p-xsmall">New Folder</span>
          </button>
          <button
            v-if="!isStatic && !isPublicLib"
            @click="deleteItem"
            class="link-primary flex w-full gap-x-2 rounded-md px-3 py-2"
          >
            <FontAwesomeIcon icon="fa-trash" class="h-4 w-4" />
            <span class="p-xsmall">Delete</span>
          </button>
          <button
            v-if="!isStatic && !isPublicLib"
            @click="renameItem"
            class="link-primary flex w-full gap-x-2 rounded-md px-3 py-2"
          >
            <FontAwesomeIcon icon="fa-pen" class="h-4 w-4" />
            <span class="p-xsmall">Rename</span>
          </button>
          <button
            v-if="isFolder && !isPublicLib"
            @click="handleUploadClick"
            class="link-primary flex w-full gap-x-2 rounded-md px-3 py-2"
          >
            <FontAwesomeIcon icon="fa-upload" class="h-4 w-4" />
            <span class="p-xsmall">Upload File</span>
          </button>
          <a
            v-if="isDownloadable"
            :href="getUrl"
            target="_blank"
            class="link-primary flex w-full gap-x-2 rounded-md px-3 py-2"
          >
            <FontAwesomeIcon icon="fa-download" class="h-4 w-4" />
            <span class="p-xsmall">Download</span>
          </a>
        </div>
      </div>

      <div @click="toggle" class="flex items-center gap-2">
        <span v-if="isFolder">
          <FontAwesomeIcon v-show="isOpen" icon="folder-open" class="h-4 w-4" />
          <FontAwesomeIcon v-show="!isOpen" icon="folder-closed" class="h-4 w-4" />
        </span>
        <span v-else>
          <FontAwesomeIcon v-show="isHome" icon="home" class="h-4 w-4 text-link-secondary" />
          <FontAwesomeIcon v-show="!isHome" icon="file-alt" class="h-4 w-4" />
        </span>
        <span v-if="!isEditMode" class="p-small">{{ itemName }}</span>
        <div v-if="isEditMode">
          <form @submit.prevent="changeFileName" class="flex w-full items-center gap-1">
            <BaseInput
              id="editInput"
              :showLable="false"
              :error="renameError"
              v-model="rename"
              class="h-6 rounded-md py-0"
              :isContactus="true"
              @key.esc="closeEditMode"
            />
            <button :disabled="!canSubmit" type="submit">
              <FontAwesomeIcon
                icon="fa-circle-check"
                :class="[
                  'h-4',
                  'w-4',
                  'success',
                  {
                    'text-gray-500': !canSubmit
                  }
                ]"
              />
            </button>
            <button @click="closeEditMode">
              <FontAwesomeIcon icon="fa-circle-xmark" class="error h-4 w-4" />
            </button>
          </form>
        </div>
      </div>
    </div>
    <div v-if="isFolder">
      <ul v-if="!isPublicLib" v-show="isOpen">
        <TreeItemComp
          v-for="(child, index) in props.treeData?.children"
          :key="index"
          :treeData="child"
          :treeHome="home"
          :siblings="props.treeData?.children"
        />
      </ul>
      <ul v-if="isPublicLib" v-show="isOpen">
        <li v-for="(lib, index) in libraries" :key="index" class="list-none pl-9">
          <div class="flex gap-2">
            <FontAwesomeIcon icon="fa-cube" class="h-4 w-4" />
            <p class="p-small">{{ lib }}</p>
          </div>
        </li>
      </ul>
    </div>
  </li>
</template>
