import loadScriptInBody from '@/services/loadScriptInBody.service'

import { delay } from 'lodash'
import { ref } from 'vue'

const isCardAlreadyMounted = ref(false)

/**
 * Update Credit Details
 * @param stripeKey any
 * @param stripCard any
 * @param stripe any
 * @param completed boolean
 * @param errorMessage any
 */
export const showUpdateCreditCard = (
  stripeKey: any,
  stripCard: any,
  stripe: any,
  completed: boolean,
  errorMessage: any
) => {
  if (isCardAlreadyMounted.value) {
    return
  }

  isCardAlreadyMounted.value = true
  completed = false

  /**
   *
   * @param count number
   */
  const render = (count: number) => {
    if (count > 12) {
      errorMessage.value =
        'Unable to update payment deatails. Please try again or contact JDoodle support.'
      return
    }

    // @ts-ignore
    // eslint-disable-next-line
    if (!window.Stripe) {
      delay(render, 1000, count + 1)

      return
    }

    // @ts-ignore
    // eslint-disable-next-line
    stripe.value = window.Stripe(stripeKey)
    const elements = stripe.value.elements()
    const style = {
      base: {
        fontSize: '16px',
        color: '#32325d'
      }
    }

    stripCard.value = elements.create('card', { style: style })
    stripCard.value.mount('#apiUpdatePaymentMethod-card-element')

    errorMessage.value = ''
    isCardAlreadyMounted.value = false

    stripCard.value.addEventListener('change', (event: any) => {
      errorMessage.value = event.error?.message || ''
    })
  }

  errorMessage.value = 'Loading payment fields, please wait...'

  loadScriptInBody
    .loadScriptInBody('https://js.stripe.com/v3/', null)
    .then(() => {
      delay(render, 2000, 1)
    })
    .catch((e: any) => {
      console.error(e)
    })

  delay(render, 2000, 1)
}
