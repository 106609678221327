<script setup lang="ts">
import ModelLayout from '@/layouts/ModelLayout.vue'
import { TEACHMODALHS } from '@/utils/models'
import BaseInput from '@/components/forms/BaseInput.vue'
import BaseTextbox from '@/components/forms/BaseTextbox.vue'
import { useField, useForm } from 'vee-validate'
import { string } from 'yup'
import { type PropType, computed, watch, ref } from 'vue'
import { HttpStatusCode } from 'axios'
import { STUDENT_GROUP_ACTIONS } from '@/components/guru/enums/teach.enum'
import router from '@/router'
import { type IStudentGroup } from '@/components/guru/interface/IStudentGroup'
import { type IStudentGroup_Request } from '@/components/guru/interface/requests.interface'
import { useRoute } from 'vue-router'
import { addStudentGroup } from '@/services/teach.service'

const props = defineProps({
  prefilledStudentGroup: {
    type: Object as PropType<IStudentGroup>,
    require: false
  }
})

const route = useRoute()

//Prop related
const currentStudentGroup = ref(props.prefilledStudentGroup)
const currentMode = computed(() =>
  props.prefilledStudentGroup != undefined ? STUDENT_GROUP_ACTIONS.EDIT : STUDENT_GROUP_ACTIONS.ADD
)

const instituteCode = route.params.instituteCode as string

watch(
  () => props.prefilledStudentGroup,
  (prefillObj) => {
    if (prefillObj !== undefined) {
      currentStudentGroup.value = prefillObj
      name.value = currentStudentGroup.value?.name
      description.value = currentStudentGroup.value?.description
    }
  }
)

// Form-related
const { errors, handleSubmit } = useForm()

const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0
})

const onSubmit = handleSubmit(async () => {
  const reqObject: IStudentGroup_Request = {
    instituteCode: instituteCode,
    name: name.value as string,
    description: description.value as string,
    insStudentGroupId: currentStudentGroup.value?.insStudentGroupId,
    mode: getMode(currentMode.value)
  }

  const res = await addStudentGroup(reqObject)

  if (res == HttpStatusCode.Ok) {
    router.go(0)
  }
})

const { value: name, errorMessage: nameError } = useField('name', string().required().min(2))

const { value: description } = useField('description', string().required().min(2))

const nameInput = {
  label: 'Student Group Name',
  placeholder: 'E.g. Class 1A'
}

const descriptionInput = {
  label: 'Description',
  placeholder: 'Short description about student group.'
}

/**
 * @returns  modal title
 */
function getModalTitle() {
  if (currentMode.value === STUDENT_GROUP_ACTIONS.ADD) {
    return 'Add Student Group'
  } else if (currentMode.value === STUDENT_GROUP_ACTIONS.EDIT) {
    return 'Edit Student Group'
  }
  return ''
}

/**
 * @param changeToMode enum of mode to change
 * @returns Enum
 */
function getMode(changeToMode: STUDENT_GROUP_ACTIONS | undefined) {
  if (changeToMode === STUDENT_GROUP_ACTIONS.EDIT) {
    return STUDENT_GROUP_ACTIONS.EDIT
  }

  if (changeToMode === STUDENT_GROUP_ACTIONS.ADD) {
    return STUDENT_GROUP_ACTIONS.ADD
  }
}
</script>

<template>
  <ModelLayout :title="getModalTitle()" :hs="TEACHMODALHS.ADD_STUDENT_GROUP">
    <!-- form -->
    <form @submit.prevent="onSubmit">
      <BaseInput
        :label="nameInput.label"
        :placeholder="nameInput.placeholder"
        v-model="name"
        :error="nameError"
        :isLightGray="false"
      />

      <BaseTextbox
        :label="descriptionInput.label"
        :placeholder="descriptionInput.placeholder"
        v-model="description"
        :isLightGray="false"
      />

      <button
        :disabled="!canSubmit"
        type="submit"
        v-if="currentMode === STUDENT_GROUP_ACTIONS.ADD"
        class="btn-primary btn-rounded-md"
      >
        Create Student Group
      </button>
      <button
        v-if="currentMode === STUDENT_GROUP_ACTIONS.EDIT"
        type="submit"
        class="btn-primary btn-rounded-md"
      >
        Save Changes
      </button>
    </form>
  </ModelLayout>
</template>
