<script setup lang="ts">
import SixItemsPanel, { type ISixItems } from '@/components/shared/SixItemsPanel.vue'
import UseCaseBanner, { type IUseCaseBannerData } from '@/components/shared/UseCaseBanner.vue'
import type { IActionButton } from '@/components/shared/PricingCard.vue'
import { SUBSCRIPTION_PRODUCT } from '@/components/shared/subscription/enum/subscription.enum'
import Button_login_then_redirect from '@/components/shared/subscription/Button_login_then_redirect.vue'
const sectionProp: IUseCaseBannerData = {
  orangeTextAboveTitle: 'Compiler plugin Features',
  title: 'Allow users to experience your products on your website',
  description:
    'Effectively showcase your products, shorten your sales cycles and make your documentation more interactive.',
  textLeftImageRight: true,
  image: {
    src: '',
    alt: ''
  }
}

const featuresGridData: ISixItems[] = [
  {
    icon: 'fa-folder-closed',
    heading: 'Seamless Integration',
    body: "Integrate JDoodle's Compiler APIs and Plugins effortlessly, saving time and technical headaches while offering a powerful coding environment."
  },
  {
    icon: 'fa-book',
    heading: 'Interactive Documentation',
    body: "Enrich your documentation with interactive coding exercises, engaging users and improving their understanding of your library's capabilities."
  },
  {
    icon: 'fa-shield-halved',
    heading: 'Secure Code Execution',
    body: 'Provide a safe sandboxed environment for users to experiment with code, ensuring security and peace of mind.'
  },
  {
    icon: 'fa-message',
    heading: 'Multi-language support',
    body: 'Our Compiler APIs and plugins support 76 multiple programming languages, catering to diverse user needs and preferences.'
  },
  {
    icon: 'fa-comment',
    heading: 'Real-time Communication',
    body: 'Enable real-time communication and interaction with users through our REST and  WebSocket APIs, fostering an immersive coding experience.'
  },
  {
    icon: 'fa-circle-nodes',
    heading: 'Simplified Request and Output',
    body: 'Enjoy a user-friendly experience with simplified coding requests and structured output solutions for easy comprehension.'
  }
]

const button: IActionButton = {
  buttonText: 'Compiler Plugin',
  link: `/dashboard/api`,
  product: SUBSCRIPTION_PRODUCT.COMPILER_API
}
</script>

<template>
  <div class="py-8 md:py-14">
    <UseCaseBanner :sectionData="sectionProp" :removePadding="true" class="py-10">
      <Button_login_then_redirect class="" :button-data="button" />
    </UseCaseBanner>
    <SixItemsPanel :sixItems="featuresGridData" />
  </div>
</template>
