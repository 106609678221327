declare global {
  interface Window {
    pym: any
  }
}

import editorService from '@/services/ide/editor.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import loadScriptInBody from '@/services/loadScriptInBody.service'
import { useIdeStore } from '@/stores/ide.store'
import { IDECONSTANT } from '@/utils/ide'
import { META, type IMeta } from '@/utils/meta'
import { compact, isNumber } from 'lodash'

import axios from 'axios'

export interface IShareIndexRequest {
  shareId: string
  isInstant: boolean
}
/**
 * on change output editor
 */
const onChangeOutputEditor = () => {
  useIdeStore()
    .outputEditor.getSession()
    .on('change', async () => {
      await new Promise((resolve) => setTimeout(resolve, 100))
      useIdeStore().pymChild.sendHeight()
    })
}
/**
 * On keyup code editor
 */
const onKeyupCodeEditor = () => {
  editorService
    .getEditor(IDECONSTANT.CODE_EDITOR)
    .textInput.getElement()
    .addEventListener('keyup', async () => {
      await new Promise((resolve) => setTimeout(resolve, 100))
      useIdeStore().pymChild.sendHeight()
    })
}
/**
 * Set the version index
 */
const setVersionIndex = () => {
  if (useIdeStore().project.versionIndex || isNumber(useIdeStore().project.versionIndex)) {
    useIdeStore().setVersionIndex(parseInt(useIdeStore().project.versionIndex))
  } else {
    // @ts-ignore-next-line
    const versionIndex = useIdeStore().ideMeta?.versions?.length - 1 || 0
    useIdeStore().setVersionIndex(versionIndex)
  }
}

/**
 * Post action for the embed share blockly
 * @param data - The shared api response data
 * @param count - The count
 */
const embedSharePostActionBlockly = async (data: any, count: number = 0) => {
  if (!window.Blockly || !useIdeStore().isBlocklyWorkspace) {
    if (count > 10) {
      useIdeStore().setShareNotFound(true)
      useIdeStore().setShareNotFoundHttpError('Unable to load the IDE. Please try again later.')
    } else {
      await new Promise((resolve) => setTimeout(resolve, 600))
      embedSharePostActionBlockly(data, count + 1)
    }
  } else {
    const project = data.project
    useIdeStore().setProject(project)
    blocklyService.openBlockly(project.script)
  }
}
/**
 * Post action for the embed share
 * @param data - The shared api response data
 * @param count - The count
 */
const embedSharePostAction = async (data: any, count: number = 0) => {
  if (!useIdeStore().isWindowAce) {
    if (count > 10) {
      useIdeStore().setShareNotFound(true)
      useIdeStore().setShareNotFoundHttpError('Unable to load the IDE. Please try again later.')
    } else {
      await new Promise((resolve) => setTimeout(resolve, 600))
      embedSharePostAction(data, count + 1)
    }
  } else {
    const project = data.project
    useIdeStore().setPymChild(new window.pym.Child())
    useIdeStore().setProject(project)
    if (useIdeStore().isBlockly) {
      blocklyService.loadBlockly()
      embedSharePostActionBlockly(data)
    } else {
      editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, project.script)
    }
    onKeyupCodeEditor()
    onChangeOutputEditor()
    setVersionIndex()
    if (useIdeStore().project?.libraries)
      useIdeStore().javaLibraries = compact(useIdeStore().project.libraries.split(' '))
    await new Promise((resolve) => setTimeout(resolve, 100))
    window.pym.Child({ polling: 500 })
    useIdeStore().pymChild.sendHeight()
  }
}
/**
 * Initialize the embed share
 * @param meta - The meta
 * @param shareId - The share id
 * @param set_args - The set args
 */
const initEmbedShare = async (meta: IMeta, shareId: string, set_args: string | null = null) => {
  useIdeStore().args = set_args
  useIdeStore().setSharedId(shareId)
  const requestData: IShareIndexRequest = {
    shareId: shareId as string,
    isInstant: meta?.isInstantShare as boolean
  }
  await axios
    .post('/api/doodle/shareIndex', requestData)
    .then(
      async (response: {
        data: {
          project: {
            language: string
            isMultiFile: boolean
          }
        }
      }) => {
        useIdeStore().setIdeMeta(META[response.data.project.language])
        await new Promise((resolve) => setTimeout(resolve, 500))
        editorService.splitAndInit()
        await new Promise((resolve) => setTimeout(resolve, 600))
        embedSharePostAction(response.data)
      }
    )
    .catch((error: { response: { data: { message: string } } }) => {
      useIdeStore().setShareNotFound(true)
      useIdeStore().setShareNotFoundHttpError(
        error.response.data.message ||
          'Something went wrong. Unable to load the shared project. Please try again later.'
      )
    })
}
/**
 * inject the pym script
 */
const initOnRouterChange = () => {
  loadScriptInBody.loadScriptInBody('/assets/jdoodle-pym.min.js')
}
export default { initOnRouterChange, initEmbedShare }
