<script setup lang="ts">
import { computed } from 'vue'
import { TYPES } from '@/utils/customPlugin'
import { languagesItems } from '@/utils/sharedData/languages'
import configureService from '@/services/ide/plugin/configure.service'
import { usePluginStore } from '@/stores/plugin.store'

const pluginStore = usePluginStore()

const selectedProjects = computed(() => {
  return pluginStore.selectedProject
})
const isSelectedProjects = computed(() => {
  return pluginStore.selectedProject !== null
})
const istypeProject = computed(() => {
  return pluginStore.isPluginResponse?.type === TYPES.PROJECT
})

/**
 * @param language gets the icon for the language
 * @returns icon
 */
const getIcon = (language: string) => {
  const langaugeForIcon = language
  for (let i = 0; i <= languagesItems.length; i++) {
    if (languagesItems[i]?.language === langaugeForIcon) return languagesItems[i]?.icon
  }
}
/**
 * @param language gets the icon for the language
 * @returns displayName
 */
const getDisplayName = (language: string) => {
  const langaugeForIcon = language
  for (let i = 0; i <= languagesItems.length; i++) {
    if (languagesItems[i]?.language === langaugeForIcon) return languagesItems[i]?.displayName
  }
}
/**
 * Deletes the selected project
 */
const onDelete = () => {
  configureService.removeSelectedProject()
}
</script>

<template>
  <div
    v-if="isSelectedProjects"
    class="section-primary flex w-fit items-center rounded-lg border p-2 text-sm hover:border-btn-primary"
  >
    <img :src="getIcon(selectedProjects.language)" class="light:inline-block me-2 h-3 w-3" />
    <span class="p-xsmall">{{ getDisplayName(selectedProjects.language) }}</span> -
    <span class="p-xsmall">{{ selectedProjects.name }}</span>
    <button
      v-if="istypeProject"
      :disabled="!istypeProject"
      class="error ms-2 h-4 w-4 disabled:text-gray-500"
      @click="onDelete"
    >
      <FontAwesomeIcon icon="fa-close" />
    </button>
  </div>
</template>
