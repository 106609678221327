<script setup lang="ts">
import Button_login_then_redirect from '@/components/shared/subscription/Button_login_then_redirect.vue'
import type { IActionButton } from '@/components/shared/PricingCard.vue'
import { SUBSCRIPTION_PRODUCT } from '@/components/shared/subscription/enum/subscription.enum'
import Solution_education from '@/assets/images/svg/Solution_education.svg'
import GifBanner, { type IGifBannerData } from '@/components/shared/GifBanner.vue'

const sectionData: IGifBannerData = {
  title:
    'Improve student learning outcomes by <span class="text-text-brand">25%</span> and teach programming more efficiently',
  countAndFeatures: [
    {
      count: '1 Million',
      description: 'Monthly Users'
    },
    {
      count: '76+',
      description: 'Languages Supported'
    }
  ],
  image: {
    src: Solution_education,
    alt: 'Solution animation'
  }
}

const list = [
  'For universities, colleges, schools and all education providers',
  'Execute code in your LMS/course material by integrating IDEs',
  'Create assignments and automate corrections and grading'
]

const button: IActionButton = {
  buttonText: 'Integrate Now',
  link: `/dashboard/institution`,
  product: SUBSCRIPTION_PRODUCT.TEACH
}
</script>

<template>
  <GifBanner :data="sectionData">
    <p v-for="(item, idx) in list" :key="idx" class="text-secondary relative mb-1.5 pl-8 text-lg">
      <span class="absolute left-0 w-6">
        <FontAwesomeIcon icon="fa-check" class="text-brand h-4 w-4 pt-1"></FontAwesomeIcon>
      </span>
      {{ item }}
    </p>
    <Button_login_then_redirect class="mt-10" :button-data="button" />
  </GifBanner>
</template>
