<script setup lang="ts">
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import { ref, onMounted } from 'vue'
import moment from 'moment'
import { getApiPaymentDetail } from '@/services/subscription.service'
import type ILink from '@/components/shared/interface/ILink'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
const breadcrumbStore = useBreadcrumbStore()
const completed = ref(false)
const nextPaymentDate = ref<null | string>(null)
const charges = ref<any>(null)

const breadcrumbLinks: ILink[] = [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  },
  {
    href: '/dashboard/payment/history',
    title: 'Payment History'
  }
]

onMounted(async () => {
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks)
  try {
    const res = await getApiPaymentDetail()
    if (res) {
      nextPaymentDate.value = moment(res.nextPaymentDate).format('DD-MM-YYYY')
      charges.value = res.charges
      completed.value = true
    }
  } catch (err: any) {
    // console.log(err)
  }
})
</script>

<template>
  <div>
    <DashboardCard title="Payment History" class="min-h-[130px]">
      <div
        v-show="(!charges || charges.length < 1) && !nextPaymentDate && completed"
        class="text-secondary my-2 text-center font-semibold"
      >
        No Payment Details Found.
      </div>
      <div v-if="completed">
        <div class="text-md mb-4 text-right">
          <p class="text-secondary">
            Next Payment Date: <span class="text-primary ml-4">{{ nextPaymentDate }}</span>
          </p>
        </div>
        <table
          class="mb-10 w-full divide-y divide-gray-200 bg-gray-100 text-left dark:divide-gray-700 dark:bg-transparent"
        >
          <thead>
            <tr>
              <th scope="col" class="text-md px-6 py-3 text-left font-semibold">Date</th>
              <th scope="col" class="text-md px-6 py-3 text-left font-semibold">Amount</th>
              <th scope="col" class="text-md px-6 py-3 text-left font-semibold">Outcome</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800"
              v-for="(charge, index) in charges"
              :key="index"
            >
              <td class="whitespace-nowrap px-6 py-4 text-sm font-medium">
                {{ moment(charge.created).format('DD-MM-YYYY') }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm">${{ charge.amount / 100 }}</td>
              <td class="whitespace-nowrap px-6 py-4 text-sm font-medium">
                {{ charge.outcome }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </DashboardCard>
  </div>
</template>
