<script setup lang="ts">
import { ref, onBeforeMount, onMounted, watch, computed, onBeforeUnmount } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { useRoute } from 'vue-router'
import { SCREENSIZES, BREAKPOINTS, COMPONENTDATA, FUNCTIONALSDATA } from '@/utils/customPlugin'
import ideService from '@/services/ide/ide.service'
import editorService from '@/services/ide/editor.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import pluginService from '@/services/ide/plugin/plugin.service'
import configureService from '@/services/ide/plugin/configure.service'
import customPluginService from '@/services/ide/plugin/custom.plugin.service'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import Recaptcha from '@/components/shared/RecaptchaComp.vue'
import FeaturePermissionMessage from '@/components/ide/plugin/FeaturePermissionMessage.vue'

import { GridLayout, GridItem } from 'vue3-grid-layout-next'

const props = defineProps({
  clientkey: {
    type: String,
    required: false
  },
  customkey: {
    type: String,
    required: false
  }
})
const route = useRoute()
const ideStore = useIdeStore()
const pluginStore = usePluginStore()

const container = ref<any>(null)

const isShowCustomPlugin = computed(() => {
  return pluginStore.isShowCustomPlugin
})
const isEditable = computed(() => {
  return pluginStore.isEditable
})
const langDisplayName = computed(() => {
  return ideStore.ideMeta?.langDisplayName
})
const isCompileLang = computed(() => {
  return ideStore.ideMeta?.isCompile || ideStore.ideMeta?.language === 'java'
})
const isShareNotFound = computed(() => {
  return ideStore.shareNotFound
})
const isShareFoundHttpError = computed(() => {
  return ideStore.shareNotFoundHttpError
})
const components = computed(() => {
  return pluginStore.components
})
const isMouseInGrid = computed(() => {
  return isEditable.value && pluginStore.isMouseInGrid
})
const displayScreenSizes = computed(() => {
  if (!isEditable.value) return 'w-full'
  let cssSizeInPixel = 'w-full'
  switch (pluginStore.screenSize) {
    case SCREENSIZES.SM:
      cssSizeInPixel = 'w-[640px]'
      break
    case SCREENSIZES.MD:
      cssSizeInPixel = 'w-[768px]'
      break
    case SCREENSIZES.LG:
      cssSizeInPixel = 'w-[1024px]'
      break
  }
  return cssSizeInPixel
})
/**
 * Init plugin ide
 */
const initPluginIde = async () => {
  pluginStore.setIsPlugin(true)
  pluginStore.setIsCusomPlugin(true)
  if ((props.clientkey && props.customkey) || isEditable) {
    if (!isEditable.value) {
      pluginStore.setClientkey(props.clientkey as string)
      pluginStore.setCustomkey(props.customkey as string)
      await customPluginService
        .initCustomPlugin()
        .then(() => {
          const activeStatus = pluginStore.isPluginResponse?.activeStatus
          if (!activeStatus) {
            ideStore.setShareNotFound(true)
            ideStore.setShareNotFoundHttpError(
              'This plugin is not active. Please contact the plugin owner.'
            )
          }
        })
        .catch((err) => {
          ideStore.setShareNotFound(true)
          ideStore.setShareNotFoundHttpError(
            'Unable to load the Custome Plugin. Please try again later.' || err.message
          )
        })
    }
  } else {
    ideStore.setShareNotFound(true)
    ideStore.setShareNotFoundHttpError('Unable to load the Custome Plugin. Please try again later.')
  }
  if (!isShareNotFound.value) {
    editorService.injectAce()
    pluginService.initOnRouterChange()
    await new Promise((resolve) => setTimeout(resolve, 100))
    pluginStore.setPymKey(route.query.id as string)
    pluginService.initEmbedPlugin()
  }
}
/**
 * On layout mounted
 */
const onLayoutUpdated = () => {
  customPluginService.reseizeAllComponents()
}
/**
 * onBreakpointChanged event
 * @param newBreakpoint The new breakpoint
 */
const onBreakpointChanged = (newBreakpoint: any) => {
  if (isEditable.value) return
  let breakpoint = newBreakpoint
  if (newBreakpoint === 'lg') {
    breakpoint = SCREENSIZES.LG
  } else if (newBreakpoint === 'md') {
    breakpoint = SCREENSIZES.MD
  } else if (newBreakpoint === 'sm') {
    breakpoint = SCREENSIZES.SM
  } else {
    breakpoint = SCREENSIZES.SM
  }
  customPluginService.onContainerResize(breakpoint)
}
/**
 * Shows the settings
 * @param component The component
 */
const showSettings = (component: any) => {
  if (isEditable.value) pluginStore.setSelectedComponent(component)
}
/**
 * Checks if the component is selected
 * @param item The component
 * @returns True if the component is selected
 */
const isSelected = (item: any) => {
  if (!item || !isEditable.value) return false
  return pluginStore.selectedComponent?.name === item.name
}
/**
 * Checks if the component is static
 * @param item The component
 * @returns True if the component is static
 */
const isStatic = (item: any) => {
  const component = pluginStore.components.find((c) => c.name === item.name)
  return component?.settings?.isStatic
}
/**
 * Deletes the component
 * @param item The component
 */
const onDelete = (item: any) => {
  configureService.deleteComponentFromLayout(item)
}
onBeforeMount(() => {
  if (!isEditable.value) {
    ideService.cleanIdeStore()
    pluginService.cleanPluginStore()
  }
  pluginStore.setComponents(COMPONENTDATA)
  pluginStore.setFunctionals(FUNCTIONALSDATA)
  initPluginIde()
  watch(route, () => {
    if (route.meta.canonicalPath || route.meta.customPlugin) initPluginIde()
  })
})
onBeforeRouteLeave((to, from, next) => {
  if (ideStore.isCodeUpdated && !isEditable.value) {
    if (
      window.confirm(
        `Are you sure you want to move from this ${langDisplayName.value}${
          isCompileLang.value ? ' Compiler' : ''
        } IDE?`
      )
    ) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
})
onMounted(() => {
  customPluginService.reseizeAllComponents()
  editorService.resizeCodeEditor()
  editorService.resizeOutputEditor()
  blocklyService.resizeBlockly()
  window.addEventListener('resize', () => {
    if (!isEditable.value) {
      customPluginService.reseizeAllComponents()
      editorService.resizeCodeEditor()
      editorService.resizeOutputEditor()
      blocklyService.resizeBlockly()
    }
  })
  window.onbeforeunload = function () {
    if (ideStore.isCodeUpdated && !isEditable.value) {
      return `Are you sure you want to move from this ${langDisplayName.value}${
        isCompileLang.value ? ' Compiler' : ''
      } IDE?`
    }
  }
})
onBeforeUnmount(() => {
  ideService.cleanIde()
  ideService.cleanIdeStore()
  pluginService.cleanPluginStore()
})
</script>
<template>
  <Recaptcha />
  <div :class="['embed-ide rounded-lg', displayScreenSizes]">
    <FeaturePermissionMessage />
    <p
      v-if="isShareNotFound"
      :class="[
        'print:hidden, p-small ',
        'error',
        'text-center',
        'my-5',
        { hidden: !isShareNotFound }
      ]"
    >
      {{ isShareFoundHttpError || 'Share not found' }}
    </p>
    <GridLayout
      v-if="!isShareNotFound && isShowCustomPlugin"
      ref="container"
      id="container"
      v-model:layout="pluginStore.layoutByScreenSize"
      :responsive-layouts="pluginStore.layouts"
      :breakpoints="BREAKPOINTS"
      :is-draggable="isEditable"
      :is-resizable="isEditable"
      :vertical-compact="true"
      :responsive="true"
      :is-bounded="true"
      :use-css-transforms="true"
      :class="[
        'section-tertiary h-full rounded-lg',
        {
          'opacity-70': isMouseInGrid
        }
      ]"
      @breakpoint-changed="onBreakpointChanged"
    >
      <GridItem
        v-for="(item, index) in pluginStore.layoutByScreenSize"
        :key="index"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        drag-allow-from="#dragAllowed"
        drag-ignore-from="#dragIgnore"
        :class="[
          'relative',
          'min-h-fit',
          'min-w-fit',
          'h-full',
          {
            'border border-btn-primary': isEditable
          },
          {
            'border-2 border-link-secondary': isSelected(item)
          }
        ]"
        style="touch-action: none"
        @moved="onLayoutUpdated"
        @resized="onLayoutUpdated"
      >
        <div v-if="isEditable" class="absolute right-0 z-10">
          <div class="flex h-fit w-fit gap-2">
            <div id="dragAllowed" class="h-4 w-4">
              <FontAwesomeIcon icon="fa-up-down-left-right" />
            </div>
            <button
              :disabled="isStatic(item)"
              class="error h-4 w-4 disabled:text-gray-500"
              @click="() => onDelete(item)"
            >
              <FontAwesomeIcon icon="fa-trash" />
            </button>
          </div>
        </div>
        <div
          id="dragIgnore"
          v-for="component in components"
          :key="component.name"
          @click="() => showSettings(component)"
        >
          <component
            v-if="item.name == component.name"
            :is="component.component"
            :id="component.name"
          />
        </div>
      </GridItem>
    </GridLayout>
  </div>
</template>

<style>
@import url('@/assets/styles/embed-ide.css');

.gutter {
  background-color: gray;
  background-repeat: no-repeat;
  background-position: 50%;
}
</style>
