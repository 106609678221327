<script setup lang="ts">
import type { IStudentGroup } from '@/components/guru/interface/IStudentGroup'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import { getStudentGroups } from '@/services/teach.service'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import { useTeachStore } from '@/stores/teach.store'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const teachStore = useTeachStore()
const breadcrumbStore = useBreadcrumbStore()
const insCode = ref<string>()

const searchString = ref('')

const route = useRoute()
const router = useRouter()
const studentGroups = ref([] as IStudentGroup[])
const filteredSgs = computed(() => {
  if (searchString.value) {
    return studentGroups.value.filter((sg: IStudentGroup) => {
      return sg.name.includes(searchString.value) || sg.description.includes(searchString.value)
    })
  } else {
    return studentGroups.value
  }
})

/**
 *
 */
function checkAuthentication() {
  if (!teachStore.guruInitialised) {
    return
  }

  let location = '/dashboard/institute/' + route.params.insCode

  if (!teachStore.isAccountOwner && !teachStore.isInstituteAdmin && !teachStore.isTeacher) {
    router.push(location)
  }
}

/**
 *
 * @param sg StudentGroup clicked on
 */
async function handleViewSg(sg: IStudentGroup) {
  router.push(`/dashboard/institution/${insCode.value}/student-group/${sg.insStudentGroupId}`)
}

onMounted(async () => {
  insCode.value = route.params.insCode as string
  teachStore.getAndSetCurrentAssociation(insCode.value)

  const res = await getStudentGroups(insCode.value)
  studentGroups.value = res

  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
})

watch(
  () => teachStore.currentAssociation,
  () => {
    checkAuthentication()
    breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
  }
)

/**
 * @param emit The emitted search string
 */
function handleSearchInput(emit: string) {
  searchString.value = emit
}

const addUserBtn = computed(() => {
  return {
    url: `/dashboard/institution/${insCode.value}/add-student-group`,
    name: 'Add Student Group'
  }
})

const breadcrumbLinks = computed(() => [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  },
  {
    href: '/dashboard/institution',
    title: 'Virtual Institutions'
  },
  {
    href: `/dashboard/institution/${insCode.value}`,
    title: `${teachStore.currentAssociation?.name}`
  },
  {
    href: `/dashboard/institution/${insCode.value}/student-groups`,
    title: `Student Groups`
  }
])
</script>

<template>
  <div class="h-full w-full">
    <DashboardCard
      title="Student Groups"
      class="h-full w-full"
      :has-search-bar="true"
      :link="addUserBtn"
      @searchInput="handleSearchInput"
    >
      <div
        v-if="teachStore.isAccountOwner || teachStore.isInstituteAdmin || teachStore.isTeacher"
        class="m-auto max-w-4xl"
      >
        <div v-if="studentGroups.length > 0" class="mt-5 w-full">
          <table class="w-full table-auto border-separate border-spacing-y-4">
            <thead class="text-start text-sm">
              <tr>
                <th class="px-3 py-1 text-start">Name</th>
                <th class="px-3 py-1 text-start">Description</th>
                <th class="px-3 py-1 text-start">Admin/Teachers</th>
                <th class="px-3 py-1 text-start">Students</th>
              </tr>
            </thead>
            <tbody class="">
              <tr
                @click="handleViewSg(sg)"
                role="button"
                v-for="sg in filteredSgs"
                :key="sg.insStudentGroupId"
                class="section-primary overflow-hidden rounded-lg p-3"
              >
                <td class="rounded-s-lg p-3 text-sm">{{ sg.name }}</td>
                <td class="p-3 text-sm">
                  {{ sg.description.slice(0, 30)
                  }}<span v-if="sg.description.length > 30">...</span>
                </td>
                <td class="p-3 text-sm">
                  {{ sg.groupAdmins.length }}
                </td>
                <td class="rounded-e-lg p-3 text-sm">{{ sg.students.length }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </DashboardCard>
  </div>
</template>
