<script lang="ts" setup>
import { type IDashboardProject } from '@/services/dashboard.project.service'
import { languagesItems } from '@/utils/sharedData/languages'
import { useRouter } from 'vue-router'
import { useIdeStore } from '@/stores/ide.store'
import { META } from '@/utils/meta'

import { useDashboardProjectStore } from '@/stores/project.store'
import { ref } from 'vue'

const ideStore = useIdeStore()
const router = useRouter()
const dashboardProjectStore = useDashboardProjectStore()
const idePath = ref(' ' as string)

const meta = { ...META }

/**
 * @param project selected project
 * @returns boolean
 */
const isSelected = (project: IDashboardProject) => {
  return dashboardProjectStore.projectId === project.id || false
}

const props = defineProps({
  projectData: {
    type: Array<IDashboardProject>,
    required: false,
    default: true
  }
})
/**
 * @param language gets the icon for the language
 * @returns icon
 */
const getIcon = (language: string) => {
  const langaugeForIcon = language
  for (let i = 0; i <= languagesItems.length; i++) {
    if (languagesItems[i]?.language === langaugeForIcon) return languagesItems[i]?.icon
  }
}

/**
 * @param id project ID selected
 * @param language  get the language of the project
 * @param isMultiFile check if project is advanced java project
 */
const openProject = (id: string, language: string, isMultiFile: boolean) => {
  ideStore.setOpenProjectID(null)
  ideStore.setOpenProjectID(id as string)

  if (isMultiFile === true && language === 'java') {
    idePath.value = `/online-java-compiler-ide`
  } else {
    for (var obj in meta) {
      if (obj === language) {
        let path = meta[obj].canonicalPath as string
        idePath.value = `/${path}`
      }
    }
  }
  // push with id as meta
  router.push({
    path: idePath.value
  })
}

/**
 * @param language gets the icon for the language
 * @returns displayName
 */
const getDisplayName = (language: string) => {
  const langaugeForIcon = language
  for (let i = 0; i <= languagesItems.length; i++) {
    if (languagesItems[i]?.language === langaugeForIcon) return languagesItems[i]?.displayName
  }
}

/**
 * @param id , project id selected
 * @param language gets the icon for the language
 * @param isMultiFile to check if it is advanced java project
 * @param name name of the selected projects
 */
const setProject = (id: string, language: string, isMultiFile: boolean, name: string) => {
  dashboardProjectStore.setProjectDetails(id, language, isMultiFile, name)
  router.push('/dashboard/project')
}

/**
 * @param id , project id selected
 * @param language gets the icon for the language
 */
const deleteProject = async (id: string, language: string) => {
  router.push('/dashboard/project')
  dashboardProjectStore.showAskForDelete = true

  dashboardProjectStore.deleteProjectId = id
  dashboardProjectStore.deleteProjectLang = language
}

// :src="item.darkIcon ? item.darkIcon : item.icon"

export interface IProjectwithIcons extends IDashboardProject {
  icon: string
  darkIcon: string | undefined
}
</script>

<template>
  <!-- temporary move: min-h-[200px] min-w-[300px]  -->

  <div class="section-combined overscroll-auto rounded-xl">
    <div
      v-for="project in props.projectData"
      :key="project.id"
      :class="[
        'section-primary',
        'p-small',
        'md:p-normal',
        'my-3',
        'rounded-lg',
        'px-6',
        'py-4',
        {
          ' border border-text-brand': isSelected(project)
        }
      ]"
    >
      <div class="mb-2 flex gap-x-3 text-sm font-light">
        {{ project.name }}
        <button @click="openProject(project.id, project.language, project.isMultiFile)">
          <FontAwesomeIcon
            icon="fa-arrow-up-right-from-square  "
            class="mr-1 cursor-pointer"
          ></FontAwesomeIcon>
        </button>
      </div>
      <div class="flex justify-between">
        <div class="text-secondary flex text-sm">
          <img :src="getIcon(project.language)" class="light:inline-block me-2 w-4" />
          {{ getDisplayName(project.language) }}
        </div>
        <div class="flex gap-x-4 text-sm">
          <FontAwesomeIcon
            icon="fa-eye"
            class="mr-1 cursor-pointer"
            @click="setProject(project.id, project.language, project.isMultiFile, project.name)"
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon="fa-trash"
            class="mr-1 cursor-pointer"
            @click="deleteProject(project.id, project.language)"
          ></FontAwesomeIcon>
          <!-- <FontAwesomeIcon icon="fa-share-nodes" class="mr-1 cursor-pointer"></FontAwesomeIcon> -->
        </div>
      </div>
    </div>
  </div>
</template>
