<script setup lang="ts">
import { ref, computed, watch, onBeforeMount, onMounted, onBeforeUnmount } from 'vue'
import { SCREENSIZES, TYPES } from '@/utils/customPlugin'
import { DASHBOARD } from '@/utils/models'
import { useRouter } from 'vue-router'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import customPluginService from '@/services/ide/plugin/custom.plugin.service'
import editorService from '@/services/ide/editor.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import configureService from '@/services/ide/plugin/configure.service'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import EditablePluginIDE from '@/components/ide/plugin/EditablePluginIDE.vue'
import ComponentList from '@/components/ide/plugin/ComponentList.vue'
import ProjectList from '@/components/ide/plugin/ProjectList.vue'
import SelectedProject from '@/components/ide/plugin/SelectedProject.vue'
import FunctionalList from '@/components/ide/plugin/FunctionalList.vue'
import SelectedFunctionals from '@/components/ide/plugin/SelectedFunctionals.vue'
// import ComponentSetting from '@/components/ide/plugin/ComponentSetting.vue'
import RenamePluginModel from '@/components/dashboard/plugin/RenamePluginModel.vue'
import utilModelsService from '@/services/util.models.service'
import ideService from '@/services/ide/ide.service'
import pluginService from '@/services/ide/plugin/plugin.service'
// import TestView from '@/views/dashboard/plugin/TestView.vue'
import DashboardBreadcrumb from '@/components/shared/dashboard/DashboardBreadcrumb.vue'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'

import moment from 'moment'

const props = defineProps({
  pluginId: {
    type: String,
    required: true
  }
})
const router = useRouter()
const ideStore = useIdeStore()
const pluginStore = usePluginStore()
const breadcrumbStore = useBreadcrumbStore()

const pluginCode = ref(props.pluginId as string)
const httpError = ref<string>('')
const httpErrorTimeOut = ref<number | null>(null)
const httpSuccess = ref<string>('')
const httpSuccessTimeOut = ref<number | null>(null)
const isShareNotFound = ref<boolean>(false)
const isShareFoundHttpError = ref<string>('')
const pluginLoaded = ref<boolean>(false)

const languages = computed(() => {
  return pluginStore.languages
})
const versions = computed(() => {
  return ideStore.isVersions
})
const screenSizes = computed(() => {
  return Object.values(SCREENSIZES)
})
const isPluginName = computed(() => {
  return pluginStore.isPluginResponse?.name
})
const isPluginDescription = computed(() => {
  return pluginStore.isPluginResponse?.description
})
const isCreatedDate = computed(() => {
  return moment(pluginStore.isPluginResponse?.dateCreated).format('DD/MM/YYYY HH:mm') || ''
})
const isLastUpdateDate = computed(() => {
  return moment(pluginStore.isPluginResponse?.updatedAt).format('DD/MM/YYYY HH:mm') || ''
})
const isActivatedText = computed(() => {
  return pluginStore.isPluginResponse?.activeStatus ? 'Deactivate' : 'Activate'
})
const isBasic = computed(() => {
  return pluginStore.isPluginResponse?.type === TYPES.BASIC
})
const showDefaultLanguage = computed(() => {
  return pluginStore.isPluginResponse?.type === TYPES.BASIC
})
const showProjects = computed(() => {
  return (
    pluginStore.isPluginResponse?.type === TYPES.BASIC ||
    pluginStore.isPluginResponse?.type === TYPES.PROJECT
  )
})
const breadcrumbLinks = computed(() => {
  return [
    {
      href: '/',
      title: 'Home'
    },
    {
      href: '/dashboard',
      title: 'Dashboard'
    },
    {
      href: '/dashboard/plugin',
      title: 'Plugins'
    },
    {
      href: `/configure-plugin/${pluginCode.value}`,
      title: `${pluginStore.isPluginResponse?.name}`
    }
  ]
})
/**
 * Checks if the version is selected
 * @param index The version index to check
 * @returns True if the version is selected
 */
const isLanguageSelected = (index: number) => {
  return pluginStore.isDefaultLanguage === languages.value[index]
}
/**
 * Checks if the version is selected
 * @param index The version index to check
 * @returns True if the version is selected
 */
const isVersionSelected = (index: number) => {
  return ideStore.versionIndex === index
}
/**
 * Sets the version
 * @param e The event
 */
const setLanguage = (e: Event) => {
  configureService.setDefautlLanguage(parseInt((e.target as HTMLSelectElement).value))
}
/**
 * Sets the version
 * @param e The event
 */
const setVersion = (e: Event) => {
  ideStore.setVersionIndex(parseInt((e.target as HTMLSelectElement).value))
  ideStore.setCodeUpdated(true)
  if (ideStore.isBlockly) blocklyService.changeBlocklyLanguage()
}
/**
 * Checks if the screen size is selected
 * @param index The screen size index to check
 * @returns True if the screen size is selected
 */
const isScreenSizeSelected = (index: number) => {
  return pluginStore.screenSize === Object.values(SCREENSIZES)[index]
}
/**
 * Sets the screen size
 * @param e The event
 */
const setScreenSize = async (e: Event) => {
  const index = parseInt((e.target as HTMLSelectElement).value)
  const screenSizeKey = Object.values(SCREENSIZES)[index]
  pluginStore.setScreenSize(screenSizeKey)
  await new Promise((resolve) => setTimeout(resolve, 100))
  customPluginService.reseizeAllComponents()
  editorService.resizeCodeEditor()
  editorService.resizeOutputEditor()
  blocklyService.resizeBlockly()
}
/**
 * Deletes the plugin
 */
const onDelete = async () => {
  await utilModelsService
    .confirmPromise('Delete Plugin', 'Are you sure you want to delete this plugin?')
    .then(async () => {
      await configureService
        .deletePlugin(props.pluginId as string)
        .then(() => {
          router.push({
            name: 'dashboard-plugin'
          })
        })
        .catch((err: { message: string }) => {
          httpError.value = err.message || 'Unable to delete the plugin. Please try again later.'
        })
    })
    .catch(() => {})
}
/**
 * Toggles the plugin active status
 */
const ToggleActiveStatus = async () => {
  const status = !pluginStore.isPluginResponse?.activeStatus
  await configureService
    .toggleActiveStatus(props.pluginId as string, status)
    .catch((err: { message: string }) => {
      httpError.value = err.message || 'Unable to toggle the plugin status. Please try again later.'
    })
}
/**
 * Resets the plugin
 */
const onReset = async () => {
  await utilModelsService
    .confirmPromise(
      'Reset Plugin',
      'Are you sure you want to reset this plugin to its last saved state?'
    )
    .then(() => {
      // reload the page
      window.location.reload()
      // configureService.resetPlugin()
      // httpSuccess.value = 'Plugin reset to last saved state.'
    })
}
/**
 * Saves the plugin
 */
const onSave = async () => {
  await configureService
    .savePlugin(props.pluginId as string)
    .then(() => {
      httpSuccess.value = 'Plugin saved successfully.'
    })
    .catch((err: { message: string }) => {
      httpError.value = err.message || 'Unable to save the plugin. Please try again later.'
    })
}
onBeforeMount(async () => {
  ideService.cleanIdeStore()
  pluginService.cleanPluginStore()
  if (props.pluginId) {
    await configureService
      .initConfigure(props.pluginId as string)
      .then((response: any) => {
        const { clientId, pluginKey } = response.data
        pluginStore.setClientkey(clientId)
        pluginStore.setCustomkey(pluginKey)
        pluginLoaded.value = true
      })
      .catch((err) => {
        console.error(err)
        isShareNotFound.value = true
        isShareFoundHttpError.value =
          'Unable to load the Custome Plugin. Please try again later.' || err.message
      })
  } else {
    isShareNotFound.value = true
    isShareFoundHttpError.value = 'Unable to load the Custome Plugin. Please try again later.'
  }
})
onMounted(() => {
  httpError.value = ''

  watch(httpError, () => {
    if (httpError.value) {
      if (httpErrorTimeOut.value) clearTimeout(httpErrorTimeOut.value)
      httpErrorTimeOut.value = setTimeout(() => {
        httpError.value = ''
      }, 8000)
    }
  })
  watch(httpSuccess, () => {
    if (httpSuccess.value) {
      if (httpSuccessTimeOut.value) clearTimeout(httpSuccessTimeOut.value)
      httpSuccessTimeOut.value = setTimeout(() => {
        httpSuccess.value = ''
      }, 8000)
    }
  })
})
onMounted(() => {
  pluginCode.value = props.pluginId as string
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
})
onBeforeUnmount(() => {
  pluginStore.setClientkey(null)
  pluginStore.setCustomkey(null)
  pluginStore.setPluginResponse(null)
  configureService.ejectPym()
  ideService.cleanIdeStore()
  pluginService.cleanPluginStore()
})

watch(
  () => pluginStore.isPluginResponse,
  () => {
    breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
  }
)

watch(
  () => props.pluginId,
  () => {
    pluginCode.value = props.pluginId as string
  }
)
</script>

<template>
  <p
    v-if="isShareNotFound"
    :class="[
      'print:hidden, p-small ',
      'error',
      'text-center',
      'my-5',
      { hidden: !isShareNotFound }
    ]"
  >
    {{ isShareFoundHttpError || 'Plugin not found' }}
  </p>
  <div v-show="!isShareNotFound">
    <div v-if="!pluginLoaded" class="flex flex-col items-center justify-center gap-4">
      <p class="p-small text-secondary">Loading...</p>
    </div>
    <div v-else class="view-container flex h-full w-full flex-col gap-4 pb-10 pt-2">
      <RenamePluginModel />
      <p :class="['p-xsmall ', 'error', , { hidden: !httpError }]">
        {{ httpError }}
      </p>
      <div class="flex justify-between">
        <DashboardBreadcrumb class="w-full" />
        <div class="flex w-full justify-end">
          <div class="flex h-fit items-center gap-1 rounded-lg border border-btn-primary px-1">
            <span class="btn btn-primary w-fit rounded-lg px-2 py-1 text-right">Beta</span>
            <span class="p-xsmall">version</span>
          </div>
        </div>
      </div>
      <DashboardCard :title="isPluginName">
        <div class="text-secondary flex flex-col items-start justify-between gap-5 xl:flex-row">
          <div class="flex flex-col gap-1">
            <p class="p-medium mb-2 flex-1 break-all">{{ isPluginDescription }}</p>
            <p class="p-small flex-1">Created on {{ isCreatedDate }}</p>
            <p class="p-small flex-1">Last edited on {{ isLastUpdateDate }}</p>
          </div>
          <div class="flex flex-row gap-1 md:gap-4">
            <button
              class="btn btn-secondary w-full"
              :data-hs-overlay="`#${DASHBOARD.RENAME_PLUGIN}`"
            >
              Rename
            </button>
            <button class="btn btn-secondary w-full" @click="onDelete">Delete</button>
            <button class="btn btn-primary w-full" @click="ToggleActiveStatus">
              {{ isActivatedText }}
            </button>
          </div>
        </div>
      </DashboardCard>
      <div class="flex w-full flex-col gap-4 overflow-hidden xl:flex-row">
        <div class="flex min-w-[375px] flex-col gap-4">
          <DashboardCard v-if="showDefaultLanguage" title="Default Language and Version">
            <div class="flex flex-col gap-4">
              <div class="flex gap-2">
                <label for="languageSelect" class="p-small text-secondary my-2 block"
                  >Language</label
                >
                <select
                  id="languageSelect"
                  class="section-secondary p-small block w-full rounded-md px-4 py-2 print:border"
                  @change="setLanguage($event)"
                >
                  <option
                    v-for="(language, index) in languages"
                    :key="index"
                    :selected="isLanguageSelected(index)"
                    :value="index"
                  >
                    {{ language.displayName }}
                  </option>
                </select>
              </div>
              <div class="flex gap-2">
                <label for="versionSelect" class="p-small text-secondary my-2 block">Version</label>
                <select
                  id="versionSelect"
                  class="section-secondary p-small block w-full rounded-md px-4 py-2 print:border"
                  @change="setVersion($event)"
                >
                  <option
                    v-for="(version, index) in versions"
                    :key="index"
                    :selected="isVersionSelected(index)"
                    :value="index"
                  >
                    {{ version }}
                  </option>
                </select>
              </div>
            </div>
          </DashboardCard>
          <DashboardCard title="Components">
            <ComponentList />
          </DashboardCard>
          <DashboardCard v-show="showProjects" title="Projects">
            <ProjectList />
          </DashboardCard>
          <!-- <DashboardCard title="Designer"> </DashboardCard> -->
          <DashboardCard title="Functional Features">
            <FunctionalList />
          </DashboardCard>
        </div>
        <div class="flex w-full flex-1 flex-col gap-4">
          <!-- <DashboardCard title="Component Settings">
            <ComponentSetting />
          </DashboardCard> -->
          <DashboardCard>
            <div class="flex flex-col gap-4">
              <div class="flex gap-5">
                <label for="sizeSelect" class="p-small text-secondary my-2 block">Sizes</label>
                <select
                  id="sizeSelect"
                  class="section-secondary p-small block w-full rounded-md px-4 py-2 print:border"
                  @change="setScreenSize($event)"
                >
                  <option
                    v-for="(size, index) in screenSizes"
                    :key="index"
                    :selected="isScreenSizeSelected(index)"
                    :value="index"
                  >
                    {{ size }}
                  </option>
                </select>
              </div>
              <p v-if="isBasic" class="p-small text-secondary">
                Your program, Command Line Arguments, Stdin Inputs, and Interactive Mode will be
                Saved and Restored.
              </p>
              <div class="flex h-fit items-end gap-4">
                <SelectedProject />
                <SelectedFunctionals />
              </div>
              <!-- <TestView /> -->
              <EditablePluginIDE />
            </div>
          </DashboardCard>
          <DashboardCard>
            <p :class="['p-xsmall', 'success', { hidden: !httpSuccess }]">
              {{ httpSuccess }}
            </p>
            <p :class="['p-xsmall ', 'error', , { hidden: !httpError }]">
              {{ httpError }}
            </p>
            <div class="flex justify-center gap-4">
              <button class="btn btn-secondary w-fit" @click="onReset">Reset</button>
              <button class="btn btn-primary w-fit" @click="onSave">Save Plugin</button>
            </div>
          </DashboardCard>
        </div>
      </div>
    </div>
  </div>
</template>
