<script setup lang="ts">
import UseCaseBanner, { type IUseCaseBannerData } from '@/components/shared/UseCaseBanner.vue'
import Homepage_7 from '@/assets/images/svg/Homepage_7.svg'

const teachAndAssessSection: IUseCaseBannerData = {
  title: 'Create assignments, invite students and run your own virtual institute',
  list: [
    'Create online courses',
    'Automate correction and grading',
    'Collaborate with students and peers'
  ],
  primaryButton: {
    text: 'Teach & Assess',
    link: '/online-assessment-teaching'
  },
  textLeftImageRight: true,
  image: {
    src: Homepage_7,
    alt: 'animation svg'
  }
}
</script>

<template>
  <div class="section-primary">
    <UseCaseBanner :sectionData="teachAndAssessSection"> </UseCaseBanner>
  </div>
</template>
