<script setup lang="ts">
declare global {
  interface Window {
    TogetherJS: any
  }
}
import { computed } from 'vue'
import { IDEVIEWMODELS } from '@/utils/models'
import projectsService from '@/services/ide/projects.service'
import historyService from '@/services/ide/settings/history.service'
import settingService from '@/services/ide/settings/setting.service'
import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'

const props = defineProps({
  handleMenuCollapse: {
    type: Function,
    required: true
  },
  menuCollapsed: {
    type: Boolean,
    required: true
  }
})

const authStore = useAuthStore()
const ideStore = useIdeStore()

const isShowLibs = computed(() => {
  return ideStore.ideMeta?.language === 'java'
})
const isUserloggedIn = computed(() => {
  return authStore.isLogedIn
})
const isAdvanced = computed(() => {
  return ideStore.isAdvanced || false
})
const isHtml = computed(() => {
  return ideStore.isHtml
})
const isFullScreen = computed(() => {
  return ideStore.isFullScreen
})
/**
 * Clear selected project before opening my projects model
 */
const initProjects = () => {
  if (authStore.isUserloggedIn) {
    projectsService.refresh()
  }
}
/**
 * Get history before opening history model
 */
const initHistory = () => {
  if (!isAdvanced.value) {
    historyService.refresh()
  }
}
/**
 * set copy editer script to code editor script
 */
const initCopy = async () => {
  if (!isHtml.value) {
    const isCopied = await settingService.copyToClipboard()
    ideStore.setIsCopied(isCopied)
  }
}
/**
 * Collaborate and initiate togetherjs
 */
const collaborate = () => {
  window.TogetherJS(this)
}
/**
 * Download the code
 */
const InitDownload = async () => {
  settingService.refreshDownloadEditor()
}
/**
 * Open the code from local file
 */
const initOpen = () => {
  settingService.refreshOpenEditor()
}
/**
 * Pretty print the page
 */
const prettyPrint = () => {
  settingService.prettyPrint()
}
</script>

<template>
  <div class="section-tertiary h-full rounded-lg p-3">
    <div class="flex flex-col items-center" :class="isFullScreen ? 'gap-[0.5rem]' : 'gap-1'">
      <!-- Collapse button -->
      <div
        class="hs-tooltip flex w-full items-center [--placement:right]"
        :class="menuCollapsed ? 'justify-center' : 'justify-between'"
      >
        <span v-if="!menuCollapsed">Menu</span>
        <button
          @click="props.handleMenuCollapse"
          role="button"
          class="btn-secondary dashboard-sub-animation rounded-md px-3 py-1 text-sm font-normal"
        >
          <FontAwesomeIcon
            icon="fa-arrow-right"
            class="w-4"
            :class="props.menuCollapsed ? 'rotate-0' : 'rotate-180'"
          />
          <!-- Tooltip -->
          <span
            class="hs-tooltip-content invisible absolute top-[-20px] z-10 inline-block rounded-md bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 dark:bg-slate-700"
            role="tooltip"
            v-if="props.menuCollapsed"
          >
            Open/Close menu
          </span>
        </button>
      </div>
      <hr class="mt-3 w-full border-t border-gray-400 dark:border-slate-800" />
      <div class="hs-tooltip flex w-full justify-start [--placement:right]">
        <button
          v-show="isShowLibs"
          class="hs-tooltip hs-tooltip-toggle btn link-primary flex w-full gap-4 p-1 align-middle"
          :class="props.handleMenuCollapse ? 'justify-center' : 'justify-start'"
          :data-hs-overlay="`#${IDEVIEWMODELS.LIBRARIES}`"
        >
          <FontAwesomeIcon icon="fa-plus" class="h-4 w-4" />
          <!-- Text -->
          <span v-if="!props.menuCollapsed" class="w-full text-start text-xs"
            >External Libraries</span
          >
          <!-- Tooltip -->
          <span
            class="hs-tooltip-content invisible absolute z-10 inline-block rounded-md bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 dark:bg-slate-700"
            role="tooltip"
            v-if="props.menuCollapsed"
          >
            External Libraries
          </span>
        </button>
      </div>
      <hr v-show="isShowLibs" class="w-full border-t border-gray-400 dark:border-slate-800" />
      <div class="hs-tooltip flex w-full justify-start [--placement:right]">
        <button
          v-if="!isAdvanced"
          class="btn hs-tooltip hs-tooltip-toggle link-primary flex w-full gap-4 p-1 align-middle"
          :class="props.menuCollapsed ? 'justify-center' : 'justify-start'"
          :data-hs-overlay="`#${IDEVIEWMODELS.EXECUTEHISTORY}`"
          @click="initHistory"
        >
          <FontAwesomeIcon icon="fa-history" class="h-4 w-4" />
          <!-- Text -->
          <span v-if="!props.menuCollapsed" class="w-full text-start text-xs">Execute History</span>
          <!-- Tooltip -->
          <span
            class="hs-tooltip-content invisible absolute z-10 inline-block rounded-md bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 dark:bg-slate-700"
            role="tooltip"
            v-if="props.menuCollapsed"
          >
            Execute History
          </span>
        </button>
      </div>
      <div class="hs-tooltip flex w-full justify-start [--placement:right]">
        <button
          class="btn hs-tooltip hs-tooltip-toggle link-primary flex w-full gap-4 p-1 align-middle"
          :class="props.menuCollapsed ? 'justify-center' : 'justify-start'"
          :data-hs-overlay="`#${IDEVIEWMODELS.NEWPROJECT}`"
        >
          <FontAwesomeIcon icon="fa-file" class="h-4 w-4" />
          <!-- Text -->
          <span v-if="!props.menuCollapsed" class="w-full text-start text-xs">New Project</span>
          <!-- Tooltip -->
          <span
            class="hs-tooltip-content invisible absolute z-10 inline-block rounded-md bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 dark:bg-slate-700"
            role="tooltip"
            v-if="props.menuCollapsed"
          >
            New Project
          </span>
        </button>
      </div>

      <div class="hs-tooltip flex w-full justify-start [--placement:right]">
        <button
          class="btn hs-tooltip hs-tooltip-toggle link-primary flex w-full gap-4 p-1 align-middle"
          :data-hs-overlay="
            isUserloggedIn ? `#${IDEVIEWMODELS.MYPROJECTS}` : `#${IDEVIEWMODELS.NOTLOGGEDIN}`
          "
          @click="initProjects"
        >
          <FontAwesomeIcon icon="fa-folder" class="h-4 w-4" />
          <!-- Text -->
          <span v-if="!props.menuCollapsed" class="w-full text-start text-xs">My Projects</span>
          <!-- Tooltip -->
          <span
            class="hs-tooltip-content invisible absolute z-10 inline-block rounded-md bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 dark:bg-slate-700"
            role="tooltip"
            v-if="props.menuCollapsed"
          >
            My Projects
          </span>
        </button>
      </div>
      <div class="hs-tooltip flex w-full justify-start [--placement:right]">
        <button
          class="btn hs-tooltip hs-tooltip-toggle link-primary flex w-full gap-4 p-1 align-middle"
          :class="props.menuCollapsed ? 'justify-center' : 'justify-start'"
          :data-hs-overlay="`#${IDEVIEWMODELS.SETTINGS}`"
          data-hs-overlay-close
          @click="collaborate"
        >
          <FontAwesomeIcon icon="fa-video" class="h-4 w-4" />
          <!-- Text -->
          <span v-if="!props.menuCollapsed" class="w-full text-start text-xs">Collaborate</span>
          <!-- Tooltip -->
          <span
            class="hs-tooltip-content invisible absolute z-10 inline-block rounded-md bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 dark:bg-slate-700"
            role="tooltip"
            v-if="props.menuCollapsed"
          >
            Collaborate
          </span>
        </button>
      </div>
      <hr class="w-full border-t border-gray-400 dark:border-slate-800" />
      <div class="hs-tooltip flex w-full justify-start [--placement:right]">
        <button
          class="btn hs-tooltip hs-tooltip-toggle link-primary flex w-full gap-4 p-1 align-middle"
          :class="props.menuCollapsed ? 'justify-center' : 'justify-start'"
          :data-hs-overlay="
            isUserloggedIn ? `#${IDEVIEWMODELS.SAVE}` : `#${IDEVIEWMODELS.NOTLOGGEDIN}`
          "
        >
          <FontAwesomeIcon icon="fa-floppy-disk" class="h-4 w-4" />
          <!-- Text -->
          <span v-if="!props.menuCollapsed" class="w-full text-start text-xs">Save</span>
          <!-- Tooltip -->
          <span
            class="hs-tooltip-content invisible absolute z-10 inline-block rounded-md bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 dark:bg-slate-700"
            role="tooltip"
            v-if="props.menuCollapsed"
          >
            Save
          </span>
        </button>
      </div>
      <div class="hs-tooltip flex w-full justify-start [--placement:right]">
        <button
          v-if="!isAdvanced && !isHtml"
          class="btn hs-tooltip hs-tooltip-toggle link-primary flex w-full gap-4 p-1 align-middle"
          :class="props.menuCollapsed ? 'justify-center' : 'justify-start'"
          :data-hs-overlay="`#${IDEVIEWMODELS.OPENFROMFILE}`"
          @click="initOpen"
        >
          <FontAwesomeIcon icon="fa-upload" class="h-4 w-4" />
          <!-- Text -->
          <span v-if="!props.menuCollapsed" class="w-full text-start text-xs"
            >Open (from local file)</span
          >
          <!-- Tooltip -->
          <span
            class="hs-tooltip-content invisible absolute z-10 inline-block rounded-md bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 dark:bg-slate-700"
            role="tooltip"
            v-if="props.menuCollapsed"
          >
            Open (from local file)
          </span>
        </button>
      </div>
      <div class="hs-tooltip flex w-full justify-start [--placement:right]">
        <button
          v-if="!isAdvanced"
          class="btn hs-tooltip hs-tooltip-toggle link-primary flex w-full gap-4 p-1 align-middle"
          :class="props.menuCollapsed ? 'justify-center' : 'justify-start'"
          :data-hs-overlay="`#${IDEVIEWMODELS.DOWNLOAD}`"
          @click="InitDownload"
        >
          <FontAwesomeIcon icon="fa-download" class="h-4 w-4" />
          <!-- Text -->
          <span v-if="!props.menuCollapsed" class="w-full text-start text-xs"
            >Save (to local file)</span
          >
          <!-- Tooltip -->
          <span
            class="hs-tooltip-content invisible absolute right-10 z-10 inline-block rounded-md bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 dark:bg-slate-700"
            role="tooltip"
            v-if="props.menuCollapsed"
          >
            Save (to local file)
          </span>
        </button>
      </div>
      <div class="hs-tooltip flex w-full justify-start [--placement:right]">
        <button
          class="btn hs-tooltip hs-tooltip-toggle link-primary flex w-full gap-4 p-1 align-middle"
          :class="props.menuCollapsed ? 'justify-center' : 'justify-start'"
          :data-hs-overlay="
            isUserloggedIn ? `#${IDEVIEWMODELS.EDITABLESHARE}` : `#${IDEVIEWMODELS.NOTLOGGEDIN}`
          "
        >
          <FontAwesomeIcon icon="fa-share-alt-square" class="h-4 w-4" />
          <!-- Text -->
          <span v-if="!props.menuCollapsed" class="w-full text-start text-xs">Editable Share</span>
          <!-- Tooltip -->
          <span
            class="hs-tooltip-content invisible absolute z-10 inline-block rounded-md bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 dark:bg-slate-700"
            role="tooltip"
            v-if="props.menuCollapsed"
          >
            Editable Share
          </span>
        </button>
      </div>
      <div class="hs-tooltip flex w-full justify-start [--placement:right]">
        <button
          v-if="!isHtml"
          class="btn hs-tooltip hs-tooltip-toggle link-primary flex w-full gap-4 p-1 align-middle"
          :data-hs-overlay="`#${IDEVIEWMODELS.INSTANTSHARE}`"
        >
          <FontAwesomeIcon icon="fa-share-square" class="h-4 w-4" />
          <!-- Text -->
          <span v-if="!props.menuCollapsed" class="w-full text-start text-xs"
            >Instant Share (No Login/Save required)</span
          >
          <!-- Tooltip -->
          <span
            class="hs-tooltip-content invisible absolute z-10 inline-block rounded-md bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 dark:bg-slate-700"
            role="tooltip"
            v-if="props.menuCollapsed"
          >
            Instant Share (No Login/Save required)
          </span>
        </button>
      </div>
      <div class="hs-tooltip flex w-full justify-start [--placement:right]">
        <button
          v-if="!isHtml"
          class="btn hs-tooltip hs-tooltip-toggle link-primary flex w-full gap-4 p-1 align-middle"
          @click="initCopy"
        >
          <FontAwesomeIcon icon="fa-clipboard" class="h-4 w-4" />
          <!-- Text -->
          <span v-if="!props.menuCollapsed" class="w-full text-start text-xs"
            >Copy to Clipboard</span
          >
          <!-- Tooltip -->
          <span
            class="hs-tooltip-content invisible absolute z-10 inline-block rounded-md bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 dark:bg-slate-700"
            role="tooltip"
            v-if="props.menuCollapsed"
          >
            Copy to Clipboard
          </span>
        </button>
      </div>
      <div class="hs-tooltip flex w-full justify-start [--placement:right]">
        <button
          v-if="!isAdvanced"
          class="btn hs-tooltip hs-tooltip-toggle link-primary flex w-full gap-4 p-1 align-middle"
          :class="props.menuCollapsed ? 'justify-center' : 'justify-start'"
          :data-hs-overlay="`#${IDEVIEWMODELS.SETTINGS}`"
          data-hs-overlay-close
          @click="prettyPrint"
        >
          <FontAwesomeIcon icon="fa-print" class="h-4 w-4" />
          <span v-if="!props.menuCollapsed" class="w-full text-start text-xs">Pretty Print</span>
          <span
            class="hs-tooltip-content invisible absolute z-10 inline-block rounded-md bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 dark:bg-slate-700"
            role="tooltip"
            v-if="props.menuCollapsed"
          >
            Pretty Print
          </span>
        </button>
      </div>
      <hr class="w-full border-t border-gray-400 dark:border-slate-800" />
      <div class="hs-tooltip flex w-full justify-start [--placement:right]">
        <a
          class="btn hs-tooltip hs-tooltip-toggle link-primary flex w-full gap-4 p-1 align-middle"
          :class="props.menuCollapsed ? 'justify-center' : 'justify-start'"
          href="https://docs.jdoodle.com"
          target="_blank"
        >
          <FontAwesomeIcon icon="fa-circle-question" class="h-4 w-4" />
          <!-- Text -->
          <span v-if="!props.menuCollapsed" class="w-full text-start text-xs">How To/FAQ</span>
          <!-- Tooltip -->
          <span
            class="hs-tooltip-content invisible absolute z-10 inline-block rounded-md bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 dark:bg-slate-700"
            role="tooltip"
            v-if="props.menuCollapsed"
          >
            How To/FAQ
          </span>
        </a>
      </div>
    </div>
  </div>
</template>
