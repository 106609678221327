<script setup lang="ts">
import GifBanner, { type IGifBannerData } from '@/components/shared/GifBanner.vue'
import SlidingImages from '@/components/shared/SlidingImages.vue'
import SixItemsPanel, { type ISixItems } from '@/components/shared/SixItemsPanel.vue'
import FrequentQuestions from '@/components/shared/FrequentQuestions.vue'
import MobileAppSection from '@/components/shared/MobileAppSection.vue'
import UserReviews from '@/components/integrate/landing/UserReviews.vue'
import UseCases from '@/components/integrate/landing/UseCases.vue'
import ThreeSteps from '@/components/shared/ThreeSteps.vue'
import PluginAPI from '@/components/integrate/landing/PluginAPI.vue'
import BannerCenter, { type IBannerCenterData } from '@/components/shared/BannerCenter.vue'
import PricingMain from '@/components/pricing/PricingMain.vue'
import Integrate_1 from '@/assets/images/svg/Integrate_1.svg'

const codeBannerData: IBannerCenterData = {
  title: 'Explore our documentation for integration guide & FAQs.'
}

const sixItemsData = {
  tag: 'Features',
  title: 'Easy integration to compile and run code, without leaving your platform',
  description:
    'Integrate plugins with 3 lines of HTML code into any LMS, blog, course material or platform',
  sixItems: [
    {
      icon: 'fa-cloud',
      heading: 'Online Code Execution Environment',
      body: 'JDoodle provides a secure code execution environment for multiple programming languages.'
    },
    {
      icon: 'fa-language',
      heading: 'Multi-Language Support',
      body: 'The Plugins support 76 programming languages, accommodating diverse curriculum needs.'
    },
    {
      icon: 'fa-rotate',
      heading: 'Real-Time Feedback',
      body: 'Students receive immediate feedback on code execution, enhancing active learning and effective debugging.'
    },
    {
      icon: 'fa-gear',
      heading: 'Customization and integration',
      body: 'The Plugins can be seamlessly integrated into existing platform and LMSs, tailored to fit specific requirements.'
    },
    {
      icon: 'fa-users',
      heading: 'Collaboration and Sharing',
      body: 'Multiple users can collaborate on the same code snippet, facilitating interactive coding experiences.'
    },
    {
      icon: 'fa-shield-halved',
      heading: 'Security and Privacy',
      body: 'JDoodle ensures code safety and data privacy through sandboxed execution environments.'
    }
  ]
}

const faqData: ISixItems[] = [
  {
    icon: 'fa-wallet',
    heading: 'What payment methods do you accept?',
    body: 'We accept the following payment methods for subscription: Credit card: Visa, Mastercard and American Express. We also accept PayPal and Bank transfer.'
  },
  {
    icon: 'fa-receipt',
    heading: 'What subscription options are available?',
    body: 'For APIs, we offer free, credits based and custom plans. For Plugins, we offer a Platform plan that includes free, team and custom plans. Please <RouterLink to="/contact-us" class="underline underline-offset-2">contact us</RouterLink> if you need a custom version.'
  },
  {
    icon: 'fa-circle-dollar-to-slot',
    heading: 'Is plan costs in USD?',
    body: 'All the costs are in USD.'
  },
  {
    icon: 'fa-shield-halved',
    heading: 'Are my credit card details safe?',
    body: 'Yes, absolutely. We use Stripe for all credit card processing and we do not store or handle your credit card details in our systems.'
  },
  {
    icon: 'fa-file-invoice-dollar',
    heading: 'What is the billing period?',
    body: 'When you subscribe to a paid plan for the first time, you are paying for the plan and your billing period starts from that date. Your credit card will be charged every month on that day.'
  },
  {
    icon: 'fa-file-lines',
    heading: 'How do I request a purchase order?',
    body: 'You can request a purchase order by contacting us. We will need your name, contact information, and the details of your purchase order. Once we have received your request, we will send you a PDF copy of the purchase order within 24 hours.'
  }
]

const integrateBanner: IGifBannerData = {
  title: 'Execute code within your platform',
  description:
    "Whether you're an educational institution, a technical blogger, an online course provider or a software product demonstrator, JDoodle's API and Plugins offer a wide array of benefits.",
  countAndFeatures: [
    {
      count: '1 Million',
      description: 'Monthly Users'
    },
    {
      count: '76+',
      description: 'Languages Supported'
    }
  ],
  image: {
    src: Integrate_1,
    alt: 'Integrate svg'
  }
}
</script>

<template>
  <div>
    <GifBanner :data="integrateBanner">
      <RouterLink type="button" class="btn btn-primary h-fit w-fit" to="/pricing">
        Integrate Now
        <FontAwesomeIcon icon="fa-arrow-right" class="ml-2" />
      </RouterLink>
    </GifBanner>

    <SlidingImages />
    <PluginAPI />

    <div class="section-primary pb-32 pt-12">
      <div class="mb-12 text-center">
        <span class="text-brand mb-4 block text-sm font-medium uppercase tracking-wider">{{
          sixItemsData.tag
        }}</span>
        <h2 class="heading-large m-auto mb-4 max-w-3xl">
          {{ sixItemsData.title }}
        </h2>
        <p class="text-secondary text-sm">
          {{ sixItemsData.description }}
        </p>
      </div>
      <SixItemsPanel :sixItems="sixItemsData.sixItems" />
    </div>

    <UseCases />
    <ThreeSteps />

    <BannerCenter :bannerData="codeBannerData">
      <a
        href="https://docs.jdoodle.com/integrating-compiler-ide-to-your-application"
        class="btn btn-primary mt-6 h-fit w-fit"
        >Find out</a
      >
    </BannerCenter>

    <PricingMain :showTabButtons="true" />
    <UserReviews />
    <FrequentQuestions :faqData="faqData" :document="true" />
    <MobileAppSection />
  </div>
</template>
