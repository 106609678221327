<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import settingService from '@/services/ide/settings/setting.service'
import { useIdeStore } from '@/stores/ide.store'
import ModelLayout from '@/layouts/ModelLayout.vue'
import { IDEVIEWMODELS } from '@/utils/models'
import editorService from '@/services/ide/editor.service'
import { IDECONSTANT } from '@/utils/ide'

const ideStore = useIdeStore()
const route = useRoute()

const downloadErrorTimeOut = ref<number | null>(null)
const downloadError = ref<string>('')
const downloadSuccessTimeOut = ref<number | null>(null)
const downloadSuccess = ref<string>('')

const isHtml = computed(() => {
  return ideStore.isHtml
})
const isCodeEditor = computed(() => {
  return ideStore.codeEditor !== null
})

const downloadName = computed(() => {
  let fileName: string | null = null
  let code: string | null = null
  if (isHtml.value) {
    fileName = 'jdoodle.html'
  } else {
    if (ideStore.codeEditor) {
      code = editorService?.getEditorSession(IDECONSTANT.CODE_EDITOR)?.getValue()
    } else code = ideStore.ideMeta?.code as string
    fileName = 'jdoodle.' + ideStore.ideMeta?.fileName
    if (ideStore.isLanguage === 'java') {
      // eslint-disable-next-line no-useless-escape
      const regex = /(public)([\s]*)(class)([\s]*)([\w\$]*)([\s]*)({)/
      const match = regex.exec(code as string)
      if (match && match.length >= 5) {
        fileName = match[5] + '.java'
      } else {
        fileName = 'jdoodle.java'
      }
    }
  }
  return fileName
})
/**
 * Copy the project script to the  to clipboard
 */
const download = () => {
  settingService
    .download(IDECONSTANT.DOWNLOAD_CODE_EDITOR)
    .then((res) => {
      if (res) {
        downloadSuccess.value = 'Downloaded Successfully'
      }
    })
    .catch((err) => {
      downloadError.value = err
    })
}
/**
 * Initialize the copy editor
 */
const initEditor = () => {
  settingService.initDownloadEditor()
}

onMounted(() => {
  initEditor()
  watch(route, () => {
    initEditor()
  })
  watch(downloadError, () => {
    if (downloadError.value) {
      if (downloadErrorTimeOut.value) clearTimeout(downloadErrorTimeOut.value)
      downloadErrorTimeOut.value = setTimeout(() => {
        downloadError.value = ''
      }, 5000)
    }
  })
  watch(downloadSuccess, () => {
    if (downloadSuccess.value) {
      if (downloadSuccessTimeOut.value) clearTimeout(downloadSuccessTimeOut.value)
      downloadSuccessTimeOut.value = setTimeout(() => {
        downloadSuccess.value = ''
      }, 5000)
    }
  })
})
</script>

<template>
  <ModelLayout title="Save (to local file)" :hs="IDEVIEWMODELS.DOWNLOAD">
    <div v-show="isCodeEditor || isHtml">
      <p v-if="downloadName" class="p-small">{{ downloadName }}</p>
      <div
        :id="IDECONSTANT.DOWNLOAD_CODE_EDITOR"
        class="editor block h-36 w-full min-w-full rounded-lg"
      ></div>
      <div class="flex w-full gap-2">
        <button class="btn-primary btn-rounded-md" @click="download">Download</button>
        <button
          class="btn-secondary btn-rounded-md"
          :data-hs-overlay="`#${IDEVIEWMODELS.DOWNLOAD}`"
          data-hs-overlay-close
        >
          Close
        </button>
      </div>
      <p :class="['p-xsmall ', 'error', , { hidden: !downloadError }]">
        {{ downloadError }}
      </p>
      <p :class="['p-xsmall', 'success', { hidden: !downloadSuccess }]">
        {{ downloadSuccess }}
      </p>
    </div>
    <div v-if="!isCodeEditor && !isHtml">
      <p class="p-normal">Please open a file to Download</p>
      <button
        class="btn-secondary btn-rounded-md"
        :data-hs-overlay="`#${IDEVIEWMODELS.DOWNLOAD}`"
        data-hs-overlay-close
      >
        Close
      </button>
    </div>
  </ModelLayout>
</template>
