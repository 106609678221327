<script setup lang="ts">
import { RouterLink } from 'vue-router'
import MegaMenu from '@/components/header/navigation/MegaMenu.vue'

interface ILinkItem {
  title: string
  routeName: string

  categories: {
    name: string
    path: string
  }[]
}

const LinkItems: ILinkItem[] = [
  {
    title: 'Learn',
    routeName: 'intergrate-online-ide-compiler-api-plugins',

    categories: [
      { name: 'Documentation', path: '' },
      { name: 'Tutorials', path: 'tutorials' },
      { name: 'Blogs', path: 'blog' }
    ]
  },
  {
    title: 'Company',
    routeName: 'online-assessment-teaching',
    categories: [
      { name: 'About JDoodle', path: 'about-us' },
      { name: 'Contact Us', path: 'contact-us' }
    ]
  }
]
</script>

<template>
  <MegaMenu>
    <div
      class="flex w-full flex-col justify-start gap-6 pt-6 sm:gap-20 sm:pl-0 sm:pt-0 md:flex-row"
    >
      <div v-for="(links, index) of LinkItems" :key="index" class="pr-8">
        <div class="flex flex-col gap-4 text-base">
          <h3 class="text-primary font-medium uppercase tracking-wider dark:text-text-primary-dark">
            {{ links.title }}
          </h3>
          <div v-for="(category, index) of links.categories" :key="index">
            <a
              v-if="category.name === 'Documentation'"
              href="https://docs.jdoodle.com/"
              target="_blank"
              class="link-primary font-light"
              >Documentation</a
            >
            <RouterLink
              v-else
              class="link-primary text-justify font-light sm:block"
              :to="`/${category.path}`"
            >
              {{ category.name }}
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </MegaMenu>
</template>
