import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useDashboardPluginStore = defineStore('dashboardplugin', () => {
  const plugins = ref<any[] | null>(null)
  const title = ref<string>('')

  const pluginList = computed(() => {
    return plugins.value
  })
  /**
   * Set plugin list
   * @param value plugin list
   */
  const setPluginList = (value: any[] | null) => {
    plugins.value = value
  }
  /**
   * Set title
   * @param value title
   */
  const setTitle = (value: string) => {
    title.value = value
  }

  return {
    pluginList,
    setPluginList,
    title,
    setTitle
  }
})
