import { PLATFORM_PLAN_VALUE } from '@/components/shared/subscription/enum/subscription.enum'

export interface IApiPlan {
  name: string
  value: string
  credits: string
}

export interface IBundledPlan {
  name: string
  value: string
  type?: string
}

export const apiPlansData: IApiPlan[] = [
  { name: 'Free', value: 'Free', credits: '200' },
  { name: '$10', value: '$10', credits: '1k' },
  { name: '$20', value: '$20', credits: '2k' },
  { name: '$50', value: '$50', credits: '5k' },
  { name: '$100', value: '$100', credits: '10k' },
  { name: '$200', value: '$200', credits: '20k' },
  { name: '$300', value: '$300', credits: '30k' },
  { name: '$400', value: '$400', credits: '40k' },
  { name: '$500', value: '$500', credits: '50k' },
  { name: 'Unlimited', value: '$990', credits: 'Unlimited' }
]

//TODO: Delete this in the future
export const ide_teach_plansData: IBundledPlan[] = [
  { name: 'Free', value: PLATFORM_PLAN_VALUE.FREE },
  { name: 'Pro', value: PLATFORM_PLAN_VALUE.PRO }
]

export const pluginPlansData: IBundledPlan[] = [
  { name: 'pFree', value: '$0', type: 'Free' },
  { name: 'p100', value: '$100', type: 'Team' }
]
