//@ts-ignore-next-line
import SockJS from 'sockjs-client/dist/sockjs'
import Stomp from 'webstomp-client'

import { IDECONSTANT, SERVER_ERROR } from '@/utils/ide'

// import gaService from '@/services/ga.service'
import editorService from '@/services/ide/editor.service'
import { type IExecuteRequest } from '@/services/ide/execute.service'

import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'

/**
 * On ws connection failed
 */
const onWsConnectionFailed = () => {
  useIdeStore().setisCodeExecuting(false)
  editorService.insertEditorSession(
    IDECONSTANT.OUTPUT_EDITOR,
    'Connection to server lost.\n It is possible your browser or internet connection may not support the ' +
      'Interactive mode.\n Please try again, or try Non-Interactive mode. Alternatively contact JDoodle ' +
      'support at hello@jdoodle.com.'
  )
}
/**
 * On ws end
 */
// const postInteractiveExecute = (startTime: number = 0) => {
const postInteractiveExecute = () => {
  useIdeStore().socketClient.disconnect()
  useIdeStore().setisCodeExecuting(false)
  // gaService.calculateAndSendExecuteEndTime(startTime, useIdeStore().isLanguage, 'execute-i')
}
/**
 * On ws connection
 * @param requestData - The request data
 */
const onWsConnection = (requestData: IExecuteRequest | null) => {
  useIdeStore().socketClient.subscribe('/user/queue/execute-i', (message: any) => {
    const msgId = message.headers['message-id']
    const msgSeq = parseInt(msgId.substring(msgId.lastIndexOf('-') + 1))

    const statusCode = parseInt(message.headers.statusCode)

    if (statusCode === 201) {
      useIdeStore().setwsNextId(msgSeq + 1)
      return
    }

    let t0 = 0
    try {
      t0 = performance.now()
      while (performance.now() - t0 < 2500 && useIdeStore().isWsNextId !== msgSeq) {
        // eslint-disable-next-line no-empty
      }
    } catch (e) {
      console.error(e)
    }

    if (statusCode === 204) {
      useIdeStore().setExecutionqTime(message.body)
      postInteractiveExecute()
    } else if (statusCode === 500) {
      editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, SERVER_ERROR)
      useIdeStore().setisCodeExecuting(false)
    } else if (statusCode === 206) {
      useIdeStore().setOutputFiles(JSON.parse(message.body))
    } else if (statusCode === 403) {
      useAuthStore().clearRobotCheck()
      editorService.insertEditorSession(IDECONSTANT.OUTPUT_EDITOR, message.body)
    } else if (statusCode !== 410) {
      editorService.insertEditorSession(IDECONSTANT.OUTPUT_EDITOR, message.body)
    }
    useIdeStore().setwsNextId(msgSeq + 1)
  })

  useIdeStore().socketClient.send('/app/execute-i', JSON.stringify(requestData), {
    message_type: 'execute'
  })
  editorService.forcusEditor(IDECONSTANT.OUTPUT_EDITOR)
}
/**
 * Execute interactive with ws
 * @param requestData - The request data
 */
const executeInteractive = (requestData: IExecuteRequest | null) => {
  if (useIdeStore().socketClient) {
    useIdeStore().socketClient.disconnect()
    useIdeStore().socketClient = null
  }
  useIdeStore().socketClient = Stomp.over(new SockJS('/engine/stomp'), {
    heartbeat: false,
    debug: false
  })

  editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, '')

  useIdeStore().wsNextId = 0

  // const startTime = gaService.getCurrentTime()

  useIdeStore().socketClient?.connect(
    {},
    () => {
      useIdeStore().setSocketConnected(true)
      onWsConnection(requestData)
    },
    onWsConnectionFailed
  )
}
/**
 * stop when user click stop button
 */
const stopExecuteInteractive = () => {
  useIdeStore().socketClient.disconnect(() => {
    useIdeStore().setSocketConnected(false)
    useIdeStore().setisCodeExecuting(false)
  })
}
/**
 * On keyup output edition
 * called when editer setup at editor service onKeyupOutputEditor
 * @param event - The event
 */
const onKeyupOutputEditor = (event: KeyboardEvent) => {
  if (
    useIdeStore().interactiveMode &&
    useIdeStore().isCodeExecuting &&
    useIdeStore().socketClient
  ) {
    let key = event.key
    if (event.key === 'Enter') {
      key = '\n'
    }
    if (useIdeStore().socketClient && useIdeStore().socketClient.connected)
      useIdeStore().socketClient.send('/app/execute-i', key, { message_type: 'input' })
  }
}

export default { executeInteractive, stopExecuteInteractive, onKeyupOutputEditor }
