declare global {
  interface Window {
    ace: any
    MSStream: any
  }
  interface ace {
    Editor: any
  }
}
import loadScriptInBody from '@/services/loadScriptInBody.service'
import { IDECONSTANT } from '@/utils/ide'

import executeService from '@/services/ide/execute.service'
import projectTreeService from '@/services/ide/projectTree.service'
import wsService from '@/services/ide/ws.service'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import { debounce } from 'lodash'

import { useDark } from '@vueuse/core'
/**
 * get editor
 * @param key - the key of the editor
 * @returns the editor
 */
const getEditor = (key: (typeof IDECONSTANT)[keyof typeof IDECONSTANT]) => {
  return window.ace.edit(key)
}
/**
 * focus the editor
 * @param key - the key of the editor
 */
const forcusEditor = (key: (typeof IDECONSTANT)[keyof typeof IDECONSTANT]) => {
  getEditor(key).focus()
}
/**
 * get the editor session
 * @param key - the key of the editor
 * @returns the editor session
 */
const getEditorSession = (key: (typeof IDECONSTANT)[keyof typeof IDECONSTANT]) => {
  return getEditor(key).getSession()
}
/**
 * set the editor value
 * @param key - the key of the editor
 * @param value - the value to set
 */
const setEditorSession = (key: (typeof IDECONSTANT)[keyof typeof IDECONSTANT], value: string) => {
  getEditor(key).getSession().setValue(value)
  if (key === IDECONSTANT.CODE_EDITOR || key === IDECONSTANT.OUTPUT_EDITOR) {
    heightChangeFunction(key)
  }
}
/**
 * insert the value to the editor
 * @param key - the key of the editor
 * @param value - the value to insert
 */
const insertEditorSession = (
  key: (typeof IDECONSTANT)[keyof typeof IDECONSTANT],
  value: string
) => {
  getEditor(key).insert(value.toString())
}
/**
 * resize when the window is resized or fullscreen is toggled or keyup
 */
const resizeOutputEditor = () => {
  if (!useIdeStore().outputEditor) return
  heightChangeFunction(IDECONSTANT.OUTPUT_EDITOR)
}
const debouncedResizeOutputEditor = debounce(resizeOutputEditor, 100)
/**
 * resize when the window is resized or fullscreen is toggled or keyup
 */
const resizeCodeEditor = () => {
  if (!useIdeStore().codeEditor) return
  heightChangeFunction(IDECONSTANT.CODE_EDITOR)
}
const debouncedResizeCodeEditor = debounce(resizeCodeEditor, 100)
/**
 * update the editor height
 * @param key - the key of the editor
 */
const heightChangeFunction = (key: (typeof IDECONSTANT)[keyof typeof IDECONSTANT]) => {
  const isPrintBlocker = useIdeStore().isPrintBlocker
  const editor = getEditor(key)
  let newHeight =
    editor.getSession().getScreenLength() * editor.renderer.lineHeight +
    editor.renderer.scrollBar.getWidth() +
    20
  let minHeight = 80
  if (key === IDECONSTANT.CODE_EDITOR && !isPrintBlocker) {
    //get parent on #ideCodeEditor
    document.getElementById(key)?.style.setProperty('height', `0px`)
    document.getElementById(key)?.style.setProperty('min-height', `0px`)
    const parent = document.getElementById('ideCodeEditor')
    const parentHeight = parent?.clientHeight || 256
    if (
      useIdeStore().isEmbedded ||
      (usePluginStore().isPlugin && !usePluginStore().isCusomPlugin)
    ) {
      newHeight = 384
      minHeight = 384
    } else {
      if (usePluginStore().isCusomPlugin) {
        newHeight = parentHeight
        minHeight = parentHeight
      } else if (useIdeStore().isBlockly) {
        if (useIdeStore().isFullScreen) {
          newHeight = parentHeight - 36
          minHeight = parentHeight - 36
        } else {
          newHeight = parentHeight
          minHeight = parentHeight
          if (!useIdeStore().isblocklyExpand) {
            newHeight = 292
            minHeight = 292
          }
        }
      } else {
        if (useIdeStore().isFullScreen) {
          newHeight = parentHeight - 36
          minHeight = parentHeight - 36
        } else {
          minHeight = 292
        }
      }
    }
  }
  if (key === IDECONSTANT.OUTPUT_EDITOR && !isPrintBlocker) {
    //get parent on #ideOutputEditor
    document.getElementById(key)?.style.setProperty('height', `0px`)
    document.getElementById(key)?.style.setProperty('min-height', `0px`)
    minHeight = 114
    if (
      useIdeStore().isEmbedded ||
      (usePluginStore().isPlugin && !usePluginStore().isCusomPlugin)
    ) {
      newHeight = 114
      minHeight = 114
    } else {
      if (!useIdeStore().isGuru) {
        if (useIdeStore().isFullScreen) {
          const parent = document.getElementById('ideOutputEditor')
          const parentHeight = parent?.clientHeight
          newHeight = parentHeight
        }
      }
    }
  }
  document.getElementById(key)?.style.setProperty('height', `${newHeight}px`)
  document.getElementById(key)?.style.setProperty('min-height', `${minHeight}px`)
  // Scroll to end solves jump bug in non-fullscreen mode.
  // document.getElementById(key)?.scrollIntoView({ block: 'end' })
  editor.resize()
}
/**
 * set the font size of the editor
 * @param size - the size to set
 */
const setFontSize = (size?: number) => {
  if (size) {
    if (!usePluginStore().isPlugin && !useIdeStore().isEmbedded) {
      localStorage.setItem(IDECONSTANT.LOCALSTORAGE_FONT_SIZE_NAME, size.toString())
    }
    useIdeStore().setFontSize(size)
  } else {
    if (!usePluginStore().isPlugin && !useIdeStore().isEmbedded) {
      if (localStorage.getItem(IDECONSTANT.LOCALSTORAGE_FONT_SIZE_NAME)) {
        useIdeStore().setFontSize(
          Number(localStorage.getItem(IDECONSTANT.LOCALSTORAGE_FONT_SIZE_NAME))
        )
      }
    }
  }
  if (useIdeStore().codeEditor && useIdeStore().fontSize) {
    useIdeStore().codeEditor.setFontSize(`${useIdeStore().fontSize}px`)
    resizeCodeEditor()
  }
}
/**
 * Initialize the ide everytime the route changes
 */
const resetCodeEditor = () => {
  const codeEditor = document.querySelector(`#${IDECONSTANT.CODE_EDITOR}`)
  if (window.ace && codeEditor) {
    setEditorSession(IDECONSTANT.CODE_EDITOR, '')
  }
  const outputEditor = document.querySelector(`#${IDECONSTANT.OUTPUT_EDITOR}`)
  if (window.ace && outputEditor) {
    setEditorSession(IDECONSTANT.OUTPUT_EDITOR, '')
  }
  useIdeStore().resetEditor()
}
/**
 * reset output editor when the route changes
 */
const resetOutputEditor = () => {
  useIdeStore().outputEditor.getSession().setValue('')
  useIdeStore().resetExecutionTime()
}
/**
 * set code editor sample script
 */
const codeEditorSetSampleScript = () => {
  if (useIdeStore().isGuru) return
  if (useIdeStore().routeMeta?.sampleScript && useIdeStore().routeMeta?.language !== 'blockly') {
    useIdeStore().codeEditor.getSession().setValue(useIdeStore().routeMeta?.sampleScript)
  }
}
/**
 * Set code editor language
 * @param language - The language to set
 */
const codeEditorChangeLanguage = (language: string) => {
  useIdeStore().codeEditor.getSession().setMode(`ace/mode/${language}`)
  if (language === 'whitespace') {
    useIdeStore().codeEditor.setShowInvisibles(true)
  } else {
    useIdeStore().codeEditor.setShowInvisibles(false)
  }
}
/**
 * Set code editor language
 */
const codeEditorSetLanguage = () => {
  if (useIdeStore().isAceLanguageCode) {
    codeEditorChangeLanguage(useIdeStore().isAceLanguageCode as string)
  }
}
/**
 * Set code editors theme
 */
const codeEditorsSetTheme = () => {
  const isDark = usePluginStore().isPlugin || useIdeStore().isEmbedded ? false : useDark().value
  if (isDark) {
    if (useIdeStore().codeEditor) useIdeStore().codeEditor.setTheme('ace/theme/gruvbox')
    if (useIdeStore().projectEditor) {
      useIdeStore().projectEditor.setTheme('ace/theme/gruvbox')
    }
    if (useIdeStore().executeCodeEditor) {
      useIdeStore().executeCodeEditor.setTheme('ace/theme/gruvbox')
    }
    if (useIdeStore().copyEditor) {
      useIdeStore().copyEditor.setTheme('ace/theme/gruvbox')
    }
    if (useIdeStore().downloadEditor) {
      useIdeStore().downloadEditor.setTheme('ace/theme/gruvbox')
    }
    if (useIdeStore().openEditor) {
      useIdeStore().openEditor.setTheme('ace/theme/gruvbox')
    }
  } else {
    if (useIdeStore().codeEditor) useIdeStore().codeEditor.setTheme('ace/theme/xcode')
    if (useIdeStore().projectEditor) {
      useIdeStore().projectEditor.setTheme('ace/theme/xcode')
    }
    if (useIdeStore().executeCodeEditor) {
      useIdeStore().executeCodeEditor.setTheme('ace/theme/xcode')
    }
    if (useIdeStore().copyEditor) {
      useIdeStore().copyEditor.setTheme('ace/theme/xcode')
    }
    if (useIdeStore().downloadEditor) {
      useIdeStore().downloadEditor.setTheme('ace/theme/xcode')
    }
    if (useIdeStore().openEditor) {
      useIdeStore().openEditor.setTheme('ace/theme/xcode')
    }
  }
  if (useIdeStore().executeOutputEditor)
    useIdeStore().executeOutputEditor.setTheme('ace/theme/gruvbox')
  if (useIdeStore().outputEditor) useIdeStore().outputEditor.setTheme('ace/theme/gruvbox')
}
/**
 * Initialize the ace editor
 * @param id - The id of the ace editor to initialize
 * @returns The ace editor
 */
const initAceEditor = (id: (typeof IDECONSTANT)[keyof typeof IDECONSTANT]) => {
  window.ace.require('ace/ext/language_tools')
  window.ace.config.set('basePath', '/assets/javascript/ace')

  if (window?.ace?.edit(id)) {
    window?.ace?.edit(id).destroy()
  }

  const editor: ace['Editor'] = window.ace.edit(id)

  if (useIdeStore().isAceLanguageCode) {
    if (id === IDECONSTANT.OUTPUT_EDITOR || id === IDECONSTANT.EXECUTE_OUTPUT_EDITOR) {
      editor.getSession().setMode('ace/mode/text')
    } else {
      editor.getSession().setMode(`ace/mode/${useIdeStore().isAceLanguageCode}`)
    }
  }

  editor.renderer.setShowGutter(false)
  editor.renderer.setAnimatedScroll(true)
  editor.renderer.setShowPrintMargin(false)

  editor.setOptions({
    enableBasicAutocompletion: true,
    enableSnippets: true,
    enableLiveAutocompletion: false
  })
  editor.$blockScrolling = Infinity

  return editor
}
/**
 * On keyup code editor
 */
const onKeyupOutputEditor = () => {
  getEditor(IDECONSTANT.OUTPUT_EDITOR)
    .textInput.getElement()
    .addEventListener('keyup', (event: KeyboardEvent) => {
      wsService.onKeyupOutputEditor(event)
    })
}
/**
 * Initialize the output editor
 */
const initOutputEditor = () => {
  useIdeStore().outputEditor = initAceEditor(IDECONSTANT.OUTPUT_EDITOR)
  useIdeStore().outputEditor.renderer.setPadding(20)
  useIdeStore().outputEditor.renderer.setScrollMargin(20, 20)
  useIdeStore().outputEditor.setDisplayIndentGuides(false)
  useIdeStore().outputEditor.setHighlightActiveLine(false)
  onKeyupOutputEditor()
  onParentOutputEditorResize()
}
/**
 * On keyup code editor
 */
const onKeyupCodeEditor = () => {
  getEditor(IDECONSTANT.CODE_EDITOR)
    .textInput.getElement()
    .addEventListener('keyup', (event: KeyboardEvent) => {
      useIdeStore().setCodeUpdated(true)
      // Ctrl + Enter to execute code
      if (event.ctrlKey && event.key === 'Enter') {
        executeService.tryExecute()
      }
      if (useIdeStore().isAdvanced) {
        projectTreeService.setCodeChanged()
      }
    })
}
/**
 * On parent code editor resize
 */
const onParentCodeEditorResize = () => {
  const parent = document.getElementById('ideCodeEditor')?.parentNode
  //if any child of parent is changed except the code editor with id code, resize the code editor
  if (parent) {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          const addedNodes = Array.from(mutation.addedNodes)
          const isCodeEditorChanged = addedNodes.some(
            (node) => (node as HTMLElement).id === IDECONSTANT.CODE_EDITOR
          )
          if (!isCodeEditorChanged) {
            debouncedResizeCodeEditor()
          }
        }
      }
    })

    observer.observe(parent, {
      childList: true,
      subtree: true
    })
  }
}

/**
 * On parent code editor resize
 */
const onParentOutputEditorResize = () => {
  const parent = document.getElementById('ideOutputEditor')?.parentNode
  //if any child of parent is changed except the code editor with id code, resize the code editor
  if (parent) {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          const addedNodes = Array.from(mutation.addedNodes)
          const isOutputEditorChanged = addedNodes.some(
            (node) => (node as HTMLElement).id === IDECONSTANT.OUTPUT_EDITOR
          )
          if (!isOutputEditorChanged) {
            debouncedResizeOutputEditor()
          }
        }
      }
    })

    observer.observe(parent, {
      childList: true,
      subtree: true
    })
  }
}

/**
 * Initialize the ace code editor
 */
const initCodeEditor = () => {
  useIdeStore().codeEditor = initAceEditor(IDECONSTANT.CODE_EDITOR)
  useIdeStore().codeEditor.renderer.setShowGutter(true)
  codeEditorSetSampleScript()
  onKeyupCodeEditor()
  setFontSize()
  onParentCodeEditorResize()
}
/**
 * if the project is advanced, split the ide
 */
const splitIde = () => {
  if (useIdeStore().ideSplit) {
    useIdeStore().ideSplit.destroy()
    useIdeStore().ideSplit = null
  }
  if (useIdeStore().isAdvanced) {
    //NOT IN USE due to separate of component to inside IDE.vue
    // useIdeStore().ideSplit = Split(['#ideProjectTree', '#ideCodeEditor'], {
    //   sizes: [0, 100],
    //   minSize: [0, 500]
    // })
  }
}
/**
 * Initialize the editor when router changes
 * @param count - The count of the init
 * @returns null
 */
const initEditors = async (count: number = 0) => {
  if (!useIdeStore().isWindowAce) {
    if (count > 10) {
      return null
    } else {
      await new Promise((resolve) => setTimeout(resolve, 100))
      initEditors(count + 1)
    }
  } else {
    if (useIdeStore().codeEditor) {
      codeEditorSetSampleScript()
      codeEditorSetLanguage()
    } else {
      initCodeEditor()
    }

    if (useIdeStore().outputEditor) {
      resetOutputEditor()
    } else {
      initOutputEditor()
    }
    codeEditorsSetTheme()
    useIdeStore().initVersionIndex()
    await new Promise((resolve) => setTimeout(resolve, 100))
  }
}
/**
 * Fix ace editor bug on ios
 */
const fixAceeIOSBug = () => {
  if (
    typeof navigator === 'object' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
    !window.MSStream
  ) {
    window.MSStream = {}
  }
}
/**
 * split and init the ide
 */
const splitAndInit = async () => {
  splitIde()
  await new Promise((resolve) => setTimeout(resolve, 500))
  initEditors()
}
/**
 * Initialize the code editor
 */
const injectAceAndSplit = () => {
  fixAceeIOSBug()
  loadScriptInBody.loadScriptInBody('/assets/javascript/ace.min.js').then(async () => {
    loadScriptInBody.loadScriptInBody('/assets/javascript/ext-language_tools.js')
    loadScriptInBody.loadScriptInBody('/assets/javascript/ext-static_highlight.js')

    if (!useIdeStore().isShared) {
      splitAndInit()
    }
  })
}

/**
 * Initialize the embed code editor
 */
const injectAce = () => {
  fixAceeIOSBug()
  // inject nessary scripts
  loadScriptInBody.loadScriptInBody('/assets/javascript/ace.min.js').then(async () => {
    loadScriptInBody.loadScriptInBody('/assets/javascript/ext-language_tools.js')
    loadScriptInBody.loadScriptInBody('/assets/javascript/ext-static_highlight.js')
  })
}
/**
 * Eject the code editor
 */
const ejectAce = () => {
  if (window.ace) {
    loadScriptInBody.unloadScriptInBody('/assets/javascript/ace.min.js')
    loadScriptInBody.unloadScriptInBody('/assets/javascript/ext-language_tools.js')
    loadScriptInBody.unloadScriptInBody('/assets/javascript/ext-static_highlight.js')
    window.ace = null
  }
}

/**
 * post set script
 * @param script - the script to set
 * @param count - the count
 * @returns null
 */
const postSetScript = async (script: string, count: number = 0) => {
  if (!useIdeStore().isWindowAce) {
    if (count > 10) {
      return null
    } else {
      await new Promise((resolve) => setTimeout(resolve, 600))
      postSetScript(script, count + 1)
    }
  } else {
    setEditorSession(IDECONSTANT.CODE_EDITOR, script)
  }
}
export default {
  setFontSize,
  heightChangeFunction,
  ejectAce,
  injectAceAndSplit,
  injectAce,
  splitAndInit,
  splitIde,
  initEditors,
  resizeCodeEditor,
  resizeOutputEditor,
  getEditor,
  getEditorSession,
  setEditorSession,
  forcusEditor,
  insertEditorSession,
  codeEditorChangeLanguage,
  codeEditorsSetTheme,
  resetCodeEditor,
  initAceEditor,
  postSetScript
}
