<script setup lang="ts">
import BaseInput from '@/components/forms/BaseInput.vue'
import BaseTextbox from '@/components/forms/BaseTextbox.vue'
import BaseSelect from '@/components/forms/BaseSelect.vue'
import { useField, useForm } from 'vee-validate'
import { boolean, number, string } from 'yup'
import { type PropType, computed, watch, ref } from 'vue'
import { HttpStatusCode } from 'axios'
import { type ILanguage, noneLanguage } from '@/utils/sharedData/languages'
import { type ISelectListItem } from '@/components/forms/ISelectListItem'
import { TEACH_CONSTANTS } from '@/components/guru/constants/teach.constants'
import BaseCheck from '@/components/forms/BaseCheck.vue'
import {
  GURU_ASSIGNMENT_ACTIONS,
  GURU_ASSIGNMENT_RESULT_TYPE
} from '@/components/guru/enums/teach.enum'
import { cloneAssignment, createAssignment, editAssignment } from '@/services/teach.service'
import type { ICreateAssignmentRequest } from '@/components/guru/interface/requests.interface'
import { useRoute } from 'vue-router'
import { FORM_SELECT_VALUE_TYPE } from '@/components/forms/form.enum'
import type IAssignmentDetailed from '@/components/guru/interface/IAssignmentDetailed'
import router from '@/router'
import { useTeachStore } from '@/stores/teach.store'

const teachStore = useTeachStore()

//Props
const props = defineProps({
  assignment: {
    type: Object as PropType<IAssignmentDetailed>,
    required: false,
    default: undefined
  },
  assignmentId: {
    type: Number,
    required: false
  },
  mode: {
    type: String as PropType<GURU_ASSIGNMENT_ACTIONS>,
    required: false
  },
  insCode: {
    type: String,
    required: false
  }
})

//Data for dropdowns
const langData: ILanguage[] = [noneLanguage, ...teachStore.languagesSupportedByAssignment]
const versionData = ref(undefined as undefined | any[])
const resultTypes: ISelectListItem[] = TEACH_CONSTANTS.ASSIGNMENT_RESULT_TYPES
const discloseTypes: ISelectListItem[] = TEACH_CONSTANTS.DISCLOSE_TYPES
const showDiscloseSection = ref(false)

const route = useRoute()
const instituteCode = computed(() => {
  if (props.insCode) {
    return props.insCode
  }
  return route.params.insCode as string
})
//Static Data
const titleInput = {
  label: 'Title',
  placeholder: 'Title'
}

const descInput = {
  label: 'Description',
  placeholder: 'Details about the assignment.'
}

const defaultLanguageInput = {
  label: 'Default Language'
}

const versionIndexInput = {
  label: 'Version'
}

const resultTypeInput = {
  label: 'Result Type'
}

const autoCorrectCheckInput = {
  label: 'Auto Correct'
}

const autoScoreInput = {
  label: 'Auto Score/Mark'
}

const discloseInput = {
  label: 'Disclose Result'
}

const enableNegativeMarkInput = {
  label: 'Enable Negative Marking'
}

const isPublicInput = {
  label: 'Is Public Assignment'
}

// Form-related
const { errors, handleSubmit } = useForm()

const autoScoreNote =
  'Note: Auto Score/Mark and Disclose is only applicable for systematically validatable questions only. Open Ended questions and questions excluded from auto correction will not be auto scored.'

const { value: title, errorMessage: titleError } = useField('title', string().required().min(5))

const { value: description, errorMessage: descriptionError } = useField(
  'description',
  string().required().min(5)
)

const { value: defaultLanguage, errorMessage: defaultLanguageError } = useField(
  'defaultLanguage',
  string()
)

const { value: versionIndex, errorMessage: versionIndexError } = useField(
  'versionIndex',
  string().nullable()
)

const { value: resultType, errorMessage: resultTypeError } = useField('resultType', number())

const { value: autoCorrect, errorMessage: autoCorrectError } = useField('autoCorrect', boolean())

const { value: autoScore, errorMessage: autoScoreError } = useField('autoScore', boolean())

const { value: disclose, errorMessage: discloseError } = useField('disclose')

const { value: enableNegativeMark, errorMessage: enableNegativeMarkError } = useField(
  'enableNegativeMark',
  boolean()
)

const { value: isPublic, errorMessage: isPublicError } = useField('isPublic', boolean().nullable())

const { value: cloneStaffs, errorMessage: cloneStaffsError } = useField('cloneStaffs', boolean())

// unused-vars cloneStudentsError
const { value: cloneStudents } = useField('cloneStudents', boolean())

//Default values
defaultLanguage.value = noneLanguage.language
versionIndex.value = 0
resultType.value = resultTypes[0].value
autoCorrect.value = false
autoScore.value = false
disclose.value = discloseTypes[0].value
enableNegativeMark.value = false
isPublic.value = false
cloneStaffs.value = false
cloneStudents.value = false

const currentMode = ref(GURU_ASSIGNMENT_ACTIONS.ADD as GURU_ASSIGNMENT_ACTIONS | undefined)

// Watched Values
// Assignment object
watch(
  () => props.assignment,
  (existingAssignment) => {
    if (existingAssignment == undefined) {
      currentMode.value = GURU_ASSIGNMENT_ACTIONS.ADD
    } else {
      title.value = existingAssignment?.title
      description.value = existingAssignment?.description
      defaultLanguage.value = existingAssignment?.defaultLanguage
      versionIndex.value = existingAssignment?.versionIndex
      resultType.value = existingAssignment?.resultType
      autoCorrect.value = existingAssignment?.autoCorrect
      autoScore.value = existingAssignment?.autoScore
      disclose.value = existingAssignment?.disclose
      enableNegativeMark.value = existingAssignment?.enableNegativeMark
      isPublic.value =
        existingAssignment?.isPublic == undefined ? null : existingAssignment?.isPublic
    }
  }
)

//Get version values and default version
watch(
  () => defaultLanguage.value,
  (selectedLang) => {
    versionData.value = langData.find((lang: ILanguage) => {
      return lang.language === selectedLang
    })?.versions

    versionIndex.value = 0
  }
)

// Check values to show/hide Disclose Section
watch(
  () => [resultType.value, autoCorrect.value, autoScore.value],
  () => {
    showDiscloseSection.value = getShowDiscloseSection()
  }
)

//Get version values and default version
watch(
  () => props.mode,
  (selectedMode) => {
    currentMode.value = selectedMode
  }
)

//isPublic must be false if Disclose = Manual
watch(
  () => disclose.value,
  (selectedDisclose) => {
    if (selectedDisclose == 0 || selectedDisclose == '0') {
      isPublic.value = false
    }
  }
)

/**
 * @returns modal title
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getModalTitle() {
  if (currentMode.value === GURU_ASSIGNMENT_ACTIONS.ADD) {
    return 'Create Assignment'
  } else if (currentMode.value === GURU_ASSIGNMENT_ACTIONS.CLONE) {
    return 'Clone Assignment'
  }

  //Edit Assignment
  return 'Edit Assignment'
}

/**
 * @returns button text
 */
function getButtonText() {
  if (currentMode.value === GURU_ASSIGNMENT_ACTIONS.ADD) {
    return 'Create Assignment'
  } else if (currentMode.value === GURU_ASSIGNMENT_ACTIONS.CLONE) {
    return 'Clone Assignment'
  }
  //Edit Mode
  return 'Save Changes'
}

/**
 * @returns boolean
 */
const getShowDiscloseSection = (): boolean => {
  if (
    (autoCorrect.value && resultType.value === GURU_ASSIGNMENT_RESULT_TYPE.COMMENT_ONLY) ||
    (autoCorrect.value && autoScore.value)
  ) {
    return true
  }
  return false
}

const showAutoMark = computed(() => {
  return autoCorrect.value == true
})

const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0
})

/**
 *
 */
const showIsPublicCheckbox = computed(() => {
  return disclose.value === TEACH_CONSTANTS.DISCLOSE_TYPES[1].value || disclose.value == '1'
})

const onSubmit = handleSubmit(async (values) => {
  //Create
  const { cloneStaffs, cloneStudents, ...rest } = values
  if (currentMode.value === GURU_ASSIGNMENT_ACTIONS.ADD) {
    const res = await createAssignment({
      instituteCode: instituteCode.value,
      ...rest
    } as ICreateAssignmentRequest)

    if (res.status == HttpStatusCode.Ok) {
      router.push(
        `/dashboard/institution/${instituteCode.value}/assignment/${
          (res.data.assignment as IAssignmentDetailed).insAssignmentId
        }`
      )
      // .then(() => router.go(0))
    }
  } else if (currentMode.value === GURU_ASSIGNMENT_ACTIONS.EDIT) {
    //Submit

    const res = await editAssignment({
      instituteCode: instituteCode.value,
      assignmentId: props.assignmentId,
      ...rest
    } as ICreateAssignmentRequest)

    if (res.status == HttpStatusCode.Ok) {
      router.go(0)
    }
  } else if (currentMode.value === GURU_ASSIGNMENT_ACTIONS.CLONE) {
    const res = await cloneAssignment({
      instituteCode: instituteCode.value,
      ...rest,
      cloneStaffs: cloneStaffs, //TODO: CHECK THIS
      cloneStudents: cloneStudents,
      assignmentId: props.assignmentId
    } as ICreateAssignmentRequest)

    if (res.status == HttpStatusCode.Ok) {
      router
        .push(
          `/dashboard/institution/${instituteCode.value}/assignment/${
            (res.data.assignment as IAssignmentDetailed).insAssignmentId
          }`
        )
        .then(() => router.go(0))
    }
  }
})
</script>

<template>
  <!-- form -->
  <form @submit.prevent="onSubmit" class="m-auto w-full max-w-2xl">
    <BaseInput
      :label="titleInput.label"
      :placeholder="titleInput.placeholder"
      v-model="title"
      :error="titleError"
      :isLightGray="false"
    />

    <BaseTextbox
      :label="descInput.label"
      :placeholder="descInput.placeholder"
      v-model="description"
      :error="descriptionError"
      :rows="5"
      :isLightGray="false"
    />

    <div class="grid grid-cols-2 gap-x-5">
      <BaseSelect
        :label="defaultLanguageInput.label"
        :list="langData"
        v-model="defaultLanguage"
        :error="defaultLanguageError"
        :default="noneLanguage"
        :valueType="FORM_SELECT_VALUE_TYPE.LANGUAGE"
        :isLightGray="false"
      />
      <div class="col-span-1">
        <BaseSelect
          v-if="versionData !== undefined"
          :label="versionIndexInput.label"
          :list="versionData"
          v-model="versionIndex"
          :error="versionIndexError"
          :valueType="FORM_SELECT_VALUE_TYPE.INDEX"
          :isLightGray="false"
        />
      </div>

      <BaseSelect
        :label="resultTypeInput.label"
        :list="resultTypes"
        v-model="resultType"
        :error="resultTypeError"
        :valueType="FORM_SELECT_VALUE_TYPE.VALUE"
        :isLightGray="false"
      />
    </div>

    <div v-if="currentMode == GURU_ASSIGNMENT_ACTIONS.CLONE">
      <BaseCheck :label="'Clone Staffs'" v-model="cloneStaffs" :error="cloneStaffsError" />

      <BaseCheck :label="'Clone Students'" v-model="cloneStudents" :error="cloneStaffsError" />
    </div>

    <BaseCheck
      :label="autoCorrectCheckInput.label"
      v-model="autoCorrect"
      :error="autoCorrectError"
    />

    <BaseCheck
      v-if="showAutoMark"
      :label="autoScoreInput.label"
      v-model="autoScore"
      :error="autoScoreError"
    />

    <div v-if="showDiscloseSection">
      <BaseCheck
        :label="enableNegativeMarkInput.label"
        v-model="enableNegativeMark"
        :error="enableNegativeMarkError"
      />

      <BaseSelect
        :label="discloseInput.label"
        :list="discloseTypes"
        v-model="disclose"
        :error="discloseError"
        :valueType="FORM_SELECT_VALUE_TYPE.VALUE"
        :is-light-gray="false"
      />

      <p class="my-5 text-sm">{{ autoScoreNote }}</p>

      <BaseCheck
        v-if="showIsPublicCheckbox"
        :label="isPublicInput.label"
        v-model="isPublic"
        :error="isPublicError"
      />
    </div>

    <button :disabled="!canSubmit" type="submit" class="btn-primary btn-rounded-md">
      {{ getButtonText() }}
    </button>
  </form>
</template>
