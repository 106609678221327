declare global {
  interface Window {
    HSOverlay: any
  }
}

import { useUtilModelsStore } from '@/stores/util.models.store'

/**
 * Create a promise to confirm the action
 * @param title title of the confirm dialog
 * @param subtitle subtitle of the confirm dialog
 * @param retunHs return hash to return to after confirm
 * @param yesBtnText yes button text
 * @param noBtnText  no button text
 * @returns promise to confirm the action
 */
const confirmPromise = (
  title: string = 'Are you sure?',
  subtitle: string | null = null,
  retunHs: string | null = null,
  yesBtnText: string = 'Yes',
  noBtnText: string = 'No'
): Promise<boolean> => {
  return useUtilModelsStore().setconfrimDetails(title, subtitle, retunHs, yesBtnText, noBtnText)
}
/**
 * on confirm click
 */
const confirmeResolve = () => {
  useUtilModelsStore().setconfrimDetailsResolve()
}
/**
 * on confirm click
 */
const confirmeReject = () => {
  useUtilModelsStore().setconfrimDetailsReject()
}

/**
 * Create a time out alert
 * @param title title of the alert dialog
 * @param subtitle subtitle of the alert dialog
 * @param retunHs return hash to return to after alert
 */
const alertTimeOut = (
  title: string = 'Alert',
  subtitle: string | null = null,
  retunHs: string | null = null
) => {
  useUtilModelsStore().setAlertDetails(title, subtitle, retunHs)
}
/**
 * open the model
 * @param hs hash to close
 */
const openModal = (hs: string) => {
  const htmlHs: HTMLElement | null = document.getElementById(hs)
  if (htmlHs) window.HSOverlay.open(htmlHs)
}
/**
 * close the model
 * @param hs hash to close
 */
const closeModal = (hs: string) => {
  const htmlHs: HTMLElement | null = document.getElementById(hs)
  if (htmlHs) window.HSOverlay.close(htmlHs)
}
export default {
  openModal,
  closeModal,
  confirmPromise,
  confirmeResolve,
  confirmeReject,
  alertTimeOut
}
