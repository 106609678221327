<script setup lang="ts">
import SixItemsPanel, { type ISixItems } from '@/components/shared/SixItemsPanel.vue'
import UseCaseBanner, { type IUseCaseBannerData } from '@/components/shared/UseCaseBanner.vue'
import Button_login_then_redirect from '@/components/shared/subscription/Button_login_then_redirect.vue'
import type { IActionButton } from '@/components/shared/PricingCard.vue'
import { SUBSCRIPTION_PRODUCT } from '@/components/shared/subscription/enum/subscription.enum'

const sectionProp: IUseCaseBannerData = {
  orangeTextAboveTitle: 'Compiler plugin Features',
  title: 'Embed code execution into your teaching material',
  description:
    'Don’t waste your time in managing coding environments and allow students to code within your platform.',
  textLeftImageRight: true,
  image: {
    src: '',
    alt: ''
  }
}

const featuresGridData: ISixItems[] = [
  {
    icon: 'fa-folder-closed',
    heading: 'Seamlessly integrate in existing platforms',
    body: 'Easy integration with three simple lines of HTML code.'
  },
  {
    icon: 'fa-comment',
    heading: 'Multi-Language Support',
    body: "JDoodle's Cloud IDE supports 76 programming languages.Work with multiple languages within the same project."
  },
  {
    icon: 'fa-message',
    heading: 'Real-time feedback',
    body: 'Students receive immediate feedback on code execution, enhancing active learning and effective debugging.'
  },
  {
    icon: 'fa-shield-halved',
    heading: 'Secure code execution environment',
    body: 'JDoodle ensures code safety and data privacy through sandboxed execution environments.'
  },
  {
    icon: 'fa-users',
    heading: 'Collaboration and sharing',
    body: 'Multiple users can collaborate on the same code snippet, facilitating interactive coding experiences.Choose between automatic or manual result disclosures for auto-corrected assignments, ensuring fairness and transparency in evaluation.'
  },
  {
    icon: 'fa-globe',
    heading: 'Accessible anytime, anywhere',
    body: 'Our Compiler Plugins are cloud-based, enabling students to access them from anywhere with an internet connection.'
  }
]

const button: IActionButton = {
  buttonText: 'Compiler Plugin',
  link: `/dashboard/plugin`,
  product: SUBSCRIPTION_PRODUCT.TEACH
}
</script>

<template>
  <div class="py-8 md:py-14">
    <UseCaseBanner :sectionData="sectionProp" :removePadding="true" class="py-10">
      <Button_login_then_redirect :button-data="button" />
    </UseCaseBanner>
    <SixItemsPanel :sixItems="featuresGridData" />
  </div>
</template>
