<script lang="ts" setup>
import { onMounted, onBeforeUnmount } from 'vue'
import { loadSnitcher, unloadSnitcher } from '@/services/snitcher.service'
import Banner from '@/components/academia/BannerComp.vue'
import Details from '@/components/academia/DetailsComp.vue'
import TrustedBy from '@/components/academia/TrustedByComp.vue'
import Features from '@/components/academia/FeaturesComp.vue'
import HowItWork from '@/components/academia/HowItWorkComp.vue'
import Testimonial from '@/components/academia/TestimonialComp.vue'
import Pricing from '@/components/academia/PricingComp.vue'
import FAQ from '@/components/academia/FAQComp.vue'

onMounted(async () => {
  loadSnitcher()
})

onBeforeUnmount(() => {
  unloadSnitcher()
})
</script>

<template>
  <Banner />
  <Details />
  <TrustedBy />
  <Features />
  <HowItWork />
  <Testimonial />
  <Pricing />
  <FAQ />
</template>
