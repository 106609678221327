<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<script setup lang="ts">
import { computed, ref } from 'vue'
import type IAssociation from '@/components/guru/interface/IAssociation'
import { useTeachStore } from '@/stores/teach.store'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { GURU_USER_STATUS } from '../enums/teach.enum'
import type { IDashboardEmptyData } from '@/components/shared/dashboard/DashboardCardEmptyMessageButton.vue'
import DashboardCardEmptyMessageButton from '@/components/shared/dashboard/DashboardCardEmptyMessageButton.vue'

const noPendingInvitations = 'You have no pending invitations.'
const teachStore = useTeachStore()

const pendingAssociations = computed(() => {
  return teachStore.associations?.filter(
    (asso: IAssociation) =>
      asso.status == GURU_USER_STATUS.PENDING_USER_ACCEPTANCE ||
      asso.status == GURU_USER_STATUS.REJECT
  )
})

const emptyData: IDashboardEmptyData = {
  message: 'You do not have any invitations'
}
</script>

<template>
  <div class="">
    <!-- No Associations -->
    <div class="" v-if="!pendingAssociations || pendingAssociations.length < 1">
      <DashboardCardEmptyMessageButton :empty-data="emptyData" />
    </div>

    <!-- Yes Associations -->
    <div class="flex flex-col gap-4" v-else>
      <router-link
        :to="`/dashboard/institution/${association.institute.code}/invitation/${association.invitationCode}`"
        class="section-primary flex items-center justify-between rounded-xl px-4 py-3"
        v-for="association in pendingAssociations"
        :key="association.institute.code"
      >
        <div class="flex gap-5">
          <div class="section-tertiary h-12 w-12 rounded-xl p-2 text-center text-2xl">
            {{ association.institute.name[0].toLocaleUpperCase() }}
          </div>
          <div class="">
            <p class="text-primary text-sm font-bold">
              {{ association.institute.name }}
            </p>
            <p class="text-secondary text-xs">
              Role:
              <span v-for="associationType in association.associationTypes" :key="associationType">
                {{ associationType }}
              </span>
            </p>
            <p class="text-secondary text-xs">{{ association.status }}</p>
          </div>
        </div>
        <button class="btn-secondary justify-self-end rounded-md px-2 py-1 text-xs">
          View <FontAwesomeIcon icon="fa-arrow-right" />
        </button>
      </router-link>
    </div>
  </div>
</template>
