<script lang="ts" setup>
import CaseStudyBannerImageAndHeading, {
  type ICaseStudyBInHData
} from '@/components/shared/casestudy/CaseStudyBannerImageAndHeading.vue'

import kansasBanner from '@/assets/images/casestudy/kansas/KansasUni1.png'
const sectionData: ICaseStudyBInHData = {
  image: {
    src: kansasBanner,
    alt: '',
    copyright: '© Rockaa from Getty Images Signature via Canva.com'
  },
  heading: "Efficient Code Execution in the University's Application",
  sHeading:
    'Seamless Integration, Expert Support and Enhanced Learning: A Collaborative Success Story',
  clientName: 'University of Kansas',
  date: 'January 2023',
  institution: 'Assessment and Technology Solutions'
}
</script>

<template>
  <CaseStudyBannerImageAndHeading :sectionData="sectionData" />
</template>
