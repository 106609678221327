<script setup lang="ts">
import { initBundle } from '@/services/subscription.service'
import {
  getAssignments,
  getAssociationsAndInstituteOwn,
  getInstituteSubscriptionInfo
} from '@/services/teach.service'
import { useSubscribeStore } from '@/stores/subscribe.store'
import { useTeachStore } from '@/stores/teach.store'
import { computed, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'

const subscribeStore = useSubscribeStore()
const teachStore = useTeachStore()
const route = useRoute()
const insCode = computed(() => route.params.insCode as string)
onMounted(async () => {
  const bundleRes = await initBundle()
  subscribeStore.initBundle(bundleRes)

  const res = await getAssociationsAndInstituteOwn()

  teachStore.setAssociationAndInstituteOwn(res)

  if (insCode.value) {
    const res = await getInstituteSubscriptionInfo(insCode.value)
    teachStore.setSubscriptionInfo(res)
  }
})

watch(
  () => teachStore.associations,
  () => {
    if (route.params.insCode) {
      teachStore.getAndSetCurrentAssociation(route.params.insCode as string)
    }
  }
)

watch(
  () => insCode.value,
  async () => {
    if (insCode.value) {
      const res = await getInstituteSubscriptionInfo(insCode.value)
      teachStore.setSubscriptionInfo(res)

      const assignmentRes = await getAssignments(insCode.value)
      teachStore.setAssignments(assignmentRes)
    }
  }
)
</script>

<template>
  <router-view></router-view>
</template>
