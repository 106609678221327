<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import historyService from '@/services/ide/settings/history.service'
import projectsService from '@/services/ide/projects.service'
import { useIdeStore, type TExecutionHistory, type THtmlExecutionHistory } from '@/stores/ide.store'
import ModelLayout from '@/layouts/ModelLayout.vue'
import { IDEVIEWMODELS } from '@/utils/models'
import { IDECONSTANT } from '@/utils/ide'
import moment from 'moment'

const ideStore = useIdeStore()
const route = useRoute()

const isMounted = ref<boolean>(false)
const loadProjectInProgress = ref<boolean>(false)

const isHtml = computed(() => {
  return ideStore.isHtml
})
const isBlockly = computed(() => {
  return ideStore.isBlockly
})
const isDisplayName = computed(() => {
  return ideStore.ideMeta?.langDisplayName
})
const title = computed(() => {
  return 'Recent ' + isDisplayName.value + ' Executions'
})
const isExecutionsMounted = computed(() => {
  return isMounted.value
})
const projects = computed(() => {
  return ideStore.isExecutionHistory || []
})
const isExecutions = computed(() => {
  if (projects.value === null) return false
  return projects.value.length > 0 || false
})
const project = computed(() => {
  return ideStore.isSelectedExecutionHistory
})
const isProject = computed(() => {
  return project.value !== null
})
const languageVersion = computed(() => {
  const codeProject = project.value as TExecutionHistory
  if (isProject.value && !isHtml.value)
    return ideStore.isVersions[codeProject.versionIndex ? codeProject.versionIndex : 0]
  else return 'Not Available'
})
/**
 * @description Load the execution history
 */
const loadHistory = () => {
  isMounted.value = false
  historyService.loadHistory()
  isMounted.value = true
}
/**
 * if the project is selected
 * @param project IProject
 * @returns boolean
 */
const isSelected = (project: TExecutionHistory | THtmlExecutionHistory) => {
  return ideStore.isSelectedExecutionHistory?.executedAt === project.executedAt || false
}
/**
 * @description Moment date
 * @param date string
 * @returns string
 */
const mmnt = (date: number) => {
  return moment(date).format('DD MMM YYYY, hh:mm:ss A')
}
/**
 * Load project
 * @param selecedProject IProject
 */
const loadProject = async (selecedProject: TExecutionHistory | THtmlExecutionHistory) => {
  loadProjectInProgress.value = true
  historyService.loadProject(selecedProject)
  loadProjectInProgress.value = false
}
/**
 * Open project
 */
const openProject = () => {
  projectsService.openProject(ideStore.isSelectedExecutionHistory)
}
/**
 * Toggle recent storage
 */
const toggleRecentStorage = () => {
  if (ideStore.isLocalStoreEnabled) {
    localStorage.setItem(
      IDECONSTANT.STOTAGE_KEY,
      ideStore.isStorage.getItem(IDECONSTANT.STOTAGE_KEY)
    )
    sessionStorage.removeItem(IDECONSTANT.STOTAGE_KEY)
  } else {
    sessionStorage.setItem(
      IDECONSTANT.STOTAGE_KEY,
      ideStore.isStorage.getItem(IDECONSTANT.STOTAGE_KEY)
    )
    localStorage.removeItem(IDECONSTANT.STOTAGE_KEY)
  }
  historyService.checkStorage()
}
onMounted(() => {
  loadHistory()
  watch(route, () => {
    loadHistory()
  })
})
</script>

<template>
  <ModelLayout :title="title" :hs="IDEVIEWMODELS.EXECUTEHISTORY" :wide-screen="true">
    <div v-show="isExecutionsMounted" class="flex flex-col gap-2">
      <div v-show="isExecutions" class="flex h-full w-full flex-col gap-5 lg:flex-row">
        <div
          class="flex h-full max-h-32 w-full max-w-full flex-col gap-2 lg:max-h-72 lg:max-w-[50%]"
        >
          <p class="heading-xsmall text-sm">Recent Executions</p>
          <div class="flex h-full max-h-full flex-col gap-1 overflow-y-scroll">
            <button
              :disabled="loadProjectInProgress"
              v-for="project in projects"
              :key="project.executedAt"
              :class="[
                'link-primary',
                'flex',
                'items-center',
                'w-full',
                'gap-x-2',
                'rounded-md',
                'py-1',
                {
                  'link-secondary': isSelected(project)
                }
              ]"
              @click="loadProject(project)"
            >
              <FontAwesomeIcon icon="fa-folder" class="h-4 w-4" />
              <span class="p-small">{{ mmnt(project.executedAt) }}</span>
            </button>
          </div>
        </div>
        <div v-show="!loadProjectInProgress && isProject" class="h-72 w-full">
          <div
            v-show="!isBlockly"
            :id="IDECONSTANT.EXECUTE_CODE_EDITOR"
            class="editor block h-44 w-full min-w-full rounded-lg"
          ></div>
          <div class="flex flex-col justify-between gap-1">
            <p class="heading-xsmall text-left text-sm">Result</p>
            <div v-show="!isHtml">
              <p class="p-xsmall">Language Version: {{ languageVersion }}</p>
              <div
                :id="IDECONSTANT.EXECUTE_OUTPUT_EDITOR"
                :class="[
                  'editor h-20 w-full rounded-lg bg-black text-white',
                  {
                    'h-64': isBlockly
                  }
                ]"
              ></div>
            </div>
            <div v-show="isHtml">
              <div class="h-36 w-full rounded-lg bg-white">
                <iframe
                  name="executionOutput"
                  frameborder="0"
                  height="100%"
                  width="100%"
                  sandbox=" allow-forms allow-popups allow-scripts allow-same-origin allow-modals"
                ></iframe>
              </div>
              <div class="hidden">
                <form
                  id="executionOutputForm"
                  name="html-download-form"
                  method="post"
                  action="/api/doodle/download/web.html"
                >
                  <textarea id="executionOutputTextArea" name="code"></textarea>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-show="!isExecutions">
        <p class="heading-xsmall text-sm">No Recent Executions</p>
        <p class="p-xsmall">No recent code exectutions found for {{ isDisplayName }}.</p>
      </div>
      <div v-show="!loadProjectInProgress && isProject" class="flex w-full gap-2">
        <button
          class="btn-primary btn-rounded-md"
          :data-hs-overlay="`#${IDEVIEWMODELS.MYPROJECTS}`"
          data-hs-overlay-close
          @click="openProject"
        >
          Open
        </button>
        <button
          class="btn-secondary btn-rounded-md"
          :data-hs-overlay="`#${IDEVIEWMODELS.EXECUTEHISTORY}`"
          data-hs-overlay-close
        >
          Close
        </button>
      </div>
    </div>
    <div class="flex items-center gap-2 rounded-lg">
      <input
        type="checkbox"
        id="hs-basic-with-description-checked"
        class="section-primary checkbox-primary"
        checked
        v-model="ideStore.isLocalStoreEnabled"
        @change="toggleRecentStorage"
      />
      <label for="hs-basic-with-description-checked" class="p-small my-2 block"
        >Remember execution history in this browser for future sessions?</label
      >
    </div>
    <p class="p-xsmall">
      please note: this change is across all the languages, and this option will show this history
      to all the users using JDoodle in this browser.
    </p>
    <button
      v-show="!isProject"
      class="btn-secondary btn-rounded-md"
      :data-hs-overlay="`#${IDEVIEWMODELS.EXECUTEHISTORY}`"
      data-hs-overlay-close
    >
      Close
    </button>
  </ModelLayout>
</template>
