<script setup lang="ts">
import type { IStudentInAssignment } from '@/components/guru/interface/IStudentInAssignment'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import { useTeachStore } from '@/stores/teach.store'
import { TEACHMODALHS } from '@/utils/models'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import NotReadyToEvaluateModal from '@/components/guru/modals/errorModals/NotReadyToEvaluateModal.vue'
import StudentRemoveModal from '@/components/guru/modals/confirmationModals/StudentRemoveModal.vue'
import SearchAndAddStudentGroupModal from '@/components/guru/modals/SearchAndAddStudentGroupModal.vue'
import SearchAndAddUserModal from '@/components/guru/modals/SearchAndAddUserModal.vue'
import { TEACH_SECTION, TEACH_STUDENT_ASSIGNMENT_STATUS } from '@/components/guru/enums/teach.enum'
import { GURU_USER_TYPE } from '@/components/guru/enums/teach.enum'
import { getOneAssignment } from '@/services/teach.service'
import utilModelsService from '@/services/util.models.service'
import { getFormattedDate } from '@/utils/format'
import DashboardCardEmptyMessageButton from '@/components/shared/dashboard/DashboardCardEmptyMessageButton.vue'
const teachStore = useTeachStore()
const breadcrumbStore = useBreadcrumbStore()
const route = useRoute()
const router = useRouter()
const insCode = ref<string>(route.params.insCode as string)
const assignmentId = ref<number>(Number.parseInt(route.params.assignmentId as string))
const studentsData = computed<IStudentInAssignment[] | undefined>(() => {
  return teachStore.currentAssignment?.students
})
const searchString = ref('')
const filters = reactive({
  evaluated: true,
  submitted: true,
  yetToStart: true,
  resultAnnounced: true,
  inProgress: true,
  reevaluationRequested: true
})

onMounted(async () => {
  insCode.value = route.params.insCode as string
  assignmentId.value = Number.parseInt(route.params.assignmentId as string)
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)

  const res = await getOneAssignment(insCode.value, assignmentId.value)
  teachStore.setCurrentAssignment(res)
})

const filteredStudents = computed(() => {
  let filtered = []
  if (studentsData.value) {
    if (searchString.value) {
      const lowerCaseString = searchString.value.toLowerCase()
      filtered = studentsData.value.filter((student: IStudentInAssignment) => {
        return (
          student.email.toLowerCase().includes(lowerCaseString) ||
          student.firstName.toLowerCase().includes(lowerCaseString) ||
          student.lastName.toLowerCase().includes(lowerCaseString)
        )
      })
    } else {
      filtered = studentsData.value
    }

    return filtered
      .filter((student: IStudentInAssignment) => {
        if (!filters.yetToStart) {
          return student.status !== TEACH_STUDENT_ASSIGNMENT_STATUS.YET_TO_START
        } else {
          return true
        }
      })
      .filter((student: IStudentInAssignment) => {
        if (!filters.inProgress) {
          return student.status !== TEACH_STUDENT_ASSIGNMENT_STATUS.IN_PROGRESS
        } else {
          return true
        }
      })
      .filter((student: IStudentInAssignment) => {
        if (!filters.submitted) {
          return student.status !== TEACH_STUDENT_ASSIGNMENT_STATUS.SUBMITTED
        } else {
          return true
        }
      })
      .filter((student: IStudentInAssignment) => {
        if (!filters.evaluated) {
          return student.status !== TEACH_STUDENT_ASSIGNMENT_STATUS.EVALUATED
        } else {
          return true
        }
      })
      .filter((student: IStudentInAssignment) => {
        if (!filters.resultAnnounced) {
          return student.status !== TEACH_STUDENT_ASSIGNMENT_STATUS.RESULT_ANNOUNCED
        } else {
          return true
        }
      })
      .filter((student: IStudentInAssignment) => {
        if (!filters.reevaluationRequested) {
          return student.status !== TEACH_STUDENT_ASSIGNMENT_STATUS.REEVALUATION_REQUESTED
        } else {
          return true
        }
      })
  } else return []
})

const currentStudentEmail = ref<string>('')

/**
 * Set ref to target student's email
 * @param user IStudentInAssignment or IUserInStudentGroup
 */
const handleRemoveStudent = async (user: IStudentInAssignment) => {
  currentStudentEmail.value = user.email
  utilModelsService.openModal(TEACHMODALHS.REMOVE_USER_STUDENT_CONFIRMATION)
}

/**
 * If ready, set student email in teachStore
 * @param student selected student
 */
const handleEvaluateStudent = (student: IStudentInAssignment) => {
  if (!readyToEvaluate(student)) {
    return
  } else {
    teachStore.setStudentUnderEvaluation(student)
    router.push(`/dashboard/institution/${insCode.value}/assignment/${assignmentId.value}/evaluate`)
  }
}

const studentNotReadyToBeEvaluatedModal = {
  title: 'Evaluate Student',
  message: 'Assignment not ready for evaluation yet.'
}

/**
 * Is student ready to be evaluated?
 * @param student IStudentInAssignment
 * @returns boolean
 */
function readyToEvaluate(student: IStudentInAssignment) {
  return (
    student.status !== TEACH_STUDENT_ASSIGNMENT_STATUS.YET_TO_START &&
    student.status !== TEACH_STUDENT_ASSIGNMENT_STATUS.IN_PROGRESS
  )
}

watch(
  () => teachStore.currentAssociation,
  () => {
    breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
  }
)

/**
 * @param emit The emitted search string
 */
function handleSearchInput(emit: string) {
  searchString.value = emit
}

/**
 * @param emitFilters Reactive object from Dashboarcard.vue
 */
function handleFilterInput(emitFilters: any) {
  filters.yetToStart = emitFilters.yetToStart
  filters.inProgress = emitFilters.inProgress
  filters.submitted = emitFilters.submitted
  filters.evaluated = emitFilters.evaluated
  filters.resultAnnounced = emitFilters.resultAnnounced
  filters.reevaluationRequested = emitFilters.reevaluationRequested
}

const breadcrumbLinks = computed(() => {
  return [
    {
      href: '/',
      title: 'Home'
    },
    {
      href: '/dashboard',
      title: 'Dashboard'
    },
    {
      href: '/dashboard/institution',
      title: 'Virtual Institutions'
    },
    {
      href: `/dashboard/institution/${insCode.value}`,
      title: `${teachStore.currentAssociation?.name}`
    },
    {
      href: `/dashboard/institution/${insCode.value}/assignment/${assignmentId.value}`,
      title: `${teachStore.currentAssignment?.title}`
    },
    {
      href: `/dashboard/institution/${insCode.value}/assignment/manage-students`,
      title: `Manage Students`
    }
  ]
})
</script>

<template>
  <SearchAndAddUserModal
    :userType="GURU_USER_TYPE.STUDENT"
    :existingUsers="studentsData"
    :instituteCode="insCode"
    :assignmentId="teachStore.currentAssignment?.insAssignmentId"
    :section="TEACH_SECTION.ASSIGNMENT"
  />

  <SearchAndAddStudentGroupModal :instituteCode="insCode" :assignmentId="assignmentId" />

  <StudentRemoveModal
    :email="currentStudentEmail"
    :instituteCode="insCode"
    :insAssignmentId="assignmentId"
    :section="TEACH_SECTION.ASSIGNMENT"
  />

  <NotReadyToEvaluateModal
    :title="studentNotReadyToBeEvaluatedModal.title"
    :message="studentNotReadyToBeEvaluatedModal.message"
  />

  <div class="h-full w-full">
    <DashboardCard
      title="Students"
      class="h-full w-full"
      :has-search-bar="true"
      :has-status-filter="true"
      @searchInput="handleSearchInput"
      @filterInput="handleFilterInput"
      :has-student-user-actions="true"
    >
      <div
        v-if="teachStore.isAccountOwner || teachStore.isInstituteAdmin || teachStore.isTeacher"
        class="m-auto max-w-4xl"
      >
        <div v-if="studentsData && studentsData.length > 0" class="mt-5 w-full">
          <table class="w-full table-auto border-separate border-spacing-y-4">
            <thead class="text-start text-sm">
              <tr>
                <th class="px-3 py-1 text-start">Name</th>
                <th class="px-3 py-1 text-start">Email</th>
                <th class="px-3 py-1 text-start">Status</th>
                <th class="px-3 py-1 text-start">Date Submitted</th>
                <th class="px-3 py-1 text-start">Marks</th>
                <th class="px-3 py-1 text-start"></th>
              </tr>
            </thead>
            <tbody class="">
              <tr
                v-for="student in filteredStudents"
                :key="student.email"
                class="section-primary overflow-hidden rounded-lg p-3"
              >
                <td class="rounded-s-lg p-3 text-sm">
                  {{ student.firstName }} {{ student.lastName }}
                </td>
                <td class="p-3 text-sm">
                  {{ student.email }}
                </td>
                <td class="p-3 text-sm">
                  {{ student.status }}
                </td>
                <td class="p-3 text-sm">
                  {{ student.submittedOn ? getFormattedDate(student.submittedOn) : '-' }}
                </td>
                <td class="p-3 text-sm">
                  {{ student.submittedOn ? student.mark : '-' }}
                </td>
                <td class="rounded-e-lg p-3 text-sm">
                  <button v-if="readyToEvaluate(student)" @click="handleEvaluateStudent(student)">
                    <FontAwesomeIcon icon="fa-pen-to-square" class="me-2"></FontAwesomeIcon>
                  </button>

                  <button @click="handleRemoveStudent(student)">
                    <FontAwesomeIcon icon="fa-trash" class="error"></FontAwesomeIcon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <DashboardCardEmptyMessageButton
          v-else
          :empty-data="{ message: 'No students added yet' }"
        />
      </div>
    </DashboardCard>
  </div>
</template>
