<script setup lang="ts">
import { IDECONSTANT } from '@/utils/ide'
import { computed } from 'vue'
import { useIdeStore } from '@/stores/ide.store'
import editorService from '@/services/ide/languages/html/editor.service'

const ideStore = useIdeStore()

const isHtml = computed(() => {
  return ideStore.isHtml
})
const isHtmlExpand = computed(() => {
  return ideStore.isHtmlExpand
})
const toogleHtmlExpandIcon = computed(() => {
  return ideStore.isHtmlExpand ? 'fa-compress' : 'fa-expand'
})
const fontSize = computed(() => {
  return ideStore.fontSize
})
/**
 * Toggles the full screen
 */
const toogleHtmlExpand = async () => {
  ideStore.setHtmlExpand(!ideStore.isHtmlExpand)
}
/**
 * Checks if the font size is selected
 * @param fontSize The font size to check
 * @returns True if the font size is selected
 */
const isFontsizeSelected = (fontSize: number) => {
  return ideStore.fontSize === fontSize
}
/**
 * Sets the font size
 * @param e The event
 */
const setFontSize = (e: Event) => {
  editorService.setFontSize(parseInt((e.target as HTMLSelectElement).value))
}
</script>

<template>
  <div class="flex items-center justify-between">
    <div class="flex h-fit w-full items-center justify-end gap-5">
      <div class="flex items-center gap-1 print:hidden">
        <label for="fontSizeSelect" class="p-xsmall block">Font Size:</label>
        <select
          id="fontSizeSelect"
          class="section-primary p-xsmall block h-6 w-fit rounded-md"
          @change="setFontSize($event)"
        >
          <option
            v-for="fontSize in Array.from({ length: 15 }, (_, i) => i + 11)"
            :key="fontSize"
            :selected="isFontsizeSelected(fontSize)"
            :value="fontSize"
          >
            {{ fontSize }}px
          </option>
        </select>
      </div>
      <button
        v-if="isHtml"
        class="hidden w-fit min-w-fit print:hidden lg:block"
        @click="toogleHtmlExpand"
      >
        <FontAwesomeIcon :icon="toogleHtmlExpandIcon" class="h-4 w-4" />
      </button>
    </div>
  </div>
  <div
    :class="[
      'flex',
      'w-full',
      'flex-col',
      'gap-5',
      'lg:flex-row',
      {
        'lg:flex-col': isHtml && isHtmlExpand
      }
    ]"
  >
    <div
      class="section-primary flex w-full flex-col gap-1 rounded-lg p-2 print:break-inside-avoid print:rounded-none print:border print:p-1"
    >
      <p class="heading-xsmall hidden text-left text-sm print:block">HTML</p>
      <div
        :id="IDECONSTANT.HTML_DOC_TYPE_EDITOR"
        class="editor block w-full min-w-full rounded-lg xl:overflow-scroll"
      />
      <span class="p-xsmall font-thin" :style="`font-size:${fontSize}px`"> &lt;html&gt; </span>
      <span class="p-xsmall font-thin" :style="`font-size:${fontSize}px`">
        &nbsp;&nbsp; &lt;head&gt;
      </span>
      <div
        :id="IDECONSTANT.HTML_HEAD_EDITOR"
        class="editor block min-w-full rounded-lg xl:overflow-scroll"
      />
      <span class="p-xsmall font-thin" :style="`font-size:${fontSize}px`">
        &nbsp;&nbsp; &lt;/head&gt;
      </span>
      <span class="p-xsmall font-thin" :style="`font-size:${fontSize}px`">
        &nbsp;&nbsp; &lt;body&gt;
      </span>
      <div
        :id="IDECONSTANT.HTML_BODY_EDITOR"
        class="editor block min-w-full rounded-lg xl:overflow-scroll"
      />
      <span class="p-xsmall font-thin" :style="`font-size:${fontSize}px`">
        &nbsp;&nbsp; &lt;body&gt;
      </span>
      <span class="p-xsmall font-thin" :style="`font-size:${fontSize}px`"> &lt;/html&gt; </span>
    </div>
    <div class="flex w-full flex-col gap-5">
      <div
        class="section-primary rounded-lg p-2 print:break-inside-avoid print:rounded-none print:border print:p-1"
      >
        <p class="heading-xsmall hidden text-left text-sm print:block">JavaScript</p>
        <span class="p-xsmall font-thin" :style="`font-size:${fontSize}px`">
          &lt;script type="text/javascript"&gt;
        </span>
        <div
          :id="IDECONSTANT.HTML_JS_EDITOR"
          class="editor block min-w-full rounded-lg xl:overflow-scroll"
        />
        <span class="p-xsmall font-thin" :style="`font-size:${fontSize}px`"> &lt;/script&gt; </span>
      </div>
      <div
        class="section-primary rounded-lg p-2 print:break-inside-avoid print:rounded-none print:border print:p-1"
      >
        <p class="heading-xsmall hidden text-left text-sm print:block">CSS</p>
        <span class="p-xsmall font-thin" :style="`font-size:${fontSize}px`">
          &lt;style type="text/css"&gt;
        </span>
        <div
          :id="IDECONSTANT.HTML_CSS_EDITOR"
          class="editor block min-w-full rounded-lg xl:overflow-scroll"
        />
        <span class="p-xsmall font-thin" :style="`font-size:${fontSize}px`"> &lt;/style&gt; </span>
      </div>
    </div>
  </div>
</template>
