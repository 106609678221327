<script setup lang="ts">
import { GURU_USER_STATUS, INSTITUTE_ROLE_RESPONSE } from '@/components/guru/enums/teach.enum'
import { useRoute, useRouter } from 'vue-router'
import { useTeachStore } from '@/stores/teach.store'
import { computed, onMounted, watch } from 'vue'
import { ref } from 'vue'
import { checkInvitationToken, respondToInvite } from '@/services/teach.service'
import { HttpStatusCode } from 'axios'
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
const teachStore = useTeachStore()
const breadcrumbStore = useBreadcrumbStore()
const route = useRoute()
const router = useRouter()
const insCode = ref('')
const invitationToken = ref('')
const currentResponse = ref(undefined as undefined | INSTITUTE_ROLE_RESPONSE)
const validToken = ref(false)
const verifyError = ref('')
const errorReason = ref('')
onMounted(async () => {
  insCode.value = route.params.insCode as string
  invitationToken.value = route.params.invitationToken as string
  teachStore.getAndSetCurrentAssociation(insCode.value)
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)

  try {
    const tokenCheckRes = await checkInvitationToken(invitationToken.value)
    if (tokenCheckRes.status == HttpStatusCode.Ok) {
      validToken.value = true
    }
  } catch (error: any) {
    verifyError.value = error?.response?.data?.message || ''
    errorReason.value = 'Sorry, we have no record with this invitation token, or it has expired.'
    validToken.value = false
  }
})

watch(
  () => teachStore.associations,
  () => {
    teachStore.getAndSetCurrentAssociation(insCode.value)
  }
)

const breadcrumbLinks = computed(() => [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  },
  {
    href: '/dashboard/institution',
    title: 'Virtual Institutions'
  },
  {
    href: `/dashboard/institution/invitations`,
    title: `My Invitations`
  },
  {
    href: `/dashboard/institution/${insCode.value}/invitation/${invitationToken.value}`,
    title: `Invitation`
  }
])

/**
 * Checks status after association is retrieved
 * @returns boolean
 */
const isPendingAcceptance = computed(() => {
  return teachStore.currentAssociationFull?.status === GURU_USER_STATUS.PENDING_USER_ACCEPTANCE
})

/**
 * Checks status after association is retrieved
 * @returns boolean
 */
const isRejected = computed(() => {
  return teachStore.currentAssociationFull?.status === GURU_USER_STATUS.REJECT
})

/**
 * @param response enum of response
 */
const handleResponse = async (response: INSTITUTE_ROLE_RESPONSE) => {
  currentResponse.value = response
}

/**
 * @returns association
 */
function getInvitedRolesString() {
  return teachStore.currentAssociationFull?.associationTypes.join(', ')
}

/**
 *
 */
const handleSubmit = async () => {
  const res = await respondToInvite(insCode.value, currentResponse.value!, invitationToken.value)

  if (res.status == HttpStatusCode.Ok) {
    if (currentResponse.value == INSTITUTE_ROLE_RESPONSE.ACCEPT) {
      router.push(`/dashboard/institution/${insCode.value}`)
      // .then(() => router.go(0))
    } else {
      router.push(`/dashboard/institution`)
      // .then(() => router.go(0))
    }
  }
}
</script>

<template>
  <DashboardCard class="flex h-full flex-col" :title="'Institute Invitation'">
    <!-- Pending / Rejected status -->
    <div v-if="validToken">
      <div v-if="isPendingAcceptance || isRejected" class="h-full">
        <!-- <RespondToInvitationModal :instituteCode="insCode" :response="currentResponse" /> -->
        <div class="m-auto my-16 flex max-w-2xl flex-col text-center" v-if="!currentResponse">
          <p>
            <span class="font-bold">{{ teachStore.currentAssociation?.name }}</span> has added you
            into their institute as a <span class="font-bold">{{ getInvitedRolesString() }}</span
            >.
          </p>
          <p class="mt-8">Would you like to accept this invitation?</p>
          <div class="mt-8 flex justify-center gap-5">
            <button
              @click="handleResponse(INSTITUTE_ROLE_RESPONSE.ACCEPT)"
              class="btn btn-primary w-fit"
            >
              Accept
            </button>
            <button
              @click="handleResponse(INSTITUTE_ROLE_RESPONSE.REJECT)"
              class="btn btn-secondary w-fit"
            >
              Decline
            </button>
          </div>
        </div>
        <div v-else class="m-auto my-16 h-full max-w-2xl">
          <p class="mb-8 text-center">Are you sure you would like to {{ currentResponse }}?</p>
          <div class="flex justify-center gap-5">
            <button @click="handleSubmit" class="btn btn-primary w-fit">Yes</button>
            <button @click="currentResponse = undefined" class="btn btn-secondary w-fit">No</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="m-auto h-full max-w-2xl">
      <h1 class="error heading-small my-10">{{ verifyError }}</h1>
      <h2 class="text-md my-10">Reason: {{ errorReason }}</h2>
    </div>
  </DashboardCard>
</template>
