<script setup lang="ts">
import appStoreImg from '@/assets/images/footer/app_store.png'
import playStoreImg from '@/assets/images/footer/google_play.png'
import phoneImg from '@/assets/images/footer/app_example.png'

interface IMobileStore {
  image: {
    src: string
    alt: string
  }
  link: string
}

const mobileStores: IMobileStore[] = [
  {
    image: {
      src: appStoreImg,
      alt: 'app_store_image'
    },
    link: 'https://apps.apple.com/us/app/jdoodle/id1544598494'
  },
  {
    image: {
      src: playStoreImg,
      alt: 'play_store_image'
    },
    link: 'https://play.google.com/store/apps/details?id=com.nutpan.jdoodle_app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
  }
]
</script>

<template>
  <div class="section-primary overflow-hidden md:pt-16">
    <div class="view-container">
      <div class="flex flex-col-reverse md:flex-row">
        <div class="basis-1/2 md:text-right">
          <img
            :src="phoneImg"
            alt="JDoodle on your phone"
            class="roundesd-5xl xs:w-[60%] inline-block h-full w-full md:mr-20 md:max-w-[420px]"
          />
        </div>

        <div class="basis-1/2 pb-16 pt-32">
          <div class="text-brand text-sm font-medium uppercase tracking-wider">CODE ON THE GO</div>
          <div class="heading-medium mb-5 mt-2 font-bold">Download the App Now</div>
          <div class="text-md text-secondary font-normal">
            Unlock the Power of Programming Anywhere, Anytime - Download our app from the App Store
            or Play Store and experience seamless coding with our Online IDEs!
          </div>

          <div class="mt-5 flex gap-5">
            <a v-for="item in mobileStores" :key="item.link" :href="item.link" rel="nofollow">
              <img :src="item.image.src" :alt="item.image.alt" class="w-32" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
