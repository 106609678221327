<script setup lang="ts">
import {
  reactivateApiSubscription,
  reactivateBundleSubscription
} from '@/services/subscription.service'
import { ref, type PropType } from 'vue'
import { SUBSCRIPTION_TYPE_ENUM } from '@/components/shared/subscription/enum/subscription.enum'

defineProps({
  subscriptionType: {
    type: String as PropType<SUBSCRIPTION_TYPE_ENUM>,
    required: true
  }
})

const clickDisabled = ref(false)

/**
 *
 */
function reactivateApi() {
  clickDisabled.value = true

  reactivateApiSubscription()
    .then(() => {
      window.location.reload()
    })
    .finally(() => {
      clickDisabled.value = false
    })
}

/**
 *
 */
function reactivateBundle() {
  clickDisabled.value = true

  reactivateBundleSubscription()
    .then(() => {
      window.location.reload()
    })
    .finally(() => {
      clickDisabled.value = false
    })
}
</script>

<template>
  <div class="section-primary">
    <h3 class="heading-medium mb-14 mt-10 font-semibold">
      Are you sure want to re-activate your subscription?
    </h3>
    <div class="text-left">
      <p>
        Your current plan will be reactivated, your plan will go to the state where it was when
        canceled.
      </p>
      <p>
        Changes you made to current plan before cancellation (e.g downgrade starting from end of
        current billing period) will be reactivated as well.
      </p>
      <p class="my-6">
        Read
        <a
          href="https://docs.jdoodle.com/integrating-compiler-ide-to-your-application/compiler-api"
          target="_blank"
          class="font-medium text-blue-500"
          >API FAQ</a
        >
        for more details
      </p>
    </div>
    <button
      v-if="subscriptionType == SUBSCRIPTION_TYPE_ENUM.BUNDLE"
      class="btn btn-primary mt-12 w-fit text-base"
      :disabled="clickDisabled"
      @click="reactivateBundle"
    >
      Reactivate Subscription
    </button>
    <button
      v-else
      class="btn btn-primary mt-12 w-fit text-base"
      :disabled="clickDisabled"
      @click="reactivateApi"
    >
      Reactivate Subscription
    </button>
  </div>
</template>
