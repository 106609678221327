<script setup lang="ts">
import BaseTextbox from '@/components/forms/BaseTextbox.vue'
import feedbackService, { type IFeedbackRequest } from '@/services/feedback.service'
import { useField, useForm } from 'vee-validate'
import { ref, computed, onMounted, watch } from 'vue'
import { string } from 'yup'

const httpErrorTimeOut = ref<number | null>(null)
const httpError = ref<string>('')
const httpSuccessTimeOut = ref<number | null>(null)
const httpSuccess = ref<string>('')

const { errors, handleSubmit, resetForm } = useForm()

const { value: comment, errorMessage: commentError } = useField(
  'comment',
  string().required().min(5)
)
const dynamicId = computed(() => {
  return `${Math.floor(Math.random() * 1000)}`
})
const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0
})

const onSubmit = handleSubmit(async (values) => {
  const requestPayload: IFeedbackRequest = {
    comment: values.comment,
    isSurvey: false
  }

  await feedbackService
    .sendFeedback(requestPayload)
    .then((response: any) => {
      httpSuccess.value = response?.data?.message || 'Thanks for your feedback!'
      httpError.value = ''
      resetForm()
    })
    .catch((error) => {
      httpSuccess.value = ''
      httpError.value = error?.response?.data?.message
    })
})
onMounted(() => {
  watch(httpError, () => {
    if (httpError.value) {
      if (httpErrorTimeOut.value) clearTimeout(httpErrorTimeOut.value)
      httpErrorTimeOut.value = setTimeout(() => {
        httpError.value = ''
      }, 8000)
    }
  })
  watch(httpSuccess, () => {
    if (httpSuccess.value) {
      if (httpSuccessTimeOut.value) clearTimeout(httpSuccessTimeOut.value)
      httpSuccessTimeOut.value = setTimeout(() => {
        httpSuccess.value = ''
      }, 8000)
    }
  })
})
</script>
<template>
  <form @submit.prevent="onSubmit">
    <BaseTextbox
      :id="dynamicId"
      label="Comments/Suggestions/Inputs..."
      type="input"
      placeholder="Your Comments"
      :error="commentError"
      v-model="comment"
      :rows="6"
    />
    <p :class="['p-xsmall', 'error', { hidden: !httpError }]">
      {{ httpError }}
    </p>
    <p :class="['p-xsmall', 'success', { hidden: !httpSuccess }]">
      {{ httpSuccess }}
    </p>

    <div class="flex flex-col justify-start gap-3 sm:flex-row sm:items-center sm:justify-between">
      <div class="flex gap-3">
        <button :disabled="!canSubmit" class="btn btn-secondary w-full sm:w-fit" type="submit">
          Post Comment
        </button>
        <!-- <button class="text-secondary text-sm" @click="comment=''">Cancel</button> -->
      </div>

      <span class="p-xsmall inline-block max-w-[300px]">
        For direct response, please include your email id in the comment or email to
        <a class="link-secondary" href="mailto:hello@jdoodle.com" target="_blank"
          >hello@jdoodle.com.</a
        >
      </span>
    </div>
  </form>
</template>
