<script setup lang="ts">
import { onMounted, ref } from 'vue'
// import type ILink from '@/components/shared/interface/ILink'
// import type IAssociation from '@/components/guru/interface/IAssociation'
// import TeachBreadcrumb from '@/components/guru/shared/TeachBreadcrumb.vue'
import AddStudentGroupModal from '@/components/guru/modals/AddStudentGroupModal.vue'
import { useRoute } from 'vue-router'
import type { IStudentGroup } from '@/components/guru/interface/IStudentGroup'
import { getStudentGroups } from '@/services/teach.service'
import StudentGroupBlock from './StudentGroupBlock.vue'
import DashboardCardEmptyMessageButton, {
  type IDashboardEmptyData
} from '@/components/shared/dashboard/DashboardCardEmptyMessageButton.vue'
import { SUBSCRIPTION_PRODUCT } from '@/components/shared/subscription/enum/subscription.enum'
import { useAuthStore } from '@/stores/auth.store'

const route = useRoute()
const insCode = ref(route.params.insCode as string)
const studentGroups = ref([] as IStudentGroup[])

onMounted(async () => {
  if (!useAuthStore().isUserloggedIn) return
  insCode.value = route.params.insCode as string
  const res = await getStudentGroups(insCode.value)

  if (props.displayCount) {
    studentGroups.value = res.slice(0, props.displayCount)
  } else {
    studentGroups.value = res
  }
})

const props = defineProps({
  displayCount: {
    type: Number,
    required: false
  }
})

const emptyData: IDashboardEmptyData = {
  message: 'You have not created any student groups',
  button: {
    buttonText: 'Add Student Group',
    product: SUBSCRIPTION_PRODUCT.TEACH,
    link: `/dashboard/institution/${insCode.value}/add-student-group`
  }
}
</script>

<template>
  <AddStudentGroupModal />
  <div class="h-full w-full">
    <div v-if="studentGroups.length > 0" class="flex flex-col gap-3">
      <div v-for="sg in studentGroups" :key="sg.insStudentGroupId">
        <StudentGroupBlock :student-group="sg" :ins-code="insCode" />
      </div>
    </div>
    <div v-else>
      <DashboardCardEmptyMessageButton :empty-data="emptyData" />
    </div>
  </div>
</template>
