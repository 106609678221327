<script lang="ts" setup>
import CaseStudyChallengesAndSolutions from '@/components/shared/casestudy/CaseStudyChallengesAndSolutions.vue'
import itext3 from '@/assets/images/casestudy/itext/iText3.png'

const sectionData = {
  paragraph1:
    'iText faced a unique challenge in showcasing the capabilities of their PDF library to potential customers. Traditionally, demonstrating the power and versatility of a software library required significant setup time, trial forms, and sometimes even complex project configurations. These barriers often hindered their ability to effectively communicate the true value of iText to prospects.',
  qouteText:
    'JDoodle’s API has enabled us to showcase the power of the iText library in a super convenient and friendly way to our prospects, with no setup time, trial forms or project setup. I can’t really think of a better way to showcase our technology.',
  qoutedBy: 'André Lemos, iText',
  paragraph2:
    "To address this challenge and simplify the process of showcasing their technology, iText turned to JDoodle's compiler API. JDoodle provided an elegant solution that allowed iText to highlight their library's capabilities in a convenient and user-friendly way. The key benefits included:",
  benefitheading: "JDoodle's solution brought several substantial advantages:",
  benefitlist: [
    {
      title: 'Instant Accessibility:',
      desc: 'JDoodle offered a platform where prospects could access and test the iText library immediately, without any need for setup or installation.'
    },
    {
      title: 'Streamlined Demonstrations: ',
      desc: "iText could effortlessly present the power of their library through JDoodle's user-friendly interface, eliminating the complexity that often accompanies software demonstrations."
    },
    {
      title: 'No Trial Forms: ',
      desc: 'Prospects could experience iText without the hassle of filling out trial forms, reducing friction in the evaluation process.'
    },
    {
      title: 'Project-Free Testing:',
      desc: "JDoodle's environment allowed prospects to experiment with iText's features and functionalities without the need to set up a project, saving valuable time."
    },
    {
      title: 'Effortless Showcasing: ',
      desc: "iText could seamlessly guide prospects through the library's capabilities, ensuring that every aspect was explored and understood."
    }
  ],
  image3: {
    src: itext3,
    alt: '',
    copyright: '© arturmarciniecphotos via Canva.com'
  },
  result: {
    paragraph:
      "The integration of JDoodle's compiler API into iText's technology showcase strategy had a transformative impact on their engagement with prospects and the effectiveness of their demonstrations.",
    list: [
      {
        title: 'Increased Engagement:',
        desc: "Prospects were more likely to explore the iText library when presented through JDoodle's hassle-free platform, resulting in higher levels of engagement."
      },
      {
        title: 'Reduced Friction:',
        desc: "Eliminating trial forms and project setup hurdles simplified the evaluation process, encouraging more prospects to experience iText's power."
      },
      {
        title: 'Faster Understanding:',
        desc: 'With the user-friendly JDoodle interface, prospects could quickly grasp the versatility and capabilities of the iText library, accelerating their decision-making process.'
      },

      {
        title: 'Enhanced Communication:',
        desc: "iText's sales and technical teams found it easier to communicate the library's value when using JDoodle as a demonstration tool."
      },
      {
        title: 'Positive Feedback:',
        desc: "Prospects appreciated the convenience and simplicity of JDoodle, leading to positive feedback and improved perceptions of iText's technology."
      }
    ]
  }
}
</script>

<template>
  <CaseStudyChallengesAndSolutions :sectionData="sectionData" />
</template>
