<script setup lang="ts">
const steps = [
  {
    title: 'Via Compiler APIs',
    description: 'Full control over your UI.'
  },
  {
    title: 'Via Compiler Plugins',
    description:
      'Simple integration with 3 lines of HTML code. You can find the code <a href="https://docs.jdoodle.com/integrating-compiler-ide-to-your-application/ide-plugin" class="underline underline-offset-2">here</a>.'
  },
  {
    title: ' Via Visual Plugins',
    description: 'Create a custom Plugin using WYSIWYG editor in the dashboard.'
  }
]
</script>

<template>
  <div class="section-primary py-8">
    <div class="view-container">
      <span class="title-feature">How it works</span>
      <div class="title-heading max-w-xl">
        3 ways to integrate IDEs into your website or platform
      </div>
      <div class="my-10 grid items-center gap-8 md:grid-cols-3">
        <div v-for="(step, index) of steps" :key="index" class="h-full">
          <div
            class="h-full cursor-pointer rounded-lg border border-neutral-200 bg-zinc-50 p-4 hover:border-red-500 hover:bg-white hover:shadow-xl dark:border-text-secondary dark:bg-background-tertiary-dark"
          >
            <div class="flex flex-col gap-6">
              <div>
                <span class="text-brand mb-2 block text-sm font-medium uppercase tracking-wider"
                  >WAY {{ index + 1 }}</span
                >
                <div class="text-start text-xl font-bold">
                  {{ step.title }}
                </div>
              </div>

              <p
                class="text-secondary text-start text-sm"
                v-if="index == 1"
                v-html="step.description"
              ></p>
              <p class="text-secondary text-start text-sm" v-else>
                {{ step.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
