<script setup lang="ts">
import BaseInput from '@/components/forms/BaseInput.vue'
import SocialLogin from '@/components/header/auth/SocialLoginComp.vue'
// @ts-ignore
import ModelLayout from '@/layouts/ModelLayout.vue'
import authService, { type IRegisterRequest } from '@/services/auth.service'
import { AUTHMODELHS } from '@/utils/models'
import { useColorMode } from '@vueuse/core'
import { useField, useForm } from 'vee-validate'

import { computed, onMounted, ref, watch, watchEffect, onBeforeUnmount } from 'vue'
import { VueRecaptcha } from 'vue-recaptcha'
import { RouterLink } from 'vue-router'
import { string } from 'yup'
import { RECAPTCHA_KEYS } from '@/utils/recaptcha'
import utilModelsService from '@/services/util.models.service'
const colorTheme = useColorMode()

const sitekey = RECAPTCHA_KEYS.RECAPTCHAREGISTERSITEKEY
const rechaptha = ref<any>(null)
const recapthaToken = ref<string>('')
const recapthaError = ref<string>('')
const showRecaptcha = ref<boolean>(false)

const httpErrorTimeOut = ref<number | null>(null)
const httpError = ref<string>('')
// const httpSuccessTimeOut = ref<number | null>(null)
const showRegisterSuccess = ref<boolean>(false)
const isLoading = ref<boolean>(false)
const passwordNotMatchError = ref<string>('')
const { errors, handleSubmit, resetForm } = useForm()

const { value: email, errorMessage: emailError } = useField('username', string().required().email())
const { value: displayName, errorMessage: displayNameError } = useField(
  'displayName',
  string().required()
)
const { value: password, errorMessage: passwordError } = useField(
  'password',
  string().required().min(5)
)
const { value: confirmPassword, errorMessage: confirmPasswordError } = useField(
  'confirmPassword',
  string().required().min(5)
)
/**
 * Handles the response from the reCAPTCHA verification and sets the token.
 * @param response - The reCAPTCHA response token.
 */
const recaptchaVerified = (response: string) => {
  recapthaToken.value = response
  recapthaError.value = ''
}

/**
 * Handles the reCAPTCHA error and sets the error message.
 * @param error - The reCAPTCHA error message.
 */
const recaptchaError = (error: string) => {
  recapthaToken.value = ''
  recapthaError.value = error
  rechaptha.value.reset()
}

const canSubmit = computed(() => {
  return (
    Object.keys(errors.value).length === 0 &&
    recapthaToken.value &&
    !recapthaError.value &&
    !passwordNotMatchError.value &&
    !isLoading.value
  )
})

const onSubmit = handleSubmit(async (values) => {
  if (!recapthaToken.value || recapthaError.value) {
    recaptchaError('Please click on "I\'m not a robot"')
    return
  }
  const requestPayload: IRegisterRequest = {
    username: values.username,
    displayName: values.displayName,
    newPassword: values.password,
    retypePassword: values.confirmPassword,
    recaptcha: recapthaToken.value
  }
  isLoading.value = true
  await authService
    .register(requestPayload)
    .then(() => {
      // httpSuccess.value = 'Successfully registered. Please verify your email.'
      showRegisterSuccess.value = true
      httpError.value = ''
      resetForm()
      // utilModelsService.closeModal(AUTHMODELHS.REGISTER)
    })
    .catch((error: any) => {
      httpError.value = error?.response?.data?.message
    })
    .finally(() => {
      recapthaToken.value = ''
      rechaptha.value.reset()
      isLoading.value = false
    })
})

onMounted(() => {
  window.addEventListener('open.hs.overlay', async ($overlayEl) => {
    if (($overlayEl.target as HTMLElement)?.id === AUTHMODELHS.REGISTER && !showRecaptcha.value) {
      showRecaptcha.value = true
    }
  })
  showRegisterSuccess.value = false
  watchEffect(() => {
    if (password.value !== confirmPassword.value) {
      passwordNotMatchError.value = 'Password does not match'
    } else {
      passwordNotMatchError.value = ''
    }
  })
  watch(httpError, () => {
    if (httpError.value) {
      if (httpErrorTimeOut.value) clearTimeout(httpErrorTimeOut.value)
      httpErrorTimeOut.value = setTimeout(() => {
        httpError.value = ''
      }, 8000)
    }
  })
  watch(showRegisterSuccess, () => {
    if (showRegisterSuccess.value) {
      setTimeout(() => {
        showRegisterSuccess.value = false
        utilModelsService.closeModal(AUTHMODELHS.REGISTER)
      }, 15000)
    }
  })
})
onBeforeUnmount(() => {
  window.removeEventListener('open.hs.overlay', () => {})
})
</script>

<template>
  <ModelLayout
    title="Sign up to JDoodle"
    description="Join us and get access all features"
    :hs="AUTHMODELHS.REGISTER"
  >
    <!-- Social Login -->
    <SocialLogin v-if="!showRegisterSuccess" />
    <!-- form -->
    <form @submit.prevent="onSubmit" v-if="!showRegisterSuccess">
      <BaseInput
        label="Email Address"
        :inputType="'email'"
        placeholder="joeDoe@example.com"
        autocomplete="email"
        :error="emailError"
        v-model="email"
      >
      </BaseInput>

      <BaseInput
        label="Display Name"
        :inputType="'text'"
        placeholder="Joe"
        autocomplete="username"
        :error="displayNameError"
        v-model="displayName"
      />

      <BaseInput
        id="register_pwd"
        label="Password"
        :inputType="'password'"
        placeholder="*****"
        :error="passwordError"
        v-model="password"
      />

      <BaseInput
        label="Confirm Password"
        :inputType="'password'"
        placeholder="*****"
        :error="confirmPasswordError"
        v-model="confirmPassword"
        class="mb-5"
      />
      <p :class="['p-xsmall ', 'error', { hidden: !passwordNotMatchError }]">
        {{ passwordNotMatchError }}
      </p>
      <div class="mb-5">
        <vue-recaptcha
          v-if="showRecaptcha"
          ref="rechaptha"
          :sitekey="sitekey || ''"
          :theme="colorTheme === 'dark' ? 'dark' : 'light'"
          @verify="recaptchaVerified"
          @expired="recaptchaError('Recaptcha expired. Please verify again.')"
          @fail="recaptchaError('Recaptcha failed. Please verify again.')"
          @error="recaptchaError"
        />
        <p :class="['p-xsmall ', 'error', { hidden: !recapthaError }]">
          {{ recapthaError }}
        </p>
      </div>

      <p :class="['p-xsmall', 'error', { hidden: !httpError }]">
        {{ httpError }}
      </p>

      <p :class="['p-xsmall', '', { hidden: !isLoading }]">Loading...</p>

      <button
        :disabled="!canSubmit"
        class="btn-primary btn-rounded-md mt-10 text-base"
        type="submit"
      >
        Sign Up
        <FontAwesomeIcon icon="fa-arrow-right" class="w-5" />
      </button>
    </form>

    <!-- Success message -->
    <div v-if="showRegisterSuccess" class="text-center">
      <p :class="['text-md', 'mb-5']">Thanks for registering to JDoodle.</p>
      <p :class="['text-sm', 'success']">
        We have sent you a confirmation email. Before logging in,
        <span class="font-bold underline">please check your email to complete registration.</span>
      </p>
    </div>

    <div
      class="mt-2 text-center text-sm text-neutral-600 dark:text-neutral-300"
      v-if="!showRegisterSuccess"
    >
      <p class="text-secondary mb-4 text-xs">
        By Clicking on Sign up, you agree to JDoodle’s <br />
        <RouterLink to="/terms" class="text-text-brand">Terms of Condition</RouterLink>
        and
        <RouterLink to="/terms" class="text-text-brand">Privacy Policy</RouterLink>
      </p>

      <span>Already have an account?</span>
      <button type="button" class="ml-1 text-text-brand" :data-hs-overlay="`#${AUTHMODELHS.LOGIN}`">
        Log In
      </button>
    </div>
  </ModelLayout>
</template>
