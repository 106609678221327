<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useIdeStore } from '@/stores/ide.store'
import ModelLayout from '@/layouts/ModelLayout.vue'
import { IDEVIEWMODELS } from '@/utils/models'
import shareService from '@/services/ide/settings/share.service'

import CopyShare from '@/components/ide/shared/CopyShareComp.vue'

const ideStore = useIdeStore()
const route = useRoute()

const httpErrorTimeOut = ref<number | null>(null)
const httpError = ref<string>('')
const httpSuccessTimeOut = ref<number | null>(null)
const httpSuccess = ref<string>('')
const loadUrlsInProgress = ref<boolean>(false)

const isProject = computed(() => {
  return ideStore.isProject && ideStore.isProjectId
})
const isAdvanced = computed(() => {
  return ideStore.isAdvanced
})
const isShared = computed(() => {
  return ideStore.isProject.url || ideStore.isProject.embedUrl
})
const isProjectUrl = computed(() => {
  return ideStore.isProject.url || ''
})
const isEmbedUrl = computed(() => {
  return ideStore.isProject.embedUrl || ''
})
const pluginId = computed(() => {
  return ideStore.isProject.pluginId || ''
})
const isProBundlePlan = computed(() => {
  return ideStore.isProject.isProBundlePlan || false
})
/**
 * Get the share url
 */
const share = async () => {
  loadUrlsInProgress.value = true

  await shareService
    .editableShare()
    .then(() => {
      httpSuccess.value = 'Project Successfully Shared.'
    })
    .catch((error) => {
      httpError.value =
        error.response.data.message || 'Something went wrong. Please try again later.'
    })

  loadUrlsInProgress.value = false
}
/**
 * Get the share url
 */
const unshare = async () => {
  loadUrlsInProgress.value = true

  await shareService
    .editableUnshare()
    .then(() => {
      httpSuccess.value = 'Project Successfully Unshared.'
    })
    .catch((error) => {
      httpError.value =
        error.response.data.message || 'Something went wrong. Please try again later.'
    })

  loadUrlsInProgress.value = false
}
onMounted(() => {
  watch(route, () => {
    httpError.value = ''
    httpSuccess.value = ''
  })
  watch(httpError, () => {
    if (httpError.value) {
      if (httpErrorTimeOut.value) clearTimeout(httpErrorTimeOut.value)
      httpErrorTimeOut.value = setTimeout(() => {
        httpError.value = ''
      }, 8000)
    }
  })
  watch(httpSuccess, () => {
    if (httpSuccess.value) {
      if (httpSuccessTimeOut.value) clearTimeout(httpSuccessTimeOut.value)
      httpSuccessTimeOut.value = setTimeout(() => {
        httpSuccess.value = ''
      }, 5000)
    }
  })
})
</script>

<template>
  <ModelLayout title="Editable Share" :hs="IDEVIEWMODELS.EDITABLESHARE">
    <p :class="['p-xsmall ', 'error', , { hidden: !httpError }]">
      {{ httpError }}
    </p>
    <p :class="['p-xsmall', 'success', { hidden: !httpSuccess }]">
      {{ httpSuccess }}
    </p>
    <div v-if="!isProject">
      <p class="p-small">Please save the project or open a project to share.</p>
      <div class="flex w-full gap-2">
        <button class="btn-primary btn-rounded-md" :data-hs-overlay="`#${IDEVIEWMODELS.SAVE}`">
          Save
        </button>
        <button
          class="btn-secondary btn-rounded-md"
          :data-hs-overlay="`#${IDEVIEWMODELS.MYPROJECTS}`"
        >
          My Projects
        </button>
      </div>
      <button
        class="btn-secondary btn-rounded-md"
        :data-hs-overlay="`#${IDEVIEWMODELS.SETTINGS}`"
        data-hs-overlay-close
      >
        Close
      </button>
    </div>
    <div v-else>
      <div v-if="!isShared">
        <p class="p-small">Do you want to share this project?</p>
        <div class="flex w-full gap-2">
          <button :disabled="loadUrlsInProgress" class="btn-primary btn-rounded-md" @click="share">
            Get Share Url<span v-if="!isAdvanced">s</span>
          </button>
          <button
            class="btn-secondary btn-rounded-md"
            :data-hs-overlay="`#${IDEVIEWMODELS.SETTINGS}`"
            data-hs-overlay-close
          >
            Close
          </button>
        </div>
      </div>
      <div v-else>
        <CopyShare
          :isShowShared="true"
          :projectUrl="isProjectUrl"
          :showEmbed="!isAdvanced"
          :embedUrl="isEmbedUrl"
          :pluginId="pluginId"
          :isProBundlePlan="isProBundlePlan"
          :closeHsModal="IDEVIEWMODELS.EDITABLESHARE"
        />
        <div class="flex w-full gap-2">
          <button
            :disabled="loadUrlsInProgress"
            class="btn-primary btn-rounded-md"
            @click="unshare"
          >
            Unshare Url<span v-if="!isAdvanced">s</span>
          </button>
          <button
            class="btn-secondary btn-rounded-md"
            :data-hs-overlay="`#${IDEVIEWMODELS.EDITABLESHARE}`"
            data-hs-overlay-close
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </ModelLayout>
</template>
