<script lang="ts" setup>
import { ref, watch, onBeforeMount, onBeforeUnmount, onMounted } from 'vue'
import htmlEditorService from '@/services/ide/languages/html/editor.service'
import CopyShare from '@/components/ide/shared/CopyShareComp.vue'

import { META } from '@/utils/meta'
import { IDECONSTANT } from '@/utils/ide'

import ideService from '@/services/ide/ide.service'
import dashboardIdeService from '@/services/dashboard.ide.service'
import { useDashboardIdeStore } from '@/stores/dashboard.ide.store'

import { useDashboardProjectStore } from '@/stores/project.store'
import axios from 'axios'
import DashboardCardEmptyMessageButton, {
  type IDashboardEmptyData
} from '@/components/shared/dashboard/DashboardCardEmptyMessageButton.vue'

import TreeItemRoComp from '@/components/ide/ide/settings/TreeItemRoComp.vue'
import { compact } from 'lodash'

const dashboardIdeStore = useDashboardIdeStore()
const isShowShared = ref(false)
const showEmbed = ref(false)
const embedUrl = ref('test' as string)
const projectUrl = ref('test' as string)
const pluginId = ref<string | null>(null)
const isHtml = ref(false)

const treeData = ref({} as Object)
const treeHome = ref({} as Object)
const libraries = ref('' as string)

const meta = { ...META }

const dashboardProjectStore = useDashboardProjectStore()

const projectId = ref<string>()
const projectLang = ref<String>()
const isAdvanced = ref<Boolean>()
const isLoading = ref<boolean>()

watch(
  () => dashboardProjectStore.projectId,
  async () => {
    await cleanIdeAndGetPreview()
  }
)

onMounted(async () => {
  // if (dashboardProjectStore.projectId !== dashboardProjectStore.deleteProjectId) {
  //   await cleanIdeAndGetPreview()
  // }

  //This appears to not have a purpose when mounted. Perhaps make it a computed value if needed to change preview screen when filtered?
  // const isProjectPresent: boolean = dashboardProjectStore.filteredProjects.some(
  //   (project: IDashboardProject): boolean => {
  //     return project.id === dashboardProjectStore.projectId
  //   }
  // )
  if (dashboardProjectStore.projectId) {
    await cleanIdeAndGetPreview()
  }
})

/**
 *
 */
const cleanIdeAndGetPreview = async () => {
  isLoading.value = true
  dashboardIdeService.cleanIde()
  isShowShared.value = false
  showEmbed.value = false

  // get script for this project ID
  projectId.value = dashboardProjectStore.projectId
  projectLang.value = dashboardProjectStore.projectLang
  isAdvanced.value = dashboardProjectStore.isAdvanced
  let aceCode: string
  for (var obj in meta) {
    if (obj === projectLang.value) {
      if (meta[obj].aceCode !== undefined) {
        aceCode = meta[obj].aceCode as string
      } else {
        aceCode = 'java'
      }
    }
  }

  await axios
    .post('/api/doodle/file', {
      id: projectId.value,
      lang: projectLang.value,
      isMultiFile: isAdvanced.value
    })
    .then((response: { data: any }) => {
      const data = response.data
      isProjectSelected.value = true
      if (isAdvanced.value) {
        if (response.data.project.libraries) {
          data.project.libraries = compact(data.project.libraries.split(' '))
        }
        data.project.script = JSON.parse(data.project.script)
        treeData.value = data.project.script?.treeData
        treeHome.value = data.project.script?.home
        libraries.value = data.project.libraries
      }

      if (!isAdvanced.value && data.project.language !== 'html') {
        dashboardIdeStore.setScript(data.project.script)
        dashboardIdeService.initOnRouterChange(aceCode)
      }

      if (data.project.language === 'html') {
        const script = htmlEditorService.convertToHtmlString(
          data.project.docType,
          data.project.htmlHead,
          data.project.htmlBody,
          data.project.jsCode,
          data.project.cssCode
        )
        dashboardIdeStore.setScript(script)
        dashboardIdeService.initOnRouterChange('html')
      }

      if (data.project.shared === true) {
        isShowShared.value = true
        showEmbed.value = true
        embedUrl.value = data.project.embedUrl
        projectUrl.value = data.project.url
        pluginId.value = data.project.pluginId || null
      }
    })
    .catch((error) => {
      throw error
    })

  isLoading.value = false
}

const emptyDataPreview: IDashboardEmptyData = {
  message: 'Select a project form preview'
}

const isProjectSelected = ref<Boolean>(false)

onBeforeMount(() => {
  ideService.cleanIdeStore()
  dashboardIdeService.cleanDashboardIdeStore()
})
onBeforeUnmount(() => {
  dashboardIdeService.cleanDashboardIdeStore()
  ideService.cleanIdeStore()
  //Remove selected Id when leaving
  dashboardProjectStore.projectId = undefined
})
</script>

<template>
  <div v-show="isProjectSelected">
    <p class="p-medium mb-5" v-if="!isLoading">
      {{ dashboardProjectStore.projectName }}
    </p>
    <p class="p-medium mb-5" v-if="isLoading">Loading...</p>
    <div v-show="isAdvanced">
      <div class="section-primary h-96 w-full min-w-full rounded-lg">
        <TreeItemRoComp :treeData="treeData" :treeHome="treeHome" :libraries="libraries" />
      </div>
    </div>

    <div v-show="!isAdvanced">
      <div
        :id="IDECONSTANT.DASHBOARDCODEEDITOR"
        class="section-primary block h-96 w-full min-w-full p-8"
      ></div>
    </div>

    <div v-show="isShowShared" class="section-primary my-5 rounded-md p-2">
      <CopyShare
        :isShowShared="isShowShared"
        :projectUrl="projectUrl"
        :showEmbed="!isHtml && !isAdvanced"
        :embedUrl="embedUrl"
        :pluginId="(pluginId as string)"
      />
    </div>
  </div>
  <div v-show="!isProjectSelected">
    <DashboardCardEmptyMessageButton :empty-data="emptyDataPreview" />
  </div>
</template>
