<script lang="ts" setup>
import CaseStudyBannerImageAndHeading, {
  type ICaseStudyBInHData
} from '@/components/shared/casestudy/CaseStudyBannerImageAndHeading.vue'

import itextBanner from '@/assets/images/casestudy/itext/iText1.png'

const sectionData: ICaseStudyBInHData = {
  image: {
    src: itextBanner,
    alt: '',
    copyright: '© scyther5 from Getty Images via Canva.com'
  },
  heading:
    'Revolutionising Technology Showcase: How JDoodle Simplified iText Library Demonstrations',
  sHeading: 'Streamlining Technology Demonstrations for Seamless Engagement',
  clientName: 'iText',
  date: 'January 2023'
}
</script>

<template>
  <CaseStudyBannerImageAndHeading :sectionData="sectionData" />
</template>
