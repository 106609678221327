<script setup lang="ts">
import { computed, type PropType } from 'vue'

export interface IHsModel {
  title: string
  hs: string
}

export interface IUseCaseBannerData {
  orangeTextAboveTitle?: string
  largeTitle?: string
  title?: string
  caseStudyNumber?: string
  highlightedTitle?: string
  listHeading?: string
  list?: string[]
  description?: string
  textLeftImageRight: boolean
  widerTitle?: boolean
  primaryButton?: {
    text: string
    link: string
  }
  secondaryButton?: {
    text: string
    link: string
  }
  image: {
    src: string
    alt: string
  }
  useCaseLink?: {
    href: string
    text: string
  }
  isUseCase?: boolean
}

const props = defineProps({
  sectionData: {
    type: Object as PropType<IUseCaseBannerData>,
    required: true
  },
  hsModel: {
    type: Object as PropType<IHsModel>
  },
  removePadding: {
    type: Boolean
  },
  countAndFeatures: {
    type: Array
  },
  caseIndex: {
    type: Number,
    default: 0
  }
})

const textDivClass = computed(() => {
  if (props.sectionData?.widerTitle) {
    return props.sectionData?.textLeftImageRight
      ? 'col-span-6 md:order-none'
      : 'col-span-1 md:order-2'
  } else {
    if (props.sectionData.image.src) {
      return props.sectionData?.textLeftImageRight
        ? 'col-span-6 md:order-none'
        : 'col-span-1 md:order-2'
    } else {
      return props.sectionData?.textLeftImageRight
        ? 'col-span-8 md:order-none'
        : 'col-span-1 md:order-2'
    }
  }
})

const imageDivClass = computed(() => {
  if (props.sectionData?.widerTitle) {
    return props.sectionData?.textLeftImageRight
      ? 'col-span-6 md:order-none'
      : 'col-span-1 md:order-1'
  } else {
    return props.sectionData?.textLeftImageRight
      ? 'col-span-6 md:order-none'
      : 'col-span-1 md:order-1'
  }
})
</script>

<template>
  <div
    class="section-primary"
    :class="[{ 'py-8 md:py-20': !removePadding }, { 'md:py-26': caseIndex }]"
  >
    <div class="view-container">
      <div
        class="grid items-center justify-center gap-2 md:gap-10"
        :class="props.sectionData?.textLeftImageRight ? 'md:grid-cols-12' : 'md:grid-cols-2'"
      >
        <div class="pt-10" :class="textDivClass">
          <span
            v-if="props.sectionData?.orangeTextAboveTitle"
            class="text-brand mb-3 block text-sm font-medium uppercase tracking-wide"
            >{{ props.sectionData?.orangeTextAboveTitle }}</span
          >
          <!-- TODO: Add the numbers for Solution pages -->
          <span v-if="props.sectionData?.caseStudyNumber">{{ sectionData.caseStudyNumber }}</span>
          <h1
            v-if="sectionData.largeTitle"
            class="heading-large z-20 mb-6 max-w-2xl text-start font-bold leading-[3rem]"
            v-html="props.sectionData?.largeTitle"
          ></h1>
          <h2
            v-if="sectionData.title"
            class="mb-6 text-start text-3xl font-extrabold leading-[2.5rem] md:text-[32px]"
            :class="props.sectionData.widerTitle ? 'max-w-2xl' : 'max-w-xl'"
          >
            {{ props.sectionData?.title }}
            <span v-if="sectionData.highlightedTitle" class="text-brand">{{
              sectionData.highlightedTitle
            }}</span>
          </h2>

          <!-- Description -->
          <p
            v-if="props.sectionData?.description"
            class="text-secondary mb-5 text-lg leading-[1.5rem]"
            :class="sectionData.isUseCase ? 'max-w-xl' : 'max-w-md'"
          >
            {{ props.sectionData?.description }}
          </p>
          <!-- List * List Title-->
          <span v-if="sectionData.listHeading" class="my-6 inline-block text-xl font-semibold">{{
            sectionData.listHeading
          }}</span>
          <p
            v-for="(item, idx) in props.sectionData?.list"
            :key="idx"
            class="text-secondary relative mb-1.5 pl-8 text-lg"
          >
            <span class="absolute left-0 w-6">
              <FontAwesomeIcon icon="fa-check" class="text-brand h-4 w-4 pt-1"></FontAwesomeIcon>
            </span>
            {{ item }}
          </p>

          <a
            v-if="sectionData.useCaseLink"
            class="text-brand mb-10 mt-14 block text-lg underline"
            :href="sectionData.useCaseLink.href"
            >{{ sectionData.useCaseLink.text }}</a
          >

          <!-- Custom component. E.g. Modal Button or Language Search -->
          <div class="my-5">
            <slot />
          </div>

          <!-- Link Buttons -->
          <div class="my-4 flex gap-8 md:my-8">
            <a
              v-if="props.sectionData?.primaryButton"
              :href="props.sectionData?.primaryButton.link"
              class="btn btn-primary w-fit py-2.5"
              >{{ props.sectionData?.primaryButton.text }}</a
            >
            <a
              v-if="props.sectionData?.secondaryButton"
              :href="props.sectionData.secondaryButton.link"
              class="btn btn-secondary w-fit border py-2.5"
              >{{ props.sectionData.secondaryButton.text }}</a
            >
          </div>
        </div>

        <div class="h-full w-full" :class="imageDivClass">
          <div
            v-if="caseIndex"
            class="text-[200px] font-extrabold leading-none text-neutral-100 dark:text-[#222] md:mt-[-100px]"
            :class="props.sectionData?.textLeftImageRight ? 'pr-6 text-right' : 'text-left'"
          >
            <span v-if="caseIndex < 10">0{{ caseIndex }}</span>
            <span v-else>{{ caseIndex }}</span>
          </div>

          <img
            v-if="sectionData.image.src"
            class="max-h-[450px] w-full"
            :class="
              props.sectionData?.textLeftImageRight ? 'm-auto md:order-none' : 'pl-10 md:order-1'
            "
            :src="sectionData.image.src"
            :alt="sectionData.image.alt"
          />
          <div v-else class="m-auto h-[60%] w-[80%] rounded-lg"></div>
        </div>
      </div>
    </div>
  </div>
</template>
