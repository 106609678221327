<script setup lang="ts">
import { useColorMode } from '@vueuse/core'
import { ref, watch } from 'vue'

const colorTheme = useColorMode()
const fillColor = ref('')

watch(
  colorTheme,
  () => {
    if (colorTheme.value == 'dark') {
      fillColor.value = '#fff'
    } else {
      fillColor.value = '#000'
    }
  },
  { immediate: true }
)
</script>

<template>
  <div
    class="section-secondary bg-[bottom:10rem] bg-[url(@/assets/images/about/JD_symbol.png)] bg-[length:60%] bg-[bottom_right_-42%] bg-no-repeat px-4 py-16 md:bg-[length:44%] md:bg-[center_right_-22%] md:px-0"
  >
    <h2 class="heading-large text-center">Our Story</h2>
    <div class="view-container">
      <div class="ms-0 flex w-full flex-col md:ms-10 lg:ms-16">
        <!-- Quote section -->
        <div class="mb-8 w-full max-w-xl">
          <div class="relative -left-6 top-10 z-10 w-24">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="116"
              height="89"
              viewBox="0 0 116 89"
              fill="none"
            >
              <path
                opacity="0.1"
                d="M39.9591 38.5404C48.4711 42.56 53.9093 50.5991 53.9093 61.712C53.9093 77.7902 42.3236 88.1938 26.9547 88.1938C11.3493 88.1938 0 77.7902 0 61.712C0 55.0916 0.945778 49.8898 7.80267 34.7573L22.9351 0H51.072L39.9591 38.5404ZM101.908 38.5404C110.42 42.56 115.858 50.5991 115.858 61.712C115.858 77.7902 104.272 88.1938 88.9031 88.1938C73.2978 88.1938 61.9484 77.7902 61.9484 61.712C61.9484 55.0916 62.8942 49.8898 69.7511 34.7573L84.8836 0H113.02L101.908 38.5404Z"
                :fill="fillColor"
              />
            </svg>
          </div>

          <p class="text-brand relative z-10 mb-3 text-lg font-bold italic">
            "JDoodle aims to democratise access to tools that the next generation of developers need
            to be competitive."
          </p>
          <p class="z-10 text-sm text-text-primary dark:text-text-primary-dark">
            - <span class="font-bold">Gokul Chandrasekaran</span>, Founder and CEO
          </p>
        </div>

        <div class="max-w-lg">
          <p class="text-secondary mb-8 text-justify text-sm leading-[1.5rem]">
            JDoodle was born out of a vision to revolutionise development, make coding accessible to
            everyone and create a more equitable and inclusive software development industry.
            Founded by a software developer driven by his own frustrations with complex and
            inefficient development tools,
            <span class="font-bold"
              >JDoodle started as a $20 side project. With a single page and programming
              language</span
            >, it quickly gained traction and attracted a dedicated user base of over 1 million
            individuals.
          </p>
          <p class="text-secondary text-justify text-sm leading-[1.5rem]">
            What sets JDoodle apart is our commitment to user feedback. Every feature and
            improvement on our platform is a direct result of listening to our users' needs and
            requests.
            <span class="font-bold"
              >With the support of Main Sequence, a venture firm founded by Australia's national
              science agency, we have secured a significant investment to scale up
            </span>
            and reach our goal of serving 10 million users globally.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
