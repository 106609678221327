<script setup lang="ts">
import unsw from '@/assets/images/about/unsw-logo.png'
import MainsequenceBlack from '@/assets/images/about/main_sequence_black.png'
import MainsequenceWhite from '@/assets/images/about/main_sequence_white.png'
import { useColorMode } from '@vueuse/core'
import { ref, watch } from 'vue'

const colorTheme = useColorMode()
const mainSequence = ref('')

watch(
  colorTheme,
  () => {
    if (colorTheme.value == 'dark') {
      mainSequence.value = MainsequenceWhite
    } else {
      mainSequence.value = MainsequenceBlack
    }
  },
  { immediate: true }
)
</script>

<template>
  <div class="section-primary py-16">
    <div class="view-container h-fit">
      <h2 class="heading-large mb-16 text-center">Our Esteemed Partners</h2>
      <div class="flex flex-col items-center justify-center gap-16 sm:flex-row sm:gap-10">
        <div class="w-80">
          <img :src="unsw" alt="UNSW Sydney" class="object-contain sm:max-h-40" />
        </div>

        <div class="sm:w-[30rem]">
          <img :src="mainSequence" alt="Main Sequence" class="object-contain" />
        </div>
      </div>
    </div>
  </div>
</template>
