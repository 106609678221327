<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { usePluginStore } from '@/stores/plugin.store'
import CustomPluginIDE from '@/views/CustomPluginIDE.vue'

const pluginStore = usePluginStore()

const gridContainer = ref<any>(null)
const gridContainerMaxWidth = ref<number>(0)

const isDragging = computed(() => {
  return pluginStore.isEditable && pluginStore.isDragging
})
/**
 * Checks the grid container max width
 */
const checkGridContainerMaxWidth = () => {
  if (!gridContainer.value) return
  const width = gridContainer.value?.clientWidth
  const widthToDeduct = width < 300 ? 2 : 20
  const maxWidth = Math.round(width - widthToDeduct)
  gridContainerMaxWidth.value = maxWidth
}

onMounted(() => {
  if (!pluginStore.isEditable) return
  checkGridContainerMaxWidth()
  window.addEventListener('resize', () => {
    checkGridContainerMaxWidth()
  })
})
</script>

<template>
  <div class="w-full">
    <div
      ref="gridContainer"
      id="gridContainer"
      :class="[
        'flex w-full flex-1 items-center justify-center',
        {
          'animate-pulse': isDragging
        }
      ]"
    >
      <div class="w-fit overflow-scroll" :style="`max-width:${gridContainerMaxWidth}px`">
        <CustomPluginIDE />
      </div>
    </div>
  </div>
</template>
