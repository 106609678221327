<script setup lang="ts">
import BaseInput from '@/components/forms/BaseInput.vue'
import BaseTextbox from '@/components/forms/BaseTextbox.vue'
import { until, useColorMode } from '@vueuse/core'
import { FORM_SELECT_VALUE_TYPE } from '@/components/forms/form.enum'
import { useField, useForm } from 'vee-validate'
import { ref, computed } from 'vue'
import { VueRecaptcha } from 'vue-recaptcha'
import { string } from 'yup'
import { RECAPTCHA_KEYS } from '@/utils/recaptcha'
import BaseSelect from '@/components/forms/BaseSelect.vue'
import { whatLikeToTalkData } from '@/utils/sharedData/whatLikeToTalk'
import { countriesData, type ICountry } from '@/utils/sharedData/country'
import type { IContactUsRequest } from '@/services/contact.service'
import contactService from '@/services/contact.service'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const colorTheme = useColorMode()
const sitekey = RECAPTCHA_KEYS.RECAPTCHALOGINSITEKEY
const rechaptha = ref<any>(null)
const recapthaToken = ref<string>('')
const recapthaError = ref<string>('')

// const httpErrorTimeOut = ref<number | null>(null)
const httpError = ref<string>('')
const httpSuccess = ref<string>('')

const { errors, handleSubmit, resetForm } = useForm()

/**
 * Function used to validate email using regex.
 * Matches one or more characters that can appear in the domain name
 * Matches two or more letters for the TLD. This ensures that the TLD contains at least two characters.
 * @param input user input
 * @returns true or message
 */
const isEmail = (input: string) => {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(input)
    ? true
    : 'Must be a valid email'
}

const { value: email, errorMessage: emailError } = useField('email', isEmail)
const { value: fName, errorMessage: fNameError } = useField('firstName', string().required().min(2))

const { value: lName, errorMessage: lNameError } = useField('lastName', string().required().min(2))

const { value: phone, errorMessage: phoneError } = useField('phone', string().required().min(3))

const { value: organisation, errorMessage: organisationError } = useField(
  'organisation',
  string().required().min(2)
)

const { value: likeToTalk, errorMessage: likeToTalkError } = useField(
  'likeToTalk',
  string().required()
)

const { value: message, errorMessage: messageError } = useField('message', string().nullable())

const selectedCountryName = ref(countriesData[0].name)
const selectedCountryObject = computed(() => {
  return countriesData.find((country: ICountry) => country.name == selectedCountryName.value)
})
likeToTalk.value = whatLikeToTalkData[0]

const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0
})

/**
 * Handles the response from the reCAPTCHA verification and sets the token.
 * @param response - The reCAPTCHA response token.
 */
const recaptchaVerified = (response: string) => {
  recapthaToken.value = response
  recapthaError.value = ''
}

/**
 * Handles the reCAPTCHA error and sets the error message.
 * @param error - The reCAPTCHA error message.
 */
const recaptchaError = (error: string) => {
  recapthaToken.value = ''
  recapthaError.value = error
  rechaptha.value.reset()
}

const onSubmit = handleSubmit(async (values) => {
  rechaptha.value.execute()

  await until(recapthaToken).changed()

  if (!recapthaToken.value || recapthaError.value) {
    return
  }

  const contactForm: IContactUsRequest = {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    phoneNumber: `${selectedCountryObject.value?.code}-${values.phone}`,
    organisation: values.organisation,
    country: selectedCountryName.value,
    likeToTalk: values.likeToTalk,
    message: values.message
  }

  await contactService
    .sendContactForm(contactForm)
    .then(() => {
      httpError.value = ''
      resetForm()
      httpSuccess.value = 'Thank you for contacting us. We will get back to you soon.'
    })
    .catch((error: any) => {
      if (error.data?.error) httpError.value = error.data.error
      if (error?.response?.data?.message) httpError.value = error?.response?.data?.message
      httpSuccess.value = ''
    })
    .finally(() => {
      recapthaToken.value = ''
      rechaptha.value.reset()
    })
})
</script>

<template>
  <div class="view-container">
    <div class="m-auto mt-12 max-w-4xl py-4 text-center">
      <h2 class="title-heading">Let's Revolutionize Software<br />Development Together</h2>
      <p class="title-description">Contact us regarding any concerns or inquiries.</p>

      <div class="py-8">
        <form class="flex flex-col gap-3" @submit.prevent="onSubmit">
          <div class="grid gap-4 md:grid-cols-2">
            <BaseInput
              label="First Name"
              :inputType="'text'"
              placeholder="e.g. John"
              :error="fNameError"
              v-model="fName"
              :isContactus="true"
            />
            <BaseInput
              label="Last Name"
              :inputType="'text'"
              placeholder="e.g. Dowry"
              :error="lNameError"
              v-model="lName"
              :isContactus="true"
            />
            <BaseInput
              label="Email address"
              :inputType="'email'"
              placeholder="e.g. john.dowry@example.com"
              autocomplete="email"
              :error="emailError"
              v-model="email"
              :isContactus="true"
            />

            <BaseInput
              label="Phone Number"
              :inputType="'text'"
              placeholder="+1 236 457 8989"
              :error="phoneError"
              v-model="phone"
              :isContactus="true"
            />
          </div>

          <BaseInput
            label="Company (if any)"
            :inputType="'text'"
            placeholder="e.g. Company XYZ"
            :error="organisationError"
            v-model="organisation"
            :isContactus="true"
          />
          <BaseSelect
            label="What would you like to talk about?"
            :list="whatLikeToTalkData"
            v-model="likeToTalk"
            :error="likeToTalkError"
            :isContactus="true"
          />

          <BaseSelect
            label="Country"
            :list="countriesData"
            v-model="selectedCountryName"
            :valueType="FORM_SELECT_VALUE_TYPE.NAME"
            :isContactus="true"
          />

          <BaseTextbox
            label="Additional message"
            v-model="message"
            :rows="8"
            :error="messageError"
            placeholder="Type a message here..."
            :isContactus="true"
          />

          <vue-recaptcha
            size="invisible"
            ref="rechaptha"
            :theme="colorTheme === 'dark' ? 'dark' : 'light'"
            :sitekey="sitekey || ''"
            @verify="recaptchaVerified"
            @fail="recaptchaError('Recaptcha failed. Please verify again.')"
            @error="recaptchaError"
          />
          <p :class="['p-xsmall', 'error', { hidden: !recapthaError }]">
            {{ recapthaError }}
          </p>

          <p :class="['p-xsmall ', 'error', , { hidden: !httpError }]">
            {{ httpError }}
          </p>

          <div class="flex">
            <button
              :disabled="!canSubmit"
              class="btn-primary btn-rounded-md mr-5 h-fit w-64"
              type="submit"
            >
              Submit
              <FontAwesomeIcon icon="fa-arrow-right" class="h-4 w-4"></FontAwesomeIcon>
            </button>
            <p class="text-secondary text-left text-sm">
              By pressing the submit button, I agree to JDoodle contacting me by email and/or phone
              to share opportunities exclusively available to Select or Enterprise customers. I also
              understand that any information I’ve shared in this form is subject to JDoodle Privacy
              Policy.
            </p>
          </div>
          <p class="p-small success mt-2">
            {{ httpSuccess }}
          </p>
        </form>
      </div>
    </div>
  </div>
</template>
