<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import projectService, {
  type IDashboardProject,
  type IDashboardProjectPageRequest
} from '@/services/dashboard.project.service'
import ProjectList from '@/components/dashboard/project/ProjectList.vue'
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'
import DashboardCardEmptyMessageButton, {
  type IDashboardEmptyData
} from '@/components/shared/dashboard/DashboardCardEmptyMessageButton.vue'
import { useDashboardProjectStore } from '@/stores/project.store'

onMounted(() => {
  loadDashboardProjects()
})

const dashboardProjectStore = useDashboardProjectStore()
const projectData = ref<IDashboardProject[]>([] as IDashboardProject[])
const page: IDashboardProjectPageRequest = { page: 0, size: 5000 }

const searchTerm = ref('')

const selectedLanguages = ref([] as string[])

const selectedStartCreatedDate = ref('' as string)
const selectedEndCreatedDate = ref('' as string)

const selectedStartModifiedDate = ref('' as string)
const selectedEndModifiedDate = ref('' as string)

const emptyDataProjects: IDashboardEmptyData = {
  message: 'You do not have any projects yet.'
}

/**
 * @returns list of projects
 */
const loadDashboardProjects = async () => {
  return await projectService
    .loadDashboardProjects(page)
    .then((response) => {
      dashboardProjectStore.filteredProjects = response
      projectData.value = response
      return projectData.value
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @returns list of projects
 */
const languagesInProjects = () => {
  const languageSet: Set<string> = new Set()
  projectData.value.forEach((project) => {
    languageSet.add(project.language)
  })
  return Array.from(languageSet)
}

/**
 * @param selectedLanguages List of langauges selected by the user
 * @param projects List of projects received for the user from the backend
 * @returns list of projects
 */
const filteredProjectsByLanguages = (
  selectedLanguages: string[],
  projects: IDashboardProject[]
) => {
  if (emptySelectedLanguages.value !== 0) {
    return projects.filter((project) => selectedLanguages.includes(project.language))
  } else {
    return projects
  }
}

/**
 * @param startDate Start date to fillter the project
 * @param endDate End date to fillter the project
 * @param projects List of projects received for the user from the backend
 * @param mode Either 'created' or 'modified'
 * @returns list of projects
 */
const filteredProjectsByDateRange = (
  startDate: string,
  endDate: string,
  projects: IDashboardProject[],
  mode: string
) => {
  const start = new Date(startDate).setHours(0, 0, 0, 0)
  const end = new Date(endDate).setHours(0, 0, 0, 0)

  if (startDate !== '' && endDate !== '') {
    return projects.filter((project) => {
      if (mode === 'created') {
        const projectDate = new Date(project.dateCreated).setHours(0, 0, 0, 0)
        if (projectDate >= start && end >= projectDate) return project
      } else if (mode === 'modified') {
        const projectDate = new Date(project.lastUpdated).setHours(0, 0, 0, 0)
        if (projectDate >= start && end >= projectDate) return project
      }
    })
  } else {
    return projects
  }
}

const emptySelectedLanguages = computed(() => {
  return selectedLanguages.value.length
})

watch(
  () => searchTerm.value,
  (string) => {
    if (string) {
      dashboardProjectStore.filteredProjects = [...projectData.value].filter(
        (p: IDashboardProject) => {
          return p.name.toLowerCase().includes(string.toLowerCase())
        }
      )
    } else {
      dashboardProjectStore.filteredProjects = [...projectData.value]
    }
  }
)

/**
 *
 */
const clearFilter = () => {
  selectedLanguages.value = [] as string[]

  selectedStartCreatedDate.value = '' as string
  selectedEndCreatedDate.value = '' as string

  selectedStartModifiedDate.value = '' as string
  selectedEndModifiedDate.value = '' as string

  dashboardProjectStore.filteredProjects = projectData.value
}

/**
 *
 */
const applyFilter = () => {
  dashboardProjectStore.setShowFilter(false) // Reset data
  dashboardProjectStore.filteredProjects = projectData.value

  // Start filters
  dashboardProjectStore.filteredProjects = filteredProjectsByLanguages(
    selectedLanguages.value,
    dashboardProjectStore.filteredProjects
  )

  dashboardProjectStore.filteredProjects = filteredProjectsByDateRange(
    selectedStartCreatedDate.value,
    selectedEndCreatedDate.value,
    dashboardProjectStore.filteredProjects,
    'created'
  )

  dashboardProjectStore.filteredProjects = filteredProjectsByDateRange(
    selectedStartModifiedDate.value,
    selectedEndModifiedDate.value,
    dashboardProjectStore.filteredProjects,
    'modified'
  )
}
</script>

<template>
  <div v-if="projectData.length !== 0">
    <div class="relative">
      <input
        placeholder="Search Projects"
        name="projectSearch"
        id="searchBar"
        class="section-primary p-small block w-full rounded-lg px-4 py-3"
        v-model="searchTerm"
      />
      <div class="pointer-events-none absolute inset-y-0 right-2 flex items-center pl-3">
        <FontAwesomeIcon icon="fa-magnifying-glass" class="text-brand h-5 w-5" />
      </div>
      <!-- Dark -->
      <!-- <img
                :src="item.darkIcon ? item.darkIcon : item.icon"
                class="light:hidden me-2 w-8 dark:inline-block"
              /> -->
      <!-- Light -->
      <!-- <img
                v-if="item.darkIcon"
                :src="item.icon"
                class="light:inline-block me-2 w-8 dark:hidden"
              /> -->
    </div>

    <div
      v-show="dashboardProjectStore.showFilter"
      class="mt-5 rounded-lg border border-black px-5 pt-2 dark:border-white"
    >
      <div class="my-2">
        <Multiselect
          v-model="selectedLanguages"
          :options="languagesInProjects()"
          mode="tags"
          placeholder="Select Language"
          :classes="{
            container:
              'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-black dark:border-white rounded section-combined  leading-snug outline-none',
            placeholder:
              'flex items-center h-full absolute left-0 top-0 pointer-events-none section-combined leading-snug pl-3.5 text-gray-400 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5 rounded-l-md p-small',
            tags: 'flex-grow flex-shrink flex flex-wrap items-center  pl-2 rtl:pl-0 rtl:pr-2 section-combined',
            tagsSearchWrapper:
              'inline-block relative  mb-1 flex-grow flex-shrink h-full section-combined'
          }"
        />
      </div>

      <div class="w-5/5 mb-2 mt-5">
        <div class="p-xsmall my-2 font-semibold">Created</div>
        <div
          class="p-small section-combined flex justify-around rounded-md border border-black dark:border-white"
        >
          <input
            type="date"
            v-model="selectedStartCreatedDate"
            class="text-primary section-combined border-section-primary rounded-l px-2 py-1"
          />
          <div class="text-secondary section-dashboard w-fit px-2 py-3">to</div>
          <input
            type="date"
            v-model="selectedEndCreatedDate"
            class="section-combined rounded-r px-2 py-1"
          />
        </div>
      </div>

      <div class="w-5/5 mb-2 mt-5">
        <div class="p-xsmall my-2 font-semibold">Modified</div>
        <div
          class="p-small section-combined flex justify-around rounded-md border border-black dark:border-white"
        >
          <input
            type="date"
            v-model="selectedStartModifiedDate"
            class="section-combined rounded-l px-2 py-1"
          />
          <div class="text-secondary section-dashboard w-fit px-2 py-3 align-middle">to</div>
          <input
            type="date"
            v-model="selectedEndModifiedDate"
            class="section-combined rounded-r px-2 py-1"
          />
        </div>
      </div>

      <div class="relative flex justify-between">
        <button class="btn-dashboard btn-rounded-md my-5 w-fit" @click="applyFilter">
          Apply Filters
        </button>
        <div class="my-auto flex gap-2">
          <div class="p-xsmall cursor-pointer underline" @click="clearFilter">clear</div>
          <!-- <div
            class="p-xsmall cursor-pointer underline"
            @click="dashboardProjectStore.setShowFilter(false)"
          >
            close
          </div> -->
        </div>
      </div>
    </div>

    <div class="no-scrollbar mt-5 h-[500px] overflow-y-auto">
      <ProjectList :projectData="dashboardProjectStore.filteredProjects" />
    </div>
  </div>
  <div v-else>
    <DashboardCardEmptyMessageButton :empty-data="emptyDataProjects" />
  </div>
</template>

<style>
:root {
  --ms-tag-color: #ffffff;
  --ms-tag-bg: #fd5200;
  --ms-tag-font-size: 0.8rem;
  --ms-font-size: 1rem;
  --ms-radius: 5px;
  --ms-option-font-size: 0.75rem;
  --ms-tag-my: 0rem;
  /* --ms-empty-color: black; */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.multiselect-options {
  background: white;
  color: rgb(62, 62, 62);
  font-size: 0.75rem;
}
</style>
