<script setup lang="ts">
import { computed, ref, markRaw, defineAsyncComponent, onMounted } from 'vue'
import SubscriptionComponent from '@/components/shared/subscription/SubscriptionComponent.vue'
import { SUBSCRIPTION_TYPE_ENUM } from '@/components/shared/subscription/enum/subscription.enum'
import { useRoute } from 'vue-router'

enum PRICING_TAB_ENUM {
  PLATFORM = 'Platform',
  API = 'API'
}
const route = useRoute()

const props = defineProps({
  showTabButtons: {
    type: Boolean,
    required: false,
    default: false
  }
})

const tabs = [
  {
    name: PRICING_TAB_ENUM.PLATFORM,
    component: markRaw(
      defineAsyncComponent(() => import('@/components/pricing/PlatformPricing.vue'))
    )
  },
  {
    name: PRICING_TAB_ENUM.API,
    component: markRaw(defineAsyncComponent(() => import('@/components/shared/PricingSlider.vue')))
  }
]

const currentTab = ref(tabs[0])

onMounted(() => {
  if (route.params.tab) {
    currentTab.value = tabs.filter((item) => {
      return route.params.tab == item.name.toLowerCase()
    })[0]
  }
})

const subscriptionType = computed(() => {
  if (currentTab.value.name == PRICING_TAB_ENUM.API) return SUBSCRIPTION_TYPE_ENUM.COMPILER_API
  else return SUBSCRIPTION_TYPE_ENUM.BUNDLE
})
</script>

<template>
  <div class="section-primary pb-36 pt-24">
    <SubscriptionComponent :subscriptionType="subscriptionType" />

    <div class="view-container mb-10" v-if="props.showTabButtons">
      <div class="section-tertiary m-auto flex w-fit gap-2 rounded-lg">
        <nav
          class="flex grow rounded-xl bg-[#17191F] p-2 transition"
          aria-label="Tabs"
          role="tablist"
        >
          <button
            v-for="(tab, index) in tabs"
            :key="index"
            type="button"
            class="text-md h-14 w-28 rounded-lg text-center font-medium font-normal text-gray-500 text-white hover:text-gray-300 md:w-48 lg:w-56"
            :class="{ 'bg-[#363944] hover:text-white': tab.name === currentTab.name }"
            role="tab"
            @click="currentTab = tab"
          >
            {{ tab.name }}
          </button>
        </nav>
      </div>
    </div>

    <keep-alive>
      <component role="tabpanel" :is="currentTab.component" />
    </keep-alive>
  </div>
</template>
