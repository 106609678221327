<script lang="ts" setup>
import { computed } from 'vue'
import { type IStudentInAssignment } from '@/components/guru/interface/IStudentInAssignment'
import { TEACH_STUDENT_ASSIGNMENT_STATUS } from '@/components/guru/enums/teach.enum'
import type { IUserInStudentGroup } from '@/components/guru/interface/IUserInStudentGroup'
import DashboardCardEmptyMessageButton from '@/components/shared/dashboard/DashboardCardEmptyMessageButton.vue'

const props = defineProps({
  assignmentId: {
    type: Number,
    required: false
  },
  instituteCode: {
    type: String,
    required: true
  },
  students: {
    type: Array<IStudentInAssignment | IUserInStudentGroup>,
    required: false
  }
})

const noOfStudents = computed(() => props.students?.length)

const yetToStart = computed(
  () =>
    (props.students as IStudentInAssignment[])?.filter((student: IStudentInAssignment) => {
      return student.status == TEACH_STUDENT_ASSIGNMENT_STATUS.YET_TO_START
    }).length
)

const submitted = computed(
  () =>
    (props.students as IStudentInAssignment[])?.filter((student: IStudentInAssignment) => {
      return student.status == TEACH_STUDENT_ASSIGNMENT_STATUS.SUBMITTED
    }).length
)

const partiallyEvaluated = computed(
  () =>
    (props.students as IStudentInAssignment[])?.filter((student: IStudentInAssignment) => {
      return student.status == TEACH_STUDENT_ASSIGNMENT_STATUS.PARTIALLY_EVALUATED
    }).length
)

const inProgress = computed(
  () =>
    (props.students as IStudentInAssignment[])?.filter((student: IStudentInAssignment) => {
      return student.status == TEACH_STUDENT_ASSIGNMENT_STATUS.IN_PROGRESS
    }).length
)

const requestedReevaluation = computed(
  () =>
    (props.students as IStudentInAssignment[])?.filter((student: IStudentInAssignment) => {
      return student.status == TEACH_STUDENT_ASSIGNMENT_STATUS.REEVALUATION_REQUESTED
    }).length
)

const resultsAnnounced = computed(
  () =>
    (props.students as IStudentInAssignment[])?.filter((student: IStudentInAssignment) => {
      return student.status == TEACH_STUDENT_ASSIGNMENT_STATUS.RESULT_ANNOUNCED
    }).length
)
</script>

<template>
  <div>
    <div class="m-auto mt-5 flex flex-col">
      <table class="table-auto" v-if="props.students && props.students?.length > 0">
        <tr>
          <td class="p-1 font-bold">No. of students:</td>
          <td class="p-1">{{ noOfStudents }}</td>
        </tr>
        <tr>
          <td class="p-1 font-bold">Yet to start:</td>
          <td class="p-1">{{ yetToStart }}</td>
        </tr>
        <tr>
          <td class="p-1 font-bold">Completed</td>
          <td class="p-1">
            {{ submitted }}
          </td>
        </tr>
        <tr>
          <td class="p-1 font-bold">Requested Re-evaluation:</td>
          <td class="p-1">{{ requestedReevaluation }}</td>
        </tr>
        <tr>
          <td class="p-1 font-bold">Partially Evaluated:</td>
          <td class="p-1">{{ partiallyEvaluated }}</td>
        </tr>
        <tr>
          <td class="p-1 font-bold">Results Announced:</td>
          <td class="p-1">{{ resultsAnnounced }}</td>
        </tr>
        <tr>
          <td class="p-1 font-bold">In Progress:</td>
          <td class="p-1">{{ inProgress }}</td>
        </tr>
      </table>

      <DashboardCardEmptyMessageButton
        v-else-if="props.students && props.students?.length < 1"
        :emptyData="{ message: 'No students added yet' }"
      />
    </div>
  </div>
</template>
