<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import projectsService from '@/services/ide/projects.service'
import { useIdeStore } from '@/stores/ide.store'
import ModelLayout from '@/layouts/ModelLayout.vue'
import { IDEVIEWMODELS } from '@/utils/models'

import BaseInput from '@/components/forms/BaseInput.vue'

import { useField, useForm } from 'vee-validate'
import { string } from 'yup'

const ideStore = useIdeStore()
const route = useRoute()

const httpError = ref<string>('')
const httpErrorTimeOut = ref<number | null>(null)
const httpSuccess = ref<string>('')
const httpSuccessTimeOut = ref<number | null>(null)
const httpInProgress = ref<boolean>(false)

const { errors, handleSubmit, resetForm } = useForm()
const { value: projectName, errorMessage: projectNameError } = useField(
  'projectName',
  string().required()
)

const isHttpInProgress = computed(() => {
  return httpInProgress.value
})
const currentProjectName = computed(() => {
  return ideStore.isProject?.name || ''
})
const isProject = computed(() => {
  return (ideStore.isProject && ideStore.isProjectId) || false
})
const buttonText = computed(() => {
  return isProject.value ? 'Save As' : 'Save'
})
const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0
})
/**
 * Save the project again
 */
const saveAgain = async () => {
  httpInProgress.value = true
  httpError.value = ''
  httpSuccess.value = ''
  await projectsService
    .saveProjectActual()
    .then((response) => {
      if (response?.status === 200) {
        httpSuccess.value = 'Project Updated and Saved Successfully'
      }
    })
    .catch((err) => {
      httpError.value = err.message
    })
    .finally(() => {
      httpInProgress.value = false
    })
}
const onSubmit = handleSubmit(async (values) => {
  httpInProgress.value = true
  httpError.value = ''
  httpSuccess.value = ''
  const savedAs: boolean = isProject.value
  await projectsService
    .checkForDubplicateProjectName(values.projectName)
    .then(async () => {
      await projectsService
        .saveProjectActual(isProject.value, values.projectName)
        .then((response) => {
          if (response?.status === 200) {
            httpSuccess.value = savedAs
              ? `Project Saved as ${values.projectName} Successfully`
              : 'New Project Saved Successfully'
          }
        })
        .catch((err) => {
          httpError.value = err.response.data.message ? err.response.data.message : err.message
        })
    })
    .catch((err) => {
      httpError.value = err.response.data.message ? err.response.data.message : err.message
    })
    .finally(() => {
      httpInProgress.value = false
      resetForm()
    })
})
onMounted(() => {
  watch(route, () => {
    httpError.value = ''
    httpSuccess.value = ''
  })
  watch(httpError, () => {
    if (httpError.value) {
      if (httpErrorTimeOut.value) clearTimeout(httpErrorTimeOut.value)
      httpErrorTimeOut.value = setTimeout(() => {
        httpError.value = ''
      }, 8000)
    }
  })
  watch(httpSuccess, () => {
    if (httpSuccess.value) {
      if (httpSuccessTimeOut.value) clearTimeout(httpSuccessTimeOut.value)
      httpSuccessTimeOut.value = setTimeout(() => {
        httpSuccess.value = ''
      }, 8000)
    }
  })
})
</script>

<template>
  <ModelLayout title="Save your Project" :hs="IDEVIEWMODELS.SAVE">
    <p :class="['p-xsmall ', 'error', , { hidden: !httpError }]">
      {{ httpError }}
    </p>
    <p :class="['p-xsmall', 'success', { hidden: !httpSuccess }]">
      {{ httpSuccess }}
    </p>
    <p :class="['p-xsmall', , { hidden: !isHttpInProgress }]">
      JDoodle is trying to save the project, Please wait...
    </p>
    <div v-if="isProject">
      <div class="flex items-center gap-2 rounded-lg">
        <input
          type="checkbox"
          id="hs-basic-with-description-checked"
          class="section-primary checkbox-primary"
          checked
          v-model="ideStore.autoSaveOn"
        />
        <label for="hs-basic-with-description-checked" class="p-small my-2 block"
          >Auto Save On Execute?</label
        >
      </div>
      <p class="heading-xsmall text-sm">Save {{ currentProjectName }} Again</p>
      <button :disabled="isHttpInProgress" class="btn-primary btn-rounded-md" @click="saveAgain">
        Save Again
      </button>
    </div>
    <form @submit.prevent="onSubmit">
      <p v-show="isProject" class="heading-xsmall text-sm">{{ buttonText }}</p>
      <BaseInput
        label="Project Name"
        placeholder="Project Name"
        :error="projectNameError"
        v-model="projectName"
      />
      <div class="flex w-full gap-2">
        <button
          :disabled="!canSubmit || isHttpInProgress"
          class="btn-primary btn-rounded-md"
          type="submit"
        >
          {{ buttonText }}
        </button>
        <button
          class="btn-secondary btn-rounded-md"
          :data-hs-overlay="`#${IDEVIEWMODELS.SAVE}`"
          data-hs-overlay-close
        >
          Close
        </button>
      </div>
    </form>
  </ModelLayout>
</template>
