<script setup lang="ts">
import { AUTHMODELHS } from '@/utils/models'
import { useAuthStore } from '@/stores/auth.store'
import { storeToRefs } from 'pinia'

import ThemeChange from '@/components/header/auth/profile/ThemeChange.vue'
const authStore = useAuthStore()

const { isUserloggedIn, username, notificationCount } = storeToRefs(authStore)
</script>

<template>
  <div class="flex flex-col gap-4 md:flex-row lg:gap-10">
    <ThemeChange />
    <button
      v-if="!isUserloggedIn"
      type="button"
      class="link-primary p-normal flex items-center"
      :data-hs-overlay="`#${AUTHMODELHS.LOGIN}`"
    >
      <div
        class="link-primary md:btn-tertiary p-small items-center justify-start rounded-md transition-all sm:pt-6 md:block md:gap-2 md:px-4 md:py-2"
      >
        <div class="text-primary flex items-center gap-2 text-sm">
          <FontAwesomeIcon icon="fa-user" class="hidden md:block" />
          Login
        </div>
      </div>
    </button>

    <div v-if="isUserloggedIn && username !== null">
      <button type="button" class="link-primary p-small md:p-normal flex items-center">
        <RouterLink to="/dashboard">
          <div
            class="link-primary sm:btn-tertiary p-small items-center justify-start gap-2 rounded-md pt-6 transition-all sm:px-4 sm:py-2 sm:pl-2 md:block"
          >
            <div class="text-primary flex items-center gap-2 text-sm">
              <FontAwesomeIcon icon="fa-user" class="hidden md:block" />
              <span>Dashboard</span>
            </div>
            <span
              v-if="notificationCount && notificationCount > 0"
              class="absolute right-0 top-0 inline-flex -translate-y-1/2 translate-x-1/2 transform items-center rounded-full bg-btn-secondary px-1.5 py-0.5 text-xs font-medium text-btn-secondary-dark dark:bg-btn-secondary-dark dark:text-btn-secondary"
            >
              {{ notificationCount > 99 ? '99+' : notificationCount }}
            </span>
          </div>
        </RouterLink>
      </button>
    </div>
  </div>
</template>
