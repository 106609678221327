<script setup lang="ts">
import { languagesItems, type ILanguage, LANGUAGE_CATEGORY } from '@/utils/sharedData/languages'
import { computed, ref, watch } from 'vue'
import CodeCard from '@/components/code/CodeCard.vue'
import 'vue3-carousel/dist/carousel.css'
// @ts-ignore
import { Carousel, Slide, Navigation } from 'vue3-carousel'
const tabCarousel = ref(null as null | any)

const selectedFilter = ref(LANGUAGE_CATEGORY.POPULAR)
const languagesSorted: ILanguage[] = [...languagesItems].sort((a: ILanguage, b: ILanguage) =>
  a.displayName > b.displayName ? 1 : -1
)

const categories = [
  LANGUAGE_CATEGORY.POPULAR,
  LANGUAGE_CATEGORY.OOP,
  LANGUAGE_CATEGORY.SCRIPTING,
  LANGUAGE_CATEGORY.FUNCTIONAL,
  LANGUAGE_CATEGORY.WEB_DEV,
  LANGUAGE_CATEGORY.PROCEDURAL,
  LANGUAGE_CATEGORY.OTHERS
]

watch(
  () => tabCarousel.value?.data?.currentSlide.value,
  (newIndex) => {
    selectedFilter.value = categories[newIndex]
  }
)

const filteredLanguages = computed<ILanguage[]>(() => {
  if (selectedFilter.value == LANGUAGE_CATEGORY.POPULAR) {
    return languagesSorted
      .filter((lang: ILanguage) => lang.isPopular == true)
      .sort((a: ILanguage, b: ILanguage) => {
        if (a.isPopularNumber && b.isPopularNumber) {
          return a.isPopularNumber - b.isPopularNumber
        } else {
          if (a.isPopularNumber && !b.isPopularNumber) {
            return -1
          }

          if (b.isPopularNumber && !a.isPopularNumber) {
            return 1
          }

          return 0
        }
      })
  }
  return languagesSorted.filter((lang: ILanguage) => lang.category == selectedFilter.value)
})
</script>

<template>
  <div class="relative mb-4 w-full">
    <div class="border-b border-gray-200 dark:border-gray-700">
      <!-- Tab bar -->
      <div class="hidden w-full sm:block">
        <nav class="flex flex-wrap justify-between" aria-label="Tabs" role="tablist">
          <button
            v-for="(category, idx) in categories"
            :key="idx"
            type="button"
            class="inline-flex items-center gap-2 whitespace-nowrap border-b-[3px] px-3 py-3 text-sm text-text-primary hover:text-text-primary dark:text-text-primary-dark dark:hover:text-text-primary-dark"
            :class="
              selectedFilter == category
                ? 'border-orange-600 font-bold text-text-primary dark:text-text-primary-dark'
                : ' border-transparent text-gray-500 '
            "
            @click="selectedFilter = category"
          >
            {{ category }}
          </button>
        </nav>
      </div>

      <!-- Carousel (Mobile only) -->
      <div class="block sm:hidden" id="codeCarousel">
        <carousel
          :items-to-show="1"
          :wrap-around="true"
          :snap-align="'center-odd'"
          ref="tabCarousel"
        >
          <slide v-for="(category, idx) in categories" :key="idx" class="w-full px-10">
            <button
              :key="idx"
              type="button"
              class="inline-flex items-center gap-2 whitespace-nowrap border-b-[3px] px-3 py-3 text-sm text-gray-500"
              :class="
                selectedFilter == category ? 'border-orange-600 font-bold' : ' border-transparent '
              "
              @click="selectedFilter = category"
            >
              {{ category }}
            </button>
          </slide>

          <template #addons>
            <navigation class="text-black dark:text-white" />
          </template>
        </carousel>
      </div>
    </div>

    <div class="mt-8">
      <div class="grid auto-rows-fr grid-cols-2 gap-5 md:grid-cols-3 lg:grid-cols-4">
        <div v-for="lang in filteredLanguages" :key="lang.displayName">
          <CodeCard :language="lang" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#codeCarousel .carousel__prev,
#codeCarousel .carousel__next {
  width: 30px;
  height: 30px;
  background-color: transparent !important;
}

#codeCarousel .carousel__prev {
  left: 20px;
}

#codeCarousel .carousel__next {
  right: 20px;
}

.carousel__icon {
  background: none;
  color: gray !important;
}
</style>
