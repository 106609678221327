<script setup lang="ts">
import BaseInput from '@/components/forms/BaseInput.vue'
import SocialLogin from '@/components/header/auth/SocialLoginComp.vue'
import ModelLayout from '@/layouts/ModelLayout.vue'
import authService, { type IloginRequest } from '@/services/auth.service'
import { AUTHMODELHS } from '@/utils/models'
import { until, useColorMode } from '@vueuse/core'
import { useField, useForm } from 'vee-validate'
import { ref, computed, onMounted, watch, onBeforeUnmount } from 'vue'
import { VueRecaptcha } from 'vue-recaptcha'
import { string } from 'yup'
import { RECAPTCHA_KEYS } from '@/utils/recaptcha'
import exclamationCircle from '@/assets/images/shared/exclamation-circle.png'
const colorTheme = useColorMode()
import { useSubscribeStore } from '@/stores/subscribe.store'
import utilModelsService from '@/services/util.models.service'

const sitekey = RECAPTCHA_KEYS.RECAPTCHALOGINSITEKEY
const rechaptha = ref<any>(null)
const recapthaToken = ref<string>('')
const recapthaError = ref<string>('')
const showRecaptcha = ref<boolean>(false)

const httpErrorTimeOut = ref<number | null>(null)
const httpError = ref<string>('')

const { errors, handleSubmit, resetForm } = useForm()

const { value: email, errorMessage: emailError } = useField('username', string().required().email())
const { value: password, errorMessage: passwordError } = useField(
  'password',
  string().required().min(5)
)

/**
 * Handles the response from the reCAPTCHA verification and sets the token.
 * @param response - The reCAPTCHA response token.
 */
const recaptchaVerified = (response: string) => {
  recapthaToken.value = response
  recapthaError.value = ''
}

/**
 * Handles the reCAPTCHA error and sets the error message.
 * @param error - The reCAPTCHA error message.
 */
const recaptchaError = (error: string) => {
  recapthaToken.value = ''
  recapthaError.value = error
  rechaptha.value.reset()
}

const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0
})

const onSubmit = handleSubmit(async (values) => {
  rechaptha.value.execute()

  await until(recapthaToken).changed()

  if (!recapthaToken.value || recapthaError.value) {
    return
  }

  const requestPayload: IloginRequest = {
    username: values.username,
    password: values.password,
    recaptcha: recapthaToken.value
  }

  await authService
    .login(requestPayload)
    .then(() => {
      httpError.value = ''
      resetForm()
      useSubscribeStore().closeSubscribeModal()
      if (useSubscribeStore().selectedPlan) {
        utilModelsService.closeModal(AUTHMODELHS.LOGIN)
        useSubscribeStore().openSubscribeModal()
      } else {
        window.location.reload()
      }
    })
    .catch((error: any) => {
      if (error.data?.error) httpError.value = error.data.error
      if (error?.response?.data?.message) httpError.value = error?.response?.data?.message
    })
    .finally(() => {
      recapthaToken.value = ''
      rechaptha.value.reset()
    })
})
onMounted(async () => {
  window.addEventListener('open.hs.overlay', async ($overlayEl) => {
    if (($overlayEl.target as HTMLElement)?.id === AUTHMODELHS.LOGIN && !showRecaptcha.value) {
      showRecaptcha.value = true
    }
  })
  watch(httpError, () => {
    if (httpError.value) {
      if (httpErrorTimeOut.value) clearTimeout(httpErrorTimeOut.value)
      httpErrorTimeOut.value = setTimeout(() => {
        httpError.value = ''
      }, 8000)
    }
  })

  // Reset form when overlay modal is closed
  const el = document.getElementById(AUTHMODELHS.LOGIN)
  if (el) {
    el.addEventListener('close.hs.overlay', () => {
      resetForm()
    })
  }
})
onBeforeUnmount(() => {
  window.removeEventListener('open.hs.overlay', () => {})
})
</script>

<template>
  <ModelLayout
    title="Sign in to JDoodle"
    description="Sign in to enjoy all the benefits"
    :hs="AUTHMODELHS.LOGIN"
  >
    <!-- Social Login -->
    <SocialLogin />
    <!-- form -->
    <form @submit.prevent="onSubmit">
      <BaseInput
        label="Email Address"
        :inputType="'email'"
        placeholder="joe@example.com"
        autocomplete="email"
        :error="emailError"
        v-model="email"
      ></BaseInput>

      <BaseInput
        id="login_pwd"
        label="Password"
        :inputType="'password'"
        placeholder="*****"
        autocomplete="current-password"
        :error="passwordError"
        v-model="password"
      >
      </BaseInput>

      <div class="text-secondary text-right text-base">
        <button type="button" class="" :data-hs-overlay="`#${AUTHMODELHS.FORGOTPASSWORD}`">
          Forgot password
        </button>
      </div>

      <vue-recaptcha
        v-if="showRecaptcha"
        size="invisible"
        ref="rechaptha"
        :theme="colorTheme === 'dark' ? 'dark' : 'light'"
        :sitekey="sitekey || ''"
        @verify="recaptchaVerified"
        @fail="recaptchaError('Recaptcha failed. Please verify again.')"
        @error="recaptchaError"
      />
      <p :class="['p-xsmall', 'error', { hidden: !recapthaError }]">
        {{ recapthaError }}
      </p>

      <p :class="['p-xsmall ', 'error', , { hidden: !httpError }]">
        {{ httpError }}
      </p>

      <div class="text-secondary mt-5 flex gap-2 text-sm">
        <img :src="exclamationCircle" class="h-fit" />
        <p>
          To serve you better, we've upgraded our security. If you haven't reset your password since
          October 7, kindly do so now to access your account. Simply click on 'Forgot Password'.
          Thanks for your understanding!
        </p>
      </div>

      <button
        :disabled="!canSubmit"
        class="btn-primary btn-rounded-md mt-6 text-base"
        type="submit"
      >
        Login
        <FontAwesomeIcon icon="fa-arrow-right" class="w-5" />
      </button>
    </form>

    <div class="mt-1 text-center text-sm text-neutral-600 dark:text-neutral-300">
      New to Jdoodle?
      <button
        type="button"
        class="ml-1 text-text-brand"
        :data-hs-overlay="`#${AUTHMODELHS.REGISTER}`"
      >
        Sign Up
      </button>
    </div>
  </ModelLayout>
</template>
