<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import dashboardPluginService from '@/services/dashboard.plugin.service'
import { useDashboardPluginStore } from '@/stores/dashboard.plugin.store'

const router = useRouter()
const pluginStore = useDashboardPluginStore()

const httpError = ref<string>('')
const httpErrorTimeOut = ref<number | null>(null)
const httpSuccess = ref<string>('')
const httpSuccessTimeOut = ref<number | null>(null)
const httpInProgress = ref<boolean>(false)

const isHttpInProgress = computed(() => {
  return httpInProgress.value
})

const isPluginList = computed(() => {
  if (!pluginStore.pluginList) return false
  return pluginStore.pluginList.length > 0
})
const pluginList = computed(() => pluginStore.pluginList)

/**
 * View plugin
 * @param plugin Plugin
 */
const viewPlugin = (plugin: any) => {
  const { id } = plugin
  router.push({
    name: 'dashboard-configure-plugin',
    params: {
      pluginId: id
    }
  })
}
/**
 * Clone plugin
 * @param plugin Plugin
 */
const clonePlugIn = (plugin: any) => {
  httpInProgress.value = true
  httpError.value = ''
  httpSuccess.value = ''
  const { id } = plugin
  dashboardPluginService
    .clonePlugin(id)
    .then(
      (response: {
        data: {
          name: string
        }
      }) => {
        httpSuccess.value = `Plugin cloned as ${response.data.name}`
        dashboardPluginService.getPluginList()
      }
    )
    .catch((err: any) => {
      httpError.value = err.message
    })
    .finally(() => {
      httpInProgress.value = false
    })
}
/**
 * Show embed code
 * @param plugin Plugin
 */
const showEmbed = (plugin: any) => {
  dashboardPluginService.setEmbededCode(plugin)
  document.getElementById('integrateCode')?.scrollIntoView()
}

onMounted(() => {
  useDashboardPluginStore().setPluginList(null)
  httpError.value = ''
  httpSuccess.value = ''
  // fetch plugin list
  dashboardPluginService.getPluginList()

  watch(httpError, () => {
    if (httpError.value) {
      if (httpErrorTimeOut.value) clearTimeout(httpErrorTimeOut.value)
      httpErrorTimeOut.value = setTimeout(() => {
        httpError.value = ''
      }, 8000)
    }
  })
  watch(httpSuccess, () => {
    if (httpSuccess.value) {
      if (httpSuccessTimeOut.value) clearTimeout(httpSuccessTimeOut.value)
      httpSuccessTimeOut.value = setTimeout(() => {
        httpSuccess.value = ''
      }, 8000)
    }
  })
})
</script>

<template>
  <div v-if="isPluginList" class="text-secondary h-full w-full">
    <p :class="['p-xsmall ', 'error', , { hidden: !httpError }]">
      {{ httpError }}
    </p>
    <p :class="['p-xsmall', 'success', { hidden: !httpSuccess }]">
      {{ httpSuccess }}
    </p>
    <div class="max-h-96 overflow-auto">
      <ul v-for="(plugin, i) in pluginList" :key="i">
        <li class="section-primary my-4 flex h-fit w-full flex-col rounded-lg p-4">
          <h4 class="p-normal">{{ plugin.name }}</h4>
          <div class="flex flex-col items-start justify-between gap-5 xl:flex-row">
            <p class="text-secondary p-small flex-1 overflow-hidden text-ellipsis break-all">
              {{ plugin.description }}
            </p>
            <div class="flex flex-col gap-1 md:flex-row md:gap-4">
              <button
                :disabled="isHttpInProgress"
                class="btn btn-secondary w-full md:w-fit"
                @click="() => viewPlugin(plugin)"
              >
                View/Edit
              </button>
              <button
                :disabled="isHttpInProgress"
                class="btn btn-secondary w-full md:w-fit"
                @click="() => clonePlugIn(plugin)"
              >
                Clone
              </button>
              <button
                :disabled="isHttpInProgress"
                class="btn btn-secondary w-full md:w-fit"
                @click="() => showEmbed(plugin)"
              >
                Show Embed Code
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div v-else class="text-secondary h-full w-full">
    <div class="flex h-full w-full flex-col items-center justify-center">
      <div class="flex flex-col items-center justify-center gap-2">
        <h4 class="p-normal">No plugins found</h4>
        <p class="p-small">You have not created any plugins yet.</p>
      </div>
    </div>
  </div>
</template>
