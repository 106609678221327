<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import settingService from '@/services/ide/settings/setting.service'
import recaptchaService from '@/services/recaptcha.service'
import { useIdeStore } from '@/stores/ide.store'
import ModelLayout from '@/layouts/ModelLayout.vue'
import { IDEVIEWMODELS } from '@/utils/models'
import { IDECONSTANT } from '@/utils/ide'

const ideStore = useIdeStore()
const route = useRoute()

const uploadInput = ref<HTMLElement | null>(null)
const uploadInProgress = ref<boolean>(false)

const importErrorTimeOut = ref<number | null>(null)
const importError = ref<string>('')
const importSuccessTimeOut = ref<number | null>(null)
const importSuccess = ref<string>('')

const isCodeEditor = computed(() => {
  return ideStore.codeEditor !== null
})
const isUploadInProgress = computed(() => {
  return uploadInProgress.value || false
})
const isScript = computed(() => {
  return ideStore.isImportedFromFiles
})
/**
 * Handles the upload click
 */
const handleUploadClick = () => {
  uploadInput?.value?.click()
}
/**
 * import the project script from the file
 * @param event the event
 */
const importFromFile = async (event: Event) => {
  uploadInProgress.value = true
  await recaptchaService
    .callViaCaptcha()
    .then(async () => {
      settingService
        .importFromFile(event)
        .then((res) => {
          if (res) {
            importSuccess.value = 'Imported Successfully'
            ideStore.setImportedFromFiles(true)
          }
        })
        .catch((err) => {
          importError.value = err
          ideStore.setImportedFromFiles(false)
        })
    })
    .catch((err: any) => {
      importError.value = err || 'Something went wrong'
      ideStore.setImportedFromFiles(false)
    })
  uploadInProgress.value = false
}
/**
 * open the code editor with the project script
 */
const open = () => {
  settingService.openFromImport()
}
/**
 * Initialize the copy editor
 */
const initEditor = () => {
  settingService.initOpenEditor()
}
onMounted(() => {
  initEditor()
  watch(route, () => {
    initEditor()
  })
  watch(importError, () => {
    if (importError.value) {
      if (importErrorTimeOut.value) clearTimeout(importErrorTimeOut.value)
      importErrorTimeOut.value = setTimeout(() => {
        importError.value = ''
      }, 5000)
    }
  })
  watch(importSuccess, () => {
    if (importSuccess.value) {
      if (importSuccessTimeOut.value) clearTimeout(importSuccessTimeOut.value)
      importSuccessTimeOut.value = setTimeout(() => {
        importSuccess.value = ''
      }, 5000)
    }
  })
})
</script>

<template>
  <ModelLayout title="Open (from local file)" :hs="IDEVIEWMODELS.OPENFROMFILE">
    <input
      id="openFromFileInput"
      v-show="false"
      ref="uploadInput"
      class="hidden"
      type="file"
      name="file"
      @change="importFromFile($event)"
    />
    <p :class="['p-xsmall ', 'error', , { hidden: !importError }]">
      {{ importError }}
    </p>
    <p :class="['p-xsmall', 'success', { hidden: !importSuccess }]">
      {{ importSuccess }}
    </p>
    <div v-if="isCodeEditor && !isScript">
      <p class="p-normal">Import from local file</p>
      <div class="flex w-full gap-2">
        <button
          :disabled="isUploadInProgress"
          class="btn-primary btn-rounded-md"
          @click="handleUploadClick"
        >
          Import
        </button>
        <button
          class="btn-secondary btn-rounded-md"
          :data-hs-overlay="`#${IDEVIEWMODELS.OPENFROMFILE}`"
          data-hs-overlay-close
        >
          Close
        </button>
      </div>
    </div>
    <div v-show="isCodeEditor && isScript">
      <div
        :id="IDECONSTANT.OPEN_FROM_FILE_EDITOR"
        class="editor block h-36 w-full min-w-full rounded-lg"
      ></div>
      <div class="flex w-full gap-2">
        <button
          :disabled="isUploadInProgress"
          :data-hs-overlay="`#${IDEVIEWMODELS.OPENFROMFILE}`"
          data-hs-overlay-close
          class="btn-primary btn-rounded-md"
          @click="open"
        >
          Open
        </button>
        <button
          :disabled="isUploadInProgress"
          class="btn-primary btn-rounded-md"
          @click="handleUploadClick"
        >
          Import Again
        </button>
      </div>
      <button
        class="btn-secondary btn-rounded-md"
        :data-hs-overlay="`#${IDEVIEWMODELS.OPENFROMFILE}`"
        data-hs-overlay-close
      >
        Close
      </button>
    </div>
    <div v-if="!isCodeEditor">
      <p class="p-normal">Please open a project first</p>
      <button
        class="btn-secondary btn-rounded-md"
        :data-hs-overlay="`#${IDEVIEWMODELS.OPENFROMFILE}`"
        data-hs-overlay-close
      >
        Close
      </button>
    </div>
  </ModelLayout>
</template>
