<script setup lang="ts">
import UseCaseBanner, { type IUseCaseBannerData } from '@/components/shared/UseCaseBanner.vue'
import Homepage_3 from '@/assets/images/svg/Homepage_3.svg'

const teachAndAssessSection: IUseCaseBannerData = {
  orangeTextAboveTitle: 'Ed Tech / Dev Tech',
  title: 'Increase time spent by users on your product by 2x.',
  highlightedTitle: 'Supercharge your product with compiler APIs and Plugins',
  description:
    'Stand out from the crowd with our innovative and imaginative APIs and Plugins. Easily integrate code execution capabilities into your platform.',
  secondaryButton: {
    text: 'Learn More',
    link: '/solutions/education-technology'
  },
  textLeftImageRight: false,
  widerTitle: true,
  image: {
    src: Homepage_3,
    alt: 'animation svg'
  }
}
</script>

<template>
  <div class="section-primary">
    <UseCaseBanner :sectionData="teachAndAssessSection"> </UseCaseBanner>
  </div>
</template>
