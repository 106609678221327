<script setup lang="ts">
interface ITrait {
  heading: string
  body: string
}

const threeTraits: ITrait[] = [
  {
    heading: 'Customer focused',
    body: 'Our team is constantly listening to feedback from users and relentlessly working to improve the platform.'
  },
  {
    heading: 'Product led',
    body: 'We focus is on building great products that solve real problems for users by constantly iterating on the platform.'
  },
  {
    heading: 'Global outlook',
    body: 'We are a truly global company, driven by a commitment to serve users worldwide while maintaining an adaptive approach.'
  }
]
</script>

<template>
  <div class="section-primary py-16">
    <div class="view-container">
      <h1 class="heading-large mb-16 max-w-3xl leading-[3rem]">
        Our innovative platform and team help people globally become efficient coders, saving time
        and cost.
      </h1>

      <div class="grid-rows-fr grid gap-8 md:grid-cols-3">
        <div v-for="item in threeTraits" :key="item.heading" class="border-primary border-t py-6">
          <h2 class="text-brand mb-4 text-2xl">{{ item.heading }}</h2>
          <p class="text-secondary text-sm">{{ item.body }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="section-secondary py-10">
    <div class="view-container">
      <p class="text-md text-center uppercase">
        SERVING 1 MILLION+ CODERS | EMPOWERING 10K+ EDUCATION INSTITUTIONS | MADE WITH
        <FontAwesomeIcon icon="fa-heart" class="text-red-500" />
        IN AUSTRALIA
      </p>
    </div>
  </div>
</template>
