<script setup lang="ts">
import { onBeforeMount, onMounted, watch, computed, onBeforeUnmount } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { useRoute } from 'vue-router'
import ideService from '@/services/ide/ide.service'
import editorService from '@/services/ide/editor.service'
import pluginService from '@/services/ide/plugin/plugin.service'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import Recaptcha from '@/components/shared/RecaptchaComp.vue'
import Ide from '@/components/ide/embed/IdeComp.vue'
import Accordian from '@/components/ide/embed/AccordionComp.vue'
import Settings from '@/components/ide/embed/SettingsComp.vue'
import Output from '@/components/ide/embed/OutputComp.vue'
import FileComp from '@/components/ide/embed/FileComp.vue'
import PostToComp from '@/components/ide/embed/PostToComp.vue'
import FeaturePermissionMessage from '@/components/ide/plugin/FeaturePermissionMessage.vue'

const route = useRoute()
const ideStore = useIdeStore()
const pluginStore = usePluginStore()

const langDisplayName = computed(() => {
  return ideStore.ideMeta?.langDisplayName
})
const isCompileLang = computed(() => {
  return ideStore.ideMeta?.isCompile || ideStore.ideMeta?.language === 'java'
})
const isShareNotFound = computed(() => {
  return ideStore.shareNotFound
})
const isShareFoundHttpError = computed(() => {
  return ideStore.shareNotFoundHttpError
})
/**
 * Init plugin ide
 */
const initPluginIde = async () => {
  pluginStore.setIsPlugin(true)
  pluginStore.setIsCusomPlugin(false)
  if (!isShareNotFound.value) {
    editorService.injectAce()
    pluginService.initOnRouterChange()
    await new Promise((resolve) => setTimeout(resolve, 100))
    pluginStore.setPymKey(route.query.id as string)
    pluginService.initEmbedPlugin()
  }
}

onBeforeMount(() => {
  ideService.cleanIdeStore()
  pluginService.cleanPluginStore()
  initPluginIde()
  watch(route, () => {
    if (route.meta.canonicalPath || route.meta.customPlugin) initPluginIde()
  })
})

onBeforeRouteLeave((to, from, next) => {
  if (ideStore.isCodeUpdated) {
    if (
      window.confirm(
        `Are you sure you want to move from this ${langDisplayName.value}${
          isCompileLang.value ? ' Compiler' : ''
        } IDE?`
      )
    ) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
})

onMounted(async () => {
  window.onbeforeunload = function () {
    if (ideStore.isCodeUpdated) {
      return `Are you sure you want to move from this ${langDisplayName.value}${
        isCompileLang.value ? ' Compiler' : ''
      } IDE?`
    }
  }
})
onBeforeUnmount(() => {
  ideService.cleanIde()
  ideService.cleanIdeStore()
  pluginService.cleanPluginStore()
})
</script>

<template>
  <Recaptcha />
  <div class="relative">
    <div class="embed-ide">
      <FeaturePermissionMessage />
      <p
        v-if="isShareNotFound"
        :class="[
          'print:hidden, p-small ',
          'error',
          'text-center',
          'my-5',
          { hidden: !isShareNotFound }
        ]"
      >
        {{ isShareFoundHttpError || 'Share not found' }}
      </p>
      <div
        v-show="!isShareNotFound"
        :class="['section-tertiary', 'flex', 'flex-col', 'gap-5', 'rounded-lg', 'p-5']"
      >
        <Ide />
        <Accordian />
        <Settings />
        <Output />
        <FileComp />
        <PostToComp />
      </div>
    </div>
  </div>
</template>

<style>
@import url('@/assets/styles/embed-ide.css');

.gutter {
  background-color: gray;
  background-repeat: no-repeat;
  background-position: 50%;
}
</style>
