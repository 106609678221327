import { META } from '@/utils/meta'
import DashboardView from '@/views/DashboardView.vue'
import DashboardHome from '@/views/dashboard/DashboardHome.vue'
import APIPlans from '@/views/dashboard/api/APIPlans.vue'
import CreateAssignment from '@/views/dashboard/institution/CreateAssignment.vue'
import CurrentInstitute from '@/views/dashboard/institution/CurrentInstitute.vue'
import DashboardTeachView from '@/views/dashboard/institution/DashboardTeachView.vue'
import InstituteHome from '@/views/dashboard/institution/InstituteHome.vue'
import AssignmentAttendPreviewPage from '@/views/dashboard/institution/assignments/AssignmentAttendPreviewPage.vue'
import AssignmentPage from '@/views/dashboard/institution/assignments/AssignmentPage.vue'
import AssignmentStudentManagementVue from '@/views/dashboard/institution/assignments/AssignmentStudentManagement.vue'
import AssignmentsAsStudentPage from '@/views/dashboard/institution/assignments/AssignmentsAsStudentPage.vue'
import AssignmentsOfInstitute from '@/views/dashboard/institution/assignments/AssignmentsOfInstitute.vue'
import MyAssociations from '@/views/dashboard/institution/associations/MyAssociations.vue'
import AddEditUserPage from '@/views/dashboard/institution/users/AddEditUserPage.vue'
import AddStudentGroupPage from '@/views/dashboard/institution/users/AddStudentGroupPage.vue'
import EditStudentGroupPage from '@/views/dashboard/institution/users/EditStudentGroupPage.vue'
import InstituteInvitationPage from '@/views/dashboard/institution/users/InstituteInvitationPage.vue'
import InvitationManagement from '@/views/dashboard/institution/users/InvitationManagement.vue'
import StudentGroupsManagement from '@/views/dashboard/institution/users/StudentGroupsManagement.vue'
import UsersPage from '@/views/dashboard/institution/users/UsersPage.vue'
import CardDetails from '@/views/dashboard/payment/CardDetails.vue'
import PaymentHIstory from '@/views/dashboard/payment/PaymentHistory.vue'
import PaymentView from '@/views/dashboard/payment/PaymentView.vue'
import PlanDetails from '@/views/dashboard/payment/PlanDetails.vue'
import PluginPlans from '@/views/dashboard/plugin/PluginPlans.vue'
import MyProjects from '@/views/dashboard/project/MyProjects.vue'
import SettingsView from '@/views/dashboard/settings/SettingsView.vue'

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: META.default,
    component: DashboardView,
    children: [
      {
        path: '',
        name: 'dashboard-home',
        component: DashboardHome
      },
      {
        path: 'project',
        name: 'dashboard-project',
        component: MyProjects
      },
      {
        path: 'api',
        name: 'dashboard-api',
        component: APIPlans
      },
      {
        path: 'plugin',
        name: 'dashboard-plugin',
        component: PluginPlans
      },
      {
        path: 'settings',
        name: 'settings',
        component: SettingsView,
        meta: META.default
      },
      {
        path: 'institution',
        name: 'dashboard-institution',
        component: DashboardTeachView,
        children: [
          {
            path: '',
            name: 'teach-home',
            component: InstituteHome
          },
          {
            path: 'assignments',
            name: 'teach-all-my-assignments',
            component: AssignmentsAsStudentPage
          },
          {
            path: 'invitations',
            name: 'teach-my-invitations',
            component: InvitationManagement
          },
          {
            path: 'associations',
            name: 'teach-my-associations',
            component: MyAssociations
          },
          {
            path: ':insCode',
            name: 'teach-institute',
            // component: ,
            children: [
              {
                path: '',
                name: 'teach-current-institute',
                component: CurrentInstitute
              },
              {
                path: 'assignments',
                name: 'teach-institute-assignments',
                component: AssignmentsOfInstitute
              },
              {
                path: 'assignment/:assignmentId',
                name: 'teach-assignment-staff-view',
                children: [
                  {
                    path: '',
                    name: 'teach-assignment-staff',
                    component: AssignmentPage
                  },
                  {
                    path: 'manage-students',
                    name: 'teach-assignment-manage-students',
                    component: AssignmentStudentManagementVue
                  },
                  {
                    path: ':mode',
                    name: 'teach-assignment-attend-preview',
                    component: AssignmentAttendPreviewPage
                  }
                ]
              },

              {
                path: 'create-assignment',
                name: 'teach-create-assignment',
                component: CreateAssignment
              },
              {
                path: 'users',
                name: 'teach-users',
                component: UsersPage
              },
              {
                path: 'add-user',
                name: 'teach-add-user',
                component: AddEditUserPage
              },
              {
                path: 'edit-user',
                name: 'teach-edit-user',
                component: AddEditUserPage
              },
              {
                path: 'invitation/:invitationToken',
                name: 'teach-institute-invitation',
                component: InstituteInvitationPage
              },
              {
                path: 'student-groups',
                name: 'teach-student-groups',
                component: StudentGroupsManagement
              },
              {
                path: 'add-student-group',
                name: 'teach-add-student-group',
                component: AddStudentGroupPage
              },
              {
                path: 'student-group/:studentGroupId',
                name: 'teach-student-group',
                component: EditStudentGroupPage
              }
            ]
          }
        ]
      },
      {
        path: 'payment',
        name: 'dashboard-payment',
        component: PaymentView,
        children: [
          {
            path: 'plans',
            name: 'dashboard-plans',
            component: PlanDetails
          },
          {
            path: 'cards',
            name: 'dashboard-cards',
            component: CardDetails
          },
          {
            path: 'history',
            name: 'dashboard-history',
            component: PaymentHIstory
          }
        ]
      }
    ]
  }
]

export default dashboardRoutes
