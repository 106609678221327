<script setup lang="ts">
import ModelLayout from '@/layouts/ModelLayout.vue'
import { TEACHMODALHS } from '@/utils/models'
import { HttpStatusCode } from 'axios'
import router from '@/router'
import { deleteStudentGroup } from '@/services/teach.service'
import { ref } from 'vue'
import utilModelsService from '@/services/util.models.service'

const props = defineProps({
  instituteCode: {
    type: String,
    required: true
  },
  insStudentGroupId: {
    type: Number
  }
})

//Static data
const modalTitle = 'Delete Student Group'
const message = 'Are you sure you want to delete this student group?'
const errMsg = ref<string>('')
/**
 *
 */
const handleSubmit = async () => {
  try {
    const res = await deleteStudentGroup(props.instituteCode, props.insStudentGroupId!)

    if (res == HttpStatusCode.Ok) {
      router.push(`/dashboard/institution/${props.instituteCode}/student-groups`)
      utilModelsService.closeModal(TEACHMODALHS.DELETE_STUDENT_GROUP_CONFIRMATION)
      // .then(() => router.go(0))
    }
  } catch (err: any) {
    errMsg.value = err?.response.data.message
  }
}
</script>

<template>
  <ModelLayout
    :title="modalTitle"
    :hs="TEACHMODALHS.DELETE_STUDENT_GROUP_CONFIRMATION"
    :loadDataWhenClose="false"
  >
    <p class="my-5 break-normal text-center text-sm">{{ message }}</p>
    <p v-if="errMsg" class="error text-center text-sm">{{ errMsg }}</p>

    <div class="mt-8 flex justify-center gap-5">
      <button class="btn-secondary rounded-md px-5 py-2" @click="handleSubmit">Yes</button>

      <button class="btn-primary rounded-md px-5 py-2" data-hs-overlay-close>No</button>
    </div>
  </ModelLayout>
</template>
