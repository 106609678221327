<script setup lang="ts">
import createIcon from '@/assets/images/teach-and-assess/file-plus.svg'
import { SUBSCRIPTION_PRODUCT } from '@/components/shared/subscription/enum/subscription.enum'
import { useTeachStore } from '@/stores/teach.store'
import { TEACHMODALHS } from '@/utils/models'
import { computed, onMounted, ref, watch } from 'vue'
import RegisterInstituteModalVue from '../modals/RegisterInstituteModal.vue'
import DashboardCardEmptyMessageButton, {
  type IDashboardEmptyData
} from '@/components/shared/dashboard/DashboardCardEmptyMessageButton.vue'
import { getAssignments, getAssignmentsOfStudent, getUsers } from '@/services/teach.service'
import type IAssignmentMini from '../interface/IAssignmentMini'
import type { IAssignmentOfStudent } from '../interface/IAssignmentOfStudent'
import { useAuthStore } from '@/stores/auth.store'

const teachStore = useTeachStore()
const props = defineProps({
  insCode: {
    type: String,
    required: false
  },
  showButton: {
    type: Boolean,
    required: false,
    default: false
  }
})

const assignments = ref<IAssignmentMini[] | IAssignmentOfStudent[]>([])
const assignmentCount = computed(() => {
  return assignments.value?.length
})

onMounted(async () => {
  if (!useAuthStore().isUserloggedIn) return
  teachStore.getAndSetCurrentAssociation(props.insCode)

  if (!teachStore.instituteUsers) {
    if (props.insCode) {
      const res = await getUsers(props.insCode)
      teachStore.setUsers(res)
    }
  }

  await getAssignmentsForStaffOrStudent()
})

watch(
  () => props.insCode,
  async () => {
    teachStore.getAndSetCurrentAssociation(props.insCode)
    if (props.insCode) {
      if (!teachStore.instituteUsers) {
        const res = await getUsers(props.insCode)
        teachStore.setUsers(res)
      }

      await getAssignmentsForStaffOrStudent()
    }
  }
)

const currentInstitute = computed(() => teachStore.currentAssociation)

const emptyData: IDashboardEmptyData = {
  message: 'You have not created an institute',
  button: {
    buttonText: 'Create Institute',
    modal: TEACHMODALHS.REGISTER_INSTITUTE,
    product: SUBSCRIPTION_PRODUCT.TEACH
  }
}

/**
 *
 */
async function getAssignmentsForStaffOrStudent() {
  let res = undefined
  if (props.insCode) {
    if (teachStore.isStudent) {
      res = await getAssignmentsOfStudent(props.insCode)
    } else {
      res = await getAssignments(props.insCode)
    }
    assignments.value = res
  }
}
</script>

<template>
  <RegisterInstituteModalVue />
  <!-- Wrap dashboard card around here -->
  <div class="flex h-full flex-col justify-between">
    <div v-if="currentInstitute != null">
      <p class="mb-1 flex items-center gap-5">
        <span class="text-primary text-md font-bold">{{ currentInstitute?.name }} </span
        ><span class="text-secondary text-sm">{{ currentInstitute?.code }}</span>
      </p>
      <div class="text-secondary mb-4 text-sm">
        {{ currentInstitute?.description.slice(0, 200) }}
        <span v-if="currentInstitute.description.length > 200">...</span>
      </div>
      <div class="mb-3 flex flex-col gap-1">
        <p class="text-primary text-sm" v-if="!teachStore.isStudent">
          <span class="text-primary font-medium">Number of students enrolled:</span>
          {{ teachStore.studentCount }}
        </p>
        <p class="text-primary text-sm">
          <span v-if="!teachStore.isInstituteAdmin" class="text-primary font-medium"
            >Number of Assignments:
          </span>
          {{ assignmentCount }}
        </p>
      </div>
      <RouterLink
        :to="`/dashboard/institution/${insCode}/create-assignment`"
        class="btn-primary inline-block flex w-fit gap-1 rounded-md px-4 py-2 text-sm"
        v-if="props.showButton"
        >Create Assignment <img :src="createIcon" class="w-5 text-white"
      /></RouterLink>
    </div>
    <div v-else class="text-sm">
      <DashboardCardEmptyMessageButton :empty-data="emptyData" />
    </div>
  </div>
</template>
