<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useSubscribeStore } from '@/stores/subscribe.store'
import { apiPlansData } from '@/utils/sharedData/plans'
import Vue3Slider from 'vue3-slider'
import { useAuthStore } from '@/stores/auth.store'
import utilModelsService from '@/services/util.models.service'
import { AUTHMODELHS } from '@/utils/models'
import { delay } from 'lodash'
import DoodleMessage from '@/components/utils/DoodleMessage.vue'

const subscribeStore = useSubscribeStore()

onMounted(() => {
  subscribeStore.showSubscribeComponents = false
})

defineProps({
  title: {
    type: String,
    required: false,
    default: 'Pricing plans for all use cases'
  },
  description: {
    type: String,
    required: false,
    default:
      'Seamlessly integrate JDoodle into your applications and platforms for enhanced code execution and collaboration. With our flexible pricing plans, you can choose the option that best suits your needs.'
  }
})

const plans = computed(() => {
  return apiPlansData
})

const currentPlan = computed(() => {
  return subscribeStore.apiInitData?.plan
})

const isUnlimited = computed(() => {
  return subscribeStore.apiInitData?.isUnlimited
})

const progress = ref(300)
const fontSize = ref('54px')
const errorMsg = ref('')

/**
 *
 * @param val string
 * @returns string
 */
function format(val: number) {
  if (val >= 0 && val < 5) {
    return '200'
  } else if (val >= 5 && val < 15) {
    return '1K'
  } else if (val >= 15 && val < 35) {
    return '2K'
  } else if (val >= 35 && val < 75) {
    return '5K'
  } else if (val >= 75 && val < 150) {
    return '10K'
  } else if (val >= 150 && val < 250) {
    return '20K'
  } else if (val >= 250 && val < 350) {
    return '30K'
  } else if (val >= 350 && val < 450) {
    return '40K'
  } else if (val >= 450 && val <= 500) {
    return '50K'
  } else {
    return 'Unlimited'
  }
}

/**
 *
 * @param val number
 */
function endBar(val: number) {
  if (val >= 0 && val < 5) {
    progress.value = 0
  } else if (val >= 5 && val < 15) {
    progress.value = 10
  } else if (val >= 15 && val < 35) {
    progress.value = 20
  } else if (val >= 35 && val < 75) {
    progress.value = 50
  } else if (val >= 75 && val < 150) {
    progress.value = 100
  } else if (val >= 150 && val < 250) {
    progress.value = 200
  } else if (val >= 250 && val < 350) {
    progress.value = 300
  } else if (val >= 350 && val < 450) {
    progress.value = 400
  } else if (val >= 450 && val <= 500) {
    progress.value = 500
  } else {
    progress.value = 990
  }
}

const list = [
  'Credits to execute your code',
  'API Usage Report',
  'Email Support',
  'Unlimited integration'
]

const selectedPlan = computed(() => {
  return plans.value.filter((item) => {
    if (progress.value == 0) {
      return item.value == 'Free'
    } else {
      return item.value == `$${progress.value}`
    }
  })[0]
})

watch(
  () => progress.value,
  () => {
    if (progress.value > 500) {
      fontSize.value = '40px'
    } else {
      fontSize.value = '54px'
    }
  }
)

/**
 *
 * @param plan any
 */
const subscribePlan = (plan: any) => {
  if (plan.name == subscribeStore.apiInitData?.plan) {
    errorMsg.value = 'Please do not select your current plan.'
    delay(() => {
      errorMsg.value = ''
    }, 1000)
    return
  }

  subscribeStore.selectPlan(plan.name)
  if (useAuthStore().isUserloggedIn) {
    subscribeStore.openSubscribeModal()
  } else {
    utilModelsService.openModal(AUTHMODELHS.LOGIN)
  }
}
</script>

<template>
  <div class="view-container py-16">
    <div class="m-auto mb-12 max-w-xl text-center">
      <h2 class="heading-large mb-4">{{ title }}</h2>
      <p class="text-tertiary text-sm">
        {{ description }}
      </p>
    </div>

    <div class="m-auto mb-12 max-w-[770px]">
      <div class="mb-16 mt-24">
        <Vue3Slider
          v-model="progress"
          color="#fd5200"
          :height="10"
          track-color="#FEFEFE"
          :tooltip="true"
          :step="10"
          :max="550"
          :alwaysShowHandle="true"
          tooltipColor="#fd5200"
          tooltipTextColor="#fff"
          :handleScale="2.2"
          :formatTooltip="format"
          @change="endBar"
        >
        </Vue3Slider>
      </div>

      <div
        class="relative grid min-h-[448px] overflow-hidden rounded-xl border border-neutral-300 bg-white dark:border-neutral-700 md:grid-cols-2"
      >
        <div
          v-show="
            (!isUnlimited && selectedPlan.name == currentPlan) ||
            (isUnlimited && selectedPlan.name == 'Unlimited')
          "
          class="absolute left-0 top-0 rounded-br-lg bg-text-brand px-2 py-1 text-white"
        >
          Current
        </div>
        <div class="max-w-[385px] bg-white py-16 pl-12 pr-6 text-gray-800">
          <div class="flex-rows md:flex-cols flex gap-4">
            <p :class="`text-[${fontSize}] font-semibold`">
              {{ selectedPlan?.credits }}
            </p>
            <div :class="{ 'pt-3': selectedPlan?.credits != 'Unlimited' }">
              <p class="text-md text-slate-500">per day</p>
              <p class="text-2xl font-medium">Credits</p>
            </div>
          </div>
          <p class="mt-1.5 text-lg text-slate-500">
            Hassle-free integration process, reliable code execution
          </p>
          <div class="my-5">
            <span class="text-[54px] font-semibold" v-if="selectedPlan.value != 'Free'">{{
              selectedPlan.value
            }}</span>
            <span class="text-[54px] font-semibold" v-else>Free</span>
            <span class="ml-2 text-lg text-slate-500">/monthly</span>
          </div>
          <button
            v-if="
              selectedPlan.credits != 'Unlimited' &&
              !(selectedPlan.name == currentPlan && !isUnlimited)
            "
            class="btn btn-primary h-16 w-48 rounded-2xl text-base"
            @click="subscribePlan(selectedPlan)"
          >
            Get Started
          </button>

          <DoodleMessage
            class="text-secondary mt-1 inline-block"
            :message="errorMsg"
            message-class="text-text-brand"
            v-show="errorMsg"
          />

          <RouterLink
            v-if="selectedPlan.name == 'Unlimited'"
            :to="{ name: 'contactus' }"
            class="btn btn-primary inline-block h-16 w-48 rounded-2xl pt-5 text-base"
          >
            Contact us
          </RouterLink>
        </div>
        <div class="bg-black py-32 pl-12 pr-7 text-white">
          <p class="mb-6 text-lg font-semibold">What's included</p>
          <ul>
            <li class="mb-4 text-base font-light" v-for="(item, index) in list" :key="index">
              <FontAwesomeIcon icon="check" class="mr-4 h-3 w-3 rounded-full bg-text-brand p-1" />
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.vue3-slider .tooltip {
  bottom: 32px !important;
  height: auto !important;
  padding: 5px 22px !important;
  font-size: 24px;
  border-radius: 26px !important;
  display: block !important;
}
.vue3-slider .tooltip::before {
  border: var(--slider-tooltip-arrow-size, 5px) solid transparent;
  border-top-color: #fd5200;
  bottom: calc(var(--slider-tooltip-arrow-size, 5px) * -2);
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  width: 0;
}

.fade-enter,
.fade-leave-to {
  opacity: 100 !important;
}

.vue3-slider .handle {
  position: absolute;
  top: 0;
  width: var(--height, 6px);
  height: var(--height, 6px);
  border-radius: calc(var(--height, 6px) / 2);
  background-color: #fff !important;
  box-shadow: 0 0 1px 0px #bbb;
}
.vue3-slider .track {
  background-color: #eee !important;
  width: 100%;
  height: 100%;
  border-radius: calc(var(--height, 6px) / 2);
}
</style>
