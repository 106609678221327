<script setup lang="ts">
import type IAssignmentMini from '@/components/guru/interface/IAssignmentMini'
import type { IAssignmentOfStudent } from '@/components/guru/interface/IAssignmentOfStudent'
import DashboardCard, {
  type IDashboardCardLink
} from '@/components/shared/dashboard/DashboardCard.vue'
import DashboardCardEmptyMessageButton, {
  type IDashboardEmptyData
} from '@/components/shared/dashboard/DashboardCardEmptyMessageButton.vue'
import { getAssignments } from '@/services/teach.service'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'
import { useTeachStore } from '@/stores/teach.store'
import { getFormattedDate } from '@/utils/format'
import { computed, onMounted, ref, watch, type ComputedRef } from 'vue'
import { useRoute, useRouter } from 'vue-router'
const teachStore = useTeachStore()
const breadcrumbStore = useBreadcrumbStore()
const route = useRoute()
const insCode = ref(route.params.insCode as string)
const searchString = ref('')
const router = useRouter()
const assignmentsData = ref<IAssignmentMini[] | IAssignmentOfStudent[]>([])
onMounted(async () => {
  insCode.value = route.params.insCode as string
  if (insCode.value) {
    teachStore.getAndSetCurrentAssociation(insCode.value)
  }
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)

  let data = [] as IAssignmentMini[]
  data = await getAssignments(insCode.value)
  assignmentsData.value = data
})

watch(
  () => teachStore.associations,
  () => {
    teachStore.getAndSetCurrentAssociation(insCode.value)
    breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks.value)
  }
)

/**
 * Direct to either assignment page OR assignment attend page
 * @param assignment Assignment row clicked
 */
async function handleViewAssignment(assignment: IAssignmentMini | IAssignmentOfStudent) {
  if (insCode.value) {
    //Teachers view
    router.push(
      `/dashboard/institution/${insCode.value}/assignment/${
        (assignment as IAssignmentMini).insAssignmentId
      }`
    )
  } else {
    //Students view
    router.push(
      `/dashboard/institution/${(assignment as IAssignmentOfStudent).insCode}/assignment/${
        (assignment as IAssignmentOfStudent).insAssignmentId
      }/attend`
    )
  }
}

const filteredAssignments = computed(() => {
  if (searchString.value) {
    const lowerCaseString = searchString.value.toLowerCase()
    return (assignmentsData.value as any[]).filter(
      (assignment: IAssignmentMini | IAssignmentOfStudent) => {
        return assignment.title.toLowerCase().includes(lowerCaseString)
      }
    )
  } else {
    return assignmentsData.value
  }
})

/**
 * Handles emission of search string and filters invitations
 * @param emit The emitted search string
 */
function handleSearchInput(emit: string) {
  searchString.value = emit
}

const breadcrumbLinks = computed(() => {
  if (insCode.value) {
    return [
      {
        href: '/',
        title: 'Home'
      },
      {
        href: '/dashboard',
        title: 'Dashboard'
      },
      {
        href: '/dashboard/institution',
        title: 'Virtual Institutions'
      },
      {
        href: `/dashboard/institution/${insCode.value}`,
        title: `${teachStore.currentAssociation?.name}`
      },
      {
        href: `/dashboard/institution/${insCode.value}/assignments`,
        title: `${teachStore.currentAssociation?.name} Assignments`
      }
    ]
  } else {
    return [
      {
        href: '/',
        title: 'Home'
      },
      {
        href: '/dashboard',
        title: 'Dashboard'
      },
      {
        href: '/dashboard/institution',
        title: 'Virtual Institutions'
      },
      {
        href: `/dashboard/institution/assignments`,
        title: `All Assignments`
      }
    ]
  }
})

const emptyData: IDashboardEmptyData = {
  message: 'This institution does not have any assignments.'
}

const pageTitle = computed(() => {
  if (insCode.value) {
    return `${teachStore.currentAssociation?.name} Assignments`
  } else {
    return `Assignments`
  }
})

const createAssignmentBtn: ComputedRef<IDashboardCardLink> = computed<IDashboardCardLink>(() => {
  return {
    url: `/dashboard/institution/${insCode.value}/create-assignment`,
    name: 'Create Assignment',
    disabled: teachStore.draftLimitReached
  }
})
</script>

<template>
  <div class="h-full w-full">
    <DashboardCard
      :title="pageTitle"
      class="h-full w-full"
      :has-search-bar="true"
      :link="createAssignmentBtn"
      @searchInput="handleSearchInput"
    >
      <div class="m-auto max-w-5xl">
        <div v-if="assignmentsData.length > 0" class="mt-5 w-full">
          <!-- Student view (By Institute OR All My Assignments (no inscode)) -->
          <table class="w-full table-auto border-separate border-spacing-y-4">
            <!-- Staff view (By Institute)-->
            <thead class="text-start text-sm">
              <tr>
                <th class="px-3 py-1 text-start">Title</th>
                <th class="px-3 py-1 text-start">Status</th>
                <th class="px-3 py-1 text-start">Start Time</th>
                <th class="px-3 py-1 text-start">End Time</th>
                <th class="px-3 py-1 text-start">Questions</th>
                <th class="px-3 py-1 text-start">Students</th>
              </tr>
            </thead>
            <tbody class="">
              <tr
                @click="handleViewAssignment(assignment)"
                role="button"
                v-for="(assignment, index) in (filteredAssignments as IAssignmentMini[])"
                :key="index"
                class="section-primary overflow-hidden rounded-lg p-3"
              >
                <td class="rounded-s-lg p-3 text-sm">{{ assignment.title }}</td>
                <td class="p-3 text-sm">
                  {{ assignment.status }}
                </td>
                <td class="p-3 text-sm">
                  {{ assignment.startTime ? getFormattedDate(assignment.startTime) : '-' }}
                </td>
                <td class="p-3 text-sm">
                  {{ assignment.endTime ? getFormattedDate(assignment.endTime) : '-' }}
                </td>
                <td class="p-3 text-sm">
                  {{ assignment.numberOfQuestion }}
                </td>
                <td class="rounded-e-lg p-3 text-sm">
                  {{ assignment.numberOfStudent }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <DashboardCardEmptyMessageButton :empty-data="emptyData" />
        </div>
      </div>
    </DashboardCard>
  </div>
</template>
