<script setup lang="ts">
import type { ICountAndFeature } from '@/components/shared/CountAndFeature.vue'
import CountAndFeatureVue from '@/components/shared/CountAndFeature.vue'
import CodeCategories from '@/components/code/CodeCategories.vue'
import CodeSearchBar from '@/components/code/CodeSearchBar.vue'

const countAndFeatures: ICountAndFeature[] = [
  {
    count: '76+',
    description: 'Languages Supported'
  },
  {
    count: '2',
    description: 'Databases'
  },
  {
    count: 'AI',
    description: 'Powered'
  }
]
</script>

<template>
  <div class="section-primary pb-4 pt-16">
    <div class="view-container">
      <h1 class="heading-large mb-5 mt-10 break-normal text-center">
        Code.<span class="text-brand inline-block">Innovate.</span>Collaborate.
      </h1>
      <p class="text-secondary mb-16 text-center text-sm">
        Join our community of 1 Million+ cloud IDE users
      </p>

      <div class="m-auto mb-16 max-w-4xl">
        <CodeSearchBar />
      </div>

      <div class="mb-10 flex flex-wrap justify-center gap-x-8 gap-y-5 md:gap-14">
        <div v-for="(item, index) in countAndFeatures" :key="index">
          <CountAndFeatureVue :countAndFeature="item" />
        </div>
      </div>

      <div class="my-20">
        <CodeCategories />
      </div>
    </div>
  </div>
</template>
