<script setup lang="ts">
import Logo from '@/components/utils/LogoComp.vue'
import router from '@/router'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  description: {
    type: String,
    required: false
  },
  hs: {
    type: String,
    required: true
  },
  loadDataWhenClose: {
    type: Boolean,
    require: false,
    default: false
  },
  wideScreen: {
    type: Boolean,
    require: false,
    default: false
  }
})

const emit = defineEmits(['closeSubscribe'])

/**
 * If prop is true, page retrieves data again from API after changes from modal
 */
const handleLoadDataWhenClose = () => {
  if (props.loadDataWhenClose) {
    router.go(0)
  }

  if (props.hs == 'hs-subscribe') {
    emit('closeSubscribe')
  }
}
</script>

<template>
  <div
    :id="props.hs"
    class="hs-overlay fixed left-0 top-0 z-[60] hidden h-screen w-full overflow-y-auto overflow-x-hidden backdrop-blur-md backdrop-brightness-200"
  >
    <div
      class="modal-bg m-0 h-max w-full opacity-0 transition-all ease-out hs-overlay-open:duration-500"
    >
      <div class="h-full min-h-[100vh] w-full">
        <div class="flex flex-col">
          <div
            class="flex items-center justify-between border-b border-neutral-300 px-4 py-2 dark:border-neutral-500 md:px-36"
          >
            <!-- logo with link to home page -->
            <Logo />
            <!-- close button -->
            <button
              type="button"
              class="link-primary hs-collapse-toggle pt-4 transition-all"
              :data-hs-overlay="`#${props.hs}`"
              @click="handleLoadDataWhenClose"
              data-hs-overlay-close
            >
              <span class="sr-only">Close</span>
              <FontAwesomeIcon
                icon="fa-xmark"
                class="h-6 w-6 text-neutral-300 hs-collapse-open:block"
              />
            </button>
          </div>

          <!-- title -->
          <div
            :class="[
              'view-container mx-auto py-16',
              {
                'md:w-[60%] lg:w-[40%]': !props.wideScreen
              }
            ]"
          >
            <h1 class="heading-medium block text-center">
              {{ props.title }}
            </h1>
            <p class="text-secondary mb-10 text-center text-xs">{{ props.description }}</p>
            <!-- main content -->
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
