// import gaService from '@/services/ga.service'
import editorService from '@/services/ide/editor.service'
import projectTreeService from '@/services/ide/projectTree.service'
import projectsService from '@/services/ide/projects.service'
import historyService from '@/services/ide/settings/history.service'
import uploadService from '@/services/ide/upload.service'
import wsService from '@/services/ide/ws.service'
import recaptchaService from '@/services/recaptcha.service'
import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import { IDECONSTANT, SERVER_ERROR, SYNC_ERROR } from '@/utils/ide'

import axios from 'axios'
let controller: AbortController | null = null

export interface IExecuteRequest {
  script?: string | null
  language: string | null
  versionIndex: number
  projectKey: string | boolean | number
  multiFile?: boolean
  libs: string[]
  mainFile?: string
  args: string | null
  stdin: string | null
  hasInputFiles: boolean
}
export interface IExecuteResponse {
  output?: string
  executionTime?: number
  memory?: number
  cpuTime?: number
  outputFiles?: string[]
}
/**
 * Wait for the sync to finish
 * @param count - the count
 */
const multifileWaitForSync = async (count: number = 0) => {
  if (!projectTreeService.isSyncSuccess) {
    if (count > 8) {
      editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, SYNC_ERROR)
      useIdeStore().setisCodeExecuting(false)
    } else {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      multifileWaitForSync(count + 1)
    }
  } else {
    executeLogic()
  }
}
/**
 * Execute the code in interactive mode
 * @param requestData - the request data
 */

/**
 * Execute the logic
 */
const executeLogic = async () => {
  let requestData: IExecuteRequest | null = null

  let language = useIdeStore().isLanguage
  let versionIndex = useIdeStore().versionIndex
  let script = editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue()

  if (useIdeStore().isBlockly) {
    language = useIdeStore().isBlocklyLanguages[useIdeStore().versionIndex]
    versionIndex = useIdeStore().isBlocklyLanguageVersions[useIdeStore().versionIndex]
  }
  if (useIdeStore().isBlockly && language === 'php') {
    script = '\n' + script + '\n'
  }

  if (useIdeStore().isAdvanced) {
    requestData = {
      language: language,
      versionIndex: versionIndex,
      projectKey: useIdeStore().projectKey,
      multiFile: true,
      libs: useIdeStore().javaLibraries,
      mainFile: useIdeStore().project.home.substring(1),
      args: useIdeStore().args,
      stdin: useIdeStore().stdin,
      hasInputFiles: useIdeStore().isInputFiles.length > 0
    }
  } else {
    requestData = {
      script: script,
      args: useIdeStore().args,
      stdin: useIdeStore().stdin,
      language: language,
      versionIndex: versionIndex,
      libs: useIdeStore().javaLibraries,
      projectKey: 1001,
      hasInputFiles: useIdeStore().isInputFiles.length > 0
    }
  }
  // const startTime: number = gaService.getCurrentTime()
  if (useIdeStore().interactiveMode) {
    wsService.executeInteractive(requestData)
    return
  }

  await axios
    .post('/engine/execute', JSON.stringify(requestData), {
      signal: controller?.signal,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((response: { data: IExecuteResponse }) => {
      if (response.data?.output) {
        editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, response.data?.output)
      } else {
        editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, '')
      }
      editorService.heightChangeFunction(IDECONSTANT.OUTPUT_EDITOR)

      useIdeStore().setExcecutionData(response.data)

      if (!useIdeStore().isAdvanced) {
        historyService.addToRecent()
      }
    })
    .catch((error: { response: { status: number } }) => {
      if (error?.response?.status === 403) {
        useAuthStore().clearRobotCheck()
      }
      editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, SERVER_ERROR)
      useIdeStore().resetExecutionTime()
    })
    .finally(() => {
      useIdeStore().setisCodeExecuting(false)
      // gaService.calculateAndSendExecuteEndTime(startTime, language, 'execute')
    })
  projectsService.autoSave()
}
/**
 * Execute the code
 */
const execute = async () => {
  if (useIdeStore().interactiveMode) {
    useIdeStore().resetStdin()
  }
  if (useIdeStore().isAdvanced) {
    projectTreeService.syncBeforeExecute()
    await new Promise((resolve) => setTimeout(resolve, 1000))
    multifileWaitForSync()
  } else {
    executeLogic()
  }
}
/**
 * Try to execute the code
 */
const tryExecute = () => {
  if (useIdeStore().isCodeExecuting) return
  controller = new AbortController()

  useIdeStore().setisCodeExecuting(true)
  useIdeStore().resetExecutionTime()
  useIdeStore().setOutputFiles([])

  editorService.setEditorSession(
    IDECONSTANT.OUTPUT_EDITOR,
    'JDoodle in Action.... Running the program...'
  )
  recaptchaService
    .callViaCaptcha()
    .then(() => {
      if (usePluginStore().pluginHasFiles && !usePluginStore().pluginIsFileUploaded) {
        uploadService
          .uploadPluginFile()
          .then(() => {
            execute()
          })
          .catch(() => {
            editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, SERVER_ERROR)
            useIdeStore().resetExecutionTime()
            useIdeStore().setisCodeExecuting(false)
          })
      } else {
        execute()
      }
    })
    .catch(() => {
      useIdeStore().setisCodeExecuting(false)
      editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, SERVER_ERROR)
      useIdeStore().resetExecutionTime()
    })
}
/**
 * stop execute the code
 */
const stopExecution = () => {
  if (!useIdeStore().isCodeExecuting) return

  if (useIdeStore().interactiveMode) {
    wsService.stopExecuteInteractive()
  } else {
    controller?.abort()
    controller = null
  }
  editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, 'Execution Stopped...')
  useIdeStore().resetExecutionTime()
  useIdeStore().setisCodeExecuting(false)
}

export default { tryExecute, stopExecution }
