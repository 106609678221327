<script setup lang="ts">
import authService, { SOCIAL_LOGIN_SERVICE } from '@/services/auth.service'

import googleImg from '@/assets/images/header/auth/google.svg'
import microsoftImg from '@/assets/images/header/auth/microsoft.svg'

interface ISocialLoginItem {
  name: string
  service: SOCIAL_LOGIN_SERVICE
  image: {
    src: string
    alt: string
  }
}

/**
 * @description Handle click on social login button
 * @param service - The social login service to use
 */
const handleSocialLogin = (service: SOCIAL_LOGIN_SERVICE): void => {
  authService.socialAuthentication(service)
}

const socialLoginItems: ISocialLoginItem[] = [
  {
    name: 'Google',
    service: SOCIAL_LOGIN_SERVICE.GOOGLE,
    image: {
      src: googleImg,
      alt: 'google'
    }
  },
  {
    name: 'Microsoft',
    service: SOCIAL_LOGIN_SERVICE.MICROSOFT,
    image: {
      src: microsoftImg,
      alt: 'microsoft'
    }
  }
]
</script>

<template>
  <div class="flex flex-col gap-2">
    <button
      v-for="(items, index) of socialLoginItems"
      :key="index"
      @click="($event) => handleSocialLogin(items.service)"
      type="button"
      class="btn-rounded-md flex items-center justify-center border hover:shadow-md"
    >
      <img class="h-auto w-4" :src="items.image.src" :alt="items.image.alt" />
      Sign in with {{ items.name }}
    </button>

    <div
      class="p-xsmall text-tertiary flex items-center py-4 uppercase before:mr-6 before:flex-[1_1_0%] before:border before:border-neutral-100 after:ml-6 after:flex-[1_1_0%] after:border after:border-neutral-100 before:dark:border-neutral-900 after:dark:border-neutral-900"
    >
      Or
    </div>
  </div>
</template>
