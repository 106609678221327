<script setup lang="ts">
import { FORM_INPUT_TYPE } from '@/components/forms/form.enum'
import { computed } from 'vue'

const props = defineProps({
  id: {
    type: String,
    default: null
  },
  label: {
    type: String,
    default: ''
  },
  showLable: {
    type: Boolean,
    default: true
  },
  name: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  autocomplete: {
    type: String,
    default: ''
  },
  error: {
    type: String,
    default: ''
  },
  modelValue: {
    required: true
  },
  inputType: {
    type: String,
    require: false
  },
  disabled: {
    type: Boolean,
    require: false
  },
  maxInt: {
    type: Number,
    required: false
  },
  isContactus: {
    type: Boolean,
    required: false,
    default: false
  },
  isLightGray: {
    type: Boolean,
    required: false,
    default: true
  },
  hasBorder: {
    type: Boolean,
    required: false,
    default: false
  },
  isSmallLabel: {
    type: Boolean,
    required: false,
    default: false
  }
})
const emit = defineEmits(['update:modelValue'])

const inputClasses = computed(() => {
  if (props.isContactus) {
    return 'section-primary border-b px-1 pb-3'
  } else if (props.isLightGray) {
    return `rounded-md bg-neutral-100 px-4 dark:bg-[#17191F]`
  } else {
    return `rounded-md px-4 section-primary`
  }
})
</script>

<template>
  <div :class="[{ 'my-2': !showLable }]">
    <div v-if="showLable" class="flex items-center justify-between">
      <label
        :for="props.name || `${props.label + props.placeholder}`"
        class="my-2 block text-base"
        :class="props.isSmallLabel ? 'text-sm' : ''"
        >{{ props.label }}</label
      >
      <slot />
    </div>
    <div class="relative">
      <input
        v-bind="$attrs"
        :id="props.id || props.name || `${props.label + props.placeholder}`"
        class="p-small mb-1 block w-full py-3 dark:caret-white"
        :class="[
          props.error ? 'border-red-500' : 'border-transparent',
          inputClasses,
          { border: hasBorder }
        ]"
        :placeholder="props.placeholder"
        :autocomplete="props.autocomplete || undefined"
        :value="modelValue"
        :max="maxInt"
        :disabled="props.disabled"
        :type="props.inputType ? props.inputType : FORM_INPUT_TYPE.STRING"
        @change="(event: Event) => {
          props.inputType == FORM_INPUT_TYPE.NUMBER ?
            emit('update:modelValue', Number.parseInt((event.target as HTMLInputElement).value)) :
            emit('update:modelValue', (event.target as HTMLInputElement).value)
        }"
      />
      <div
        :class="[
          'absolute inset-y-0 right-0 flex cursor-pointer items-center pr-2',
          { hidden: !props.error }
        ]"
        @click="emit('update:modelValue', '')"
      >
        <FontAwesomeIcon icon="fa-circle-xmark" class="error h-4 w-4" />
      </div>
    </div>
    <p :class="['p-xsmall error text-left', { hidden: !props.error }]">
      {{ props.error.charAt(0).toUpperCase() + props.error.slice(1) }}
    </p>
    <p
      :class="['p-xsmall ', 'error']"
      v-if="props.inputType == FORM_INPUT_TYPE.NUMBER && (modelValue as number) >= (maxInt as number)"
    >
      Number must be less than {{ props.maxInt }}
    </p>
  </div>
</template>
