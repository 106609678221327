<script setup lang="ts">
declare global {
  interface Window {
    TogetherJS: any
  }
}
import { onBeforeMount, onMounted, onBeforeUnmount, watch, computed, ref } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { useRoute } from 'vue-router'
import { type IMeta } from '@/utils/meta'
import ideService from '@/services/ide/ide.service'
import shareService from '@/services/ide/settings/share.service'
import projectsService from '@/services/ide/projects.service'
import editorService from '@/services/ide/editor.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import historyService from '@/services/ide/settings/history.service'
import utilModelsService from '@/services/util.models.service'
import loadScriptInBody from '@/services/loadScriptInBody.service'

import { useDark } from '@vueuse/core'
import { useIdeStore } from '@/stores/ide.store'

import SettingsModel from '@/components/ide/ide/settings/SettingsModel.vue'
import LibraryModel from '@/components/ide/ide/settings/LibraryModel.vue'
import ClearProjectModel from '@/components/ide/ide/settings/ClearProjectModel.vue'
import NotLoginModel from '@/components/ide/ide/settings/NotLoginModel.vue'
import MyProjectModel from '@/components/ide/ide/settings/MyProjectModel.vue'
import ExecurteHistoryModel from '@/components/ide/ide/settings/ExecurteHistoryModel.vue'
import SaveModel from '@/components/ide/ide/settings/SaveModel.vue'
// import CopyModel from '@/components/ide/ide/settings/CopyModel.vue'
import DownloadModel from '@/components/ide/ide/settings/DownloadModel.vue'
import OpenFromFileModel from '@/components/ide/ide/settings/OpenFromFileModel.vue'
import EditableShareModel from '@/components/ide/ide/settings/EditableShareModel.vue'
import InstantShareModel from '@/components/ide/ide/settings/InstantShareModel.vue'

import Recaptcha from '@/components/shared/RecaptchaComp.vue'
import PrintBlocker from '@/components/ide/shared/PrintBlocker.vue'
import Header from '@/components/ide/ide/HeaderComp.vue'
import FullscreenHeaderComp from '@/components/ide/ide/FullscreenHeaderComp.vue'
import ProjectHeader from '@/components/ide/ide/ProjectHeaderComp.vue'
import ProjectTree from '@/components/ide/ide/ProjectTreeComp.vue'
import Ide from '@/components/ide/ide/IdeComp.vue'
import Accordian from '@/components/ide/ide/AccordionComp.vue'
import Settings from '@/components/ide/ide/SettingsComp.vue'
import SettingsSideBar from '@/components/ide/ide/settings/SettingsSideBar.vue'
import Output from '@/components/ide/ide/OutputComp.vue'

import IdeFeedbackComp from '@/components/ide/IdeFeedbackComp.vue'
import IdeFooterCardsComp from '@/components/ide/IdeFooterCardsComp.vue'
import CodeSearchBar from '@/components/code/CodeSearchBar.vue'

const props = defineProps({
  shareId: {
    type: String,
    required: false
  },
  byPost: {
    type: Boolean,
    required: false,
    default: false
  }
})
const route = useRoute()
const isDark = useDark()
const ideStore = useIdeStore()

// Styling
const menuCollapsed = ref<boolean>(true)

const isBlockly = computed(() => {
  return ideStore.isBlockly
})
const isFullScreen = computed(() => {
  return ideStore.isFullScreen
})
const langDisplayName = computed(() => {
  return ideStore.ideMeta?.langDisplayName
})
const isCompileLang = computed(() => {
  return ideStore.ideMeta?.isCompile || ideStore.ideMeta?.language === 'java'
})
const isShareNotFound = computed(() => {
  return ideStore.shareNotFound
})
const isShareFoundHttpError = computed(() => {
  return ideStore.shareNotFoundHttpError
})
const isAdvanced = computed(() => {
  return ideStore.isAdvanced
})
const showProjectName = computed(() => {
  return ideStore.project && ideStore.project?.name
})

/**
 * @description inject together js
 */
const injectTogetherJs = () => {
  if (!window.TogetherJS) {
    loadScriptInBody.loadScriptInBody('/assets/javascript/togetherjs-min.js')
  }
}
/**
 * @description eject together js
 */
const ejectTogetherJs = () => {
  if (window.TogetherJS) {
    loadScriptInBody.unloadScriptInBody('/assets/javascript/togetherjs-min.js')
  }
}
/**
 * @description init ide on mount and router change
 */
const initIde = async () => {
  await ideService.initOnRouterChange(route.meta as IMeta)
  const byPost = route.query.byPost === 'true'
  await ideService.initPostIde(props.byPost || byPost)
  await shareService.initOnRouterChange(props.shareId as string)
}
/**
 * Handles the click of menu
 */
const handleMenuCollapse = () => {
  menuCollapsed.value = !menuCollapsed.value
}

onBeforeMount(() => {
  ideService.cleanIdeStore()
  ideStore.setRouteMeta(null as any)
  ideStore.setIdeMeta(null as any)
  injectTogetherJs()
  initIde()
  // make ideMeta reactive
  watch(route, () => {
    if (route.meta.canonicalPath) initIde()
  })
  watch(isDark, () => {
    editorService.codeEditorsSetTheme()
  })
})

onMounted(async () => {
  window.addEventListener('resize', () => {
    editorService.resizeCodeEditor()
    editorService.resizeOutputEditor()
    blocklyService.resizeBlockly()
  })
  window.onbeforeunload = function () {
    if (ideStore.isCodeUpdated) {
      return `Are you sure you want to move from this ${langDisplayName.value}${
        isCompileLang.value ? ' Compiler' : ''
      } IDE?`
    }
  }
  historyService.checkStorage()
  await ideService.initIde()
  projectsService.initOnRouterChange()
})
onBeforeRouteLeave((to, from, next) => {
  if (ideStore.isCodeUpdated) {
    utilModelsService
      .confirmPromise(
        `Are you sure you want to move from this ${langDisplayName.value}${
          isCompileLang.value ? ' Compiler' : ''
        } IDE?`,
        'You will lose all your unsaved changes.'
      )
      .then(() => {
        next()
      })
      .catch(() => {
        next(false)
      })
  } else {
    next()
  }
})
onBeforeUnmount(() => {
  ejectTogetherJs()
  ideService.cleanIde()
  ideService.cleanIdeStore()
})
</script>

<template>
  <SettingsModel />
  <LibraryModel />
  <ClearProjectModel />
  <NotLoginModel />
  <MyProjectModel />
  <ExecurteHistoryModel />
  <SaveModel />
  <!-- <CopyModel /> -->
  <DownloadModel />
  <OpenFromFileModel />
  <EditableShareModel />
  <InstantShareModel />
  <Recaptcha />

  <!-- OUTER GRID 12 COL (fullscreen 12 rows) -->
  <div
    class="section-primary grid h-full grid-cols-6 grid-rows-1 gap-3 lg:grid-cols-12"
    :class="[
      {
        'fixed left-0 top-0 z-50 m-0 h-screen min-h-screen w-full grid-rows-12 overflow-scroll p-0 print:relative':
          isFullScreen
      },
      {
        'p-5': !isFullScreen
      }
    ]"
  >
    <!-- Hidden @ Full -->
    <div class="col-span-6 lg:col-span-12" :class="isFullScreen ? 'hidden' : ''">
      <PrintBlocker />
      <div
        :class="[
          'block',
          {
            'hidden print:block': isFullScreen
          }
        ]"
      >
        <Header />
      </div>
      <p
        v-if="isShareNotFound"
        :class="[
          'print:hidden, p-small ',
          'error',
          'text-center',
          'my-5',
          { hidden: !isShareNotFound }
        ]"
      >
        {{ isShareFoundHttpError || 'Share not found' }}
      </p>
    </div>

    <!-- Fullscreen Heading -->
    <div class="section-tertiary col-span-6 row-span-1 lg:col-span-12" v-if="isFullScreen">
      <FullscreenHeaderComp />
    </div>

    <!-- Side Menu -->
    <div
      :class="[
        'print:hidden',
        { 'row-span-1': !isFullScreen },
        { 'row-span-11': isFullScreen },
        { 'col-span-2': !menuCollapsed },
        { 'col-span-1': menuCollapsed }
      ]"
    >
      <SettingsSideBar :handle-menu-collapse="handleMenuCollapse" :menu-collapsed="menuCollapsed" />
    </div>

    <!-- Rest of screen section-primary -->
    <div
      class="relative h-full w-full"
      :class="[
        { 'row-span-11': isFullScreen },

        // Not advanced IDE
        {
          'col-span-4 print:col-span-6 lg:col-span-10 print:lg:col-span-12':
            !menuCollapsed && !isAdvanced
        },
        {
          'col-span-5 print:col-span-6 lg:col-span-11 print:lg:col-span-12':
            menuCollapsed && !isAdvanced
        },
        // Advanced IDE
        { 'col-span-4 lg:col-span-10': !menuCollapsed && isAdvanced },
        { 'col-span-5 lg:col-span-11': menuCollapsed && isAdvanced }
      ]"
    >
      <!-- Project Header (not in grid), 44px tall-->
      <div
        class="col-span-12 flex h-[44px]"
        :class="showProjectName ? ' justify-between' : 'justify-end'"
      >
        <ProjectHeader v-if="showProjectName" />
        <CodeSearchBar :is-full-size="false" :isMini="true" />
      </div>

      <!-- Flex Container for FileTree, Main Column, and Output -->
      <div
        v-show="!isShareNotFound"
        :class="[
          'h-full flex-col gap-3 rounded-lg lg:max-h-[calc(100%-44px)] ',
          {
            'flex h-auto w-full ': !isFullScreen
          },
          {
            'row-span-11 flex lg:flex-row': isFullScreen
          },
          {
            'flex-row': isFullScreen && isBlockly
          }
        ]"
      >
        <!-- Main Column -->
        <div
          class="section-tertiary col-span-8 h-full rounded-lg"
          :class="[
            {
              'flex w-full flex-col gap-3 ': isFullScreen
            },
            {
              'flex w-full flex-col gap-y-3 lg:w-[100%] ': !isFullScreen
            }
          ]"
        >
          <!-- File Tree (Mobile only) -->
          <div
            v-if="isAdvanced"
            class="block h-fit min-h-[150px] lg:hidden"
            :class="[{ 'w-full': !isFullScreen }, { 'w-full': isFullScreen }]"
          >
            <div id="ideProjectTree" class="h-full">
              <ProjectTree :hideCollapse="true" class="" />
            </div>
          </div>

          <!-- IDE Card -->
          <!-- Mobile - 350px, Widescreen - 60% -->
          <div
            class="section-tertiary h-auto w-full rounded-md px-4 py-4"
            :class="[
              { 'min-h-[450px] grow lg:min-h-[60%]': isFullScreen },
              { 'min-h-[350px] grow lg:min-h-[50%]': !isFullScreen }
            ]"
          >
            <Settings :class="isAdvanced ? '' : 'mb-2'" />
            <div class="flex h-full">
              <!-- File Tree (Widescreen only) -->
              <!-- 20% width -->
              <div
                v-if="isAdvanced"
                class="hidden lg:block lg:w-[20%]"
                :class="[{ 'row-span-1 ': !isFullScreen }, { 'row-span-12': isFullScreen }]"
              >
                <div id="ideProjectTree" class="h-full w-full min-w-full lg:min-w-fit">
                  <ProjectTree :hideCollapse="true" />
                </div>
              </div>
              <Ide />
            </div>
          </div>

          <!-- Acordion Card -->
          <div class="section-tertiary h-fit rounded-md px-4">
            <Accordian />
          </div>
        </div>

        <!-- Output section -->
        <div
          class="section-tertiary h-auto rounded-md p-4"
          :class="isFullScreen ? 'col-span-4 lg:w-[70%]' : 'w-full'"
        >
          <Output />
        </div>
      </div>
    </div>
  </div>

  <!-- Share/Feedback components -->
  <div
    class="px-5 print:block"
    :class="[
      {
        hidden: isFullScreen
      }
    ]"
  >
    <IdeFooterCardsComp />
    <IdeFeedbackComp />
  </div>
</template>

<style>
.gutter {
  background-color: gray;
  background-repeat: no-repeat;
  background-position: 50%;
}
</style>
