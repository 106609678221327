<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { IDEVIEWMODELS } from '@/utils/models'
import editorService from '@/services/ide/editor.service'
import projectTreeService from '@/services/ide/projectTree.service'
import { useIdeStore } from '@/stores/ide.store'
import ExecuteComp from '@/components/ide/ide/ExecuteComp.vue'

const ideStore = useIdeStore()

const timeOut = ref<number | null>(null)

const copiedToClip = ref<boolean>(false)
const showCopiedMessageText = ref<string>('')
const copySuccess = ref<boolean>(false)

const isCopied = computed(() => {
  return ideStore.isCopied
})
const isHtml = computed(() => {
  return ideStore.isHtml
})

const toogleFullScreenIcon = computed(() => {
  return ideStore.isFullScreen ? 'fa-compress' : 'fa-expand'
})
const isFullScreen = computed(() => {
  return ideStore.isFullScreen
})

/**
 * Toggles the full screen
 */
const toogleFullScreen = async () => {
  ideStore.setFullScreen(!ideStore.isFullScreen)
  if (ideStore.isAdvanced) {
    projectTreeService.expandProjectTree()
    if (ideStore.isFullScreen) {
      // ideStore.ideSplit.setSizes([25, 75])
    } else {
      // ideStore.ideSplit.setSizes([0, 100])
    }
  }
  await new Promise((resolve) => setTimeout(resolve, 100))
  editorService.resizeCodeEditor()
  editorService.resizeOutputEditor()
}

/**
 * Checks if the font size is selected
 * @param fontSize The font size to check
 * @returns True if the font size is selected
 */
const isFontsizeSelected = (fontSize: number) => {
  return ideStore.fontSize === fontSize
}
/**
 * Sets the font size
 * @param e The event
 */
const setFontSize = (e: Event) => {
  editorService.setFontSize(parseInt((e.target as HTMLSelectElement).value))
}
/**
 * Copy the project script to the  to clipboard
 */
const copyToClipboard = async () => {
  if (isCopied.value) {
    showCopiedMessageText.value = 'Copied to clipboard!'
    copySuccess.value = true
    copiedToClip.value = true
  }
  if (timeOut.value) clearTimeout(timeOut.value)
  timeOut.value = setTimeout(() => {
    copiedToClip.value = false
    copySuccess.value = false
    showCopiedMessageText.value = ''
    ideStore.setIsCopied(false)
  }, 5000)
}

onMounted(() => {
  watch(isCopied, () => {
    copyToClipboard()
  })
})
</script>

<template>
  <div class="rounded-lg print:hidden">
    <div class="flex w-full items-center justify-around">
      <p :class="['p-xsmall w-full', { success: copySuccess, hidden: !copiedToClip }]">
        {{ showCopiedMessageText }}
      </p>
      <div
        :class="[
          'flex',
          'w-full',
          'gap-3',
          'flex-row',
          'justify-end',
          { 'justify-center': isHtml }
        ]"
      >
        <div v-if="!isHtml" class="flex items-center gap-1 print:hidden">
          <label for="fontSizeSelect" class="block text-xs">Font Size:</label>
          <select
            id="fontSizeSelect"
            class="section-primary p-xsmall block h-6 w-fit rounded-md"
            @change="setFontSize($event)"
          >
            <option
              v-for="fontSize in Array.from({ length: 15 }, (_, i) => i + 11)"
              :key="fontSize"
              :selected="isFontsizeSelected(fontSize)"
              :value="fontSize"
            >
              {{ fontSize }}px
            </option>
          </select>
        </div>
        <div v-if="isFullScreen || isHtml" class="flex items-center gap-1">
          <ExecuteComp />
          <button
            v-if="isHtml"
            class="btn-secondary flex w-fit items-center gap-2 rounded-md px-2 py-1 text-sm"
            :data-hs-overlay="`#${IDEVIEWMODELS.SETTINGS}`"
          >
            <FontAwesomeIcon icon="fa-gears" class="h-5 w-3" />
          </button>
        </div>
        <div class="flex gap-2">
          <button
            v-if="!isHtml && !isFullScreen"
            class="btn-secondary w-fit min-w-fit rounded-md px-2 py-1 text-sm"
            @click="toogleFullScreen"
          >
            <FontAwesomeIcon :icon="toogleFullScreenIcon" class="h-3 w-3" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
