<script lang="ts" setup>
import { computed } from 'vue'
import { AUTHMODELHS } from '@/utils/models'
import { useAuthStore } from '@/stores/auth.store'

import checkBoGreenImg from '@/assets/images/educationInstitution/pricing/checkbox-green.svg'
import type { RouterLink } from 'vue-router'

const authStore = useAuthStore()

const isLogedIn = computed(() => authStore.isLogedIn)
</script>

<template>
  <section class="mx-auto mt-20 flex flex-col items-center justify-center">
    <div class="mx-auto mb-12 text-center lg:max-w-4xl">
      <span
        class="mb-4 inline-block rounded-full bg-[#e5ffea] px-2 text-xs font-semibold uppercase leading-tight text-[#fd5a0d] shadow-[0_1px_2px_rgba(0,0,0,0.15)]"
        >Pricing</span
      >
      <h1 class="mb-4 text-3xl font-bold leading-tight tracking-[-0.02px] lg:text-4xl">
        Free and custom pricing plan for your institution
      </h1>
      <p class="text-lg font-medium text-slate-500 lg:text-xl">
        Pricing that meets your specific requirements.
      </p>
    </div>
    <div
      class="ml-[-1rem] mr-[-1rem] flex w-[77.5%] flex-col flex-wrap items-start justify-center lg:flex-row"
    >
      <div class="h-full w-full p-4 lg:w-6/12">
        <div
          class="flex h-full w-full flex-col rounded-md bg-gray-50 p-8 shadow-[0_1px_2px_rgba(0,0,0,0.1)] transition-transform duration-[0.5s] hover:scale-105"
        >
          <div class="flex-wrap items-end justify-between px-8 lg:flex">
            <div>
              <h3 class="mb-2 text-2xl font-semibold tracking-[-0.02em] text-gray-700 lg:text-3xl">
                Basic Plan
              </h3>
              <p class="font-medium text-slate-500">Free with JDoodle branding</p>
            </div>

            <div class="pt-8 text-center">
              <span class="relative right-1 top-[-2.5rem] text-3xl font-semibold text-gray-700"
                >$</span
              >
              <span class="text-6xl font-semibold tracking-[-0.02em] text-gray-700 lg:text-[4rem]"
                >Free</span
              >
              <span class="ml-1 text-2xl font-semibold text-slate-500">/mo</span>
            </div>
          </div>
          <div class="border-b pb-4"></div>
          <ul class="self-start px-8 pb-16 pt-8">
            <li class="mb-4 flex items-center font-medium text-slate-500">
              <img className="mr-3" :src="checkBoGreenImg" alt="checkBoGreen" />
              <span>Easy integration with 3 lines of HTML code </span>
            </li>
            <li class="mb-4 flex items-center font-medium text-slate-500">
              <img className="mr-3" :src="checkBoGreenImg" alt="checkBoGreen" />
              <span>Complete Documentation</span>
            </li>
          </ul>
          <div class="border-b"></div>
          <div class="px-8 pt-8">
            <button
              v-if="!isLogedIn"
              class="signup-button inline-block w-full rounded-md border-[none] bg-background-jdoodle px-7 py-4 text-center text-base font-medium leading-6 text-white shadow-[0_1px_2px_rgba(0,0,0,0.15)] transition-[background-color] delay-[0.2s] duration-[0.2s,box-shadow] hover:bg-background-jdoodle-hover disabled:pointer-events-none disabled:bg-slate-500 disabled:text-white disabled:shadow-none lg:text-lg"
              :data-hs-overlay="`#${AUTHMODELHS.REGISTER}`"
            >
              Get Started Now
            </button>
          </div>
        </div>
      </div>
      <div class="h-full w-full p-4 lg:w-6/12">
        <div
          class="flex h-full w-full flex-col rounded-md bg-gray-50 p-8 shadow-[0_1px_2px_rgba(0,0,0,0.1)] transition-transform duration-[0.5s] hover:scale-105"
        >
          <div class="flex-wrap items-end justify-between px-8 lg:flex">
            <div>
              <h3 class="text-2xl font-semibold tracking-[-0.02em] text-gray-700 lg:text-3xl">
                Custom
              </h3>
              <p class="font-medium text-slate-500">Unlimited possibilities</p>
            </div>

            <div class="pt-8 text-center">
              <span class="relative right-1 top-[-2.5rem] text-3xl font-semibold text-gray-700"
                >$</span
              >
              <span class="text-6xl font-semibold tracking-[-0.02em] text-gray-700 lg:text-[4rem]"
                >499</span
              >
              <span class="ml-1 text-2xl font-semibold text-slate-500">onwards/mo</span>
            </div>
          </div>
          <div class="border-b pb-4"></div>
          <ul class="self-start px-8 pb-16 pt-8">
            <li class="mb-4 flex items-center font-medium text-slate-500">
              <img className="mr-3" :src="checkBoGreenImg" alt="checkBoGreen" />
              <span>Access to all features</span>
            </li>
            <li class="mb-4 flex items-center font-medium text-slate-500">
              <img className="mr-3" :src="checkBoGreenImg" alt="checkBoGreen" />
              <span>Assisted onboarding support</span>
            </li>
            <li class="mb-4 flex items-center font-medium text-slate-500">
              <img className="mr-3" :src="checkBoGreenImg" alt="checkBoGreen" />
              <span>White-labelled</span>
            </li>
            <li class="mb-4 flex items-center font-medium text-slate-500">
              <img className="mr-3" :src="checkBoGreenImg" alt="checkBoGreen" />
              <span>Tailor-made plugins to meet your requirements</span>
            </li>
          </ul>
          <div class="border-b"></div>
          <div class="px-8 pt-8">
            <RouterLink
              to="/contact-us"
              class="inline-block w-full rounded-md border-[none] bg-background-jdoodle px-7 py-4 text-center text-base font-medium leading-6 text-white shadow-[0_1px_2px_rgba(0,0,0,0.15)] transition-[background-color] delay-[0.2s] duration-[0.2s,box-shadow] hover:bg-background-jdoodle-hover disabled:pointer-events-none disabled:bg-slate-500 disabled:text-white disabled:shadow-none lg:text-lg"
              >Contact Us</RouterLink
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
