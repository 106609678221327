<template>
  <p class="mb-2 mt-5 text-xs font-bold underline">Hints: Invitations</p>
  <p class="text-xs">
    Users should login to JDoodle.com and accept your invite before you can include them into
    Assignments. Please note JDoodle.com does not send any email invites to users currently, you
    have to inform the users yourself.
  </p>

  <div class="mt-5">
    <p class="mb-2 text-xs font-bold underline">Hints: User Permissions</p>
    <ul class="list-disc ps-5 text-xs">
      <li>
        Account Owner has all permissions in the institute. Account owner can access all the
        Assignments and Students Groups in the institute.
      </li>
      <li>
        Institute Admins can create/edit all types of users. Institute Admin can manage Student
        Groups they created or they are included. Institute Admins can't create or manage
        Assignments
      </li>
      <li>
        Teachers can create/edit students, but not Admins/Teachers. Teachers can create and mange
        Student Groups and Assignments (both they created or included).
      </li>
    </ul>
  </div>
</template>
