import { type RouteMeta } from 'vue-router'
export interface IMeta extends RouteMeta {
  metaTags?: { name: string; content: string }[]
  title?: string
  header?: string
  fileName?: string
  canonicalPath?: string
  terminal?: string
  language?: string
  aceCode?: string
  defaultVersion?: number
  versions?: string[]
  langDisplayName?: string
  isCompile?: boolean
  isPlus?: boolean
  editableTypes?: string[]
  sampleScript?: string
  blocklyAceCodes?: string[]
  blocklyLanguages?: string[]
  blocklyLanguageVersions?: number[]
  isAdvanced?: boolean
  basicPath?: string
}

export const META: { [key: string]: IMeta } = {
  default: {
    metaTags: [
      {
        name: 'description',
        content:
          'is a free Online  Compiler, Editor, IDE  for Java, C, C++, PHP, Perl, Python, Ruby. Code Online - Share Save Java/C/C++/Perl/Python/Ruby online'
      },
      {
        name: 'keywords',
        content:
          'Online Program Compiler - Online Editor - Online IDE - Coding Online -  Share Save online For JAVA, C, C++, PHP, PERL, PYTHON, RUBY'
      }
    ],
    title: 'Online Compiler and Editor/IDE for Java, C/C++, PHP, Python, Perl, etc'
  },
  home: {
    metaTags: [
      {
        name: 'description',
        content:
          'JDoodle is an online coding platform and cloud IDE for practising, teaching, compiling and learning in Java, C, C++, PHP, Python, Ruby, Perl and other programming languages.'
      },
      {
        name: 'keywords',
        content:
          'Code online online compiler -  online code editor -  cloud IDE -  online IDE -  online programming -  Compiler API -  Compiler Plugin -  Teaching platform -  Assessment platform -  software development -  developer -  artificial intelligence -  best code editor'
      }
    ],
    title:
      'JDoodle - Free online cloud coding platform IDE to practice, teach and learn programming'
  },
  code: {
    metaTags: [
      {
        name: 'description',
        content:
          "Code online for FREE in 76 languages with JDoodle's simple online compilers, editors and IDEs with cloud IDE"
      },
      {
        name: 'keywords',
        content:
          'Code online -  online compiler -  online code editor -  cloud ide -  online IDE -  online programming -  coding -  software development -  developer -  artificial intelligence -  best code editor'
      }
    ],
    title:
      'JDoodle - Online Compiler and Editor/IDE for Java, C, C++, PHP, Python, Ruby, Perl - Code and Run Online'
  },
  integrate: {
    metaTags: [
      {
        name: 'description',
        content:
          'online compiler API and IDE Plugins to compile and execute programs in Java, C, C++, PHP, Perl, Python, Ruby and many more languages'
      },
      {
        name: 'keywords',
        content:
          'Integrate IDE -  LMS integration -  IDE APIs -  IDE Plugins -  compiler -  API -  online programming -  online ide integration'
      }
    ],
    title: 'JDoodle - Integrate online compiler plugin and API'
  },
  teachassess: {
    metaTags: [
      {
        name: 'description',
        content:
          'Create your virtual institute for free, invite students, create courses assignments and automate grading with JDoodle.'
      },
      {
        name: 'keywords',
        content:
          'Education platform -  auto grading -  student assignments -  virtual institute -  programming assessments -  programming courses -  online compiler -  online ide -  LMS integration'
      }
    ],
    title: 'JDoodle - Teach programming online, courses, assignments and automate grading'
  },

  educators: {
    metaTags: [
      {
        name: 'description',
        content:
          'Programming education solutions for teachers, schools, colleges and universities. Coding made possible within LMS, course materials and platforms.'
      },
      {
        name: 'keywords',
        content:
          'Education platform -  auto grading -  student assignments -  virtual institute -  programming assessments -  programming courses -  online compiler -  online ide -  online programming -  online classroom -  LMS integration'
      }
    ],
    title: 'JDoodle - Integrate online IDEs with LMS or course materials for educational institutes'
  },
  edutech: {
    metaTags: [
      {
        name: 'description',
        content:
          "Execute code in your platform, blog, website by easily integrating JDoodle's online compiler IDE APIs and IDE plugins."
      },
      {
        name: 'keywords',
        content:
          'Education technology -  modern learning -  tech solutions -  Integrate compiler IDE -  LMS integration -  compiler IDE APIs -  IDE Plugins -  online compiler -  online IDE'
      }
    ],
    title: 'JDoodle - Compile and execute code within your platform, blogs, websites'
  },

  libprovider: {
    metaTags: [
      {
        name: 'description',
        content:
          "Demonstrate software libraries and integrate online compiler IDEs with JDoodle's API and Plugins."
      },
      {
        name: 'keywords',
        content:
          'Software libraries -  compiler API integration -  compiler IDE Plugins -  online compiler -  online ide'
      }
    ],
    title: 'JDoodle - Demonstrate software library - Integrate online IDE - API and Plugins'
  },
  aboutus: {
    metaTags: [
      {
        name: 'description',
        content: 'Learn about JDoodle, our mission and story.'
      },
      {
        name: 'keywords',
        content: 'JDoodle mission -  about us -  company story -  team'
      }
    ],
    title: 'JDoodle - About Us'
  },
  contactus: {
    metaTags: [
      {
        name: 'description',
        content: 'Get in touch with JDoodle for inquiries and support.'
      },
      {
        name: 'keywords',
        content: 'Contact JDoodle -  support inquiries -  get in touch'
      }
    ],
    title: 'JDoodle - Contact Us'
  },
  pricing: {
    metaTags: [
      {
        name: 'description',
        content: "Explore JDoodle's pricing plans."
      },
      {
        name: 'keywords',
        content:
          'Pricing plans -  Compiler API price -  Compiler Plugin price -  Teach and assessment price -  online IDE Pricing'
      }
    ],
    title: 'JDoodle - Online Compiler API IDE Pricing'
  },
  blogs: {
    metaTags: [
      {
        name: 'description',
        content: "Explore insightful coding, technology and education blogs on JDoodle's platform."
      },
      {
        name: 'keywords',
        content: 'Coding blogs -  technology insights -  education blogs'
      }
    ],
    title: 'JDoodle Blog - all about programming'
  },

  api: {
    metaTags: [
      {
        name: 'description',
        content:
          'Compiler API is an online API to compile and execute programs in Java, C, C++, PHP, Perl, Python, Ruby and many more languages'
      },
      {
        name: 'keywords',
        content:
          'Online Program Compiler - Online Editor - Online IDE - Coding Online - Online Program Runner -  Online Compiler API'
      }
    ],
    title: 'Online Compiler API for Java, C, C++, PHP, Python, Ruby, Perl'
  },
  guru: {
    metaTags: [
      {
        name: 'description',
        content:
          'Guru - Online Assessment, Assignment, Tests and Courses in Java, C, C++, PHP, Perl, Python, Ruby and many more languages'
      },
      {
        name: 'keywords',
        content:
          'Guru - Online Assessment, Assignment Tests and Courses in Java, C, C++, PHP, Perl, Python, Ruby and many more languages'
      }
    ],
    title: 'Guru - Online Assessment, Assignments, Tests and Courses'
  },
  java: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Java Compiler - Online Java Editor - Online Java IDE -  Java Coding Online - Online Java Runner - Share Save Java online'
      },
      {
        name: 'keywords',
        content:
          'Online Java Compiler - Online Java Editor - Online Java IDE - Java Coding Online - Online Java Runner -  Share Save Java online'
      }
    ],
    title: 'Online Java Compiler - Online Java Editor -  Java Code Online ',
    header: 'Java Code',
    fileName: '.java',
    canonicalPath: 'online-java-compiler',
    language: 'java',
    versions: ['JDK 1.8.0_66', 'JDK 9.0.1', 'JDK 10.0.1', 'JDK 11.0.4', 'JDK 17.0.1'],
    langDisplayName: 'Java',
    isCompile: false,
    isPlus: true,
    editableTypes: ['.java', '.txt'],
    sampleScript: `public class MyClass {
    public static void main(String args[]) {
      int x=10;
      int y=25;
      int z=x+y;

      System.out.println("Sum of x+y = " + z);
    }
}`
  },
  c: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online C Compiler - Online C Editor - Online C IDE -  C Coding Online - Online C Runner - Share Save C Program online'
      },
      {
        name: 'keywords',
        content:
          'Online C Compiler - Online C Editor - Online C IDE - C Coding Online - Online C Runner -  Share Save C online'
      }
    ],
    title: 'Online C Compiler - Online C Editor -  Run C Online ',
    header: 'C Code',
    fileName: 'c',
    canonicalPath: 'c-online-compiler',
    language: 'c',
    aceCode: 'c_cpp',
    versions: ['GCC 5.3.0', 'Zapcc 5.0.0', 'GCC 7.2.0', 'GCC 8.1.0', 'GCC 9.1.0', 'GCC 11.1.0'],
    langDisplayName: 'C',
    isCompile: true,
    sampleScript: `#include<stdio.h>

int main() {
    int x=10;
    int y=25;
    int z=x+y;
    printf("Sum of x+y = %i", z);
}`
  },
  c99: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online C99 Compiler - Online C99 Editor - Online C99 IDE -  C99 Coding Online - Online C99 Runner - Share Save C99 Program online'
      },
      {
        name: 'keywords',
        content:
          'Online C99 Compiler - Online C99 Editor - Online C99 IDE - C99 Coding Online - Online C99 Runner -  Share Save C99 online'
      }
    ],
    title: 'Online C99 Compiler - Online C99 Editor -  Run C99 Online - Online C99 Runner',
    header: 'C99 Code',
    fileName: 'c',
    canonicalPath: 'compile-c99-online',
    language: 'c99',
    aceCode: 'c_cpp',
    versions: ['GCC 5.3.0', 'GCC 7.2.0', 'GCC 8.1.0', 'GCC 9.1.0', 'GCC 11.1.0'],
    langDisplayName: 'C-99',
    isCompile: true,
    sampleScript: `#include<stdio.h>

int main(void) {
    int x=10;
    int y=25;
    int z=x+y;
    printf("Sum of x+y = %i", z);
}`
  },
  cpp: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online C++ Compiler - Online C++ Editor - Online C++ IDE -  C++ Coding Online - Online C++ Runner - Share Save C++ Program online'
      },
      {
        name: 'keywords',
        content:
          'Online C++ Compiler - Online C++ Editor - Online C++ IDE - C++ Coding Online - Online C++ Runner -  Share Save C++ online'
      }
    ],
    title: 'Online C++ Compiler - Online C++ Editor -  Run C++ Online - Online C++ Runner',
    header: 'C++ Code',
    fileName: 'cpp',
    canonicalPath: 'online-compiler-c++',
    versions: ['GCC 5.3.0', 'Zapcc 5.0.0', 'GCC 7.2.0', 'GCC 8.1.0', 'GCC 9.1.0', 'GCC 11.1.0'],
    language: 'cpp',
    aceCode: 'c_cpp',
    langDisplayName: 'C++',
    isCompile: true,
    sampleScript: `#include <iostream>

using namespace std;

int main() {
    int x=10;
    int y=25;
    int z=x+y;

    cout<<"Sum of x+y = " << z;
}`
  },

  cpp14: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online C++ 14 Compiler - Online C++ 14 Editor - Online C++ 14 IDE -  C++ 14 Coding Online - Online C++ 14 Runner - Share Save C++ 14 Program online'
      },
      {
        name: 'keywords',
        content:
          'Online C++ 14 Compiler - Online C++ 14 Editor - Online C++ 14 IDE - C++ 14 Coding Online - Online C++ 14 Runner -  Share Save C++ 14 online'
      }
    ],
    title:
      'Online C++ 14 Compiler - Online C++ 14 Editor -  Run C++ 14 Online - Online C++ 14 Runner',
    header: 'C++  14 Code',
    fileName: 'cpp',
    canonicalPath: 'online-compiler-c++14',
    language: 'cpp14',
    aceCode: 'c_cpp',
    versions: [
      'g++ 14 GCC 5.3.0',
      'g++ 14 GCC 7.2.0',
      'g++ 14 GCC 8.1.0',
      'g++ 14 GCC 9.1.0',
      'GCC 11.1.0'
    ],
    langDisplayName: 'C++ 14',
    isCompile: true,
    sampleScript: `#include <iostream>

using namespace std;

int main() {
    int x=10;
    int y=25;
    int z=x+y;

    cout<<"Sum of x+y = " << z;
}`
  },

  cpp17: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online C++ 17 Compiler - Online C++ 17 Editor - Online C++ 17 IDE -  C++ 17 Coding Online - Online C++ 17 Runner - Share Save C++ 17 Program online'
      },
      {
        name: 'keywords',
        content:
          'Online C++ 17 Compiler - Online C++ 17 Editor - Online C++ 17 IDE - C++ 17 Coding Online - Online C++ 17 Runner -  Share Save C++ 17 online'
      }
    ],
    title:
      'Online C++ 17 Compiler - Online C++ 17 Editor -  Run C++ 17 Online - Online C++ 17 Runner',
    header: 'C++  17 Code',
    fileName: 'cpp',
    canonicalPath: 'online-compiler-c++17',
    language: 'cpp17',
    aceCode: 'c_cpp',
    versions: ['g++ 17 GCC 9.1.0', 'g++ 17 GCC 11.1.0'],
    langDisplayName: 'C++ 17',
    isCompile: true,
    sampleScript: `#include <iostream>

using namespace std;

int main() {
    int x=10;
    int y=25;
    int z=x+y;

    cout<<"Sum of x+y = " << z;
}`
  },
  php: {
    metaTags: [
      {
        name: 'description',
        content:
          'PHP Online Editor - PHP Online IDE -  PHP Coding Online - Share Save PHP Program online'
      },
      {
        name: 'keywords',
        content:
          'PHP Online Editor - PHP Online IDE -  PHP Coding Online - Share Save PHP Program online'
      }
    ],
    title: 'PHP Online Editor - PHP Online IDE -  PHP Coding Online',
    header: 'PHP Code',
    fileName: 'php',
    canonicalPath: 'php-online-editor',
    language: 'php',
    versions: ['5.6.16', '7.1.11', '7.2.5', '7.3.10', '8.0.13'],
    langDisplayName: 'PHP',
    isCompile: false,
    sampleScript: `<?php

    $x=10;
    $y=25;
    $z=$x+$y;

    $msg = 'Sum of x+y = ';

    print($msg.$z);

?>`
  },
  perl: {
    metaTags: [
      {
        name: 'description',
        content:
          'PERL Online Editor - PERL Online IDE -  PERL Coding Online - Share Save PERL Program online'
      },
      {
        name: 'keywords',
        content:
          'PERL Online Editor - PERL Online IDE -  PERL Coding Online - Share Save PERL Program online'
      }
    ],
    title: 'PERL Online Editor - PERL Online IDE -  PERL Coding Online',
    header: 'PERL Code',
    fileName: 'pl',
    canonicalPath: 'execute-perl-online',
    language: 'perl',
    versions: ['5.22.0', '5.26.1', '5.26.2', '5.30.0', '5.34.0'],
    langDisplayName: 'Perl',
    isCompile: false,
    sampleScript: `$x = 10;
$y = 25;
$z = $x+$y;

print "Sum of $x + $y = $z";`
  },

  python2: {
    metaTags: [
      {
        name: 'description',
        content:
          'Python Online Editor - Python Online IDE -  Python Programming Online - Share Save Python Program online'
      },
      {
        name: 'keywords',
        content:
          'Python Online Editor - Python Online IDE -  Python Programming Online - Share Save Python Program online'
      }
    ],
    title: 'Python Online Editor - Python Online IDE -  Python Programming Online',
    header: 'Python2 Code',
    fileName: 'py',
    canonicalPath: 'python-programming-online',
    language: 'python2',
    versions: ['2.7.11', '2.7.15', '2.7.16', '2.7.18'],
    aceCode: 'python',
    langDisplayName: 'Python 2',
    isCompile: false,
    sampleScript: `x=10;
y=25;
z=x+y;

print "Sum of x+y =", z;`
  },

  python3: {
    metaTags: [
      {
        name: 'description',
        content:
          'Python Online Editor - Python Online IDE -  Python Programming Online - Share Save Python Program online'
      },
      {
        name: 'keywords',
        content:
          'Python Online Editor - Python Online IDE -  Python Programming Online - Share Save Python Program online'
      }
    ],
    title: 'Python Online Editor - Python Online IDE -  Python Programming Online',
    header: 'Python3 Code',
    fileName: 'py',
    canonicalPath: 'python3-programming-online',
    language: 'python3',
    versions: ['3.5.1', ' 3.6.3', '3.6.5', '3.7.4', '3.9.9'],
    langDisplayName: 'Python 3',
    aceCode: 'python',
    isCompile: false,
    sampleScript: `x=10;
y=25;
z=x+y;

print ("sum of x+y =", z);`
  },

  ruby: {
    metaTags: [
      {
        name: 'description',
        content:
          'RUBY Online Editor - RUBY Online IDE -  RUBY Coding Online - Share Save RUBY Program online'
      },
      {
        name: 'keywords',
        content:
          'RUBY Online Editor - RUBY Online IDE -  RUBY Coding Online - Share Save RUBY Program online'
      }
    ],
    title: 'RUBY Online Editor - RUBY Online IDE -  RUBY Coding Online',
    header: 'RUBY Code',
    fileName: 'rb',
    canonicalPath: 'execute-ruby-online',
    language: 'ruby',
    versions: ['2.2.4', '2.4.2p198', '2.5.1p57', '2.6.5', '3.0.2'],
    langDisplayName: 'Ruby',
    isCompile: false,
    sampleScript: `x = 10;
y = 25;
z = x+y;

print "Sum of x + y = ", z;`
  },

  go: {
    metaTags: [
      {
        name: 'description',
        content:
          'Go Online Editor - Go Online IDE -  Go Coding Online - Share Save Go Program online'
      },
      {
        name: 'keywords',
        content:
          'Go Online Editor - Go Online IDE -  Go Coding Online - Share Save Go Program online'
      }
    ],
    title: 'Go Online Editor - Go Online IDE -  Go Coding Online',
    header: 'Go Code',
    fileName: 'go',
    canonicalPath: 'execute-go-online',
    language: 'go',
    versions: ['1.5.2', '1.9.2', '1.10.2', '1.13.1', '1.17.3'],
    langDisplayName: 'GO Lang',
    isCompile: false,
    sampleScript: `package main

import "fmt"

func main() {
    x:= 25
    y:=10

    z:= x + y

    fmt.Printf("Sum of x + y = %d", z)
}`
  },

  scala: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Scala Compiler - Online Scala Editor - Online Scala IDE -  Scala Coding Online - Online Scala Runner - Share Save Scala Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Scala Compiler - Online Scala Editor - Online Scala IDE - Scala Coding Online - Online Scala Runner -  Share Save Scala online'
      }
    ],
    title: 'Online Scala Compiler - Online Scala Editor -  Run Scala Online - Online Scala Runner',
    header: 'Scala Code',
    fileName: 'scala',
    canonicalPath: 'compile-scala-online',
    language: 'scala',
    versions: ['2.12.0', '2.12.4', '2.12.5', '2.13.0', '2.13.6'],
    langDisplayName: 'Scala',
    isCompile: false,
    sampleScript: `
object MyClass {
    def add(x:Int, y:Int) = x + y;

    def main(args: Array[String]) {
        print("sum of x + y = " + add(25,10));
    }
}`
  },

  bash: {
    metaTags: [
      {
        name: 'description',
        content:
          'Test Bash Shell Script Online - Online Bash Script Tester -  Bash Scripting Online - Test Shell Script Online - Share Save Bash Shell Script online'
      },
      {
        name: 'keywords',
        content:
          'Test Bash Script Online - Online Bash Script Tester -  Bash Scripting Online - Test Shell Script Online - Share Save Bash Script online'
      }
    ],
    title: 'Test Bash Script Online - Online Bash Script Tester - Shell Scripting online',
    header: 'Bash Script',
    fileName: 'sh',
    canonicalPath: 'test-bash-shell-script-online',
    language: 'bash',
    versions: ['4.3.42', '4.4.12', '4.4.19', '5.0.011', '5.1.12'],
    langDisplayName: 'Bash Shell',
    isCompile: false,
    sampleScript: `#!/bin/bash

echo "welcome to JDoodle"`
  },

  sql: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online SQL Editor - Online SQL Scripting -  Practice SQL Online - Share Save SQL Program online'
      },
      {
        name: 'keywords',
        content:
          'Online SQL Editor - Online SQL Scripting -  Practice SQL Online - Share Save SQL Program online'
      }
    ],
    title: 'Online SQL Editor - Online SQL Scripting -  Practice SQL Online ',
    header: 'SQL Code',
    fileName: 'sql',
    canonicalPath: 'execute-sql-online',
    language: 'sql',
    versions: ['SQLite 3.9.2', 'SQLite 3.21.0', 'SQLite 3.23.1', 'SQLite 3.29.0', 'SQLite 3.37.0'],
    langDisplayName: 'SQL',
    isCompile: false,
    sampleScript: `
        create table calc(x int, y int);

        insert into calc values(10, 25);

        select x,y, (x+y) from calc;`
  },

  pascal: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Pascal Compiler - Online Pascal Editor - Online Pascal IDE -  Pascal Coding Online - Online Pascal Runner - Share Save Pascal Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Pascal Compiler - Online Pascal Editor - Online Pascal IDE - Pascal Coding Online - Online Pascal Runner -  Share Save Pascal online'
      }
    ],
    title:
      'Online Pascal Compiler - Online Pascal Editor -  Run Pascal Online - Online Pascal Runner',
    header: 'Pascal Code',
    fileName: 'pas',
    canonicalPath: 'execute-pascal-online',
    language: 'pascal',
    versions: ['fpc 3.0.0', 'fpc-3.0.2', 'fpc-3.0.4', 'fpc-3.2.2'],
    langDisplayName: 'Pascal',
    isCompile: true,
    sampleScript: `Program AddNums(output);
    var x,y,z:integer;
    begin

    x:=10;
    y:=25;
    z:=x+y;
    writeln('The sum of x+y is ', z);
end.`
  },

  csharp: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online C# Compiler - Online C# Editor - Online C# IDE -  C# Coding Online - Online C# Runner - Share Save C# Program online'
      },
      {
        name: 'keywords',
        content:
          'Online C# Compiler - Online C# Editor - Online C# IDE - C# Coding Online - Online C# Runner -  Share Save C# online'
      }
    ],
    title:
      'Online C# Compiler - Online C# Editor -  Run C# Online - Online C# Runner - Execute C Sharp ',
    header: 'C# Code',
    fileName: 'cs',
    canonicalPath: 'compile-c-sharp-online',
    language: 'csharp',
    versions: ['mono 4.2.2', 'mono 5.0.0', 'mono 5.10.1', 'mono 6.0.0', 'mono-6.12.0'],
    langDisplayName: 'C#',
    isCompile: true,
    sampleScript: `using System;

class Program
{
    static void Main() {
        int x = 10;
        int y = 25;
        int z = x + y;

        Console.Write("Sum of x + y = "+ z);
    }
}`
  },
  vbn: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online VB.Net Compiler - Online VB.Net Editor - Online VB.Net IDE -  VB.Net Coding Online - Online VB.Net Runner - Share Save VB.Net Program online'
      },
      {
        name: 'keywords',
        content:
          'Online VB.Net Compiler - Online VB.Net Editor - Online VB.Net IDE - VB.Net Coding Online - Online VB.Net Runner -  Share Save VB.Net online'
      }
    ],
    title:
      'Online VB.Net Compiler - Online VB.Net Editor -  Run VB.Net Online - Online VB.Net Runner',
    header: 'VB.Net Code',
    fileName: 'vb',
    canonicalPath: 'compile-vb-dot-net-online',
    language: 'vbn',
    versions: ['mono 4.0.1', 'mono 4.6', 'mono 5.10.1', 'mono 6.0.0', 'mono 6.12.0'],
    langDisplayName: 'VB.Net',
    isCompile: true,
    sampleScript: `Imports System

Public Class Test
    Public Shared Sub Main()
        Dim x, y as Integer
        x = 10
        y = 25
        System.Console.WriteLine("Sum of x and y = " & (x+y))
    End Sub
End Class`
  },

  haskell: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Haskell Compiler - Online Haskell Editor - Online Haskell IDE -  Haskell Coding Online - Online Haskell Runner - Share Save Haskell Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Haskell Compiler - Online Haskell Editor - Online Haskell IDE - Haskell Coding Online - Online Haskell Runner -  Share Save Haskell online'
      }
    ],
    title:
      'Online Haskell Compiler - Online Haskell Editor -  Run Haskell Online - Online Haskell Runner',
    header: 'Haskell Code',
    fileName: 'hs',
    canonicalPath: 'execute-haskell-online',
    language: 'haskell',
    versions: ['ghc 7.10.3', 'ghc 8.2.1', 'ghc 8.2.2', 'ghc 8.6.5', 'ghc 9.0.1'],
    langDisplayName: 'Haskell',
    isCompile: true,
    sampleScript: `addMe :: Integer -> Integer -> Integer
addMe x y = x + y

main :: IO ()
main =  do
putStr "Sum of x + y = "
print(addMe 10 25)`
  },

  objc: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Objective C Compiler - Online Objective C Editor - Online Objective C IDE -  Objective C Coding Online - Online Objective C Runner - Share Save Objective C Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Objective C Compiler - Online Objective C Editor - Online Objective C IDE - Objective C Coding Online - Online Objective C Runner -  Share Save Objective C online'
      }
    ],
    title:
      'Online Objective C Compiler - Online Objective C Editor -  Run Objective C Online - Online Objective C Runner',
    header: 'Objective C Code',
    fileName: 'm',
    canonicalPath: 'execute-objective-c-online',
    language: 'objc',
    versions: ['GCC 5.3.0', 'GCC 7.2.0', 'GCC 8.1.0', 'GCC 9.1.0', 'GCC 11.1.0'],
    langDisplayName: 'Objective C',
    aceCode: 'objectivec',
    isCompile: true,
    sampleScript: `#import <Foundation/Foundation.h>

int main (int argc, const char * argv[])
{
    NSAutoreleasePool * pool = [[NSAutoreleasePool alloc] init];

    int x = 10;
    int y = 25;

    NSLog(@"Sum of x + y = %i", (x+y));

    [pool drain];
    return 0;
}`
  },

  swift: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Swift Compiler - Online Swift Editor - Online Swift IDE -  Swift Coding Online - Online Swift Runner - Share Save Swift Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Swift Compiler - Online Swift Editor - Online Swift IDE - Swift Coding Online - Online Swift Runner -  Share Save Swift online'
      }
    ],
    title: 'Online Swift Compiler - Online Swift Editor -  Run Swift Online - Online Swift Runner',
    header: 'Swift Code',
    fileName: 'swift',
    canonicalPath: 'execute-swift-online',
    language: 'swift',
    versions: ['2.2', '3.1.1', '4.1', '5.1', '5.5'],
    langDisplayName: 'Swift',
    isCompile: true,
    sampleScript: `let x = 10
let y = 25
print("Sum of x and y = ", (x+y))`
  },
  groovy: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Groovy Compiler - Online Groovy Editor - Online Groovy IDE -  Groovy Coding Online - Online Groovy Runner - Share Save Groovy Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Groovy Compiler - Online Groovy Editor - Online Groovy IDE - Groovy Coding Online - Online Groovy Runner -  Share Save Groovy online'
      }
    ],
    title:
      'Online Groovy Compiler - Online Groovy Editor -  Run Groovy Online - Online Groovy Runner',
    header: 'Groovy Code',
    fileName: 'groovy',
    canonicalPath: 'execute-groovy-online',
    language: 'groovy',
    versions: [
      '2.4.6 JVM: 1.7.0_99',
      '2.4.12 JVM: 9.0.1',
      '2.4.15 JVM: 10.0.1',
      '2.5.8 JVM: 11.0.4',
      '3.0.9 JVM: 17.0.1'
    ],
    langDisplayName: 'Groovy',
    isCompile: false,
    sampleScript: `def x = 10
def y = 25

print 'Sum of x + y = ' + (x + y)`
  },
  fortran: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Fortran Compiler - Online Fortran Editor - Online Fortran IDE -  Fortran Coding Online - Online Fortran Runner - Share Save Fortran Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Fortran Compiler - Online Fortran Editor - Online Fortran IDE - Fortran Coding Online - Online Fortran Runner -  Share Save Fortran online'
      }
    ],
    title:
      'Online Fortran Compiler - Online Fortran Editor -  Run Fortran Online - Online Fortran Runner',
    header: 'Fortran Code',
    fileName: 'f95',
    canonicalPath: 'execute-fortran-online',
    language: 'fortran',
    versions: ['GNU 5.3.0', 'GNU 7.2.0', 'GNU 8.1.0', 'GNU 9.1.0', 'GNU 11.1.0'],
    langDisplayName: 'Fortran',
    isCompile: true,
    sampleScript: `program sum
    REAL X,Y,Z
    X = 10
    Y= 25
    Z = X + Y
    PRINT *,"sum of x + y = ", Z
end program sum`
  },
  brainfuck: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Brainfuck Compiler - Online Brainfuck Editor - Online Brainfuck IDE -  Brainfuck Coding Online - Online Brainfuck Runner - Share Save Brainfuck Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Brainfuck Compiler - Online Brainfuck Editor - Online Brainfuck IDE - Brainfuck Coding Online - Online Brainfuck Runner -  Share Save Brainfuck online'
      }
    ],
    title:
      'Online Brainf**k Compiler - Online Brainf**k Editor -  Run Brainf**k Online - Online Brainf**k Runner',
    header: 'Brainf**k Code',
    fileName: 'bf',
    canonicalPath: 'execute-brainfuck-online',
    language: 'brainfuck',
    versions: ['bfc-0.1'],
    langDisplayName: 'Brainf**k',
    isCompile: true,
    sampleScript: `>+++++++++[<++++++++>-]<.>+++++++[<++++>-]<+.+++++++..+++.>>>++++++++[<++++>-]
<.>>>++++++++++[<+++++++++>-]<---.<<<<.+++.------.--------.>>+.>++++++++++.`
  },

  lua: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Lua Compiler - Online Lua Editor - Online Lua IDE -  Lua Coding Online - Online Lua Runner - Share Save Lua Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Lua Compiler - Online Lua Editor - Online Lua IDE - Lua Coding Online - Online Lua Runner -  Share Save Lua online'
      }
    ],
    title: 'Online Lua Compiler - Online Lua Editor -  Run Lua Online - Online Lua Runner',
    header: 'Lua Code',
    fileName: 'lua',
    canonicalPath: 'execute-lua-online',
    language: 'lua',
    versions: ['5.3.2', '5.3.4', '5.3.5', '5.4.3'],
    langDisplayName: 'Lua',
    isCompile: false,
    sampleScript: `local x = 10
local y = 25
local z = x + y

print("Sum of x+y =",z)`
  },

  tcl: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online TCL Compiler - Online TCL Editor - Online TCL IDE -  TCL Coding Online - Online TCL Runner - Share Save TCL Program online'
      },
      {
        name: 'keywords',
        content:
          'Online TCL Compiler - Online TCL Editor - Online TCL IDE - TCL Coding Online - Online TCL Runner -  Share Save TCL online'
      }
    ],
    title: 'Online TCL Compiler - Online TCL Editor -  Run TCL Online - Online TCL Runner',
    header: 'TCL Code',
    fileName: 'tcl',
    canonicalPath: 'execute-tcl-online',
    language: 'tcl',
    versions: ['8.6', '8.6.7', '8.6.8', '8.6.9', '8.6.12'],
    langDisplayName: 'TCL',
    isCompile: false,
    sampleScript: `set x 10
set y 25
set z [expr $x + $y]

puts "Sum of x +y =  $z"`
  },

  hack: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Hack Compiler - Online Hack Editor - Online Hack IDE -  Hack Coding Online - Online Hack Runner - Share Save Hack Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Hack Compiler - Online Hack Editor - Online Hack IDE - Hack Coding Online - Online Hack Runner -  Share Save Hack online'
      }
    ],
    title: 'Online Hack Compiler - Online Hack Editor -  Run Hack Online - Online Hack Runner',
    header: 'Hack Code',
    fileName: 'hack',
    canonicalPath: 'execute-hack-online',
    language: 'hack',
    versions: ['HipHop VM 3.13.0'],
    langDisplayName: 'Hack',
    isCompile: true,
    sampleScript: `<?hh

    $x=10;
    $y=25;
    $z=$x+$y;

    $msg = 'Sum of x+y = ';

    echo($msg.$z);`
  },
  rust: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online RUST Compiler - Online RUST Editor - Online RUST IDE -  RUST Coding Online - Online RUST Runner - Share Save RUST Program online'
      },
      {
        name: 'keywords',
        content:
          'Online RUST Compiler - Online RUST Editor - Online RUST IDE - RUST Coding Online - Online RUST Runner -  Share Save RUST online'
      }
    ],
    title: 'Online RUST Compiler - Online RUST Editor -  Run RUST Online - Online RUST Runner',
    header: 'RUST Code',
    fileName: 'rs',
    canonicalPath: 'execute-rust-online',
    language: 'rust',
    versions: ['1.10.0', '1.21.0', '1.25.0', '1.38.0', '1.56.1'],
    langDisplayName: 'RUST',
    isCompile: true,
    sampleScript: `fn main() {
    let x = 10;
    let y = 25;

    let z = x + y;

    println!("Sum of x and y = {} ",z);
}`
  },
  d: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online D Compiler - Online D Editor - Online D IDE -  D Coding Online - Online D Runner - Share Save D Program online'
      },
      {
        name: 'keywords',
        content:
          'Online D Compiler - Online D Editor - Online D IDE - D Coding Online - Online D Runner -  Share Save D online'
      }
    ],
    title: 'Online D Compiler - Online D Editor -  Run D Online - Online D Runner',
    header: 'D Code',
    fileName: 'd',
    canonicalPath: 'execute-d-online',
    language: 'd',
    versions: ['DMD64 D Compiler v2.071.1', 'DMD64 D Compiler v2.088', 'DMD64 D Compiler v2.098'],
    langDisplayName: 'D',
    isCompile: true,
    sampleScript: `import std.stdio;

void main(string[ ] args) {

    int x = 10;
    int y = 25;

    int z = x + y;

    writeln("x + y = ", z);
}`
  },
  ada: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Ada Compiler - Online Ada Editor - Online Ada IDE -  Ada Coding Online - Online Ada Runner - Share Save Ada Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Ada Compiler - Online Ada Editor - Online Ada IDE - Ada Coding Online - Online Ada Runner -  Share Save Ada online'
      }
    ],
    title: 'Online Ada Compiler - Online Ada Editor -  Run Ada Online - Online Ada Runner',
    header: 'Ada Code',
    fileName: 'adb',
    canonicalPath: 'execute-ada-online',
    language: 'ada',
    versions: [
      'GNATMAKE 6.1.1',
      'GNATMAKE 7.2.0',
      'GNATMAKE 8.1.0',
      'GNATMAKE 9.1.0',
      'GNATMAKE 11.1.0'
    ],
    langDisplayName: 'Ada',
    isCompile: true,
    sampleScript: `with Ada.Text_IO; use Ada.Text_IO;
with Ada.Integer_Text_IO; use Ada.Integer_Text_IO;
procedure jdoodle is
    X: Natural := 10;
    Y: Natural := 25;
    Z: Natural := X + Y;
begin
    Put("X + Y = ");
    Put(Z);
end jdoodle;`
  },
  r: {
    metaTags: [
      {
        name: 'description',
        content: 'R Online Editor - R Online IDE -  R Coding Online - Share Save R Program online'
      },
      {
        name: 'keywords',
        content: 'R Online Editor - R Online IDE -  R Coding Online - Share Save R Program online'
      }
    ],
    title: 'R Online Editor - R Online IDE -  R Coding Online',
    header: 'R Code',
    fileName: 'r',
    canonicalPath: 'execute-r-online',
    language: 'r',
    versions: ['3.3.1', '3.4.2', '3.5.0', '3.6.1', '4.1.2'],
    langDisplayName: 'R Language',
    isCompile: false,
    sampleScript: `x <- 10
y <- 25
z <- sum(x,y)

cat("x + y = ", z)`
  },
  freebasic: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online FREE BASIC Compiler - Online FREE BASIC Editor - Online FREE BASIC IDE -  FREE BASIC Coding Online - Online FREE BASIC Runner - Share Save FREE BASIC Program online'
      },
      {
        name: 'keywords',
        content:
          'Online FREE BASIC Compiler - Online FREE BASIC Editor - Online FREE BASIC IDE - FREE BASIC Coding Online - Online FREE BASIC Runner -  Share Save FREE BASIC online'
      }
    ],
    title:
      'Online FREE BASIC Compiler - Online FREE BASIC Editor -  Run FREE BASIC Online - Online FREE BASIC Runner',
    header: 'FREE BASIC Code',
    fileName: 'bas',
    canonicalPath: 'execute-freebasic-online',
    language: 'freebasic',
    versions: ['1.05.0', '1.07.1', '1.08.1'],
    langDisplayName: 'FREE BASIC',
    isCompile: true,
    sampleScript: `Dim x as Integer = 10
DIM y as Integer = 25
DIM z as Integer
z = x + y

print "Sum of x + y = "; z`
  },
  verilog: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online VERILOG Compiler - Online VERILOG Editor - Online VERILOG IDE -  VERILOG Coding Online - Online VERILOG Runner - Share Save VERILOG Program online'
      },
      {
        name: 'keywords',
        content:
          'Online VERILOG Compiler - Online VERILOG Editor - Online VERILOG IDE - VERILOG Coding Online - Online VERILOG Runner -  Share Save VERILOG online'
      }
    ],
    title:
      'Online VERILOG Compiler - Online VERILOG Editor -  Run VERILOG Online - Online VERILOG Runner',
    header: 'VERILOG Code',
    fileName: 'v',
    canonicalPath: 'execute-verilog-online',
    language: 'verilog',
    versions: ['10.1', '10.2', '10.3', '11'],
    langDisplayName: 'VERILOG',
    isCompile: true,
    sampleScript: `module jdoodle;
    initial begin
        $display ("Welcome to JDoodle!!!");
        $finish;
    end
endmodule`
  },
  cobol: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online COBOL Compiler - Online COBOL Editor - Online COBOL IDE -  COBOL Coding Online - Online COBOL Runner - Share Save COBOL Program online'
      },
      {
        name: 'keywords',
        content:
          'Online COBOL Compiler - Online COBOL Editor - Online COBOL IDE - COBOL Coding Online - Online COBOL Runner -  Share Save COBOL online'
      }
    ],
    title: 'Online COBOL Compiler - Online COBOL Editor -  Run COBOL Online - Online COBOL Runner',
    header: 'COBOL Code',
    fileName: 'cobc',
    canonicalPath: 'execute-cobol-online',
    language: 'cobol',
    versions: ['GNU COBOL 2.0.0', 'GNU COBOL 2.2.0', 'GNU COBOL 3.0', 'GNU COBOL 3.1.2'],
    langDisplayName: 'COBOL',
    isCompile: true,
    sampleScript: `IDENTIFICATION DIVISION.
PROGRAM-ID. HELLO-WORLD.
DATA DIVISION.
    WORKING-STORAGE SECTION.
        77 X PIC 99.
        77 Y PIC 99.
        77 Z PIC 99.
PROCEDURE DIVISION.
    SET X TO 10.
    SET Y TO 25.
    ADD X Y GIVING Z.
    DISPLAY "X + Y = "Z.
STOP RUN.
`
  },
  dart: {
    metaTags: [
      {
        name: 'description',
        content:
          'DART Online Editor - DART Online IDE -  DART Coding Online - Share Save DART Program online'
      },
      {
        name: 'keywords',
        content:
          'DART Online Editor - DART Online IDE -  DART Coding Online - Share Save DART Program online'
      }
    ],
    title: 'DART Online Editor - DART Online IDE -  DART Coding Online',
    header: 'DART Code',
    fileName: 'dart',
    canonicalPath: 'execute-dart-online',
    language: 'dart',
    versions: ['1.18.0', '1.24.2', '1.24.3', '2.5.1', '2.14.4'],
    langDisplayName: 'Dart',
    isCompile: false,
    sampleScript: `void main(){
    var x = 10;
    var y = 25;
    var z = x + y;
    print("x + y = $z");
}`
  },

  yabasic: {
    metaTags: [
      {
        name: 'description',
        content:
          'YABASIC Online Editor - YABASIC Online IDE -  YABASIC Coding Online - Share Save YABASIC Program online'
      },
      {
        name: 'keywords',
        content:
          'YABASIC Online Editor - YABASIC Online IDE -  YABASIC Coding Online - Share Save YABASIC Program online'
      }
    ],
    title: 'YABASIC Online Editor - YABASIC Online IDE -  YABASIC Coding Online',
    header: 'YABASIC Code',
    fileName: 'bas',
    canonicalPath: 'execute-yabasic-online',
    language: 'yabasic',
    versions: ['2.769', '2.84.1'],
    langDisplayName: 'YaBasic',
    isCompile: false,
    sampleScript: `let x = 10
let y = 25
let z = x + y

print "sum of x + y = ", z`
  },

  clojure: {
    metaTags: [
      {
        name: 'description',
        content:
          'Clojure Online Editor - Clojure Online IDE -  Clojure Coding Online - Share Save Clojure Program online'
      },
      {
        name: 'keywords',
        content:
          'Clojure Online Editor - Clojure Online IDE -  Clojure Coding Online - Share Save Clojure Program online'
      }
    ],
    title: 'Clojure Online Editor - Clojure Online IDE -  Clojure Coding Online',
    header: 'Clojure Code',
    fileName: 'clj',
    canonicalPath: 'execute-clojure-online',
    language: 'clojure',
    versions: ['1.8.0', '1.9.0', '1.10.1', '1.10.3'],
    langDisplayName: 'Clojure',
    isCompile: false,
    sampleScript: `(ns clojure.examples.hello
(:gen-class))

(defn sum-of-numbers [x y]
(println (format "x + y = %d" (+ x y))))

(sum-of-numbers 10 25)`
  },
  nodejs: {
    metaTags: [
      {
        name: 'description',
        content:
          'NodeJS Online Editor - NodeJS Online IDE -  NodeJS Coding Online - Share Save NodeJS Program online'
      },
      {
        name: 'keywords',
        content:
          'NodeJS Online Editor - NodeJS Online IDE -  NodeJS Coding Online - Share Save NodeJS Program online'
      }
    ],
    title: 'NodeJS Online Editor - NodeJS Online IDE -  NodeJS Coding Online',
    header: 'NodeJS Code',
    fileName: 'js',
    canonicalPath: 'execute-nodejs-online',
    language: 'nodejs',
    versions: ['6.3.1', '9.2.0', '10.1.0', '12.11.1', '17.1.0'],
    langDisplayName: 'NodeJS',
    isCompile: false,
    sampleScript: `var x = 10;
var y = 25;
var z = x + y;

console.log("x + y = " + z);`
  },
  scheme: {
    metaTags: [
      {
        name: 'description',
        content:
          'Scheme Online Editor - Scheme Online IDE -  Scheme Coding Online - Share Save Scheme Program online'
      },
      {
        name: 'keywords',
        content:
          'Scheme Online Editor - Scheme Online IDE -  Scheme Coding Online - Share Save Scheme Program online'
      }
    ],
    title: 'Scheme Online Editor - Scheme Online IDE -  Scheme Coding Online',
    header: 'Scheme Code',
    fileName: 'sc',
    canonicalPath: 'execute-scheme-online',
    language: 'scheme',
    versions: ['Gauche 0.9.4', 'Gauche 0.9.5', 'Gauche 0.9.8', 'Gauche 0.9.10'],
    langDisplayName: 'Scheme',
    isCompile: false,
    sampleScript: `(define (sum a b)
(display "x + y = ")
(display (+ a b)))

(sum 10 25)
(newline)`
  },
  forth: {
    metaTags: [
      {
        name: 'description',
        content:
          'Forth Online Editor - Forth Online IDE -  Forth Coding Online - Share Save Forth Program online'
      },
      {
        name: 'keywords',
        content:
          'Forth Online Editor - Forth Online IDE -  Forth Coding Online - Share Save Forth Program online'
      }
    ],
    title: 'Forth Online Editor - Forth Online IDE -  Forth Coding Online',
    header: 'Forth Code',
    fileName: 'fth',
    canonicalPath: 'execute-forth-online',
    language: 'forth',
    versions: ['gforth 0.7.3'],
    langDisplayName: 'Forth',
    isCompile: false,
    sampleScript: '.( sum of 10 and 25 is ) 10 25 + .'
  },

  prolog: {
    metaTags: [
      {
        name: 'description',
        content:
          'Prolog Online Editor - Prolog Online IDE -  Prolog Coding Online - Share Save Prolog Program online'
      },
      {
        name: 'keywords',
        content:
          'Prolog Online Editor - Prolog Online IDE -  Prolog Coding Online - Share Save Prolog Program online'
      }
    ],
    title: 'Prolog Online Editor - Prolog Online IDE -  Prolog Coding Online',
    header: 'Prolog Code',
    fileName: 'pg',
    canonicalPath: 'execute-prolog-online',
    language: 'prolog',
    versions: ['GNU Prolog 1.4.4', 'GNU Prolog 1.4.5', 'GNU Prolog 1.5.0'],
    langDisplayName: 'Prolog',
    isCompile: false,
    sampleScript: `:- initialization(main).
  main :- write('Welcome to JDoodle.com!!').`
  },
  octave: {
    metaTags: [
      {
        name: 'description',
        content:
          'Octave Online Editor - Octave Online IDE -  Octave Coding Online - Share Save Octave Program online'
      },
      {
        name: 'keywords',
        content:
          'Octave Online Editor - Octave Online IDE -  Octave Coding Online - Share Save Octave Program online'
      }
    ],
    title: 'Octave Online Editor - Octave Online IDE -  Octave Coding Online',
    header: 'Octave Code',
    fileName: 'm',
    canonicalPath: 'execute-octave-matlab-online',
    language: 'octave',
    versions: ['GNU 4.0.0', 'GNU 4.2.1', 'GNU 4.4.0', 'GNU 5.1.0', 'GNU 6.4.0'],
    langDisplayName: 'Octave',
    isCompile: false,
    sampleScript: `vector = (1:1:10);
matrix = [vector ; vector * 5; vector * 10 ]
matrix(1:3, 2:4)`
  },
  coffeescript: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online CoffeeScript Compiler - Online CoffeeScript Editor - Online CoffeeScript IDE -  CoffeeScript Coding Online - Online CoffeeScript Runner - Share Save CoffeeScript Program online'
      },
      {
        name: 'keywords',
        content:
          'Online CoffeeScript Compiler - Online CoffeeScript Editor - Online CoffeeScript IDE - CoffeeScript Coding Online - Online CoffeeScript Runner -  Share Save CoffeeScript online'
      }
    ],
    title:
      'Online CoffeeScript Compiler - Online CoffeeScript Editor -  Run CoffeeScript Online - Online CoffeeScript Runner',
    header: 'CoffeeScript Code',
    fileName: 'coffee',
    canonicalPath: 'compile-coffeescript-online',
    language: 'coffeescript',
    versions: ['1.11.1', '2.0.0', '2.3.0', '2.4.1', '2.6.1'],
    langDisplayName: 'CoffeeScript',
    aceCode: 'coffee',
    isCompile: true,
    sampleScript: `x = 10
y = 25
z = x+y

console.log("Sum of ", x , " and ", y , " is ", z)`
  },
  icon: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Icon Compiler - Online Icon Editor - Online Icon IDE -  Icon Coding Online - Online Icon Runner - Share Save Icon Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Icon Compiler - Online Icon Editor - Online Icon IDE - Icon Coding Online - Online Icon Runner -  Share Save Icon online'
      }
    ],
    title: 'Online Icon Compiler - Online Icon Editor -  Run Icon Online - Online Icon Runner',
    header: 'Icon Code',
    fileName: 'icn',
    canonicalPath: 'compile-icon-online',
    language: 'icon',
    versions: ['9.4.3', '9.5.1'],
    langDisplayName: 'Icon',
    isCompile: true,
    sampleScript: `procedure main()
    x := 10;
    y := 25;
    z := x + y;

    write ("sum of ", x, " and ",y," is ",z);
end`
  },

  fsharp: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online F# Compiler - Online F# Editor - Online F# IDE -  F# Coding Online - Online F# Runner - Share Save F# Program online'
      },
      {
        name: 'keywords',
        content:
          'Online F# Compiler - Online F# Editor - Online F# IDE - F# Coding Online - Online F# Runner -  Share Save F# online'
      }
    ],
    title: 'Online F# Compiler - Online F# Editor -  Run F# Online - Online F# Runner',
    header: 'F# Code',
    fileName: 'fs',
    canonicalPath: 'compile-fsharp-online',
    language: 'fsharp',
    versions: ['4.1', '4.5.0'],
    langDisplayName: 'F#',
    isCompile: true,
    sampleScript: `printf "welcome to JDoodle.com"`
  },

  nasm: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Assembler (NASM) Compiler - Online Assembler (NASM) Editor - Online Assembler (NASM) IDE -  Assembler (NASM) Coding Online - Online Assembler (NASM) Runner - Share Save Assembler (NASM) Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Assembler (NASM) Compiler - Online Assembler (NASM) Editor - Online Assembler (NASM) IDE - Assembler (NASM) Coding Online - Online Assembler (NASM) Runner -  Share Save Assembler (NASM) online'
      }
    ],
    title:
      'Online Assembler (NASM) Compiler - Online Assembler (NASM) Editor -  Run Assembler (NASM) Online - Online Assembler (NASM) Runner',
    header: 'Assembler (NASM) Code',
    fileName: 'asm',
    canonicalPath: 'compile-assembler-nasm-online',
    language: 'nasm',
    versions: ['2.11.08', '2.13.01', '2.13.03', '2.14.02', '2.15.05'],
    langDisplayName: 'Assembler - NASM',
    aceCode: 'assembly_x86',
    isCompile: true,
    sampleScript: `section .text

global _start

_start:

    mov     eax, [x]
    sub     eax, '0'
    mov     ebx, [y]
    sub     ebx, '0'
    add     eax, ebx
    add     eax, '0'

    mov     [sum], eax

    mov     ecx, msg
    mov     edx, len
    mov     ebx, 1
    mov     eax, 4
    int     0x80

    mov     ecx, sum
    mov     edx, 1
    mov     ebx, 1
    mov     eax, 4
    int     0x80

    mov     eax, 1
    int     0x80

section .data
    x db '5'
    y db '3'
    msg db  "sum of x and y is "
    len equ $ - msg

segment .bss

    sum resb 1
`
  },

  gccasm: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Assembler (GCC) Compiler - Online Assembler (GCC) Editor - Online Assembler (GCC) IDE -  Assembler (GCC) Coding Online - Online Assembler (GCC) Runner - Share Save Assembler (GCC) Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Assembler (GCC) Compiler - Online Assembler (GCC) Editor - Online Assembler (GCC) IDE - Assembler (GCC) Coding Online - Online Assembler (GCC) Runner -  Share Save Assembler (GCC) online'
      }
    ],
    title:
      'Online Assembler (GCC) Compiler - Online Assembler (GCC) Editor -  Run Assembler (GCC) Online - Online Assembler (GCC) Runner',
    header: 'Assembler (GCC) Code',
    fileName: 's',
    canonicalPath: 'compile-assembler-gcc-online',
    language: 'gccasm',
    versions: ['GCC 6.2.1', 'GCC 8.1.0', 'GCC 9.1.0', 'GCC 11.1.0'],
    langDisplayName: 'Assembler - GCC',
    aceCode: 'assembly_x86',
    isCompile: true,
    sampleScript: `.data
.globl greet
greet:
.string "Welcome to JDoodle"

.text
.global main
main:
    pushq   %rbp
    movq    %rsp,       %rbp
    movq    $greet,     %rdi
    call    puts
    movq    $0,         %rax
    leave
    ret
`
  },

  intercal: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Intercal Compiler - Online Intercal Editor - Online Intercal IDE -  Intercal Coding Online - Online Intercal Runner - Share Save Intercal Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Intercal Compiler - Online Intercal Editor - Online Intercal IDE - Intercal Coding Online - Online Intercal Runner -  Share Save Intercal online'
      }
    ],
    title:
      'Online Intercal Compiler - Online Intercal Editor -  Run Intercal Online - Online Intercal Runner',
    header: 'Intercal Code',
    fileName: 'i',
    canonicalPath: 'compile-intercal-online',
    language: 'intercal',
    versions: ['0.30'],
    langDisplayName: 'Intercal',
    isCompile: true,
    sampleScript: `DO ,1 <- #19
PLEASE DO ,1 SUB #1 <- #22
DO ,1 SUB #2 <- #68
DO ,1 SUB #3 <- #112
DO ,1 SUB #4 <- #112
DO ,1 SUB #5 <- #208
DO ,1 SUB #6 <- #64
DO ,1 SUB #7 <- #16
PLEASE DO ,1 SUB #8 <- #162
DO ,1 SUB #9 <- #214
DO ,1 SUB #10 <- #56
DO ,1 SUB #11 <- #242
PLEASE DO ,1 SUB #12 <- #178
DO ,1 SUB #13 <- #48
DO ,1 SUB #14 <- #44
DO ,1 SUB #15 <- #0
DO ,1 SUB #16 <- #208
DO ,1 SUB #17 <- #240
DO ,1 SUB #18 <- #144
DO ,1 SUB #19 <- #34
PLEASE READ OUT ,1
PLEASE GIVE UP
`
  },
  nemerle: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Nemerle Compiler - Online Nemerle Editor - Online Nemerle IDE -  Nemerle Coding Online - Online Nemerle Runner - Share Save Nemerle Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Nemerle Compiler - Online Nemerle Editor - Online Nemerle IDE - Nemerle Coding Online - Online Nemerle Runner -  Share Save Nemerle online'
      }
    ],
    title:
      'Online Nemerle Compiler - Online Nemerle Editor -  Run Nemerle Online - Online Nemerle Runner',
    header: 'Nemerle Code',
    fileName: 'n',
    canonicalPath: 'compile-nemerle-online',
    language: 'nemerle',
    versions: ['1.2.0.507'],
    langDisplayName: 'Nemerle',
    isCompile: true,
    sampleScript: `class JDoodle
{
    static Main () : void
    {
        def x = 10;
        def y = 25;
        def z = x + y;
        System.Console.WriteLine ("Sum of {0} and {1} is {2}", x, y, z);
    }
}`
  },
  ocaml: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Ocaml Compiler - Online Ocaml Editor - Online Ocaml IDE -  Ocaml Coding Online - Online Ocaml Runner - Share Save Ocaml Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Ocaml Compiler - Online Ocaml Editor - Online Ocaml IDE - Ocaml Coding Online - Online Ocaml Runner -  Share Save Ocaml online'
      }
    ],
    title: 'Online Ocaml Compiler - Online Ocaml Editor -  Run Ocaml Online - Online Ocaml Runner',
    header: 'Ocaml Code',
    fileName: 'ml',
    canonicalPath: 'compile-ocaml-online',
    language: 'ocaml',
    versions: ['4.03.0', '4.08.1', '4.12.0'],
    langDisplayName: 'Ocaml',
    isCompile: true,
    sampleScript: `print_string "Welcome to OCAML online ide from JDoodle.com!\\n";;`
  },

  unlambda: {
    metaTags: [
      {
        name: 'description',
        content:
          'Unlambda Online Editor - Unlambda Online IDE -  Unlambda Coding Online - Share Save Unlambda Program online'
      },
      {
        name: 'keywords',
        content:
          'Unlambda Online Editor - Unlambda Online IDE -  Unlambda Coding Online - Share Save Unlambda Program online'
      }
    ],
    title: 'Unlambda Online Editor - Unlambda Online IDE -  Unlambda Coding Online',
    header: 'Unlambda Code',
    fileName: 'un',
    canonicalPath: 'execute-unlambda-online',
    language: 'unlambda',
    versions: ['0.1.3', '0.1.4.2'],
    langDisplayName: 'Unlambda',
    isCompile: false,
    sampleScript: '`r``````````````````.W.e.l.c.o.m.e. .t.o. .J.D.o.o.d.l.ei'
  },

  picolisp: {
    metaTags: [
      {
        name: 'description',
        content:
          'Picolisp Online Editor - Picolisp Online IDE -  Picolisp Coding Online - Share Save Picolisp Program online'
      },
      {
        name: 'keywords',
        content:
          'Picolisp Online Editor - Picolisp Online IDE -  Picolisp Coding Online - Share Save Picolisp Program online'
      }
    ],
    title: 'Picolisp Online Editor - Picolisp Online IDE -  Picolisp Coding Online',
    header: 'Picolisp Code',
    fileName: 'pil',
    canonicalPath: 'execute-picolisp-online',
    language: 'picolisp',
    versions: ['3.1.11.1', '17.11.14', '18.5.11', '18.9.5', '21.6.30'],
    langDisplayName: 'Picolisp',
    aceCode: 'lisp',
    isCompile: false,
    sampleScript: `"Welcome to Picolisp online ide from JDoodle.com"
(bye)`
  },

  spidermonkey: {
    metaTags: [
      {
        name: 'description',
        content:
          'SpiderMonkey Online Editor - SpiderMonkey Online IDE -  SpiderMonkey Coding Online - Share Save SpiderMonkey Program online'
      },
      {
        name: 'keywords',
        content:
          'SpiderMonkey Online Editor - SpiderMonkey Online IDE -  SpiderMonkey Coding Online - Share Save SpiderMonkey Program online'
      }
    ],
    title: 'SpiderMonkey Online Editor - SpiderMonkey Online IDE -  SpiderMonkey Coding Online',
    header: 'SpiderMonkey Code',
    fileName: 'js',
    canonicalPath: 'execute-spidermonkey-online',
    language: 'spidermonkey',
    versions: ['38', '45.0.2'],
    langDisplayName: 'SpiderMonkey',
    isCompile: false,
    sampleScript: `var x = 10;
var y = 25;
var z = x + y;

console.log("x + y = " + z);`
  },

  rhino: {
    metaTags: [
      {
        name: 'description',
        content:
          'Rhino JS Online Editor - Rhino JS Online IDE -  Rhino JS Coding Online - Share Save Rhino JS Program online'
      },
      {
        name: 'keywords',
        content:
          'Rhino JS Online Editor - Rhino JS Online IDE -  Rhino JS Coding Online - Share Save Rhino JS Program online'
      }
    ],
    title: 'Rhino JS Online Editor - Rhino JS Online IDE -  Rhino JS Coding Online',
    header: 'Rhino JS Code',
    fileName: 'js',
    canonicalPath: 'execute-rhino-online',
    language: 'rhino',
    versions: ['1.7.7.1', '1.7.7.2', '1.7.13'],
    langDisplayName: 'Rhino JS',
    isCompile: false,
    sampleScript: `var x = 10;
var y = 25;
var z = x + y;

print('Sum of ',x,' and ',y,' is ',z);`
  },
  bc: {
    metaTags: [
      {
        name: 'description',
        content:
          'BC Online Editor - BC Online IDE -  BC Coding Online - Share Save BC Program online'
      },
      {
        name: 'keywords',
        content:
          'BC Online Editor - BC Online IDE -  BC Coding Online - Share Save BC Program online'
      }
    ],
    title: 'BC Online Editor - BC Online IDE -  BC Coding Online',
    header: 'BC Code',
    fileName: 'bc',
    canonicalPath: 'execute-bc-online',
    language: 'bc',
    versions: ['1.06.95', '1.07.1'],
    langDisplayName: 'BC',
    isCompile: false,
    sampleScript: `x = 10;
y = 25;
z = x + y;

print "Sum of ",x," and ",y," is ",z;
quit;`
  },
  clisp: {
    metaTags: [
      {
        name: 'description',
        content:
          'CLISP Online Editor - CLISP Online IDE -  CLISP Coding Online - Share Save CLISP Program online'
      },
      {
        name: 'keywords',
        content:
          'CLISP Online Editor - CLISP Online IDE -  CLISP Coding Online - Share Save CLISP Program online'
      }
    ],
    title: 'CLISP Online Editor - CLISP Online IDE -  CLISP Coding Online',
    header: 'CLISP Code',
    fileName: 'lisp',
    canonicalPath: 'execute-clisp-online',
    language: 'clisp',
    defaultVersion: 3,
    versions: [
      'GNU CLISP 2.49 - GNU C 5.2.0 ',
      'GNU CLISP 2.49 - GNU C 6.2.1',
      'GNU CLISP 2.49.93 - GNU 8.1.0',
      'GNU CLISP 2.49.93 - GNU 9.1.0',
      'ecl 21.2.1',
      'sbcl 2.1.9',
      'ccl 1.12.1',
      'abcl 1.8.0'
    ],
    langDisplayName: 'CLISP',
    aceCode: 'lisp',
    isCompile: false,
    sampleScript: `(print  "welcome to CLISP online ide from JDoodle.com")`
  },

  elixir: {
    metaTags: [
      {
        name: 'description',
        content:
          'Elixir Online Editor - Elixir Online IDE -  Elixir Coding Online - Share Save Elixir Program online'
      },
      {
        name: 'keywords',
        content:
          'Elixir Online Editor - Elixir Online IDE -  Elixir Coding Online - Share Save Elixir Program online'
      }
    ],
    title: 'Elixir Online Editor - Elixir Online IDE -  Elixir Coding Online',
    header: 'Elixir Code',
    fileName: 'exs',
    canonicalPath: 'execute-elixir-online',
    language: 'elixir',
    versions: ['1.3.4', '1.5.2', '1.6.4', '1.9.1', '1.12.2'],
    langDisplayName: 'Elixir',
    isCompile: false,
    sampleScript: `x = 10
y = 25
z = x + y

IO.puts "Sum of #{x} and #{y} is #{z}"`
  },

  factor: {
    metaTags: [
      {
        name: 'description',
        content:
          'Factor Online Editor - Factor Online IDE -  Factor Coding Online - Share Save Factor Program online'
      },
      {
        name: 'keywords',
        content:
          'Factor Online Editor - Factor Online IDE -  Factor Coding Online - Share Save Factor Program online'
      }
    ],
    title: 'Factor Online Editor - Factor Online IDE -  Factor Coding Online',
    header: 'Factor Code',
    fileName: 'factor',
    canonicalPath: 'execute-factor-online',
    language: 'factor',
    versions: ['8.25', '8.28', '8.29', '8.31'],
    langDisplayName: 'Factor',
    isCompile: false,
    sampleScript: `USE: io

"Hello, Welcome to Factor online ide from JDoodle.com" print`
  },

  falcon: {
    metaTags: [
      {
        name: 'description',
        content:
          'Falcon Online Editor - Falcon Online IDE -  Falcon Coding Online - Share Save Falcon Program online'
      },
      {
        name: 'keywords',
        content:
          'Falcon Online Editor - Falcon Online IDE -  Falcon Coding Online - Share Save Falcon Program online'
      }
    ],
    title: 'Falcon Online Editor - Falcon Online IDE -  Falcon Coding Online',
    header: 'Falcon Code',
    fileName: 'fl',
    canonicalPath: 'execute-falcon-online',
    language: 'falcon',
    versions: ['0.9.6.8 (Chimera)'],
    langDisplayName: 'Falcon',
    isCompile: false,
    sampleScript: `> x = 10
> y = 25
> z = x + y
> "Sum of ",x ," and ", y," is ", z`
  },

  fantom: {
    metaTags: [
      {
        name: 'description',
        content:
          'Fantom Online Editor - Fantom Online IDE -  Fantom Coding Online - Share Save Fantom Program online'
      },
      {
        name: 'keywords',
        content:
          'Fantom Online Editor - Fantom Online IDE -  Fantom Coding Online - Share Save Fantom Program online'
      }
    ],
    title: 'Fantom Online Editor - Fantom Online IDE -  Fantom Coding Online',
    header: 'Fantom Code',
    fileName: 'fan',
    canonicalPath: 'execute-fantom-online',
    language: 'fantom',
    versions: ['1.0.69'],
    langDisplayName: 'Fantom',
    isCompile: false,
    sampleScript: `class HelloWorld
{
    static Void main()
    {
        x := 10
        y := 25
        z := x + y
        echo("Sum of " + x + " and " + y + " is " + z)
    }
}`
  },

  nim: {
    metaTags: [
      {
        name: 'description',
        content:
          'Nim Online Editor - Nim Online IDE -  Nim Coding Online - Share Save Nim Program online'
      },
      {
        name: 'keywords',
        content:
          'Nim Online Editor - Nim Online IDE -  Nim Coding Online - Share Save Nim Program online'
      }
    ],
    title: 'Nim Online Editor - Nim Online IDE -  Nim Coding Online',
    header: 'Nim Code',
    fileName: 'nim',
    canonicalPath: 'execute-nim-online',
    language: 'nim',
    versions: ['0.15.0', '0.17.2', '0.18.0', '1.4.8'],
    langDisplayName: 'Nim',
    isCompile: false,
    sampleScript: `var x,y,z :int
x = 10
y = 25
z = x + y
echo ("sum of ", x, " and ", y, " is ", z)
`
  },

  pike: {
    metaTags: [
      {
        name: 'description',
        content:
          'Pike Online Editor - Pike Online IDE -  Pike Coding Online - Share Save Pike Program online'
      },
      {
        name: 'keywords',
        content:
          'Pike Online Editor - Pike Online IDE -  Pike Coding Online - Share Save Pike Program online'
      }
    ],
    title: 'Pike Online Editor - Pike Online IDE -  Pike Coding Online',
    header: 'Pike Code',
    fileName: 'pike',
    canonicalPath: 'execute-pike-online',
    language: 'pike',
    versions: ['v8.0', 'v8.0.702'],
    langDisplayName: 'Pike',
    isCompile: false,
    sampleScript: `int main()
{
    int x = 10;
    int y = 25;
    int z = x + y;
    write("Sum of " + x +" and " + y + " is " + z);
    return 0;
}`
  },
  smalltalk: {
    metaTags: [
      {
        name: 'description',
        content:
          'SmallTalk Online Editor - SmallTalk Online IDE -  SmallTalk Coding Online - Share Save SmallTalk Program online'
      },
      {
        name: 'keywords',
        content:
          'SmallTalk Online Editor - SmallTalk Online IDE -  SmallTalk Coding Online - Share Save SmallTalk Program online'
      }
    ],
    title: 'SmallTalk Online Editor - SmallTalk Online IDE -  SmallTalk Coding Online',
    header: 'SmallTalk Code',
    fileName: 'gst',
    canonicalPath: 'execute-smalltalk-online',
    language: 'smalltalk',
    versions: ['GNU SmallTalk 3.2.92'],
    langDisplayName: 'SmallTalk',
    isCompile: false,
    sampleScript: "Transcript show: 'Welcome to SmallTalk online ide from JDoodle.com'."
  },

  mozart: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online OZ-Mozart Compiler - Online OZ-Mozart Editor - Online OZ-Mozart IDE -  OZ-Mozart Coding Online - Online OZ-Mozart Runner - Share Save OZ-Mozart Program online'
      },
      {
        name: 'keywords',
        content:
          'Online OZ-Mozart Compiler - Online OZ-Mozart Editor - Online OZ-Mozart IDE - OZ-Mozart Coding Online - Online OZ-Mozart Runner -  Share Save OZ-Mozart online'
      }
    ],
    title:
      'Online OZ-Mozart Compiler - Online OZ-Mozart Editor -  Run OZ-Mozart Online - Online OZ-Mozart Runner',
    header: 'OZ-Mozart Code',
    fileName: 'oza',
    canonicalPath: 'compile-oz-mozart-online',
    language: 'mozart',
    versions: ['2.0.0 (OZ 3)'],
    langDisplayName: 'OZ Mozart',
    isCompile: true,
    sampleScript: `functor
import
    System
    Application
define
    {System.showInfo 'Welcome to JDoodle!'}
    {Application.exit 0}
end`
  },

  lolcode: {
    metaTags: [
      {
        name: 'description',
        content:
          'LOLCODE Online Editor - LOLCODE Online IDE -  LOLCODE Coding Online - Share Save LOLCODE Program online'
      },
      {
        name: 'keywords',
        content:
          'LOLCODE Online Editor - LOLCODE Online IDE -  LOLCODE Coding Online - Share Save LOLCODE Program online'
      }
    ],
    title: 'LOLCODE Online Editor - LOLCODE Online IDE -  LOLCODE Coding Online',
    header: 'LOLCODE Code',
    fileName: 'lo',
    canonicalPath: 'execute-lolcode-online',
    language: 'lolcode',
    versions: ['0.10.5'],
    langDisplayName: 'LOLCODE',
    isCompile: false,
    sampleScript: `HAI 1.2
VISIBLE "Welcome to JDoodle!!"
KTHXBYE`
  },

  racket: {
    metaTags: [
      {
        name: 'description',
        content:
          'Racket Online Editor - Racket Online IDE -  Racket Coding Online - Share Save Racket Program online'
      },
      {
        name: 'keywords',
        content:
          'Racket Online Editor - Racket Online IDE -  Racket Coding Online - Share Save Racket Program online'
      }
    ],
    title: 'Racket Online Editor - Racket Online IDE -  Racket Coding Online',
    header: 'Racket Code',
    fileName: 'rkt',
    canonicalPath: 'execute-racket-online',
    language: 'racket',
    versions: ['6.11', '6.12', '7.4', '8.3'],
    langDisplayName: 'Racket',
    isCompile: false,
    sampleScript: `(writeln "Welcome to JDoodle!!")`
  },

  kotlin: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Kotlin Compiler - Online Kotlin Editor - Online Kotlin IDE -  Kotlin Coding Online - Online Kotlin Runner - Share Save Kotlin Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Kotlin Compiler - Online Kotlin Editor - Online Kotlin IDE - Kotlin Coding Online - Online Kotlin Runner -  Share Save Kotlin online'
      }
    ],
    title:
      'Online Kotlin Compiler - Online Kotlin Editor -  Run Kotlin Online - Online Kotlin Runner',
    header: 'Kotlin Code',
    fileName: 'kt',
    canonicalPath: 'compile-kotlin-online',
    language: 'kotlin',
    versions: [
      '1.1.51 (JRE 9.0.1+11)',
      '1.2.40 (JRE 10.0.1)',
      '1.3.50 (JRE 11.0.4)',
      '1.6.0 (JRE 17.0.1+12)'
    ],
    langDisplayName: 'Kotlin',
    isCompile: true,
    sampleScript: `fun main(args: Array<String>) {
    val x: Int = 10
    val y: Int = 25

    val z = x + y

    println("The sum of $x + $y is  $z")
}`
  },

  whitespace: {
    metaTags: [
      {
        name: 'description',
        content:
          'Whitespace Online Editor - Whitespace Online IDE -  Whitespace Coding Online - Share Save Whitespace Program online'
      },
      {
        name: 'keywords',
        content:
          'Whitespace Online Editor - Whitespace Online IDE -  Whitespace Coding Online - Share Save Whitespace Program online'
      }
    ],
    title: 'Whitespace Online Editor - Whitespace Online IDE -  Whitespace Coding Online',
    header: 'Whitespace Code',
    fileName: 'ws',
    canonicalPath: 'execute-whitespace-online',
    language: 'whitespace',
    versions: ['0.3'],
    langDisplayName: 'Whitespace',
    isCompile: false,
    sampleScript:
      // eslint-disable-next-line no-tabs
      `Welcome to JDoodle.com   \n   	  	   \n		    	\n   		  	 	\n		    	 \n   		 		  \n		    		\n   		 		  \n		    	  \n   		 				\n		    	 	\n   	 		  \n		    		 \n   	     \n		    			\n   			 			\n		    	   \n   		 				\n		    	  	\n   			  	 \n		    	 	 \n   		 		  \n		    	 		\n   		  	  \n		    		  \n   	     \n		    		 	\n   		 				\n		    			 \n   		  		 \n		    				\n   	     \n		    	    \n   			  		\n		    	   	\n   			    \n		    	  	 \n   		    	\n		    	  		\n   		   		\n		    	 	  \n   		  	 	\n		    	 	 	\n   			  		\n		    	 		 \n   	    	\n		    	 			\n    \n		     \n\n 	 			 			 			  	  		 	  	 			 	   		  	 	\n\n 	 		 			  		  	 	 			 			 		 		   		 	  	 		 			  		  	 	\n\n\n\n\n   		    	 		  	   		  	  \n	   \n	\n\n   			 			 			  	  		 	  	 			 	   		  	 	\n \n 			 \n \n	  			 			 			  	  		 	  	 			 	   		  	 	 	 					 		  	 	 		 			  		  	  \n	\n     	\n	   \n \n 			 			 			  	  		 	  	 			 	   		  	 	\n\n   			 			 			  	  		 	  	 			 	   		  	 	 	 					 		  	 	 		 			  		  	  \n \n\n \n\n\n	\n\n   			  	  		  	 	 		    	 		  	  \n \n  \n 	\n	 			 \n    	 	 \n	  	\n	  			  	  		  	 	 		    	 		  	   	 					 		  	 	 		 			  		  	  \n \n\n   	\n	   \n \n 			  	  		  	 	 		    	 		  	  \n\n   			  	  		  	 	 		    	 		  	   	 					 		  	 	 		 			  		  	  \n \n\n   	\n	       \n		 \n	\n\n   		 			  		  	 	 			 			 		 		   		 	  	 		 			  		  	 	\n   	 	 \n   		 	\n	\n  	\n  \n	\n`
  },
  erlang: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Erlang Compiler - Online Erlang Editor - Online Erlang IDE -  Erlang Coding Online - Online Erlang Runner - Share Save Erlang Program online'
      },
      {
        name: 'keywords',
        content:
          'Online J Language Compiler - Online Erlang Editor - Online Erlang IDE - Erlang Coding Online - Online Erlang Runner -  Share Save Erlang online'
      }
    ],
    title:
      'Online Erlang Compiler - Online Erlang Editor -  Run Erlang Online - Online Erlang Runner',
    header: 'Erlang Code',
    fileName: 'erl',
    canonicalPath: 'execute-erlang-online',
    language: 'erlang',
    versions: ['22.1', '24'],
    langDisplayName: 'Erlang',
    isCompile: false,
    sampleScript: `io:format("Welcome to JDoodle!~n").`
  },
  jlang: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online J Language Compiler - Online J Language Editor - Online J Language IDE -  J Language Coding Online - Online J Language Runner - Share Save J Language Program online'
      },
      {
        name: 'keywords',
        content:
          'Online J Language Compiler - Online J Language Editor - Online J Language IDE - J Language Coding Online - Online J Language Runner -  Share Save J Language online'
      }
    ],
    title:
      'Online J Language Compiler - Online J Language Editor -  Run J Language Online - Online J Language Runner',
    header: 'J Language Code',
    fileName: 'jl',
    canonicalPath: 'execute-jlanguage-online',
    language: 'jlang',
    versions: ['9.01.10'],
    langDisplayName: 'J Language',
    isCompile: false,
    sampleScript: `echo 'Welcome to JDoodle.com!'
exit ''`
  },
  jbang: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online JBang Editor - Online JBang IDE -  JBang Coding Online - Online JBang Runner - Share Save JBang Program online'
      },
      {
        name: 'keywords',
        content:
          'Online JBang Compiler - Online JBang Editor - Online JBang IDE - JBang Coding Online - Online JBang Runner -  Share Save JBang online'
      }
    ],
    title: 'Online JBang Compiler - Online JBang Editor -  Run JBang Online - Online JBang Runner',
    header: 'JBang Code',
    fileName: 'java',
    canonicalPath: 'try-jbang',
    language: 'jbang',
    versions: ['0.83.1'],
    langDisplayName: 'JBang',
    isCompile: false,
    aceCode: 'java',
    sampleScript: `///usr/bin/env jbang "$0" "$@" ; exit $?
//DEPS com.github.lalyos:jfiglet:0.0.8

import com.github.lalyos.jfiglet.FigletFont;

class hello {

    public static void main(String... args) throws Exception {
        System.out.println(FigletFont.convertOneLine(
               "Hello " + ((args.length>0)?args[0]:"jbang")));  ;;
    }
}`
  },
  fasm: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online FASM Assembly Compiler - Online FASM Assembly Editor - Online FASM Assembly IDE -  FASM Assembly Coding Online - Online FASM Assembly Runner - Share Save FASM Assembly Program online'
      },
      {
        name: 'keywords',
        content:
          'Online FASM Assembly Language Compiler - Online FASM Assembly Editor - Online FASM Assembly IDE - FASM Assembly Coding Online - Online FASM Assembly Runner -  Share Save FASM Assembly online'
      }
    ],
    title:
      'Online FASM Assembly Compiler - Online FASM Assembly Editor -  Run FASM Assembly Online - Online FASM Assembly Runner',
    header: 'FASM Code',
    fileName: 'asm',
    canonicalPath: 'execute-fasm-online',
    language: 'fasm',
    versions: ['1.73.27'],
    langDisplayName: 'FASM',
    aceCode: 'assembly_x86',
    isCompile: true,
    sampleScript: `format ELF64 executable 3

segment readable executable

entry main

main:
    lea  rdi, [msg]                               ; address of msg goes into rdi
    mov  rax, 14                                  ; put length of msg into rax
    mov  rdx, rax                                 ; move rax to rdx
    mov  rsi, rdi                                 ; move rdi to rsi
    mov  rdi, 1                                   ; stdout
    mov  rax, 1                                   ; sys_write
    syscall
    xor  rdi, rdi                                 ; exit code 0
    mov  rax, 60                                  ; sys_exit
    syscall

segment readable writable

msg  db 'Hello world!', 10, 0
`
  },
  awk: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online AWK Compiler - Online AWK Editor - Online AWK IDE -  AWK Coding Online - Online AWK Runner - Share Save AWK Program online'
      },
      {
        name: 'keywords',
        content:
          'Online AWK Language Compiler - Online AWK Editor - Online AWK IDE - AWK Coding Online - Online AWK Runner -  Share Save AWK online'
      }
    ],
    title: 'Online AWK Compiler - Online AWK Editor -  Run AWK Online - Online AWK Runner',
    header: 'AWK Code',
    fileName: 'awk',
    canonicalPath: 'execute-awk-online',
    language: 'awk',
    versions: ['GNU Awk 5.1.1, API: 3.1'],
    langDisplayName: 'AWK',
    aceCode: 'empty',
    isCompile: false,
    sampleScript: `BEGIN {
   print "Welcome to JDoodle!"
}`
  },
  algol: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Algol 68 Genie Compiler - Online Algol 68 Genie Editor - Online Algol 68 Genie IDE -  Algol 68 Genie Coding Online - Online Algol 68 Genie Runner - Share Save Algol 68 Genie Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Algol 68 Language Compiler - Online Algol 68 Genie Editor - Online Algol 68 Genie IDE - Algol 68 Genie Coding Online - Online Algol 68 Genie Runner -  Share Save Algol 68 Genie online'
      }
    ],
    title:
      'Online Algol 68 Genie Compiler - Online Algol 68 Genie Editor -  Run Algol 68 Genie Online - Online Algol 68 Genie Runner',
    header: 'Algol 68 Code',
    fileName: 'alg',
    canonicalPath: 'execute-algol68-online',
    language: 'algol',
    aceCode: 'empty',
    versions: ['Genie 2.8.5'],
    langDisplayName: 'Algol 68',
    isCompile: false,
    sampleScript: `print("Welcome to JDoodle!")`
  },
  befunge: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Befunge Compiler - Online Befunge Editor - Online Befunge IDE -  Befunge Coding Online - Online Befunge Runner - Share Save Befunge Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Befunge Language Compiler - Online Befunge Editor - Online Befunge IDE - Befunge Coding Online - Online Befunge Runner -  Share Save Befunge online'
      }
    ],
    title:
      'Online Befunge Compiler - Online Befunge Editor -  Run Befunge Online - Online Befunge Runner',
    header: 'Befunge Code',
    fileName: 'be',
    canonicalPath: 'execute-befunge-online',
    language: 'befunge',
    aceCode: 'empty',
    versions: ['cfunge 0.9.0'],
    langDisplayName: 'Befunge',
    isCompile: false,
    sampleScript: `0"!eldooDJ ot emocleW">:#,_@`
  },
  haxe: {
    metaTags: [
      {
        name: 'description',
        content:
          'Online Haxe Compiler - Online Haxe Editor - Online Haxe IDE -  Haxe Coding Online - Online Haxe Runner - Share Save Haxe Program online'
      },
      {
        name: 'keywords',
        content:
          'Online Haxe Compiler - Online Haxe Editor - Online Haxe IDE - Haxe Coding Online - Online Haxe Runner -  Share Save Haxe online'
      }
    ],
    title: 'Online Haxe Compiler - Online Haxe Editor -  Run Haxe Online - Online Haxe Runner',
    header: 'Haxe Code',
    fileName: 'hx',
    canonicalPath: 'execute-haxe-online',
    language: 'haxe',
    versions: ['4.2.4'],
    langDisplayName: 'Haxe',
    isCompile: true,
    sampleScript: `class Main {
  static public function main():Void {
    trace("Welcome to JDoodle!");
  }
}`
  },
  blockly: {
    metaTags: [
      {
        name: 'description',
        content: 'Blockly Online'
      },
      {
        name: 'keywords',
        content: 'Blockly Online'
      }
    ],
    title: 'Blockly Online',
    header: 'Blockly',
    fileName: 'hx',
    canonicalPath: 'blockly-online',
    language: 'blockly',
    versions: ['Dart', 'Lua', 'PHP', 'Python'],
    blocklyAceCodes: ['dart', 'lua', 'PHP', 'python'],
    blocklyLanguages: ['dart', 'lua', 'php', 'python3'],
    blocklyLanguageVersions: [4, 3, 4, 4],
    langDisplayName: 'Blockly',
    isCompile: false,
    sampleScript: `{"blocks":{"languageVersion":0,"blocks":[{"type":"text_print","id":"%B3I#U(F2j{@ZQ;Kc5r^","x":16,"y":8,"inputs":{"TEXT":{"block":{"type":"text","id":"LM9uh/dp#q2d^z=Kfq{#","fields":{"TEXT":"Welcome to JDoodle"}}}}}]}}`,
    aceCode: 'python'
  },
  html: {
    metaTags: [
      {
        name: 'description',
        content: 'Run HTML CSS Javascript online - Save Share HTML CSS Javascript online'
      },
      {
        name: 'keywords',
        content:
          'Online HTML/CSS/Javascript Editor, test html css javascript online, online ide for html css javascript - save share html css java script online'
      }
    ],
    title: 'Online HTML CSS Javascript Editor',
    language: 'html',
    fileName: 'html',
    header: 'HTML/CSS/JS',
    canonicalPath: 'html-css-javascript-online-editor',
    langDisplayName: 'HTML/CSS/JS'
  },
  mysql: {
    metaTags: [
      {
        name: 'description',
        content: 'Online MySQL Terminal, practice MySQL online'
      },
      {
        name: 'keywords',
        content: 'Online MySQL Terminal - practice MySQL online'
      }
    ],
    title: 'Online MySQL Terminal - practice MySQL online',
    header: 'MySQL',
    terminal: 'mysql',
    versions: ['5.7'],
    langDisplayName: 'MySQL',
    canonicalPath: 'online-mysql-terminal',
    sampleScript: ''
  },
  mongo: {
    metaTags: [
      {
        name: 'description',
        content: 'Online mongoDB Terminal, practice mongoDB online'
      },
      {
        name: 'keywords',
        content: 'Online mongoDB Terminal - practice mongoDB online'
      }
    ],
    title: 'Online mongoDB Terminal - practice mongoDB online',
    header: 'mongoDB',
    terminal: 'mongo',
    versions: ['3.2.4'],
    langDisplayName: 'mongoDB',
    canonicalPath: 'online-mongodb-terminal',
    sampleScript: ''
  }
}
