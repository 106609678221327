<script setup lang="ts">
import CaseStudyDemoCTA, {
  type IDemoCTATextData
} from '@/components/shared/casestudy/CaseStudyDemoCTA.vue'

const sectionData: IDemoCTATextData = {
  textHeading: 'Empower your students to learn and execute code in your platform',
  textSubheading: 'Create better learning experiences and improve learning outcomes.',
  getStartedLink: '/pricing/api',
  learnMoreLink: '/intergrate-online-ide-compiler-api-plugins'
}
</script>

<template>
  <CaseStudyDemoCTA :sectionData="sectionData" />
</template>
