<script setup lang="ts">
import { type PropType } from 'vue'
import { type IAssignmentOfStudent } from '@/components/guru/interface/IAssignmentOfStudent'
import { getAssignmentDeadlineDate, getAssignmentDeadlineTime } from '@/utils/format'
import { TEACH_STUDENT_ASSIGNMENT_STATUS } from '@/components/guru/enums/teach.enum'
import type { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const props = defineProps({
  assignment: {
    type: Object as PropType<IAssignmentOfStudent>,
    required: true
  },
  showBtnAndDesc: {
    type: Boolean,
    required: false,
    default: false
  }
})
/**
 * Get action btn depending on submission status
 * @param assignment The current assignment
 * @returns string text
 */
function getActionText(assignment: IAssignmentOfStudent) {
  if (assignment.submissionStatus == TEACH_STUDENT_ASSIGNMENT_STATUS.YET_TO_START) {
    return 'Start Now'
  } else if (assignment.submissionStatus == TEACH_STUDENT_ASSIGNMENT_STATUS.IN_PROGRESS) {
    return 'Continue'
  } else {
    return 'View'
  }
}
</script>

<template>
  <div class="section-primary flex justify-between rounded-xl px-5 py-3">
    <div class="flex w-[65%] flex-col">
      <div class="text-primary flex items-center text-sm">
        <span class="break-normal">{{ props.assignment.title }}</span>
        <span
          class="ms-2 rounded-full px-2 text-xs"
          :class="props.assignment.isPublicStudent ? 'bg-purple-600' : 'bg-blue-700'"
          >{{ props.assignment.isPublicStudent ? 'Public' : 'Private' }}</span
        >
      </div>
      <p
        class="text-secondary mb-1 text-xs"
        v-if="props.showBtnAndDesc && props.assignment.description"
      >
        {{ props.assignment.description.slice(0, 20) }}
        <span v-if="props.assignment.description.length > 20">...</span>
      </p>
      <p class="text-secondary mb-2 text-xs">{{ props.assignment.insCode }}</p>
      <p class="text-secondary text-xs">{{ props.assignment.submissionStatus }}</p>
      <router-link
        v-if="props.showBtnAndDesc"
        :to="`/dashboard/institution/${props.assignment.insCode}/assignment/${props.assignment.insAssignmentId}`"
        class="btn-secondary w-[100px] w-fit rounded-md px-2 py-[6px] text-center text-xs"
        >{{ getActionText(props.assignment) }} <FontAwesomeIcon icon="fa-arrow-right"
      /></router-link>
    </div>
    <div class="text-secondary flex flex-col justify-center text-center text-xs">
      <p class="mb-1">Deadline:</p>
      <p class="">
        {{ getAssignmentDeadlineDate(props.assignment.endTime) }}
      </p>
      <p class="">
        {{ getAssignmentDeadlineTime(props.assignment.endTime) }}
      </p>
    </div>
  </div>
</template>
