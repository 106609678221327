import type { ILanguage } from '@/utils/sharedData/languages'

/**
 *
 */
export class SelectedLanguageObject {
  displayName: string = ''
  name: string = ''
  versions: string[] | undefined = []

  /**
   * assignment default items
   * @param languageItem selected Language Item
   */
  constructor(languageItem: ILanguage) {
    this.displayName = languageItem.displayName
    this.name = languageItem.language!
    this.versions = languageItem.versions
  }
}
