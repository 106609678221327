<script setup lang="ts">
import BaseInput from '@/components/forms/BaseInput.vue'
// @ts-ignore
import ModelLayout from '@/layouts/ModelLayout.vue'
import authService, { type IForgotPasswordRequest } from '@/services/auth.service'
import { AUTHMODELHS } from '@/utils/models'
import { until, useColorMode } from '@vueuse/core'
import { useField, useForm } from 'vee-validate'
import { ref, computed, onMounted, watch, onBeforeUnmount } from 'vue'
import { VueRecaptcha } from 'vue-recaptcha'
import { string } from 'yup'
import { RECAPTCHA_KEYS } from '@/utils/recaptcha'

const colorTheme = useColorMode()

const sitekey = RECAPTCHA_KEYS.RECAPTCHALOGINSITEKEY
const rechaptha = ref<any>(null)
const recapthaToken = ref<string>('')
const recapthaError = ref<string>('')
const showRecaptcha = ref<boolean>(false)
const isLoading = ref<boolean>(false)
const httpErrorTimeOut = ref<number | null>(null)
const httpError = ref<string>('')
const httpSuccessTimeOut = ref<number | null>(null)
const httpSuccess = ref<string>('')

const { errors, handleSubmit, resetForm } = useForm()

const { value: email, errorMessage: emailError } = useField('email', string().required().email())

/**
 * Handles the response from the reCAPTCHA verification and sets the token.
 * @param response - The reCAPTCHA response token.
 */
const recaptchaVerified = (response: string) => {
  recapthaToken.value = response
  recapthaError.value = ''
}

/**
 * Handles the reCAPTCHA error and sets the error message.
 * @param error - The reCAPTCHA error message.
 */
const recaptchaError = (error: string) => {
  recapthaToken.value = ''
  recapthaError.value = error
  rechaptha.value.reset()
}

const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0
})

const onSubmit = handleSubmit(async (values) => {
  isLoading.value = true
  rechaptha.value.execute()

  await until(recapthaToken).changed()

  if (!recapthaToken.value || recapthaError.value) {
    return
  }

  const requestPayload: IForgotPasswordRequest = {
    email: values.email,
    gc: recapthaToken.value
  }

  await authService
    .forgotPassword(requestPayload)
    .then((response: any) => {
      httpSuccess.value =
        response?.data?.message ||
        'Please check your mail for Password reset Link (JDoodle sents mail only if the entered mail is already registered)'
      httpError.value = ''
      resetForm()
      // close the modal
      // utilModelsService.closeModal(AUTHMODELHS.FORGOTPASSWORD)
    })
    .catch((error: any) => {
      httpSuccess.value = ''
      httpError.value = error?.response?.data?.message
    })
    .finally(() => {
      recapthaToken.value = ''
      rechaptha.value.reset()
      isLoading.value = false
    })
})
onMounted(async () => {
  window.addEventListener('open.hs.overlay', async ($overlayEl) => {
    if (
      ($overlayEl.target as HTMLElement)?.id === AUTHMODELHS.FORGOTPASSWORD &&
      !showRecaptcha.value
    ) {
      showRecaptcha.value = true
    }
  })
  watch(httpError, () => {
    if (httpError.value) {
      if (httpErrorTimeOut.value) clearTimeout(httpErrorTimeOut.value)
      httpErrorTimeOut.value = setTimeout(() => {
        httpError.value = ''
      }, 8000)
    }
  })
  watch(httpSuccess, () => {
    if (httpSuccess.value) {
      if (httpSuccessTimeOut.value) clearTimeout(httpSuccessTimeOut.value)
      httpSuccessTimeOut.value = setTimeout(() => {
        httpSuccess.value = ''
      }, 8000)
    }
  })
})
onBeforeUnmount(() => {
  window.removeEventListener('open.hs.overlay', () => {})
})
</script>

<template>
  <ModelLayout
    title="Forgot password"
    description="Join us and get access all features"
    :hs="AUTHMODELHS.FORGOTPASSWORD"
  >
    <!-- form -->
    <form @submit.prevent="onSubmit" class="pt-12">
      <BaseInput
        label="Email address"
        :inputType="'email'"
        placeholder="joe@example.com"
        autocomplete="email"
        :error="emailError"
        v-model="email"
        class="mb-2"
      ></BaseInput>
      <vue-recaptcha
        v-if="showRecaptcha"
        size="invisible"
        ref="rechaptha"
        :theme="colorTheme === 'dark' ? 'dark' : 'light'"
        :sitekey="sitekey || ''"
        @verify="recaptchaVerified"
        @fail="recaptchaError('Recaptcha failed. Please verify again.')"
        @error="recaptchaError"
      />
      <p :class="['p-xsmall ', 'error', { hidden: !recapthaError }]">
        {{ recapthaError }}
      </p>
      <p :class="['p-xsmall ', '']" v-if="isLoading">Loading...</p>
      <p :class="['p-xsmall', 'error', { hidden: !httpError }]">
        {{ httpError }}
      </p>
      <p :class="['p-xsmall', 'success', { hidden: !httpSuccess }]">
        {{ httpSuccess }}
      </p>

      <button
        :disabled="!canSubmit"
        class="btn-primary btn-rounded-md mt-10 text-base"
        type="submit"
      >
        Send Password Reset Link
        <FontAwesomeIcon icon="fa-arrow-right ml-1" class="w-5" />
      </button>
    </form>

    <div class="mt-1 text-center text-sm text-neutral-600 dark:text-neutral-300">
      Remember your password?
      <button type="button" class="text-text-brand" :data-hs-overlay="`#${AUTHMODELHS.LOGIN}`">
        Log In
      </button>
    </div>
  </ModelLayout>
</template>
