<script setup lang="ts">
import DashboardCard from '@/components/shared/dashboard/DashboardCard.vue'
import { ref, onMounted } from 'vue'
import { showUpdateCreditCard } from '@/services/payment.service'
import {
  initCompilerApi,
  getApiPaymentMethod,
  updateApiPaymentMethod
} from '@/services/subscription.service'
import type ILink from '@/components/shared/interface/ILink'
import { useBreadcrumbStore } from '@/stores/breadcrumb.store'

const breadcrumbStore = useBreadcrumbStore()
const completed = ref(false)
const errorMessage = ref<string>('')

const endsWith = ref<string>('')
const cardType = ref<string>('')
const expires = ref<string>('')
const paymentMethodFound = ref(false)
const showUpdateCreditCardSection = ref(false)

const stripCard = ref<any>(null)
const stripe = ref<any>(null)

const stripKey = ref('')

const breadcrumbLinks: ILink[] = [
  {
    href: '/',
    title: 'Home'
  },
  {
    href: '/dashboard',
    title: 'Dashboard'
  },
  {
    href: '/dashboard/payment/cards',
    title: 'Cards'
  }
]

onMounted(() => {
  breadcrumbStore.setBreadcrumbLinks(breadcrumbLinks)
  initCompilerApi().then((res) => {
    stripKey.value = res.stripeKey
  })

  getApiPaymentMethod()
    .then((res) => {
      paymentMethodFound.value = true
      expires.value = res.expires
      cardType.value = res.cardType
      endsWith.value = res.endsWith
      completed.value = true
    })
    .catch((err) => {
      if (err.response?.status === 402) {
        completed.value = true
        paymentMethodFound.value = false
      }
    })
})

/**
 *
 */
function showUpdateSection() {
  showUpdateCreditCardSection.value = true
  completed.value = false
  showUpdateCreditCard(stripKey.value, stripCard, stripe, completed.value, errorMessage)
}
/**
 *
 */
function closeUpdateForm() {
  showUpdateCreditCardSection.value = false
  completed.value = true
  errorMessage.value = ''
}

/**
 *
 */
async function updatePaymentMethod() {
  stripe.value.createToken(stripCard.value).then(async (result: any) => {
    if (result.error) {
      errorMessage.value = result.error.message
    } else {
      updateApiPaymentMethod(result.token.id)
        .then((data) => {
          expires.value = data.expires
          cardType.value = data.cardType
          endsWith.value = data.endsWith
          showUpdateCreditCardSection.value = false
          paymentMethodFound.value = true
          completed.value = true
        })
        .catch((err) => {
          errorMessage.value = err.data.message
        })
    }
  })
}
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="gap-4 lg:grid lg:grid-cols-2">
      <DashboardCard title="Cards" class="h-[340px]">
        <div
          v-if="completed && !paymentMethodFound"
          class="text-secondary flex h-48 flex-col items-center justify-center font-semibold"
        >
          No Credit Card Details Found.
        </div>

        <div v-if="completed && paymentMethodFound" class="mt-6">
          <ul class="text-primary leading-10">
            <li class="w-full">
              <span class="text-secondary inline-block w-2/5">Card Type</span>
              {{ cardType }}
            </li>

            <li>
              <span class="text-secondary inline-block w-2/5">Card Number</span>
              {{ endsWith }}
            </li>

            <li>
              <span class="text-secondary inline-block w-2/5">Card Expiry</span>
              {{ expires }}
            </li>

            <button class="btn btn-primary mt-10 text-base" @click="showUpdateSection">
              Update my card information
              <FontAwesomeIcon icon="fa-arrow-right" class="ml-2" />
            </button>
          </ul>
        </div>

        <div v-if="showUpdateCreditCardSection">
          <div v-show="!completed" class="pt-6">
            <div class="api-payment-box text-white">
              <div
                id="apiUpdatePaymentMethod-card-element"
                class="m-auto mb-2 h-12 max-w-[600px] rounded-md border border-gray-500 bg-gray-100 p-3 text-white dark:bg-gray-300"
              ></div>
              <img
                src="@/assets/images/integrate/powered_by_stripe.png"
                alt="Powered By Stripe"
                class="mx-auto mt-6 w-32"
              />
            </div>

            <div class="mt-6 text-center" v-show="errorMessage">
              <FontAwesomeIcon icon="fa-circle-exclamation" class="text-md mr-2 text-red-500" />
              <span class="whitespace-break-spaces text-sm font-medium">
                {{ errorMessage }}
              </span>
            </div>

            <div class="mt-6 flex flex-row justify-center">
              <button class="btn btn-primary w-32" @click="updatePaymentMethod">Update</button>
              <button class="btn btn-secondary ml-6 w-32" @click="closeUpdateForm">Close</button>
            </div>
          </div>
        </div>
      </DashboardCard>

      <DashboardCard>
        <div class="flex h-full flex-col items-center justify-center text-center text-sm">
          <p class="text-primary mb-10 max-w-sm">
            Are you looking for a custom solution for your business or institution? Easy! We we will
            be happy to create it for you.
          </p>

          <RouterLink class="btn-dashboard" :to="{ name: 'contactus' }"> Contact Us </RouterLink>
        </div>
      </DashboardCard>
    </div>
  </div>
</template>

<style scoped>
.ElementsApp,
.ElementsApp .InputElement {
  font-size: 16px;
  color: white !important;
}
</style>
