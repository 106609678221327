<script lang="ts" setup>
import { TEACHMODALHS } from '@/utils/models'
import {
  addOrRemoveUserFromAssignment,
  addOrRemoveUserFromStudentGroup
} from '@/services/teach.service'
import ModelLayout from '@/layouts/ModelLayout.vue'
import {
  GURU_USER_REQUEST,
  GURU_USER_TYPE,
  STUDENT_GROUP_ACTIONS,
  TEACH_SECTION
} from '@/components/guru/enums/teach.enum'
import { ref, type PropType } from 'vue'
import { HttpStatusCode } from 'axios'
import router from '@/router'
import type {
  IAssignment_Users_AddRemoveRequest,
  IStudentGroup_AddRemoveRequest
} from '@/components/guru/interface/requests.interface'

const props = defineProps({
  section: {
    type: String as PropType<TEACH_SECTION>,
    required: true,
    default: undefined
  },
  instituteCode: { type: String, required: true },
  insAssignmentId: {
    type: Number,
    required: true
  },
  email: { type: String, required: true },
  studentGroupId: {
    type: Number,
    default: undefined
  }
})

//static Data
const modalTitle = 'Remove Student'
/**
 * @returns message
 */
function getMessage() {
  const message = ref('')
  if (props.section == TEACH_SECTION.ASSIGNMENT) {
    return (message.value =
      'Are you sure, do you want to remove this student from this Assignment?')
  }

  if (props.section == TEACH_SECTION.STUDENT_GROUP) {
    return (message.value = 'Are you sure, do you want to remove this student from the group?')
  }
}

/**
 *
 */
const handleSubmit = async () => {
  if (props.section == TEACH_SECTION.ASSIGNMENT) {
    const requestObject: IAssignment_Users_AddRemoveRequest = {
      email: props.email,
      instituteCode: props.instituteCode!,
      insAssignmentId: props.insAssignmentId!,
      updateType: GURU_USER_REQUEST.REMOVE,
      participantType: GURU_USER_TYPE.STUDENT
    }

    const res = await addOrRemoveUserFromAssignment(requestObject)

    if (res == HttpStatusCode.Ok) {
      router.go(0)
    }
  }

  //For Student Group Students
  if (props.section == TEACH_SECTION.STUDENT_GROUP) {
    const reqObject: IStudentGroup_AddRemoveRequest = {
      email: props.email,
      instituteCode: props.instituteCode!,
      insStudentGroupId: props.studentGroupId,
      mode: STUDENT_GROUP_ACTIONS.VIEW,
      type: GURU_USER_TYPE.STUDENT,
      subType: STUDENT_GROUP_ACTIONS.REMOVE
    }

    const res = await addOrRemoveUserFromStudentGroup(reqObject)

    if (res == HttpStatusCode.Ok) {
      router.go(0)
    }
  }
}
</script>

<template>
  <ModelLayout
    :title="modalTitle"
    :hs="TEACHMODALHS.REMOVE_USER_STUDENT_CONFIRMATION"
    :loadDataWhenClose="false"
  >
    <p class="my-5 break-normal text-center text-sm">{{ getMessage() }}</p>

    <div class="mt-8 flex justify-center gap-5">
      <button class="btn-secondary rounded-md px-5 py-2" @click="handleSubmit">Yes</button>

      <button class="btn-primary rounded-md px-5 py-2" data-hs-overlay-close>No</button>
    </div>
  </ModelLayout>
</template>
