import axios from 'axios'

export interface IContactUsRequest {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  organisation: string
  country: string
  likeToTalk: string
  message?: string
}
/**
 * @param contactForm The contactus request object
 * @returns The response object containing the user details
 */
const sendContactForm = async (contactForm: IContactUsRequest) => {
  return await axios
    .post('/api/doodle/contactUs', contactForm)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export default { sendContactForm }
