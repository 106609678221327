import { defineStore } from 'pinia'
import { ref } from 'vue'

export enum LOADINGINDICATORTEXTTYPE {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
  CRITICAL = 'critical'
}
export interface IloadingIndicatorText {
  type: LOADINGINDICATORTEXTTYPE
  text?: string
}

export const useApiStore = defineStore('api', () => {
  const showLoadingIndicator = ref<boolean>(false)
  const flagToStopLoadingIndicator = ref<boolean>(false)
  const showLoadingIndicatorText = ref<IloadingIndicatorText>({
    type: LOADINGINDICATORTEXTTYPE.LOADING,
    text: ''
  })
  const showLoadingIndicatorTexts = ref<IloadingIndicatorText[]>([])
  const globalMsg = ref('')
  /**
   * Show the loading indicator
   */
  const haldleShowLoadingIndicators = async () => {
    if (showLoadingIndicator.value === true) {
      return
    }
    showLoadingIndicator.value = true
    while (showLoadingIndicatorTexts.value.length > 0) {
      showLoadingIndicatorText.value =
        showLoadingIndicatorTexts.value.shift() as IloadingIndicatorText

      if (showLoadingIndicatorText.value.type === LOADINGINDICATORTEXTTYPE.LOADING) {
        await new Promise((resolve) => setTimeout(resolve, 1000))
        while (showLoadingIndicatorTexts.value.length === 0) {
          if (flagToStopLoadingIndicator.value === true) {
            flagToStopLoadingIndicator.value = false
            break
          }
          await new Promise((resolve) => setTimeout(resolve, 100))
        }
      } else {
        await new Promise((resolve) => setTimeout(resolve, 2000))
      }

      if (
        showLoadingIndicatorTexts.value.length === 0 &&
        showLoadingIndicatorText.value.type !== LOADINGINDICATORTEXTTYPE.CRITICAL
      ) {
        showLoadingIndicator.value = false
      }
    }
  }

  /**
   * Show the loading indicator
   * @param loadingIndicatorText - The text to show in the loading indicator
   */
  const addToShowLoadingIndicator = (loadingIndicatorText: IloadingIndicatorText) => {
    showLoadingIndicatorTexts.value.push(loadingIndicatorText)
    haldleShowLoadingIndicators()
  }
  /**
   * Clear the loading indicator
   */
  const flagtoHideLoadingIndicatior = () => {
    flagToStopLoadingIndicator.value = true
  }

  /**
   *
   * @param val String
   */
  const setGlobalMsg = (val: string) => {
    globalMsg.value = val
  }

  return {
    showLoadingIndicator,
    showLoadingIndicatorText,
    addToShowLoadingIndicator,
    flagtoHideLoadingIndicatior,
    setGlobalMsg,
    globalMsg
  }
})
