<script lang="ts" setup>
import CaseStudyBanner, { type ICaseStudyBanner } from '@/components/shared/CaseStudyBanner.vue'
import itext from '@/assets/images/shared/reviews/businesses/itext.svg'

const caseStudyBannerData: ICaseStudyBanner = {
  orangeTextAboveTitle: 'Case Study',
  title:
    'iText Library showcased the power of its library conveniently with no setup time, trail forms or project setup.',
  primaryButton: {
    link: '/case-study/itext',
    text: 'Read More'
  },
  image: {
    src: itext,
    alt: 'IText'
  }
}
</script>
<template>
  <div>
    <CaseStudyBanner :sectionData="caseStudyBannerData" />
  </div>
</template>
