declare global {
  interface Window {
    __PRERENDER_INJECTED: boolean
  }
  interface ace {
    Editor: any
  }
}

import { FILE_MESSAGE_TYPE } from '@/utils/ide'
import { type IMeta } from '@/utils/meta'

import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

type TActiveItem = {
  parent?: string
  name?: string
  content?: string
  dirtyActions?: object[]
  codeChanged?: boolean
  isDirty?: boolean
}
type TFileMessage = {
  type: (typeof FILE_MESSAGE_TYPE)[keyof typeof FILE_MESSAGE_TYPE]
  message: string | null
}
export type TExecutionHistory = {
  script: string
  args: string | null
  stdin: string | null
  libs: string[]
  output: string
  executedAt: number
  versionIndex: number
}

export type THtmlExecutionHistory = {
  docType: string
  htmlHead: string
  htmlBody: string
  jsCode: string
  cssCode: string
  executedAt: number
}
export const useIdeStore = defineStore('ide', () => {
  const syncInProgress = ref<boolean>(false)
  const editingInProcess = ref<boolean>(false)
  const routeMeta = ref<IMeta | null>(null)
  const ideMeta = ref<IMeta | null>(null)
  const fontSize = ref<number>(12)
  const ideSplit = ref<any | null>(null)
  const codeEditor = ref<ace['Editor'] | null>(null)
  const codeUpdated = ref<boolean>(false)
  const outputEditor = ref<ace['Editor'] | null>(null)
  const project = ref<any | null>(null)
  const activeItem = ref<TActiveItem>({})
  const minimizeTree = ref<boolean>(false)
  const syncErrorMessages = ref<string | null>('')
  const projectKey = ref<string | boolean>(false)

  const interactiveMode = ref<boolean>(false)
  const versionIndex = ref<number>(-1)
  const args = ref<string | null>(null)
  const stdin = ref<string | null>(null)
  const javaLibraries = ref<string[]>([])
  const executionTime = ref<string | null>(null)
  const memory = ref<number | null>(null)
  const cpuTime = ref<number | null>(null)

  const autoSaveOn = ref<boolean>(false)

  const isCodeExecuting = ref<boolean>(false)
  const socketClient = ref<any | null>(null)
  const wsNextId = ref<number>(0)
  const socketConnected = ref<boolean>(false)

  const uploadInProgress = ref<boolean>(false)
  const maxUploads = ref<number>(5)
  const maxFileSize = ref<number>(5000000)
  const inputFiles = ref<string[]>([])
  const outputFiles = ref<string[]>([])
  const fileUploadMessages = ref<TFileMessage | null>(null)

  const projects = ref<any[]>([])
  const selectedProject = ref<any | null>(null)
  const projectEditor = ref<ace['Editor'] | null>(null)

  const storage = ref<any | null>(null)
  const isLocalStoreEnabled = ref<boolean>(false)
  const executionHistory = ref<TExecutionHistory[] | THtmlExecutionHistory[] | null>(null)
  const selectedExecutionHistory = ref<TExecutionHistory | THtmlExecutionHistory | null>(null)
  const executeCodeEditor = ref<ace['Editor'] | null>(null)
  const executeOutputEditor = ref<ace['Editor'] | null>(null)

  const copyEditor = ref<ace['Editor'] | null>(null)
  const isCopied = ref<boolean>(false)
  const downloadEditor = ref<ace['Editor'] | null>(null)

  const openEditor = ref<ace['Editor'] | null>(null)
  const importedFromFiles = ref<boolean>(false)

  const printBlocker = ref<boolean>(false)
  const fullScreen = ref<boolean>(false)

  const blocklyExpand = ref<boolean>(false)
  let blocklyWorkspace: any | null = null
  const blocklyEditorLanguages = ref<any[]>([])

  const shareNotFound = ref<boolean>(false)
  const shareNotFoundHttpError = ref<string | null>(null)
  const sharedId = ref<string | null>(null)

  const isEmbedded = ref<boolean>(false)
  const pymChild = ref<any | null>(null)

  const isGuru = ref<boolean>(false)

  const htmlExpand = ref<boolean>(false)
  const htmlDoctypeEditor = ref<ace['Editor'] | null>(null)
  const htmlHeadEditor = ref<ace['Editor'] | null>(null)
  const htmlBodyEditor = ref<ace['Editor'] | null>(null)
  const htmlJsEditor = ref<ace['Editor'] | null>(null)
  const htmlCssEditor = ref<ace['Editor'] | null>(null)

  const terminal = ref<any | null>(null)

  const openProjectID = ref<string | null>(null)

  const isWindowAce = computed(() => {
    return window['ace'] || null
  })
  const isAdvanced = computed(() => {
    return ideMeta.value?.isAdvanced || false
  })
  const isShared = computed(() => {
    return routeMeta.value?.isShared || false
  })
  const isInstantShare = computed(() => {
    return ideMeta.value?.isInstantShare || false
  })
  const isAceLanguageCode = computed(() => {
    if (ideMeta.value?.language === 'blockly') return 'python'
    if (ideMeta.value?.aceCode) return ideMeta.value.aceCode
    return ideMeta.value?.language
  })
  const isLanguage = computed(() => {
    return routeMeta.value?.language || ideMeta.value?.language || ''
  })
  const isLangDisplayName = computed(() => {
    return routeMeta.value?.langDisplayName || ideMeta.value?.langDisplayName || ''
  })
  const isProject = computed(() => {
    return project.value || null
  })
  const isProjectId = computed(() => {
    return project.value?.id || null
  })
  const isVersions = computed(() => {
    return ideMeta.value?.versions || []
  })
  const isVersionIndex = computed(() => {
    if (isVersions.value.length === 0) return 0
    return ideMeta.value?.defaultVersion
      ? ideMeta.value?.defaultVersion
      : isVersions.value.length - 1
  })
  const isDefaultVersion = computed(() => {
    return ideMeta.value?.defaultVersion || -1
  })
  const isAutoSaveOn = computed(() => {
    return autoSaveOn.value
  })
  const isWsNextId = computed(() => {
    return wsNextId.value
  })
  const isSocketConnected = computed(() => {
    return socketConnected.value
  })
  const isUploadInProgress = computed(() => {
    return uploadInProgress.value
  })
  const isMaxUploads = computed(() => {
    return maxUploads.value
  })
  const isMaxFileSize = computed(() => {
    return maxFileSize.value
  })
  const isInputFiles = computed(() => {
    return inputFiles.value
  })
  const isOutputFiles = computed(() => {
    return outputFiles.value
  })
  const isFileUploadMessages = computed(() => {
    return fileUploadMessages.value
  })
  const isProjects = computed(() => {
    return projects.value
  })
  const isSelectedProject = computed(() => {
    return selectedProject.value
  })
  const isStorage = computed(() => {
    return storage.value || null
  })
  const isExecutionHistory = computed(() => {
    return executionHistory.value
  })
  const isSelectedExecutionHistory = computed(() => {
    return selectedExecutionHistory.value
  })
  const isImportedFromFiles = computed(() => {
    return importedFromFiles.value
  })
  const isPrintBlocker = computed(() => {
    return printBlocker.value
  })
  const isFullScreen = computed(() => {
    return fullScreen.value
  })
  const isCodeUpdated = computed(() => {
    return codeUpdated.value
  })
  const isblocklyExpand = computed(() => {
    return blocklyExpand.value
  })
  const isBlocklyWorkspace = computed(() => {
    return blocklyWorkspace
  })
  const isBlocklyEditorLanguages = computed(() => {
    return blocklyEditorLanguages.value
  })
  const isBlockly = computed(() => {
    return (
      routeMeta.value?.language === 'blockly' ||
      ideMeta.value?.language === 'blockly' ||
      project.value?.language === 'blockly'
    )
  })
  const isBlocklyLanguages = computed(() => {
    return ideMeta.value?.blocklyLanguages || []
  })
  const isBlocklyLanguageVersions = computed(() => {
    return ideMeta.value?.blocklyLanguageVersions || []
  })
  const isShareId = computed(() => {
    return sharedId.value || null
  })
  const isHtml = computed(() => {
    return routeMeta.value?.language === 'html' || ideMeta.value?.language === 'html'
  })
  const isHtmlExpand = computed(() => {
    return htmlExpand.value
  })
  const isTerminal = computed(() => {
    return ideMeta.value?.terminal || ''
  })
  /**
   * Set editing in progress
   * @param value - The value to set
   */
  const setEditingInProgress = (value: boolean) => {
    editingInProcess.value = value
  }
  /**
   * Set the ide meta
   * @param meta - The meta to set
   */
  const setRouteMeta = (meta: IMeta) => {
    routeMeta.value = meta
  }
  /**
   * Set the ide meta
   * @param meta - The meta to set
   */
  const setIdeMeta = (meta: IMeta) => {
    ideMeta.value = meta
  }
  /**
   * set font size
   * @param size - The size to set
   */
  const setFontSize = (size: number) => {
    fontSize.value = size
  }
  /**
   * Set the project
   * @param data - The project to set
   */
  const setProject = (data: any | null) => {
    project.value = data
  }
  /**
   * Set the active item. usually done when init
   */
  const setActiveItem = () => {
    activeItem.value = project.value?.treeData.children.find((o: any) => {
      return '/' + o.name === project.value.home
    })
  }
  /**
   * Set the minimize tree
   * @param value - The value to set
   */
  const setMinimizeTree = (value: boolean) => {
    minimizeTree.value = value
  }
  /**
   * Set the project key
   * @param key - The key to set
   */
  const setProjectKey = (key: string | boolean) => {
    projectKey.value = key
  }
  /**
   * Set the sync error messages. will be displayed in the project tree
   * @param value - The value to set
   */
  const setSyncErrorMessages = async (value: string | null) => {
    syncErrorMessages.value = value
    await new Promise((resolve) => setTimeout(resolve, 8000))
    syncErrorMessages.value = null
  }
  /**
   * reset the editor
   */
  const resetEditor = () => {
    args.value = ''
    stdin.value = ''
    javaLibraries.value = []
    executionTime.value = null
    memory.value = 0
    cpuTime.value = 0
  }
  /**
   * reset the execution time
   */
  const resetExecutionTime = () => {
    executionTime.value = null
    memory.value = null
    cpuTime.value = null
  }
  /**
   * Set the execution time
   * @param value - The value to set
   */
  const setExecutionqTime = (value: string) => {
    executionTime.value = value
  }
  /**
   * set the initial version index
   */
  const initVersionIndex = () => {
    if (isGuru.value) return
    if (isVersions.value.length > 0) {
      if (isDefaultVersion.value !== -1) {
        versionIndex.value = isDefaultVersion.value
      } else {
        versionIndex.value = isVersions.value.length - 1
      }
    }
  }
  /**
   * Set the version
   * @param index - The version index to set
   */
  const setVersionIndex = (index: number) => {
    versionIndex.value = index
  }

  /**
   * Set the isCodeExecuting
   * @param value - The value to set
   */
  const setisCodeExecuting = (value: boolean) => {
    isCodeExecuting.value = value
  }
  /**
   * Set output files
   * @param files - The files to set
   */
  const setOutputFiles = (files: string[]) => {
    outputFiles.value = files
  }
  /**
   * reset stdin
   */
  const resetStdin = () => {
    stdin.value = null
  }
  /**
   * Set the execution data
   * @param data - The data to set
   */
  const setExcecutionData = (data: any) => {
    executionTime.value = data.executeTime
    memory.value = data.memory
    cpuTime.value = data.cpuTime
    outputFiles.value = data.outputFiles
  }
  /**
   * Set socket next id
   * @param value - The value to set
   */
  const setwsNextId = (value: number) => {
    wsNextId.value = value
  }
  /**
   * Set socket connected
   * @param value - The value to set
   */
  const setSocketConnected = (value: boolean) => {
    socketConnected.value = value
  }
  /**
   * Set upload in progress
   * @param value - The value to set
   */
  const setUploadInProgress = (value: boolean) => {
    uploadInProgress.value = value
  }
  /**
   * Set input files
   * @param files - The files to set
   */
  const setInputFiles = (files: string[]) => {
    inputFiles.value = files
  }
  /**
   * Remove input file
   * @param file - The file to remove
   */
  const removeInputFile = (file: string) => {
    inputFiles.value.splice(inputFiles.value.indexOf(file), 1)
  }
  /**
   * Set file error messages
   * @param type - The value to set
   * @param value - The value to set
   */
  const setFileUploadMessages = async (
    type: (typeof FILE_MESSAGE_TYPE)[keyof typeof FILE_MESSAGE_TYPE],
    value: string | null
  ) => {
    fileUploadMessages.value = {
      type: type,
      message: value
    }
    await new Promise((resolve) => setTimeout(resolve, 5000))
    fileUploadMessages.value = null
  }
  /**
   * Set projects
   * @param data - The data to set
   */
  const setProjects = (data: any[]) => {
    projects.value = data
  }
  /**
   * Set selected project
   * @param data - The data to set
   */
  const setSelectedProject = (data: any | null) => {
    selectedProject.value = data
  }
  /**
   * Set the isLocalStoreEnabled
   * @param value - The value to set
   */
  const setIsLocalStoreEnabled = (value: boolean) => {
    isLocalStoreEnabled.value = value
  }
  /**
   * Set the storage
   * @param value - The value to set
   */
  const setStotage = (value: any | null) => {
    storage.value = value
  }
  /**
   * Set the execution history
   * @param value - The value to set
   */
  const setExecutionHistory = (value: any[] | null) => {
    executionHistory.value = value
  }
  /**
   * Set the selected execution history
   * @param value - The value to set
   */
  const setSelectedExecutionHistory = (value: TExecutionHistory | THtmlExecutionHistory | null) => {
    selectedExecutionHistory.value = value
  }
  /**
   * Set the copy editor
   * @param value - The value to set
   */
  const setIsCopied = (value: boolean) => {
    isCopied.value = value
  }
  /**
   * Set the execute code editor
   * @param value - The value to set
   */
  const setImportedFromFiles = (value: boolean) => {
    importedFromFiles.value = value
  }
  /**
   * Set project editable share url
   * @param url - The url to set
   */
  const setEditableShareUrl = (url: string | null) => {
    project.value.url = url
  }
  /**
   * Set project editable share embed url
   * @param embedUrl - The embed url to set
   */
  const setEditableShareEmbedUrl = (embedUrl: string | null) => {
    project.value.embedUrl = embedUrl
  }
  /**
   * Set the pluginId
   * @param pluginId - The value to set
   */
  const setEditableSharePluginId = (pluginId: string | null) => {
    project.value.pluginId = pluginId
  }
  /**
   * Set the isProBundlePlan
   * @param value - The value to set
   */
  const setIsProBundlePlan = (value: boolean) => {
    project.value.isProBundlePlan = value
  }
  /**
   * Set the print blocker
   * @param value - The value to set
   */
  const setPrintBlocker = (value: boolean) => {
    printBlocker.value = value
  }
  /**
   * Set the full screen
   * @param value - The value to set
   */
  const setFullScreen = (value: boolean) => {
    fullScreen.value = value
  }
  /**
   * Set the code updated
   * @param value - The value to set
   */
  const setCodeUpdated = (value: boolean) => {
    codeUpdated.value = value
  }
  /**
   * Set the blockly expand
   * @param value - The value to set
   */
  const setBlocklyExpand = (value: boolean) => {
    blocklyExpand.value = value
  }
  /**
   * Set the blockly workspace
   * @param value - The value to set
   */
  const setBlocklyWorkspace = (value: any) => {
    blocklyWorkspace = value
  }
  /**
   * Set the blockly editor languages
   * @param value - The value to set
   */
  const setBlocklyEditorLanguages = (value: any[]) => {
    blocklyEditorLanguages.value = value
  }
  /**
   * Set the blockly editor languages
   * @param value - The value to set
   */
  const setShareNotFound = (value: boolean) => {
    shareNotFound.value = value
  }
  /**
   * Set shareNotFoundHttpError value
   * @param value - The value to set
   */
  const setShareNotFoundHttpError = (value: string | null) => {
    shareNotFoundHttpError.value = value
  }
  /**
   * Set the shared id
   * @param value - The value to set
   */
  const setSharedId = (value: string | null) => {
    sharedId.value = value
  }
  /**
   * Set the isEmbedded
   * @param value - The value to set
   */
  const setIsEmbedded = (value: boolean) => {
    isEmbedded.value = value
  }
  /**
   * Set the pym child
   * @param value - The value to set
   */
  const setPymChild = (value: any | null) => {
    pymChild.value = value
  }

  /**
   * Set the isGuru
   * @param value - The value to set
   */
  const setIsGuru = (value: boolean) => {
    isGuru.value = value
  }
  /**
   * Set the html expand
   * @param value - The value to set
   */
  const setHtmlExpand = (value: boolean) => {
    htmlExpand.value = value
  }
  /**
   * Set the html expand
   * @param value - The value to set
   */
  const setOpenProjectID = (value: string | null) => {
    openProjectID.value = value
  }

  return {
    syncInProgress,
    editingInProcess,
    setEditingInProgress,
    routeMeta,
    setRouteMeta,
    ideMeta,
    setIdeMeta,
    fontSize,
    setFontSize,
    ideSplit,
    codeEditor,
    outputEditor,
    resetEditor,
    resetExecutionTime,
    setExecutionqTime,
    project,
    setProject,
    activeItem,
    setActiveItem,
    minimizeTree,
    setMinimizeTree,
    projectKey,
    setProjectKey,
    syncErrorMessages,
    setSyncErrorMessages,
    isWindowAce,
    isAdvanced,
    isShared,
    isInstantShare,
    isAceLanguageCode,
    isLanguage,
    isLangDisplayName,
    isProject,
    isProjectId,
    isVersions,
    versionIndex,
    isDefaultVersion,
    initVersionIndex,
    setVersionIndex,
    isVersionIndex,
    interactiveMode,
    executionTime,
    cpuTime,
    memory,
    args,
    stdin,
    resetStdin,
    isCodeExecuting,
    setisCodeExecuting,
    isBlockly,
    isBlocklyLanguages,
    isBlocklyLanguageVersions,
    javaLibraries,
    setExcecutionData,
    autoSaveOn,
    isAutoSaveOn,
    socketClient,
    wsNextId,
    isWsNextId,
    setwsNextId,
    isSocketConnected,
    setSocketConnected,
    isUploadInProgress,
    setUploadInProgress,
    isMaxUploads,
    isMaxFileSize,
    isInputFiles,
    setInputFiles,
    removeInputFile,
    isOutputFiles,
    setOutputFiles,
    isFileUploadMessages,
    setFileUploadMessages,
    isProjects,
    setProjects,
    projectEditor,
    isSelectedProject,
    setSelectedProject,
    isLocalStoreEnabled,
    setIsLocalStoreEnabled,
    isStorage,
    setStotage,
    isExecutionHistory,
    setExecutionHistory,
    isSelectedExecutionHistory,
    setSelectedExecutionHistory,
    executeCodeEditor,
    executeOutputEditor,
    copyEditor,
    isCopied,
    setIsCopied,
    downloadEditor,
    openEditor,
    isImportedFromFiles,
    setImportedFromFiles,
    setEditableShareUrl,
    setEditableShareEmbedUrl,
    setEditableSharePluginId,
    setIsProBundlePlan,
    isPrintBlocker,
    setPrintBlocker,
    isFullScreen,
    setFullScreen,
    isCodeUpdated,
    setCodeUpdated,
    isblocklyExpand,
    setBlocklyExpand,
    isBlocklyWorkspace,
    setBlocklyWorkspace,
    isBlocklyEditorLanguages,
    setBlocklyEditorLanguages,
    isShareId,
    setSharedId,
    shareNotFound,
    setShareNotFound,
    shareNotFoundHttpError,
    setShareNotFoundHttpError,
    isEmbedded,
    setIsEmbedded,
    pymChild,
    setPymChild,
    isGuru,
    setIsGuru,
    isHtmlExpand,
    setHtmlExpand,
    htmlDoctypeEditor,
    htmlHeadEditor,
    htmlBodyEditor,
    htmlJsEditor,
    htmlCssEditor,
    isHtml,
    terminal,
    isTerminal,
    setOpenProjectID,
    openProjectID
  }
})
