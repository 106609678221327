<script setup lang="ts">
import BaseInput from '@/components/forms/BaseInput.vue'
import Goback from '@/components/utils/GobackComp.vue'
import authService, { type IRestPasswordRequest } from '@/services/auth.service'
import { useAuthStore } from '@/stores/auth.store'
import { useField, useForm } from 'vee-validate'
import { computed, onMounted, ref, watch, watchEffect } from 'vue'
import { string } from 'yup'

const props = defineProps({
  token: {
    type: String,
    required: true
  }
})

const authStore = useAuthStore()

const httpErrorTimeOut = ref<number | null>(null)
const httpError = ref<string>('')

const resetTokenError = ref('')
const resetError = ref('')

enum RESTPASSWORDSTATE {
  WAITINGFORINIT = 'waitingForInit',
  LOGEDIN = 'logedin',
  INVALIDE = 'invalid',
  RESETTING = 'resetting',
  RESET = 'reset'
}

const resetPasswordState = ref<RESTPASSWORDSTATE>(RESTPASSWORDSTATE.WAITINGFORINIT)

const showGoback = computed<boolean>(() => {
  return (
    resetPasswordState.value !== RESTPASSWORDSTATE.WAITINGFORINIT &&
    resetPasswordState.value !== RESTPASSWORDSTATE.RESETTING
  )
})

interface ISuggestion {
  title: string
  description: string
}

const waitingForInitSuggestion: ISuggestion[] = [
  {
    title: 'Please wait...',
    description: 'We are verifying your password reset token. Please wait for a moment.'
  }
]
const logedInSuggestion: ISuggestion[] = [
  {
    title: 'Please logout to reset your password',
    description:
      'please click on the logout button to logout from your account. We will verify your password reset token after you logout.'
  }
]

const invalidTokenSuggesions: ISuggestion[] = [
  {
    title: 'Please check the link is valid',
    description:
      'If you have copied the link from an email, please make sure you have copied the entire link.'
  },
  {
    title: 'This is one time use link',
    description:
      'If you have already used the link, your password reset token should have been expired already. Please request for a new password reset token.'
  },
  {
    title: 'Your password token is expired',
    description:
      'Your password reset code may have been expired. Please request for a new password reset token.'
  },
  {
    title: 'If you still have issues varifying your email',
    description: 'Please contact JDoodle Support for any assistance.'
  }
]

const resetSuggestion: ISuggestion[] = [
  {
    title: 'Your password has been reset successfully',
    description: 'Now you are logged in with your new password.'
  }
]

interface IViewData {
  title: string
  suggestions: ISuggestion[]
  icon: string
}

const viewData = computed<IViewData>(() => {
  switch (resetPasswordState.value) {
    case RESTPASSWORDSTATE.LOGEDIN:
      return {
        title: 'You are already logged in',
        suggestions: logedInSuggestion,
        icon: 'right-from-bracket'
      }
    case RESTPASSWORDSTATE.INVALIDE:
      return {
        title: 'We are unable to verify your password reset token',
        suggestions: invalidTokenSuggesions,
        icon: 'circle-question'
      }
    case RESTPASSWORDSTATE.RESETTING:
      return {
        title: 'Reset Password',
        suggestions: [],
        icon: 'unlock'
      }
    case RESTPASSWORDSTATE.RESET:
      return {
        title: 'Your password has been reset successfully',
        suggestions: resetSuggestion,
        icon: 'circle-check'
      }
    default:
      return {
        title: 'Loading...',
        suggestions: waitingForInitSuggestion,
        icon: 'spinner'
      }
  }
})
/**
 * @description Verify registration
 */
const checkToken = async () => {
  await authService
    .verifyForgotPasswordToken(props.token)
    .then(() => {
      resetTokenError.value = ''
      resetPasswordState.value = RESTPASSWORDSTATE.RESETTING
    })
    .catch((error: any) => {
      resetTokenError.value =
        error?.response?.data?.message || 'Something went wrong. Please try again.'
      resetPasswordState.value = RESTPASSWORDSTATE.INVALIDE
    })
}

const { errors, handleSubmit, resetForm } = useForm()

const { value: password, errorMessage: passwordError } = useField(
  'newPassword',
  string().required().min(5)
)
const { value: confirmPassword, errorMessage: confirmPasswordError } = useField(
  'retypeNewPassword',
  string().required().min(5)
)
const passwordNotMatchError = ref<string>('')
const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0 && !passwordNotMatchError.value
})

/**
 * @description Verify registration
 *  1. If user is loged in, log out and verify registration
 *  2. If user is not loged in, verify registration
 */
const onSubmit = handleSubmit(async (values) => {
  const requestPayload: IRestPasswordRequest = {
    newPassword: values.newPassword,
    retypeNewPassword: values.retypeNewPassword,
    t: props.token
  }

  await authService
    .resetForgotPassword(requestPayload)
    .then(() => {
      resetError.value = ''
      httpError.value = ''
      resetForm()
      resetPasswordState.value = RESTPASSWORDSTATE.RESET
    })
    .catch((error: any) => {
      httpError.value = error?.response?.data?.message || 'Something went wrong. Please try again.'
    })
})

onMounted(() => {
  watch(
    () => [authStore?.isInitiated, props.token],
    (isAppInitiated) => {
      if (isAppInitiated) {
        if (authStore.isLogedIn) {
          resetPasswordState.value = RESTPASSWORDSTATE.LOGEDIN
        } else {
          checkToken()
        }
      }
    }
  )
  watch(httpError, () => {
    if (httpError.value) {
      if (httpErrorTimeOut.value) clearTimeout(httpErrorTimeOut.value)
      httpErrorTimeOut.value = setTimeout(() => {
        httpError.value = ''
      }, 8000)
    }
  })
  watchEffect(() => {
    if (password.value !== confirmPassword.value) {
      passwordNotMatchError.value = 'Password does not match'
    } else {
      passwordNotMatchError.value = ''
    }
  })
})
</script>
<template>
  <div class="section-primary">
    <div class="view-container py-20 md:max-w-4xl">
      <div class="mb-10">
        <h1 class="heading-large mb-14 text-center md:leading-tight">
          {{ viewData.title }}
        </h1>
        <div v-if="resetPasswordState !== RESTPASSWORDSTATE.RESETTING">
          <h2 v-if="resetTokenError" class="error heading-medium mb-4">
            Reason: {{ resetTokenError }}
          </h2>
          <div v-for="(sugession, index) of viewData.suggestions" :key="index" class="py-4">
            <div class="flex gap-x-5">
              <FontAwesomeIcon :icon="viewData.icon" class="mt-1 h-6 w-6 flex-shrink-0" />
              <div>
                <h3 class="heading-small">
                  {{ sugession.title }}
                </h3>
                <p class="p-normal mt-1 text-justify text-link-primary-hover">
                  {{ sugession.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <form
          v-else
          @submit.prevent="onSubmit"
          class="mx-auto mb-10 w-[90%] md:w-[60%] lg:w-[460px]"
        >
          <BaseInput
            label="New Password"
            :inputType="'password'"
            placeholder="*****"
            :error="passwordError"
            v-model="password"
          />

          <BaseInput
            label="Confirm Password"
            :inputType="'password'"
            placeholder="*****"
            :error="confirmPasswordError"
            v-model="confirmPassword"
          />
          <p :class="['mt-2', 'p-xsmall', 'error', { hidden: !passwordNotMatchError }]">
            {{ passwordNotMatchError }}
          </p>
          <p :class="['mt-2', 'p-xsmall', 'error', { hidden: !httpError }]">
            {{ httpError }}
          </p>

          <button :disabled="!canSubmit" class="btn-primary btn-rounded-md mt-10" type="submit">
            Reset Password
          </button>
        </form>
      </div>
      <Goback v-if="showGoback">
        <button
          v-if="
            resetPasswordState === RESTPASSWORDSTATE.LOGEDIN ||
            resetPasswordState === RESTPASSWORDSTATE.INVALIDE
          "
          @click="checkToken"
          class="btn-secondary btn-rounded-md sm:w-auto"
        >
          <FontAwesomeIcon
            :icon="
              resetPasswordState === RESTPASSWORDSTATE.LOGEDIN
                ? 'right-from-bracket'
                : 'rotate-right'
            "
            class="h-5 w-5"
          />
          {{ resetPasswordState === RESTPASSWORDSTATE.LOGEDIN ? 'Logout and verify' : 'Try again' }}
        </button>
      </Goback>
    </div>
  </div>
</template>
