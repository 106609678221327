<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { VueRecaptcha } from 'vue-recaptcha'
import { RECAPTCHA_KEYS } from '@/utils/recaptcha'
import recaptchaService from '@/services/recaptcha.service'
import { useRecaptchaStore } from '@/stores/recaptcha.store'

const sitekey = RECAPTCHA_KEYS.RECAPTCHAIDEKEY
const recaptchaStore = useRecaptchaStore()
const rechaptcha = ref<any>(null)

const promise = computed(() => {
  return recaptchaStore.isRecaptchaPromise
})
const isRecaptchaPromiseFinish = computed(() => {
  return recaptchaStore.isRecaptchaPromiseFinish
})
/**
 * Get the rechaptcha and set it on ide store
 * @param count - The reCAPTCHA error message.
 */
const postAction = async (count: number = 0) => {
  if (!rechaptcha.value) {
    if (count < 10) {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      postAction(count + 1)
    }
    return
  } else {
    recaptchaStore.rechaptcha = rechaptcha.value
  }
}

onMounted(() => {
  postAction()
  watch(promise, (val) => {
    if (!isRecaptchaPromiseFinish.value && val) {
      recaptchaService.checkRecaptcha()
    }
  })
})
</script>
<template>
  <vue-recaptcha
    class="hidden"
    size="invisible"
    ref="rechaptcha"
    :sitekey="sitekey || ''"
    @verify="recaptchaService.recaptchaVerified"
    @fail="recaptchaService.recaptchaFailed"
    @error="recaptchaService.recaptchaError"
  />
</template>
