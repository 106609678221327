<script setup lang="ts">
import CodeBanner from '@/components/code/CodeBanner.vue'
import CodeHero from '@/components/code/CodeHero.vue'
import WhyJdoodleSection from '@/components/code/WhyJdoodleSection.vue'
import CodeUserReviews from '@/components/code/CodeUserReviews.vue'
import MobileAppSection from '@/components/shared/MobileAppSection.vue'
import RecommendForm from '@/components/code/RecommendForm.vue'
import CodeFAQ from '@/components/code/CodeFAQ.vue'
// import CodePricing from '@/components/code/CodePricing.vue'
import SubscriptionComponent from '@/components/shared/subscription/SubscriptionComponent.vue'
import { SUBSCRIPTION_TYPE_ENUM } from '@/components/shared/subscription/enum/subscription.enum'
import { onMounted } from 'vue'
import { initBundle } from '@/services/subscription.service'
import { useSubscribeStore } from '@/stores/subscribe.store'

const subscribeStore = useSubscribeStore()
onMounted(async () => {
  const bundleRes = await initBundle()
  subscribeStore.initBundle(bundleRes)
})
</script>

<template>
  <SubscriptionComponent :subscriptionType="SUBSCRIPTION_TYPE_ENUM.BUNDLE" />
  <CodeHero />
  <WhyJdoodleSection />
  <CodeBanner />
  <!-- <CodePricing /> -->
  <CodeUserReviews />
  <RecommendForm />
  <CodeFAQ />
  <MobileAppSection />
</template>
